import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

import type { CropperProps } from "./cropper";
import { Cropper } from "./cropper";

export const openCropperModal = (props: CropperProps) =>
  ModalInstance.open({
    children: <CropperModalContent {...props} />,
    keepOnBackdropClick: false,
    closeButton: false,
  });

const CropperModalContent = ({
  file,
  onCrop,
  onClose,
  ...props
}: CropperProps) => {
  if (!file) {
    return <p>Sorry! There was an issue with file upload. Please try again.</p>;
  }

  const handleCrop = (fileToCrop: File) => {
    onCrop(fileToCrop);
    ModalInstance.close();
  };

  const handleClose = () => {
    if (onClose) onClose();
    ModalInstance.close();
  };

  return (
    <>
      <Typography
        component="h3"
        variant="title"
        fontWeight={400}
        size="small"
        className="mb mt0"
      >
        Crop Image
      </Typography>
      <Cropper
        {...props}
        file={file}
        onClose={handleClose}
        onCrop={handleCrop}
      />
    </>
  );
};

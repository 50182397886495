import React from "react";

import { Box, Button } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import type { TopNavigationProps } from "@js/layouts/app";
import { TopNavigationComponent } from "@js/layouts/app";
import { getCurrentPathEncoded } from "@js/utils";

import styles from "./styles.module.scss";

type PublicPostPageTopNavigationProps = TopNavigationProps & {
  handleSignUp: () => void;
};

export const PublicPostPageTopNavigation = ({
  handleSignUp,
  ...props
}: PublicPostPageTopNavigationProps) => {
  const smallMobile = useMediaQuery(400);
  const isMobile = useMediaQuery("sm");
  const buttonsSize = isMobile ? "x-small" : "small";

  return (
    <TopNavigationComponent
      bgcolor={"var(--soft-green)"}
      px={0}
      logoSize={smallMobile ? 100 : 128}
      {...props}
    >
      <Box display="flex" sx={{ gap: { xs: 1, sm: 2 } }}>
        <Button
          variant="secondary"
          href={`/auth/login/?next=${getCurrentPathEncoded()}`}
          RouterLink={RouterLink}
          size={buttonsSize}
        >
          Log&nbsp;in
        </Button>
        <Button
          variant="primary"
          className={styles.btnGuest}
          onClick={handleSignUp}
          size={buttonsSize}
        >
          Sign up
        </Button>
      </Box>
    </TopNavigationComponent>
  );
};

import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Checkbox } from "@braintrust/braintrust-ui-components";

type StoreResumeCheckboxFieldProps = {
  wasResumeOnceUploaded: boolean;
};

export const StoreResumeCheckboxField = ({
  wasResumeOnceUploaded,
}: StoreResumeCheckboxFieldProps) => {
  return (
    <Field
      name="store_resume_for_future_bids"
      wasResumeOnceUploaded={wasResumeOnceUploaded}
      component={StoreResumeCheckbox}
    />
  );
};
const StoreResumeCheckbox = ({
  input,
  wasResumeOnceUploaded,
}: WrappedFieldProps & StoreResumeCheckboxFieldProps) => {
  return (
    <Checkbox
      checked={!!input.value}
      onChange={input.onChange}
      label={
        wasResumeOnceUploaded
          ? "Add this resume to my profile"
          : "Store resume on profile and for future applications"
      }
    />
  );
};

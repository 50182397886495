import React from "react";
import { useSnackbar } from "notistack";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import type { SavedJobFilter } from "@js/types/jobs";

import { SnackbarActionButton } from "../snackbar-action-button";

type DeleteSavedFiltersSnackbarContentProps = {
  savedFilter: SavedJobFilter;
  onUndoClick: () => void;
  key?: number;
};

export const DeletedSavedFiltersSnackbarContent = ({
  savedFilter,
  onUndoClick,
  key,
}: DeleteSavedFiltersSnackbarContentProps) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Box
      sx={{
        color: "var(--white)",
        opacity: 0.9,
        borderRadius: "10px",
        px: 2,
        py: 2,
        bgcolor: "var(--black)",
      }}
    >
      <Typography component="span" size="medium" color="white">
        "{savedFilter.name}" has been removed.{" "}
        <SnackbarActionButton
          onClick={() => {
            onUndoClick();
            closeSnackbar(key);
          }}
          variant="no-padding"
          withUnderline
        >
          Undo
        </SnackbarActionButton>
      </Typography>
    </Box>
  );
};

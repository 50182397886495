import type { MouseEvent } from "react";
import { useLocation } from "react-router-dom";

import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { Button } from "@braintrust/braintrust-ui-components";
import { ArrowTopRightIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useJobLocationContext } from "@js/apps/jobs/context";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import type { MainJobListingJob } from "@js/types/jobs";

import { clickViewJobAfterSearch, viewJobClicked } from "../../actions";
import { getJobDetailsUrl } from "../../utils";

type ViewJobButtonProps = {
  job: MainJobListingJob;
  fullWidth?: boolean;
  resultPosition?: number;
  searchResults?: boolean;
} & Pick<ButtonProps, "variant" | "sx" | "style" | "className" | "color">;

export const ViewJobButton = ({
  job,
  fullWidth,
  resultPosition,
  searchResults,
  ...props
}: ViewJobButtonProps) => {
  const dispatch = useAppDispatch();
  const location = useJobLocationContext();
  const { search } = useLocation();
  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.JOB_SEARCH_BOX,
  );

  const btnText = job.has_freelancer_bid ? "Applied" : "View job";
  const btnVariant = job.has_freelancer_bid ? "positive" : props.variant;

  const handleClick = () => {
    dispatch(viewJobClicked({ source: location, search }));

    if (searchResults && resultPosition !== undefined)
      dispatch(
        clickViewJobAfterSearch({
          jobId: job.id,
          location,
          resultPosition,
          searchQueryId,
        }),
      );
  };

  const handleMouseDown = (e: MouseEvent<HTMLElement>) => {
    //left mouse button click -- is handled by onClick - swiper removes onmousedown with button = 0
    if (e.button === 0) return;

    handleClick();
  };

  return (
    <>
      <Button
        {...props}
        fullWidth={fullWidth}
        variant={btnVariant}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        to={getJobDetailsUrl({ id: job.id, search, location })}
        rel="noopener noreferrer"
        target="_blank"
        RouterLink={RouterLink}
      >
        {btnText} <ArrowTopRightIcon style={{ fontSize: 24 }} />
      </Button>
    </>
  );
};

import type { MouseEventHandler, ReactNode } from "react";
import React from "react";

import type {
  BoxProps,
  TypographyProps,
} from "@braintrust/braintrust-ui-components";
import { Box, Stack, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { Sanitize } from "@js/components/sanitize";

import { useCtaClickedContext } from "../../context";

export type ReferCardProps = {
  bgcolor?: string;
  description?: React.ReactNode;
  footer?: React.ReactNode;
  imgSrc?: string;
  title: React.ReactNode;
  titleSize?: TypographyProps["size"];
  descriptionProps?: Partial<TypographyProps>;
  footerProps?: BoxProps;
};

export type ReferCardBoxProps = Omit<BoxProps, keyof ReferCardProps>;

export const ReferCard: React.FC<
  React.PropsWithChildren<ReferCardProps & ReferCardBoxProps>
> = ({
  children,
  bgcolor = "soft-yellow",
  description,
  footer,
  imgSrc,
  title,
  titleSize = "large",
  descriptionProps,
  footerProps,
  ...rest
}) => {
  const { ctaClicked } = useCtaClickedContext();

  const handleFooterClick = (event: React.MouseEvent<HTMLElement>) => {
    const targetElement = event.target as HTMLElement;

    if (targetElement.tagName === "A") {
      ctaClicked(ENUMS.UserClicksEventCTAName.LEARN_MORE_ABOUT_BRAINTRUST);
    }
  };

  return (
    <Box
      bgcolor={`var(--${bgcolor})`}
      px={{ xs: 3, sm: 5 }}
      py={{ xs: 3, sm: 4 }}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
      >
        <Stack sx={{ justifyContent: "space-between" }}>
          {typeof title === "string" ? (
            <Title
              size={titleSize}
              children={<Sanitize input={title} unescape />}
            />
          ) : (
            <Title size={titleSize} children={title} />
          )}
          {description && (
            <>
              {typeof description === "string" ? (
                <Description
                  {...descriptionProps}
                  children={<Sanitize input={description} unescape />}
                />
              ) : (
                <Description {...descriptionProps} children={description} />
              )}
            </>
          )}
        </Stack>
        {imgSrc && (
          <Box ml={{ xs: 0, sm: 4 }} textAlign="center">
            <img src={imgSrc} alt="" width="200" height="200" />
          </Box>
        )}
      </Box>

      {children && <Box>{children}</Box>}
      {footer && (
        <Footer boxProps={footerProps} onClick={handleFooterClick}>
          {typeof footer === "string" ? (
            <Sanitize input={footer} unescape style={{ lineHeight: "24px" }} />
          ) : (
            footer
          )}
        </Footer>
      )}
    </Box>
  );
};

const setSizeForSmall = (size: TypographyProps["size"]) => {
  if (size === "medium") {
    return "small";
  } else if (size === "large") {
    return "medium";
  }
  return size;
};

const Title = ({
  size,
  ...props
}: Partial<TypographyProps> & {
  size: NonNullable<TypographyProps["size"]>;
}) => {
  const isMobile = useMediaQuery("sm");
  const _size = isMobile ? setSizeForSmall(size) : size;

  return (
    <Typography
      component="h3"
      variant="title"
      size={_size}
      fontWeight={400}
      mb={1}
      {...props}
    />
  );
};

const Description = (props: Partial<TypographyProps>) => {
  return <Typography component="div" size={"large"} {...props} />;
};

const Footer = ({
  boxProps,
  onClick,
  children,
}: {
  boxProps?: BoxProps;
  onClick: MouseEventHandler<HTMLElement>;
  children: ReactNode;
}) => {
  return (
    <Box mt={4} display="flex" alignItems="flex-end" {...boxProps}>
      <Typography
        component="div"
        size="small"
        color="secondary"
        onClick={onClick}
      >
        {children}
      </Typography>
    </Box>
  );
};

import cs from "classnames";

import { Alert, Typography } from "@braintrust/braintrust-ui-components";

import styles from "./style.module.scss";

export type LocationMismatchAlertProps = {
  userFirstName: string;
  sessionLocationMismatch: boolean;
  billingLocationMismatch: boolean;
  className?: string;
};

export const LocationMismatchAlert = ({
  userFirstName,
  sessionLocationMismatch,
  billingLocationMismatch,
  className,
}: LocationMismatchAlertProps) => {
  if (!sessionLocationMismatch && !billingLocationMismatch) {
    return null;
  }

  return (
    <Alert
      type="error"
      data-testid="location-mismatch-alert"
      className={cs(styles.locationMismatchAlert, styles.mediumRed, className)}
    >
      <Typography component="p" variant="paragraph" size="medium">
        {getLocationMismatchAlertContent({
          userFirstName,
          sessionLocationMismatch,
          billingLocationMismatch,
        })}
      </Typography>
    </Alert>
  );
};

const getLocationMismatchAlertContent = ({
  userFirstName,
  sessionLocationMismatch,
  billingLocationMismatch,
}: LocationMismatchAlertProps) => {
  if (sessionLocationMismatch && billingLocationMismatch) {
    return `📍 There are multiple discrepancies with ${userFirstName}’s location.`;
  }

  if (sessionLocationMismatch) {
    return `📍 ${userFirstName}’s profile location does not match their last active session.`;
  }

  return `📍 ${userFirstName}’s profile location does not match their billing location.`;
};

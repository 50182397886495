import { Loader, Typography } from "@braintrust/braintrust-ui-components";
import { SyncCircleIcon } from "@braintrust/braintrust-ui-components/Icons";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import type { EmployerInvoiceMinimal } from "@js/types/invoices";
import { formatCurrency, pluralize } from "@js/utils";

import { usePayDependentInvoicesFirst } from "./use-pay-dependent-invoices-first";

type PayDependentInvoiceFirstMessageProps = {
  invoicesThatCannotBePaid: EmployerInvoiceMinimal[];
  onConfirmIncludingInvoices: (toInclude: number[]) => void;
};

export const openPayDependentInvoiceFirstMessage = ({
  invoicesThatCannotBePaid,
  onConfirmIncludingInvoices,
}: PayDependentInvoiceFirstMessageProps) => {
  ModalInstance.open({
    keepOnBackdropClick: true,
    closeButton: false,
    children: (
      <PayDependentInvoiceFirstMessageModalContent
        {...{ invoicesThatCannotBePaid, onConfirmIncludingInvoices }}
      />
    ),
  });
};

const PayDependentInvoiceFirstMessageModalContent = ({
  invoicesThatCannotBePaid,
  onConfirmIncludingInvoices,
}: PayDependentInvoiceFirstMessageProps) => {
  const {
    fetchingDependentInvoicesList,
    dependentThatCantBePaid,
    dependentInvoicesList,
    handleConfirm,
  } = usePayDependentInvoicesFirst({
    invoicesThatCannotBePaid,
    onConfirmIncludingInvoices,
  });

  if (fetchingDependentInvoicesList) {
    return <Loader />;
  }

  const confirmText = `Include ${
    invoicesThatCannotBePaid.length > 1 ? "these invoices" : "this invoice"
  } in my payment`;

  return (
    <ModalConfirm
      confirmDisabled={dependentThatCantBePaid.length > 0}
      confirmText={confirmText}
      onConfirm={handleConfirm}
      onCancel={ModalInstance.close}
    >
      <Typography
        component="h1"
        variant="title"
        fontWeight={400}
        size="small"
        className="mb+ header-with-icon"
      >
        <SyncCircleIcon /> Dependent Invoice
        {pluralize(invoicesThatCannotBePaid.length)}
      </Typography>
      <Typography component="p">
        Talent invoice{pluralize(invoicesThatCannotBePaid.length)}&nbsp;must be
        paid first so fees can be calculated correctly:
      </Typography>
      <ol className="mb++">
        {dependentThatCantBePaid.length > 0
          ? dependentThatCantBePaid.map((invoice) => {
              return (
                <li key={invoice.id}>
                  Please wait for the <strong>{invoice.number}</strong> Invoice
                  payment to complete prior to paying the fee invoice.
                </li>
              );
            })
          : (dependentInvoicesList || []).map((invoice) => {
              return (
                <li key={invoice.id}>
                  Invoice <strong>{invoice.number}</strong> from{" "}
                  {invoice.freelancer_name} for&nbsp;
                  {formatCurrency(invoice.gross_total)}.
                </li>
              );
            })}
      </ol>
    </ModalConfirm>
  );
};

import {
  Avatar,
  Badge,
  Box,
  Button,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { AdminBadgeIcon } from "@braintrust/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import type { SpaceUser } from "@js/types/spaces";

import styles from "./member-avatar-with-details.module.scss";

export type MemberAvatarWithDetailsProps = {
  member: Pick<SpaceUser, "is_admin" | "user">;
};

export const MemberAvatarWithDetails = ({
  member: {
    is_admin,
    user: { public_name, introduction_headline, freelancer_id, ...avatarData },
  },
}: MemberAvatarWithDetailsProps) => {
  return (
    <Button
      variant="no-padding"
      RouterLink={RouterLink}
      to={`/talent/${freelancer_id}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box className={styles.container}>
        <Badge
          badgeContent={
            is_admin ? (
              <AdminBadgeIcon data-testid="space-admin-badge" />
            ) : undefined
          }
          className={styles.avatar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          overlap="circular"
        >
          <Avatar src={avatarData} size="md" color="dark-blue" />
        </Badge>

        <Stack>
          <Typography component="span" variant="label" size="medium" ellipsis>
            {public_name}
          </Typography>
          <Typography
            component="span"
            variant="paragraph"
            size="small"
            color="grey-1"
            multilineEllipsis={4}
          >
            {introduction_headline}
          </Typography>
        </Stack>
      </Box>
    </Button>
  );
};

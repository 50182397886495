import { Navigate, Outlet, Route } from "react-router-dom";

import { useAccountType, useIsNodeStaff } from "@js/apps/common/hooks";
import { ACCESS_RULE } from "@js/routes/constants";
import { ProtectedRoute } from "@js/routes/protected-route";

import {
  CreateOfferPage,
  EditOfferPage,
  EmployerPreviewOfferProtectedPage,
  TalentOfferAcceptedSuccessPage,
  TalentPreviewOfferPage,
} from "../views";
import { NodeStaffPrevievOffer } from "../views/node-staff-preview-offer";

const PreviewOfferPage = () => {
  const { isEmployer, isFreelancer } = useAccountType();
  const isNodeStaff = useIsNodeStaff();

  if (isNodeStaff) {
    return <NodeStaffPrevievOffer />;
  }

  if (isEmployer) {
    return <EmployerPreviewOfferProtectedPage />;
  }

  if (isFreelancer) {
    return <TalentPreviewOfferPage />;
  }

  return <Navigate to="/" replace />;
};

export const OffersRoutes = (
  <>
    <Route
      element={
        <ProtectedRoute
          rules={[
            ACCESS_RULE.CAN_MANAGE_JOBS,
            ACCESS_RULE.EMPLOYER_HAS_ADDRESS_SET,
          ]}
        >
          <Outlet />
        </ProtectedRoute>
      }
    >
      <Route path=":offerId/edit" element={<EditOfferPage />} />
      <Route path=":bidId/new" element={<CreateOfferPage />} />
    </Route>
    <Route
      path=":offerId/accepted"
      element={<TalentOfferAcceptedSuccessPage />}
    />
    <Route path=":offerId" element={<PreviewOfferPage />} />
    <Route path="*" element={<Navigate to="/page-not-found" replace />} />
  </>
);

import { Field, reduxForm } from "redux-form";
import type { CountryCode } from "libphonenumber-js";

import { Box, Button, Grid } from "@braintrust/braintrust-ui-components";
import { Modal } from "@js/components/modal";
import { Form } from "@js/forms/components/form";
import { PhoneField } from "@js/forms/fields/phone";

import { Section } from "../helpers";

import styles from "./styles.module.scss";

export const MOBILE_NUMBER_FORM_ID = "mobile-number-form";
const UPDATE_PHONE_NUMBER_VERIFICATION_MODAL_ID =
  "update-phone-number-verification-modal";

export const UpdatePhoneNumberVerificationModal = Modal(
  UPDATE_PHONE_NUMBER_VERIFICATION_MODAL_ID,
  {
    closeButton: false,
    className: styles.phoneVerificationCodeModal,
  },
);

type PhoneNumberType = string;

export type PhoneVerificationFormValues = {
  phone_number: {
    country_iso2: CountryCode;
    phone_number: PhoneNumberType;
  };
};
type PromptFormValues = {
  verification_code?: string;
  code?: string;
  is_backup_code?: boolean;
  password?: string;
};

export type TwoFaCode = {
  code?: string;
  password?: string;
};

export type VerificationData = PromptFormValues;

type OwnProps = {
  isPhoneNumberSet: boolean;
};

export const PhoneVerificationForm = reduxForm<
  PhoneVerificationFormValues,
  OwnProps
>({
  form: MOBILE_NUMBER_FORM_ID,
  enableReinitialize: true,
})(({ submit, error, submitting, isPhoneNumberSet, pristine }) => {
  return (
    <>
      <Form onSubmit={submitting ? null : submit} error={error}>
        <Section title="Mobile Number">
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <Field
                variant="standard"
                id="phone_number"
                name="phone_number"
                component={PhoneField}
                helperText={
                  isPhoneNumberSet
                    ? undefined
                    : "We will send a verification code to your mobile number."
                }
              />
            </Grid>
            <Grid item sm={6} xs={12} container alignItems="flex-start">
              <Box mt={0.75}>
                <Button variant="primary" type="submit" disabled={pristine}>
                  Save Mobile Number
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Form>
      <UpdatePhoneNumberVerificationModal />
    </>
  );
});

import { z } from "zod";

import { API } from "@js/api";
import type { UnverifiedUser } from "@js/types/auth";

import type { ClientReferralProgramFormValues } from "../dashboard/views/refer-clients-program/join-clients-referral-program-form";

type BanUserFromPostingParams = {
  userId: number;
  postId: number;
};
type SendConnectorSignupDataParams = {
  page_name: "Earn" | "Signup";
};
export type PurchaseUpgradeParams = {
  upgrade_type: "boost" | "insights";
};
export type InitStripeCheckoutParams = PurchaseUpgradeParams & {
  return_url: string;
};
export type ConfirmStripeCheckoutParams = PurchaseUpgradeParams & {
  session_id: string;
};

const userSearchSchema = z.object({
  id: z.number(),
  name_and_email: z.string(),
});

export type UserSearchResult = z.infer<typeof userSearchSchema>;

export type UserSearchParams = {
  search?: string;
  return_ops_assigned_to_jobs_only?: boolean;
  hiring_manager?: boolean;
  page_size?: number;
  for_employer?: number;
  invoice_action_performer?: boolean;
  return_sales_only?: boolean;
  return_ops_only?: boolean;
};

const usersApi = API.injectEndpoints({
  endpoints: (build) => ({
    getUserSearch: build.query<UserSearchResult[], UserSearchParams>({
      query: (params) => ({
        url: `/user_search/`,
        method: "GET",
        params: {
          limit: 100,
          ...params,
        },
      }),
    }),
    getUserSearchItem: build.query<UserSearchResult | undefined, number>({
      query: (id) => ({
        url: `/user_search/${id}/`,
        method: "GET",
        validateStatus: () => true,
      }),
      transformResponse: (response: unknown) => {
        // validate the data as we are omitting the status validation
        const parseResult = userSearchSchema.safeParse(response);
        if (!parseResult.success) {
          return undefined;
        }

        return parseResult.data;
      },
    }),
    banUserFromPosting: build.mutation<void, BanUserFromPostingParams>({
      query: ({ userId, postId }) => ({
        url: `/users/${userId}/ban_from_posting/`,
        method: "POST",
        data: {
          post: postId,
        },
      }),
    }),
    sendEmployerOnboardingData: build.mutation<void, void>({
      query: () => ({
        url: `/users/send_employer_onboarding_data/`,
        method: "POST",
      }),
    }),
    sendConnectorSignupData: build.mutation<
      void,
      SendConnectorSignupDataParams
    >({
      query: ({ page_name }) => ({
        url: `/users/send_connector_signup_data/?page_name=${page_name}`,
        method: "POST",
      }),
    }),
    getUnfinishedUser: build.query<UnverifiedUser, string>({
      query: (key) => ({
        url: `/user/registration/check_key/`,
        method: "GET",
        params: {
          confirmation_key: key,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    optInIntoReferClientProgram: build.mutation<
      void,
      ClientReferralProgramFormValues
    >({
      query: (values) => ({
        url: `/users/optin_into_refer_client_program/`,
        method: "POST",
        data: values,
      }),
    }),
    purchaseUpgrade: build.mutation<void, PurchaseUpgradeParams>({
      query: ({ upgrade_type }) => ({
        url: `/user/purchase/${upgrade_type}/`,
        method: "POST",
      }),
      invalidatesTags: ["WalletBalance", "Jobs", "GetPurchaseList"],
    }),
    reserveUpgrade: build.mutation<void, PurchaseUpgradeParams>({
      query: ({ upgrade_type }) => ({
        url: `/user/reserve/${upgrade_type}/`,
        method: "POST",
      }),
      invalidatesTags: ["WalletBalance"],
    }),

    rePurchaseUpgrade: build.mutation<void, PurchaseUpgradeParams>({
      query: ({ upgrade_type }) => ({
        url: `/user/purchase/${upgrade_type}/`,
        method: "POST",
      }),
    }),

    initStripeCheckoutUpgradeSession: build.mutation<
      { clientSecret: string },
      InitStripeCheckoutParams
    >({
      query: ({ upgrade_type, return_url }) => ({
        url: `/user/stripe/purchase/${upgrade_type}/`,
        method: "POST",
        params: {
          return_url,
        },
      }),
    }),
    confirmStripeCheckoutUpgradeSession: build.mutation<
      void,
      ConfirmStripeCheckoutParams
    >({
      query: ({ upgrade_type, session_id }) => ({
        url: `/user/stripe/confirm/${upgrade_type}/`,
        method: "POST",
        params: {
          session_id,
        },
      }),
    }),
  }),
});

export const {
  useBanUserFromPostingMutation,
  useSendConnectorSignupDataMutation,
  useSendEmployerOnboardingDataMutation,
  useGetUnfinishedUserQuery,
  useOptInIntoReferClientProgramMutation,
  useGetUserSearchQuery,
  useGetUserSearchItemQuery,
  useLazyGetUserSearchItemQuery,
  usePurchaseUpgradeMutation,
  useReserveUpgradeMutation,
  useRePurchaseUpgradeMutation,
  useInitStripeCheckoutUpgradeSessionMutation,
  useConfirmStripeCheckoutUpgradeSessionMutation,
} = usersApi;

import { useState } from "react";
import cs from "classnames";

import { Typography } from "@braintrust/braintrust-ui-components";
import { getTruncatedText } from "@js/utils";

import style from "./style.module.scss";

type ReviewCardDescriptionProps = {
  description?: string;
};

export const ReviewCardDescription = ({
  description,
}: ReviewCardDescriptionProps) => {
  const _description = description || "";
  const [showFull, setShowFull] = useState(false);
  const isDescriptionShort = _description.length <= 170;
  const truncatedReviewDescription = getTruncatedText(_description);

  if (isDescriptionShort) {
    return (
      <Typography
        mt={2}
        component="pre"
        size="medium"
        sx={{
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {description}
      </Typography>
    );
  }

  return (
    <Typography
      mt={2}
      component="pre"
      size="medium"
      sx={{
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
      }}
    >
      {showFull ? description : `${truncatedReviewDescription} · `}
      <button
        className={cs("btn-reset pointer text-underline", {
          [style.expanded]: showFull,
        })}
        onClick={() => setShowFull((prevState) => !prevState)}
      >
        {showFull ? "Read less" : "Read more"}
      </button>
    </Typography>
  );
};

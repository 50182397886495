import { Box, Button } from "@braintrust/braintrust-ui-components";
import { KeyboardArrowRightIcon } from "@braintrust/braintrust-ui-components/Icons";
import { SearchListingHeader } from "@js/apps/common/components";
import { SearchViewHeaderContainer } from "@js/apps/common/components/search-view-header-container";
import {
  PostsFilters,
  PostsLoadingState,
} from "@js/apps/give-and-get-help/components";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { AppLayout } from "@js/layouts/app";

import { VirtualizedPostsSection } from "../../components/virtualized-posts-section";
import { useSearchPosts } from "../../hooks/use-search-posts";

import styles from "./styles.module.scss";

export const SearchPosts = () => {
  const {
    posts,
    hasMore,
    handleFetchingMore,
    loading,
    fetchingPosts,
    searchTerm,
    postsCount,
    handleCtaClick,
  } = useSearchPosts();
  const postLocationValue = PostLocation.Values.post_search;

  return (
    <UniversalSearchContextProvider initialTab="CAREER_HELP">
      <PostLocation.Provider value={postLocationValue}>
        <AppLayout pageTitle="Search jobs" bgcolor={`var(--white)`}>
          <Box className={styles.postsListingPage}>
            <Header searchTerm={searchTerm} handleCtaClick={handleCtaClick} />
            {loading ? (
              <PostsLoadingState />
            ) : (
              <>
                <PostsFilters postsLength={postsCount} />
                <VirtualizedPostsSection
                  posts={posts}
                  hasMore={hasMore}
                  fetchMore={handleFetchingMore}
                  fetchingPosts={fetchingPosts}
                />
              </>
            )}
          </Box>
        </AppLayout>
      </PostLocation.Provider>
    </UniversalSearchContextProvider>
  );
};

type HeaderProps = { searchTerm?: string } & CtaButtonsProps;

const Header = ({ searchTerm, handleCtaClick }: HeaderProps) => {
  const headerText = searchTerm
    ? `Post results for "${searchTerm}"`
    : "Post results";

  return (
    <SearchViewHeaderContainer>
      <SearchListingHeader hideUnderline size="medium">
        {headerText}
      </SearchListingHeader>
      <CtaButtons handleCtaClick={handleCtaClick} />
    </SearchViewHeaderContainer>
  );
};

type CtaButtonsProps = { handleCtaClick: () => void };

const CtaButtons = ({ handleCtaClick }: CtaButtonsProps) => {
  return (
    <Box display="flex" flexShrink={0}>
      <Button
        variant="secondary"
        endIcon={<KeyboardArrowRightIcon />}
        onClick={handleCtaClick}
      >
        Go to Career Help
      </Button>
    </Box>
  );
};

import type { MouseEvent } from "react";
import React from "react";

import {
  Box,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import { PersonAddIcon } from "@braintrust/braintrust-ui-components/Icons";

import { CommonTeamMembersModalListItemContainer } from "./common-team-members-modal-list-item-container";

export type CommonTeamMembersModalInvitationItemProps = {
  invitationEmail: string;
  onRemove: () => void;
};

export const CommonTeamMembersModalInvitationItem = ({
  invitationEmail,
  onRemove,
}: CommonTeamMembersModalInvitationItemProps) => {
  const isMobile = useMediaQuery("sm");
  const onRemoveClick = (ev: MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();

    onRemove();
  };

  return (
    <CommonTeamMembersModalListItemContainer sx={{ gap: { xs: 1, sm: 2 } }}>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          backgroundColor: "var(--soft-violet)",
          borderRadius: "50%",
          height: "100%",
          aspectRatio: "1/1",
        }}
      >
        <PersonAddIcon sx={{ fontSize: "23px" }} />
      </Box>
      <Typography
        variant="label"
        component="p"
        size={isMobile ? "small" : "medium"}
        ellipsis
      >
        {invitationEmail}
      </Typography>
      <Typography
        component="a"
        role="button"
        href="#"
        variant="link"
        size="small"
        color="grey-1"
        sx={{ ml: "auto", cursor: "pointer", whiteSpace: "nowrap" }}
        onClick={onRemoveClick}
      >
        Remove
      </Typography>
    </CommonTeamMembersModalListItemContainer>
  );
};

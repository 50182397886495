import React from "react";

import {
  Box,
  Button,
  IconButton,
  Menu,
  RoundedBox,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { MoreVertIcon } from "@braintrust/braintrust-ui-components/Icons";
import { LoginAs } from "@js/apps/common/components";
import { useAccountType, useIsNodeStaff } from "@js/apps/common/hooks";
import { DefaultActionBar } from "@js/apps/jobs/components/job-details-action-bar/default-action-bar";
import { ReviewModal } from "@js/apps/reviews/components/review-modal";
import { openWriteReviewModal } from "@js/apps/reviews/components/review-modal/write-review-modal-instance";
import { ReviewsSummaryPanel } from "@js/apps/reviews/components/reviews-summary-panel";
import { useAutomaticallyOpenWriteReviewModal } from "@js/apps/reviews/hooks/automatically-open-review-modal";
import { showWriteReviewFormAfterOpen } from "@js/apps/reviews/slice";
import { RouterLink } from "@js/components/link";
import { useAppDispatch, useQueryParams } from "@js/hooks";
import type { EmployerPublicProfile } from "@js/types/employer";

import { EmployerWebsiteUrl } from "../../components";
import { openAssignOwnersModal } from "../../components/assign-owners-modal";

import { FreelancerTopBar } from "./freelancer-top-bar";

type TopBarProps = {
  isProfileOwner: boolean;
  employer: EmployerPublicProfile;
};

export const TopBar = ({ isProfileOwner, employer }: TopBarProps) => {
  const isNodeStaff = useIsNodeStaff();
  const { isFreelancer } = useAccountType();
  const query = useQueryParams();
  const loginAsEmployerUserId = employer.login_as_user_id;

  if (isNodeStaff && loginAsEmployerUserId) {
    return (
      <DefaultActionBar
        bgcolor="var(--dark-blue)"
        title="Company information"
        subtitle={`${employer.open_jobs_count} Open Jobs`}
        actions={[
          <LoginAs
            key="login-as"
            variant="primary"
            as={loginAsEmployerUserId}
            inverse
          />,
          <Menu
            key="extra-actions"
            anchor={
              <IconButton
                size="medium"
                aria-label="open actions menu"
                variant="transparent"
                inverse
              >
                <MoreVertIcon style={{ width: 22.5, height: 22.5 }} />
              </IconButton>
            }
          >
            <Menu.Item onClick={() => openAssignOwnersModal({ employer })}>
              Add Owners
            </Menu.Item>
          </Menu>,
        ]}
      />
    );
  }

  if (isProfileOwner) {
    return (
      <DefaultActionBar
        bgcolor="var(--dark-blue)"
        title="Company information"
        subtitle={`${employer.open_jobs_count} Open Jobs`}
        actions={[
          <Button
            key="default-action-button"
            variant="primary"
            inverse
            to="/employer/edit/organization_info"
            RouterLink={RouterLink}
          >
            Edit company details
          </Button>,
        ]}
      />
    );
  }

  if (isFreelancer && !!query["job"]) {
    return <FreelancerTopBar jobId={query["job"]} />;
  }

  return null;
};

type BasicInfoProps = Pick<
  EmployerPublicProfile,
  "website" | "industry" | "location"
>;

export const BasicInfo = ({ website, industry, location }: BasicInfoProps) => {
  return (
    <RoundedBox p={2} maxHeight={400} height="auto">
      <h2 className="sr-only">Basic info</h2>
      {website && (
        <>
          <h3 className="sr-only">Website:</h3>
          <EmployerWebsiteUrl
            url={website}
            maxLength={35}
            fontWeight={500}
            size="small"
          />
        </>
      )}
      {industry && (
        <>
          <h3 className="sr-only">Industry: </h3>
          <Typography variant="label" component="p" size="small">
            {industry.name}
          </Typography>
        </>
      )}
      {location && (
        <>
          <h3 className="sr-only">Location: </h3>
          <Typography variant="label" component="p" size="small">
            {location}
          </Typography>
        </>
      )}
    </RoundedBox>
  );
};

type JobsInfoProps = {
  label: string;
  value: string | number;
};

export const JobsInfo = ({ label, value }: JobsInfoProps) => {
  return (
    <RoundedBox p={2} maxHeight={400} height="auto">
      <Typography component="h3" variant="label" size="small" mb={1}>
        {label}
      </Typography>
      <Typography component="p" size="large">
        {value}
      </Typography>
    </RoundedBox>
  );
};

type ReviewsProps = {
  employer: EmployerPublicProfile;
};

export const EmployerReviews = ({ employer }: ReviewsProps) => {
  const dispatch = useAppDispatch();
  useAutomaticallyOpenWriteReviewModal({ profile: employer });

  const canOpenReviewModal = !!(
    employer.can_user_review_job || employer.review_count
  );
  const shouldShowReviewsSection =
    employer.can_user_review_job || !!employer.review_count;

  const openReviewModal = () => canOpenReviewModal && openWriteReviewModal();
  const onWriteReviewClick = () => {
    dispatch(showWriteReviewFormAfterOpen(true));
    if (canOpenReviewModal) {
      openWriteReviewModal();
    }
  };

  return (
    <>
      {shouldShowReviewsSection && (
        <RoundedBox p={3} component="section">
          <Typography component="h2" variant="label">
            Reviews
          </Typography>

          <Box
            display="flex"
            gap="32px"
            alignItems="center"
            flexWrap="wrap"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
          >
            {employer.review_count > 0 && (
              <Box mt={2}>
                <ReviewsSummaryPanel
                  display="flex"
                  flexWrap="wrap"
                  openReviewModal={openReviewModal}
                  subjectIsEmployer={true}
                  profile={employer}
                  canOpenReviewModal={canOpenReviewModal}
                />
              </Box>
            )}
            <Box
              flexShrink={0}
              mt={2}
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={1}
            >
              {employer.review_count > 0 && (
                <Button variant="primary" onClick={openReviewModal}>
                  Read All Reviews
                </Button>
              )}
              {employer.can_user_review_job && (
                <Button variant="secondary" onClick={onWriteReviewClick}>
                  Leave a review
                </Button>
              )}
            </Box>
          </Box>
        </RoundedBox>
      )}

      <ReviewModal
        profile={employer}
        reviewSubject={ENUMS.AccountType.EMPLOYER}
      />
    </>
  );
};

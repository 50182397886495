import React from "react";

import {
  Box,
  Grid,
  IconButton,
  Typography,
  VerticalSeparator,
} from "@braintrust/braintrust-ui-components";
import {
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  CloseIcon,
  EditPenIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { Sanitize } from "@js/components/sanitize";

type PaymentsPanelItemProps = {
  header: React.ReactNode;
  subheader?: Array<string | JSX.Element> | string;
  avatar?: React.ReactNode;
  onDelete?: () => void;
  onEdit?: () => void;
  borderBottom: boolean;
  arrowDownDisabled?: boolean;
  arrowUpDisabled?: boolean;
  onArrowDownClick?: () => void;
  onArrowUpClick?: () => void;
  description?: React.ReactNode;
  renderButtons?: (buttons: Array<React.ReactNode>) => Array<React.ReactNode>;
};

export const PaymentsPanelItem = ({
  header,
  subheader,
  avatar,
  onDelete,
  onEdit,
  borderBottom,
  arrowDownDisabled,
  arrowUpDisabled,
  onArrowDownClick,
  onArrowUpClick,
  description,
  renderButtons,
}: PaymentsPanelItemProps): JSX.Element => {
  const defaultButtons = [
    onArrowDownClick && (
      <IconButton
        key="move-down-button"
        aria-label="move down"
        onClick={onArrowDownClick}
        variant="tertiary"
        size="small"
        disabled={arrowDownDisabled}
      >
        <ArrowDownwardIcon />
      </IconButton>
    ),
    onArrowUpClick && (
      <IconButton
        key="move-up-button"
        aria-label="move up"
        onClick={onArrowUpClick}
        variant="tertiary"
        size="small"
        disabled={arrowUpDisabled}
      >
        <ArrowUpwardIcon />
      </IconButton>
    ),
    onEdit && (
      <IconButton
        key="edit-button"
        aria-label="edit"
        onClick={onEdit}
        variant="tertiary"
        size="small"
      >
        <EditPenIcon />
      </IconButton>
    ),
    onDelete && (
      <IconButton
        key="delete-button"
        aria-label="delete"
        onClick={onDelete}
        variant="tertiary"
        size="small"
      >
        <CloseIcon />
      </IconButton>
    ),
  ].filter(Boolean);

  const buttons = renderButtons
    ? renderButtons(defaultButtons)
    : defaultButtons;

  return (
    <Box
      component="article"
      width={1}
      pt={2}
      pb={2}
      style={{ wordBreak: "break-word" }}
      borderTop="1px solid var(--black)"
      borderBottom={borderBottom ? "1px solid var(--black)" : undefined}
    >
      <Grid container wrap="nowrap">
        {avatar && <Box mr={2}>{avatar}</Box>}
        <Box flexGrow={1} component="header" width={1}>
          <Typography
            size="large"
            component="h3"
            style={{ wordBreak: "break-word" }}
            variant="paragraph"
          >
            {header}
          </Typography>
          <Typography
            size="small"
            component="h4"
            variant="paragraph"
            color="secondary"
            style={{
              display: "inline",
              wordBreak: "break-word",
            }}
          >
            {Array.isArray(subheader)
              ? subheader.map((subheaderItem, index) => (
                  <React.Fragment
                    key={
                      typeof subheaderItem === "string"
                        ? subheaderItem
                        : subheaderItem.key
                    }
                  >
                    {index !== 0 && (
                      <VerticalSeparator
                        height={24}
                        sx={{ verticalAlign: "middle" }}
                      />
                    )}
                    {subheaderItem}
                  </React.Fragment>
                ))
              : subheader}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexWrap={{ xs: "wrap", sm: "nowrap", lg: "nowrap", md: "wrap" }}
          justifyContent="flex-end"
          gap={{ xs: 1, sm: 0, lg: 0, md: 1 }}
          alignItems="flex-start"
        >
          {buttons.map((button, index) => {
            if (index === buttons.length - 1 && React.isValidElement(button)) {
              return <React.Fragment key={button.key}>{button}</React.Fragment>;
            } else {
              return (
                button &&
                React.isValidElement(button) && (
                  <Box key={button.key} mr={0.5}>
                    {button}
                  </Box>
                )
              );
            }
          })}
        </Box>
      </Grid>
      {description && (
        <Box mt={4}>
          <Typography component="p">
            {typeof description === "string" ? (
              <Sanitize input={description} className="bw" />
            ) : (
              description
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

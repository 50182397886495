import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { CheckIcon } from "@braintrust/braintrust-ui-components/Icons";
import { getEnumLabel } from "@js/utils";

export const useOptionTooltip = (
  availabilityOptions?: EnumType<typeof ENUMS.FreelancerAvailabilityForWork>[],
) => {
  if (!availabilityOptions) {
    return null;
  }

  const getOptionLabel = (
    option: EnumType<typeof ENUMS.FreelancerAvailabilityForWork>,
  ) => {
    const enumLabel = getEnumLabel(
      ENUMS.FreelancerAvailabilityForWorkLabels,
      option,
    );

    if (enumLabel === ENUMS.FreelancerAvailabilityForWorkLabels.direct_hire) {
      return "Employee roles";
    }

    return enumLabel;
  };

  const tooltipTitle = availabilityOptions.map((option) => {
    const label = getOptionLabel(option);

    return (
      <Box key={option} display="flex" alignItems="center" gap="6px">
        <CheckIcon style={{ width: "16px", height: "16px" }} />
        <Typography component="p" variant="label" fontWeight={500}>
          {label}
        </Typography>
      </Box>
    );
  });

  return tooltipTitle;
};

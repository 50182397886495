import { Button, Typography } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

export const WriteReviewSuccessModal = () => {
  return (
    <div>
      <Typography variant="title" size="small" component="h2">
        Thank you
      </Typography>
      <Typography
        variant="paragraph"
        size="medium"
        component="p"
        fontWeight={400}
        mb={2}
        mt={2}
      >
        Thank you for submitting a review. Due to the double-blind review
        process it will display after both parties evaluate each other.
      </Typography>
      <div className="buttons right">
        <Button variant="transparent" onClick={closeWriteReviewSuccessModal}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export const openWriteReviewSuccessModal = () => {
  ModalInstance.open({
    children: <WriteReviewSuccessModal />,
    className: styles.modal,
    closeButton: false,
  });
};

export const closeWriteReviewSuccessModal = () => {
  ModalInstance.close();
};

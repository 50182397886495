import {
  IconButton,
  ListItemIcon,
  Menu,
} from "@braintrust/braintrust-ui-components";
import {
  FlagIcon,
  MoreVertIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useReport } from "@js/apps/common/hooks/use-report";

import styles from "./styles.module.scss";

type TextMessageActionMenuProps = {
  messageId: number;
  showMoreButton?: boolean;
  setShowMoreButton: (value: boolean) => void;
  isOwner?: boolean;
  roomId: number;
  messageAuthorName: string;
};

export const TextMessageActionMenu = ({
  messageId,
  showMoreButton,
  setShowMoreButton,
  isOwner,
  roomId,
  messageAuthorName,
}: TextMessageActionMenuProps) => {
  const { handleReportMessage } = useReport();

  const report = handleReportMessage({
    id: messageId,
    type: "message",
    room: roomId,
    name: messageAuthorName,
  });

  const showMenuAnchor = !isOwner && showMoreButton;

  if (!showMenuAnchor) {
    return null;
  }

  return (
    <Menu
      id={`message-action-menu-${messageId}`}
      anchor={
        <IconButton
          className={styles.contentMore}
          aria-label="more menu"
          data-testid="more-menu"
          variant="white-grey"
          size="x-small"
          shape="squared"
        >
          <MoreVertIcon />
        </IconButton>
      }
      onClose={() => setShowMoreButton(false)}
    >
      <Menu.Item onClick={report} sx={{ color: "var(--negative-1)" }}>
        <ListItemIcon sx={{ color: "var(--negative-1)" }}>
          <FlagIcon />
        </ListItemIcon>
        Report message
      </Menu.Item>
    </Menu>
  );
};

import { useCallback, useMemo } from "react";

import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { Box, Button, Tooltip } from "@braintrust/braintrust-ui-components";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useBulkSelectActions } from "../../hooks/use-bulk-select-actions";

import { BidSelectionCheckbox } from "./bid-selection-checkbox";

const DEFAULT_DISABLED_ACTION_TOOLTIP_TEXT =
  "This action can't be performed on any of the selected applications.";

type BulkSelectActionsProps = {
  job: Job;
  bidList: EmployerBidListBid[];
  isShowingHiddenBids: boolean;
};

export const BulkSelectActions = ({
  job,
  bidList,
  isShowingHiddenBids,
}: BulkSelectActionsProps) => {
  const {
    bulkHideBids,
    bulkSendInterviewRequest,
    bulkRejectOffer,
    handleShareBidsClick,
    selectedBidsTotal,
    isAnyOfSelectedBidsUnapproved,
    canSendInterviewRequest,
    canSendRejection,
    areAllSelectedBidsHidden,
    canShareBidsWithTeamMembers,
  } = useBulkSelectActions(job, bidList);

  const shouldRender = selectedBidsTotal > 0;
  const shouldShowUnhideAction = useMemo(
    () => isShowingHiddenBids || areAllSelectedBidsHidden,
    [areAllSelectedBidsHidden, isShowingHiddenBids],
  );

  const getSendInterviewRequestTitle = useCallback(() => {
    if (!canSendInterviewRequest) {
      return DEFAULT_DISABLED_ACTION_TOOLTIP_TEXT;
    }
    if (isAnyOfSelectedBidsUnapproved && job.job_type !== ENUMS.JobType.GRANT) {
      return "One or more of the selected Talent are unapproved. Please de-select or approve Talent to proceed.";
    }
  }, [canSendInterviewRequest, isAnyOfSelectedBidsUnapproved, job.job_type]);

  if (!shouldRender) {
    return null;
  }

  const sendRejectionTitle = !canSendRejection
    ? DEFAULT_DISABLED_ACTION_TOOLTIP_TEXT
    : undefined;

  const sendInterviewRequestTitle = getSendInterviewRequestTitle();

  const isInterviewRequestButtonDisabled =
    !canSendInterviewRequest ||
    (job.job_type !== ENUMS.JobType.GRANT && isAnyOfSelectedBidsUnapproved);

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" rowGap={2.5}>
      <BidSelectionCheckbox />
      <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
        <ActionButton
          onClick={bulkSendInterviewRequest}
          title={sendInterviewRequestTitle}
          disabled={isInterviewRequestButtonDisabled}
        >
          Send interview request
        </ActionButton>

        <ActionButton
          onClick={bulkRejectOffer}
          title={sendRejectionTitle}
          disabled={!canSendRejection}
        >
          Send rejection
        </ActionButton>

        <ActionButton onClick={() => bulkHideBids(shouldShowUnhideAction)}>
          {shouldShowUnhideAction ? "Unhide" : "Hide 👎"}
        </ActionButton>

        {canShareBidsWithTeamMembers && (
          <ActionButton onClick={handleShareBidsClick}>Share</ActionButton>
        )}
      </Box>
    </Box>
  );
};

type ActionButtonProps = Omit<
  ButtonProps,
  "variant" | "shape" | "size" | "href" | "to"
> & { title?: string };

const ActionButton = ({ children, title, ...rest }: ActionButtonProps) => {
  const buttonProps: ButtonProps = {
    variant: "white-border-beige",
    shape: "squared",
    size: "medium",
    ...rest,
  };
  if (title) {
    return (
      <Tooltip title={title}>
        <span>
          <Button {...buttonProps}>{children}</Button>
        </span>
      </Tooltip>
    );
  }
  return <Button {...buttonProps}>{children}</Button>;
};

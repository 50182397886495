import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Button } from "@braintrust/braintrust-ui-components";
import { AddIcon } from "@braintrust/braintrust-ui-components/Icons";

import { useInitialInvoiceItemPrice } from "./use-initial-invoice-item-price";

type AddInvoiceItemButtonProps = {
  onClick: (initialInvoiceItemPrice: number | null) => void;
};

export const AddInvoiceItemButton = (props: AddInvoiceItemButtonProps) => {
  return (
    <Field name="job" component={AddInvoiceItemButtonComponent} {...props} />
  );
};

type AddInvoiceItemButtonComponentProps = TypedWrappedFieldProps<
  number | null
> &
  AddInvoiceItemButtonProps;

const AddInvoiceItemButtonComponent = ({
  input,
  onClick,
}: AddInvoiceItemButtonComponentProps) => {
  const jobId = input.value;
  const initialInvoiceItemPrice = useInitialInvoiceItemPrice({ jobId });
  const handleAddItemClick = () => {
    onClick(initialInvoiceItemPrice);
  };

  return (
    <Button
      variant="transparent"
      startIcon={<AddIcon />}
      onClick={handleAddItemClick}
    >
      Add Item
    </Button>
  );
};

import { Box, Loader } from "@braintrust/braintrust-ui-components";

type TooltipContentProps = {
  hideTotalApplicants: boolean;
  totalBidsCount?: number;
  isLoading: boolean;
};

const TooltipContent: React.FC<TooltipContentProps> = ({
  hideTotalApplicants,
  totalBidsCount,
  isLoading,
}) => {
  const defaultLabel = "You've boosted this application.";

  if (hideTotalApplicants) {
    return defaultLabel;
  }

  const applicantsCount = totalBidsCount || 0;
  const firstLabel = `Your application is now at the top${applicantsCount < 10 ? "." : ` of ${applicantsCount} applicants.`}`;

  return isLoading ? (
    <Box display="inline-block">
      <Loader color="inherit" size={12} />
    </Box>
  ) : (
    `${firstLabel} This action cannot be undone.`
  );
};

export default TooltipContent;

import type { Action } from "redux";

import type { ReferralActionTypes } from "@js/apps/dashboard/actions";
import type { GghActionTypes } from "@js/apps/give-and-get-help/action-types";
import type { HashtagActionTypes } from "@js/apps/give-and-get-help/actions";
import type {
  EmployerInviteActionTypes,
  EmployerOnboardingActionTypes,
  OnboardingChooseAccountType,
  OnboardingNextStepClicked,
} from "@js/apps/onboarding/actions";

type AllowedActionTypes =
  | GghActionTypes
  | HashtagActionTypes
  | OnboardingNextStepClicked
  | OnboardingChooseAccountType
  | EmployerOnboardingActionTypes
  | EmployerInviteActionTypes
  | ReferralActionTypes
  | (string & Record<string, never>);
export type TrackableUserAction = Action<AllowedActionTypes> & { payload: any };

export const isTrackableUserAction = (
  action: unknown,
): action is TrackableUserAction => {
  if (typeof action !== "object" || action === null) {
    return false;
  }

  const { type } = action as TrackableUserAction;

  // Add any additional checks based on your requirements
  return typeof type === "string";
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import {
  CheckIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { Job } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import { useRequirementsIndicator } from "./use-requirements-indicator";

export type RequirementsIndicatorProps = {
  locationRequirementsMet: boolean | null;
  timezoneRequirementsMet: boolean | null;
  job: Job;
};

export const RequirementsIndicator = ({
  locationRequirementsMet,
  timezoneRequirementsMet,
  job,
}: RequirementsIndicatorProps) => {
  const {
    multipleLocationsRequired,
    multipleTimezonesRequired,
    displayLocationRequirement,
    displayTimezoneRequirement,
  } = useRequirementsIndicator({
    locationRequirementsMet,
    timezoneRequirementsMet,
    job,
  });

  if (!displayLocationRequirement && !displayTimezoneRequirement) {
    return null;
  }

  return (
    <>
      <Typography component="p" variant="paragraph" size="large" mb={2}>
        Requirements
      </Typography>
      <Box display="flex" flexDirection="column" gap={1} mb={6}>
        {displayLocationRequirement && (
          <RequirementIndicator
            type="location"
            multipleLocationsRequired={multipleLocationsRequired}
            requirementMet={!!locationRequirementsMet}
          />
        )}
        {displayTimezoneRequirement && (
          <RequirementIndicator
            type="timezone"
            multipleTimezonesRequired={multipleTimezonesRequired}
            requirementMet={!!timezoneRequirementsMet}
          />
        )}
      </Box>
    </>
  );
};

type RequirementIndicatorProps =
  | {
      type: "location";
      multipleLocationsRequired: boolean;
      requirementMet: boolean;
    }
  | {
      type: "timezone";
      multipleTimezonesRequired: boolean;
      requirementMet: boolean;
    };

const RequirementIndicator = (props: RequirementIndicatorProps) => {
  const icon = props.requirementMet ? (
    <CheckIcon sx={{ fontSize: 16, color: "var(--positive-1)" }} />
  ) : (
    <CloseIcon sx={{ fontSize: 16, color: "var(--negative-2)" }} />
  );

  const content = getRequirementIndicatorContent(props);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      data-testid="requirement-indicator"
    >
      {icon}
      <Typography
        size="medium"
        variant="label"
        component="span"
        fontWeight={500}
      >
        {content}
      </Typography>
    </Box>
  );
};

const getRequirementIndicatorContent = (props: RequirementIndicatorProps) => {
  const { type } = props;

  switch (type) {
    case "location": {
      const location = props.multipleLocationsRequired
        ? "locations"
        : "location";

      return props.requirementMet
        ? `Talent states they can work in the required ${location}`
        : `Talent cannot work in the required ${location}`;
    }
    case "timezone": {
      const timezone = props.multipleTimezonesRequired
        ? "timezones"
        : "timezone";

      return props.requirementMet
        ? `Talent states they can work in the required ${timezone}`
        : `Talent cannot work in the required ${timezone}`;
    }
    default: {
      return assertUnreachable(type) as never;
    }
  }
};

import { useState } from "react";
import { change, clearFields, Field, SubmissionError } from "redux-form";

import {
  Alert,
  Box,
  Button,
  IconBackground,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { OTPAuthCodeField } from "@js/apps/common/forms/fields/otp/otp-auth-code";
import { RouterLink } from "@js/components/link";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { CheckboxField } from "@js/forms/fields/checkbox";
import { useAppDispatch } from "@js/hooks";

import type { WithdrawBalancePayload } from "../../api";
import { useWithdrawBalanceMutation } from "../../api";

import {
  WITHDRAWAL_FORM_ID,
  WithdrawalBTRSTFormContent,
  WithdrawalBTRSTFormInstance,
} from "./form";
import { WithdrawalModalInstance } from "./modal-instance";

import style from "./style.module.scss";

export const WithdrawalModalContent = () => {
  const [show2FA, setShow2FA] = useState(false);
  const [withdrawBalance] = useWithdrawBalanceMutation();
  const dispatch = useAppDispatch();

  const onSubmit = async (values: WithdrawBalancePayload) => {
    if (!values.code) {
      dispatch(clearFields(WITHDRAWAL_FORM_ID, false, false, "is_backup_code"));
    }

    return withdrawBalance(values)
      .unwrap()
      .then(() => {
        if (values.code) {
          WithdrawalModalInstance.close();
          openFinalizeWithdrawalModal();
        } else {
          setShow2FA(true);
        }
      })
      .catch((error) => {
        throw new SubmissionError(error?.data);
      });
  };

  const openFinalizeWithdrawalModal = () =>
    ModalInstance.open({
      className: "max-width-500",
      children: (
        <>
          <ModalConfirm onConfirm={ModalInstance.close} confirmText="Close">
            <Typography component="h4" variant="title" fontWeight={400} mb={2}>
              To continue, click the link in the verification email sent to you.
            </Typography>
            <Typography component="p" mb={2}>
              For security reasons, you need to verify your transfer via email.
              You need to click the link in the email sent to you, then finalize
              the transfer back on Braintrust.
              <strong>
                {" "}
                If you skip this step, your tokens will not be transferred.
              </strong>
            </Typography>
            <Typography component="p" mb={4}>
              The link expires in 30 minutes.
            </Typography>
          </ModalConfirm>
        </>
      ),
    });

  return (
    <WithdrawalBTRSTFormInstance onSubmit={onSubmit}>
      {show2FA ? (
        <>
          <Typography component="h4" variant="title" fontWeight={400} mb={2}>
            2-step authentication
          </Typography>
          <Typography component="p" mb={4}>
            Enter the 6-digit code generated by your authenticator app.
          </Typography>
          <OTPAuthCodeField name="code" />
          <Field
            name="is_backup_code"
            component={CheckboxField}
            label="Use backup code instead"
          />
          <Box mt={4}>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => {
                dispatch(change(WITHDRAWAL_FORM_ID, "code", undefined));
                setShow2FA(false);
              }}
            >
              Back
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography
            component="h2"
            variant="title"
            fontWeight={400}
            size="medium"
            pt={4}
            pb={4}
          >
            Transfer BTRST tokens to a wallet on the Base network
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            <Alert
              className={style.modalAlert}
              type="warning-dark"
              alignIcon="center"
              rounded
              withIcon
              customIcon={
                <IconBackground
                  size="large"
                  backgroundColor="var(--soft-orange)"
                  sx={{ fontSize: "1.25rem" }}
                >
                  🚨
                </IconBackground>
              }
            >
              <Typography component="p" variant="paragraph" size="medium">
                Your wallet is now on the Base blockchain, so you must choose a{" "}
                <Typography
                  component="link"
                  href={SETTINGS.SUPPORT_URLS.HOW_TO_WITHDRAW_TOKEN_ARTICLE_URL}
                  target="_blank"
                  RouterLink={RouterLink}
                >
                  Base-compatible wallet
                </Typography>
                .
              </Typography>
            </Alert>
            <Alert
              className={style.modalAlert}
              type="warning-dark"
              alignIcon="center"
              rounded
              withIcon
              customIcon={
                <IconBackground
                  size="large"
                  backgroundColor="var(--soft-orange)"
                  sx={{ fontSize: "1.25rem" }}
                >
                  ⏰
                </IconBackground>
              }
            >
              <Typography component="p" variant="paragraph" size="medium">
                Transfers may take up to 24 hours to process.
              </Typography>
            </Alert>
          </Box>
          <WithdrawalBTRSTFormContent
            onCancel={WithdrawalModalInstance.close}
          />
        </>
      )}
    </WithdrawalBTRSTFormInstance>
  );
};

import React from "react";
import { Field, reduxForm } from "redux-form";

import { Button, Grid } from "@braintrust/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import { TextField } from "@js/forms/fields";

const VERIFY_STRIPE_ACH_FORM_ID = "verify-stripe-ach-payment-method";

export type VerifyStripeACHFormData = {
  amount_1: string;
  amount_2: string;
};

type VerifyStripeACHFormProps = {
  onCancel: () => void;
};

export const VerifyStripeACHForm = reduxForm<
  VerifyStripeACHFormData,
  VerifyStripeACHFormProps
>({
  form: VERIFY_STRIPE_ACH_FORM_ID,
  enableReinitialize: true,
  initialValues: {
    amount_1: "0",
    amount_2: "0",
  },
})(({ submit, error, submitting, onCancel }) => (
  <Form onSubmit={submitting ? null : submit} error={error}>
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Field
          variant="standard"
          id="amount_1"
          name="amount_1"
          component={TextField}
          InputProps={{
            startAdornment: "$",
          }}
          label="Amount 1"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          variant="standard"
          id="amount_2"
          name="amount_2"
          component={TextField}
          InputProps={{
            startAdornment: "$",
          }}
          label="Amount 2"
          size="small"
        />
      </Grid>
    </Grid>
    <Grid className="buttons right mt+">
      <Button variant="primary" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="primary" disabled={submitting} type="submit">
        Verify
      </Button>
    </Grid>
  </Form>
));

import { useEffect } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { DoubleDatePopoverPickerField } from "@js/forms/fields";
import { dateDifference } from "@js/utils";

import { ProjectDurationTileButton } from "./project-duration-tile-button";

type ProjectDurationFieldProps = {
  deadline: TypedWrappedFieldProps<string>;
  contract_type: TypedWrappedFieldProps<string>;
  start_date: TypedWrappedFieldProps<string>;
  isEditForm: boolean;
};

export const ProjectDurationField = ({
  contract_type: contractType,
  deadline,
  start_date,
  isEditForm,
}: ProjectDurationFieldProps) => {
  const setContractType = contractType.input.onChange;
  const contractTypeValue = contractType.input.value;
  const { error: contractTypeError, touched: contractTypeTouched } =
    contractType.meta;
  const { error: deadlineError } = deadline.meta;
  const { error: startDateError } = start_date.meta;

  useEffect(() => {
    const value = deadline.input.value;
    if (value) {
      const isLongTerm = dateDifference(value, undefined, "M") >= 6;
      setContractType(
        isLongTerm ? ENUMS.JobContractType.LONG : ENUMS.JobContractType.SHORT,
      );
    }
  }, [deadline.input.value, setContractType]);

  const error = startDateError || deadlineError;

  return (
    <Box>
      <Box mb={2.25}>
        <Typography component="label">
          How long will the Talent work on this job?
        </Typography>
      </Box>
      {contractTypeError && contractTypeTouched && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {contractTypeError}
        </Typography>
      )}

      <ProjectDurationTileButton
        value={contractTypeValue}
        options={options}
        onChange={(contract) => setContractType(contract as string)}
        itemWidth={180}
      />

      <Box mt={3}>
        <DoubleDatePopoverPickerField
          id="start-date-and-deadline"
          names={["start_date", "deadline"]}
          start_date={start_date}
          deadline={deadline}
          disablePast={!isEditForm}
          hideError
        />

        {!!error && (
          <Typography
            my={2}
            component="p"
            variant="paragraph"
            color="negative"
            className="error-section"
          >
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

type TileLabelProps = {
  header: string;
  subtext: string;
};

const TileLabel = ({ header, subtext }: TileLabelProps) => {
  return (
    <>
      <Typography component="p" variant="label" size="large">
        {header}
      </Typography>
      <Typography component="p" variant="paragraph" size="small">
        {subtext}
      </Typography>
    </>
  );
};

const options = [
  {
    label: (
      <TileLabel
        header={ENUMS.JobContractTypeLabels.short}
        subtext="1-6 months"
      />
    ),
    value: ENUMS.JobContractType.SHORT,
    text: "⚡️",
  },
  {
    label: (
      <TileLabel
        header={ENUMS.JobContractTypeLabels.long}
        subtext="6 months or more"
      />
    ),
    value: ENUMS.JobContractType.LONG,
    text: "⏳️",
  },
];

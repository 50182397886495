import React from "react";
import type { WrappedFieldsProps } from "redux-form";
import { Fields } from "redux-form";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { getError, isError, isTouched } from "@js/forms/utils";

import { InfoTag } from "../info-tag";

import { AmountField } from "./amount-field";
import { RATE_FIELDS_KEYS } from "./constants";
import { getFieldsToCheckMeta } from "./helpers";
import { PaymentTypeField } from "./payment-type-field";
import { rateValidator } from "./validators";

export const RateSectionModule = () => {
  return (
    <Fields
      id="payment_type"
      names={[
        RATE_FIELDS_KEYS.hourly_rate,
        RATE_FIELDS_KEYS.payment_type,
        RATE_FIELDS_KEYS.fixed_rate,
        RATE_FIELDS_KEYS.min_rate,
        RATE_FIELDS_KEYS.max_rate,
        RATE_FIELDS_KEYS.min_annual_rate,
        RATE_FIELDS_KEYS.max_annual_rate,
        RATE_FIELDS_KEYS.job_type,
        RATE_FIELDS_KEYS.ai_min_rate,
        RATE_FIELDS_KEYS.ai_max_rate,
        RATE_FIELDS_KEYS.custom_min_rate,
        RATE_FIELDS_KEYS.custom_max_rate,
        "is_ai_generated",
      ]}
      validate={[rateValidator]}
      component={Rate}
    />
  );
};

const Rate = ({
  job_type,
  payment_type,
  fixed_rate,
  min_rate,
  max_rate,
  min_annual_rate,
  max_annual_rate,
  hourly_rate,
  is_ai_generated,
  ai_min_rate,
  ai_max_rate,
  custom_min_rate,
  custom_max_rate,
}: WrappedFieldsProps) => {
  const selectedPaymentType: EnumType<typeof ENUMS.JobPaymentType> =
    payment_type.input.value;
  const fields = getFieldsToCheckMeta(selectedPaymentType, [
    fixed_rate,
    min_rate,
    max_rate,
    custom_min_rate,
    custom_max_rate,
    min_annual_rate,
    max_annual_rate,
    hourly_rate,
    payment_type,
  ]);

  const isErrorInSection = isError(fields);
  const isAnyTouched = isTouched(fields);
  const displayError = isErrorInSection && isAnyTouched;

  return (
    <FieldSectionPanel
      title="Rate"
      description="How Talent will be paid"
      isError={displayError}
    >
      {displayError && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {getError(fields)}
        </Typography>
      )}

      <Fields
        names={["is_ai_generated"]}
        component={InfoTag}
        message="The rate is derived by matching your job requirements against our extensive database of recent job offers."
      />

      <Box mb={3}>
        <PaymentTypeField job_type={job_type} payment_type={payment_type} />
      </Box>

      <AmountField
        payment_type={payment_type}
        fixed_rate={fixed_rate}
        min_rate={min_rate}
        max_rate={max_rate}
        min_annual_rate={min_annual_rate}
        max_annual_rate={max_annual_rate}
        hourly_rate={hourly_rate}
        is_ai_generated={is_ai_generated}
        ai_min_rate={ai_min_rate}
        ai_max_rate={ai_max_rate}
        custom_min_rate={custom_min_rate}
        custom_max_rate={custom_max_rate}
      />
    </FieldSectionPanel>
  );
};

import { Navigate } from "react-router-dom";

import { Loader } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useIdParam } from "@js/hooks";

import { useGetEmployerOfferQuery } from "../../api";
import { EmployerPreviewOfferProtectedPage } from "../employer-preview-offer";
import { TalentPreviewOfferPage } from "../talent-preview-offer";

export const NodeStaffPrevievOffer = () => {
  const offerId = useIdParam("offerId");
  const user = useUser();
  const { data: offer, isLoading } = useGetEmployerOfferQuery(
    offerId as number,
    { skip: !offerId },
  );

  if (!offerId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  if (isLoading) {
    return <Loader centered />;
  }

  if (user && user.freelancer === offer?.bid.freelancer.id) {
    return <TalentPreviewOfferPage />;
  }

  return <EmployerPreviewOfferProtectedPage />;
};

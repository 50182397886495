import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import { useNavigate } from "@js/hooks";
import { getMakeOfferMessage, saveMakeOfferMessage } from "@js/services";
import type { EmployerBidListBid } from "@js/types/jobs";

import type { SendMessageFormValues } from "../../forms/bid-send-message";
import { BidSendMessage } from "../../forms/bid-send-message";

import { MakeOfferMessageBox } from "./box";

import style from "./style.module.scss";

type OpenMakeOfferMessageModalProps = {
  bid: EmployerBidListBid;
  nextUrl: string;
};

export const openMakeOfferMessageModal = ({
  bid,
  nextUrl,
}: OpenMakeOfferMessageModalProps) => {
  const name = bid.freelancer.user.first_name;

  ModalInstance.open({
    className: style.makeOfferMessageModal,
    children: (
      <MakeOfferMessageBox>
        <MakeOfferMessageWrapper bidId={bid.id} name={name} nextUrl={nextUrl} />
      </MakeOfferMessageBox>
    ),
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: { marginRight: "-16px", marginTop: "-14px" },
    },
  });
};

type MakeOfferMessageWrapperProps = {
  bidId: number;
  name: string;
  nextUrl: string;
};

const MakeOfferMessageWrapper = ({
  bidId,
  name,
  nextUrl,
}: MakeOfferMessageWrapperProps) => {
  const navigate = useNavigate();

  const initialMessage =
    `Hey ${name} 👋,\n\n` +
    `I’d like to make you an offer for this job. Looking forward to working together! `;

  return (
    <>
      <Typography component="h2" variant="label">
        Make {name} an offer for this job
      </Typography>
      <Typography component="p" size="small" mb={2}>
        ✍️️ Personalize your message below to entice them to join your team!
      </Typography>
      <BidSendMessage
        form="send-interview-request"
        onSubmit={(values: SendMessageFormValues) => {
          const { message } = values;
          saveMakeOfferMessage(bidId, message);
        }}
        onSubmitSuccess={() => {
          navigate(nextUrl, { state: { prevSearch: window.location.search } });
          ModalInstance.close();
        }}
        initialValues={{
          message: getMakeOfferMessage(bidId) || initialMessage,
        }}
        ButtonProps={{
          children: "Next: Create offer",
          variant: "medium-green",
          type: "submit",
        }}
        minRows={4}
      />
    </>
  );
};

import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import type { StripeACHPayment } from "@js/types/payments";

import styles from "../style.module.scss";

type StripeACHItemProps = {
  paymentMethod: StripeACHPayment;
};

const StripeACHItem = ({ paymentMethod }: StripeACHItemProps) => (
  <>
    <div className={styles.paymentMethodsListItemDetailsItem}>
      <Typography
        component="p"
        size="small"
        fontWeight={500}
        textTransform="uppercase"
        color="grey-2"
        className={styles.paymentMethodsListItemDetailsItemName}
      >
        Account Number
      </Typography>
      <Typography component="p">
        {paymentMethod.method.account_number}
      </Typography>
    </div>
    <div className={styles.paymentMethodsListItemDetailsItem}>
      <Typography
        component="p"
        size="small"
        fontWeight={500}
        textTransform="uppercase"
        color="grey-2"
        className={styles.paymentMethodsListItemDetailsItemName}
      >
        Routing Number
      </Typography>
      <Typography component="p">
        {paymentMethod.method.routing_number}
      </Typography>
    </div>
    <div className={styles.paymentMethodsListItemDetailsItem}>
      <Typography
        component="p"
        size="small"
        fontWeight={500}
        textTransform="uppercase"
        color="grey-2"
        className={styles.paymentMethodsListItemDetailsItemName}
      >
        Currency
      </Typography>
      <Typography component="p" textTransform="uppercase">
        {paymentMethod.method.currency}
      </Typography>
    </div>
  </>
);

export default StripeACHItem;

import React, { useMemo } from "react";

import type { SvgIconProps } from "@braintrust/braintrust-ui-components";
import type { StickerValue } from "@js/types/give-and-get-help";

import { getStickerIcon } from "../helper";

export type StickerIconProps = {
  stickerValue: StickerValue;
} & SvgIconProps;

export const StickerIcon = ({ stickerValue, ...rest }: StickerIconProps) => {
  const Icon = useMemo(() => {
    return getStickerIcon(stickerValue);
  }, [stickerValue]);

  return <Icon titleAccess={`${stickerValue} icon`} {...rest} />;
};

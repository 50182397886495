import React from "react";

import { Box, Loader, Typography } from "@braintrust/braintrust-ui-components";
import { BraintrustIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { useGetWalletBalanceQuery } from "@js/apps/dashboard/api";
import { roundBalance } from "@js/utils";

export const WalletLabelWithTokenAmount = () => {
  const user = useUser();
  const userBalanceId = Number(user?.balance_holder);
  const { data, isLoading } = useGetWalletBalanceQuery(
    { balanceHolderId: userBalanceId },
    { skip: user?.freelancer_hide_token_balance || !userBalanceId },
  );

  const balance = data?.available_tokens ? data?.available_tokens : "...";
  const balanceRounded = roundBalance(data?.available_tokens);

  return (
    <Box
      width={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      Wallet
      {isLoading && <Loader size={25} />}
      {!isLoading && data && !user?.freelancer_hide_token_balance && (
        <Box
          title={balance}
          ml={1}
          maxWidth={100}
          bgcolor="var(--black)"
          color="var(--white)"
          borderRadius="100px"
          padding="1px 6px"
          display="flex"
          alignItems="center"
        >
          <BraintrustIcon style={{ fontSize: 18, color: "white" }} />
          <span className="sr-only">balance: </span>
          <Typography
            className="display-ellipsis"
            color="white"
            ml={0.5}
            variant="label"
            size="small"
            component="span"
          >
            {balanceRounded}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

import type { DragEventHandler } from "react";
import React from "react";
import type { WrappedFieldInputProps } from "redux-form";

import type { SelectChangeEvent } from "@braintrust/braintrust-ui-components";

import type { FieldType, SelectFieldProps } from "./select";

type InputProps = {
  onDrop: DragEventHandler<HTMLDivElement> | null;
} & Omit<WrappedFieldInputProps, "onDrop">;

type SelectConnectorProps = {
  SelectComponent: Exclude<React.ElementType, symbol>;
  multiple?: boolean;
  input: InputProps;
} & Omit<FieldType<SelectFieldProps>, "input">;

export const SelectConnector = ({
  SelectComponent,
  input,
  meta,
  multiple = false,
  ...rest
}: SelectConnectorProps) => {
  input.value = multiple && !Array.isArray(input.value) ? [] : input.value;
  input.onDrop = null;
  const onChange = (ev: SelectChangeEvent) => {
    input.onChange(ev.target.value);
  };

  return (
    <SelectComponent
      error={meta.touched && meta?.error}
      multiple={multiple}
      {...input}
      onChange={onChange}
      {...rest}
    />
  );
};

import { Box, Stack } from "@braintrust/braintrust-ui-components";
import { AboutThisSpace } from "@js/apps/spaces/components/about-this-space";
import { AdminList } from "@js/apps/spaces/components/admin-list";
import { RuleList } from "@js/apps/spaces/components/rules";
import { SpaceActivity } from "@js/apps/spaces/components/space-activity";
import { useSpaceAdmins } from "@js/apps/spaces/hooks/use-space-admins";

import { TrendingHashtagsList } from "../../trending-hashtags";

import { useAboutSpace } from "./use-about-space";

import styles from "./style.module.scss";

export const AboutSpace = () => {
  const {
    membersData,
    isAdmin,
    spaceData,
    rules,
    shouldRenderEditRulesButton,
    isMember,
    areNotificationsTurnOn,
  } = useAboutSpace();

  const admins = useSpaceAdmins({ members: membersData?.results });

  if (!spaceData || !membersData) {
    return null;
  }

  const membersSrc = `/spaces/${spaceData.id}/people/`;

  return (
    <Box className={styles.container}>
      <Stack direction="column" gap={2} overflow="auto">
        <AboutThisSpace
          space={spaceData}
          members={membersData.results}
          membersCount={membersData.count}
          isAdmin={isAdmin}
          isMember={isMember}
          areNotificationsTurnOn={areNotificationsTurnOn}
        />
        <RuleList
          spaceId={spaceData.id}
          rules={rules}
          shouldRenderEditRulesButton={shouldRenderEditRulesButton}
        />
      </Stack>

      <Stack direction="column" gap={2}>
        <SpaceActivity
          space={spaceData}
          spaceType={spaceData.visibility}
          created={spaceData.created}
          lastWeekPosts={spaceData.number_of_posts_for_last_7_days}
          lastMonthPosts={spaceData.number_of_posts_for_last_30_days}
          members={membersData.results}
          membersCount={membersData.count}
          membersSrc={membersSrc}
        />
        <AdminList admins={admins} showProfileCardPopover />
        <TrendingHashtagsList spaceId={spaceData.id} />
      </Stack>
    </Box>
  );
};

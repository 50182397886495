import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { globalTimeoutsDelays, useTimeout } from "@js/hooks/timeout";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import { logViewEvent } from "@js/services/analytics/event-logging";
import type { User } from "@js/types/auth";

import {
  areEventPropertiesValid,
  prepareDataForViewEventLogging,
} from "../../utils";

/**
 * The main goal of a delay is to skip logging unnecessary auto redirects or auto url updates
 * not caused by the user. Additionally it is required for another associated features connected to
 * adding non-router data (available after a page component mount) to a router state and sending
 * them via Amplitude with page change events.
 **/

export const useLogViewEvent = (user: User | null): void => {
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { pathname } = location;
  const { id: userId, last_login: lastLogin } = user ?? {};

  // use refs so the delayed callback is called with the latest values
  const locationRef = useEffectRef(location);
  const paramsRef = useEffectRef(params);
  const searchParamsRef = useEffectRef(searchParams);

  useTimeout(
    () => {
      if (useLogViewEvent.isLoggingDisabled) {
        return;
      }

      const { event, eventProperties } = prepareDataForViewEventLogging(
        locationRef.current,
        searchParamsRef.current,
        paramsRef.current,
      );

      if (!areEventPropertiesValid(eventProperties)) {
        return;
      }

      logViewEvent(event, eventProperties);
    },
    globalTimeoutsDelays.log_view_event,
    [pathname, userId, lastLogin],
  );
};

useLogViewEvent.isLoggingDisabled = false;

export const enableDefaultViewEventLogging = () => {
  useLogViewEvent.isLoggingDisabled = false;
};

export const disableDefaultViewEventLogging = () => {
  useLogViewEvent.isLoggingDisabled = true;
};

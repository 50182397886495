import { Button } from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useGoBackHistory } from "@js/hooks";

import { TalentOfferStatusWithJobTitle } from "../offer-status-with-job-title";

import { OfferNavigation } from "./helpers";
import type { TalentOfferCTAButtonsProps } from "./talent-navigation-buttons";
import { TalentOfferCTAButtons } from "./talent-navigation-buttons";

type TalentNavigationProps = TalentOfferCTAButtonsProps;

export const TalentNavigation = ({
  offer,
  onOfferReject,
}: TalentNavigationProps) => {
  const goBack = useGoBackHistory();

  return (
    <OfferNavigation
      leftSocket={
        <Button
          variant="transparent"
          shape="squared"
          onClick={() => goBack(`/talent/dashboard/my_jobs/offers/`)}
          sx={{
            display: "grid",
            placeItems: "center",
          }}
          startIcon={<CloseIcon />}
        >
          Go back
        </Button>
      }
      centerSocket={
        <TalentOfferStatusWithJobTitle
          jobId={offer.job.id}
          jobTitle={offer.job.title}
          offerStatus={offer.status}
        />
      }
      rightSocket={
        <TalentOfferCTAButtons offer={offer} onOfferReject={onOfferReject} />
      }
    />
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";

type LabelWithLimitProps = {
  id: string;
  label: string;
  limit: number;
  currentLength: number;
};

export const LabelWithLimit = ({
  id,
  label,
  currentLength,
  limit,
}: LabelWithLimitProps) => {
  return (
    <Box id={id} display="flex" mb={1} justifyContent="space-between">
      <Box display="flex" gap={0.5}>
        <Typography
          component="label"
          variant="label"
          size="small"
          fontWeight={400}
        >
          {label}
        </Typography>
        <Typography component="p" size="small" sx={{ color: "var(--grey-2)" }}>
          {`(max ${limit})`}
        </Typography>
      </Box>
      <Typography component="p" size="small" sx={{ color: "var(--grey-2)" }}>
        {`${currentLength} / ${limit}`}
      </Typography>
    </Box>
  );
};

import { useEffect } from "react";

import { Box, Loader } from "@braintrust/braintrust-ui-components";
import { useStatsQuery } from "@js/apps/common/api";
import { InvitedByUser } from "@js/apps/onboarding/components/invitation";
import { EXAMPLE_USERS } from "@js/apps/onboarding/constants";
import { Snackbar } from "@js/components/snackbar";
import { UsersThumbnailAvatars } from "@js/components/users-thumbnail-avatars";
import { useAppSelector } from "@js/hooks";
import { LocalStorage } from "@js/services";

import styles from "./style.module.scss";

export const OnboardingProfNetHeader = ({
  referral,
}: {
  referral: string | undefined;
}) => {
  const { data: stats } = useStatsQuery();

  const publicInvitationDetails = useAppSelector(
    (state) => state.auth.publicInvitationDetails,
  );
  const isLoading = useAppSelector(
    (state) => state.auth.fetchPublicInvitationDetails,
  );

  const invitationError = publicInvitationDetails?.invitation_error;
  const invitationWithReferral =
    publicInvitationDetails && !invitationError && referral;

  useEffect(() => {
    if (invitationError && referral) {
      Snackbar.error(invitationError, {
        persist: true,
        preventDuplicate: true,
      });

      LocalStorage.removeItem(LocalStorage.keys.SIGN_UP_REFERRER);
    }
  }, [invitationError, referral]);

  return (
    <Box className={styles.header}>
      {isLoading ? (
        <Loader />
      ) : invitationWithReferral ? (
        <InvitedByUser publicInvitationDetails={publicInvitationDetails} />
      ) : (
        <UsersThumbnailAvatars
          users={EXAMPLE_USERS}
          usersAmount={
            stats?.users_count_representation
              ? stats.users_count_representation
              : "0"
          }
          spacing={8}
          max={4}
        />
      )}
    </Box>
  );
};

import { SubmissionError } from "redux-form";

import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { offersApi } from "@js/apps/jobs/apps/offers/api";
import { useVerifyStripeACHPaymentMethodMutation } from "@js/apps/payments/api";
import {
  openVerifyStripeACHPaymentMethodModal,
  VerifyStripeACHPaymentMethodModalInstance,
} from "@js/apps/payments/components/verify-stripe-ach-payment-method";
import type { VerifyStripeACHFormData } from "@js/apps/payments/forms/verify-stripe-ach-payment-method";
import { Date } from "@js/components/date";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import type { EmployerOffer } from "@js/types/jobs";
import type { PaymentMethod } from "@js/types/payments";
import { DateFormats } from "@js/utils/date/types";

import { EmployerStatusBox } from "../employer-status-box";

type StatusBoxProps = {
  offer: EmployerOffer;
};

export const AcceptedStatusBox = ({ offer }: StatusBoxProps) => {
  const name = offer.bid.freelancer.user.first_name;

  return (
    <EmployerStatusBox
      color="green"
      label="🎉 This offer was accepted!"
      description={
        <Typography component="p" size="large" multipleEllipsis>
          Congratulations on finding the right Talent for your job! Be sure to
          communicate with {name} about getting started and any other important
          details.
        </Typography>
      }
    />
  );
};

export const RejectedStatusBox = ({ offer }: StatusBoxProps) => {
  const name = offer.bid.freelancer.user.first_name;

  return (
    <EmployerStatusBox
      color="red"
      label="🚫 This offer was declined"
      description={
        offer.reject_reason && (
          <>
            <Typography
              mb={1}
              component="p"
              size="small"
              fontWeight={500}
              color="grey-1"
              multipleEllipsis
            >
              {name}'s reason for declining:
            </Typography>
            <Typography
              component="p"
              size="large"
              style={{ whiteSpace: "pre-wrap" }}
            >
              "{offer.reject_reason}"
            </Typography>
          </>
        )
      }
    />
  );
};

export const CanceledStatusBox = ({ offer }: StatusBoxProps) => {
  return (
    <EmployerStatusBox
      color="red"
      label="🚫 Offer canceled by the Client"
      description={
        offer.cancelled_at && (
          <Typography component="p" size="large">
            You canceled this offer on{" "}
            <Date
              date={offer.cancelled_at}
              format={DateFormats["January 1, 1970"]}
            />
            .
          </Typography>
        )
      }
    />
  );
};

export const ExpiredStatusBox = ({ offer }: StatusBoxProps) => {
  return (
    <EmployerStatusBox
      color="orange"
      label="⌛️ This offer expired"
      description={
        offer.expired_at && (
          <Typography component="p" size="large">
            Offer expired{" "}
            <Date
              date={offer.expired_at}
              format={DateFormats["January 1, 1970"]}
            />
            .
          </Typography>
        )
      }
    />
  );
};

export const PendingStatusBox = ({ offer }: StatusBoxProps) => {
  const name = offer.bid.freelancer.user.first_name;

  return (
    <EmployerStatusBox
      color="blue"
      label="👀 This offer is in review with the Talent"
      description={
        <Typography component="p" size="large" multipleEllipsis>
          Offers usually get a response within a day or two. Send a message if
          you need to follow up or share additional information. You’ll be
          notified as soon as {name} responds!
        </Typography>
      }
    />
  );
};

export const PaymentInProgressStatusBox = ({ offer }: StatusBoxProps) => {
  const isCCPaymentMethod =
    offer.deposit_payment_method.content_type ===
    ENUMS.PaymentContentType.StripeCreditCardPaymentMethod;

  if (isCCPaymentMethod) {
    return (
      <EmployerStatusBox
        color="blue"
        label="⌛ This offer has not been sent yet"
        description={
          <Typography component="p" size="large" multipleEllipsis>
            We’ll send this offer to {offer.bid.freelancer.user.first_name}{" "}
            automatically once your deposit has been received.
          </Typography>
        }
      />
    );
  }

  return (
    <EmployerStatusBox
      color="blue"
      label="⌛ This offer has not been sent yet"
      description={
        <Typography component="p" size="large" multipleEllipsis>
          We’ll send this offer to {offer.bid.freelancer.user.first_name}{" "}
          automatically once your ACH payment has been verified by two micro
          transactions and your deposit has been received. This typically takes
          3 - 5 business days.
        </Typography>
      }
    />
  );
};

export const WaitingForMethodVerificationStatusBox = ({
  offer,
}: StatusBoxProps) => {
  const dispatch = useAppDispatch();
  const [verifyStripeACHPayment] = useVerifyStripeACHPaymentMethodMutation();

  const verifyStripeACHPaymentMethod = async (
    paymentMethod: PaymentMethod,
    values: VerifyStripeACHFormData,
  ) => {
    const result = await verifyStripeACHPayment({
      paymentMethodId: paymentMethod.method.id,
      values,
    })
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error.data);
      });

    dispatch(
      offersApi.util.invalidateTags([{ type: "EmployerOffers", id: offer.id }]),
    );

    return result;
  };

  const onVerifyStripeACHPaymentMethod = () => {
    openVerifyStripeACHPaymentMethodModal({
      paymentMethod: offer.deposit_payment_method,
      disableSnackbarOnSuccess: true,
      onSubmit: verifyStripeACHPaymentMethod,
    });
  };

  return (
    <>
      <EmployerStatusBox
        color="blue"
        label="⌛ This offer has not been sent yet"
        description={
          <Box>
            <Typography component="p" size="large" multipleEllipsis>
              We’ll send this offer to {offer.bid.freelancer.user.first_name}{" "}
              automatically once your ACH payment has been verified by two micro
              transactions and your deposit has been received. This typically
              takes 3 - 5 business days.
            </Typography>
            <Typography component="p" size="large" mt="16px">
              Are there transactions in your bank right now?{" "}
              <Typography
                component="button"
                variant="paragraph"
                className="btn-reset"
                size="large"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
                onClick={onVerifyStripeACHPaymentMethod}
              >
                Verify the two amounts
              </Typography>
              .
            </Typography>
          </Box>
        }
      />
      <VerifyStripeACHPaymentMethodModalInstance />
    </>
  );
};

export const PaymentMethodFailedStatusBox = ({ offer }: StatusBoxProps) => {
  return (
    <>
      <EmployerStatusBox
        color="red"
        label="🚨 Your payment method has failed so we are not able to send your
        offer."
        description={
          <Box>
            <Typography component="p" size="large">
              We will auto-resend your offer once the payment is successful.
              Please update your billing details.
            </Typography>

            <Button
              variant="primary"
              shape="squared"
              sx={{
                marginTop: "24px",
              }}
              to={`/jobs/${offer.bid.job.id}/offers/${offer.id}/edit`}
              RouterLink={RouterLink}
            >
              Update billing details
            </Button>
          </Box>
        }
      />
      <VerifyStripeACHPaymentMethodModalInstance />
    </>
  );
};

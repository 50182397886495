import React from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import { SpacesHeader } from "@js/apps/spaces/components/header";
import { SpaceList } from "@js/apps/spaces/components/space-list";
import { AppLayout } from "@js/layouts/app";

export const ExploreSpacesPage = () => {
  return (
    <AppLayout bgcolor="var(--soft-green)" pageTitle="Spaces">
      <Box maxWidth="755px" width="100%" mx="auto">
        <SpacesHeader title="Explore spaces" />

        <SpaceList />
      </Box>
    </AppLayout>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { Sanitize } from "@js/components/sanitize";

import { SectionTitle } from "../section-title";

type DescriptionProps = {
  title: string;
  description: string;
};

export const Description = ({ description, title }: DescriptionProps) => {
  return (
    <Box>
      <SectionTitle title={title} />
      <Typography
        component="div"
        color="secondary"
        mb={2}
        style={{ overflowWrap: "break-word" }}
        size="medium"
      >
        <Sanitize input={description} />
      </Typography>
    </Box>
  );
};

import React, { useState } from "react";

import { Box, Divider, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { PollResults } from "@js/apps/give-and-get-help/components/poll/poll-result";
import { PollFormComponent } from "@js/apps/give-and-get-help/forms/poll-form";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";
import type { PollOption } from "@js/types/give-and-get-help";

import { usePostContext } from "../../context";
import { useDisableSpecificPostActions } from "../../hooks/use-disable-specific-post-actions";

type PollProps = {
  poll_options: PollOption[];
  poll_text: string;
  poll_votes_count: number | null;
  postId: number;
};

export const Poll: React.FC<PollProps> = ({
  poll_text,
  poll_votes_count,
  poll_options,
  postId,
}) => {
  const isSmall = useMediaQuery("sm");
  const { isPublic } = usePostContext();
  const disablePoll = useDisableSpecificPostActions();
  const hasUserVoted = poll_options.some((option) => option.user_voted);
  const [showResultsWhenNotVoted, setShowResultsWhenNotVoted] =
    useState(isPublic);
  const voteCountLabel = poll_votes_count === 1 ? "person" : "people";
  const voteCountText = poll_votes_count
    ? `${poll_votes_count} ${voteCountLabel} voted`
    : "No one voted yet";

  const handleShowResultsClick = () => {
    if (isPublic) {
      openSignUpModal();
      return;
    }
    setShowResultsWhenNotVoted((prev) => !prev);
  };

  const showResults = hasUserVoted || showResultsWhenNotVoted;

  return (
    <Box mt={3}>
      <Divider
        color="secondary"
        sx={{
          mb: 3,
        }}
      />
      <Typography
        component="p"
        variant="paragraph"
        size={isSmall ? "medium" : "large"}
        fontWeight={isSmall ? 500 : 400}
        sx={{
          wordBreak: "break-word",
        }}
      >
        {poll_text}
      </Typography>
      <Typography
        component="p"
        color="secondary"
        variant="paragraph"
        size="small"
      >
        {voteCountText}
        {!hasUserVoted && !!poll_votes_count && poll_votes_count > 0 && (
          <Typography
            component="span"
            variant="link"
            size="small"
            className="pointer blue"
            onClick={handleShowResultsClick}
          >
            {showResults ? " Hide" : " Show"} results
          </Typography>
        )}
      </Typography>
      <Box
        mt={2}
        sx={{
          overflowWrap: "anywhere",
        }}
      >
        {showResults ? (
          <PollResults votes_count={poll_votes_count} options={poll_options} />
        ) : (
          <PollFormComponent
            resultsShown={showResults}
            postId={postId}
            options={poll_options}
            votes_count={poll_votes_count}
            disablePoll={disablePoll}
          />
        )}
      </Box>
    </Box>
  );
};

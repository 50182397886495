import React from "react";

import { Button } from "@braintrust/braintrust-ui-components";

export const SignUpConfirmButton = ({
  onClick,
}: {
  onClick?: VoidFunction;
}) => {
  return (
    <Button onClick={onClick} variant="primary" type="submit">
      Join Braintrust
    </Button>
  );
};

import React from "react";

import { Rating, Typography } from "@braintrust/braintrust-ui-components";

import style from "./style.module.scss";

type ReviewsSummaryProps = {
  rate: string;
  count?: number;
  label?: string;
};

export const ReviewsSummary = ({ rate, count, label }: ReviewsSummaryProps) => (
  <div className={style.reviewsSummary}>
    <div className={style.reviewsSummaryGroup}>
      <Typography
        className={style.reviewsSummaryValue}
        component="p"
        variant="title"
        fontWeight={400}
        size="small"
      >
        {rate}
      </Typography>
      <Rating
        className={style.reviewsSummaryStars}
        value={parseFloat(rate)}
        iconStyle={{ width: "24px", height: "24px" }}
        readOnly
      />
      {count && (
        <Typography
          component="p"
          size="small"
          color="grey-2"
          className={style.reviewsSummaryCount}
        >
          ({count})
        </Typography>
      )}
    </div>
    {label && (
      <Typography
        component="p"
        size="small"
        fontWeight={500}
        textTransform="uppercase"
        className={style.reviewsSummaryLabel}
      >
        {label}
      </Typography>
    )}
  </div>
);

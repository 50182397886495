import type { ReactNode } from "react";
import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";

export const PostsListEmptyState = ({ children }: { children?: ReactNode }) => {
  return (
    <Box sx={{ minHeight: "200px", display: "grid", placeItems: "center" }}>
      <Typography component="p" variant="title">
        {children ?? "No posts yet"}
      </Typography>
    </Box>
  );
};

import React from "react";

import {
  IconButton,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";

import { WithdrawalModalContent } from "./modal-content";
import { WithdrawalModalInstance } from "./modal-instance";

export const WithdrawalModal = () => {
  return (
    <WithdrawalModalInstance>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography component="p" variant="label" fontWeight={500} size="large">
          Transfer BTRST
        </Typography>
        <IconButton
          variant="tertiary"
          size="x-small"
          aria-label="Close location mismatch modal"
          onClick={WithdrawalModalInstance.close}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <WithdrawalModalContent />
    </WithdrawalModalInstance>
  );
};

WithdrawalModal.openModal = WithdrawalModalInstance.open;
WithdrawalModal.closeModal = WithdrawalModalInstance.close;

import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { ModalConfirm, ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

type DeleteOfferConfirmationModalProps = {
  onConfirm: () => void;
  offerReceiverName: string;
};

const DeleteOfferConfirmationModal = ({
  onConfirm,
  offerReceiverName,
}: DeleteOfferConfirmationModalProps) => {
  return (
    <ModalConfirm
      onConfirm={onConfirm}
      confirmText="Remove offer"
      confirmButtonVariant="destructive"
      buttonsSquareShape
    >
      <Typography component="p" variant="label" size="large" mb={3}>
        Remove your offer
      </Typography>
      <Typography
        component="p"
        variant="title"
        size="medium"
        fontWeight={400}
        mb={1}
      >
        Are you sure want to remove your offer?
      </Typography>
      <Typography component="span">
        {offerReceiverName} will no longer be able to accept it. You can't undo
        this, but you can create a new one. This does not remove any messages or
        reactions you sent them.
      </Typography>
    </ModalConfirm>
  );
};

export const openDeleteOfferConfirmationModal = ({
  onConfirm,
  offerReceiverName,
}: DeleteOfferConfirmationModalProps) =>
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    className: styles.removeOfferConfirmationModal,
    children: (
      <DeleteOfferConfirmationModal
        onConfirm={() => {
          onConfirm();
          ModalInstance.close();
        }}
        offerReceiverName={offerReceiverName}
      />
    ),
  });

import type { ReactElement } from "react";
import { useMemo } from "react";

import { IconButton, Menu } from "@braintrust/braintrust-ui-components";
import { MoreVertIcon } from "@braintrust/braintrust-ui-components/Icons";
import type {
  EmployerOwnJob,
  Job,
  NodeStaffMainJobListingJob,
} from "@js/types/jobs";
import { getEnumLabel } from "@js/utils/enums";

import type { JobActionsEnum } from "../job-actions/jobs-actions-object";
import { useJobAction } from "../job-actions/jobs-actions-object";

type JobActionMenuProps = {
  job: Job | EmployerOwnJob | NodeStaffMainJobListingJob;
  iconColor?: string;
  className?: string;
  filterActions?: (jobActions: Job["job_actions"]) => Job["job_actions"];
  additionalMenuItems?: ReactElement[];
};

export const JobActionMenu = ({
  job,
  iconColor,
  className = "job-action-menu-old",
  filterActions = defaultFilterJobActions,
  additionalMenuItems,
}: JobActionMenuProps) => {
  const { handleJobAction, JobActionModalInstance } = useJobAction();

  const filteredJobActions = useMemo(() => {
    if (!job?.job_actions) {
      return [];
    }

    return filterActions(job.job_actions);
  }, [filterActions, job?.job_actions]);

  if (!filteredJobActions?.length) {
    return null;
  }

  return (
    <>
      <Menu
        anchor={
          <IconButton
            className={className}
            aria-label="job action menu"
            aria-controls={`job-action-menu-old-${job.id}`}
            aria-haspopup="true"
            variant="transparent"
            size="medium"
          >
            <MoreVertIcon style={{ color: iconColor || "inherit" }} />
          </IconButton>
        }
        id={`job-action-menu-old-${job.id}`}
      >
        {additionalMenuItems?.map((menuItem) => menuItem)}
        {filteredJobActions.map(
          ({ action: actionName, state, reason, ...item }) => {
            const label = getActionLabel(actionName);
            return (
              <Menu.Item
                key={label}
                onClick={
                  actionName
                    ? () => handleJobAction(job, actionName)
                    : undefined
                }
                disabled={state === ENUMS.JobActionStates.DISABLED}
                tooltipText={reason}
                {...item}
              >
                {label}
              </Menu.Item>
            );
          },
        )}
      </Menu>
      <JobActionModalInstance />
    </>
  );
};

const defaultFilterJobActions = (jobActions: Job["job_actions"]) => jobActions;

const getActionLabel = (actionName?: JobActionsEnum) =>
  actionName && getEnumLabel(ENUMS.JobActionsLabels, actionName);

import { useEffect } from "react";

import {
  Loader,
  Pagination,
  PaginationWrapper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { JOB_EVENTS_PAGE_QUERY_PARAM } from "@js/apps/jobs/components/job-events-button/const";
import { Datetime } from "@js/components/date";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector, useQueryParams } from "@js/hooks";
import { DateTimeFormats } from "@js/utils/date/types";

import { fetchJobEvents } from "../../actions";

import style from "./style.module.scss";

type ModalContentProps = {
  jobId: number;
};

export const openJobEventsModal = (jobId: number) => {
  ModalInstance.open({
    children: <ModalContent jobId={jobId} />,
    closeButton: true,
    className: style.jobEventListModal,
  });
};

const ModalContent = ({ jobId }: ModalContentProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.jobs.fetchingJobEvents);
  const currentPage = useQueryParams()[JOB_EVENTS_PAGE_QUERY_PARAM] || 1;

  useEffect(() => {
    dispatch(fetchJobEvents({ job: jobId, page: currentPage }));
  }, [currentPage, dispatch, jobId]);

  return (
    <div>
      <Typography component="h4" variant="title" size="small" mb={2}>
        Job Events
      </Typography>
      {loading ? <Loader /> : <EventList />}
    </div>
  );
};

const EventList = () => {
  const events = useAppSelector((state) => state.jobs.jobEvents);

  if (!events.results.length) {
    return <EmptyState />;
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Creator</TableCell>
            <TableCell>Is Matcher</TableCell>
            <TableCell>Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.results.map(({ event, creator, is_matcher, created }) => (
            <TableRow key={event + created} sx={{ verticalAlign: "text-top" }}>
              <TableCell sx={{ maxWidth: "800px" }}>{event}</TableCell>
              <TableCell>{creator}</TableCell>
              <TableCell align="center">{is_matcher ? "Yes" : "No"}</TableCell>
              <TableCell>
                <Datetime
                  date={created}
                  format={DateTimeFormats["1970-12-31 23:59"]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {events.count > SETTINGS.JOB_EVENTS_PER_PAGE && (
        <PaginationWrapper mt={3}>
          <Pagination
            pageQueryParamName={JOB_EVENTS_PAGE_QUERY_PARAM}
            RouterLink={RouterLink}
            perPage={SETTINGS.JOB_EVENTS_PER_PAGE}
            count={events.count}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

const EmptyState = () => {
  return (
    <Typography component="p" textAlign="center">
      There are no events yet.
    </Typography>
  );
};

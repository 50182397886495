import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { Modal } from "@js/components/modal";

import style from "./styles.module.scss";

const BookmarkIntroModalContent = () => {
  return (
    <Box>
      <img
        src={`${SETTINGS.STATIC_URL}bookmarks/bookmarks-intro.svg`}
        alt="Graphic presented Bookmarks feature"
        width="295"
        height="208"
      />
      <Typography component="p" variant="title" size="small" fontWeight={400}>
        Everything you saved in one place
      </Typography>
      <Typography component="p" variant="paragraph" size="medium">
        Every time you save a post or a job, we’ll add it to your collection
        here.
      </Typography>
    </Box>
  );
};
export const BookmarksIntroModal = Modal("bookmarks-showcase-modal", {
  disablePortal: true,
  keepOnBackdropClick: false,
  children: <BookmarkIntroModalContent />,
  closeButton: false,
  className: style.modal,
});

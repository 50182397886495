import type { ReactNode } from "react";

import {
  ListItem,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";

import styles from "./styles.module.scss";

export const MembersListItem = ({
  children,
  onRemove,
}: {
  children: ReactNode;
  onRemove: () => void;
}) => {
  return (
    <ListItem sx={{ padding: 0 }}>
      <Stack
        sx={{ alignItems: "flex-start", gap: 0.5 }}
        className={styles.memberListItem}
      >
        {children}
        <Typography
          component="button"
          variant="link"
          size="small"
          color="grey-1"
          p={0}
          onClick={onRemove}
          className={styles.memberListItemRemove}
        >
          Remove
        </Typography>
      </Stack>
    </ListItem>
  );
};

import React from "react";
import _ from "underscore";

import {
  Divider,
  RoundedBox,
  Typography,
} from "@braintrust/braintrust-ui-components";

import { ChecklistRow } from "./checklist-row";
import type { ChecklistItemType } from "./const";

import styles from "./style.module.scss";

export type ChecklistAction = {
  type: ChecklistItemType;
  header: string;
  subheader?: string;
  isCompleted?: boolean;
  description?: JSX.Element;
  action?: (() => void) | boolean;
};

type ChecklistContainerProps = {
  title?: string;
  actions: ChecklistAction[];
};

export const ChecklistContainer = ({
  title = "✏️ Braintrust checklist",
  actions,
}: ChecklistContainerProps) => {
  return (
    <RoundedBox sx={{ border: "1px solid var(--soft-beige)" }}>
      <Typography component="span" variant="label" size="large">
        {title}
      </Typography>

      <div className={styles.checklist}>
        {actions.map((item) => (
          <React.Fragment key={item.header}>
            <ChecklistRow
              header={item.header}
              subheader={item.subheader}
              isCompleted={item.isCompleted}
              description={item.description}
              action={item.action}
            />
            <Divider color="beige" />
          </React.Fragment>
        ))}
      </div>
    </RoundedBox>
  );
};

import React from "react";

import {
  Pagination,
  PaginationWrapper,
} from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export type PeoplePaginationProps = {
  page?: number;
  total?: number;
};

export const PeoplePagination = ({
  page = 1,
  total = 0,
}: PeoplePaginationProps) => {
  return (
    <PaginationWrapper sx={{ justifyContent: "end", pr: { xs: 3, md: 4 } }}>
      <Pagination
        count={total}
        page={Number(page)}
        perPage={12}
        RouterLink={RouterLink}
      />
    </PaginationWrapper>
  );
};

import type { ElementRef } from "react";
import { useRef } from "react";

import {
  Box,
  Slide,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";

type UploadLinkedinConnectionsErrorProps = {
  error: string | undefined;
  showError: boolean;
  onErrorClear: () => void;
};

export const UploadLinkedinConnectionsError = ({
  error,
  showError,
  onErrorClear,
}: UploadLinkedinConnectionsErrorProps) => {
  const containerRef = useRef<ElementRef<"div">>(null);

  return (
    <>
      {!!error && (
        <Stack
          ref={containerRef}
          sx={{
            position: "absolute",
            top: 0,
            px: 2,
            width: "100%",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <Slide
            in={showError}
            unmountOnExit
            mountOnEnter
            container={containerRef.current}
            onExited={() => {
              onErrorClear();
            }}
          >
            <Box
              sx={{
                mt: 2,
                p: "8px 24px",
                backgroundColor: "var(--negative-1)",
                borderRadius: 2,
                maxWidth: "100%",
              }}
            >
              <Typography
                component="p"
                variant="paragraph"
                size="medium"
                multilineEllipsis={2}
                color="white"
                sx={{ textAlign: "center" }}
              >
                {error}
              </Typography>
            </Box>
          </Slide>
        </Stack>
      )}
    </>
  );
};

import cs from "classnames";

import { Typography } from "@braintrust/braintrust-ui-components";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields/field-section-panel";

import style from "./style.module.scss";

type BoostFieldPanelProps = {
  title: string;
  description: string;
  displayError: boolean;
  error: string;
  children: React.ReactNode;
  isChecked: boolean;
};

export const BoostFieldPanel = ({
  title,
  description,
  displayError,
  error,
  children,
  isChecked,
}: BoostFieldPanelProps) => {
  return (
    <FieldSectionPanelWithImportance
      title={title}
      image={`${SETTINGS.STATIC_URL}jobs/boost-icon-rounded.svg`}
      description={description}
      fieldImportance="optional"
      isError={displayError}
      className={cs(style.field, { [style.boostColor]: isChecked })}
      tooltipMessage={
        <>
          <Typography component="p">
            Stand out as a high-intent candidate by boosting this job as your
            top choice.{" "}
          </Typography>
        </>
      }
    >
      {children}
      {displayError && (
        <Typography mt={2} error component="p" variant="paragraph">
          {error}
        </Typography>
      )}
    </FieldSectionPanelWithImportance>
  );
};

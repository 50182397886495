import React, { useEffect } from "react";
import { Field } from "redux-form";
import amplitude from "amplitude-js";

import {
  Box,
  ExpandingInput,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { signUpAttempt } from "@js/apps/common/actions";
import { goalSelected } from "@js/apps/onboarding/actions";
import { OptionCheckbox } from "@js/apps/onboarding/fields/option-checkbox";
import {
  ONBOARDING_SUBMIT_TYPE,
  useOnboardingUpdateSubmitType,
} from "@js/apps/onboarding/hooks";
import { useAppDispatch } from "@js/hooks";
import { LocalStorage } from "@js/services";

import { OnboardingProfNetLayout } from "../layout";

import { JOINING_REASON_OPTIONS } from "./const";

import style from "./style.module.scss";

export const OnboardingGoalsPage = () => {
  const referral =
    LocalStorage.getItem(LocalStorage.keys.SIGN_UP_REFERRER) ?? undefined;

  const updateSubmitType = useOnboardingUpdateSubmitType();
  const dispatch = useAppDispatch();
  const isMd = useMediaQuery("md");

  const handleOptionSelect = (selectedOption: string) => {
    dispatch(
      goalSelected({
        joining_reasons: selectedOption as EnumType<typeof ENUMS.JoiningReason>,
      }),
    );
  };

  useEffect(() => {
    signUpAttempt(amplitude.getInstance().getDeviceId());
  }, []);

  return (
    <OnboardingProfNetLayout
      activeStep={0}
      onContinue={() => updateSubmitType(ONBOARDING_SUBMIT_TYPE.goals)}
    >
      <Typography component="h1">Welcome to Braintrust!</Typography>
      <Box mt={1} mb={referral ? "50px" : 0} id="reasons-to-join">
        <Typography
          component="span"
          fontWeight={400}
          variant="title"
          size="small"
        >
          👋 Hi
        </Typography>
        <Field
          name="first_name"
          placeholder="First name"
          autoFocus
          maxLength={50}
          component={ExpandingInput}
        />
        <Typography
          component="span"
          variant="title"
          mr={1}
          fontWeight={400}
          size="small"
        >
          !
        </Typography>
        <Typography
          component={isMd ? "p" : "span"}
          fontWeight={400}
          variant="title"
          size="small"
        >
          Tell us why you’re here.
        </Typography>
      </Box>
      <Field
        className={style.joiningReasonsContainer}
        name="joining_reasons"
        labelledBy="reasons-to-join"
        options={JOINING_REASON_OPTIONS}
        onSelect={handleOptionSelect}
        component={OptionCheckbox}
      />
    </OnboardingProfNetLayout>
  );
};

import type { ReactNode } from "react";

import type { StackProps } from "@braintrust/braintrust-ui-components";
import { Stack } from "@braintrust/braintrust-ui-components";

type ReferralsStatsSummaryStateContainerProps = {
  children: ReactNode;
} & Pick<StackProps, "sx">;

export const ReferralsStatsSummaryStateContainer = ({
  children,
  sx,
}: ReferralsStatsSummaryStateContainerProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        minWidth: "235px",
        maxWidth: "500px",
        gap: "60px",
        height: "100%",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

import { useMemo } from "react";

import {
  Loader,
  RoundedBox,
  Typography,
} from "@braintrust/braintrust-ui-components";

import style from "./style.module.scss";

type LoadingStateTalentsWithSmartMatchingProps = {
  text: string;
};

const LoadingStateTalentsWithSmartMatching = ({
  text,
}: LoadingStateTalentsWithSmartMatchingProps) => {
  return (
    <RoundedBox
      sx={{
        backgroundColor: "var(--soft-blue)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader
        className={style.smartMatchingLoadingState}
        color="inherit"
        size={48}
      />
      <Typography component="h4" variant="label" mt={4} mb={1}>
        Loading your matches...
      </Typography>
      <Typography component="p" mb={4}>
        {text}
      </Typography>
    </RoundedBox>
  );
};

type InvitesLoadingStateTalentsWithSmartMatchingProps = {
  analyzingProfilesCount: number;
};

export const InvitesLoadingStateTalentsWithSmartMatching = ({
  analyzingProfilesCount,
}: InvitesLoadingStateTalentsWithSmartMatchingProps) => {
  const userCountToDisplay = useMemo(() => {
    const count = analyzingProfilesCount;

    return count.toLocaleString("en-US");
  }, [analyzingProfilesCount]);

  return (
    <LoadingStateTalentsWithSmartMatching
      text={`Our AI Matching Engine is analyzing ${userCountToDisplay} profiles. This should take less than 30 seconds.`}
    />
  );
};

export const BidsLoadingStateTalentsWithSmartMatching = () => {
  return (
    <LoadingStateTalentsWithSmartMatching text="Our AI is retrieving and analyzing your applications. This should take less than 30 seconds." />
  );
};

import React from "react";

import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { Button } from "@braintrust/braintrust-ui-components";
import { useLoginAs } from "@js/apps/auth/hooks";

type LoginAsProps = {
  as: number;
} & ButtonProps;

export const LoginAs = ({ as, ...props }: LoginAsProps) => {
  const { canLoginAs, loginAs } = useLoginAs(as);

  if (!canLoginAs) return null;

  return (
    <Button onClick={loginAs} {...props}>
      Log in as client
    </Button>
  );
};

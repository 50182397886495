import { Button, WarningAlert } from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import { AddW8Modal } from "./add-w8-modal";
import { AddW9Modal } from "./add-w9-modal";

type TaxDocumentsAlertProps = {
  country?: string | null;
  isW8FormRequired: boolean;
  isW9FormRequired: boolean;
  uploadedAt?: string | null;
  isLoading: boolean;
};

export const TaxDocumentsAlert = ({
  country,
  isW8FormRequired,
  isW9FormRequired,
  uploadedAt,
  isLoading,
}: TaxDocumentsAlertProps) => {
  if (isLoading) {
    return null;
  }

  if (!country) {
    return (
      <WarningAlert
        title="No Country Selected."
        text="Before you can be paid, you must set your country location."
        action={
          <Button
            href="/settings/account_info/"
            variant="primary"
            RouterLink={RouterLink}
          >
            Set Country
          </Button>
        }
      ></WarningAlert>
    );
  }

  if (isW8FormRequired && !uploadedAt) {
    return (
      <WarningAlert
        title="W-8 Form Required."
        text="Before withdrawing funds, all residents outside of U.S. must provide their W-8 tax information."
        action={
          <Button onClick={AddW8Modal.open} variant="primary">
            Add W-8
          </Button>
        }
      ></WarningAlert>
    );
  }

  if (isW9FormRequired && !uploadedAt) {
    return (
      <WarningAlert
        title="W-9 Form Required."
        text="Before withdrawing funds, all U.S. residents must provide their W-9 tax information."
        action={
          <Button onClick={AddW9Modal.open} variant="primary">
            Add W-9
          </Button>
        }
      ></WarningAlert>
    );
  }

  return null;
};

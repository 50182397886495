import { Box, Typography } from "@braintrust/braintrust-ui-components";

import { PostJobForClientFields } from "./fields";
import { PostJobForClientModalInstance } from "./modal";
import { SubmitAndCancelButtons } from "./submit-and-cancel-buttons";
import { useOpenPostJobForAClientModal } from "./use-open-post-job-for-a-client";

export const PostJobForClientModal = () => {
  useOpenPostJobForAClientModal();

  return (
    <PostJobForClientModalInstance>
      <Box
        sx={{
          minWidth: {
            sm: 500,
          },
        }}
      >
        <Typography
          component="h2"
          variant="title"
          size="small"
          fontWeight={400}
          mb={4}
        >
          Post a Job for a Client
        </Typography>
        <PostJobForClientFields />
        <SubmitAndCancelButtons />
      </Box>
    </PostJobForClientModalInstance>
  );
};

import {
  type Control,
  type FieldValues,
  useController,
  type UseControllerProps,
} from "react-hook-form";

import type {
  SelectProps,
  SwitchProps,
} from "@braintrust/braintrust-ui-components";
import { Switch } from "@braintrust/braintrust-ui-components";

export type RhfSwitchProps<T extends FieldValues> = SwitchProps &
  UseControllerProps<T> & {
    id: SelectProps["id"];
    label: SelectProps["label"];
    control: Control<T>;
    customValue?: unknown;
    customUncheckedValue?: unknown;
  };

export const RhfSwitch = <T extends FieldValues>({
  id,
  label,
  customValue,
  customUncheckedValue,
  onChange,
  control,
  name,
  ...props
}: RhfSwitchProps<T>) => {
  const {
    field: { value, onChange: rhfOnChange, ...rest },
  } = useController({
    control,
    name,
  });

  const isChecked = customValue ? value === customValue : !!value;

  const handleOnChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const newValue = customValue
      ? checked
        ? customValue
        : customUncheckedValue
      : checked;

    if (onChange) {
      onChange(_, !!newValue);
    }

    rhfOnChange(newValue);
  };

  return (
    <Switch
      id={id}
      label={label}
      value={value}
      onChange={handleOnChange}
      checked={isChecked}
      {...rest}
      ref={undefined}
      {...props}
    />
  );
};

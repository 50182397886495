import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

import { RuleForm } from "./forms";

import style from "./style.module.scss";

type CreateRuleProps = {
  ruleNumber: number;
  spaceId: number;
};

const CreateRule = ({ ruleNumber, spaceId }: CreateRuleProps) => {
  return (
    <Box className={style.container}>
      <Typography component="p" variant="paragraph" size="large" mb={4}>
        Create rule
      </Typography>

      <RuleForm mode="create" ruleNumber={ruleNumber} spaceId={spaceId} />
    </Box>
  );
};

export type OpenCreateRuleModalArg = {
  onClose: () => void;
} & CreateRuleProps;

const openCreateRuleModal = ({
  onClose,
  ...createRuleProps
}: OpenCreateRuleModalArg) => {
  // close the sortable rule list modal
  ModalInstance.close();

  ModalInstance.open({
    className: style.ruleListModal,
    padding: false,
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    children: <CreateRule {...createRuleProps} />,
    onClose: onClose,
  });
};

export { openCreateRuleModal };

import { Typography } from "@braintrust/braintrust-ui-components";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields";
import { OptionTileButtons } from "@js/components/option-tile-buttons";

import { useRequirementField } from "./hook";
import type { RequirementFieldProps } from "./types";

import styles from "./styles.module.scss";

const OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const RequirementField = ({
  input,
  meta,
  job,
  type,
}: RequirementFieldProps) => {
  const { handleChange, description, title, displayError, error } =
    useRequirementField({
      field: { input, meta },
      type,
      job,
    });

  return (
    <FieldSectionPanelWithImportance
      title={title}
      description={description}
      isError={displayError}
      fieldImportance="required"
    >
      <OptionTileButtons
        options={OPTIONS}
        value={input.value}
        onChange={handleChange}
        className={styles.tileButtonsWrapper}
        buttonClassName={styles.tileBtn}
      />
      {displayError && (
        <Typography mt={2} component="p" variant="paragraph" error>
          {error}
        </Typography>
      )}
    </FieldSectionPanelWithImportance>
  );
};

import { Box, Tooltip, Typography } from "@braintrust/braintrust-ui-components";
import { InfoIcon } from "@braintrust/braintrust-ui-components/Icons";

import {
  getApplicationStatusColorVariant,
  getApplicationTooltipText,
} from "./utils";

import styles from "./style.module.scss";

export type ApplicationStatusWithTooltipProps =
  Partial<ApplictionStatusProps> & {
    appliedDate?: string | null;
    offerCreatedDate?: string | null;
    offerAcceptedDate?: string | null;
    offerUpdatedDate: string | null | undefined;
  };

export const ApplicationStatusWithTooltip = ({
  status,
  appliedDate,
  offerCreatedDate,
  offerAcceptedDate,
  offerUpdatedDate,
}: ApplicationStatusWithTooltipProps) => {
  if (noPillForStatus(status)) return null;

  const title = getApplicationTooltipText({
    status,
    appliedDate,
    offerCreatedDate,
    offerAcceptedDate,
    offerUpdatedDate,
  });

  return (
    <Tooltip
      title={title}
      color="var(--black)"
      placement="top"
      maxWidth={238}
      disabled={!title}
    >
      <span className={styles.tooltip} style={{ minWidth: 0 }}>
        <Box display="flex" gap="4px" alignItems="center">
          <ApplictionStatus status={status} />
          {title && <InfoIcon sx={{ fontSize: "20px" }} />}
        </Box>
      </span>
    </Tooltip>
  );
};

type ApplictionStatusProps = {
  status: EnumType<typeof ENUMS.JobFreelancerStatus>;
};
export const ApplictionStatus = ({ status }: ApplictionStatusProps) => {
  if (noPillForStatus(status)) return null;

  const variant = getApplicationStatusColorVariant(status);

  return (
    <Typography
      className={styles.applicationStatusPill}
      component="p"
      size="small"
      title={ENUMS.JobFreelancerStatusLabels[status]}
      ellipsis
      sx={{
        backgroundColor: variant.bgColor,
        color: variant.textColor,
      }}
    >
      {ENUMS.JobFreelancerStatusLabels[status]}
    </Typography>
  );
};

const noPillForStatus = (
  status?: EnumType<typeof ENUMS.JobFreelancerStatus>,
) => {
  return status === ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS || !status;
};

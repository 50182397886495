import cs from "classnames";

import {
  Loader,
  RoundedBox,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import { JobEmployerDetails } from "@js/apps/employer/components";
import { SimilarJobsSection } from "@js/apps/jobs/components";
import { ExperienceBox } from "@js/apps/jobs/components/experience-box";
import { Rate } from "@js/apps/jobs/components/rate";
import { RoleAndJobTypeBox } from "@js/apps/jobs/components/role-and-job-type-box";
import { ScreeningCard } from "@js/apps/jobs/components/screening-card";
import type { EmployerPublicProfile } from "@js/types/employer";
import type { FreelancerBid, Job } from "@js/types/jobs";

import {
  JobDetailsBasicDetails,
  JobHeader,
  JobStatus,
  LocationRequirementWarning,
  MatchersListing,
  ShareThisJobBubble,
} from "../../components";
import { JobDescription } from "../../components/job-description";
import { isJobCompleted } from "../../utils";

import style from "./style.module.scss";

type StandardJobDetailsProps = {
  job: Job;
  bid?: FreelancerBid;
  employer: EmployerPublicProfile | undefined;
};

export const StandardJobDetails = ({
  job,
  bid,
  employer,
}: StandardJobDetailsProps) => {
  const isMobile = useMediaQuery("sm");
  const isCompleted = isJobCompleted(job);
  const displayShareJobBubble = !isCompleted && !!job?.openings_left;
  const hideScreeningCard =
    isMobile ||
    job?.job_type === ENUMS.JobType.GRANT ||
    job?.status_for_freelancer !== ENUMS.JobFreelancerStatus.PENDING_APPROVAL;

  return (
    <>
      <main>
        <JobHeader job={job} bid={bid} withDetailsHeader />
        <section
          className={cs(style.jobDetails, {
            [style.jobDetailsWithoutSeniority]:
              !job.experience_level && !job.level,
          })}
        >
          <div className={style.jobStatusBox}>
            <JobStatus job={job} bid={bid} className={style.jobStatus} />
          </div>
          <div className={style.jobRate}>
            <Rate job={job} bid={bid} />
          </div>
          <div className={style.jobBasicDetails}>
            <JobDetailsBasicDetails
              boxProps={{
                className: style.jobBasicDetailsWrapper,
              }}
              job={job}
            />
          </div>
          <div className={style.jobWarningBadge}>
            <LocationRequirementWarning
              job={{
                ...job,
                strong_location_requirement_met:
                  job.strong_location_requirement_met,
                strong_timezone_requirement_met:
                  job.strong_timezone_requirement_met,
              }}
            />
          </div>
          <div className={style.jobSeniority}>
            <ExperienceBox
              experienceAsArrow={job.experience_as_arrow}
              experienceLevel={job.experience_level}
              jobRole={job.role}
              level={job.level}
            />
          </div>
          <div className={style.jobTopSkills}>
            <RoleAndJobTypeBox jobType={job.job_type} jobRole={job.role} />
          </div>
        </section>
        {displayShareJobBubble && (
          <div className={style.jobMobileRefer}>
            <ShareThisJobBubble job={job} />
          </div>
        )}
        <section className={style.jobContent}>
          <section style={{ overflow: "hidden" }}>
            <JobDescription job={job} />
            <div className={style.jobBottomStatusBox}>
              <JobStatus job={job} bid={bid} longTile />
            </div>
          </section>
          <section className={style.jobEmployerColumn}>
            {!hideScreeningCard && <ScreeningCard />}
            {displayShareJobBubble && (
              <div className={style.jobRefer}>
                <ShareThisJobBubble job={job} />
              </div>
            )}
            <div>
              {!employer ? (
                <Loader />
              ) : (
                <RoundedBox>
                  <JobEmployerDetails employer={employer} jobId={job.id} />
                </RoundedBox>
              )}
            </div>
            <div>
              <MatchersListing job={job} />
            </div>
            <div className={style.jobBottomRate}>
              <Rate job={job} bid={bid} isCompleted={isCompleted} />
            </div>
          </section>
          {displayShareJobBubble && (
            <div className={style.jobMobileRefer}>
              <ShareThisJobBubble job={job} />
            </div>
          )}
        </section>
      </main>
      <div className={style.jobSimilarJobs}>
        <SimilarJobsSection jobId={job.id} />
      </div>
    </>
  );
};

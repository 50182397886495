import type { FC } from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import type { PurchaseUpgradeParams } from "@js/apps/auth/api";

import { useDescriptionSection } from "./hooks/use-description-section";

type DescriptionSectionProps = {
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const DescriptionSection: FC<DescriptionSectionProps> = ({
  upgradeType,
}) => {
  const {
    extraContent,
    howMuchIsItContent,
    productDescriptionTitle,
    productDescriptionContent,
  } = useDescriptionSection({ upgradeType });

  return (
    <Box
      flex={1}
      paddingTop={4}
      paddingX={4}
      paddingBottom={7}
      display="flex"
      flexDirection="column"
      gap={3}
    >
      <Box display="flex" gap={3} flexDirection={{ xs: "column", md: "row" }}>
        <Box flex={0.5}>
          <Typography component="p" variant="label" fontWeight={500} mb={1.5}>
            {productDescriptionTitle}
          </Typography>
          <Box>{productDescriptionContent}</Box>
        </Box>
        <Box flex={0.5}>
          <Typography component="p" variant="label" fontWeight={500} mb={1.5}>
            How much is it?
          </Typography>
          <Typography component="p" variant="paragraph" size="small">
            {howMuchIsItContent}
          </Typography>
        </Box>
      </Box>
      {extraContent}
      <Box>
        <Typography component="p" variant="label" fontWeight={500} mb={1.5}>
          What payments methods do you accept?
        </Typography>
        <Typography component="p" variant="paragraph" size="small">
          You can pay with your preferred credit card, or for a more seamless
          experience, use Google Pay or Apple Pay. If you prefer a digital
          wallet, Link is also accepted.
        </Typography>
      </Box>
    </Box>
  );
};

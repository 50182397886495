import { useState } from "react";

import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { Button, Tooltip } from "@braintrust/braintrust-ui-components";
import { CheckIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  addNotInterestedJobId,
  deleteNotInterestedJobId,
} from "@js/apps/freelancer/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { MainJobListingJob } from "@js/types/jobs";

import { updateInvitationInterestedState } from "../../actions";

type NotInterestedButtonProps = {
  job: MainJobListingJob;
  fullWidth?: boolean;
} & Omit<ButtonProps, "variant">;

export const NotInterestedButton = ({
  job,
  fullWidth,
  ...props
}: NotInterestedButtonProps) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const notInterestedJobIds = useAppSelector(
    (state) => state.freelancer.notInterestedJobIds,
  );
  const isInterested = !notInterestedJobIds.includes(job.id);
  const variant = isInterested ? "secondary" : "dark-violet";
  const isInvited = job.invited_by_matcher || job.invited_by_client;
  const notInterestedAction = isInterested
    ? addNotInterestedJobId
    : deleteNotInterestedJobId;

  if (
    !isInvited ||
    !job.interested ||
    job.has_freelancer_bid ||
    !!job.rejected_at
  ) {
    return null;
  }

  const handleClick = () => {
    if (loading) return;

    setLoading(true);
    updateInvitationInterestedState(job.id, !isInterested)
      .then(() => {
        dispatch(notInterestedAction(job.id));
      })
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip
      title="Selecting ‘Not interested’ will remove this job from your invitations."
      placement="top"
      disabled={!isInterested}
    >
      <Button
        variant={variant}
        fullWidth={fullWidth}
        onClick={handleClick}
        sx={{
          height: { sm: 40 },
        }}
        {...props}
      >
        Not interested{" "}
        {!isInterested && <CheckIcon style={{ fontSize: 20, marginLeft: 4 }} />}
      </Button>
    </Tooltip>
  );
};

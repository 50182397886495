import { Loader } from "@braintrust/braintrust-ui-components";
import { MODULE_EDIT_MODAL_PROPERTIES } from "@js/apps/freelancer/constants";
import type { ServiceFormFields } from "@js/apps/freelancer/forms/add-ggh-services-form";
import {
  ServiceCategoryFormContent,
  ServiceDetailsFormContent,
  ServiceFormInstance,
} from "@js/apps/freelancer/forms/add-ggh-services-form";
import type { ServiceFormFields as ServiceProps } from "@js/apps/freelancer/forms/add-ggh-services-form/service-category-form";
import type { StepType } from "@js/apps/freelancer/hooks";
import { ADD_SERVICE_STEP, useAddServices } from "@js/apps/freelancer/hooks";
import { IsCategorySuggestedContextProvider } from "@js/apps/give-and-get-help/context";
import { ModalInstance } from "@js/components/modal";
import { assertUnreachable } from "@js/utils";

import { ServicesListModalContent } from "./services-list-modal";

type AddServicesManagerProps = {
  freelancerId: number;
  initialStep?: StepType;
  preselectedValues?: Partial<ServiceFormFields>;
};

const AddServicesManager = ({
  freelancerId,
  initialStep,
  preselectedValues,
}: AddServicesManagerProps) => {
  const {
    currentStep,
    goBack,
    initialValues,
    setStep,
    handleCategorySubmit,
    onSubmit,
    onSubmitSuccess,
    isLoading,
    services,
    deleteService,
    editService,
    onDiscardChanges,
  } = useAddServices(freelancerId, initialStep, preselectedValues);

  if (isLoading) return <Loader centered />;
  switch (currentStep) {
    case ADD_SERVICE_STEP.CATEGORY_FORM_MODAL_OPENED:
      return (
        <ServiceFormInstance
          initialValues={initialValues}
          onSubmit={handleCategorySubmit}
        >
          <ServiceCategoryFormContent
            freelancerId={freelancerId}
            openAddServices={openAddServices}
          />
        </ServiceFormInstance>
      );
    case ADD_SERVICE_STEP.BUDGET_FORM_MODAL_OPENED:
      return (
        <ServiceFormInstance
          initialValues={initialValues}
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
        >
          <ServiceDetailsFormContent
            onBack={goBack}
            onDiscardChanges={onDiscardChanges}
          />
        </ServiceFormInstance>
      );
    case ADD_SERVICE_STEP.SERVICES_LIST_MODAL_OPENED:
      return (
        <ServicesListModalContent
          setStep={setStep}
          services={services}
          deleteService={deleteService}
          editService={editService}
        />
      );
    default: {
      assertUnreachable(currentStep);
      return null;
    }
  }
};

export type OpenAddServicesProps = {
  onClose: () => void;
  freelancerId: number;
  initialStep?: StepType;
  isCategorySuggested?: boolean;
  preselectedValues?: Partial<ServiceFormFields>;
};

export const openAddServices = ({
  onClose,
  freelancerId,
  initialStep,
  isCategorySuggested = false,
  preselectedValues,
}: OpenAddServicesProps) =>
  ModalInstance.open({
    children: (
      <IsCategorySuggestedContextProvider initialValue={isCategorySuggested}>
        <AddServicesManager
          freelancerId={freelancerId}
          initialStep={initialStep}
          preselectedValues={preselectedValues}
        />
      </IsCategorySuggestedContextProvider>
    ),
    onClose: onClose,
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });

type AddServicesSecondStep = {
  initialValues: Optional<ServiceProps, "budget">;
  onSubmit: (data: ServiceProps) => void;
  onSubmitSuccess: () => void;
  onClose: () => void;
};

export const openAddServicesSecondStep = ({
  initialValues,
  onSubmit,
  onSubmitSuccess,
  onClose,
}: AddServicesSecondStep) =>
  ModalInstance.open({
    children: (
      <ServiceFormInstance
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      >
        <ServiceDetailsFormContent
          onBack={onClose}
          onDiscardChanges={onClose}
        />
      </ServiceFormInstance>
    ),
    onClose: onClose,
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });

import type { ChangeEvent } from "react";
import React from "react";

import { Button, Typography } from "@braintrust/braintrust-ui-components";

export type SwitchButtonProps = {
  buttonText?: string;
  checked: boolean;
  onChange: (_: ChangeEvent | null, checked: boolean) => void;
};

export const SwitchButton = ({
  buttonText,
  checked,
  onChange,
  ...props
}: SwitchButtonProps) => {
  return (
    <Button
      onClick={() => {
        onChange(null, !checked);
      }}
      variant={checked ? "positive-3" : "white-grey"}
      shape="squared"
      aria-checked={checked}
      {...props}
    >
      <Typography component="span" color={checked ? "inherit" : "grey"}>
        {buttonText}
      </Typography>
    </Button>
  );
};

import React from "react";

import {
  Box,
  Button,
  Loader,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useStatsQuery } from "@js/apps/common/api";
import { useSignUpFinish } from "@js/apps/onboarding/hooks";
import { UsersThumbnailAvatars } from "@js/components/users-thumbnail-avatars";
import { CoreLayout } from "@js/layouts/core";

import { EXAMPLE_USERS } from "../../constants";
import { useSignUpEmailConfirmPage } from "../../hooks/sign-up-confirm";

import style from "./style.module.scss";

export const SignUpFinishPage: React.FC = () => {
  const { data: stats, isLoading: isLoadingStats } = useStatsQuery();
  const { loading, unverifiedUser, signUpError } = useSignUpFinish();
  const { handleEmailResend } = useSignUpEmailConfirmPage();

  const isLoading = loading || isLoadingStats;
  if (isLoading) {
    return <Loader centered />;
  }

  return (
    <CoreLayout>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: "20px",
          pt: { xs: "20px", sm: "80px" },
          textAlign: "center",
        }}
      >
        <Box sx={{ mb: 4.5 }}>
          <UsersThumbnailAvatars
            users={EXAMPLE_USERS}
            usersAmount={
              stats?.users_count_representation
                ? stats.users_count_representation
                : "0"
            }
            spacing={8}
            max={4}
          />
        </Box>
        {signUpError ? (
          <Typography component="p">
            {signUpError}
            {signUpError.includes("Invalid key") && (
              <>
                {" Please "}
                <Button
                  variant="transparent"
                  className={style.resendButton}
                  onClick={handleEmailResend}
                >
                  resend the email
                </Button>
              </>
            )}
          </Typography>
        ) : (
          unverifiedUser?.user_is_already_registered && (
            <Typography component="p">
              Account is already registered.
            </Typography>
          )
        )}
      </Stack>
    </CoreLayout>
  );
};

import {
  Box,
  ButtonCore,
  Loader,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";

export const EndSpacesMessage = () => {
  const isTablet = useMediaQuery("md");

  return (
    <Box
      mt={3}
      display="flex"
      justifyContent="center"
      flexDirection={isTablet ? "column" : "row"}
      gap={isTablet ? "8px" : undefined}
    >
      <Typography component="p" textAlign={isTablet ? "center" : undefined}>
        You've reached the end of the spaces. Thanks for stopping by!
      </Typography>
      <Box
        component={ButtonCore}
        sx={{ textDecoration: "underline" }}
        ml={1}
        fontWeight={400}
        fontSize={16}
        onClick={() =>
          window?.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
      >
        Back to top
      </Box>
    </Box>
  );
};

export const SpacesLoadingState = () => {
  return (
    <Box
      display="grid"
      justifyContent="center"
      alignContent="center"
      width="100%"
      minHeight={200}
    >
      <Loader />
    </Box>
  );
};

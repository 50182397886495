import { useMemo, useState } from "react";
import cs from "classnames";

import type { ButtonVariants } from "@braintrust/braintrust-ui-components";
import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { CopyIcon } from "@braintrust/braintrust-ui-components/Icons";
import { clickCopyLink } from "@js/apps/dashboard/actions";
import type { ReferType } from "@js/apps/dashboard/types";
import { useAppDispatch } from "@js/hooks";

import styles from "./style.module.scss";

export type CopyLinkBoxVariant = "modal" | "card";

export type CopyLinkBoxProps = {
  referralLink: string;
  handleCopy: (text: string) => void;
  smallText?: boolean;
  shortCopyText?: boolean;
  variant?: CopyLinkBoxVariant;
  type: ReferType;
  iconColorVariant?: "medium-blue" | "soft-orange";
};

export const CopyLinkBox = ({
  referralLink,
  handleCopy,
  smallText = false,
  shortCopyText = false,
  variant = "modal",
  type,
  iconColorVariant = "soft-orange",
}: CopyLinkBoxProps) => {
  const url = new URL(referralLink);
  const isMobile = useMediaQuery("md");
  const isModalVariant = variant === "modal";
  const isCardVariant = variant === "card";
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleOnCopy = () => {
    handleCopy(referralLink);
    setIsCopied(true);
    dispatch(clickCopyLink(type));
  };

  const copyButtonVariant: ButtonVariants = useMemo(() => {
    if (isCardVariant && isCopied) return "positive";

    if (isCardVariant) return "primary";

    return "transparent";
  }, [isCardVariant, isCopied]);

  const copyButtonLabel: string = useMemo(() => {
    if (isCardVariant && isCopied) return "Link copied";

    if (isMobile || shortCopyText) return "Copy";

    return "Copy link";
  }, [isCardVariant, isCopied, isMobile, shortCopyText]);

  return (
    <div
      className={cs(styles.container, {
        [styles.modal]: isModalVariant,
        [styles.card]: isCardVariant,
        [styles.modalSmallText]: smallText && isModalVariant,
      })}
    >
      {isCardVariant && (
        <Box
          className={styles.cardIcon}
          sx={{ backgroundColor: `var(--${iconColorVariant})` }}
        >
          <img
            src={`${SETTINGS.STATIC_URL}referral/chain-icon.png`}
            alt="chain icon"
            width="24"
            height="25"
          />
        </Box>
      )}

      <Typography
        component="span"
        size={isMobile || smallText ? "small" : "medium"}
        fontWeight={400}
        title={url.href}
        height="100%"
        flexGrow={1}
      >
        <input
          className={styles.linkDisplayInput}
          value={url.href}
          onFocus={(e) => e.target.select()}
          tabIndex={-1}
          readOnly
        ></input>
      </Typography>

      <Button
        variant={copyButtonVariant}
        size={smallText ? "x-small" : "small"}
        startIcon={isModalVariant ? <CopyIcon /> : undefined}
        onClick={handleOnCopy}
        style={{ flexShrink: 0 }}
      >
        {copyButtonLabel}
      </Button>
    </div>
  );
};

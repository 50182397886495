import React, { useMemo } from "react";

import { List } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { CommonTeamMembersModalInvitationItem } from "@js/apps/employer/components/common-team-members-modal";
import type { EmployerTeamMemberType } from "@js/types/employer";
import type {
  JobSubscriberData,
  JobSubscriberInvitationData,
} from "@js/types/jobs";

import { getSortedTeamMembers } from "./helpers";
import { JobSubscribersListItem } from "./job-subscribers-list-item";

export type JobSubscribersListProps = {
  jobOwnerId?: number;
  teamMembers: EmployerTeamMemberType[];
  jobSubscribers: JobSubscriberData[];
  editedJobSubscribers: JobSubscriberData[];
  jobSubscriberInvitations: JobSubscriberInvitationData[];
  toggleJobSubscriber: (jobSubscriberData: JobSubscriberData) => void;
  toggleJobSubscriberInvitation: (
    jobSubscriberInvitationData: JobSubscriberInvitationData,
  ) => void;
};

export const JobSubscribersList = ({
  jobOwnerId,
  editedJobSubscribers,
  jobSubscribers,
  teamMembers,
  jobSubscriberInvitations,
  toggleJobSubscriber,
  toggleJobSubscriberInvitation,
}: JobSubscribersListProps) => {
  const user = useUser();

  const sortedTeamMembers = useMemo(
    () => getSortedTeamMembers({ teamMembers, jobOwnerId, jobSubscribers }),
    [teamMembers, jobOwnerId, jobSubscribers],
  );

  if (!user) {
    return <></>;
  }

  return (
    <List sx={{ display: "flex", flexDirection: "column", p: 0, gap: 2 }}>
      {jobSubscriberInvitations.map((invitation) => {
        const handleEmailInvitationRemove = () => {
          toggleJobSubscriberInvitation(invitation);
        };
        return (
          <CommonTeamMembersModalInvitationItem
            key={invitation.email}
            invitationEmail={invitation.email}
            onRemove={handleEmailInvitationRemove}
          />
        );
      })}
      {sortedTeamMembers.map((teamMember) => {
        const handleToggleJobSubscriber = () => {
          toggleJobSubscriber({ team_member_id: teamMember.id });
        };

        return (
          <JobSubscribersListItem
            key={teamMember.id}
            teamMember={teamMember}
            jobOwnerId={jobOwnerId}
            currentUserId={user.id}
            jobSubscribers={jobSubscribers}
            editedJobSubscribers={editedJobSubscribers}
            toggleJobSubscriber={handleToggleJobSubscriber}
          />
        );
      })}
    </List>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import type { SpaceBadge } from "@js/types/spaces";

import styles from "../style.module.scss";

export type SpaceCardBadgeProps = {
  badge: SpaceBadge;
  inviter?: string | undefined;
};

export const FOR_YOU_BADGE = "for_you";

export const SpaceCardBadge = ({ badge, inviter }: SpaceCardBadgeProps) => {
  if (badge === FOR_YOU_BADGE) {
    return <Container>⭐️ For you</Container>;
  }

  return (
    <Container>
      ✉️ Invited by{" "}
      <Typography variant="label" component="span" size="small">
        {inviter}
      </Typography>
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren) => {
  return (
    <Box className={styles.cardBadge}>
      <Typography variant="paragraph" component="p" size="small" ellipsis>
        {children}
      </Typography>
    </Box>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { useAppSelector } from "@js/hooks";

import { useGetFreelancerProfileCompletionQuery } from "../../api";
import { ProfileCompletionFeedback } from "../profile-completion/feedback";
import { ProgressBarNudge } from "../progress-bar-nudge";

import styles from "./style.module.scss";

export const NavigationProfileCompletionNudgeCard = (): JSX.Element | null => {
  const freelancerProfile = useFreelancerProfile();
  const canBid = freelancerProfile?.can_bid;
  const freelancerId = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.id,
  );
  const { data: profileCompletion } = useGetFreelancerProfileCompletionQuery(
    { freelancerId: freelancerId as number },
    { skip: !freelancerId || canBid },
  );

  if (!freelancerProfile || !profileCompletion || canBid) {
    return null;
  }

  return (
    <Box className={styles.profileCompletionNavCard}>
      <Typography
        component="p"
        variant="paragraph"
        size="medium"
        fontWeight={400}
        mb={2}
        color="white"
      >
        Your profile is {profileCompletion.completion_percent}% complete
      </Typography>
      <ProgressBarNudge
        value={profileCompletion.completion_percent}
        darkProgressBar
        height={"13px"}
      />
      <Typography
        component="p"
        color="white"
        style={{ fontSize: "11px", lineHeight: "17px", margin: "12px 0" }}
      >
        Complete your profile to apply for jobs and go through our Braintrust
        Approved Talent screening.
      </Typography>
      <ProfileCompletionFeedback
        missingAreas={profileCompletion.missing_areas}
      />
    </Box>
  );
};

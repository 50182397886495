import React from "react";
import { useSearchParams } from "react-router-dom";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box } from "@braintrust/braintrust-ui-components";
import { ClockIcon } from "@braintrust/braintrust-ui-components/Icons";
import { CommitmentForm } from "@js/apps/common/components/filters/config";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { HoursPerWeekSliderField } from "@js/forms/fields/hours-per-week-slider";
import { PossibleFilters } from "@js/types/common";

import { JobPopoverFilterButton } from "../../components";

const formId = "job-filters__commitment";

const initialButtonLabel = "Commitment";

export const CommitmentFilter = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [params] = useSearchParams();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.COMMITMENT,
      filter_value: values,
    });
  };

  const availabilityFrom = params.get("availability_from");
  const availabilityTo = params.get("availability_to");
  const isActive = Boolean(availabilityFrom && availabilityTo);

  const buttonLabel = isActive
    ? getHoursLabel([Number(availabilityFrom), Number(availabilityTo)], {
        equal: (value: number) => `${value} hrs/wk`,
        different: (value: number[]) => `${value[0]}-${value[1]} hrs/wk`,
      })
    : initialButtonLabel;

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      label={buttonLabel}
      startIcon={<ClockIcon />}
      isActive={isActive}
      popoverContent={
        <CommitmentForm
          form={formId}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Field
                submit={submit}
                name="availability"
                component={CommitmentField}
                close={() => setIsOpen(false)}
                buttonLabel={buttonLabel}
              />
            );
          }}
        </CommitmentForm>
      }
    />
  );
};

const initialValue = [5, 40];

type CommitmentFieldProps = {
  submit: () => void;
  close: () => void;
  buttonLabel: string;
} & TypedWrappedFieldProps<number[] | "">;
const CommitmentField = ({
  buttonLabel,
  input,
  submit,
  meta,
  close,
}: CommitmentFieldProps) => {
  const handleReset = () => {
    input.onChange(null);
  };

  const handleSubmit = () => {
    if (meta.pristine && buttonLabel !== initialButtonLabel) return close();
    submit();
  };

  return (
    <JobPopoverFilterButton.Content
      onApply={handleSubmit}
      onReset={handleReset}
    >
      <HoursPerWeekSliderField
        meta={meta}
        minLabelProps={{
          size: "medium",
          color: "grey-3",
          mr: 2.5,
          component: "label",
        }}
        maxLabelProps={{
          size: "medium",
          color: "grey-3",
          ml: 2.5,
          component: "label",
        }}
        input={{
          ...input,
          value: input.value || initialValue,
        }}
      />
      <Box mt={0.5} mb={-1} display="flex" justifyContent="center" gap={1}>
        <Label value={input.value || initialValue} />
      </Box>
    </JobPopoverFilterButton.Content>
  );
};

const Label = ({ value }: { value: string | number[] }) => {
  const [start, end] = value || initialValue;

  const preposition = start === end ? "⏰" : "⏰ Between";
  const inputValue = start === end ? start : `${start} - ${end}`;

  return (
    <>
      {preposition} <span className="fw-600">{inputValue}</span> hours per week
    </>
  );
};

const getHoursLabel = (
  values: [number, number],
  {
    equal,
    different,
  }: {
    equal: (value: number) => string;
    different: (value: number[]) => string;
  },
) => {
  if (values[0] === values[1]) {
    return equal(values[0]);
  } else {
    return different(values);
  }
};

import type { FormErrors } from "redux-form";
import { getFormSyncErrors } from "redux-form";

import {
  Box,
  FormHelperText,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { REQUEST_FORM_ID } from "@js/apps/give-and-get-help/components/request-refund-modal/constants";
import type { RequestRefundModalFormData } from "@js/apps/give-and-get-help/components/request-refund-modal/types";
import { useAppSelector } from "@js/hooks";

import styles from "./styles.module.scss";

export const RequestRefundErrorSection = () => {
  const syncErrorsObj: FormErrors<RequestRefundModalFormData> = useAppSelector(
    (state) => getFormSyncErrors(REQUEST_FORM_ID)(state),
  );

  return (
    <Box>
      <FormHelperText className={styles.errorMessage} error>
        <Typography component="span" variant="paragraph" size="small">
          {syncErrorsObj.refund_requested_reason}
        </Typography>
      </FormHelperText>
    </Box>
  );
};

import cs from "classnames";

import { Loader, Typography } from "@braintrust/braintrust-ui-components";
import { InfoIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { assertUnreachable } from "@js/utils";

import styles from "./styles.module.scss";

enum UploadJobDescriptionStatus {
  PENDING = "PENDING",
  UPLOADING = "UPLOADING",
  FAILED = "FAILED",
  PROCESSED = "PROCESSED",
}

export const JobDescriptionUpdateStatusTag = () => {
  const { status, fileName, error } = useGetJobDescriptionUploadStatus();

  switch (status) {
    case UploadJobDescriptionStatus.UPLOADING:
      return (
        <div
          className={cs(
            styles.jobDescriptionUploadStatusTagWrapper,
            styles.jobDescriptionUploadStatusTagWrapperUploading,
          )}
        >
          <div className={styles.jobDescriptionUploadStatusTagLoader}>
            <Loader color="info" size={24} />
          </div>
          <Typography component="p" color="blue" size="small">
            Uploading <b>{fileName}</b>
          </Typography>
        </div>
      );
    case UploadJobDescriptionStatus.FAILED:
      return (
        <div
          className={cs(
            styles.jobDescriptionUploadStatusTagWrapper,
            styles.jobDescriptionUploadStatusTagWrapperFailed,
          )}
        >
          <InfoIcon className={styles.jobDescriptionUploadStatusTagInfoIcon} />
          <Typography
            component="p"
            color="negative"
            size="small"
            fontWeight={500}
          >
            {error}
          </Typography>
        </div>
      );
    case UploadJobDescriptionStatus.PROCESSED:
      return (
        <div
          className={cs(
            styles.jobDescriptionUploadStatusTagWrapper,
            styles.jobDescriptionUploadStatusTagWrapperProcessed,
          )}
        >
          <Typography component="p" color="primary" size="small">
            Please review the details of your job post to ensure it has the
            correct information.
          </Typography>
        </div>
      );
    case UploadJobDescriptionStatus.PENDING:
      return null;
    default:
      assertUnreachable(status);
      return null;
  }
};

const useGetJobDescriptionUploadStatus = () => {
  const {
    isFetchingJobDataFromUploadedFile,
    updateJobFormWithFileDataError,
    updateJobFormWithFileDataFinished,
    uploadingFileName,
    uploadError,
    isUploading,
  } = useJobFormContext();

  return {
    status: getStatus({
      isUploading,
      isFetchingJobDataFromUploadedFile,
      uploadError,
      updateJobFormWithFileDataError,
      updateJobFormWithFileDataFinished,
      uploadingFileName,
    }),

    fileName: uploadingFileName,
    error: uploadError || updateJobFormWithFileDataError,
  };
};

const getStatus = ({
  isUploading,
  isFetchingJobDataFromUploadedFile,
  uploadError,
  updateJobFormWithFileDataError,
  updateJobFormWithFileDataFinished,
  uploadingFileName,
}: {
  isUploading: boolean;
  isFetchingJobDataFromUploadedFile: boolean;
  uploadError: string | undefined;
  updateJobFormWithFileDataError: string | null;
  updateJobFormWithFileDataFinished: boolean;
  uploadingFileName: string | undefined;
}): UploadJobDescriptionStatus => {
  if (isUploading || isFetchingJobDataFromUploadedFile)
    return UploadJobDescriptionStatus.UPLOADING;

  if (uploadError || updateJobFormWithFileDataError)
    return UploadJobDescriptionStatus.FAILED;

  if (updateJobFormWithFileDataFinished)
    return UploadJobDescriptionStatus.PROCESSED;

  if (!!uploadingFileName) return UploadJobDescriptionStatus.UPLOADING; // to handle split second when between first fetch ended and second did not yet start (isUploading and isFetchingJobDataFromUploadedFile are both false)

  return UploadJobDescriptionStatus.PENDING;
};

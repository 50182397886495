import type {
  TypedWrappedFieldProps,
  WrappedFieldInputProps,
} from "redux-form";

import { Box } from "@braintrust/braintrust-ui-components";
import { UserSearchMultipleField } from "@js/components/autocomplete-new/combobox-multiple/user";
import type { JobFormValues } from "@js/types/jobs";

type JobOwnersFieldsProps = {
  op_owners: TypedWrappedFieldProps<JobFormValues["op_owners"]>;
  sale_owners: TypedWrappedFieldProps<JobFormValues["sale_owners"]>;
};

export const JobOwnersFields = ({
  op_owners,
  sale_owners,
}: JobOwnersFieldsProps) => {
  return (
    <>
      <Box mb={2}>
        <UserSearchMultipleField
          input={op_owners.input as WrappedFieldInputProps}
          meta={op_owners.meta}
          label="Add Talent Specialist"
          placeholder="Select Owner(s)"
          endpointParams={{ return_ops_only: true, page_size: 100 }}
        />
      </Box>
      <Box>
        <UserSearchMultipleField
          input={sale_owners.input as WrappedFieldInputProps}
          meta={sale_owners.meta}
          label="Add Sales Specialist"
          placeholder="Select Owner(s)"
          endpointParams={{ return_sales_only: true, page_size: 100 }}
        />
      </Box>
    </>
  );
};

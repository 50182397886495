import { useEffect, useState } from "react";

import { Button, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import type { StepType } from "@js/apps/freelancer/hooks";
import { ADD_SERVICE_STEP } from "@js/apps/freelancer/hooks";
import { useUpdateHelpServicePositionMutation } from "@js/apps/give-and-get-help/api";
import { SortableList } from "@js/components/sortable-list/motion";
import { useDebouncedMutation } from "@js/hooks";
import type { HelpService } from "@js/types/give-and-get-help";

import { ServiceCard } from "../service-card";

import { openDeleteServiceModal } from "./delete-service-modal";

type ServicesListModalContentProps = {
  services?: HelpService[];
  setStep: (step: StepType) => void;
  deleteService: (id: number) => void;
  editService: (service: HelpService) => void;
};

export const ServicesListModalContent = ({
  services,
  setStep,
  deleteService,
  editService,
}: ServicesListModalContentProps) => {
  const [displayedServices, setDisplayedServices] = useState(services);
  const [updateHelpServicePosition] = useDebouncedMutation(
    useUpdateHelpServicePositionMutation,
  );
  const isSmall = useMediaQuery("sm");

  useEffect(() => {
    setDisplayedServices(services);
  }, [services]);

  if (!displayedServices) {
    return null;
  }

  const handleReorder = (itemPositions: HelpService[]) => {
    setDisplayedServices(itemPositions);
    updateHelpServicePosition({ itemPositions });
  };

  return (
    <>
      <Typography
        component="h3"
        variant={isSmall ? "paragraph" : "title"}
        size={isSmall ? "large" : "small"}
        fontWeight={400}
      >
        Services
      </Typography>
      <SortableList<HelpService>
        items={displayedServices}
        onReorder={handleReorder}
        style={{
          display: "grid",
          gap: "24px",
          marginTop: isSmall ? "24px" : "32px",
        }}
      >
        {(service) => {
          return (
            <ServiceCard
              key={service.id}
              service={service}
              onEdit={() => editService(service)}
              onDelete={() => openDeleteServiceModal(service, deleteService)}
            />
          );
        }}
      </SortableList>
      <Button
        variant="tertiary"
        style={{ marginTop: isSmall ? "24px" : "32px" }}
        onClick={() => {
          setStep(ADD_SERVICE_STEP.CATEGORY_FORM_MODAL_OPENED);
        }}
      >
        Add a service
      </Button>
    </>
  );
};

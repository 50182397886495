import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth";
import { ModalInstance } from "@js/components/modal";

import { openAddWithdrawalMethodModal } from "../add-withdrawal-method";

import styles from "./styles.module.scss";

const ChoosePayoutMethodModalContent = () => {
  const user = useUser();
  const isOTPEnabled = user?.is_otp_enabled;

  const onAddStripeCryptoAccountClick = () => {
    ModalInstance.close();
    if (!isOTPEnabled) {
      return openOTPDisabledWarningModal();
    }

    openAddWithdrawalMethodModal({
      choosenWithdrawalMethod:
        ENUMS.WithdrawalContentType.StripeWithdrawalMethod,
    });
  };

  const onAddBankingInformationClick = () => {
    ModalInstance.close();
    if (!isOTPEnabled) {
      return openOTPDisabledWarningModal();
    }

    openAddWithdrawalMethodModal({
      choosenWithdrawalMethod:
        ENUMS.WithdrawalContentType.TransferWiseWithdrawalMethod,
    });
  };

  return (
    <Box>
      <Box>
        <Typography mb={3} component="h1" variant="title" size="small">
          Please choose your payout method
        </Typography>
        <Box>
          {SETTINGS.STRIPE_WITHDRAWALS_ENABLED && (
            <Button
              variant="secondary"
              onClick={onAddStripeCryptoAccountClick}
              fullWidth
            >
              Stripe account
            </Button>
          )}
        </Box>
        <Box mt={2}>
          <Button
            variant="secondary"
            fullWidth
            onClick={onAddBankingInformationClick}
          >
            Add bank account
          </Button>
        </Box>
        <Box mt={2}>
          <Button variant="secondary" fullWidth onClick={ModalInstance.close}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const openChoosePayoutMethodModal = () => {
  ModalInstance.open({
    children: <ChoosePayoutMethodModalContent />,
    className: styles.modal,
  });
};

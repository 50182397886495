import { Box } from "@braintrust/braintrust-ui-components";
import { BasicInfo } from "@js/apps/jobs/components/basic-info";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemBasicInfoProps = {
  job: MainJobListingJob;
};

export const JobItemBasicInfo = ({ job }: JobItemBasicInfoProps) => {
  return (
    <Box className={styles.wrapper}>
      <BasicInfo job={job} />
    </Box>
  );
};

import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { CheckboxProps } from "@braintrust/braintrust-ui-components";
import { Checkbox } from "@braintrust/braintrust-ui-components";

export type RhfCheckboxFieldProps<T extends FieldValues> = CheckboxProps &
  UseControllerProps<T> & {
    id: CheckboxProps["id"];
    label: CheckboxProps["label"];
    control: Control<T>;
    mapValue?: (bool: boolean) => unknown;
    isChecked?: (value: unknown) => boolean;
  };

export const RhfCheckboxField = <T extends FieldValues>({
  id,
  label,
  mapValue = (bool) => bool,
  isChecked = (val) => !!val as boolean,
  ...props
}: RhfCheckboxFieldProps<T>) => {
  const { field, fieldState } = useController(props);

  return (
    <Checkbox
      id={id}
      label={label}
      {...props}
      {...field}
      value={field.value || ""}
      onChange={(_ev, checked) => {
        field.onChange(mapValue(checked));
      }}
      checked={isChecked(field.value)}
      ref={undefined}
      error={fieldState.error?.message}
    />
  );
};

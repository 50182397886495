import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Typography } from "@braintrust/braintrust-ui-components";
import { usePrepareSmartMatchingQuery } from "@js/apps/jobs/api";
import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { RichTextareaField } from "@js/forms/fields/rich-textarea";

type JobDescriptionProps = {
  meta?: TypedWrappedFieldProps<string>["meta"];
};

export const JobDescription = ({ meta }: JobDescriptionProps) => {
  const displayError = meta && !!meta.error && meta.touched;
  usePrepareSmartMatchingQuery(undefined, {
    skip: !meta?.dirty,
    pollingInterval: meta?.dirty ? 300000 : 0,
  });
  const { lastFormUpdateSourceFileId } = useJobFormContext();

  return (
    <FieldSectionPanel
      title="Job Description"
      id="tell-us-more-about-the-job-description"
      isError={displayError}
      titleProps={{
        component: "label",
        htmlFor: "description",
      }}
    >
      {displayError && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {meta.error}
        </Typography>
      )}
      <Field
        id="description"
        name="description"
        key={lastFormUpdateSourceFileId} // remount to update lexical state
        component={RichTextareaField}
        placeholder="Tell Talent more about the project, what they’ll be working on, what a typical week might look like, etc. You can also copy/paste your job description here."
        toolbar={[
          ["bold", "italic", "underline"],
          ["format"],
          ["align-left", "align-center", "align-right"],
          ["list", "indent"],
          ["emoji", "clear-formatting"],
          ["undo", "redo"],
        ]}
        maxHeight={1757}
        hideError
      />
    </FieldSectionPanel>
  );
};

// Deprecated but old jobs can have introduction and don't have description
export const JobIntroduction = ({ meta }: JobDescriptionProps) => {
  const displayError = meta && !!meta.error && meta.touched;
  usePrepareSmartMatchingQuery(undefined, {
    skip: !meta?.dirty,
    pollingInterval: meta?.dirty ? 300000 : 0,
  });

  return (
    <FieldSectionPanel
      title="Tell us more about the job"
      description="What will the Talent be working on?"
      id="tell-us-more-about-the-job-introduction"
      isError={displayError}
      titleProps={{
        component: "label",
        htmlFor: "introduction",
      }}
    >
      {displayError && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {meta.error}
        </Typography>
      )}
      <Field
        id="introduction"
        name="introduction"
        component={RichTextareaField}
        toolbar={[
          ["bold", "italic", "underline"],
          ["format"],
          ["align-left", "align-center", "align-right"],
          ["list", "indent"],
          ["emoji", "clear-formatting"],
          ["undo", "redo"],
        ]}
        placeholder="Tell Talent more about the project, what they’ll be working on, what a typical week might look like, etc. You can also copy/paste your job description here."
        maxHeight={1757}
        hideError
      />
    </FieldSectionPanel>
  );
};

import { useMemo, useState } from "react";
import { Fields } from "redux-form";

import { ClockIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components/job-popover-filter-button";
import { PossibleFilters } from "@js/types/common";

import type { WorkingTimezoneTalents } from "../../config";
import {
  HOURS_OVERLAP_MAP,
  useTalentFilters,
  workingTimezoneTalentFilterShape,
  WorkingTimezoneTalentsForm,
} from "../../config";

import {
  fieldNames,
  WorkingTimezoneTalentsFields,
} from "./working-timezone-talents-fields";

const formId = "filters__working_timezone_talents";

export const WorkingTimezoneTalentsFilter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { filters } = useTalentFilters();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: WorkingTimezoneTalents) => {
    handleFilterApplied({
      filter_type: PossibleFilters.WORKING_TIMEZONE,
      filter_value: values,
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const filterState = useMemo(() => getFilterState(filters), [filters]);

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      PaperProps={{ sx: { maxHeight: "580px", width: "520px" } }}
      isActive={filterState.isActive}
      label={filterState.label}
      startIcon={<ClockIcon />}
      popoverContent={
        <>
          <WorkingTimezoneTalentsForm
            form={formId}
            onSubmitSuccess={() => {
              setIsOpen(false);
            }}
            onSubmitSideAction={handleSubmitSideAction}
          >
            {({ submit }) => (
              <Fields
                names={fieldNames}
                component={WorkingTimezoneTalentsFields}
                submit={submit}
                close={handleClose}
                validate={[validateValues]}
              />
            )}
          </WorkingTimezoneTalentsForm>
        </>
      }
    />
  );
};

const validateValues = (_value: unknown, allValues: WorkingTimezoneTalents) => {
  const areAllUnselected =
    !allValues || (!allValues.timezone && !allValues.hours_overlap);
  if (areAllUnselected) {
    return;
  }

  const parseResult = workingTimezoneTalentFilterShape.safeParse(allValues);
  if (!parseResult.success) {
    return "Please select both the timezone and the overlap";
  }

  return;
};

const defaultLabel = "Working hours";
const inactiveFilterState = {
  label: defaultLabel,
  isActive: false,
};

const getFilterState = (
  filters: Partial<WorkingTimezoneTalents>,
): { label: string; isActive: boolean } => {
  const parseResult = workingTimezoneTalentFilterShape.safeParse(filters);
  if (!parseResult.success) {
    return inactiveFilterState;
  }

  const timezone = SETTINGS.TIMEZONE_ABBREVIATIONS.find(
    (timezoneOption) => timezoneOption.utc_offset === parseResult.data.timezone,
  );

  if (!timezone) {
    return inactiveFilterState;
  }

  return {
    label: `${timezone.abbreviation} | ${
      HOURS_OVERLAP_MAP[parseResult.data.hours_overlap].onFilterButtonLabel
    }`,
    isActive: true,
  };
};

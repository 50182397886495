import {
  Box,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  CloseIcon,
  ErrorSVGIcon,
} from "@braintrust/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

export const WarningMessage = ({
  message,
  onClose,
}: {
  message?: string | JSX.Element;
  onClose: () => void;
}) => {
  if (!message) return <></>;

  return (
    <Box mt={3}>
      <Box
        px={1.5}
        py={1.5}
        mb={3}
        width="100%"
        bgcolor="var(--soft-red)"
        borderRadius={1}
        display="flex"
        position="relative"
      >
        <ErrorSVGIcon
          className={styles.errorIcon}
          style={{ color: "var(--negative-2)" }}
        />
        <Box ml={4.5} mr={4.5}>
          <Typography
            fontWeight={500}
            color="negative"
            component="p"
            variant="paragraph"
            size="small"
          >
            {message}
          </Typography>
        </Box>
        <Box>
          <IconButton
            className={styles.closeBtn}
            variant="transparent"
            size="small"
            aria-label="Close self promotion warning"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

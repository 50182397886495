import { Box, Typography } from "@braintrust/braintrust-ui-components";

type ReuploadCsvProps = {
  onClick: () => void;
};

export const ReuploadCsv = ({ onClick }: ReuploadCsvProps) => {
  return (
    <Box
      component="button"
      className="btn-reset pointer text-underline"
      onClick={onClick}
      sx={{ mt: "2px !important" }}
    >
      <Typography
        component="span"
        variant="link"
        size="small"
        color="secondary"
        sx={{ fontSize: "11px !important" }}
      >
        New connections? Re-upload your LinkedIn connections CSV.
      </Typography>
    </Box>
  );
};

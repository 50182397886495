import {
  Box,
  CircularProgressDecorator,
  IconButton,
  Menu,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { MoreVertIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { shareProfileClicked } from "@js/apps/employer/actions";
import { openShareTalentWithTeamMembersModal } from "@js/apps/employer/components/share-talent-with-team-members-modal";
import { useCanShareWithTeamMembers } from "@js/apps/employer/hooks/use-can-share-with-team-members";
import { useRemoveInvitationMutation } from "@js/apps/jobs/api";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { UserAvatar } from "@js/components/user-avatar";
import { useAppDispatch } from "@js/hooks/redux";
import type { TalentInviteSuggestion } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import { getMatchCircleMeta } from "../../apps/bids/components/bid-avatar-with-score-match/helpers";

import style from "./style.module.scss";

type InviteTalentProps = {
  jobId: number;
  freelancer: TalentInviteSuggestion;
};

export const ActionMenu = ({ jobId, freelancer }: InviteTalentProps) => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const [removeInvitation, { isLoading }] = useRemoveInvitationMutation();
  const { openMessengerModal } = useOpenMessengerModal();
  const canShareTalentWithMembers = useCanShareWithTeamMembers();

  return (
    <Menu
      anchor={
        <IconButton
          sx={{
            width: "42px",
            height: "42px",
          }}
          aria-label="more actions"
          variant="white-violet"
          shape="squared"
        >
          <MoreVertIcon />
        </IconButton>
      }
    >
      <Menu.Item
        onClick={() => {
          if (isLoading) return undefined;
          removeInvitation({ jobId, freelancerId: freelancer.id });
        }}
      >
        Remove
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          if (!user) {
            return;
          }
          openMessengerModal({
            jobId: jobId,
            context: { participants: [user?.id, freelancer.user.id] },
          });
        }}
      >
        Message
      </Menu.Item>
      {canShareTalentWithMembers && (
        <Menu.Item
          onClick={() => {
            dispatch(shareProfileClicked());
            openShareTalentWithTeamMembersModal({
              talentId: freelancer.id,
            });
          }}
        >
          Share profile
        </Menu.Item>
      )}
    </Menu>
  );
};

type InviteTalentStatusProps = {
  status: EnumType<typeof ENUMS.TalentInviteStatus> | null;
};

export const InviteTalentStatusLabel = ({
  status,
}: InviteTalentStatusProps) => {
  const { label, color } = getInviteTalentStatusMeta(status);

  if (!label) return null;

  return (
    <Typography component="h4" variant="paragraph" sx={{ color }}>
      {label}
    </Typography>
  );
};

const getInviteTalentStatusMeta = (
  status: InviteTalentStatusProps["status"],
) => {
  switch (status) {
    case ENUMS.TalentInviteStatus.DECLINED:
    case ENUMS.TalentInviteStatus.VIEWED:
      return {
        label: ENUMS.TalentInviteStatusLabels[status],
        color: "var(--dark-violet)",
      };

    case ENUMS.TalentInviteStatus.INVITED:
    case null:
      return {
        label: null,
        color: null,
      };
    default:
      assertUnreachable(status);
      return {
        label: null,
        color: null,
      };
  }
};

type InviteAvatarWithScoreMatchProps = {
  freelancer: TalentInviteSuggestion;
};

export const InviteAvatarWithScoreMatch = ({
  freelancer,
}: InviteAvatarWithScoreMatchProps) => {
  const { color, value } = getMatchCircleMeta({
    matchLabelKey: freelancer.match_label,
  });

  return (
    <Box width={1} height={1} className={style.inviteTalentCardAvatar}>
      <CircularProgressDecorator value={value} color={color} thickness={2.5}>
        <Box
          sx={{
            padding: "0.5px",
          }}
        >
          <UserAvatar
            user={freelancer.user}
            hideBorder
            disableShadow
            hideBadge
          />
        </Box>
      </CircularProgressDecorator>
    </Box>
  );
};

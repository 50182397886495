import React from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import { useAccountType } from "@js/apps/common/hooks";
import { JobSkillChip } from "@js/apps/skills/components";
import type { SkillForJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobSkillsPropsType = {
  skills: Array<SkillForJob>;
};

export const JobSkills = ({ skills = [] }: JobSkillsPropsType) => {
  const { isFreelancer } = useAccountType();

  if (!isFreelancer && !!skills.length) {
    return (
      <Box display="flex" flexWrap="wrap" gap={1}>
        {skills.map((skill) => {
          return (
            <Box key={skill?.id}>
              <JobSkillChip className={styles.skillChip} skill={skill} />
            </Box>
          );
        })}
      </Box>
    );
  }

  if (!skills.length) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {skills.map((skill) => {
          return (
            <Box key={skill?.id}>
              <JobSkillChip className={styles.skillChip} skill={skill} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";
import { destroy } from "redux-form";

import {
  Box,
  Button,
  Divider,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import type { OpenAddServicesProps } from "@js/apps/freelancer/components/ggh-services/modals/add-ggh-services";
import { useGetPostCategoriesQuery } from "@js/apps/give-and-get-help/api";
import type { PostCategoryOption } from "@js/apps/give-and-get-help/components/category-and-budget-utils";
import { getPostCategoriesOptions } from "@js/apps/give-and-get-help/components/category-and-budget-utils";
import { CreateNewCategoryPills } from "@js/apps/give-and-get-help/components/suggest-category-modal/create-new-category-pills";
import { createFormInstance } from "@js/forms/components";
import { ButtonSelectField } from "@js/forms/fields";
import { required } from "@js/forms/validators";
import { useAppDispatch } from "@js/hooks";

export const ADD_GGH_SERVICE_FORM_ID = "add-ggh-service-form";

export type ServiceFormFields = {
  id?: number;
  category: number;
  description: string;
  budget: string;
};

export const ServiceFormInstance = createFormInstance<
  ServiceFormFields,
  unknown
>(ADD_GGH_SERVICE_FORM_ID, {
  destroyOnUnmount: false,
});

type ServiceCategoryFormContentProps = {
  freelancerId: number;
  openAddServices: (props: OpenAddServicesProps) => void;
};

export const ServiceCategoryFormContent = ({
  freelancerId,
  openAddServices,
}: ServiceCategoryFormContentProps) => {
  const { destroyForm } = useClearAddServicesForm();
  const isSmall = useMediaQuery("sm");

  return (
    <>
      <Typography
        component="h3"
        variant={isSmall ? "paragraph" : "title"}
        size={isSmall ? "large" : "small"}
        fontWeight={400}
      >
        Services
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        size={isSmall ? "medium" : "large"}
        my={3}
      >
        What kind of help do you want to offer to the community?
      </Typography>

      <Field
        name="category"
        component={CategorySelectField}
        validate={[required]}
      />

      <Box mt={isSmall ? 3 : 4}>
        <CreateNewCategoryPills
          onSuggestCategorySuccess={() =>
            openAddServices({
              onClose: destroyForm,
              freelancerId,
              isCategorySuggested: true,
            })
          }
          onSuggestCategoryFail={() =>
            openAddServices({
              onClose: destroyForm,
              freelancerId,
            })
          }
          onSuggestCategoryCancel={() =>
            openAddServices({ onClose: destroyForm, freelancerId })
          }
        />
      </Box>

      <Divider color="light-grey" sx={{ m: isSmall ? "24px 0" : "32px 0" }} />

      <Button
        variant="positive-2"
        size="medium"
        shape="squared"
        type="submit"
        style={{ float: "right" }}
      >
        Next
      </Button>
    </>
  );
};

const CategorySelectField = ({
  input,
  meta,
}: TypedWrappedFieldProps<PostCategoryOption["id"]>) => {
  const { data, isLoading } = useGetPostCategoriesQuery();
  const isSmall = useMediaQuery("sm");

  if (isLoading || !data?.length) return null;

  const categoryOptions = getPostCategoriesOptions(data, false);

  return (
    <>
      <Typography component="p" variant="paragraph" mb={2}>
        <b>Choose a category</b> (select one)
      </Typography>

      <ButtonSelectField
        input={input}
        meta={meta}
        multiple={false}
        options={categoryOptions}
        canSingleValueBeEmpty
        variant="white-violet"
        selectedVariant="violet-with-dark-border--violet-text"
        fontWeight={400}
        typographySize={isSmall ? "small" : "medium"}
        size={isSmall ? "small" : "medium"}
      />
    </>
  );
};

export const useClearAddServicesForm = () => {
  const dispatch = useAppDispatch();

  return {
    destroyForm: () => dispatch(destroy(ADD_GGH_SERVICE_FORM_ID)),
  };
};

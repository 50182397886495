import type { WrappedFieldProps, WrappedFieldsProps } from "redux-form";
import { submit } from "redux-form";

import {
  Box,
  Button,
  Tooltip,
  Typography,
} from "@braintrust/braintrust-ui-components";
import type { PostCategoryOption } from "@js/apps/give-and-get-help/components";
import { InfoTooltip } from "@js/components/info-tooltip";
import { useAppDispatch } from "@js/hooks";

import { MessageOfferBudget } from "./budget";
import { MessageOfferCategory } from "./category";

import styles from "./style.module.scss";

const OFFER_LABEL = "Offer 1:1 help";
const TOOLTIP_TEXT = `Earn BTRST by offering your expertise to other users within the Braintrust Network.
  By helping others with their professional development you're helping grow and improve the network.`;
const CATEGORY_LABEL = "What are you offering help with?";
const BUDGET_LABEL = "Set your rate";

export type PostCategoryFieldProps = WrappedFieldProps & {
  categories: PostCategoryOption[];
};

export type MessageOfferProps = {
  postCategories: PostCategoryFieldProps["categories"];
  className: string;
  canNotSendMessageReason: string;
  canSendMessage: boolean;
};

const budgetOptions = [{ label: "Free", value: "0" }];

// TODO: Merge with CreatePostCategory to create one reusable component
export const HelpOfferField = ({
  category,
  postCategories,
  budget,
  className,
  canNotSendMessageReason,
  canSendMessage,
  ...props
}: MessageOfferProps & WrappedFieldsProps) => {
  const formId = budget.meta.form;
  const isOfferNotCompleted = [category, budget].some(
    (field) => field.input.value === "",
  );
  const isSubmitBtnDisabled = isOfferNotCompleted || !canSendMessage;

  return (
    <Box className={styles.modalContent}>
      <Box className={styles.helpOffer} {...props}>
        <Typography component="p" variant="label" size="small" mb={1}>
          {OFFER_LABEL}
          <InfoTooltip title={TOOLTIP_TEXT} />
        </Typography>
        <MessageOfferCategory
          input={category.input}
          meta={category.meta}
          categories={postCategories}
          label={CATEGORY_LABEL}
        />
        <MessageOfferBudget
          input={budget.input}
          meta={budget.meta}
          options={budgetOptions}
          label={BUDGET_LABEL}
        />
      </Box>
      <SendMessageButton
        formId={formId}
        canNotSendMessageReason={canNotSendMessageReason}
        isDisabled={isSubmitBtnDisabled}
      />
    </Box>
  );
};

type SendMessageButtonProps = {
  formId: string;
  canNotSendMessageReason: string;
  isDisabled: boolean;
};

const SendMessageButton = ({
  formId,
  canNotSendMessageReason,
  isDisabled,
}: SendMessageButtonProps) => {
  const dispatch = useAppDispatch();
  return (
    <Tooltip
      title={canNotSendMessageReason}
      disabled={!canNotSendMessageReason}
    >
      <span>
        <Button
          variant="positive"
          disabled={isDisabled}
          type="submit"
          //https://stackoverflow.com/a/56970849/15536964 -- (mobile) prevent hiding keyboard on Send button click
          onTouchEnd={(e) => {
            e.preventDefault();
            dispatch(submit(formId));
          }}
          shape="squared"
          fontWeight={400}
        >
          Send offer
        </Button>
      </span>
    </Tooltip>
  );
};

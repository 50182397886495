import { useCallback } from "react";
import cs from "classnames";

import {
  IconButton,
  Tooltip,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  DragHorizontalVariantIcon,
  StarFilledSVGIcon,
  StarSVGIcon,
  TrashIcon,
} from "@braintrust/braintrust-ui-components/Icons";

import { useGetSkillById } from "../../hooks";

import styles from "./style.module.scss";

type SelectedSkillItemProps = {
  skillId: number;
  isFavorite: boolean;
  onFavorite: (skillId: number) => void;
  onRemove: (skillId: number) => void;
  quantityFavoriteSkills: number;
  isMaxFavoriteSkillsCountReached?: boolean;
};

export const SelectedSkillItem = ({
  skillId,
  isFavorite,
  onFavorite,
  onRemove,
  quantityFavoriteSkills,
  isMaxFavoriteSkillsCountReached,
}: SelectedSkillItemProps) => {
  const { data: skill } = useGetSkillById({ id: skillId });
  const handleClickStar = useCallback(() => {
    onFavorite(skillId);
  }, [skillId, onFavorite]);

  const handleClickBin = useCallback(() => {
    onRemove(skillId);
  }, [skillId, onRemove]);

  const handleStarClickCapture = useCallback(
    (e: React.MouseEvent) => {
      if (isMaxFavoriteSkillsCountReached && !isFavorite) {
        e.stopPropagation();
      }
    },
    [isMaxFavoriteSkillsCountReached, isFavorite],
  );

  if (!skill) {
    return <></>;
  }

  return (
    <div
      className={cs(styles.item, {
        [`${styles.itemSuperpower}`]: isFavorite,
      })}
    >
      {!!isFavorite && quantityFavoriteSkills > 1 && (
        <DragHorizontalVariantIcon className={styles.itemDrag} />
      )}
      <IconButton
        variant="transparent"
        onClick={handleClickStar}
        onClickCapture={handleStarClickCapture}
        aria-label={`Toggle top skill ${skill.name}`}
      >
        <SelectedSkillItemStar
          isFavorite={isFavorite}
          isMaxFavoriteSkillsCountReached={isMaxFavoriteSkillsCountReached}
        />
      </IconButton>
      <Typography
        component="p"
        size="medium"
        variant="paragraph"
        sx={{ flex: 1 }}
      >
        {skill.name}
      </Typography>
      <IconButton
        variant="transparent"
        onClick={handleClickBin}
        aria-label="Delete skill"
      >
        <TrashIcon style={{ fontSize: 16 }} />
      </IconButton>
    </div>
  );
};

type SelectedSkillItemStarProps = {
  isFavorite?: boolean;
  isMaxFavoriteSkillsCountReached?: boolean;
};

const SelectedSkillItemStar = ({
  isFavorite,
  isMaxFavoriteSkillsCountReached,
}: SelectedSkillItemStarProps) => {
  if (isFavorite) {
    return (
      <StarFilledSVGIcon
        data-testid="selected-skill-filled-star"
        style={{ color: "var(--positive-2)" }}
      />
    );
  }

  if (isMaxFavoriteSkillsCountReached) {
    return (
      <Tooltip
        title="You have the max number of top skills. Remove one of your top skills in order to select a new one."
        placement="top"
        color="rgba(var(--black-rgb), 0.8)"
        maxWidth={216}
      >
        <StarFilledSVGIcon
          data-testid="selected-skill-filled-star"
          style={{ color: "var(--grey-4)" }}
        />
      </Tooltip>
    );
  }

  return <StarSVGIcon />;
};

import type { ComponentProps, ReactNode } from "react";

import {
  Box,
  LinearLoader,
  Typography,
} from "@braintrust/braintrust-ui-components";

type LinearLoaderProps = ComponentProps<typeof LinearLoader>;
type ProgressBarWithLabelProps = {
  label?: ReactNode;
  value: number;
  showProgressBar?: boolean;
} & DistributiveOmit<LinearLoaderProps, "value">;

const ProgressBarWithLabel = ({
  label,
  value,
  showProgressBar = true,
  ...linearLoaderProps
}: ProgressBarWithLabelProps) => (
  <Box sx={{ textAlign: "left" }} className="progress-bar-with-label">
    {!!label && (
      <Typography
        component="p"
        sx={{ mb: 0.5 }}
        className="progress-bar-with-label-title"
      >
        {label}
      </Typography>
    )}
    {!!showProgressBar && (
      <LinearLoader
        variant="determinate"
        {...linearLoaderProps}
        value={value}
        sx={{
          backgroundColor: "var(--grey-5)",
          height: "6px",
          borderRadius: "1rem",
          ".MuiLinearProgress-bar": {
            borderRadius: "1rem",
          },
          ...linearLoaderProps.sx,
        }}
      />
    )}
  </Box>
);

export default ProgressBarWithLabel;

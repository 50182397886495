import React from "react";

import { LinearLoader } from "@braintrust/braintrust-ui-components";
import { useAppSelector } from "@js/hooks";

import styles from "./style.module.scss";

const RequestLoader = () => {
  const loading = useAppSelector((state) => state.requestLoader.loading);
  const hideRequestLoader = useAppSelector(
    (state) => state.common.hideRequestLoader,
  );
  if (!loading || hideRequestLoader) {
    return null;
  }

  return (
    <div className={styles.requestLoader}>
      <LinearLoader color="secondary" />
    </div>
  );
};

export default RequestLoader;

import type { TypographySizes } from "@braintrust/braintrust-ui-components";
import { Box, Typography } from "@braintrust/braintrust-ui-components";
import type { JobListingJob } from "@js/types/jobs";

type EmployerNameAndJobTitleProps = {
  job: JobListingJob;
  employerNameSize?: TypographySizes;
  jobTitleSize?: TypographySizes;
};

export const EmployerNameAndJobTitle = ({
  job,
  employerNameSize = "large",
  jobTitleSize = "large",
}: EmployerNameAndJobTitleProps) => {
  return (
    <Box width="100%">
      <Typography
        component="h5"
        variant="label"
        size={employerNameSize}
        ellipsis
        className="capitalize-first-letter"
      >
        {job.employer.name}
      </Typography>
      <Typography
        component="h5"
        variant="paragraph"
        size={jobTitleSize}
        ellipsis
        className="capitalize-first-letter"
      >
        {job.title}
      </Typography>
    </Box>
  );
};

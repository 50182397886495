import {
  Avatar,
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import type { EmployerTeamMemberType } from "@js/types/employer";

type CommonTeamMembersModalListMemberItemDetailsProps = {
  isJobOwner: boolean;
  teamMember: EmployerTeamMemberType;
};

export const CommonTeamMembersModalListMemberItemDetails = ({
  teamMember,
  isJobOwner,
}: CommonTeamMembersModalListMemberItemDetailsProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        height: "100%",
        flex: 1,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          height: "100%",
          aspectRatio: "1/1",
          maxWidth: { xs: "32px", sm: "56px" }, // Avatar requires it regardless of aspect ratio to calculate correct typography sizes
        }}
      >
        <Avatar src={teamMember} size="full" color="medium-violet" />
      </Box>
      <Typography
        variant="label"
        component="p"
        size={isMobile ? "small" : "medium"}
        ellipsis
        sx={{ ml: { xs: 1, sm: 2 } }}
      >
        {teamMember.public_name}
      </Typography>
      {isJobOwner && (
        <Chip
          color="orange"
          label="Job Owner"
          variant="squared"
          size="small"
          sx={{ p: "4px !important", ml: 0.5, flexShrink: 0 }}
          LabelProps={{
            sx: {
              fontSize: "10px !important",
              fontWeight: 400,
              color: "var(--black)",
            },
          }}
        />
      )}
    </Stack>
  );
};

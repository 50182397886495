import React, { useMemo } from "react";
import cs from "classnames";

import {
  Box,
  Carousel,
  IconButton,
  Typography,
  useSwiperRefs,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useAppDispatch, useNavigate } from "@js/hooks";

import { clickTrendingHashtag } from "../../actions";
import { HASHTAG_QUERY_PARAM } from "../../constants";

import { TrendingHashtagsButton } from "./trending-hashtags-button";
import { assignColors } from "./trending-hashtags-config";

import styles from "./styles.module.scss";

type TrendingHashtagsCarouselProps = {
  options: Array<{
    label: string;
    value: number;
  }>;
};

export const TrendingHashtagsCarousel = ({
  options,
}: TrendingHashtagsCarouselProps) => {
  const { prevEl, prevElRef, nextEl, nextElRef } = useSwiperRefs();
  const isMobile = useMediaQuery("sm");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const optionsWithColors = useMemo(() => assignColors(options), [options]);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {isMobile ? (
          <Typography
            size="large"
            variant="paragraph"
            component="h4"
            flexGrow={1}
          >
            Trending hashtags
          </Typography>
        ) : (
          <Typography
            size="small"
            variant="title"
            component="h4"
            fontWeight={400}
            flexGrow={1}
          >
            Explore trending hashtags
          </Typography>
        )}
        <TrendingHashtagsNavigationButton ref={prevElRef} type="prev" />
        <TrendingHashtagsNavigationButton ref={nextElRef} type="next" />
      </Box>
      <Box className={styles.hashtagsCarouselSwiper}>
        <Carousel
          slides={optionsWithColors.map((option) => {
            return (
              <TrendingHashtagsButton
                key={option.label}
                option={option}
                onClick={() => {
                  dispatch(
                    clickTrendingHashtag({
                      hashtag_id: option.value as number,
                    }),
                  );
                  window.scrollTo({ top: 0 });
                  navigate(`/feed?${HASHTAG_QUERY_PARAM}=${option.value}`);
                }}
              />
            );
          })}
          threshold={10}
          resistance={false}
          spaceBetween={8}
          mousewheel
          navigation={{
            prevEl,
            nextEl,
          }}
          fadedBounds
          fadedBoundsClasses={{
            mode: "overwrite",
            previousFadeClassName:
              styles.hashtagsCarouselSwiperWithPreviousFades,
            nextFadeClassName: styles.hashtagsCarouselSwiperWithNextFades,
          }}
        />
      </Box>
    </Box>
  );
};

type TrendingHashtagsNavigationButtonProps = {
  type: "next" | "prev";
};

const TrendingHashtagsNavigationButton = React.forwardRef<
  HTMLButtonElement | null,
  TrendingHashtagsNavigationButtonProps
>(({ type }, ref) => {
  return (
    <Box
      className={cs(styles.hashtagsCarouselNavBtn, {
        [styles[`hashtags-carousel__nav-btn--${type}`]]: true,
      })}
    >
      <IconButton
        ref={ref}
        variant="transparent"
        aria-label="next"
        size="medium"
        className={styles.hashtagsCarouselNavBtnIconButton}
      >
        {type === "next" ? <ArrowRightIcon /> : <ArrowLeftIcon />}
      </IconButton>
    </Box>
  );
});

import React, { useMemo, useState } from "react";
import cx from "classnames";

import type { TooltipProps } from "@braintrust/braintrust-ui-components";
import { Box, IconButton, Tooltip } from "@braintrust/braintrust-ui-components";
import { FramerComponent } from "@js/apps/common/framer";
import { useIsTouchDevice } from "@js/apps/common/hooks";
import type { Sticker } from "@js/apps/give-and-get-help/constants";
import type { StickerValue } from "@js/types/give-and-get-help";

import styles from "./animated-sticker-button.module.scss";

export type AnimatedStickerButtonProps = AnimatedStickerButtonDesktopProps &
  AnimatedStickerButtonMobileProps;

const moveUpBy = 15;

export const AnimatedStickerButton = (props: AnimatedStickerButtonProps) => {
  const isTouchDevice = useIsTouchDevice();

  const AnimatedStickerButtonComponent = isTouchDevice
    ? AnimatedStickerButtonMobile
    : AnimatedStickerButtonDesktop;

  return <AnimatedStickerButtonComponent {...props} />;
};

type AnimatedStickerButtonDesktopProps = {
  onSelect: (value: StickerValue) => void;
} & Sticker;

export const AnimatedStickerButtonDesktop = ({
  value,
  title,
  IconComponent,
  onSelect,
  rotateOnHoverDirection,
}: AnimatedStickerButtonDesktopProps) => {
  const [isHover, setIsHover] = useState(false);

  const shouldAnimate = isHover;

  const onEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onSelect(value);
      event.preventDefault();
    }
  };

  return (
    <TooltipWithOffsetY
      title={title}
      open={shouldAnimate}
      offsetY={moveUpBy - 5}
    >
      <Box>
        <FramerComponent.div
          whileTap={{
            scale: 0.9,
          }}
          onKeyDown={onEnter}
        >
          <StickerIconButton
            value={value}
            title={title}
            IconComponent={IconComponent}
            shouldAnimate={shouldAnimate}
            rotateOnHoverDirection={rotateOnHoverDirection}
            onSelect={onSelect}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          />
        </FramerComponent.div>
      </Box>
    </TooltipWithOffsetY>
  );
};

type AnimatedStickerButtonMobileProps = {
  shouldAnimate: boolean;
} & Sticker;

export const AnimatedStickerButtonMobile = ({
  value,
  title,
  IconComponent,
  shouldAnimate,
  rotateOnHoverDirection,
}: AnimatedStickerButtonMobileProps) => {
  return (
    <TooltipWithOffsetY
      title={title}
      open={shouldAnimate}
      offsetY={moveUpBy - 5}
    >
      <Box>
        {/* wrap with box to silence mui error on tooltip children having title */}
        <StickerIconButton
          value={value}
          title={title}
          IconComponent={IconComponent}
          shouldAnimate={shouldAnimate}
          rotateOnHoverDirection={rotateOnHoverDirection}
        />
      </Box>
    </TooltipWithOffsetY>
  );
};

type StickerIconButtonProps = {
  shouldAnimate: boolean;
  onSelect?: (value: StickerValue) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & Sticker;

const StickerIconButton = ({
  shouldAnimate,
  value,
  title,
  IconComponent,
  onSelect,
  onMouseEnter,
  onMouseLeave,
  rotateOnHoverDirection = "left",
}: StickerIconButtonProps) => {
  const iconAnimation = useMemo(() => {
    return {
      scale: 1.2,
      translateY: `-${moveUpBy}px`,
      rotate: rotateOnHoverDirection === "left" ? "10deg" : "-10deg",
    };
  }, [rotateOnHoverDirection]);

  return (
    <IconButton
      variant="transparent"
      size="small"
      className={cx(styles.stickerButton, {
        [styles.stickerButtonAnimated]: shouldAnimate,
      })}
      aria-label={`select ${title} reaction`}
      data-animated={shouldAnimate}
      data-sticker={value} // used to select the element on touch devices
      data-testid={`${value}-sticker-button`}
      tabIndex={-1}
      onClick={onSelect ? () => onSelect(value) : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disableRipple
    >
      <FramerComponent.div animate={shouldAnimate ? iconAnimation : undefined}>
        <IconComponent sx={{ fontSize: 32 }} />
      </FramerComponent.div>
    </IconButton>
  );
};

type TooltipWithOffsetYProps = {
  offsetY: number;
} & TooltipProps;

const TooltipWithOffsetY = ({
  offsetY,
  children,
  ...rest
}: TooltipWithOffsetYProps) => {
  return (
    <Tooltip
      placement="top"
      arrow={false}
      {...rest}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, offsetY],
              },
            },
          ],
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

import React from "react";

import type {
  ButtonProps,
  ButtonVariants,
} from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { CompleteProfileButton } from "@js/apps/jobs/components/complete-profile-button";
import { NotLoggedInApplyButton } from "@js/apps/jobs/components/not-logged-in-apply-button";
import type { FreelancerBid, MainJobListingJob } from "@js/types/jobs";

import { BidButton } from "../../apps/bids/components";

type CompleteProfileOrSubmitButtonProps = {
  job: MainJobListingJob;
  bid?: FreelancerBid;
  sx?: ButtonProps["sx"];
  variant?: ButtonVariants;
  inverse?: boolean;
  onClick?: () => void;
  openInNewTab?: boolean;
  hideBoostButton?: boolean;
};

export const CompleteProfileOrSubmitButton = ({
  job,
  bid,
  onClick,
  hideBoostButton,
  ...props
}: CompleteProfileOrSubmitButtonProps) => {
  const user = useUser();
  const freelancerProfile = useFreelancerProfile();
  const canBid = freelancerProfile?.can_bid;
  const hasOffer = !!bid?.current_offer;

  if (!user) {
    return (
      <div onClick={onClick} role="presentation">
        <NotLoggedInApplyButton jobId={job.id} {...props} />
      </div>
    );
  }

  if (hasOffer || canBid) {
    return (
      <div onClick={onClick} role="presentation">
        <BidButton
          job={job}
          bid={bid}
          hideBoostButton={hideBoostButton}
          {...props}
        />
      </div>
    );
  }

  return (
    <div onClick={onClick} role="presentation">
      <CompleteProfileButton {...props} />
    </div>
  );
};

import React from "react";
import { useRef } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { insightsBannerClicked } from "@js/apps/jobs/actions";
import { useViewInsightsBanner } from "@js/apps/jobs/hooks/view-insights-banner";
import { useAppDispatch } from "@js/hooks";
import { usePurchaseUpgrade } from "@js/hooks/purchase-upgrade";

type JobInsightsBannerProps = {
  onClose: () => void;
};

const JobInsightsBanner: React.FC<JobInsightsBannerProps> = ({ onClose }) => {
  const jobListBoostBannerWrapperRef = useRef<HTMLDivElement>(null);
  useViewInsightsBanner(jobListBoostBannerWrapperRef);

  const dispatch = useAppDispatch();
  const { onClickPurchaseUpgrade } = usePurchaseUpgrade("insights");
  const handleButtonClick = () => {
    dispatch(insightsBannerClicked());
    onClickPurchaseUpgrade();
  };

  const isMobile = useMediaQuery("sm");

  return (
    <Box
      display="flex"
      minHeight="151px"
      flexDirection={isMobile ? "column" : "row"}
      border="1px solid var(--soft-grey)"
      overflow="hidden"
      borderRadius={4}
      sx={{ background: "var(--white)" }}
      ref={jobListBoostBannerWrapperRef}
    >
      <Box
        sx={{
          background: "var(--medium-green)",
          img: { objectFit: "contain" },
        }}
        display="flex"
        flex={isMobile ? "148px" : 1.01}
        minHeight={isMobile ? "148px" : "unset"}
        borderRadius={isMobile ? 0 : 2}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/job-insight-banner.png`}
          height="100%"
          width="100%"
          alt="job-insight-banner"
        />
      </Box>
      <Box
        flex={2}
        display="flex"
        alignItems="center"
        gap={1.5}
        flexDirection={isMobile ? "column" : "row"}
        py={isMobile ? 2 : 1}
        px={isMobile ? 2 : 3}
      >
        <Box flex={1}>
          <Typography component="h3" size="large">
            Maximize your job search success
          </Typography>
          <Typography component="p" size="small">
            Get tailored Application Insights to identify promising jobs,
            enhance your application, and more.
          </Typography>
        </Box>
        <Button
          sx={{
            textWrap: "nowrap",
            alignSelf: isMobile ? "end" : "center",
            paddingY: "7px !important",
          }}
          variant="positive"
          shape="squared"
          size="medium"
          endIcon={<ArrowRightIcon />}
          onClick={handleButtonClick}
        >
          Get Application Insights
        </Button>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 16,
          right: 5,
        }}
      >
        <IconButton
          variant="transparent"
          size="small"
          aria-label="Close Job insight card"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default JobInsightsBanner;

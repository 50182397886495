import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { HandshakeWithBackgroundIcon } from "@braintrust/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

import { EnumeratedItemWithCheckmark } from "./enumerated-item-with-checkmark";

import styles from "./style.module.scss";

export const YouAreInGoodHands = () => {
  return (
    <Box
      className={styles.rightPanelElement}
      bgcolor="var(--white)"
      border="1px solid var(--soft-beige)"
    >
      <HandshakeWithBackgroundIcon
        sx={{ width: 64, height: 64, color: "var(--medium-yellow)" }}
      />
      <Typography component="p" variant="label" size="large">
        You’re in good hands
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <EnumeratedItemWithCheckmark>
          <Typography component="span" fontWeight={500}>
            100% refunded after first invoice.
          </Typography>{" "}
          When you hire Talent for your job and pay your first invoice, your
          deposit will be refunded.
        </EnumeratedItemWithCheckmark>
        <EnumeratedItemWithCheckmark>
          <Typography component="span" fontWeight={500}>
            Or if you don't hire anyone.
          </Typography>{" "}
          If you close this job without hiring anyone, you’ll get 100% of your
          deposit back.
        </EnumeratedItemWithCheckmark>
        <EnumeratedItemWithCheckmark>
          <Typography component="span" fontWeight={500}>
            Safe and secure payments.
          </Typography>{" "}
          Braintrust uses Stripe and Wise for secure payment processing, so
          there’s never a worry.
        </EnumeratedItemWithCheckmark>
      </Box>
      <Typography component="p" variant="paragraph" size="small" mt={2}>
        To learn more about invoicing,{" "}
        <Typography
          component="a"
          variant="paragraph"
          size="small"
          href={SETTINGS.SUPPORT_URLS.CLIENT_FAQ_LINK}
          rel="noopener noreferrer"
          RouterLink={RouterLink}
          target="_blank"
        >
          click here.
        </Typography>
      </Typography>
    </Box>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ResumeViewer } from "@js/apps/common/components/resume-viewer";
import { RouterLink } from "@js/components/link";
import type { EmployerBidListBid } from "@js/types/jobs";

export const JobBidResumeViewer = ({ bid }: { bid: EmployerBidListBid }) => {
  const resumeLink = bid.resume?.attachment.file;
  const resumeDownloadLink = bid.resume?.attachment.file_download_url;

  if (!resumeLink || !resumeDownloadLink) {
    return (
      <EmptyState
        id={bid.freelancer.id}
        name={bid.freelancer.user.first_name}
      />
    );
  }

  return (
    <ResumeViewer
      resumeFile={resumeLink}
      resumeFileDownloadUrl={resumeDownloadLink}
    />
  );
};

const EmptyState = ({ id, name }: { id: number; name: string }) => {
  return (
    <Box my={7.5} textAlign="center">
      <Typography component="p" variant="paragraph" mb={0.5}>
        {name} hasn’t included a resume.
      </Typography>
      <Typography
        component="link"
        variant="paragraph"
        to={`/talent/${id}`}
        RouterLink={RouterLink}
        color="blue"
      >
        View {name}’s profile instead.
      </Typography>
    </Box>
  );
};

import type { ReactNode } from "react";
import React, { useMemo } from "react";

import {
  Avatar,
  Box,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { PersonAddIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useGetRolesQuery } from "@js/apps/roles/api";

import type { MemberInviteData } from "../types";

import { MemberOptionLabel } from "./member-option-label";

import styles from "./styles.module.scss";

export type MemberProps = {
  member: MemberInviteData;
  isMenuOption?: boolean;
};

export const NoMemberOption = () => {
  return (
    <div className={styles.member}>
      <div className={styles.memberAddMember}>👀</div>
      We couldn’t find anyone on Braintrust with that name
    </div>
  );
};

const AddMemberGraphic = () => {
  return (
    <div className={styles.memberAddMember}>
      <PersonAddIcon data-testid="add-member-icon" />
    </div>
  );
};

const Badge = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      component="span"
      variant="paragraph"
      size="small"
      display="block"
      className={styles.memberBadge}
    >
      {children}
    </Typography>
  );
};

export const Member = ({ member, isMenuOption }: MemberProps) => {
  const {
    already_invited,
    public_name,
    first_name,
    last_name,
    gravatar,
    has_avatar_set,
    avatar_thumbnail,
    country,
    badge,
    role_id,
    freelancer_id,
    id,
  } = member;

  const { data: roles } = useGetRolesQuery();
  const suggestedMemberRole = useMemo(() => {
    if (!role_id || !roles?.length) {
      return;
    }

    return roles.find((role) => role.id === role_id);
  }, [roles, role_id]);

  const parsedBadge = badge
    ? ENUMS.UserSuggestedForSpaceBadgeLabels[badge]
    : undefined;

  const isUser = !!id;
  const avatar = isUser ? (
    <Avatar
      size="full"
      src={{
        avatar_thumbnail,
        first_name,
        gravatar,
        has_avatar_set,
        last_name,
      }}
    />
  ) : (
    <AddMemberGraphic />
  );
  const isMobile = useMediaQuery("sm");

  return (
    <div className={styles.memberWrapper}>
      {isMobile && parsedBadge && <Badge>{parsedBadge}</Badge>}
      <div className={styles.member}>
        {avatar}
        <Box>
          <Box display="flex" gap={1} alignItems="center">
            <MemberOptionLabel
              public_name={public_name}
              freelancer_id={freelancer_id}
              disableLeftClick={isMenuOption}
            />
            {already_invited && <InvitedTag />}
          </Box>
          <Stack direction="row" gap={0.5} divider={<Separator />}>
            {suggestedMemberRole && (
              <Typography
                component="span"
                size="small"
                variant="paragraph"
                ellipsis
                color="grey-1"
                sx={{ overflow: "hidden" }}
              >
                {suggestedMemberRole.name}
              </Typography>
            )}
            {!isMobile && country && (
              <Typography
                component="span"
                size="small"
                variant="paragraph"
                color="grey-2"
              >
                {country}
              </Typography>
            )}
          </Stack>
        </Box>
        {!isMobile && parsedBadge && <Badge>{parsedBadge}</Badge>}
      </div>
    </div>
  );
};

const Separator = () => {
  return (
    <Typography
      component="span"
      variant="paragraph"
      size="small"
      color="tertiary"
    >
      ·
    </Typography>
  );
};

const InvitedTag = () => {
  return (
    <Typography
      component="span"
      variant="paragraph"
      size="small"
      color="secondary"
      className={styles.memberInviteTag}
    >
      Invited
    </Typography>
  );
};

import React, { useState } from "react";
import classnames from "classnames";

import {
  Box,
  IconButton,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { KeyboardArrowDownIcon } from "@braintrust/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

type ChecklistRowProps = {
  header?: string;
  subheader?: string;
  isCompleted?: boolean;
  description?: JSX.Element;
  action?: (() => void) | boolean;
};

export const ChecklistRow = ({
  header,
  subheader,
  isCompleted,
  description,
  action,
}: ChecklistRowProps) => {
  const [open, setOpen] = useState(false);
  const openAccordion = () => !isCompleted && setOpen(!open);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <ChecklistAccordion
        expanded={open}
        header={
          <Typography component="p" variant="paragraph" size="large">
            {header}
          </Typography>
        }
        subheader={
          subheader ? (
            <Typography
              component="p"
              variant="paragraph"
              sx={{ color: "var(--grey-2)" }}
            >
              {subheader}
            </Typography>
          ) : (
            <Status isCompleted={isCompleted} />
          )
        }
        onClick={
          typeof action === "function" ? () => action() : () => openAccordion()
        }
        disabled={isCompleted}
      >
        {description}
      </ChecklistAccordion>
    </Box>
  );
};

type ChecklistAccordionProps = {
  expanded: boolean;
  header: React.ReactNode;
  subheader?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
};

const ChecklistAccordion: React.FC<ChecklistAccordionProps> = ({
  children,
  expanded,
  header,
  subheader,
  onClick,
  disabled,
}) => {
  return (
    <div className={styles.checklistAccordion}>
      {/* Disable click-events-have-key-events as keyboard users could focus on inside button */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={styles.checklistAccordionContent}
        onClick={onClick}
        role="button"
      >
        <div className={styles.checklistAccordionHeader}>
          {header}
          {subheader}
        </div>
        <div>
          <IconButton
            disabledType="opacity"
            disabled={disabled}
            variant="transparent"
            aria-label={expanded ? "Shrink accordion" : "Expand accordion"}
            className={classnames(styles.checklistAccordionButton, {
              [styles.checklistAccordionButtonExpanded]: expanded,
            })}
            size="medium"
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </div>
      </div>
      {expanded ? children : null}
    </div>
  );
};

type StatusProps = {
  isCompleted?: boolean;
};

const Status = ({ isCompleted }: StatusProps) => {
  return (
    <Stack direction="row">
      <Typography
        component="p"
        variant="paragraph"
        mr={1}
        sx={{ color: "var(--grey-2)" }}
      >
        Status:
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        sx={{ color: isCompleted ? "var(--positive-2)" : "var(--negative-2)" }}
      >
        {isCompleted ? "Complete" : "Incomplete"}
      </Typography>
    </Stack>
  );
};

import { Box, Stack } from "@braintrust/braintrust-ui-components";
import { JobLocationContext } from "@js/apps/jobs/context";
import { NavigateWithSnackbarToastReceiver } from "@js/components/snackbar";

import { usePublicJobsListing } from "../../hooks/use-public-jobs-listing";

import { PublicJobsEmptyState } from "./public-jobs-empty-state";
import { PublicJobsList } from "./public-jobs-list";
import { PublicJobListingFiltersForm } from "./public-jobs-list-filters";
import { PublicJobsLoader } from "./public-jobs-loader";

export const PublicJobs = () => {
  const { jobs, total, loading, isAnyFilterApplied } = usePublicJobsListing();

  const showJobs = !loading && !!jobs?.length;
  const showEmptyState = !loading && !jobs?.length;

  return (
    <JobLocationContext.Provider
      value={JobLocationContext.Values.public_job_listing}
    >
      <NavigateWithSnackbarToastReceiver />
      <Stack sx={{ maxWidth: "70rem", marginInline: "auto" }}>
        <Box sx={{ mb: 2 }}>
          <PublicJobListingFiltersForm
            total={total}
            isAnyFilterApplied={isAnyFilterApplied}
          />
        </Box>
        {loading && <PublicJobsLoader />}
        {showJobs && <PublicJobsList jobs={jobs} total={total} />}
        {showEmptyState && <PublicJobsEmptyState />}
      </Stack>
    </JobLocationContext.Provider>
  );
};

import type { ReactNode } from "react";
import cs from "classnames";

import { Box } from "@braintrust/braintrust-ui-components";
import { CoreLayout } from "@js/layouts/core";

import styles from "./style.module.scss";

type OfferLayoutProps = {
  children: React.ReactNode;
  topStripeSocket?: React.ReactNode;
  navigationSocket: React.ReactNode;
  talentInfoSocket?: React.ReactNode;
  className?: string;
};

export const OfferLayout = ({
  children,
  className,
  topStripeSocket,
  navigationSocket,
  talentInfoSocket,
}: OfferLayoutProps) => {
  return (
    <CoreLayout className={cs(className, styles.offersLayout, "offers-layout")}>
      <Box className={styles.offersLayoutHeader}>
        {topStripeSocket && (
          <Box className={styles.offersLayoutTopStripeSocket}>
            <Box className={styles.offersLayoutTopStripe}>
              {topStripeSocket}
            </Box>
          </Box>
        )}
        <Box className={styles.offersLayoutNavigationSocket}>
          <Box className={styles.offersLayoutNavigation}>
            {navigationSocket}
          </Box>
        </Box>
      </Box>
      {talentInfoSocket && (
        <Box className={styles.offersLayoutTalentInfoSocket}>
          <Box className={styles.offersLayoutTalentInfo}>
            {talentInfoSocket}
          </Box>
        </Box>
      )}
      <Box className={styles.offersLayoutContent}>{children}</Box>
    </CoreLayout>
  );
};

type OfferFormContentContainerProps = {
  children: ReactNode;
  twoColumnsLayout?: boolean;
};

export const OfferFormContentContainer = ({
  children,
  twoColumnsLayout,
}: OfferFormContentContainerProps) => {
  const containerWidth = twoColumnsLayout ? 1189 : 787;

  return (
    <Box sx={{ maxWidth: containerWidth, width: "100%", marginInline: "auto" }}>
      {children}
    </Box>
  );
};

import React from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import { NavLayoutContainer } from "@js/layouts/app";
import { CoreLayout } from "@js/layouts/core";

import style from "./style.module.scss";

type JobInterviewLayoutProps = {
  children: React.ReactNode;
  header?: React.ReactNode;
};

export const JobInterviewLayout = ({
  children,
  header,
}: JobInterviewLayoutProps) => {
  return (
    <CoreLayout className={style.jobInterview}>
      <NavLayoutContainer className={style.jobInterviewHeader}>
        {header}
      </NavLayoutContainer>
      <Box className={style.jobInterviewContent}>{children}</Box>
    </CoreLayout>
  );
};

import type { ReactNode } from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { JobItemEmployerData } from "@js/apps/jobs/components/job-item";
import { JobTypeTagWithTooltip } from "@js/apps/jobs/components/job-type-tag-with-tooltip";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";

import styles from "./style.module.scss";

type MyWorkBaseCardProps = {
  job: Pick<Job, "employer" | "title" | "job_type" | "id">;
  infoSlot?: ReactNode;
  roleSlot?: ReactNode;
  statusSlot: ReactNode;
  rateSlot: ReactNode;
  ctaSlot: ReactNode;
  applicationBoostSlot?: ReactNode;
};

export const MyWorkBaseCard = ({
  job,
  infoSlot,
  roleSlot,
  statusSlot,
  rateSlot,
  ctaSlot,
  applicationBoostSlot,
}: MyWorkBaseCardProps) => {
  return (
    <Box className={styles.card}>
      <Box className={styles.cardEmployer}>
        <JobItemEmployerData employer={job.employer} isSmallJobItemCard />
      </Box>

      <Box className={styles.cardJobTypeAndRole}>
        <Box>
          <JobTypeTagWithTooltip jobType={job.job_type} />
        </Box>

        <Box className={styles.cardJobRole}>{roleSlot}</Box>
      </Box>

      <Typography
        className={styles.cardJobTitle}
        component="h2"
        variant="title"
        fontWeight={400}
        size="small"
        RouterLink={RouterLink}
        to={`/jobs/${job.id}`}
        target="_blank"
        rel="noopener"
      >
        {job.title}
      </Typography>

      <Box className={styles.cardInfo}>{infoSlot}</Box>

      <Box className={styles.cardStatus}>{statusSlot}</Box>

      <Box className={styles.cardJobRate}>{rateSlot}</Box>

      <Box className={styles.cardCta}>
        {applicationBoostSlot} {ctaSlot}
      </Box>
    </Box>
  );
};

import React from "react";

import type {
  BoxProps,
  ToggleDrawer,
} from "@braintrust/braintrust-ui-components";
import { Box, ButtonCore } from "@braintrust/braintrust-ui-components";
import {
  DiamondIcon,
  SuitcaseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import type { Role } from "@js/types/roles";
import { assertUnreachable, pluralize } from "@js/utils";

import styles from "./style.module.scss";

type JobTypeTagProps = {
  type: EnumType<typeof ENUMS.JobType>;
};

export const JobTypeTag = ({ type }: JobTypeTagProps) => {
  const user = useUser();

  if (!type) {
    return null;
  }

  const { label, bgcolor } = getJobTypeTagMeta(type, user?.account_type);

  return (
    <Tag bgcolor={bgcolor}>
      <SuitcaseIcon style={{ fontSize: 16 }} />
      {label}
    </Tag>
  );
};

type RolesTagModel = {
  role: Role;
};

type RolesButtonTagProps = RolesTagModel & {
  disabled?: boolean;
  onClick: (() => void) | ToggleDrawer;
};

export const RolesButtonTag = ({
  role,
  disabled,
  onClick,
}: RolesButtonTagProps) => {
  return (
    <Tag
      component={ButtonCore}
      bgcolor="var(--blue)"
      disabled={disabled}
      onClick={onClick}
    >
      <DiamondIcon style={{ fontSize: 16 }} />
      {getRolesLabel({ role })}
    </Tag>
  );
};

type RolesTagProps = {
  role: Role | null;
  experience?: number;
  className?: string;
};

export const RolesTag = ({ role, experience, className }: RolesTagProps) => {
  if (!role || Object.keys(role).length === 0) return null;

  const roleName = getRolesLabel({ role });

  return (
    <Tag bgcolor="var(--blue)" className={className}>
      <DiamondIcon style={{ fontSize: 16 }} />
      <span title={roleName} className={styles.rolesTagText}>
        {roleName}
        {experience &&
          ` (${experience} year${pluralize(experience, {
            zeroPlural: false,
          })})`}
      </span>
    </Tag>
  );
};

type OpeningsNumberButtonTagProps = {
  disabled?: boolean;
  onClick: (() => void) | ToggleDrawer;
  label: string;
};

export const OpeningsNumberButtonTag = ({
  disabled,
  onClick,
  label,
}: OpeningsNumberButtonTagProps) => (
  <Tag
    component={ButtonCore}
    bgcolor="rgba(var(--black-rgb), 0.08)"
    disabled={disabled}
    onClick={onClick}
  >
    {label}
  </Tag>
);

type TagProps = BoxProps & {
  bgcolor: string;
  className?: string;
  disabled?: boolean;
};

export const Tag = ({ bgcolor, className, ...props }: TagProps) => {
  return (
    <Box
      p="4px 12px"
      gap="4px"
      borderRadius="5px"
      bgcolor={bgcolor}
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={`${className} typography typography--small typography-variant--paragraph typography--ellipsis`}
      {...props}
    />
  );
};

export const getJobTypeTagMeta = (
  type: EnumType<typeof ENUMS.JobType>,
  accountType?: EnumType<typeof ENUMS.AccountType>,
): { label: string; bgcolor: string } => {
  switch (type) {
    case ENUMS.JobType.DIRECT_HIRE: {
      return {
        label: "Employee",
        bgcolor: "var(--medium-orange)",
      };
    }
    case ENUMS.JobType.FREELANCE: {
      return {
        label:
          accountType === ENUMS.AccountType.EMPLOYER
            ? "Contractor"
            : "Freelance",
        bgcolor: "var(--medium-orange)",
      };
    }
    case ENUMS.JobType.GRANT: {
      return {
        label: "Grant",
        bgcolor: "var(--medium-orange)",
      };
    }
    default: {
      assertUnreachable(type);
      // default which might be not true
      return {
        label: "Employee",
        bgcolor: "var(--medium-orange)",
      };
    }
  }
};

const getRolesLabel = ({ role }: RolesTagModel): string => {
  return role.name;
};

export type JobTypeAndRoleTagProps = {
  jobType: EnumType<typeof ENUMS.JobType>;
  role: Role;
  mt?: number;
  createdTime?: string;
};

import { useCallback } from "react";
import cx from "classnames";

import {
  Backdrop,
  Box,
  Divider,
  IconButton,
  Stack,
} from "@braintrust/braintrust-ui-components";
import { SearchSVGIcon } from "@braintrust/braintrust-ui-components/Icons";
import { SearchFilterLocationContext } from "@js/apps/common/context/search-filter-location-context";
import { useGlobalSearchEventTracking } from "@js/apps/universal-search/hooks/use-global-search-event-tracking";

import { CloseSearchContext } from "../../context/close-search-context";
import { useToggleSearchPaused } from "../../hooks/use-toggle-search-paused";
import { useUniversalSearchForm } from "../../hooks/use-universal-search-form";
import { SearchResults } from "../search-results";
import { UniversalSearchEmptyState } from "../universal-search-empty-state";
import { UniversalSearchField } from "../universal-search-field";
import { UniversalSearchLoader } from "../universal-search-loader";
import { UniversalSearchTabs } from "../universal-search-tabs";

import styles from "./universal-search-form.module.scss";

export const UniversalSearchForm = () => {
  return (
    <SearchFilterLocationContext.Provider value="main_search_box">
      <UniversalSearchFormComponent />
    </SearchFilterLocationContext.Provider>
  );
};

const UniversalSearchFormComponent = () => {
  const {
    onFieldFocus,
    onTabChange,
    handleSetOpen,
    currentTab,
    isMobile,
    isUniversalSearchOpen,
    currentData,
    isLoading,
    isFetching,
    canAccessGiveAndGetHelp,
    searchQuery,
  } = useUniversalSearchForm();

  const { handleSearchChange, handleSearchEnter, handleTabChange } =
    useToggleSearchPaused({ isLoading, currentTab });

  const { trackGlobalSearchEvent, resetGlobalSearchReported } =
    useGlobalSearchEventTracking();

  const currentTabHasData =
    (currentTab === "CAREER_HELP" &&
      !!currentData?.postResults?.results.length) ||
    (currentTab === "PEOPLE" && !!currentData?.talentResults?.results.length) ||
    (currentTab === "JOBS" && !!currentData?.jobResults?.results.length);

  const showSearchResults = !isLoading && currentTabHasData;
  const showEmptyState = !isLoading && !currentTabHasData;
  const handleSearchClose = useCallback(() => {
    resetGlobalSearchReported();
    handleSetOpen(false);
  }, [handleSetOpen, resetGlobalSearchReported]);

  return (
    <CloseSearchContext.Provider value={handleSearchClose}>
      <Box className={styles.form}>
        {isMobile && (
          <IconButton
            title="Open search bar"
            aria-label="Open search bar"
            variant="transparent"
            onClick={() => handleSetOpen(!isUniversalSearchOpen)}
            size="medium"
            className={styles.button}
          >
            <SearchSVGIcon />
          </IconButton>
        )}
        {(!isMobile || isUniversalSearchOpen) && (
          <>
            <Box
              className={cx(styles.search, {
                [styles.searchOpen]: isUniversalSearchOpen,
              })}
            >
              <UniversalSearchField
                onFocus={onFieldFocus}
                onChange={(search: string) => {
                  trackGlobalSearchEvent();
                  handleSearchChange(search);
                }}
                onEnter={handleSearchEnter}
                canAccessGiveAndGetHelp={canAccessGiveAndGetHelp}
              />
              {isUniversalSearchOpen && (
                <Stack
                  className={styles.content}
                  data-testid="universal-search-modal"
                >
                  <Box sx={{ px: 2 }}>
                    <Divider color="light-grey" />
                    <UniversalSearchTabs
                      canAccessGiveAndGetHelp={canAccessGiveAndGetHelp}
                      currentTab={currentTab}
                      onTabChange={(tab) => {
                        handleTabChange();
                        onTabChange(tab);
                      }}
                      currentTabHasData={currentTabHasData}
                    />
                  </Box>
                  <Box className={styles.results}>
                    {isLoading && <UniversalSearchLoader />}
                    {showSearchResults && (
                      <SearchResults
                        currentTab={currentTab}
                        currentData={currentData}
                        isAfterSearch={!!searchQuery}
                      />
                    )}
                    {showEmptyState && (
                      <UniversalSearchEmptyState
                        canAccessGiveAndGetHelp={canAccessGiveAndGetHelp}
                        currentTab={currentTab}
                        isFetching={isFetching}
                      />
                    )}
                  </Box>
                </Stack>
              )}
            </Box>
            <Backdrop
              data-testid="universal-search-backdrop"
              invisible
              open={isUniversalSearchOpen}
              onClick={() => {
                resetGlobalSearchReported();
                handleSetOpen(false);
              }}
            ></Backdrop>
          </>
        )}
      </Box>
    </CloseSearchContext.Provider>
  );
};

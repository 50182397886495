import ReactPlayer from "react-player";

import { Box } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

const VideoPreviewModalContent = ({ videoUrl }: { videoUrl: string }) => (
  <Box className={styles.videoPreviewContainer}>
    <Box className={styles.videoPreview}>
      <ReactPlayer
        url={videoUrl}
        controls
        width="100%"
        height="100%"
        muted
        playing
      />
    </Box>
  </Box>
);

export const openVideoPreviewModal = ({ videoUrl }: { videoUrl: string }) => {
  ModalInstance.open({
    className: `${styles.videoPreviewModal} text-content-mw"`,
    children: <VideoPreviewModalContent videoUrl={videoUrl} />,
    keepOnBackdropClick: true,
    closeButton: true,
    closeButtonProps: {
      sx: {
        backgroundColor: "var(--white) !important",
        height: "48px",
        width: "48px",
      },
      size: "x-small",
    },
  });
};

import React, { useCallback } from "react";

import { NavSearch } from "@braintrust/braintrust-ui-components";
import { SearchSVGIcon } from "@braintrust/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

type RoomListSearchProps = {
  value: string;
  onChange: (val: string) => void;
};

export const RoomListSearch = ({ value, onChange }: RoomListSearchProps) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void =>
      onChange(event.target.value),
    [onChange],
  );

  return (
    <div className={styles.search}>
      <NavSearch
        id="search-room"
        InputProps={{ startAdornment: <SearchSVGIcon /> }}
        value={value}
        onChange={handleChange}
        placeholder="Search by name"
      />
    </div>
  );
};

import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import type { FreelancerAvailabilityParams } from "@js/apps/available-for-work/api";
import { useUpdateFreelancerAvailabilityMutation } from "@js/apps/available-for-work/api";
import { timezoneOptions } from "@js/apps/available-for-work/forms/fields/timezone-field/helpers";
import {
  FRONTEND_STORAGE_KEYS,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { useReloadJobsOnAvailabilityChange } from "@js/apps/jobs/apps/listing/hooks/use-reload-jobs-on-availability-change";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { RhfForm } from "@js/rhf/types";
import { isErrorWithDataAndRootMessage } from "@js/types/errors";

const schema = z
  .object({
    availability_for_work: z.boolean(),
    availability_for_work_options: z
      .array(z.nativeEnum(ENUMS.FreelancerAvailabilityForWork))
      .optional(),
    working_hours_tz_abbr: z.string().nullable().optional(),
    working_hours_start: z.number().optional(),
    working_hours_end: z.number().optional(),
  })
  .refine((data) => data.working_hours_end !== data.working_hours_start, {
    path: ["working_hours_end"],
    message: "The end time must be different than the start time",
  })
  .refine(
    (data) =>
      !data.availability_for_work ||
      (data.availability_for_work_options &&
        data.availability_for_work_options.length > 0),

    {
      path: ["availability_for_work_options"],
      message:
        "Please select which types of roles you’re open to and when you’re available to work.",
    },
  )
  .refine((data) => !data.availability_for_work || data.working_hours_tz_abbr, {
    path: ["working_hours_tz_abbr"],
    message: "Please select a timezone",
  })
  .refine(
    (data) =>
      !data.availability_for_work ||
      data.working_hours_start ||
      data.working_hours_start === 0,

    {
      path: ["working_hours_start"],
      message: "Required",
    },
  )
  .refine(
    (data) =>
      !data.availability_for_work ||
      data.working_hours_end ||
      data.working_hours_end === 0,
    {
      path: ["working_hours_end"],
      message: "Required",
    },
  );

type AvailabilityForm = RhfForm<typeof schema>;
export type AvailabilityFormValues = AvailabilityForm["Values"];

export const useAvailabilityForm = (onClose: () => void) => {
  const dispatch = useAppDispatch();
  const [setStorageValue] = useSetStorageValueMutation();
  const { reloadJobsOnAvailabilityChange } =
    useReloadJobsOnAvailabilityChange();
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer?.freelancerProfile,
  );
  const fetchingFreelancerProfile = useAppSelector(
    (state) => state.freelancer.fetchingFreelancerProfile,
  );

  const initialWorkingHoursTimezoneData = useMemo(
    () =>
      timezoneOptions.find(
        (timezone) =>
          freelancerProfile?.working_hours_tz_abbr === timezone.value,
      ),
    [freelancerProfile?.working_hours_tz_abbr],
  );

  const form = useForm<AvailabilityForm["Values"]>({
    resolver: zodResolver(schema),
    mode: "onTouched",
    defaultValues: {
      availability_for_work: freelancerProfile?.availability_for_work || false,
      availability_for_work_options:
        freelancerProfile?.availability_for_work_options || [],
      working_hours_start: freelancerProfile?.working_hours_start ?? 9,
      working_hours_end: freelancerProfile?.working_hours_end ?? 17,
      working_hours_tz_abbr: initialWorkingHoursTimezoneData?.value || "",
    },
  });

  const { handleSubmit, formState, control, setError, watch, clearErrors } =
    form;
  const { errors, isSubmitted, submitCount } = formState;

  const isAvailableForWork = watch("availability_for_work");

  useEffect(() => {
    if (!isAvailableForWork) {
      clearErrors();
    }
  }, [clearErrors, isAvailableForWork]);

  const [updateFreelancerAvailability] =
    useUpdateFreelancerAvailabilityMutation();

  const isFormInvalid = Object.keys(errors).length > 0;
  const isSubmitFailed = isSubmitted && submitCount > 0 && isFormInvalid;

  const disableSubmit = isSubmitFailed && isFormInvalid;

  const onSubmit = async (data: AvailabilityForm["Values"]) => {
    if (!freelancerProfile || disableSubmit) {
      return;
    }

    if (data.availability_for_work) {
      setStorageValue({
        key: FRONTEND_STORAGE_KEYS.working_hours_coach_mark_shown,
        value: true,
      });
    }

    const preparedData: FreelancerAvailabilityParams =
      data.availability_for_work
        ? ({
            id: freelancerProfile.id,
            availability_for_work: true,
            availability_for_work_options: data.availability_for_work_options,
            working_hours_tz_abbr: data.working_hours_tz_abbr,
            working_hours_start: data.working_hours_start,
            working_hours_end: data.working_hours_end,
          } as FreelancerAvailabilityParams)
        : {
            id: freelancerProfile.id,
            availability_for_work: false,
          };

    await updateFreelancerAvailability(preparedData)
      .unwrap()
      .then(() => {
        reloadJobsOnAvailabilityChange();
        Snackbar.success(`The availability status has been changed!`);
        onClose();
        dispatch(fetchFreelancerProfile(freelancerProfile?.id));
      })
      .catch((error) => {
        if (isErrorWithDataAndRootMessage(error)) {
          setError("availability_for_work_options", {
            message: error.data._error[0],
          });
        }
        Snackbar.error(`Failed to change availability status.`);
      });
  };

  return {
    control,
    handleSubmit,
    disableSubmit,
    onSubmit,
    isLoading: fetchingFreelancerProfile,
  };
};

import React from "react";

import { IconButton } from "@braintrust/braintrust-ui-components";
import { EnvelopeIcon } from "@braintrust/braintrust-ui-components/Icons";
import { navItemClicked } from "@js/apps/freelancer/actions";
import { useGetUnreadMessagesCountQuery } from "@js/apps/messenger/api";
import { RouterLink } from "@js/components/link";
import { NAV_ITEM_IDS } from "@js/constants";
import { useAppDispatch } from "@js/hooks";
import { notLessThanZero } from "@js/utils";

import { BadgeNotification } from "../badge-notifications";

export const MessengerNotificationsIconButton: React.FC<{
  style?: React.CSSProperties;
}> = (props) => {
  const { data } = useGetUnreadMessagesCountQuery();
  const dispatch = useAppDispatch();
  const logMessengerOpen = () => {
    dispatch(
      navItemClicked({
        nav_item_id: NAV_ITEM_IDS.MESSENGER_OPEN,
        nav_item_href: "/messages/",
        context_url: window.location.pathname,
      }),
    );
  };

  const unreadMessagesCount = notLessThanZero(data?.unread_messages_count ?? 0);

  return (
    <IconButton
      variant="transparent"
      to="/messages/"
      RouterLink={RouterLink}
      size="medium"
      onClick={logMessengerOpen}
      aria-label="Open messenger"
      {...props}
    >
      <BadgeNotification notificationLength={unreadMessagesCount}>
        <EnvelopeIcon sx={{ width: "24px", height: "24px" }} />
      </BadgeNotification>
    </IconButton>
  );
};

import React from "react";
import type { InjectedFormProps } from "redux-form";
import {
  Field,
  reduxForm,
  SubmissionError,
  submit as reduxSubmit,
} from "redux-form";

import { Button, Grid } from "@braintrust/braintrust-ui-components";
import { useFreelancerId } from "@js/apps/common/hooks";
import { Snackbar } from "@js/components/snackbar";
import { Form } from "@js/forms/components/form";
import { TextField } from "@js/forms/fields";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { fetchFreelancerProfile, updateCompanyName } from "../../actions";
export const CompanyName = (): JSX.Element => {
  const formProps = useCompanyNameForm();

  return <CompanyNameForm {...formProps} />;
};

type CustomProps = {
  submit(): void;
};
type FormData = { company_name: string };
type InjectedProps = InjectedFormProps<FormData, CustomProps>;

const formId = "company-name-form";

const useCompanyNameForm = () => {
  const dispatch = useAppDispatch();
  const freelancerId = useFreelancerId();
  const companyName = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.company_name,
  );

  const submit = () => dispatch(reduxSubmit(formId));

  const onSubmit = (values: FormData) => {
    if (!freelancerId) return;

    return updateCompanyName(freelancerId, values).catch((error) => {
      throw new SubmissionError(error);
    });
  };

  const onSubmitSuccess = () => {
    Snackbar.success("Company name successfully saved.");
    if (freelancerId) {
      dispatch(fetchFreelancerProfile(freelancerId));
    }
  };

  return {
    submit,
    onSubmit,
    onSubmitSuccess,
    initialValues: {
      company_name: companyName,
    },
  };
};

const CompanyNameFormComponent = ({
  submit,
  submitting,
  error,
}: CustomProps & InjectedProps) => {
  return (
    <Form onSubmit={submitting ? undefined : submit} error={error}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item md={4}>
          <Field
            id="company_name"
            name="company_name"
            label="Company name"
            component={TextField}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button style={{ marginBottom: 4 }} type="submit" variant="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const CompanyNameForm = reduxForm<FormData, CustomProps>({
  form: formId,
  enableReinitialize: true,
})(CompanyNameFormComponent);

import type { TypedWrappedFieldProps } from "redux-form";

import { Box } from "@braintrust/braintrust-ui-components";
import { RATE_OPTIONS } from "@js/apps/jobs/apps/create-job/constants";
import { DollarNumberField } from "@js/forms/fields/number/number";
import { assertUnreachable } from "@js/utils";

import { RATE_FIELDS_KEYS } from "../constants";

import { RateCustomField } from "./rate-custom-field";

type AmountFieldProps = {
  payment_type: TypedWrappedFieldProps<EnumType<typeof ENUMS.JobPaymentType>>;
  fixed_rate: TypedWrappedFieldProps<string>;
  min_rate: TypedWrappedFieldProps<string>;
  max_rate: TypedWrappedFieldProps<string>;
  min_annual_rate: TypedWrappedFieldProps<string>;
  max_annual_rate: TypedWrappedFieldProps<string>;
  hourly_rate: TypedWrappedFieldProps<string>;
  is_ai_generated: TypedWrappedFieldProps<boolean>;
  ai_min_rate: TypedWrappedFieldProps<string>;
  ai_max_rate: TypedWrappedFieldProps<string>;
  custom_min_rate: TypedWrappedFieldProps<string>;
  custom_max_rate: TypedWrappedFieldProps<string>;
};

export const AmountField = ({
  payment_type,
  fixed_rate,
  min_rate,
  max_rate,
  min_annual_rate,
  max_annual_rate,
  hourly_rate,
  is_ai_generated,
  ai_min_rate,
  ai_max_rate,
  custom_min_rate,
  custom_max_rate,
}: AmountFieldProps) => {
  const selectedPaymentType: EnumType<typeof ENUMS.JobPaymentType> =
    payment_type.input.value;

  switch (selectedPaymentType) {
    case ENUMS.JobPaymentType.HOURLY: {
      return (
        <RateCustomField
          hourly_rate={hourly_rate}
          min_rate={min_rate}
          max_rate={max_rate}
          rateOptions={RATE_OPTIONS}
          is_ai_generated={is_ai_generated}
          ai_min_rate={ai_min_rate}
          ai_max_rate={ai_max_rate}
          custom_min_rate={custom_min_rate}
          custom_max_rate={custom_max_rate}
        />
      );
    }
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      return (
        <DollarNumberField
          hideError
          id={RATE_FIELDS_KEYS.fixed_rate}
          name={RATE_FIELDS_KEYS.fixed_rate}
          {...fixed_rate}
          placeholder="100,000"
          inputProps={{
            style: { padding: "20px" },
            "aria-label": "fixed price salary",
          }}
          sx={{
            maxWidth: "155px",
            ".MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
            "& input::placeholder": {
              color: "var(--grey-3)",
            },
          }}
        />
      );
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      return (
        <Box display="flex" alignItems="center" sx={{ maxWidth: "440px" }}>
          <DollarNumberField
            hideError
            id={RATE_FIELDS_KEYS.min_annual_rate}
            name={RATE_FIELDS_KEYS.min_annual_rate}
            {...min_annual_rate}
            placeholder="100,000"
            size="large"
            inputProps={{ "aria-label": "minimum annual salary" }}
          />
          <Box mx={2}>to</Box>
          <DollarNumberField
            hideError
            id={RATE_FIELDS_KEYS.max_annual_rate}
            name={RATE_FIELDS_KEYS.max_annual_rate}
            {...max_annual_rate}
            placeholder="150,000"
            size="large"
            inputProps={{ "aria-label": "maximum annual salary" }}
          />
        </Box>
      );
    }
    default: {
      assertUnreachable(selectedPaymentType);
      return null;
    }
  }
};

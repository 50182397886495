import type { ComponentPropsWithoutRef } from "react";

import { RoundedBox } from "@braintrust/braintrust-ui-components";
import type { EmployerLogoWithNameProps } from "@js/apps/employer/components";
import { EmployerLogoNameAndRating } from "@js/apps/employer/components";
import type { EmployerPublicProfile } from "@js/types/employer";

type EmployerInfoProps = ComponentPropsWithoutRef<typeof RoundedBox> & {
  employer: EmployerPublicProfile;
  jobId: number;
  logoSize?: EmployerLogoWithNameProps["size"];
};

export const EmployerInfo = ({
  jobId,
  employer,
  ...props
}: EmployerInfoProps) => {
  return (
    <RoundedBox px={4} py={3} {...props}>
      <EmployerLogoNameAndRating
        employer={employer}
        jobId={jobId}
        logoSize="medium"
      />
    </RoundedBox>
  );
};

import React, { useState } from "react";
import _ from "underscore";

import type { IconButtonProps } from "@braintrust/braintrust-ui-components";
import { IconButton, Popover } from "@braintrust/braintrust-ui-components";
import { BellIcon } from "@braintrust/braintrust-ui-components/Icons";
import { navItemClicked } from "@js/apps/freelancer/actions";
import { NAV_ITEM_IDS } from "@js/constants";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { BadgeNotification } from "../badge-notifications";
import { SiteNotifications } from "../site-notifications";

type SiteNotificationsIconProps = {
  style?: IconButtonProps["style"];
};

export const SiteNotificationsIcon = ({
  style,
}: SiteNotificationsIconProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const siteNotifications = useAppSelector(
    (state) => state.notifications.siteNotifications,
  );

  const dispatch = useAppDispatch();

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setIsPanelOpen(true);
    setAnchorEl(e.currentTarget);

    dispatch(
      navItemClicked({
        nav_item_id: NAV_ITEM_IDS.NOTIFICATIONS_OPEN,
        context_url: window.location.pathname,
      }),
    );
  };

  const handleClose = () => {
    setIsPanelOpen(false);
  };
  const newCount = _.where(siteNotifications ?? [], { read: false }).length;

  return (
    <Popover
      PaperProps={{ style: { overflow: "visible", borderRadius: 12 } }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: 53,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isPanelOpen}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchor={
        <IconButton
          variant="transparent"
          aria-label="open notifications"
          onClick={handleOpen}
          size="medium"
          style={style}
        >
          <BadgeNotification notificationLength={newCount}>
            <BellIcon
              sx={{ width: "24px", height: "24px", strokeWidth: "1.5px" }}
            />
          </BadgeNotification>
        </IconButton>
      }
    >
      <SiteNotifications close={handleClose} anchorEl={anchorEl} />
    </Popover>
  );
};

import type { ReactNode } from "react";

import { Button } from "@braintrust/braintrust-ui-components";
import { ReferModalContent } from "@js/apps/common/components/refer-modal-content";
import { ModalInstance } from "@js/components/modal";

import styles from "../refer-modal-content/styles.module.scss";

export const ReferButton = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <Button
      variant="primary"
      size="medium"
      shape="squared"
      onClick={openReferralModal}
    >
      {children}
    </Button>
  );
};

export const openReferralModal = (): void => {
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
      },
    },
    className: styles.referModal,
    children: (
      <ReferModalContent
        headline={SETTINGS.REFERRAL_HEADLINE}
        subtext={SETTINGS.REFERRAL_SUBTEXT}
        hideImage
        titleSize="medium"
        descriptionProps={{ size: "medium" }}
        // increase specificity by passing py and px instead of just p
        px="2px"
        py="2px"
        type="talent"
        copyLinkProps={{ shortCopyText: false, smallText: true }}
      />
    ),
  });
};

import {
  Button,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { Modal } from "@js/components/modal";

import styles from "./styles.module.scss";

export const RemoveVideoConfirmationModal = Modal(
  "REMOVE_VIDEO_CONFIRMATION_MODAL",
  {
    className: "text-content-mw",
  },
);

type RemoveVideoConfirmationModalProps = {
  onConfirm: () => void;
};

const RemoveVideoConfirmationModalContent = ({
  onConfirm,
}: RemoveVideoConfirmationModalProps) => {
  return (
    <>
      <Typography
        component="h1"
        variant="title"
        size="small"
        mt={{ xs: "25px", sm: 0 }}
      >
        Are you sure you want to delete this intro video?
      </Typography>
      <Typography component="p" mt={2}>
        This video will be removed.
      </Typography>
      <Stack
        direction="row"
        sx={{
          gap: 1,
          flexWrap: "wrap",
          mt: 4,
        }}
      >
        <Button
          variant="secondary"
          shape="squared"
          onClick={() => RemoveVideoConfirmationModal.close()}
        >
          Cancel
        </Button>
        <Button
          variant="destructive"
          shape="squared"
          onClick={() => {
            onConfirm();
            RemoveVideoConfirmationModal.close();
          }}
          sx={{ backgroundColor: "var(--negative-2) !important" }}
        >
          Delete
        </Button>
      </Stack>
    </>
  );
};

export const openRemoveVideoConfirmationModal = (
  props: RemoveVideoConfirmationModalProps,
) =>
  RemoveVideoConfirmationModal.open({
    className: `${styles.removeVideoModal} text-content-mw`,
    closeButton: true,
    closeButtonProps: {
      sx: {
        backgroundColor: "var(--grey-5) !important",
        height: "42px",
        width: "42px",
      },
      size: "x-small",
    },
    keepOnBackdropClick: true,
    children: <RemoveVideoConfirmationModalContent {...props} />,
  });

import { Box } from "@braintrust/braintrust-ui-components";

import { SectionTitle } from "../section-title";

export const SkillSectionTitle = () => {
  return (
    <Box display="flex" justifyContent="space-between">
      <SectionTitle title="Skills" />
    </Box>
  );
};

import type { ReactNode } from "react";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import { Box, Typography } from "@braintrust/braintrust-ui-components";

import type { SearchDataType } from "../../config";
import { getLabelForResultsCount } from "../../utils";

export type ResultsCountDataType =
  | Exclude<SearchDataType, "BID" | "CAREER_HELP">
  | "BIDS";
export const ResultsCount = ({
  dataType,
  count,
  total_count,
  wholeTextBold,
}: {
  dataType: ResultsCountDataType;
  count: number;
  total_count?: number;
  fontWeight?: TypographyProps["fontWeight"];
  wholeTextBold?: boolean;
}) => {
  const label = getLabelForResultsCount(dataType, count);
  if (total_count && total_count > 10000) {
    return (
      <ResultsCountContainer>
        <Typography fontWeight={500} component="span" fontSize="small">
          {count.toLocaleString("en-US")}{" "}
        </Typography>
        results returned of{" "}
        <Typography fontWeight={500} component="span" fontSize="small">
          {total_count.toLocaleString("en-US")}{" "}
        </Typography>
        {label}
      </ResultsCountContainer>
    );
  }

  return (
    <ResultsCountContainer>
      <Typography fontWeight={500} component="span" fontSize="small">
        {count.toLocaleString("en-US")}{" "}
      </Typography>
      <Typography
        fontWeight={wholeTextBold ? 500 : 400}
        component="span"
        fontSize="small"
      >
        {label} found
      </Typography>
    </ResultsCountContainer>
  );
};

const ResultsCountContainer = ({ children }: { children: ReactNode }) => {
  return <Box sx={{ mr: 1 }}>{children}</Box>;
};

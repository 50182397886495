import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import type {
  TypedWrappedFieldProps,
  WrappedFieldInputProps,
} from "redux-form";
import { Field } from "redux-form";

import type { PopoverActions } from "@braintrust/braintrust-ui-components";
import { Loader } from "@braintrust/braintrust-ui-components";
import { DiamondIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { RoleParams } from "@js/apps/common/components/filters/forms/role-form";
import { RoleForm } from "@js/apps/common/components/filters/forms/role-form";
import { ROLE_FILTER_SCHEMA } from "@js/apps/common/components/filters/schemas/role-filter-schema";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components/job-popover-filter-button";
import type { CustomRole } from "@js/apps/jobs/apps/listing/hooks/custom-role-and-role-type";
import { useCustomRole } from "@js/apps/jobs/apps/listing/hooks/custom-role-and-role-type";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import type { OptionWithPrimitiveValue } from "@js/types/common";
import { PossibleFilters } from "@js/types/common";
import { pluralize } from "@js/utils";

const DEFAULT_ROLE_LABEL = "Role";
const roleQueryParamKey: keyof RoleParams = "role";

export const RoleFilter = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [params] = useSearchParams();
  const popoverActionRef = useRef<PopoverActions>(null);
  const roleQueryParam = params.get(roleQueryParamKey);
  const { handleFilterApplied } = useHandleFilterApplied();
  const selectedRolesIds = useMemo(() => {
    return ROLE_FILTER_SCHEMA.parse(roleQueryParam);
  }, [roleQueryParam]);

  const { selectedRoleData, roleOptions } = useCustomRole({ selectedRolesIds });

  const label = getRoleLabel({
    selectedRoleData,
    roleOptions,
  });

  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.ROLE,
      filter_value: values,
    });
  };

  return (
    <JobPopoverFilterButton
      action={popoverActionRef}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      label={label}
      startIcon={<DiamondIcon viewBox="0 1 15 15" />}
      isActive={!!selectedRolesIds?.length}
      popoverContent={
        <RolePopoverForm
          onSubmitSideAction={handleSubmitSideAction}
          roleOptions={roleOptions}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
        />
      }
    />
  );
};

type RolePopoverFormProps = {
  onSubmitSuccess: () => void;
  onSubmitSideAction: (values: Record<string, unknown>) => void;
  roleOptions: RoleFieldProps["roleOptions"];
};

const formId = "listing-filters__role";

const RolePopoverForm = ({
  onSubmitSuccess,
  onSubmitSideAction,
  roleOptions,
}: RolePopoverFormProps) => {
  return (
    <RoleForm
      onSubmitSuccess={onSubmitSuccess}
      prepareToSubmit={(values) => {
        return {
          ...values,
          role: values.role,
          skills: values.skills,
        };
      }}
      form={formId}
      onSubmitSideAction={onSubmitSideAction}
    >
      {({ submit }) => {
        return (
          <Field
            submit={submit}
            component={RoleField}
            roleOptions={roleOptions}
            name="role"
          />
        );
      }}
    </RoleForm>
  );
};

type RoleFieldProps = {
  submit: () => void;
  roleOptions: Array<{ value: number; label: string }>;
} & TypedWrappedFieldProps<number[]>;

const RoleField = ({ submit, input, meta, roleOptions }: RoleFieldProps) => {
  const handleReset = () => {
    input.onChange(null);
  };

  const handleApply = () => {
    submit();
  };

  return (
    <JobPopoverFilterButton.Content onApply={handleApply} onReset={handleReset}>
      {!roleOptions.length && <Loader />}
      <RoleButtonSelect options={roleOptions} input={input} meta={meta} />
    </JobPopoverFilterButton.Content>
  );
};

type RoleButtonSelectProps = {
  options: OptionWithPrimitiveValue<number>[];
} & TypedWrappedFieldProps<number[]>;

const RoleButtonSelect = ({ input, meta, options }: RoleButtonSelectProps) => {
  return (
    <ButtonSelectField
      labelledBy="category-label"
      fontWeight={500}
      options={options}
      multiple
      canSingleValueBeEmpty
      variant="white-violet"
      shape="squared"
      input={input as WrappedFieldInputProps}
      meta={meta}
    />
  );
};

const getRoleLabel = ({ selectedRoleData, roleOptions }: CustomRole) => {
  if (!selectedRoleData.length) {
    return DEFAULT_ROLE_LABEL;
  }

  const selectedRoleOption = roleOptions.filter((roleOption) =>
    selectedRoleData.map((role) => role.id).includes(roleOption.value),
  );

  if (!selectedRoleOption.length) return "Other";

  const firstRole = selectedRoleOption[0].label;

  if (selectedRoleOption.length === 1) return firstRole;

  return `${firstRole} & ${selectedRoleOption.length - 1} other${pluralize(
    selectedRoleOption.length - 1,
  )}`;
};

import { Suspense } from "react";

import { Loader } from "@braintrust/braintrust-ui-components";
import type { PostLexicalTextFieldProps } from "@js/apps/give-and-get-help/forms/fields/post-lexical-textfield/post-lexical-textfield";
import { lazyWithRetry } from "@js/utils";

const PostLexicalTextFieldLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "lexical-editor" */ "@js/apps/give-and-get-help/forms/fields/post-lexical-textfield/post-lexical-textfield"
  );

  return { default: module.PostLexicalTextField };
});

export type { PostLexicalTextFieldProps };

export const PostLexicalTextField = (props: PostLexicalTextFieldProps) => {
  return (
    <Suspense fallback={<Loader centered />}>
      <PostLexicalTextFieldLazy {...props} />
    </Suspense>
  );
};

import classNames from "classnames";

import { Box } from "@braintrust/braintrust-ui-components";
import { useQueryParams } from "@js/hooks";
import type { Talent } from "@js/types/freelancer";

import type { TALENT_LOCATION } from "../../constants";

import { CardItemTalent } from "./card-item";
import { EmptyTalentList } from "./empty-talent-list";

import styles from "./style.module.scss";

type TalentListingComponentTypes = {
  talentList: Array<Talent>;
  onClear: () => void;
  searchResults?: boolean;
  location: EnumType<typeof TALENT_LOCATION>;
};

export const TalentListing = ({
  onClear,
  talentList,
  searchResults,
  location,
}: TalentListingComponentTypes) => {
  const query = useQueryParams();

  const talentListingClasses = classNames(styles.talentListing, {
    [styles.talentListingEmptyState]: talentList.length === 0,
  });

  return (
    <Box className={talentListingClasses}>
      {talentList.length ? (
        talentList.map((talent, index) => (
          <CardItemTalent
            key={talent.id}
            jobId={
              query.invite_to_job && query.job ? Number(query.job) : undefined
            }
            talent={talent}
            searchResults={searchResults}
            resultPosition={index}
            location={location}
          />
        ))
      ) : (
        <EmptyTalentList onClear={onClear} />
      )}
    </Box>
  );
};

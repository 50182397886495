import { Grid, Typography } from "@braintrust/braintrust-ui-components";

export const EmployerReferralListHeader = () => {
  return (
    <Grid
      container
      sx={{
        display: {
          xs: "none",
          lg: "flex",
        },
        mb: "20px",
      }}
    >
      <Grid item xs={6}>
        <Typography
          component="p"
          variant="paragraph"
          size="large"
          color="grey-1"
        >
          Client referral
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={6}
        spacing={2}
        wrap="nowrap"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs sx={{ maxWidth: "130px !important" }}>
          <Typography
            component="p"
            variant="paragraph"
            size="large"
            color="grey-1"
          >
            Jobs
          </Typography>
        </Grid>
        <Grid item xs sx={{ maxWidth: "130px !important" }}>
          <Typography
            component="p"
            variant="paragraph"
            size="large"
            color="grey-1"
          >
            Hires
          </Typography>
        </Grid>
        <Grid item sx={{ width: "300px" }}>
          <Typography
            component="p"
            variant="paragraph"
            size="large"
            color="grey-1"
          >
            Billings
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { useCallback } from "react";
import type { WrappedFieldProps } from "redux-form";

import { Box, Button } from "@braintrust/braintrust-ui-components";

type SaveAndAddProps = WrappedFieldProps & {
  canSaveAndAddAnother?: boolean;
};

export const SaveAndAddAnotherField = ({
  input,
  canSaveAndAddAnother,
}: SaveAndAddProps) => {
  const handleClick = useCallback(() => {
    input.onChange("true");
  }, [input]);

  return (
    <Box mt={3}>
      <Button variant="tertiary-dark" type="submit" onClick={handleClick}>
        {canSaveAndAddAnother ? "Save and add another" : "Add"}
      </Button>
    </Box>
  );
};

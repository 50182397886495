import { Navigate, Outlet } from "react-router-dom";

import {
  Alert,
  Loader,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { showAlert } from "@braintrust/braintrust-ui-components/utils";
import { useUser } from "@js/apps/common/hooks/use-user";
import { JOB_LOCATION, JobLocationContext } from "@js/apps/jobs/context";
import { PageTitle } from "@js/components/page-title";
import { useAppDispatch } from "@js/hooks";
import { globalTimeoutsDelays, useTimeout } from "@js/hooks/timeout";
import { useIdParam } from "@js/hooks/use-id-param";
import { AppLayout } from "@js/layouts/app";

import { viewJobDetails } from "../../actions";
import { useJobDetails } from "../../hooks";
import { PublicJobDetails } from "../public-job-details";

import { BYOTJobDetails } from "./byot-job-details";
import { StandardJobDetails } from "./standard-job-details";

import style from "./style.module.scss";

type JobDetailsProps = {
  jobId: number;
};

const JobDetailsWrapper = () => {
  const user = useUser();
  const jobId = useIdParam();

  if (!jobId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  if (!user) {
    return <PublicJobDetails jobId={jobId} />;
  }

  return <JobDetails jobId={jobId} />;
};

const JobDetails = ({ jobId }: JobDetailsProps) => {
  const dispatch = useAppDispatch();
  const { loading, job, bid, employerProfile: employer } = useJobDetails(jobId);
  const isBYOT = job?.is_byot === true;

  useTimeout(
    () => {
      if (!jobId) {
        return;
      }
      dispatch(
        viewJobDetails({
          source: JOB_LOCATION.job_details,
          jobUrl: window.location.href,
          jobId,
        }),
      );
    },
    globalTimeoutsDelays.log_view_event,
    [dispatch, jobId],
  );

  return (
    <JobLocationContext.Provider value={JobLocationContext.Values.job_details}>
      <AppLayout className={style.job} bgcolor="var(--soft-blue)">
        {loading || !job ? (
          <Loader />
        ) : (
          <>
            <PageTitle>{`Job: ${job.title}`}</PageTitle>
            {!!job.referring_user &&
              showAlert(
                <Alert type="info" withIcon>
                  <Typography
                    component="p"
                    variant="paragraph"
                    size="medium"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <Typography component="span" fontWeight={500}>
                      {job.referring_user}
                    </Typography>{" "}
                    referred you for this job, please review and place an
                    application if you think you’d be a good fit!
                  </Typography>
                </Alert>,
              )}
            {isBYOT ? (
              <BYOTJobDetails job={job} bid={bid} employer={employer} />
            ) : (
              <StandardJobDetails job={job} bid={bid} employer={employer} />
            )}
          </>
        )}
        <Outlet />
      </AppLayout>
    </JobLocationContext.Provider>
  );
};

export default JobDetailsWrapper;

import React from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  CREATE_POST_PROMPT,
  CREATE_SPACE_POST_PROMPT,
} from "@js/apps/give-and-get-help/components/create-post/constants";

import styles from "./styles.module.scss";

type CreateOrEditPostModalHeaderProps = { closeModal: () => void };

export const CreateOrEditPostModalHeader = ({
  closeModal,
}: CreateOrEditPostModalHeaderProps) => {
  const isMobile = useMediaQuery("md");
  const { pathname } = useLocation();

  const isOnSpacesPage = pathname.includes("spaces");

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography
        component="h4"
        fontWeight={500}
        variant="label"
        style={{ fontSize: isMobile ? "13px" : "20px" }}
      >
        {isOnSpacesPage ? CREATE_SPACE_POST_PROMPT : CREATE_POST_PROMPT}
      </Typography>
      <IconButton
        size={isMobile ? "x-small" : "small"}
        variant="transparent"
        aria-label="Close modal"
        className={styles.closeButton}
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

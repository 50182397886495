import React from "react";
import { Field } from "redux-form";

import { Box } from "@braintrust/braintrust-ui-components";
import { reduxForm } from "@js/forms/components";
import { Form } from "@js/forms/components/form";
import { WARNING_MODAL_TYPE } from "@js/forms/hooks";

import { JobInterviewGradingCriteria } from "../../components/job-interview-grading-criteria";
import { JobInterviewIntroVideo } from "../../components/job-interview-intro-video";
import { JobInterviewQuestions } from "../../components/job-interview-questions";
import { JobInterviewSettings } from "../../components/job-interview-settings";
import { INTERVIEW_QUESTIONS_FORM_ID } from "../../constants";
import { gradingCriteriaValidator, questionsValidator } from "../../validator";

import { InterviewQuestionFormCover } from "./interview-question-form-cover";

import styles from "./style.module.scss";

type InterviewQuestionFormCustomProps = {
  header: React.ReactNode;
  customContent?: React.ReactNode;
};

export const InterviewQuestionsForm = reduxForm<
  any,
  InterviewQuestionFormCustomProps
>({
  form: INTERVIEW_QUESTIONS_FORM_ID,
  unsavedChangesWarning: true,
  unsavedChangesWarningModelType: WARNING_MODAL_TYPE.AI_INTERVIEW_QUESTION,
})(({ submitting, submit, header, customContent }) => {
  return (
    <Form
      onSubmit={submitting ? submit : null}
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      {header}
      {customContent ? (
        customContent
      ) : (
        <>
          <Box className={styles.interviewQuestionForm}>
            <Box className={styles.interviewQuestionFormLeftContainer}>
              <JobInterviewSettings />
              <Field
                name="interview_criteria"
                component={JobInterviewGradingCriteria}
                validate={[gradingCriteriaValidator]}
              />
              <JobInterviewIntroVideo />
            </Box>
            <Box className={styles.interviewQuestionFormRightContainer}>
              <Field
                name="interview_questions"
                component={JobInterviewQuestions}
                validate={[questionsValidator]}
              />
            </Box>
          </Box>

          <Field
            name="is_ai_interview_enabled"
            component={InterviewQuestionFormCover}
          />
        </>
      )}
    </Form>
  );
});

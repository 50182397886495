import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { skipToken } from "@reduxjs/toolkit/query";
import { z } from "zod";

import {
  Box,
  Button,
  ProgressBarStepper,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { fetchCurrentUser, postUserLogin } from "@js/apps/auth/actions";
import { AuthLayout } from "@js/apps/auth/views/layout/sign-in-layout";
import {
  useGetByotInvitationDetailsQuery,
  useRegisterBYOTTalentMutation,
} from "@js/apps/byot/api";
import { InvitationDetails } from "@js/apps/byot/components/invitation-details";
import { UserTestimonialsCarousel } from "@js/apps/onboarding/views/layout/onboarding-carousel/user-comments-carousel";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useNavigate, useQueryParams } from "@js/hooks";
import { RhfCheckboxField, RhfTextField } from "@js/rhf/fields";
import { RhfPasswordField } from "@js/rhf/fields/password";
import type { RhfForm } from "@js/rhf/types";
import { mapServerErrors } from "@js/rhf/utils";

const signUpByot = z.object({
  first_name: z.string().min(1, "Required"),
  last_name: z.string().min(1, "Required"),
  email: z.string().min(1, "Required"),
  password: z.string().min(1, "Required"),
  offerId: z.string(),
  accept_terms_of_use: z.boolean(),
});

type SignUpBYOTForm = RhfForm<typeof signUpByot>;

export const SignUpBYOT = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [register] = useRegisterBYOTTalentMutation();
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting },
  } = useForm<SignUpBYOTForm["Values"]>({
    resolver: zodResolver(signUpByot),
  });
  const { offer_id } = useQueryParams();
  const { data: invitationDetails } = useGetByotInvitationDetailsQuery(
    offer_id ? offer_id : skipToken,
  );

  const isInvitationDetailsError = !!(
    !offer_id ||
    invitationDetails?._error ||
    invitationDetails?.invitation_error
  );

  const onSubmit = async (values: SignUpBYOTForm["Values"]) => {
    try {
      await register(values).unwrap();
      const user = await dispatch(fetchCurrentUser());
      await dispatch(postUserLogin(user));
      navigate("/sign_up_byo_talent/accept_offer");
    } catch (errorUnknown: unknown) {
      const error = errorUnknown as { data: Record<string, string[]> };
      const errors = mapServerErrors(error.data);

      errors.forEach((err) => {
        setError(err.name as keyof SignUpBYOTForm["Values"], err.error);
      });
    }
  };

  useEffect(() => {
    if (invitationDetails?._error) {
      Snackbar.error(invitationDetails._error);
      return;
    }

    if (invitationDetails?.invitation_error) {
      Snackbar.error(invitationDetails.invitation_error);
      return;
    }

    if (invitationDetails) {
      setValue("offerId", offer_id);
    }
  }, [invitationDetails, offer_id, setValue]);

  return (
    <AuthLayout
      noLogoLink
      rightSocket={
        <AuthLayout.TestimonialsContainer>
          <UserTestimonialsCarousel />
        </AuthLayout.TestimonialsContainer>
      }
    >
      <Box width={1} maxWidth={672}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={7}>
            {!!invitationDetails && !isInvitationDetailsError && (
              <InvitationDetails invitationDetails={invitationDetails} />
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={2} p={2}>
            <Box mb={4}>
              <Typography component="p">Welcome to Braintrust!</Typography>
              <Typography
                component="h1"
                variant="title"
                size="small"
                fontWeight={400}
              >
                Create your account.
              </Typography>
            </Box>
            <Box display="flex" gap={2}>
              <RhfTextField
                control={control}
                name="first_name"
                id="first-name"
                label="First name"
                placeholder="First name"
                required
              />
              <RhfTextField
                control={control}
                name="last_name"
                id="last-name"
                label="Last name"
                placeholder="Last name"
                required
              />
            </Box>
            <RhfTextField
              control={control}
              name="email"
              id="email"
              label="Email address"
              placeholder="Email address"
              required
            />
            <RhfPasswordField
              control={control}
              id="password"
              label="Password"
              name="password"
              type="password"
              placeholder="••••••••••••••••••"
              required
            />
            <RhfCheckboxField
              control={control}
              id="terms-of-use-id"
              label={
                <Typography component="span" color="secondary">
                  * I agree to the{" "}
                  <Typography
                    component="a"
                    href={SETTINGS.TERMS_DOC_URL}
                    target="_blank"
                    className="policy-field-link"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    RouterLink={RouterLink}
                  >
                    Terms of Service
                  </Typography>{" "}
                  and{" "}
                  <Typography
                    component="a"
                    href={SETTINGS.PRIVACY_DOC_URL}
                    target="_blank"
                    className="policy-field-link"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    RouterLink={RouterLink}
                  >
                    Privacy Policy
                  </Typography>
                </Typography>
              }
              name="accept_terms_of_use"
            />
            <Box mt={2} />
            <Button
              variant="primary"
              type="submit"
              size="medium"
              disabled={isInvitationDetailsError || isSubmitting}
            >
              Sign up
            </Button>
            <Box mb={10} />
            <ProgressBarStepper activeStep={0} stepsLength={3} />
            <Box display="flex" justifyContent="center">
              <Typography component="p" mt={5} size="small">
                Have an account?{" "}
                <Typography
                  component="link"
                  to="/auth/login/"
                  size="small"
                  fontWeight={500}
                  RouterLink={RouterLink}
                >
                  Log in
                </Typography>
              </Typography>
            </Box>
          </Box>
        </form>
      </Box>
    </AuthLayout>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { CheckIcon } from "@braintrust/braintrust-ui-components/Icons";

export const UploadedFileBadge = ({ fileName }: { fileName: string }) => {
  return (
    <Box bgcolor="var(--soft-grey)" p={1} display="inline-block">
      <Typography component="p" size="small">
        <CheckIcon
          sx={{
            width: 26, // increased to add space between text
            height: 20,
            color: "var(--positive-2)",
            verticalAlign: "middle",
          }}
        />
        <Box component="span" fontWeight={500}>
          {fileName}
        </Box>{" "}
        uploaded
      </Typography>
    </Box>
  );
};

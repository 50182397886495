import { Box, Stack } from "@braintrust/braintrust-ui-components";
import { Emoji } from "@js/components/emoji";

import type { UniversalSearchEmptyCommunicationProps } from "./universal-search-empty-communication";
import { UniversalSearchEmptyCommunication } from "./universal-search-empty-communication";

export const UniversalSearchEmptyStateInitial = ({
  currentTabName,
  tabsWithData,
}: UniversalSearchEmptyCommunicationProps) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        px: { xs: 2, md: 5 },
        py: { xs: 2, md: 3 },
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          width: "64px",
          height: "64px",
          borderRadius: "50%",
          bgcolor: "var(--medium-blue)",
        }}
      >
        <Emoji emoji="👀" size="36px" />
      </Box>

      <Box sx={{ maxWidth: "90%", textAlign: "center" }}>
        <UniversalSearchEmptyCommunication
          tabsWithData={tabsWithData}
          currentTabName={currentTabName}
        />
      </Box>
    </Stack>
  );
};

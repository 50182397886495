import React, { useState } from "react";

import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";

import {
  closeMatchSummaryModal,
  MatchSummaryChipInteractive,
  MatchSummaryTooltip,
  openMatchSummaryModal,
} from "../match-summary";

import type { InviteTalentMatchSummaryProps } from "./invite-talent-match-summary";
import { InviteTalentMatchSummary } from "./invite-talent-match-summary";

export type InviteTalentMatchScoreBadgeProps = Pick<
  InviteTalentMatchSummaryProps,
  "job" | "freelancer" | "notificationHandler"
>;

export const InviteTalentMatchScoreBadge = (
  props: InviteTalentMatchScoreBadgeProps,
) => {
  const isMobileOrTablet = useMediaQuery("md");
  const label = ENUMS.SmartMatchingLabels[props.freelancer.match_label];
  if (!label || label === ENUMS.SmartMatchingLabels.unavailable) {
    return <></>;
  }

  return isMobileOrTablet ? (
    <InviteTalentMatchScoreBadgeWithModal {...props} />
  ) : (
    <InviteTalentMatchScoreBadgeWithTooltip {...props} />
  );
};

const InviteTalentMatchScoreBadgeWithTooltip = (
  props: InviteTalentMatchScoreBadgeProps,
) => {
  const [open, setOpen] = useState(false);
  return (
    <MatchSummaryTooltip
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      matchLabel={props.freelancer.match_label}
    >
      <InviteTalentMatchSummary
        {...props}
        closeSummary={() => setOpen(false)}
      />
    </MatchSummaryTooltip>
  );
};

const InviteTalentMatchScoreBadgeWithModal = (
  props: InviteTalentMatchScoreBadgeProps,
) => {
  const handleChipClick = () => {
    openMatchSummaryModal({
      children: (
        <InviteTalentMatchSummary
          {...props}
          closeSummary={closeMatchSummaryModal}
          userWithBadgeContainerProps={{ sx: { maxWidth: "85%" } }}
        />
      ),
    });
  };

  return (
    <MatchSummaryChipInteractive
      matchLabel={props.freelancer.match_label}
      onClick={handleChipClick}
    />
  );
};

import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { CheckIcon } from "@braintrust/braintrust-ui-components/Icons";

export const EnumeratedItemWithCheckmark = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <Box display="flex" gap={1}>
      <CheckIcon sx={{ fill: "var(--positive-2)" }} />
      <Typography component="span" variant="paragraph" size="medium">
        {children}
      </Typography>
    </Box>
  );
};

import React from "react";
import { Field, Fields } from "redux-form";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useIsEmployerImpersonated } from "@js/apps/jobs/hooks";
import { ButtonSelectField } from "@js/forms/fields";
import { required } from "@js/forms/validators";
import type { JobFormValues } from "@js/types/jobs";
import { enumToOptions } from "@js/utils";

import { POST_JOB_FOR_CLIENT_FIELDS } from "../constants";

import { EmployerAndHiringManagerField } from "./employer-and-hiring-manager-field";
import { JobOwnersFields } from "./job-owners-field";

export const PostJobForClientFields = () => {
  const isEmployerImpersonated = useIsEmployerImpersonated();

  return (
    <Box maxWidth={500}>
      {/* when admin is logged as employer we don't want to display those fields */}
      {!isEmployerImpersonated && (
        <>
          <Typography mb={2} component="h3" size="large" variant="paragraph">
            Choose an Existing Client
          </Typography>
          <Box mb={4}>
            <Fields
              validate={[validateEmployerAndHiringManager]}
              names={[
                POST_JOB_FOR_CLIENT_FIELDS.hiring_manager,
                POST_JOB_FOR_CLIENT_FIELDS.employer,
                POST_JOB_FOR_CLIENT_FIELDS.op_owners,
                POST_JOB_FOR_CLIENT_FIELDS.sale_owners,
              ]}
              format={(value) => {
                if (!value) return undefined;
                return value;
              }}
              component={EmployerAndHiringManagerField}
            />
          </Box>

          <Typography mb={2} component="h3" size="large" variant="paragraph">
            Add Owners
          </Typography>

          <Box mb={4}>
            <Fields
              names={[
                POST_JOB_FOR_CLIENT_FIELDS.op_owners,
                POST_JOB_FOR_CLIENT_FIELDS.sale_owners,
              ]}
              component={JobOwnersFields}
            />
          </Box>
        </>
      )}

      <Box mb={4}>
        <Typography mb={2} component="p" variant="paragraph" size="large">
          Job Fill Support Type
        </Typography>
        <Field
          name={POST_JOB_FOR_CLIENT_FIELDS.job_fill_support_type}
          options={enumToOptions(ENUMS.JobFillSupportTypeLabels)}
          variant="white-violet"
          shape="squared"
          multiple={false}
          validate={[required]}
          component={ButtonSelectField}
          mapValueToFormValue={(value: Record<string, unknown>) => value?.id}
        />
      </Box>
    </Box>
  );
};

const requiredFields =
  (fields: string[]) =>
  (
    value: unknown,
    _formValues: JobFormValues,
    _formProps: unknown,
    field: string,
  ) => {
    if (!fields.includes(field)) {
      return;
    }

    return required(value);
  };

const validateEmployerAndHiringManager = requiredFields([
  POST_JOB_FOR_CLIENT_FIELDS.hiring_manager,
  POST_JOB_FOR_CLIENT_FIELDS.employer,
]);

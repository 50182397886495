import { Box, Stack, Typography } from "@braintrust/braintrust-ui-components";

import { useTrendingHashtags } from "../../hooks/use-trending-hashtags";

import { TrendingHashtagsListItem } from "./trending-hashtags-list-item";

import styles from "./style.module.scss";

type TrendingHashtagsListProps = {
  spaceId: number;
};

export const TrendingHashtagsList = ({
  spaceId,
}: TrendingHashtagsListProps) => {
  const { showHashtagsList, optionsWithColors, handleHashtagClick } =
    useTrendingHashtags(spaceId);

  if (!optionsWithColors.length) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <Typography component="h3" variant="paragraph" size="large">
        Trending topics
      </Typography>
      <Stack
        component="ul"
        gap={1}
        sx={{
          width: "100%",
          p: 0,
          m: 0,
        }}
      >
        {showHashtagsList &&
          optionsWithColors.map((hashtag) => {
            return (
              <TrendingHashtagsListItem
                key={hashtag.label}
                onClick={() => handleHashtagClick(hashtag)}
                iconBackgroundColor={hashtag.iconBackgroundColor}
                iconColor={hashtag.iconColor}
                label={hashtag.label}
              />
            );
          })}
      </Stack>
    </Box>
  );
};

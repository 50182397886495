import {
  Box,
  Button,
  Chip,
  Divider,
  ListItem,
  Stack,
} from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { MemberAvatarWithDetails } from "@js/apps/spaces/components/member-avatar-with-details";
import { useIsSpaceAdmin } from "@js/apps/spaces/hooks/use-is-space-admin";
import type { SpaceUser } from "@js/types/spaces";

import { JoinedIn } from "../joined-in";

import { openRemoveMemberModal } from "./remove-member-modal";

export type PeopleListItemProps = {
  member: SpaceUser;
  spaceId: number;
};

export const PeopleListItem = ({ member, spaceId }: PeopleListItemProps) => {
  const { isSpaceAdmin } = useIsSpaceAdmin({ spaceId });
  const user = useUser();
  const { openMessengerModal } = useOpenMessengerModal();

  const handleMessageClick = () => {
    if (!user) {
      return;
    }

    openMessengerModal({
      context: { participants: [user.id, member.user.id] },
    });
  };

  const isCurrentUser = user?.id === member.user.id;
  const showRemoveButton = isSpaceAdmin && !isCurrentUser;
  const showMessageButton = !isCurrentUser && member.user_can_be_messaged;

  return (
    <>
      <ListItem
        sx={{
          py: 3,
          px: { xs: 3, md: 4 },
          gap: { xs: 2, md: 3 },
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MemberAvatarWithDetails member={member} />
        </Box>
        <Stack
          sx={{
            alignItems: "center",
            gap: { xs: 2, md: 3 },
            flexDirection: { xs: "row-reverse", sm: "row" },
          }}
        >
          {member.is_admin && (
            <Chip
              label="Admin"
              variant="squared"
              color="violet"
              size="x-small"
              typographySize="small"
              LabelProps={{ fontWeight: 400 }}
              sx={{
                flexShrink: 0,
              }}
            />
          )}
          <JoinedIn joinedIn={member.date_joined} />
        </Stack>
        <Stack
          direction={"row"}
          gap={1}
          sx={{ width: { xs: "100%", sm: "auto" } }}
        >
          {showMessageButton && (
            <Button
              variant="primary"
              sx={{ flexGrow: { xs: 1, md: 0 } }}
              size="x-small"
              onClick={handleMessageClick}
            >
              Message
            </Button>
          )}
          {showRemoveButton && (
            <Button
              disabled={member.is_admin}
              variant="black-outlined"
              sx={{ flexGrow: { xs: 1, md: 0 } }}
              size="x-small"
              onClick={() =>
                openRemoveMemberModal({ member, spaceId: Number(spaceId) })
              }
            >
              Remove
            </Button>
          )}
        </Stack>
      </ListItem>
      <Divider color="beige" />
    </>
  );
};

import type { Dispatch } from "react";
import React, { useEffect } from "react";
import cs from "classnames";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { RouterLink } from "@js/components/link";
import { Sanitize } from "@js/components/sanitize";
import type { Employer } from "@js/types/employer";

import styles from "./style.module.scss";

type EmployerOverviewProps = {
  name: Employer["name"];
  overview: Employer["overview"];
};

export const EmployerOverview = ({ name, overview }: EmployerOverviewProps) => {
  return (
    <Box height={1}>
      <Typography
        variant="label"
        component="h2"
        sx={{
          wordBreak: "break-word",
          marginBottom: "16px",
        }}
        size="medium"
      >
        About {name}
      </Typography>
      <OverviewContainer overview={overview} />
    </Box>
  );
};

type OverviewContainerProps = {
  overview: string;
  employerProfileUrl?: string;
  jobId?: number;
};

export const OverviewContainer = ({
  overview,
  employerProfileUrl,
  jobId,
}: OverviewContainerProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const [showSeeMore, setShowSeeMore] = React.useState(false);
  const contentBox = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const user = useUser();

  useEffect(() => {
    if (contentBox && contentRef) {
      const contentWrapper =
        contentBox.current?.getBoundingClientRect().height || 0;
      const contentHeight =
        contentRef.current?.getBoundingClientRect().height || 0;

      const _showSeeMore = showSeeMore || contentHeight > contentWrapper;

      setShowSeeMore(_showSeeMore);
    }
  }, [showSeeMore]);

  return (
    <Box>
      <Box
        maxHeight={expanded ? 1 : 150}
        overflow={expanded ? "visible" : "hidden"}
        className={cs({
          [styles.overlay]: expanded ? false : showSeeMore,
        })}
        ref={contentBox}
      >
        <Box ref={contentRef}>
          <Typography
            variant="paragraph"
            component="div"
            size="small"
            style={{ wordBreak: "break-word" }}
          >
            <OverviewContent overview={overview} />
          </Typography>
        </Box>
      </Box>
      <Box>
        {showSeeMore &&
          (user ? (
            <SeeMoreSection
              employerProfileUrl={employerProfileUrl}
              expanded={expanded}
              setExpanded={setExpanded}
              setShowSeeMore={setShowSeeMore}
            />
          ) : (
            <SignUpBtn jobId={jobId} />
          ))}
      </Box>
    </Box>
  );
};

type OverviewContentProps = { overview: string };

const OverviewContent = ({ overview }: OverviewContentProps) => {
  return <Sanitize className={styles.employerOverview} input={overview} />;
};

type SeeMoreSectionProps = {
  employerProfileUrl?: string;
  expanded: boolean;
  setExpanded: Dispatch<React.SetStateAction<boolean>>;
  setShowSeeMore: Dispatch<React.SetStateAction<boolean>>;
};

const SeeMoreSection = ({
  employerProfileUrl,
  expanded,
  setExpanded,
  setShowSeeMore,
}: SeeMoreSectionProps) => {
  if (!employerProfileUrl) {
    return (
      <ExpandBtn
        expanded={expanded}
        onClick={() => {
          setExpanded((prev) => !prev);
          setShowSeeMore(true);
        }}
      />
    );
  }

  return (
    <Typography
      variant="label"
      component="link"
      to={employerProfileUrl}
      size="small"
      RouterLink={RouterLink}
    >
      See more
    </Typography>
  );
};

type ExpandBtnProps = { expanded: boolean; onClick: VoidFunction };

const ExpandBtn = ({ expanded, onClick }: ExpandBtnProps) => {
  return (
    <button className="btn-reset pointer" onClick={onClick}>
      <Typography variant="label" component="span" size="small">
        {expanded ? "See less" : "See more"}
      </Typography>
    </button>
  );
};

type SignUpBtnProps = { jobId?: number };

const SignUpBtn = ({ jobId }: SignUpBtnProps) => {
  const url = jobId ? `/auth/sign_up/?job_id=${jobId}` : "/auth/sign_up/";

  return (
    <Typography
      variant="label"
      component="link"
      to={url}
      size="small"
      RouterLink={RouterLink}
    >
      Sign up to see more
    </Typography>
  );
};

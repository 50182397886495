import { type ReactElement, useMemo } from "react";

import { Menu } from "@braintrust/braintrust-ui-components";
import type { ShareLink } from "@js/apps/common/services";

import { SharePostButton } from "./share-post-button";
import {
  ShareMenuLinkedInIcon,
  ShareMenuLinkIcon,
  ShareMenuXIcon,
} from "./share-post-menu-icons";

type SharePostMenuProps = {
  isMobile: boolean;
  showPulseAnimation: boolean;
  shareLinks: ReturnType<typeof ShareLink.createAllLinks>;
  anchor?: ReactElement;
  style?: React.CSSProperties;
  onCopyLinkClick: () => void;
  onSharePostButtonClick: () => void;
  getTitleForService: (service: string) => string;
  handleTrackSharePost: (
    kind: EnumType<typeof ENUMS.SharePostEventKind>,
  ) => void;
};

export const SharePostMenuComponent = ({
  anchor,
  style,
  shareLinks,
  isMobile,
  showPulseAnimation,
  onCopyLinkClick,
  onSharePostButtonClick,
  getTitleForService,
  handleTrackSharePost,
}: SharePostMenuProps) => {
  const itemStyle = useMemo<React.CSSProperties>(
    () => ({ gap: "8px", fontSize: isMobile ? "16px" : "13px" }),
    [isMobile],
  );

  const displayedAnchor = !anchor ? (
    <SharePostButton
      onClick={onSharePostButtonClick}
      showPulseAnimation={showPulseAnimation}
    />
  ) : (
    anchor
  );

  return (
    <Menu
      style={style}
      anchor={displayedAnchor}
      mobileHeading="Share to"
      drawerOnMobile
    >
      <Menu.Item
        onClick={() =>
          handleTrackSharePost(ENUMS.SharePostEventKind.SHARE_VIA_TWITTER)
        }
        component="a"
        href={shareLinks.twitter}
        target="_blank"
        rel="noopener noreferrer"
        sx={itemStyle}
      >
        <ShareMenuXIcon isMobile={isMobile} />
        {getTitleForService("X")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          handleTrackSharePost(ENUMS.SharePostEventKind.SHARE_VIA_LINKEDIN)
        }
        component="a"
        href={shareLinks.linkedIn}
        target="_blank"
        rel="noopener noreferrer"
        sx={itemStyle}
      >
        <ShareMenuLinkedInIcon isMobile={isMobile} />
        {getTitleForService("Linkedin")}
      </Menu.Item>
      <Menu.Item onClick={onCopyLinkClick} sx={itemStyle}>
        <ShareMenuLinkIcon isMobile={isMobile} />
        Copy link
      </Menu.Item>
    </Menu>
  );
};

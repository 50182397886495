import React from "react";

import {
  Box,
  ButtonCore,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  AddIcon,
  ArrowRightIcon,
  EditPenIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { UserAvatarProps } from "@js/components/user-avatar";
import { UserAvatar } from "@js/components/user-avatar";

export type UserBubbleProps = {
  color?: string;
  user?: UserAvatarProps["user"];
  name: string;
  title: string;
  actionIconType?: "view" | "edit" | "add";
  to?: string;
  onClick?: () => void;
};

export const UserBubble = ({
  color = "var(--dark-teal)",
  user,
  name,
  title,
  actionIconType,
  ...props
}: UserBubbleProps) => {
  return (
    <Box
      component={ButtonCore}
      width={1}
      maxWidth={432}
      px={2.5}
      py={2}
      borderRadius={46}
      bgcolor={color}
      color="white"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{
        textDecoration: "none",
      }}
      {...props}
    >
      <Box display="flex" alignItems="center" width={1} maxWidth={1}>
        <Box mr={2}>
          {!user ? (
            <Box
              mr={2}
              sx={{
                width: "48px",
                height: "48px",
                border: "1px dashed #FFFFFF",
                borderRadius: "391.438px",
              }}
            />
          ) : (
            <UserAvatar
              hideBadge
              hideBorder
              disableShadow
              user={user}
              size="md"
            />
          )}
        </Box>
        <Box flexGrow={1} overflow="hidden" textAlign="left">
          <Typography component="p" size="small" fontWeight={500}>
            {title}
          </Typography>
          <Typography
            ellipsis
            title={name}
            component="p"
            sx={{ textDecoration: user ? "none" : "underline" }}
            size="medium"
          >
            {name}
          </Typography>
        </Box>
        {actionIconType && (
          <Box display="flex" pl={1} alignItems="center">
            <ActionIcon type={actionIconType} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ActionIcon = ({
  type,
}: {
  type: "view" | "edit" | "add" | undefined;
}) => {
  switch (type) {
    case "view":
      return <ArrowRightIcon />;
    case "edit":
      return <EditPenIcon />;
    case "add":
      return <AddIcon />;
    default:
      return null;
  }
};

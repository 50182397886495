import React from "react";

import type { StackProps } from "@braintrust/braintrust-ui-components";
import {
  Avatar,
  Box,
  CircularProgressDecorator,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { ApprovedBadge } from "@js/apps/jobs/components/approved-badge-with-tooltip";
import type { User } from "@js/types/auth";
import type { EmployerBidListBid } from "@js/types/jobs";

import { getMatchCircleMeta } from "../bid-avatar-with-score-match/helpers";

import { MatchSummaryChip } from "./match-summary-chip";

import styles from "./match-summary-user-with-badge.module.scss";

export type MatchSummaryUserWithBadgeProps = {
  matchLabel: EmployerBidListBid["match_label"];
  freelancerUser: User;
  containerProps?: Pick<StackProps, "sx">;
};

export const MatchSummaryUserWithBadge = ({
  freelancerUser,
  matchLabel,
  containerProps,
}: MatchSummaryUserWithBadgeProps) => {
  const { color, value } = getMatchCircleMeta({
    matchLabelKey: matchLabel,
  });

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        gap: 1,
        mb: 2,
        ...containerProps?.sx,
      }}
    >
      <Box className={styles.avatarContainer}>
        <CircularProgressDecorator value={value} color={color} thickness={2.5}>
          {/* fixes z-index for some Safari versions */}
          <Avatar src={freelancerUser} size="full" style={{ zIndex: 0 }} />
        </CircularProgressDecorator>
      </Box>
      <Stack
        sx={{
          alignItems: "start",
          gap: "2px",
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        <Stack
          direction="row"
          sx={{ gap: 1, alignItems: "center", maxWidth: "100%" }}
        >
          <Typography
            component="span"
            variant="label"
            size="medium"
            fontWeight={500}
            ellipsis
          >
            {freelancerUser.public_name}
          </Typography>
          {freelancerUser.freelancer_approved && (
            <span>
              <ApprovedBadge isApproved />
            </span>
          )}
        </Stack>
        <MatchSummaryChip matchLabel={matchLabel} />
      </Stack>
    </Stack>
  );
};

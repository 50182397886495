import { PersonAddIcon } from "@braintrust/braintrust-ui-components/Icons";

import styles from "./team-member-invitations-add-member-avatar.module.scss";

export const TeamMemberInvitationsAddMemberAvatar = () => {
  return (
    <div className={styles.addMember}>
      <PersonAddIcon
        data-testid="add-member-icon"
        className={styles.addMemberIcon}
      />
    </div>
  );
};

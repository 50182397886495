import {
  IconButton,
  ListItemIcon,
  Menu,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import {
  BlockIcon,
  EditPenIcon,
  FlagIcon,
  LinkIcon,
  MoreHorizIcon,
  RemoveIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useGetPostsActions } from "@js/apps/give-and-get-help/hooks/post-action-menu-actions";
import {
  ModalConfirm,
  ModalInstance,
  openModalAndWaitForInput,
} from "@js/components/modal";
import type { IPost, PostComment } from "@js/types/give-and-get-help";

import { useDisableSpecificPostActions } from "../../hooks/use-disable-specific-post-actions";
import { DeletePostModalContent } from "../delete-post-modal";

type ActionMenuProps = {
  entity: IPost | PostComment;
  urlToCopy: string;
  type: "post" | "comment" | "user";
  handleEditClick?: () => void;
  isCelebratoryPost?: boolean;
};

export const ActionsMenu = ({
  entity,
  urlToCopy,
  type,
  handleEditClick,
  isCelebratoryPost,
}: ActionMenuProps) => {
  const {
    isBanned: isPostAuthorBanned,
    isPostAuthor,
    banUser,
    report,
    deleteUserPost,
    handleCopy,
    handleMenuClick,
    editPost,
    actionVisibility,
  } = useGetPostsActions({ entity, type, isCelebratoryPost });
  const disablePostActions = useDisableSpecificPostActions();
  const isMobile = useMediaQuery("sm");

  return (
    <Menu
      anchor={
        <IconButton
          aria-label={`Open ${type} menu`}
          aria-controls={`menu-${entity.id}`}
          data-testid={`open-menu-${type}-${entity.id}`}
          aria-haspopup="true"
          onClick={handleMenuClick}
          variant="transparent"
          size="medium"
          sx={{
            marginRight: "-12px",
            marginTop: isMobile ? "-12px" : "0",
            alignSelf: "flex-start",
          }}
        >
          <MoreHorizIcon style={{ color: "inherit" }} />
        </IconButton>
      }
    >
      {actionVisibility.banUser && (
        <Menu.Item
          onClick={() => {
            openModalAndWaitForInput({
              children: (
                <ModalConfirm confirmText="Ban user">
                  Are you sure you wish to ban{" "}
                  {entity.freelancer.user.public_name}?
                </ModalConfirm>
              ),
            })
              .then(() => {
                banUser();
              })
              .catch((err) => {
                if (err.cancelledByUser) return;
                console.error(err);
              });
          }}
          sx={{ color: "var(--negative-1)" }}
          disabled={isPostAuthorBanned}
        >
          <ListItemIcon sx={{ color: "var(--negative-1)" }}>
            <BlockIcon />
          </ListItemIcon>
          Ban User
        </Menu.Item>
      )}

      {actionVisibility.edit && (
        <Menu.Item
          disabled={disablePostActions}
          onClick={() => editPost(handleEditClick)}
        >
          <ListItemIcon>
            <EditPenIcon sx={{ color: "var(--grey-1)", strokeWidth: 1.5 }} />
          </ListItemIcon>
          Edit
        </Menu.Item>
      )}

      {actionVisibility.copyLink && (
        <Menu.Item onClick={() => handleCopy(urlToCopy)}>
          <ListItemIcon>
            <LinkIcon sx={{ color: "var(--grey-1)", strokeWidth: 1.5 }} />
          </ListItemIcon>
          Copy link
        </Menu.Item>
      )}

      {actionVisibility.report && (
        <Menu.Item onClick={report}>
          <FlagIcon sx={{ color: "var(--negative-1)" }} />
          <Typography component="span" color="negative" ml={1}>
            Report
          </Typography>
        </Menu.Item>
      )}

      {actionVisibility.delete && (
        <Menu.Item
          onClick={() => {
            ModalInstance.open({
              closeButton: true,
              closeButtonProps: {
                variant: "tertiary",
                size: "x-small",
              },
              keepOnBackdropClick: true,
              children: (
                <DeletePostModalContent
                  isPostAuthor={isPostAuthor}
                  isCelebratoryPost={isCelebratoryPost}
                  deletePost={deleteUserPost}
                  closeModal={ModalInstance.close}
                />
              ),
            });
          }}
          sx={{ color: "var(--negative-1)" }}
        >
          <ListItemIcon sx={{ color: "var(--negative-1)" }}>
            <RemoveIcon />
          </ListItemIcon>
          Delete
        </Menu.Item>
      )}
    </Menu>
  );
};

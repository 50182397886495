import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

export const openAboutSpaceDescriptionModal = ({
  description,
}: {
  description: string;
}) => {
  ModalInstance.open({
    className: styles.modal,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
      className: styles.closeButton,
    },
    children: <AboutThisSpaceDescription description={description} />,
  });
};

type AboutThisSpaceDescriptionProps = {
  description: string;
};

export const AboutThisSpaceDescription = ({
  description,
}: AboutThisSpaceDescriptionProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography component="h3" variant="title" size="small" fontWeight={400}>
        About this space
      </Typography>
      <Typography component="p" variant="paragraph" fontWeight={400}>
        {description}
      </Typography>
    </Box>
  );
};

import { createAction } from "@reduxjs/toolkit";

import type { GridColDef } from "@braintrust/braintrust-ui-components/components/DataGrid";
import { AVATAR_FIELD_UPLOADING } from "@js/apps/employer/action-types";
import { Events } from "@js/services/analytics/constants";

import type {
  ExportCSVCustomDateRange,
  ExportCSVRangeOptionValue,
} from "./components/employer-invoices/export-employer-invoices-modal/helpers";
import {
  EXPORT_CSV_RANGE_OPTION_LABELS,
  EXPORT_CSV_RANGE_OPTION_VALUE,
} from "./components/employer-invoices/export-employer-invoices-modal/helpers";

export const avatarFieldUploading = (isLoading: boolean) => ({
  type: AVATAR_FIELD_UPLOADING,
  payload: isLoading,
});

export const shareApplicantClicked = createAction(Events.CLICK_SHARE_APPLICANT);

export const shareApplicantBulkClicked = createAction(
  Events.CLICK_SHARE_APPLICANT_BULK,
);

export const shareProfileClicked = createAction(Events.CLICK_SHARE_PROFILE);

export const clickSyncATSHomeDashboard = createAction(
  Events.CLICK_SYNC_JOBS_ATS_HOME_DASHBOARD,
);

export const clickNavATSIntegration = createAction(
  Events.CLICK_NAV_ATS_INTEGRATION,
);

export const clickAddIntegrationATS = createAction(
  Events.CLICK_ADD_INTEGRATION_ATS,
);

export const clickResyncATS = createAction(Events.CLICK_RESYNC_ATS);

export const clickDisconnectATS = createAction(Events.CLICK_DISCONNECT_ATS);

export const clickSelectJobsATS = createAction(Events.CLICK_SELECT_JOBS_ATS);

export const clickMoveToDraftsATS = createAction(
  Events.CLICK_MOVE_TO_DRAFTS_ATS,
);

export const clickPublishJobsATS = createAction(Events.CLICK_PUBLISH_JOBS_ATS);

export const myTalentSortOrder = ({
  columns,
  sortBy,
}: {
  columns: GridColDef[];
  sortBy: string;
}) => {
  const sortByFieldLabel =
    columns
      .find((columnDef) => columnDef.field === sortBy)
      ?.headerName?.toLocaleLowerCase() ?? sortBy;
  return {
    type: Events.MY_TALENT_SORT_ORDER,
    payload: { sort_filter: sortByFieldLabel },
  };
};

export const filterMyTalentSearch = ({
  filterType,
  filterValue,
}: {
  filterType: string;
  filterValue: unknown;
}) => {
  return {
    type: Events.FILTER_MY_TALENT_SEARCH,
    payload: { filter_type: filterType, filter_value: filterValue },
  };
};

export const clickMyTalentSearch = () => {
  return {
    type: Events.CLICK_MY_TALENT_SEARCH,
  };
};

export const clickMyTalentViewJobs = () => {
  return {
    type: Events.CLICK_MY_TALENT_VIEW_JOBS,
  };
};

export const clickMyTalentShowTalent = () => {
  return {
    type: Events.CLICK_MY_TALENT_SHOW_TALENT,
  };
};

export const clickMyTalentShowContracts = () => {
  return {
    type: Events.CLICK_MY_TALENT_SHOW_CONTRACTS,
  };
};

export const clickMyTalentCSV = () => {
  return {
    type: Events.CLICK_MY_TALENT_CSV,
  };
};

export const clickMyTalentColumns = ({
  columnOptions,
  selectedColumns,
}: {
  columnOptions: GridColDef[];
  selectedColumns: string[];
}) => {
  const selectedColumnsNames = selectedColumns.map((columnField) => {
    const columnDef = columnOptions.find(
      (myTalentTableColumnDef) => myTalentTableColumnDef.field === columnField,
    );
    return columnDef?.headerName?.toLocaleLowerCase() ?? columnField;
  });

  return {
    type: Events.CLICK_MY_TALENT_COLUMNS,
    payload: { columns: selectedColumnsNames },
  };
};

export const employerInvoicesSortOrderChanged = ({
  columns,
  sortBy,
}: {
  columns: GridColDef[];
  sortBy: string;
}) => {
  const sortByFieldLabel =
    columns
      .find((columnDef) => columnDef.field === sortBy)
      ?.headerName?.toLocaleLowerCase() ?? sortBy;

  return {
    type: Events.INVOICE_SORT_ORDER,
    payload: { sort_filter: sortByFieldLabel },
  };
};

export const filterEmployerInvoicesSearchApplied = ({
  filterType,
  filterValue,
}: {
  filterType: string;
  filterValue: unknown;
}) => {
  return {
    type: Events.FILTER_INVOICES_SEARCH,
    payload: { filter_type: filterType, filter_value: filterValue },
  };
};

export const employerInvoicesSearchApplied = () => {
  return {
    type: Events.CLICK_INVOICES_SEARCH,
  };
};

export const processExportCSVDateRangeForTracking = (
  value: ExportCSVRangeOptionValue,
  customRange: ExportCSVCustomDateRange | undefined,
): string => {
  switch (value) {
    case EXPORT_CSV_RANGE_OPTION_VALUE.CUSTOM_RANGE: {
      const stringifiedCustomRange = [
        customRange?.date_after,
        customRange?.date_before,
      ]
        .map((date) => date ?? "...")
        .join(" - ");

      return `${EXPORT_CSV_RANGE_OPTION_LABELS[value]?.toLocaleLowerCase()} ${stringifiedCustomRange}`;
    }
    default:
      return (
        EXPORT_CSV_RANGE_OPTION_LABELS[value]?.toLocaleLowerCase() ?? value
      );
  }
};

export const employerInvoicesCSVClicked = (
  value: ExportCSVRangeOptionValue,
  customRange: ExportCSVCustomDateRange | undefined,
) => {
  const dateRange = processExportCSVDateRangeForTracking(value, customRange);

  return {
    type: Events.CLICK_INVOICE_CSV,
    payload: { date_range: dateRange },
  };
};

export const downloadEmployerInvoicesClicked = () => {
  return {
    type: Events.CLICK_DOWNLOAD_INVOICES,
  };
};

export const employerInvoicesColumnsChanged = ({
  columnOptions,
  selectedColumns,
}: {
  columnOptions: Array<{ label: string; value: string }>;
  selectedColumns: string[];
}) => {
  const selectedColumnsNames = selectedColumns.map((selectedColumn) => {
    const columnDef = columnOptions.find(
      (columnOption) => columnOption.value === selectedColumn,
    );
    return columnDef?.label?.toLocaleLowerCase() ?? selectedColumn;
  });

  return {
    type: Events.CLICK_INVOICES_COLUMNS,
    payload: { columns: selectedColumnsNames },
  };
};

export const welcomeModalPostJobClicked = createAction(
  Events.CLICK_WELCOME_MODAL_POST_JOB,
);

export const welcomeModalLinkClicked = createAction(
  Events.CLICK_WELCOME_MODAL_LEARN_MORE,
);

export const welcomeModalClosed = createAction(
  Events.CLICK_WELCOME_MODAL_CLOSE,
);

import { ExplainerCarousel as Carousel } from "@braintrust/braintrust-ui-components";
import { NotLoggedInApplyButton } from "@js/apps/jobs/components";
import { getApplyButtonLabelByStatus } from "@js/apps/jobs/views/public-job-details/helpers";
import type { Job } from "@js/types/jobs";

import style from "./style.module.scss";

type ExplainerCarouselProps = {
  job: Job;
};

export const ExplainerCarousel = ({ job }: ExplainerCarouselProps) => {
  return (
    <div className={style.explainerWrapper}>
      <Carousel
        items={EXPLAINER_CAROUSEL_DATA}
        loop
        customButton={
          <NotLoggedInApplyButton
            jobId={job.id}
            variant="positive-2"
            shape="squared"
            fullWidth
            className={style.applyBtn}
            label={getApplyButtonLabelByStatus(job.job_status)}
          />
        }
      />
    </div>
  );
};
const EXPLAINER_CAROUSEL_DATA = [
  {
    backgroundSource: `${SETTINGS.STATIC_URL}public-job-page/explainer_middleman.svg`,
    title: "Eliminate the middleman",
    description:
      "Get ready for savings on both sides: No talent fees or membership costs so freelancers keep 100% of what they earn—and client budgets go 3-4x further.",
  },
  {
    backgroundSource: `${SETTINGS.STATIC_URL}public-job-page/explainer_ownership.svg`,
    title: "Take ownership",
    description:
      "Get rewarded with BTRST, Braintrust's cryptotoken, for inviting talent, taking a class—even signing up! Use token rewards to help shape the future of the platform.",
  },
  {
    backgroundSource: `${SETTINGS.STATIC_URL}public-job-page/explainer_work_with_best.svg`,
    title: "Work with the best",
    description:
      "All talent and clients on the platform are meticulously vetted and matched, so you can get straight to work with the best of the best.",
  },
];

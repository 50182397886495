import React from "react";

import { FileIcon } from "@braintrust/braintrust-ui-components/Icons";

import { FileCommonBox } from "./file-common-box";

import styles from "./styles.module.scss";

type FilePreviewProps = {
  name: string;
  size?: number;
  fileLink: string;
};

export const FilePreview = ({ name, size, fileLink }: FilePreviewProps) => {
  return (
    <a
      className={`${styles.postAttachment} ${styles.postAttachmentFile}`}
      download
      href={fileLink}
      rel="noopener noreferrer"
      target="_blank"
    >
      <FileCommonBox
        name={name}
        size={size}
        color="var(--grey-1)"
        previewThumbnail={
          <FileIcon
            sx={{
              color: "var(--info-1)",
            }}
          />
        }
      />
    </a>
  );
};

import type { ChangeEventHandler } from "react";
import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { NavSearchProps } from "@braintrust/braintrust-ui-components";
import { NavSearch } from "@braintrust/braintrust-ui-components";

type ReduxFormTextFieldFactoryProps = TypedWrappedFieldProps<string> &
  NavSearchProps;

const ReduxFormSearchFieldFactory =
  (Component: React.FC<React.PropsWithChildren<NavSearchProps>>) =>
  ({ input, ...props }: ReduxFormTextFieldFactoryProps) => {
    const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      input.onChange(event.target.value);
    };

    return <Component {...props} {...input} onChange={onChange} />;
  };

export const NavSearchField = ReduxFormSearchFieldFactory(NavSearch);

import { motion } from "framer-motion";

import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box, Button } from "@braintrust/braintrust-ui-components";
import { useAppDispatch } from "@js/hooks";

import { getInspiredBtnClicked } from "../../actions";
import { useShowGetInspiredButton } from "../../hooks";
import { openHowToPostModal } from "../how-to-post-modal";

import styles from "./styles.module.scss";

type GetInspiredBtnProps = BoxProps;

export const GetInspiredBtn = (props: GetInspiredBtnProps) => {
  const dispatch = useAppDispatch();
  const { showGetInspiredButton } = useShowGetInspiredButton();

  const handleOnClick = () => {
    dispatch(getInspiredBtnClicked());
    openHowToPostModal();
  };

  if (showGetInspiredButton) {
    return (
      <Box className={styles.wrapper} {...props}>
        <Button
          variant="medium-violet"
          className={styles.button}
          onClick={handleOnClick}
        >
          <AnimatedPlayIcon />
          Get inspired
        </Button>
      </Box>
    );
  }

  return null;
};

const AnimatedPlayIcon = () => {
  return (
    <motion.svg
      viewBox="0 0 24 24"
      fill="none"
      className={styles.svg}
      animate={{
        scale: [1, 1.3, 1.3, 1],
        rotate: [0, -15, -15, 0],
      }}
      transition={{
        duration: 1,
        ease: "easeInOut",
        times: [0, 0.2, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 2.4,
        delay: 2,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5708 11.1425C19.2182 11.5309 19.2182 12.4691 18.5708 12.8575L6.5145 20.0913C5.84797 20.4912 5 20.0111 5 19.2338L5 4.76619C5 3.9889 5.84797 3.50878 6.5145 3.9087L18.5708 11.1425Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
};

import { type FC, useMemo } from "react";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import type { PurchaseUpgradeParams } from "@js/apps/auth/api";
import {
  accordionContainerStyle,
  BOOST_ACCORDION_CONTENT,
  BOOST_DESCRIPTION,
  INSIGHTS_ACCORDION_CONTENT,
  INSIGHTS_DESCRIPTION,
} from "@js/apps/jobs/components/application-onramp-modal/constants";
import { BasicAccordion } from "@js/apps/on-ramp/components/onramp-modal/accordion";

type DescriptionProps = {
  requiredTotalBtrst: number;
  requiredTotalBtrstRaw: string;
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const Description: FC<DescriptionProps> = ({
  requiredTotalBtrst,
  requiredTotalBtrstRaw,
  upgradeType,
}) => {
  const isMobile = useMediaQuery("sm");
  const formattedRequiredTotalBtrst =
    requiredTotalBtrst < 0 ? requiredTotalBtrst * -1 : requiredTotalBtrst;

  const accordionContent = useMemo(() => {
    if (upgradeType === "boost") {
      return BOOST_ACCORDION_CONTENT;
    } else if (upgradeType === "insights") {
      return INSIGHTS_ACCORDION_CONTENT;
    } else {
      return [];
    }
  }, [upgradeType]);

  const description = useMemo(() => {
    if (upgradeType === "boost") {
      return BOOST_DESCRIPTION;
    } else if (upgradeType === "insights") {
      return INSIGHTS_DESCRIPTION;
    } else {
      return [];
    }
  }, [upgradeType]);

  const formattedUpgradeType = useMemo(() => {
    const newLabel = upgradeType.charAt(0).toUpperCase() + upgradeType.slice(1);

    if (upgradeType === "boost") {
      return `${newLabel}s`;
    }

    return newLabel;
  }, [upgradeType]);

  const backgroundColor = useMemo(() => {
    if (upgradeType === "boost") {
      return "var(--soft-blue)";
    } else if (upgradeType === "insights") {
      return "var(--soft-green)";
    } else {
      return "";
    }
  }, [upgradeType]);

  const purchaseLabelProps: TypographyProps = useMemo(() => {
    if (isMobile) {
      return {
        component: "p",
        variant: "label",
        size: "medium",
        fontWeight: 500,
      };
    }

    return {
      component: "p",
      variant: "label",
      size: "large",
      fontWeight: 500,
    };
  }, [isMobile]);

  const titleProps: TypographyProps = useMemo(() => {
    if (isMobile) {
      return {
        component: "h3",
        variant: "title",
        size: "small",
      };
    }

    return {
      variant: "title",
      component: "h3",
      size: "medium",
    };
  }, [isMobile]);

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      boxSizing="border-box"
      maxWidth={{ xs: "100%", lg: "560px" }}
      padding={{ xs: "20px", sm: "36px 28px", md: "50px 63px", lg: "0 80px" }}
      sx={{ backgroundColor }}
    >
      <Box
        position="absolute"
        top={0}
        left={{ xs: 69, lg: "unset" }}
        right={{ xs: "unset", lg: 0 }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/job-insight-bg.svg`}
          alt="braintrust-logo"
          width="239"
          height="183"
        />
      </Box>
      <Box
        zIndex={1}
        display="flex"
        flexDirection="column"
        gap={{ xs: 1, lg: 3 }}
      >
        <Box>
          <Box display={"flex"} gap={1} alignItems={"center"} marginBottom={1}>
            <Typography {...purchaseLabelProps}>Purchase</Typography>
            <Box display="flex" alignContent="center">
              <img
                src={`${SETTINGS.STATIC_URL}logo/logo-symbol.svg`}
                alt="logo"
                width="20"
                height="19"
              />
            </Box>
            <Typography {...purchaseLabelProps}>BTRST</Typography>
          </Box>
          <Typography {...titleProps} fontWeight={400} mb={{ xs: 1, lg: 2 }}>
            Add{" "}
            <Tooltip title={requiredTotalBtrstRaw}>
              <span>{formattedRequiredTotalBtrst}</span>
            </Tooltip>{" "}
            BTRST to your wallet to purchase Application {formattedUpgradeType}
          </Typography>
          <Typography
            component="p"
            variant="paragraph"
            size="medium"
            fontWeight={500}
          >
            {description}
          </Typography>
        </Box>

        <Typography component="p" variant="paragraph" size="small">
          Looks like you don't have enough BTRST for Application{" "}
          {formattedUpgradeType}. No worries. You can securely buy BTRST through
          Coinbase using USD or the fiat of your choice. Once the tokens are in
          your wallet, we'll help you get Application {formattedUpgradeType}.
        </Typography>
        <Box sx={accordionContainerStyle}>
          <BasicAccordion options={accordionContent} />
        </Box>
      </Box>
    </Box>
  );
};

import type { ReactNode } from "react";

import { Stack } from "@braintrust/braintrust-ui-components";

type SearchListProps = { children: ReactNode };

export const SearchList = ({ children }: SearchListProps) => {
  return (
    <Stack
      component="ul"
      sx={{
        alignSelf: "start",
        listStyle: "none",
        width: "100%",
        p: 0,
        m: 0,
      }}
    >
      {children}
    </Stack>
  );
};

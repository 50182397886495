import { Button } from "@braintrust/braintrust-ui-components";
import { reviewJobClicked } from "@js/apps/jobs/actions";
import { useInitializeInterviewQuestion } from "@js/apps/jobs/apps/create-job/hooks/use-initialize-interview-question";
import {
  JOB_FORM_SUBMIT_TYPE,
  useUpdateSubmitType,
} from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useGetJobFlowEntry } from "@js/apps/jobs/hooks/use-get-job-flow-entry";
import { useAppDispatch } from "@js/hooks";

export const SubmitButton = () => {
  const updateSubmitType = useUpdateSubmitType();
  const dispatch = useAppDispatch();
  const { flow_entry } = useGetJobFlowEntry();
  const { generateInterviewQuestion } = useInitializeInterviewQuestion();

  const handleBtnClick = () => {
    updateSubmitType(JOB_FORM_SUBMIT_TYPE.job_dry_run);
    generateInterviewQuestion();
    dispatch(reviewJobClicked({ flow_entry }));
  };

  return (
    <Button
      onClick={handleBtnClick}
      type="submit"
      variant="positive"
      shape="squared"
    >
      Review and post
    </Button>
  );
};

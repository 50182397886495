import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box } from "@braintrust/braintrust-ui-components";
import { ApprovedIcon } from "@braintrust/braintrust-ui-components/Icons";

export const ApprovedBadge = ({
  iconStyles,
  boxProps,
}: {
  iconStyles?: React.CSSProperties;
  boxProps?: BoxProps;
}) => {
  return (
    <Box
      bgcolor="var(--yellow)"
      borderRadius="50%"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      border="2px solid var(--yellow)"
      {...boxProps}
    >
      <ApprovedIcon
        style={{
          color: "var(--black)",
          fontSize: 21.5,
          strokeWidth: "3px",
          ...iconStyles,
        }}
      />
    </Box>
  );
};

import React from "react";
import { Field } from "redux-form";

import { Typography } from "@braintrust/braintrust-ui-components";
import { CheckboxField } from "@js/forms/fields/checkbox";

export const SMSOptInField = () => {
  return (
    <Field
      name="sms_opt_in"
      id="sms-opt-in"
      data-testid="sms-opt-in"
      component={CheckboxField}
    >
      <Typography
        component="span"
        color="secondary"
        style={{ lineHeight: "1.2" }}
      >
        I consent to receive SMS/MMS messages from Braintrust. Message and data
        rates may apply. Message frequency varies. Text STOP to cancel.
      </Typography>
    </Field>
  );
};

import {
  Box,
  Button,
  Divider,
  IconButton,
  Loader,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  AvailabilityOptionsField,
  AvailabilityOptionsFieldError,
} from "@js/apps/available-for-work/forms/fields/availability-options-field/availability-options-field";
import {
  clickAvailabilityToggle,
  clickSetAvailability,
} from "@js/apps/freelancer/actions";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";
import { RhfSwitch } from "@js/rhf/fields/switch";

import { useAvailabilityForm } from "../../hooks/use-availability-form";

import { WorkingTimezoneField } from "./working-timezone-field";

import styles from "./styles.module.scss";

type AvailabilityModalProps = {
  onClose: () => void;
};

export const AvailabilityModal = ({ onClose }: AvailabilityModalProps) => {
  const { onSubmit, isLoading, handleSubmit, disableSubmit, control } =
    useAvailabilityForm(onClose);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("sm");

  const handleToggleClick = (isToggleOn: unknown) => {
    dispatch(clickAvailabilityToggle(!!isToggleOn));
  };

  const handleSaveAvailabilityClick = () => dispatch(clickSetAvailability());

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={styles.availabilityModalWrapper}>
        <Box className={styles.availabilityModalHeader}>
          <Box className={styles.availabilityModalTitle}>
            <Typography
              component="h3"
              variant="title"
              size="small"
              fontWeight={400}
            >
              Set your work availability
            </Typography>
            <IconButton
              className={styles.availabilityModalCloseIcon}
              variant="tertiary"
              size="small"
              aria-label="Close availability form modal"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            component="p"
            variant="paragraph"
            size="medium"
            color="grey-1"
          >
            If you’re looking for work, select the job types and hours you’re
            open to working, and it’ll appear on your profile.
          </Typography>
        </Box>
        <RhfSwitch
          id="availability_for_work"
          name="availability_for_work"
          control={control}
          label={
            <Stack
              direction="row"
              sx={{ alignItems: "center", gap: 2, lineHeight: 1 }}
            >
              <Typography component="span" variant="label" size="large">
                I’m looking for work
              </Typography>
              {isLoading && <Loader size={25} />}
            </Stack>
          }
          LabelProps={{
            sx: {
              gap: 1,
            },
          }}
          color="secondary"
          onChange={handleToggleClick}
        />
        <Box className={styles.availabilityModalOpenOptions}>
          <Typography
            className={styles.availabilityModalOpenOptionsTitle}
            component="p"
            variant="paragraph"
            size="medium"
            color="grey-1"
          >
            Job types I'm open to:
          </Typography>

          <AvailabilityOptionsField control={control} />
        </Box>
        <Divider color="secondary" />
        <Box my={3}>
          <WorkingTimezoneField control={control} />
        </Box>

        {isMobile && <Divider color="secondary" sx={{ mb: 2 }} />}

        <Box className={styles.availabilityModalErrorSection}>
          <AvailabilityOptionsFieldError control={control} />
          <Button
            variant="dark-violet"
            shape="squared"
            size="small"
            type="submit"
            fontWeight={400}
            disabled={disableSubmit}
            sx={{
              flexShrink: 0,
            }}
            onClick={handleSaveAvailabilityClick}
          >
            Save my availability
          </Button>
        </Box>
      </Box>
    </form>
  );
};

type OpenAvailAbilityModalConfig = {
  onClose?: () => void;
};

export const openAvailabilityModal = (config?: OpenAvailAbilityModalConfig) => {
  let wasClosed = false;
  const handleClose = () => {
    if (wasClosed) {
      return;
    }

    wasClosed = true;
    if (config?.onClose) {
      config.onClose();
    }
    ModalInstance.close();
  };

  ModalInstance.close();
  ModalInstance.open({
    children: <AvailabilityModal onClose={handleClose} />,
    closeButton: false,
    onClose: handleClose,
  });
};

import { Loader } from "@braintrust/braintrust-ui-components";
import { BidRejectionFeedbackForm } from "@js/apps/jobs/apps/bids";
import { CoreLayout } from "@js/layouts/core";

import { useBidRejectionFeedbackPage } from "../../apps/bids/hooks";

const RejectionFeedbackPage = () => {
  const { handleGoBackClick, onSubmit, isLoading } =
    useBidRejectionFeedbackPage();

  if (isLoading) {
    return <Loader centered />;
  }

  return (
    <CoreLayout pageTitle="Rejection Feedback">
      <BidRejectionFeedbackForm
        onSubmit={onSubmit}
        handleGoBackClick={handleGoBackClick}
      />
    </CoreLayout>
  );
};

export default RejectionFeedbackPage;

import { Suspense, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Field } from "redux-form";

import {
  Box,
  Loader,
  Tab,
  Tabs,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import { SelectField } from "@js/forms/fields";
import { normalizePath } from "@js/utils";

import { FreelancerDashboardLayout } from "../layout";

import { HelpOffers11FiltersForm, OFFER_STATUS_OPTIONS } from "./filters";

import styles from "../listings-content-wrapper.module.scss";

const MY_HELP_OFFERS_NAVIGATION_ITEMS = [
  {
    label: "Getting help",
    path: "/talent/dashboard/my_jobs/help_offers/getting_help",
  },
  {
    label: "Giving help",
    path: "/talent/dashboard/my_jobs/help_offers/giving_help",
  },
];

export const HELP_OFFERS_1_1_FILTER_STATUS_FORM_ID =
  "help-offers-1-1-filter-status";

export const MyHelpOffersListingPage = () => {
  const { pathname } = useLocation();

  const isMobile = useMediaQuery(500);
  const labelSize = isMobile ? "medium" : "large";

  const value = useMemo(() => {
    const normalizedPath = normalizePath(pathname);
    const currentTab = MY_HELP_OFFERS_NAVIGATION_ITEMS.find((item) => {
      const tabPathNormalized = normalizePath(item.path);
      return normalizedPath.includes(tabPathNormalized);
    });
    return currentTab?.path || MY_HELP_OFFERS_NAVIGATION_ITEMS[0].path;
  }, [pathname]);

  return (
    <FreelancerDashboardLayout pageTitle="1:1 Help Offers">
      <Box
        className={styles.wrapper}
        sx={{ "&&": { mt: 3, pt: { xs: 2, sm: "30px" }, minHeight: "300px" } }}
      >
        <Box
          component="nav"
          mb={{ xs: 3, sm: 4 }}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Tabs
            aria-label="my help offers tabs"
            value={value}
            sx={{
              "& .MuiTabs-flexContainer": {
                gap: 3,
              },
            }}
          >
            {MY_HELP_OFFERS_NAVIGATION_ITEMS.map(({ label, path }) => (
              <Tab
                key={label}
                value={path}
                component={RouterLink}
                inactiveColorVariable="grey-2"
                to={path}
                sx={{ pb: 1 }}
                label={
                  <Typography
                    component="span"
                    variant="label"
                    size={labelSize}
                    fontWeight={400}
                  >
                    {label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
          <HelpOffers11FiltersForm form={HELP_OFFERS_1_1_FILTER_STATUS_FORM_ID}>
            <Box mt={{ xs: 1, sm: 0 }} minWidth={186}>
              <Field
                id="offer-status"
                name="status"
                component={SelectField}
                options={OFFER_STATUS_OPTIONS}
              />
            </Box>
          </HelpOffers11FiltersForm>
        </Box>
        <Suspense fallback={<Loader centered />}>
          <Outlet />
        </Suspense>
      </Box>
    </FreelancerDashboardLayout>
  );
};

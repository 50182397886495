import React, { useEffect } from "react";

import { SwipeableDrawer } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { useAppDispatch } from "@js/hooks/redux";

import {
  hideReactionsDrawer,
  useReactionsDrawerOpen,
} from "../store/stickers-module-slice";

import { ReactionsDrawerContent } from "./reactions-drawer-content";
import { ReactionsDrawerTitle } from "./reactions-drawer-title";

export const ReactionsDrawer = () => {
  const isMobile = useMediaQuery("sm");
  const isOpen = useReactionsDrawerOpen();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (!isOpen) {
      return;
    }
    // hide drawer when resoltion changes to non mobile
    dispatch(hideReactionsDrawer());
  }, [isOpen, isMobile, dispatch]);

  const handleDrawerClose = () => {
    dispatch(hideReactionsDrawer());
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={handleDrawerClose}
      onOpen={() => null} // open state is controlled
      PaperProps={{
        sx: {
          borderRadius: "16px 16px 0 0",
          height: "40vh",
          pt: 3,
        },
      }}
    >
      <ReactionsDrawerTitle />
      <ReactionsDrawerContent />
    </SwipeableDrawer>
  );
};

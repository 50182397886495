import { Field } from "redux-form";

import type { WrappedFieldProps } from "@braintrust/braintrust-ui-components";
import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { CalendarLinkTextField } from "@js/apps/settings/components/calendar-link";
import { CheckboxField } from "@js/forms/fields";
import type { EmployerBidListBid } from "@js/types/jobs";

import { calendarLinkValidator } from "./validator";

type SetupCalendarFieldsProps = {
  calendar_link: WrappedFieldProps;
  save_as_default: WrappedFieldProps;
  freelancer: EmployerBidListBid["freelancer"];
  onConfirm: () => void;
};

export const SetupCalendarFields = ({
  freelancer,
  onConfirm,
}: SetupCalendarFieldsProps) => {
  const name = freelancer?.user?.first_name;

  return (
    <>
      <Typography component="h1" variant="title" fontWeight={400} size="small">
        Send scheduling link
      </Typography>
      <Box mt={1}>
        Advance {name} to the next round by sending a scheduling link to allow
        them to book a time on your calendar.
      </Box>

      <Box mt={3} mb={4}>
        <Field
          id="calendar_link"
          name="calendar_link"
          label="Your scheduling link"
          placeholder="Enter your link"
          component={CalendarLinkTextField}
          validate={[calendarLinkValidator]}
        />
        <Field
          id="save_as_default"
          name="save_as_default"
          component={CheckboxField}
          size="small"
          label={
            <Typography component="p" variant="paragraph" size="small">
              Save as my default scheduling link
            </Typography>
          }
        />
      </Box>
      <Button variant="primary" shape="squared" onClick={onConfirm}>
        Confirm details
      </Button>
    </>
  );
};

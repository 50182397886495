import type { ReactNode } from "react";

import { Stack } from "@braintrust/braintrust-ui-components";

type ReferralsListTopContainerProps = {
  children: ReactNode;
};

export const ReferralsListTopContainer = ({
  children,
}: ReferralsListTopContainerProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        mb: { sx: 1, sm: 2, md: 4 },
        alignItems: "center",
        flexWrap: "wrap",
        gap: 4,
      }}
    >
      {children}
    </Stack>
  );
};

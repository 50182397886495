import { Typography } from "@braintrust/braintrust-ui-components";
import { careerHelpThroughMyPostsClicked } from "@js/apps/give-and-get-help/actions";
import { BackButton } from "@js/apps/give-and-get-help/components/header/back-button";
import { useAppDispatch } from "@js/hooks";

import styles from "./styles.module.scss";

type MyPostsHeaderProps = {
  postsCount: number;
};

export const MyPostsHeader = ({ postsCount }: MyPostsHeaderProps) => {
  const dispatch = useAppDispatch();

  const onGoToCareerHelpClick = () => {
    dispatch(careerHelpThroughMyPostsClicked());
  };

  return (
    <header className={styles.myPostsHeader}>
      <Typography component="h1" variant="title" size="medium" fontWeight={400}>
        My posts <span className={styles.counter}>({postsCount})</span>
      </Typography>

      <BackButton onClick={onGoToCareerHelpClick} />
    </header>
  );
};

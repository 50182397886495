import { useSnackbar } from "notistack";

import {
  Box,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  CircleCheckOutlineIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";

import styles from "./remove-member-snackbar.module.scss";

export const UserRemovedSnackbarContent = () => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box className={styles.removeMemberSnackbarContainer}>
        <CircleCheckOutlineIcon
          className={styles.removeMemberSnackbarCheckIcon}
        />
        <Typography color="white" component="p" variant="paragraph">
          User removed
        </Typography>
        <IconButton
          size="small"
          variant="transparent"
          onClick={() => closeSnackbar()}
          aria-label="Close"
        >
          <CloseIcon className={styles.removeMemberSnackbarCloseIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

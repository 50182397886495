import { type ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { getCreateEmployerAccountRedirectLink } from "../../hooks/onboarding-form/helpers";

import { LoadingOverlay } from "./loading-overlay";

export const NEW_EMPLOYER_SELF_SERVE_PARAM = "new_employer_self_serve";

export const OnboardingNewSelfServeRedirect = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const shouldRedirect = searchParams.has(NEW_EMPLOYER_SELF_SERVE_PARAM);

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }

    window.location.href = getCreateEmployerAccountRedirectLink();
  }, [shouldRedirect]);

  if (shouldRedirect) {
    return <LoadingOverlay fullScreen loaderProps={{ size: 64 }} show />;
  }

  return <>{children}</>;
};

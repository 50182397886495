import React from "react";

import {
  Button,
  Divider,
  Grid,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { SectionSubtitle } from "@js/apps/settings/forms/helpers";
import { Datetime } from "@js/components/date";
import type { SessionItem as SessionItemType } from "@js/types/settings";
import { DateTimeFormats } from "@js/utils/date/types";

import styles from "./styles.module.scss";

type SessionItemProps = {
  session: SessionItemType;
  num: number;
  onDeleteClick: ((x: SessionItemType) => void) | null;
};

export const SessionItem = ({
  session,
  onDeleteClick,
  num,
}: SessionItemProps) => {
  return (
    <Grid container spacing={2} className={styles.item}>
      <Grid item xs={12}>
        <SectionSubtitle
          action={
            onDeleteClick && (
              <Button
                variant="secondary"
                onClick={() => onDeleteClick(session)}
              >
                Terminate
              </Button>
            )
          }
        >
          Session {num}
        </SectionSubtitle>
      </Grid>
      <Grid item sm={6} xs={12}>
        <SessionDetail
          label="Current session"
          info={session.is_current_session ? "Yes" : "No"}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <SessionDetail label="Device details" info={session.device_details} />
      </Grid>
      <Grid item xs={12}>
        <Divider color="secondary" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <SessionDetail label="IP address" info={session.ip_address} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <SessionDetail
          label="Last activity"
          info={
            <>
              {session.localization || "Unknown"}
              <Typography
                component="span"
                variant="label"
                size="small"
                display="block"
                fontWeight={400}
              >
                <Datetime
                  date={session.last_activity}
                  format={DateTimeFormats["January 1st 1970, 0:00:00 pm"]}
                />
              </Typography>
            </>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider color="secondary" />
      </Grid>
    </Grid>
  );
};

type SessionDetailProps = {
  label: string;
  info: React.ReactNode;
};

const SessionDetail = ({ label, info }: SessionDetailProps) => {
  return (
    <>
      <Typography variant="label" component="h6" mb={2}>
        {label}
      </Typography>
      <Typography component="p" size="large">
        {info}
      </Typography>
    </>
  );
};

import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import type { ResumeFieldProps } from "@js/apps/jobs/forms/fields";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields";
import { AddResumeField } from "@js/apps/jobs/forms/fields";
import { useAppSelector } from "@js/hooks";

type AddResumeToBidFieldProps = Omit<ResumeFieldProps, "hideCheckbox"> & {
  isRequired: boolean;
  isEditPage: boolean;
};

export const AddResumeToBidField = ({
  isRequired,
  isEditPage,
  ...props
}: AddResumeToBidFieldProps) => {
  const resumeInput = props.input;
  const profileResumeId = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.resume?.id,
  );

  const isProfileResumeAdded =
    !!resumeInput?.value?.id && resumeInput?.value?.id === profileResumeId;

  return (
    <FieldSectionPanelWithImportance
      id="resume"
      title="Include your resume"
      isError={props.meta.touched && props.meta.error}
      fieldImportance={isRequired ? "required" : "optional"}
    >
      <AddResumeField
        hideCheckbox={isEditPage || isProfileResumeAdded}
        isProfileResumeAdded={isProfileResumeAdded}
        {...props}
      />
      {props.meta.touched && props.meta.error && (
        <Typography error component="p" sx={{ mt: 2 }}>
          {props.meta.error}
        </Typography>
      )}
    </FieldSectionPanelWithImportance>
  );
};

import React, { useEffect } from "react";

import { Button, Typography } from "@braintrust/braintrust-ui-components";
import { Modal } from "@js/components/modal";

import { AddStripeACHModalInstance } from "../../components/add-stripe-ach";
import { AddStripeCreditCardModalInstance } from "../../components/add-stripe-credit-card";

import style from "./style.module.scss";

const ADD_PAYMENT_MODAL_ID = "ADD_PAYMENT_METHOD_MODAL";

export const AddPaymentModalInstance = Modal(ADD_PAYMENT_MODAL_ID, {
  keepOnBackdropClick: true,
});

export const AddPaymentMethodModal = () => {
  useEffect(() => {
    return () => {
      AddStripeACHModalInstance.close();
      AddStripeCreditCardModalInstance.close();
    };
  }, []);

  return (
    <AddPaymentModalInstance>
      <Typography
        component="h1"
        variant="title"
        fontWeight={400}
        className={style.methodModal}
      >
        Add Payment Method
      </Typography>
      <Typography component="p" className={style.methodModal}>
        Please choose the type of payment method you would like to add:
      </Typography>
      <div className="buttons center">
        <Button
          variant="primary"
          onClick={() => {
            AddPaymentModalInstance.close();
            AddStripeCreditCardModalInstance.open();
          }}
        >
          Credit/Debit Card
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            AddPaymentModalInstance.close();
            AddStripeACHModalInstance.open();
          }}
        >
          ACH
        </Button>
      </div>
    </AddPaymentModalInstance>
  );
};

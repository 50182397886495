import { useLocation } from "react-router-dom";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import {
  clickJobTitleAfterSearch,
  viewJobClicked,
} from "@js/apps/jobs/actions";
import { useJobLocationContext } from "@js/apps/jobs/context";
import { getJobDetailsUrl } from "@js/apps/jobs/utils";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

import styles from "./styles.module.scss";

type JobItemHeaderProps = {
  title: string;
  id: number;
  resultPosition?: number;
  searchResults?: boolean;
};

export const JobItemHeader = ({
  title,
  id,
  resultPosition,
  searchResults,
}: JobItemHeaderProps) => {
  const dispatch = useAppDispatch();
  const location = useJobLocationContext();
  const { search } = useLocation();
  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.JOB_SEARCH_BOX,
  );

  return (
    <Box className={styles.wrapper}>
      <Typography
        className={styles.title}
        onContextMenuCapture={() => {
          dispatch(viewJobClicked({ source: location, search }));
        }}
        onMouseUp={(e) => {
          // why onMouseUp: Skip mouse clicks that don't open the link
          const { button } = e;
          const isLeftMouseButton = button === 0;
          const isMiddleMouseButton = button === 1;
          if (!isLeftMouseButton && !isMiddleMouseButton) {
            return;
          }

          dispatch(viewJobClicked({ source: location, search }));
          if (searchResults && resultPosition !== undefined)
            dispatch(
              clickJobTitleAfterSearch({
                jobId: id,
                location,
                resultPosition,
                searchQueryId,
              }),
            );
        }}
        component="link"
        target="_blank"
        to={getJobDetailsUrl({ id, search, location })}
        rel="noopener noreferrer"
        fontWeight={400}
        variant="title"
        size="small"
        RouterLink={RouterLink}
        noTextDecoration
      >
        <div className="capitalize-first-letter">{title}</div>
      </Typography>
    </Box>
  );
};

import { useMemo } from "react";
import type { DecoratedFormProps } from "redux-form";
import _ from "underscore";

import { Alert, Typography } from "@braintrust/braintrust-ui-components";
import {
  ModalConfirm,
  ModalInstance,
  openModalAndWaitForInput,
} from "@js/components/modal";
import {
  useAppDispatch,
  useAppSelector,
  useNavigate,
  useQueryParams,
} from "@js/hooks";

import { createFreelancerInvoice } from "../../actions";
import {
  useGetFreelancerInvoiceQuery,
  useLazyGetEmployerAddressQuery,
  useLazyGetInitialInvoiceDataQuery,
} from "../../api";
import type { InvoiceFormData } from "../../forms/add-invoice/types";
import { useJobSelectList } from "../job-select-list";

import {
  getAddInvoiceInitialValues,
  getInitialValuesForNewInvoice,
  isPONumberRequired,
} from "./helpers";
import { useInvoiceIssueDate } from "./hooks";

export const useAddInvoice = () => {
  const dispatch = useAppDispatch();
  const { copy: idOfInvoiceToCopy, job: jobIdParam } = useQueryParams();
  const navigate = useNavigate();

  const { data: jobSelectList, isLoading: isLoadingJobSelectList } =
    useJobSelectList();
  const { data: invoiceToCopy, isLoading: isLoadingInvoice } =
    useGetFreelancerInvoiceQuery(idOfInvoiceToCopy, {
      skip: !idOfInvoiceToCopy,
    });

  const [fetchEmployersAddress] = useLazyGetEmployerAddressQuery();

  const loading = isLoadingJobSelectList || isLoadingInvoice;

  const [fetchInitialInvoiceData, { data: initialInvoiceData }] =
    useLazyGetInitialInvoiceDataQuery();
  const issueDate = useInvoiceIssueDate();
  const withdrawalMethods = useAppSelector(
    (state) => state.withdrawal.withdrawalMethods,
  );

  const isStripeDefaultWithdrawalMethod =
    withdrawalMethods?.find((method) => method.is_default)?.content_type ===
    ENUMS.WithdrawalContentType.StripeWithdrawalMethod;

  const onSubmit = async (values: InvoiceFormData) => {
    if (isPONumberRequired(values, initialInvoiceData)) {
      await openModalAndWaitForInput({
        children: (
          <ModalConfirm confirmText="Create Invoice">
            <Typography component="h2" variant="title" size="small">
              PO # Required
            </Typography>
            <Typography component="p" maxWidth="550px">
              This client requires a PO # on each invoice. Please add the
              correct PO #. You may submit the invoice without a PO if one has
              not been assigned, but this could delay your payment. If you need
              help determining the PO for this job, please contact{" "}
              <a
                className="text-underline"
                href="mailto:accounting@usebraintrust.com"
              >
                accounting@usebraintrust.com
              </a>
              .
            </Typography>
          </ModalConfirm>
        ),
      });
    } else {
      await openModalAndWaitForInput({
        children: (
          <ModalConfirm confirmText="Create Invoice">
            Are you sure all invoice details are accurate?
            {isStripeDefaultWithdrawalMethod && (
              <Alert type="info" className="mt+">
                <Typography
                  component="p"
                  size="small"
                  color="blue"
                  fontWeight={500}
                >
                  Stripe charges a {SETTINGS.STRIPE_WITHDRAWAL_FEE}% fee for
                  payments. This equates to a fee of USD.
                </Typography>
              </Alert>
            )}
          </ModalConfirm>
        ),
      });
    }

    return dispatch(createFreelancerInvoice(values));
  };

  const onSubmitSuccess = () => {
    navigate("/talent/invoices/");
  };

  const onSubmitFail = (
    errors: Record<string, string>,
    _dispatch: unknown,
    submitError: Record<string, string>,
  ) => {
    if (submitError?.cancelledByUser) {
      return;
    }

    if (
      errors?.error_codes &&
      errors.error_codes.includes(
        ENUMS.InvoiceNumberError.DUPLICATED_NUMBER_ERROR,
      )
    ) {
      ModalInstance.open({
        children: (
          <ModalConfirm onConfirm={ModalInstance.close}>
            <div style={{ maxWidth: 505 }}>
              <Typography
                component="h2"
                variant="title"
                fontWeight={400}
                size="small"
                className="mt0"
              >
                Duplicate invoice Number
              </Typography>
              <Typography component="p">
                The client has already received this invoice number from another
                Braintrust related invoice. Please create a new invoice number.
              </Typography>
            </div>
          </ModalConfirm>
        ),
      });
    }
  };

  const suggestedInvoiceNumber = initialInvoiceData?.new_invoice_number;

  const initialValues = useMemo(() => {
    return getAddInvoiceInitialValues({
      suggestedInvoiceNumber,
      invoiceToCopy,
      idOfInvoiceToCopy,
      jobIdParam,
      jobSelectList,
      issueDate,
    });
  }, [
    suggestedInvoiceNumber,
    invoiceToCopy,
    idOfInvoiceToCopy,
    jobIdParam,
    jobSelectList,
    issueDate,
  ]);

  const onChange = (
    values: InvoiceFormData,
    _dispatch: unknown,
    props: DecoratedFormProps<InvoiceFormData, any, InvoiceFormData>,
    previousValues: InvoiceFormData,
  ) => {
    if (values.job === previousValues.job) {
      return;
    }

    const job = _.findWhere(jobSelectList, { id: values.job });

    if (job) {
      fetchEmployersAddress(job.employer.id);
      fetchInitialInvoiceData({ job: job.id })
        .unwrap()
        .then((data) => {
          const suggestedNumberInvoice = data?.new_invoice_number;

          if (props.pristine) {
            props.initialize?.(
              getAddInvoiceInitialValues({
                idOfInvoiceToCopy,
                invoiceToCopy,
                suggestedInvoiceNumber: suggestedNumberInvoice,
                jobIdParam,
                jobSelectList,
                issueDate,
              }),
              false,
            );
          } else {
            props.initialize?.(
              getInitialValuesForNewInvoice({
                ...previousValues,
                suggestedInvoiceNumber: suggestedNumberInvoice,
                jobIdParam: job.id,
                jobSelectList,
                issueDate,
              }),
              false,
            );
          }
        });
    }
  };

  const canChangeJob = !jobIdParam;
  const isCopy = !!idOfInvoiceToCopy;

  return {
    enableReinitialize: false,
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    onChange,
    initialValues,
    initialInvoiceData,
    loading,
    canChangeJob,
    isCopy,
  };
};

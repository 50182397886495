import React from "react";
import type { WrappedFieldArrayProps } from "redux-form";
import { Field } from "redux-form";

import { Box, FormHelperText } from "@braintrust/braintrust-ui-components";
import { TextField } from "@js/forms/fields";
import type { FreelancerExternalProfile } from "@js/types/common";

import styles from "./style.module.scss";

type ExternalProfilesFieldProps = WrappedFieldArrayProps;

export const ExternalProfilesField = ({
  fields,
  meta,
}: ExternalProfilesFieldProps) => {
  return (
    <div className={styles.bioFieldWrapper}>
      {fields.map((name, index) => {
        const profile: FreelancerExternalProfile = fields.get(index);

        return (
          <Field
            key={name}
            id={profile.name}
            placeholder={profile.placeholder}
            label={
              <div className={styles.externalProfilesLabel}>
                {profile.logo && (
                  <Label logo={profile.logo?.thumbnail} name={profile.name} />
                )}
              </div>
            }
            name={`${name}.public_url`}
            component={TextField}
          />
        );
      })}
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </div>
  );
};

type LabelProps = {
  logo: string;
  name: string;
};

const Label = ({ logo, name }: LabelProps) => {
  return (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <img src={logo} alt={name} width="25" height="25" />
      {name}
    </Box>
  );
};

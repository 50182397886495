import {
  Box,
  Button,
  Paper,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { ArrowRightIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useNavigate } from "@js/hooks";
import type { SpaceUser } from "@js/types/spaces";

import { AdminListItem } from "./admin-list-item";

import styles from "./style.module.scss";

type AdminListProps = {
  admins: SpaceUser["user"][];
  showProfileCardPopover?: boolean;
  spaceId?: number;
};

const MAX_ADMIN_AMOUNT_IN_PREVIEW = 4;

export const AdminList = ({
  admins,
  showProfileCardPopover,
  spaceId,
}: AdminListProps) => {
  const navigate = useNavigate();
  const shouldShowReadMoreButton =
    admins.length > MAX_ADMIN_AMOUNT_IN_PREVIEW && spaceId;
  const adminsToShow = shouldShowReadMoreButton
    ? admins.slice(0, MAX_ADMIN_AMOUNT_IN_PREVIEW)
    : admins;

  return (
    <Paper variant="outlined" className={styles.adminListContainer}>
      <Typography component="p" size="large">
        Admins
      </Typography>

      {adminsToShow.map((admin) => (
        <AdminListItem
          key={admin.id}
          admin={admin}
          showProfileCardPopover={showProfileCardPopover}
        />
      ))}

      {shouldShowReadMoreButton && (
        <>
          <Button
            className={styles.readMoreWrapper}
            onClick={() => navigate(`/spaces/${spaceId}/about/`)}
            variant="no-padding"
          >
            Read more <ArrowRightIcon />
          </Button>

          <Box className={styles.fade} />
        </>
      )}
    </Paper>
  );
};

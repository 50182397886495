import type { ReactNode } from "react";

import { Typography } from "@braintrust/braintrust-ui-components";

type FileUploadFieldLabelProps = {
  children?: ReactNode;
  id?: string;
};

export const FileUploadFieldLabel = ({
  children,
  id,
}: FileUploadFieldLabelProps) => {
  return (
    <Typography
      id={id}
      component="label"
      size="medium"
      fontWeight={500}
      sx={{ mb: 1, display: "inline-block" }}
    >
      {children}
    </Typography>
  );
};

import { useEffect, useMemo, useState } from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type {
  ComboBoxProps,
  TextFieldProps,
} from "@braintrust/braintrust-ui-components";
import { TextField } from "@braintrust/braintrust-ui-components";
import { ComboBox } from "@braintrust/braintrust-ui-components";
import {
  useGetBalanceHolderQuery,
  useGetBalanceHoldersSearchQuery,
} from "@js/apps/admin/api";
import type { BalanceHoldersSearchResult } from "@js/apps/admin/types";
import { useSearchPhrase } from "@js/components/autocomplete-new/hooks/search-phrase";
import { useSynchronizeSingleValueInternalStateWithFormValue } from "@js/components/autocomplete-new/hooks/synchronize-with-form-value";

type RhfBalanceHoldersComboboxFieldProps<
  T extends FieldValues,
  DisableClearable extends boolean,
> = UseControllerProps<T> &
  Partial<
    Omit<
      ComboBoxProps<
        BalanceHoldersSearchResult & { isInitialItem?: boolean },
        DisableClearable
      >,
      "component"
    >
  > & {
    label: string | undefined;
    variant?: TextFieldProps["variant"];
    id: string;
    control: Control<T>;
    helperText?: string;
  };

export const RhfBalanceHoldersComboboxField = <
  T extends FieldValues,
  DisableClearable extends boolean = false,
>({
  label,
  variant = "outlined",
  helperText,
  ...props
}: RhfBalanceHoldersComboboxFieldProps<T, DisableClearable>) => {
  const { field, fieldState, formState } = useController(props);
  const [value, setValue] = useState<BalanceHoldersSearchResult | null>(null);
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: balanceHolders } = useGetBalanceHoldersSearchQuery({
    search: searchPhrase,
  });

  const defaultValue = formState.defaultValues
    ? formState.defaultValues[props.name]
    : undefined;

  const skip = fieldState.isDirty || !defaultValue || !!value; // "dirty" is cleared with every search so we need to rely on "value" too
  const { data: initialItem, isLoading: initialTaxonomyLoading } =
    useGetBalanceHolderQuery(Number(defaultValue), {
      skip,
    });

  const options = useMemo(() => {
    // include initialItem in options to avoid MUI warning related to isOptionEqualToValue
    if (initialItem) {
      return [
        ...(balanceHolders || []),
        { ...initialItem, isInitialItem: true },
      ];
    }

    if (!skip) {
      return [
        ...(balanceHolders || []),
        { ...{ name: defaultValue, id: defaultValue }, isInitialItem: true },
      ];
    }

    return balanceHolders || [];
  }, [balanceHolders, defaultValue, skip, initialItem]);

  useSynchronizeSingleValueInternalStateWithFormValue({
    initialTaxonomyLoading: false,
    formValue: field.value,
    isValueEqualFormValue: field.value === value?.id,
    findOption: (option) => {
      return option.id === field.value;
    },
    setValue,
    options,
  });

  useEffect(() => {
    if (initialItem) {
      setValue(initialItem);
    }
  }, [initialItem]);

  return (
    <ComboBox<
      BalanceHoldersSearchResult & { isInitialItem?: boolean },
      DisableClearable
    >
      initialTaxonomiesLoading={initialTaxonomyLoading}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            variant={variant}
            {...params}
            label={label || "User"}
            error={fieldState.invalid}
            helperText={fieldState.error?.message || helperText}
          />
        );
      }}
      // @ts-expect-error fallback for initial state
      value={value || null}
      onInputChange={onInputChange}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        field.onChange(valueArg?.id || undefined);
      }}
      filterOptions={(optionsArg) => {
        return optionsArg.filter((opt) => !opt.isInitialItem);
      }}
      onBlur={field.onBlur}
      disabled={field.disabled}
      getOptionLabel={(option) => option.name}
      {...props}
    />
  );
};

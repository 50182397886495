import { Box, Stack } from "@braintrust/braintrust-ui-components";

import { FILE_UPLOAD_STATE_STATUS } from "../../constants";
import { renameAttachment } from "../../helpers/file-upload";
import type { AttachmentBase, FilePreviewData, UploadType } from "../../types";
import { AttachmentRenameInput } from "../attachment-rename-input";

export type FilesPreviewListProps = {
  filesPreviewData: FilePreviewData[];
  onFileDelete?: (fileData: FilePreviewData) => void;
  onFileRename?: (renamedFileData: {
    attachment: AttachmentBase;
    id: number;
  }) => void;
} & (
  | { disableRename: true; uploadType?: UploadType }
  | { disableRename?: undefined; uploadType: UploadType }
);

export const FilesPreviewList = ({
  filesPreviewData,
  onFileDelete,
  onFileRename,
  disableRename,
  uploadType,
}: FilesPreviewListProps) => {
  const handleFileRename = async (
    fileData: FilePreviewData,
    newName: string,
  ) => {
    if (disableRename) {
      return;
    }
    const fileAttachmentId = fileData.file?.attachment.id;
    if (!fileAttachmentId) {
      return;
    }

    return renameAttachment({
      uuid: fileAttachmentId,
      uploadType,
      name: newName,
    }).then((response) => {
      onFileRename?.(response);
    });
  };

  return (
    <Stack
      component="ul"
      sx={{ gap: 2, p: 0, m: 0, listStyle: "none" }}
      aria-label="Files preview"
    >
      {filesPreviewData.map((filePreviewData) => {
        const canRenameFile =
          filePreviewData.file?.attachment.id && !disableRename;
        return (
          <Box key={filePreviewData.fileId} component="li">
            <AttachmentRenameInput
              key={filePreviewData.fileId}
              value={filePreviewData.name}
              loading={
                filePreviewData.status === FILE_UPLOAD_STATE_STATUS.UPLOADING
              }
              onRename={
                canRenameFile
                  ? (newName: string) =>
                      handleFileRename(filePreviewData, newName)
                  : undefined
              }
              onDelete={() => onFileDelete?.(filePreviewData)}
            />
          </Box>
        );
      })}
    </Stack>
  );
};

import React from "react";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import { Typography } from "@braintrust/braintrust-ui-components";

type JobSectionTitleProps = {
  children: React.ReactNode | string;
} & Omit<TypographyProps, "component" | "variant" | "fontWeight" | "size">;

export const JobSectionTitle = ({
  children,
  ...props
}: JobSectionTitleProps) => (
  <Typography
    {...props}
    component="h2"
    variant="paragraph"
    fontWeight={500}
    size="medium"
  >
    {children}
  </Typography>
);

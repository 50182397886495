import { useState } from "react";

import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useAppDispatch } from "@js/hooks";

import { fetchWithdrawalMethods } from "../actions";

export const useFetchWithdrawalMethods = (options?: { skip?: boolean }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    if (!options?.skip) {
      dispatch(fetchWithdrawalMethods()).then(() => {
        setFetched(true);
      });
    }
  });

  return { fetched };
};

import {
  Button,
  Divider,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { SelectedMembersList } from "@js/apps/spaces/components/invite-to-space-modal/selected-members-list";
import { useInviteToSpaceModal } from "@js/apps/spaces/components/invite-to-space-modal/use-invite-to-space-modal";
import { SpaceInviteMemberAutocomplete } from "@js/apps/spaces/components/space-invite-member-autocomplete";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

type OpenSpaceInviteContactsModalProps = {
  spaceId: number;
};

type InviteToSpaceModalProps = OpenSpaceInviteContactsModalProps;

export const openSpaceInviteContactsModal = ({
  spaceId,
}: OpenSpaceInviteContactsModalProps) => {
  return ModalInstance.open({
    children: <InviteToSpaceModal spaceId={spaceId} />,
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    className: styles.inviteToSpaceModal,
    containerScrollableNoMobilePadding: true,
    padding: false,
  });
};

export const InviteToSpaceModal = ({ spaceId }: InviteToSpaceModalProps) => {
  const isMobile = useMediaQuery("sm");
  const {
    handleAddMember,
    removeMember,
    handleInvite,
    isInviteDisabled,
    errorMessage,
    selectedMembers,
  } = useInviteToSpaceModal({ spaceId });

  return (
    <div className={styles.inviteToSpaceModalContent}>
      <Typography
        component="h1"
        variant={isMobile ? "paragraph" : "title"}
        size={isMobile ? "large" : "small"}
        fontWeight={400}
        sx={{ mb: { xs: 2, sm: 3 } }}
      >
        Invite people to this space
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        size={isMobile ? "medium" : "large"}
        sx={{ mb: { xs: 2, sm: 3 } }}
      >
        Search and select Braintrust members by name. You can invite friends
        without a Braintrust account by entering their email.
      </Typography>
      <SpaceInviteMemberAutocomplete
        spaceId={spaceId}
        value={selectedMembers}
        onChange={(newValue) => {
          if (newValue) {
            // filter out same members when user pass the same email multiple times
            const filteredNewValue = [
              ...new Map(
                newValue.map((item) => [
                  item["inputValue"] || item["id"],
                  item,
                ]),
              ).values(),
            ];

            handleAddMember(filteredNewValue);
          }
        }}
      />
      <SelectedMembersList members={selectedMembers} onRemove={removeMember} />
      <Divider color="secondary" />
      <div className={styles.modalFooter}>
        {errorMessage && (
          <Typography component="p" error>
            {errorMessage}
          </Typography>
        )}

        <Button
          variant="positive"
          onClick={handleInvite}
          disabled={isInviteDisabled}
          shape="squared"
        >
          Invite
        </Button>
      </div>
    </div>
  );
};

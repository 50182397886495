import React from "react";

import { Button, IconButton } from "@braintrust/braintrust-ui-components";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useSignUpBanner } from "@js/components/signup-banner/use-sign-up-banner";

import styles from "./styles.module.scss";

type SignupBannerProps = {
  onSignUpClick?: () => void;
  onCloseClick?: () => void;
  children: React.ReactNode;
  className?: string;
};

export const SignupBanner = ({
  onSignUpClick,
  onCloseClick,
  children,
  className,
}: SignupBannerProps) => {
  const { handleSignUpClick, isSignupBannerHidden, handleCloseBtnClick } =
    useSignUpBanner({ onCloseClick, onSignUpClick });

  if (isSignupBannerHidden) {
    return null;
  }

  return (
    <aside className={`${styles.signupBanner} ${className}`}>
      <div className={styles.signupBannerCopy}>
        {children}
        <Button
          variant="transparent"
          className={styles.signupBannerCta}
          endIcon={<ArrowRightIcon />}
          onClick={handleSignUpClick}
          size="x-small"
        >
          Sign up
        </Button>
      </div>
      <IconButton
        variant="transparent"
        size="small"
        title="close button"
        aria-label="close banner"
        onClick={handleCloseBtnClick}
      >
        <CloseIcon />
      </IconButton>
    </aside>
  );
};

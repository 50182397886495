import type { HTMLAttributes, ReactNode } from "react";
import cs from "classnames";

import {
  Box,
  InputAdornment,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { PersonAddIcon } from "@braintrust/braintrust-ui-components/Icons";
import { InvitationsAutocomplete } from "@js/components/autocomplete-new/invitations";

import { useTeamMemberInvitations } from "../../hooks/use-team-member-invitations";

import { TeamMemberInvitationsInvitationItem } from "./team-member-invitations-invitation-item";

import styles from "./styles.module.scss";

export type TeamMemberInvitationsAutocompleteProps = {
  toggleInvitation: (email: string) => void;
  selectedInvitations: string[];
  invitationActionButton: ReactNode;
  errorMessage: string | undefined;
};

// This autocomplete only allows selections from options generated based on the input value
const emptyOptions: string[] = [];

export const TeamMemberInvitationsAutocomplete = ({
  toggleInvitation,
  selectedInvitations,
  invitationActionButton,
  errorMessage,
}: TeamMemberInvitationsAutocompleteProps) => {
  const { isOpen, inputValue, setInputValue, handleClose, handleOpen } =
    useTeamMemberInvitations();

  const handleChange = (values: string[]) => {
    const selectedOption = values[values.length - 1];
    if (!selectedOption) {
      return;
    }

    toggleInvitation(selectedOption);
  };

  const renderTeamMemberOption = (
    renderOptionProps: HTMLAttributes<HTMLLIElement>,
    option: string,
  ) => {
    const isInvited = selectedInvitations.some((emailInvitation) => {
      return emailInvitation === option;
    });

    return (
      <li
        {...renderOptionProps}
        key={option}
        className={cs(styles.option, renderOptionProps.className, {
          [styles.invitedOption]: isInvited,
        })}
        aria-disabled={isInvited}
      >
        <TeamMemberInvitationsInvitationItem
          option={option}
          isInvited={isInvited}
          invitationActionButton={invitationActionButton}
        />
      </li>
    );
  };

  const filterTeamMemberOptions = (
    options: string[],
    params: { inputValue: string },
  ) => {
    const trimmedInput = params.inputValue.trim();
    const optionsCopy = [...options];

    if (trimmedInput !== "") {
      optionsCopy.push(trimmedInput);
    }

    return optionsCopy;
  };

  return (
    <Box>
      <InvitationsAutocomplete
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        multiple
        options={emptyOptions}
        className={
          !inputValue ? styles.autocompletePlaceholderPadding : undefined
        }
        inputValue={inputValue}
        onInputChange={(_event, input) => {
          setInputValue(input);
        }}
        onChange={(_event, newValue, reason) => {
          if (!newValue) {
            return;
          }

          if (reason === "clear" || reason === "removeOption") {
            setInputValue("");
            return;
          }

          handleChange(newValue);
        }}
        noOptionsText={<></>}
        placeholder="Add people by email..."
        renderOption={renderTeamMemberOption}
        filterOptions={filterTeamMemberOptions}
        getOptionLabel={(option) => option}
        inputProps={{
          startAdornment: !inputValue ? (
            <InputAdornment position="end" sx={{ ml: 3 }}>
              <PersonAddIcon className={styles.personAddIcon} />
            </InputAdornment>
          ) : null,
        }}
      />
      {errorMessage && (
        <Typography
          component="p"
          variant="paragraph"
          size="medium"
          error
          sx={{ mt: 2 }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

import { Typography } from "@braintrust/braintrust-ui-components";
import type { BYOTError } from "@js/apps/byot/types";
import { ErrorMessage } from "@js/components/error-section";
import { handleDownloadFile } from "@js/utils";

type ErrorProps = {
  error: BYOTError;
};

export const Errors = ({ error }: ErrorProps) => {
  if (!error) {
    return null;
  }

  if (error.type === "upload") {
    if (error.message === "too_many_rows") {
      return (
        <ErrorMessage message="File exceeds the 500 entry upload limit">
          Please split the CSV into smaller files and try again.
        </ErrorMessage>
      );
    }

    return (
      <ErrorMessage message="File not uploaded correctly">
        {error.message}
      </ErrorMessage>
    );
  }

  if (error.type === "processing") {
    return (
      <ErrorMessage message="The data in your file was not processed">
        Download the CSV file for specific error details. Fix all the errors and
        try again.{" "}
        <Typography
          component="span"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            handleDownloadFile({
              endpoint: error.csvWithErrorsUrl,
              params: { download_csv_with_errors: true },
            });
          }}
        >
          Download CSV
        </Typography>
      </ErrorMessage>
    );
  }

  // shouldn't happen
  return (
    <ErrorMessage message="File not uploaded correctly">
      Somethine went wrong, please try to upload file again.
    </ErrorMessage>
  );
};

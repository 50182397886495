import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { Emoji } from "@js/components/emoji";
import { RouterLink } from "@js/components/link";
import type { FreelancerPublic } from "@js/types/freelancer";
import type { TalentReview } from "@js/types/reviews";
import { assertUnreachable } from "@js/utils";

type ReviewCardReviewTypeProps = {
  review: TalentReview;
  profile: FreelancerPublic;
};

export const ReviewCardReviewType = ({
  review,
  profile,
}: ReviewCardReviewTypeProps) => {
  const { reviewer, review_type } = review;
  let headline: string | JSX.Element = "";
  switch (review_type) {
    case ENUMS.ReviewType.WORK_REVIEW:
      headline = `${reviewer.first_name} hired ${profile.user.first_name} at `;
      break;
    case ENUMS.ReviewType.COWORKER_REVIEW:
      headline = `${reviewer.first_name} worked with ${profile.user.first_name} at `;
      break;
    case ENUMS.ReviewType.HELP_OFFER_REVIEW:
      headline = (
        <Box display="flex" alignItems="center">
          <Typography component="p" size="small">
            {profile.user.first_name} helped {reviewer.first_name} with&nbsp;
          </Typography>
          <Emoji emoji={review.emoji} />
          <Typography component="span" size="small" fontWeight={500} ml={0.5}>
            {review.category}
          </Typography>
        </Box>
      );
      break;
    case ENUMS.ReviewType.EMPLOYMENT_REVIEW:
      return null;
    default:
      assertUnreachable(review_type);
  }

  return (
    <Typography
      component={
        review_type === ENUMS.ReviewType.HELP_OFFER_REVIEW ? "div" : "p"
      }
      color="grey-1"
      size="small"
    >
      {headline}
      {(review_type === ENUMS.ReviewType.WORK_REVIEW ||
        review_type === ENUMS.ReviewType.COWORKER_REVIEW) && (
        <Typography
          sx={{ wordBreak: "break-word" }}
          component="span"
          size="small"
          target="_blank"
          to={`/employers/${review.employer.id}`}
          RouterLink={RouterLink}
        >
          {review.employer.name}
        </Typography>
      )}
    </Typography>
  );
};

import { Box } from "@braintrust/braintrust-ui-components";
import { BookmarkJobButton } from "@js/apps/bookmarks/components";
import { useAccountType, useIsNodeStaff, useUser } from "@js/apps/common/hooks";
import { JobActionMenu } from "@js/apps/jobs/components/job-action-menu";
import { ShareJobButton } from "@js/apps/jobs/components/share-job-button";

import type { JobItemProps } from "../../job-item";

import styles from "./styles.module.scss";

type JobItemActionsPropsTypes = Pick<JobItemProps, "job">;

export const JobItemActions = ({ job }: JobItemActionsPropsTypes) => {
  const isNodeStaff = useIsNodeStaff();
  const { isFreelancer } = useAccountType();
  const user = useUser();

  return (
    <Box className={styles.wrapper}>
      {user && <ShareJobButton job={job} sx={{ mr: 0.5 }} />}
      {isFreelancer && <BookmarkJobButton job={job} />}
      {isNodeStaff && "job_actions" in job && (
        <JobActionMenu job={job} iconColor="var(--black)" />
      )}
    </Box>
  );
};

import React from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import type { EmployerStatsSummaryType } from "@js/types/dashboard";

import {
  ReferralEmptyState,
  ReferralsStatsContainer,
  ReferralsStatsListContainer,
  ReferralsStatsSummaryContainer,
  ReferralsStatsSummaryMainSection,
} from "../referral-stats";

import {
  EmployerReferralsList,
  type EmployerReferralsListProps,
} from "./employer-referrals-list";
import { EmployerReferralsStatsSummary } from "./employer-referrals-stats-summary";

type EmployerReferralsProps = {
  summary: EmployerStatsSummaryType;
} & EmployerReferralsListProps;

export const EmployerReferrals = ({
  summary,
  referrals,
  fetchMore,
  loading,
  filters,
}: EmployerReferralsProps) => {
  const showSummaryEmptyState = !summary.referred_count;
  const showReferralsList = !!summary.referred_count;

  return (
    <ReferralsStatsContainer color="violet">
      <ReferralsStatsSummaryContainer>
        <ReferralsStatsSummaryMainSection
          title="Your Client referrals"
          totalBalance={String(summary.total_earned)}
          tooltipText="Total BTRST you have earned from referring clients."
        />
        <Box
          sx={{
            mr: !showSummaryEmptyState ? "20px" : undefined,
          }}
        >
          <EmployerReferralsStatsSummary summary={summary} />
        </Box>

        {showSummaryEmptyState && (
          <ReferralEmptyState
            bgColor="var(--medium-violet)"
            title="You haven’t referred any Clients, yet."
            description="Grab your referral link above and start inviting Clients to Braintrust. All the BTRST you earn from your Client referral activities will be tracked here. "
          />
        )}
      </ReferralsStatsSummaryContainer>
      {showReferralsList && (
        <ReferralsStatsListContainer dividerColor="var(--medium-violet)">
          <EmployerReferralsList
            fetchMore={fetchMore}
            loading={loading}
            referrals={referrals}
            filters={filters}
          />
        </ReferralsStatsListContainer>
      )}
    </ReferralsStatsContainer>
  );
};

import React from "react";

import { Avatar, Box, Typography } from "@braintrust/braintrust-ui-components";
import { InvitedByUserToJob } from "@js/apps/onboarding/components/invitation/invited-by-user-to-job";
import { Logo } from "@js/components/logo";
import type {
  PublicInvitationDetails,
  PublicInvitationToJob,
} from "@js/types/auth";

import style from "./style.module.scss";

type InvitedByUserProps = {
  publicInvitationDetails: PublicInvitationDetails;
};

export const InvitedByUser: React.FC<InvitedByUserProps> = ({
  publicInvitationDetails,
}) => {
  return (
    <>
      <Logo variant="full-black" size={192} className={style.logo} />

      <Box display="flex" alignItems="center">
        <Avatar
          sx={{ height: 28, width: 28, bgcolor: "none !important" }}
          alt={publicInvitationDetails.first_name}
          textSize="small"
          color={
            publicInvitationDetails.account_type === "freelancer"
              ? "dark-blue"
              : "medium-violet"
          }
          src={publicInvitationDetails}
        />
        <Typography component="span" variant="label" className={style.badge}>
          {publicInvitationDetails.first_name} invited you to join!
        </Typography>
      </Box>

      {isInvitationToOpenedJob(publicInvitationDetails) && (
        <InvitedByUserToJob publicInvitationDetails={publicInvitationDetails} />
      )}
    </>
  );
};

export const isInvitationToOpenedJob = (
  invitation: PublicInvitationDetails,
): invitation is PublicInvitationToJob => {
  return (
    !!(invitation as PublicInvitationToJob).job &&
    (invitation as PublicInvitationToJob).job.is_open
  );
};

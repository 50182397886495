import { useState } from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

import { useRetryInterviewMutation } from "../../api";

type OpenSendRetryConfirmationModalProps = {
  bidId: number;
};

const SendRetryConfirmationModalContent = ({
  bidId,
}: OpenSendRetryConfirmationModalProps) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [retryInterview] = useRetryInterviewMutation();

  const handleConfirm = () => {
    if (isConfirming) return;

    setIsConfirming(true);

    retryInterview({ bidId })
      .unwrap()
      .then(() => {
        Snackbar.success("AI Interview Sent");
      })
      .catch((error) => {
        Snackbar.error(error.data._error);
      })
      .finally(() => {
        setIsConfirming(false);
        ModalInstance.close();
      });
  };

  return (
    <ModalConfirm
      confirmText={"Confirm"}
      confirmDisabled={isConfirming}
      onConfirm={handleConfirm}
      onCancel={() => ModalInstance.close()}
    >
      <Typography component="h1" variant="title" fontWeight={400} size="small">
        Send AI Interview Retry
      </Typography>
      <Box sx={{ pr: 6, mt: 1 }}>
        This will delete the applicant’s current interview scorecard and send
        them a new AI interview request. Are you sure you want to proceed?
      </Box>
    </ModalConfirm>
  );
};

export const openSendRetryConfirmationModal = (
  props: OpenSendRetryConfirmationModalProps,
) => {
  ModalInstance.open({
    className: "text-content-mw",
    children: <SendRetryConfirmationModalContent {...props} />,
  });
};

import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { MenuSelectProps } from "@braintrust/braintrust-ui-components";
import { MenuSelect } from "@braintrust/braintrust-ui-components";

type RhfMenuSelectProps<T extends FieldValues> = Omit<
  MenuSelectProps,
  "onChange" | "value"
> &
  UseControllerProps<T> & {
    control: Control<T>;
  };
export const RhfMenuSelect = <T extends FieldValues>({
  ...props
}: RhfMenuSelectProps<T>) => {
  const { field } = useController(props);

  return <MenuSelect {...props} {...field} ref={undefined} />;
};

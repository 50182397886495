import type { TypedWrappedFieldProps } from "redux-form";

import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { shouldDisplayError } from "@js/forms/utils";

import { useGetPopularSkillsOptions } from "./use-get-popular-skills-options";

type UseCreateJobSkillsLogicArgs = {
  newSkillsFormField: TypedWrappedFieldProps<number[]>;
  topSkillsFormField: TypedWrappedFieldProps<number[]>;
  roleId: number | undefined;
};

export const useCreateJobSkillsLogic = ({
  newSkillsFormField,
  topSkillsFormField,
  roleId,
}: UseCreateJobSkillsLogicArgs) => {
  const isMobile = useMediaQuery("sm");
  const displayError = shouldDisplayError([
    newSkillsFormField,
    topSkillsFormField,
  ]);

  const newMeta = {
    ...newSkillsFormField.meta,
    error: null,
  };

  const errorMessage =
    newSkillsFormField.meta.error || topSkillsFormField.meta.error;
  const selectedSkills = newSkillsFormField.input.value;

  const { popularSkillsOptions } = useGetPopularSkillsOptions({
    role: roleId,
    selectedSkills: selectedSkills,
    skillsLimit: isMobile ? 5 : 13,
  });

  return {
    displayError,
    newMeta,
    error: errorMessage,
    popularSkillsOptions,
  };
};

type UseEditFreelancerProfileSkillsLogicArgs = {
  newSkillsFormField: TypedWrappedFieldProps<number[]>;
  roleId: number;
};

export const useEditFreelancerProfileSkillsLogic = ({
  newSkillsFormField,
  roleId,
}: UseEditFreelancerProfileSkillsLogicArgs) => {
  const isMobile = useMediaQuery("sm");
  const displayError = shouldDisplayError([newSkillsFormField]);

  const newMeta = {
    ...newSkillsFormField.meta,
    error: null,
  };

  const fieldValues = newSkillsFormField.input.value;

  const { popularSkillsOptions } = useGetPopularSkillsOptions({
    role: roleId,
    selectedSkills: fieldValues,
    skillsLimit: isMobile ? 5 : 13,
  });

  return {
    displayError,
    newMeta,
    // we are already displaying error message at the top of the skills card
    // no need to duplicate this message
    error: undefined,
    popularSkillsOptions,
  };
};

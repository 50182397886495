import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { TextFieldProps } from "@braintrust/braintrust-ui-components";
import { TextField } from "@braintrust/braintrust-ui-components";

export type RhfTextFieldProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    id: TextFieldProps["id"];
    control: Control<T>;
  };

export const RhfTextField = <T extends FieldValues>({
  id,
  helperText,
  ...props
}: RhfTextFieldProps<T>) => {
  const { field, fieldState } = useController(props);

  return (
    <TextField
      id={id}
      {...props}
      {...field}
      value={field.value || ""}
      ref={undefined}
      error={fieldState.invalid}
      helperText={fieldState.error?.message || helperText}
    />
  );
};

import SpacesHeaderBg from "@static/spaces/spaces-header-bg.svg";
import cs from "classnames";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";

import { Avatar } from "../avatar";

import { ActionMenu } from "./action-menu";
import { CTABtn } from "./cta-btn";
import { DetailsSection } from "./details-section";

import styles from "./styles.module.scss";

type SpaceHeaderProps = {
  title: string;
  id: number;
  avatarSource: string;
  membersCount: number;
  isMember?: boolean;
  isAdmin?: boolean;
  isPrivate?: boolean;
  className?: string;
  areNotificationsTurnOn?: boolean;
  isSpaceMemberFetching?: boolean;
};

export const SpaceHeader = ({
  title,
  avatarSource,
  membersCount,
  isMember,
  isAdmin,
  isPrivate,
  className,
  id,
  areNotificationsTurnOn,
  isSpaceMemberFetching,
}: SpaceHeaderProps) => {
  const isMobile = useMediaQuery(650);

  return (
    <Box className={cs(styles.wrapper, className)}>
      <SpacesHeaderBg className={styles.watermark} />
      <div className={styles.container}>
        <div className={styles.containerRight}>
          <div>
            <CTABtn
              isMember={isMember}
              spaceId={id}
              isSpaceMemberFetching={isSpaceMemberFetching}
            />
          </div>
          {isMember && (
            <ActionMenu
              areNotificationsTurnOn={areNotificationsTurnOn}
              isAdmin={isAdmin}
            />
          )}
        </div>
        <div className={styles.containerLeft}>
          <Avatar src={avatarSource} size={isMobile ? "medium" : "large"} />
          <div className={styles.containerDetailsWrapper}>
            <Typography
              component="h2"
              variant="title"
              size={isMobile ? "small" : "large"}
              fontWeight={400}
              className={styles.containerTitle}
              ellipsis
            >
              {title}
            </Typography>
            <DetailsSection isPrivate={isPrivate} membersCount={membersCount} />
          </div>
        </div>
      </div>
    </Box>
  );
};

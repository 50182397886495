import { Button } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { KeyboardArrowRightIcon } from "@braintrust/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

type BackButtonProps = {
  onClick?: () => void;
};

export const BackButton = ({ onClick }: BackButtonProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Button
      variant="secondary"
      fontWeight={500}
      endIcon={<KeyboardArrowRightIcon />}
      size={isMobile ? "x-small" : "small"}
      onClick={onClick}
      RouterLink={RouterLink}
      to="/feed/"
    >
      Go to Career Help
    </Button>
  );
};

import { Box, Loader, Typography } from "@braintrust/braintrust-ui-components";
import { Modal } from "@js/components/modal";

import styles from "./style.module.scss";

export const UploadLoadingModalInstance = Modal("upload-loading-modal", {
  className: styles.modal,
  closeButton: false,
  keepOnBackdropClick: true,
});

type UploadingModalProps = {
  uploadingFileName: string;
};

export const UploadLoadingModal = ({
  uploadingFileName,
}: UploadingModalProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          background: "#F7FCEC80",
          display: "flex",
          padding: "10px",
          borderRadius: "50%",
        }}
      >
        <Loader size={70} color="inherit" className={styles.loader} />
      </Box>
      <Typography component="span" mt={2} fontWeight={500} size="large">
        Uploading...
      </Typography>
      <Typography component="span" fontWeight={400} size="small">
        {uploadingFileName}
      </Typography>
    </Box>
  );
};

export const openUploadLoadingModal = ({ uploadingFileName = "" }) =>
  UploadLoadingModalInstance.open({
    onClose: () => UploadLoadingModalInstance.close(),
    children: <UploadLoadingModal uploadingFileName={uploadingFileName} />,
  });

import React, { useMemo } from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { clickPostHashtag } from "@js/apps/give-and-get-help/actions";
import {
  HASHTAG_QUERY_PARAM,
  POST_HASHTAG_QUERY_PARAM,
} from "@js/apps/give-and-get-help/constants";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context";
import type { PostHashtag } from "@js/apps/give-and-get-help/types";
import { RouterLink } from "@js/components/link";
import { getHashtagRawName } from "@js/components/rich-text-editor/custom-plugins/hashtag-plugin/utils/get-hashtag-raw-name";
import { useAppDispatch } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

import { parseTextWithHashtags } from "./parse-text-with-hashtags";

import styles from "./styles.module.scss";

type TextWithHashtags = {
  text: string;
  postData: IPost;
};

export const PostTextWithHashtagLinks = ({
  text,
  postData,
}: TextWithHashtags) => {
  const parsedTextArray = useMemo<
    Array<{ text: string; hashtag?: PostHashtag }>
  >(() => {
    return parseTextWithHashtags({
      text,
      postHashtags: postData.post_hashtags,
    });
  }, [postData, text]);

  if (!postData.post_hashtags?.length) {
    // eslint-disable-next-line react/no-danger
    return <p dangerouslySetInnerHTML={{ __html: text }} />;
  }

  return (
    <p>
      {parsedTextArray.map((textFragment, index) => {
        // the text is not unique - it is fine to use the index, as the rendered elements are stateless
        const key = index;
        if (!textFragment.hashtag) {
          return (
            <span
              key={key}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: textFragment.text }}
            />
          );
        }

        const { hashtag } = textFragment;

        return <HashtagLink key={key} hashtag={hashtag} postData={postData} />;
      })}
    </p>
  );
};

const HashtagLink = ({
  hashtag,
  postData,
}: {
  hashtag: PostHashtag;
  postData?: IPost;
}) => {
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();

  const spaceId = postData?.space;
  const redirectLink = spaceId
    ? `/spaces/${spaceId}/discussion?${HASHTAG_QUERY_PARAM}=${hashtag.hashtag_id}`
    : `/feed/?${HASHTAG_QUERY_PARAM}=${hashtag.hashtag_id}&${POST_HASHTAG_QUERY_PARAM}=${hashtag.id}`;

  const handlePostHashtagClick = () => {
    if (!postData) {
      return;
    }
    const { id: postHashtagId, hashtag_id, hashtag_name } = hashtag;
    const postHashtag = {
      id: postHashtagId,
      hashtag_id,
      hashtag_name,
    };

    dispatch(
      clickPostHashtag({
        hashtag_id: postHashtag.hashtag_id,
        post_id: postData.id,
        post_level: postData.level,
        slot_position: postData.slot_position,
        post_location: postLocation,
      }),
    );
  };

  return (
    <Typography
      className={styles.postHashtag}
      component="link"
      to={redirectLink}
      RouterLink={RouterLink}
      size="large"
      variant="link"
      color="blue"
      onClick={handlePostHashtagClick}
    >
      #{getHashtagRawName(hashtag.hashtag_name)}
    </Typography>
  );
};

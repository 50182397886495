import * as React from "react";

import type { AvatarGroupProps } from "@braintrust/braintrust-ui-components";
import {
  Avatar,
  AvatarGroup,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { User } from "@js/types/auth";
import { pluralize } from "@js/utils";

import style from "./style.module.scss";

type AvatarUserData = Pick<
  User,
  "id" | "has_avatar_set" | "avatar_thumbnail" | "gravatar"
> & { first_name?: User["first_name"] };

type UsersThumbnailAvatarsProps = {
  users: AvatarUserData[];
  maxGroupImages?: number;
  avatarsSpacing?: number;
} & AvatarGroupProps &
  MembersLinkOrTextProps;

export const SimplyUsersThumbnailAvatars: React.FC<
  UsersThumbnailAvatarsProps
> = ({ users, usersAmount, membersSrc, ...props }) => {
  // MUI AvatarGroup counts in surplus element which we want to hide and shows exact number of avatars
  const maxVisibleAvatars = props.maxGroupImages
    ? props.maxGroupImages + 1
    : props.maxGroupImages;

  return (
    <div className={style.simplyUserThumbnailAvatars}>
      <div className={style.simplyUserGroupAndThumbnailWrapper}>
        <AvatarGroup
          max={maxVisibleAvatars}
          sx={{
            "& > div:first-of-type": {
              display:
                props.maxGroupImages && users.length > props.maxGroupImages
                  ? "none"
                  : "flex",
            },
          }}
        >
          {users.map((user, idx) => {
            return (
              <Avatar
                key={user.id}
                className={style.simplyUsersThumbnailsAvatarsGroupAvatar}
                sx={{ marginLeft: "-8px !important", zIndex: idx }}
                alt={user.first_name}
                src={getAvatarSrc(user)}
              />
            );
          })}
        </AvatarGroup>
        <MembersLinkOrText
          onClick={props.onClick}
          usersAmount={usersAmount}
          membersSrc={membersSrc}
        />
      </div>
    </div>
  );
};

type MembersLinkOrTextProps = {
  membersSrc?: string;
  usersAmount?: number;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
};
const MembersLinkOrText = ({
  membersSrc,
  usersAmount,
  onClick,
}: MembersLinkOrTextProps) => {
  if (!usersAmount) {
    return <></>;
  }

  const text = `${usersAmount} member${pluralize(usersAmount)}`;

  if (!membersSrc) {
    return (
      <Typography
        component="span"
        className={style.simplyUsersCountBadge}
        onClick={onClick}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Typography
      component="a"
      to={membersSrc}
      RouterLink={RouterLink}
      className={style.simplyUsersCountBadge}
      onClick={onClick}
    >
      {text}
    </Typography>
  );
};

const getAvatarSrc = (user: AvatarUserData) => {
  return { first_name: "Freelancer", ...user };
};

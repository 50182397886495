import { Button, Typography } from "@braintrust/braintrust-ui-components";
import {
  useCompleteHelpOffer,
  useRequestHelpOfferRevision,
} from "@js/apps/common/hooks/use-help-offer";
import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";

import { HelpOfferButtonPrimary } from "./help-offer-button-primary";

import styles from "../styles.module.scss";

export const HelpOfferButtonsApprovalRequested = ({
  offer,
  isOfferAuthor,
  isMobile,
  type,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const { handleCompleteOffer, isAccepting } = useCompleteHelpOffer({ offer });
  const { handleRequestRevision, isRequestingRevision } =
    useRequestHelpOfferRevision({ offer });

  const disableButtons = isRequestingRevision || isAccepting;
  const isDashboardOffer = type === "dashboard";

  if (isOfferAuthor)
    return !isDashboardOffer ? (
      <Typography component="span" className={styles.helpOfferStatus}>
        Final review requested
      </Typography>
    ) : null;

  return (
    <>
      {(!isMobile || isDashboardOffer) && (
        <Button
          variant="transparent-border-beige"
          shape="squared"
          className="help-offer-action-buttons__secondary"
          onClick={handleRequestRevision}
          disabled={disableButtons}
        >
          Ask for a revision
        </Button>
      )}
      <HelpOfferButtonPrimary
        onClick={handleCompleteOffer}
        disabled={disableButtons}
      >
        Mark complete
      </HelpOfferButtonPrimary>
    </>
  );
};

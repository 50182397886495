import { Typography } from "@braintrust/braintrust-ui-components";
import type { FreelancerRole } from "@js/types/freelancer";

import type { RolesFormData } from "../../forms/role-form";

import { RoleListItem } from "./role-list-item";

type RolesListProps = {
  roles: FreelancerRole[];
  onEdit: (data: RolesFormData) => void;
  onDelete: (data: FreelancerRole) => void;
};

export const RolesList = ({ roles, onEdit, onDelete }: RolesListProps) => {
  const isLastRole = roles.length === 1;

  return (
    <div>
      <Typography component="h3" variant="title" size="small" mb={4}>
        🔰 Roles
      </Typography>
      {roles.map((role) => (
        <RoleListItem
          key={role.id}
          role={role}
          onEdit={onEdit}
          onDelete={onDelete}
          isLastRole={isLastRole}
        />
      ))}
    </div>
  );
};

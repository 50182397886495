import React from "react";

import { Box, Stack } from "@braintrust/braintrust-ui-components";
import { DepositIsRequired } from "@js/apps/jobs/apps/offers/components/create-offer-page-content/right-panel-sections/deposit-is-required";
import { YouAreInGoodHands } from "@js/apps/jobs/apps/offers/components/create-offer-page-content/right-panel-sections/you-are-in-good-hands";
import { ReviewOfferButton } from "@js/apps/jobs/apps/offers/components/review-offer-button";
import { OfferFields } from "@js/apps/jobs/apps/offers/forms";

type CreateOfferPageContentProps = {
  showDepositRequiredSection?: boolean;
};

export const CreateOfferPageContent = ({
  showDepositRequiredSection,
}: CreateOfferPageContentProps) => {
  return (
    <Box
      display="flex"
      gap={2}
      flexDirection={{ xs: "column-reverse", md: "row" }}
      justifyContent="center"
    >
      <Box sx={{ width: "100%" }}>
        <OfferFields />
        <ReviewOfferButton />
      </Box>
      {showDepositRequiredSection && (
        <Stack gap={2} sx={{ width: "100%", maxWidth: { md: 386 } }}>
          <DepositIsRequired />
          <YouAreInGoodHands />
        </Stack>
      )}
    </Box>
  );
};

import React from "react";

import type { TypographySizes } from "@braintrust/braintrust-ui-components";
import { Typography } from "@braintrust/braintrust-ui-components";

type SearchListingHeaderProps = {
  hideUnderline?: boolean;
  size?: TypographySizes;
};

export const SearchListingHeader: React.FC<
  React.PropsWithChildren<SearchListingHeaderProps>
> = ({ children, hideUnderline, size = "large" }) => {
  return (
    <Typography
      variant="title"
      component="h1"
      size={size}
      fontWeight={400}
      sx={{
        wordWrap: "break-word",
        wordBreak: "break-word",
      }}
      underline={hideUnderline ? undefined : "yellow"}
    >
      {children}
    </Typography>
  );
};

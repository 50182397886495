import React from "react";
import cs from "classnames";

import {
  Box,
  Carousel,
  IconButton,
  useSwiperRefs,
} from "@braintrust/braintrust-ui-components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@braintrust/braintrust-ui-components/Icons";

import style from "./style.module.scss";

type FiltersCarouselProps = {
  items: JSX.Element[];
};

export const FiltersCarousel = ({ items }: FiltersCarouselProps) => {
  const { prevEl, prevElRef, nextEl, nextElRef } = useSwiperRefs();

  return (
    <Box className={style.filtersCarousel} data-testid="filters-carousel">
      <Box className={cs(style.button, style.buttonLeft)} ref={prevElRef}>
        <IconButton variant="white-grey" aria-label="previous" size="small">
          <ArrowLeftIcon />
        </IconButton>
      </Box>
      <Carousel
        slides={items}
        slideProps={{ style: { height: "auto", width: "auto" } }}
        className={style.filtersCarouselSwiper}
        fadedBounds
        fadedBoundsClasses={{
          mode: "overwrite",
          previousFadeClassName: style.filtersCarouselSwiperWithPreviousFades,
          nextFadeClassName: style.filtersCarouselSwiperWithNextFades,
        }}
        spaceBetween={8}
        navigation={{
          prevEl,
          nextEl,
          disabledClass: style.buttonHidden,
        }}
      />
      <Box className={cs(style.button, style.buttonRight)} ref={nextElRef}>
        <IconButton variant="white-grey" aria-label="next" size="small">
          <ArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

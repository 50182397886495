import React from "react";
import { Field, reduxForm } from "redux-form";

import { Button } from "@braintrust/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import { TextareaField } from "@js/forms/fields";

export const ADD_NOTE_FORM_ID = "add-note-form";

export type AddNoteFormData = {
  id: number;
  content: string;
  objectName: EnumType<typeof ENUMS.NoteContentType>;
};

export const AddNoteForm = reduxForm<AddNoteFormData>({
  form: ADD_NOTE_FORM_ID,
})(({ submitting, submit }) => {
  return (
    <Form onSubmit={submitting ? null : submit} error={null}>
      <Field
        id="add-note"
        name="content"
        label="Write Your Note"
        component={TextareaField}
        variant="standard"
      />
      <div className="right">
        <Button variant="primary" type="submit">
          Add Note
        </Button>
      </div>
    </Form>
  );
});

import { Typography } from "@braintrust/braintrust-ui-components";
import { SearchSVGIcon } from "@braintrust/braintrust-ui-components/Icons";

export const NotFoundText = () => {
  return (
    <Typography component="h4" variant="label" textAlign="center">
      Skill not found 🙁
    </Typography>
  );
};

export const SearchIcon = () => {
  return (
    <SearchSVGIcon
      style={{
        color: "var(--grey-1)",
        fontSize: 24,
      }}
    />
  );
};

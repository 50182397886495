import React, { useState } from "react";

import { Box, Loader } from "@braintrust/braintrust-ui-components";

type JobItemCompanyLogoProps = {
  logoThumbnail: string | undefined;
  alt: string;
  isTransparent: boolean;
};

type JobItemCompanyLogoImageProps = {
  logoThumbnail: string | undefined;
  alt: string;
  width: number;
};

export const JobItemCompanyLogo = ({
  logoThumbnail,
  alt,
  isTransparent,
}: JobItemCompanyLogoProps) => {
  return !isTransparent ? (
    <Box
      width="50%"
      maxWidth={43}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: "var(--white)", padding: "10px" }}
      marginBottom={1}
    >
      <JobItemCompanyLogoImage
        logoThumbnail={logoThumbnail}
        alt={alt}
        width={100}
      />
    </Box>
  ) : (
    <JobItemCompanyLogoImage
      logoThumbnail={logoThumbnail}
      alt={alt}
      width={50}
    />
  );
};

const JobItemCompanyLogoImage = ({
  logoThumbnail,
  alt,
  width,
}: JobItemCompanyLogoImageProps) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && <Loader />}
      <img
        style={{
          display: loaded ? "block" : "none",
          maxWidth: 63,
        }}
        width={`${width}%`}
        height="auto"
        src={logoThumbnail}
        alt={alt}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

import type { ReactNode } from "react";
import React from "react";
import type { WrappedFieldProps } from "redux-form";

import type {
  MultipleSelectProps,
  PrimitiveValue,
  SelectProps,
} from "@braintrust/braintrust-ui-components";
import {
  SelectMultiple,
  SelectStyled,
} from "@braintrust/braintrust-ui-components";

import { SelectConnector } from "./connector";

export type FieldType<T> = WrappedFieldProps & T;

type SelectFieldOption = {
  label: ReactNode;
  value: PrimitiveValue;
};

export type SelectFieldProps = Omit<SelectProps, "input" | "options"> & {
  options: SelectFieldOption[] | readonly SelectFieldOption[];
};

export const SelectField = (
  props: FieldType<SelectFieldProps>,
): JSX.Element => {
  return (
    <SelectConnector
      variant="outlined-new"
      SelectComponent={SelectStyled}
      {...props}
    />
  );
};

export const SelectMultipleField = (
  props: FieldType<MultipleSelectProps>,
): JSX.Element => {
  return (
    <SelectConnector
      variant="outlined-new"
      multiple
      SelectComponent={SelectMultiple}
      onClear={() => props.input.onChange([])}
      {...props}
    />
  );
};

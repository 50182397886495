import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";
import cs from "classnames";

import {
  Box,
  Button,
  IconButton,
  ProgressBarStepper,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { LongArrowLeftIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useNewClientSignUpInvitation } from "@js/apps/onboarding/hooks/new-client-signup-invitation";
import { RouterLink } from "@js/components/link";
import { CoreLayout } from "@js/layouts/core";
import { LocalStorage } from "@js/services";

import { OnboardingProfNetHeader } from "./onboarding-profnet-header";

import styles from "./style.module.scss";

const StepError = ({ meta }: TypedWrappedFieldProps) => {
  if (!meta.error) return null;

  return (
    <Typography
      data-testid="onboarding-step-error"
      error
      mt={1}
      mr={2}
      variant="paragraph"
      component="span"
      size="medium"
    >
      {meta.error}
    </Typography>
  );
};

type OnboardingProftNetLayoutTypes = {
  onBack?: () => void;
  onContinue?: () => void;
  activeStep: number;
  pageTitle?: string;
  buttonText?: string;
  className?: string;
};

export const OnboardingProfNetLayout: React.FC<
  React.PropsWithChildren<OnboardingProftNetLayoutTypes>
> = ({
  children,
  onBack,
  onContinue,
  activeStep,
  buttonText,
  pageTitle = "Hi! Tell us why you’re here.",
  className,
}) => {
  const { isClientInvitation, isClientCompanyNodePresent } =
    useNewClientSignUpInvitation();

  const onboardingStepsQuantity = 3;
  const isTablet = useMediaQuery("md");

  const referral =
    LocalStorage.getItem(LocalStorage.keys.SIGN_UP_REFERRER) ?? undefined;

  const showProgressBarStepper =
    !isClientInvitation && !isClientCompanyNodePresent;

  const showNavigationButtons = Boolean(onBack || onContinue);
  const showOnboardingStepsError = !showNavigationButtons || isTablet;

  return (
    <div className={cs(styles.leftSection)}>
      <CoreLayout
        className={cs(styles.layout, className)}
        pageTitle={pageTitle}
      >
        <OnboardingProfNetHeader referral={referral} />
        <Box className={cs(styles.content)}>{children}</Box>

        {showOnboardingStepsError && (
          <Field name="onboarding_step_error" component={StepError} />
        )}

        {showNavigationButtons && (
          <NavigationButtons
            onBack={onBack}
            onContinue={onContinue}
            buttonText={buttonText}
          />
        )}

        {Number(activeStep) >= 0 && (
          <Typography component="p" mt={5} mb={2} size="small">
            Have an account?{" "}
            <Typography
              component="link"
              to="/auth/login/"
              size="small"
              fontWeight={500}
              RouterLink={RouterLink}
            >
              Log in
            </Typography>
          </Typography>
        )}

        {showProgressBarStepper && (
          <Box className={styles.stepper}>
            <ProgressBarStepper
              activeStep={activeStep}
              stepsLength={onboardingStepsQuantity}
            />
          </Box>
        )}
      </CoreLayout>
    </div>
  );
};

type NavigationButtonsProps = Pick<
  OnboardingProftNetLayoutTypes,
  "onBack" | "onContinue" | "buttonText"
>;

const NavigationButtons = ({
  onBack,
  onContinue,
  buttonText,
}: NavigationButtonsProps) => {
  const isTablet = useMediaQuery("md");

  return (
    <Box
      className={styles.navigationButtons}
      justifyContent={!!onBack ? "space-between" : "flex-end"}
    >
      {!!onBack && (
        <IconButton variant="transparent" onClick={onBack} aria-label="Go back">
          <LongArrowLeftIcon />
        </IconButton>
      )}

      {!isTablet && (
        <Field name="onboarding_step_error" component={StepError} />
      )}

      <Box>
        {!!onContinue && (
          <Button
            variant="primary"
            size="medium"
            type="submit"
            onClick={() => {
              onContinue();
            }}
          >
            {buttonText || "Continue"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

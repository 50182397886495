import React from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import {
  SignUpTestimonialsCarousel,
  UserTestimonialsCarousel,
} from "@js/apps/onboarding/views/layout/onboarding-carousel/user-comments-carousel";
import { Logo } from "@js/components/logo";
import { CoreLayout } from "@js/layouts/core";

import styles from "./styles.module.scss";

type AuthLayoutProps = {
  children: React.ReactNode;
  rightSocket: React.ReactNode;
  pageTitle?: string;
  noLogo?: boolean;
  noLogoLink?: boolean;
};

export const AuthLayout = ({
  children,
  rightSocket,
  pageTitle,
  noLogo,
  noLogoLink,
}: AuthLayoutProps) => {
  return (
    <CoreLayout pageTitle={pageTitle} className="width-100">
      <div className={styles.signIn}>
        <div className={styles.signInLeft}>
          {!noLogo && (
            <div className={styles.signInLogo}>
              <Logo alt="Braintrust" noLink={noLogoLink} />
            </div>
          )}
          {children}
        </div>
        {!!rightSocket && (
          <div className={styles.signInRight}>
            <div className={styles.signInRightContent}>{rightSocket}</div>
          </div>
        )}
      </div>
    </CoreLayout>
  );
};

AuthLayout.TestimonialsContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box
      maxWidth="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box p={{ xs: "64px 32px", md: "64px" }} width="100%" maxWidth="600px">
        {children}
      </Box>
    </Box>
  );
};

export const AuthSignInLayout = ({
  pageTitle,
  children,
}: Pick<AuthLayoutProps, "pageTitle" | "children">) => {
  return (
    <AuthLayout
      pageTitle={pageTitle}
      rightSocket={
        <AuthLayout.TestimonialsContainer>
          <UserTestimonialsCarousel />
        </AuthLayout.TestimonialsContainer>
      }
    >
      <Box width="100%" maxWidth="640px" mt="56px">
        {children}
      </Box>
    </AuthLayout>
  );
};

export const AuthOnboardingLayout = ({
  pageTitle,
  children,
}: Pick<AuthLayoutProps, "pageTitle" | "children">) => {
  return (
    <AuthLayout
      noLogo
      pageTitle={pageTitle}
      rightSocket={
        <AuthLayout.TestimonialsContainer>
          <SignUpTestimonialsCarousel />
        </AuthLayout.TestimonialsContainer>
      }
    >
      <Box width="100%" maxWidth="680px">
        {children}
      </Box>
    </AuthLayout>
  );
};

import {
  Box,
  Button,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import {
  FlowerIcon,
  LightBulbIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { Emoji } from "@js/components/emoji";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks/redux";

import { openCreatePostModal } from "../create-or-edit-post-modal/modal/open-modal";

import { HowToPostQuestions } from "./how-to-post-questions";
import { HowToPostVideo } from "./how-to-post-video";

import styles from "./styles.module.scss";

export const HowToPostModalContent = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("sm");

  const handlePostQuestionClick = () => {
    ModalInstance.close();
    dispatch(openCreatePostModal({ composer_location: "feed" }));
  };

  return (
    <Box>
      <Box className={styles.topContentWrapper}>
        <Box className={styles.topContent}>
          <Stack
            direction="row"
            sx={{
              gap: 1,
              alignItems: "center",
              justifyContent: { sm: "center" },
              mb: { xs: 11, sm: 4.5 },
              px: 3,
              marginInline: { sm: "auto" },
              maxWidth: "80%",
              flexWrap: "wrap",
            }}
          >
            <Box className={styles.handEmoji}>
              <Emoji emoji="✍️" />
            </Box>
            <Typography
              component="h2"
              fontWeight={500}
              size="medium"
              variant="label"
              sx={{ whiteSpace: "nowrap" }}
            >
              How to post on Career Help
            </Typography>
          </Stack>
          <Box sx={{ px: 3 }}>
            <Box className={styles.videoContainer}>
              <HowToPostVideo />
            </Box>
          </Box>

          <Box className={styles.bgIcon}>
            <FlowerIcon className={styles.flowerIcon} />
            <LightBulbIcon className={styles.bulbIcon} />
          </Box>
          <Box sx={{ pt: { xs: 3, md: 4 }, pb: 3 }}>
            <HowToPostQuestions />
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: { xs: 3, md: 4 }, pb: 3, px: 3 }}>
        <Typography
          component="h3"
          variant="title"
          className={styles.bottomText}
          size={isMobile ? "small" : "medium"}
          fontWeight={400}
          sx={{ maxWidth: { md: "80%" } }}
        >
          What kind of career advice could you use today?
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          className={styles.bottomText}
          size={isMobile ? "small" : "medium"}
          sx={{ maxWidth: { md: "75%" }, mb: { xs: 2, md: 3 } }}
        >
          Learning a new skill? Tackling a new project? Masha will show you how
          to ask the community for insights and advice in the video above.
        </Typography>
        <Box sx={{ textAlign: "end" }}>
          <Button
            variant="primary"
            shape="squared"
            onClick={handlePostQuestionClick}
          >
            Post a question
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const openHowToPostModal = () => {
  ModalInstance.close();
  ModalInstance.open({
    children: <HowToPostModalContent />,
    padding: false,
    className: styles.modal,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "small",
      className: styles.closeButton,
    },
  });
};

import cs from "classnames";

import {
  Box,
  Button,
  Rating,
  SwipeableDrawer,
  Typography,
  useDrawer,
} from "@braintrust/braintrust-ui-components";
import { EyeIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useIsNodeStaff } from "@js/apps/common/hooks/is-node-staff";
import type { FreelancerForNodeStaff } from "@js/types/freelancer";

import { InternalTalentReviewDrawer } from "../internal-talent-rating-drawer";

import styles from "./style.module.scss";

type CardColors = "soft-beige" | "off-white" | "white";

export type InternalRatingProfile = Pick<
  FreelancerForNodeStaff,
  | "id"
  | "user"
  | "average_internal_review_rating"
  | "internal_review_ratings_count"
>;

type RatingCardProps = {
  freelancer: InternalRatingProfile;
  shortTile?: boolean;
  color?: CardColors;
  className?: string;
};

export const RatingCard = ({
  freelancer,
  shortTile,
  color = "off-white",
  className,
}: RatingCardProps) => {
  const isStaff = useIsNodeStaff();
  const { isOpen, toggleDrawer } = useDrawer();

  if (!isStaff) return null;

  const { average_internal_review_rating, internal_review_ratings_count } =
    freelancer;

  return (
    <Box
      className={cs(styles.ratingCard, className, {
        [styles.ratingCardShort]: shortTile,
        [styles[`rating-card--${color}`]]: color,
      })}
    >
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={0.5}>
        {average_internal_review_rating && (
          <Typography component="span" fontWeight={500}>
            {average_internal_review_rating}
          </Typography>
        )}
        <Rating
          variant="stars"
          size="small"
          iconStyle={{ height: "24px", marginLeft: "-3px" }}
          containerProps={{ lineHeight: "16px", marginLeft: "3px" }}
          color="orange"
          readOnly
          value={Number(average_internal_review_rating)}
        />
        <Typography
          component="span"
          size="small"
          color="grey-3"
          mr={0.5}
          fontWeight={500}
        >
          ({internal_review_ratings_count})
        </Typography>
        <Button
          onClick={toggleDrawer}
          size="x-small"
          fontWeight={400}
          variant="no-padding"
          style={{
            padding: "0px",
            textDecoration: "underline",
            textUnderlineOffset: "2px",
          }}
        >
          Rate
        </Button>
      </Box>
      <Box display="flex" alignItems="center">
        <EyeIcon
          style={{
            width: "18px",
            height: "18px",
          }}
        />
        <Typography component="span" size="small" fontWeight={400} ml={0.75}>
          Only the core team will see this.
        </Typography>
      </Box>
      <SwipeableDrawer
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        anchor="right"
        ModalProps={{
          keepMounted: false,
        }}
      >
        <InternalTalentReviewDrawer
          onClose={toggleDrawer}
          talent={freelancer}
        />
      </SwipeableDrawer>
    </Box>
  );
};

import { Typography } from "@braintrust/braintrust-ui-components";
import { FeedHashtagsHeaderContainer } from "@js/apps/common/components/feed-hashtags-header-container";
import { useGetHashtagByIdQuery } from "@js/apps/give-and-get-help/api";
import { BackButton } from "@js/apps/give-and-get-help/components/header/back-button";

type FeedHashtagHeaderProps = {
  hashtagId: number;
};

export const FeedHashtagHeader = ({ hashtagId }: FeedHashtagHeaderProps) => {
  const { data } = useGetHashtagByIdQuery(hashtagId);

  const hashtagName = data?.name ?? "";

  return (
    <FeedHashtagsHeaderContainer>
      <Typography
        component="h1"
        variant="title"
        textAlign="left"
        size="medium"
        fontWeight={400}
        maxWidth="100%"
        ellipsis
      >
        #{hashtagName}
      </Typography>
      <BackButton />
    </FeedHashtagsHeaderContainer>
  );
};

import GghCelebratoryBackground from "@static/give-get-help/ggh-celebratory-background.svg";

import { Box } from "@braintrust/braintrust-ui-components";

import styles from "./styles.module.scss";

type SocialProofPostBackgroundProps = {
  celebratoryBackground: string;
  backgroundColor: string;
};

export const SocialProofPostBackground = ({
  celebratoryBackground,
  backgroundColor,
}: SocialProofPostBackgroundProps) => {
  return (
    <>
      <GghCelebratoryBackground
        color={celebratoryBackground}
        className={styles.celebratoryBackground}
      />
      <Box
        className={styles.verticalGradient}
        style={{
          background: `linear-gradient(270deg, ${backgroundColor} 0%, rgba(249, 246, 255, 0) 85%)`,
        }}
      />
      <Box className={styles.horizontalGradient} />
    </>
  );
};

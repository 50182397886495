import React from "react";

import {
  Grid,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";

type BidListHeaderProps = {
  closeDrawer: () => void;
  totalBids: number;
  goToNextBid: () => void;
  goToPreviousBid: () => void;
  currentBidIndex: number | undefined;
};

export const HEADER_HEIGHT = 82;

export const BidDetailsHeader = ({
  goToNextBid,
  goToPreviousBid,
  currentBidIndex,
  closeDrawer,
  totalBids,
}: BidListHeaderProps) => {
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="space-between"
      alignItems="center"
      height={HEADER_HEIGHT}
      p={2}
    >
      <Grid item container spacing={2} alignItems="center" xs={"auto"}>
        <Grid item>
          <IconButton
            onClick={goToPreviousBid}
            size="medium"
            variant="primary"
            aria-label="Previous"
          >
            <ArrowLeftIcon />
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton
            onClick={goToNextBid}
            size="medium"
            variant="primary"
            aria-label="Next"
          >
            <ArrowRightIcon />
          </IconButton>
        </Grid>

        {typeof currentBidIndex !== "undefined" && (
          <Grid item>
            <Typography
              component="p"
              variant="paragraph"
              size="small"
              style={{ color: "var(--grey-2)" }}
            >
              Application {currentBidIndex + 1} of {totalBids}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item>
        <IconButton
          size="small"
          variant="transparent"
          onClick={closeDrawer}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

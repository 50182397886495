import React from "react";
import AvatarBackground from "@static/freelancer/talent-image-card-background.svg";

import {
  Avatar,
  Box,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { LocationPinSmallIcon } from "@braintrust/braintrust-ui-components/Icons";
import { ApprovedBadge } from "@js/apps/freelancer/components/approved-badge";
import { RolesTag } from "@js/apps/jobs/components/job-type-and-role-tags";
import { RouterLink } from "@js/components/link";

import type { SimplePostTalentLinkAttachment } from "../post-attachments";

import styles from "./styles.module.scss";

type TalentProfileCardProps = SimplePostTalentLinkAttachment & {
  openNewTab?: boolean;
};

export const TalentProfileCard = ({
  user,
  location,
  role,
  years_experience,
  url,
  approved,
  rate,
  openNewTab,
}: TalentProfileCardProps) => {
  return (
    <Box
      className={styles.prettyLinkCardContainer}
      component={RouterLink}
      to={url}
      target={openNewTab ? "_blank" : undefined}
    >
      <div className={styles.prettyLinkCardAvatarContainer}>
        <AvatarBackground className={styles.prettyLinkCardAvatarBackground} />
        <Avatar className={styles.prettyLinkCardAvatar} size="md" src={user} />
      </div>
      <div className={styles.prettyLinkCardIntroduction}>
        <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
          <Typography component="h4" fontWeight={500} ellipsis>
            {user.public_name}
          </Typography>
          {approved && <ApprovedBadge iconStyles={{ strokeWidth: "2px" }} />}
        </Stack>
        {user.introduction_headline && (
          <Typography component="p" size="small" ellipsis>
            {user.introduction_headline}
          </Typography>
        )}
      </div>
      <Box display="flex" alignItems="center" px={3}>
        {!!rate && (
          <Typography
            component="p"
            size="small"
            sx={{ whiteSpace: "nowrap" }}
            mr={1}
          >
            ${rate}/hr
          </Typography>
        )}
        {!!location && (
          <div className={styles.prettyLinkCardLocation}>
            <LocationPinSmallIcon
              className={styles.prettyLinkCardLocationIcon}
            />
            <Typography component="span" size="small" title={location} ellipsis>
              {location}
            </Typography>
          </div>
        )}
      </Box>
      {!!role && (
        <Box
          className={styles.prettyLinkCardRole}
          px={3}
          maxWidth="fit-content"
        >
          <RolesTag
            className="role-tag"
            role={role}
            experience={years_experience}
          />
        </Box>
      )}
    </Box>
  );
};

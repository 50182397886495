import { useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";
import cs from "classnames";

import type { ButtonVariants } from "@braintrust/braintrust-ui-components";
import { Box, Tooltip } from "@braintrust/braintrust-ui-components";
import { CheckCircleFilledIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  CustomBudgetRateEndAdornment,
  CustomBudgetRateStartAdornment,
} from "@js/apps/give-and-get-help/components/category-and-budget-utils/adornment";
import type { RenderCustomOptionButtonArgs } from "@js/components/option-tile-buttons";
import { CustomOptionTileButton } from "@js/components/option-tile-buttons";
import { NumberField } from "@js/forms/fields";
import type { OptionWithPrimitiveValue } from "@js/types/common";

import { useCustomRateButton } from "./use-custom-rate-button";

import styles from "./style.module.scss";

type CustomRateButtonProps = {
  input: WrappedFieldProps["input"];
  meta: WrappedFieldProps["meta"];
  disabled?: boolean;
  options: OptionWithPrimitiveValue[];
  showTooltip?: boolean;
  showCheckIcon?: boolean;
} & Pick<
  RenderCustomOptionButtonArgs,
  "handleChange" | "isSelected" | "itemA11yProps" | "onClick" | "setActiveIndex"
>;

export const CustomRateButton = ({
  input,
  meta,
  options,
  disabled,
  onClick,
  isSelected = false,
  handleChange,
  itemA11yProps,
  setActiveIndex,
  showTooltip,
  showCheckIcon,
}: CustomRateButtonProps) => {
  const {
    handleButtonOnClick,
    handleValueChange,
    onFocus,
    onBlur,
    inputWidth,
    inputRef,
    focus,
    amountValue,
  } = useCustomRateButton({
    input,
    meta,
    isSelected,
    options,
    onClick,
    setActiveIndex,
  });

  const buttonVariant: ButtonVariants = useMemo(() => {
    if (disabled) {
      return "disabled-grey";
    }

    if (isSelected && !focus) {
      return "medium-violet";
    }

    return "white-violet";
  }, [disabled, focus, isSelected]);

  const button = (
    <CustomOptionTileButton
      {...itemA11yProps(isSelected)}
      option={{
        value: "custom",
        label:
          isSelected || amountValue ? (
            <Box display={"flex"} alignItems={"center"}>
              {isSelected && showCheckIcon && (
                <Box mr={1}>
                  <CheckCircleFilledIcon fontSize="medium" />
                </Box>
              )}
              <NumberField
                input={input}
                meta={meta}
                hideError
                variant="standard"
                id="custom-budget-rate"
                // @ts-ignore TODO: fix this type when refactoring this component
                onChange={handleValueChange}
                className={styles.inputWrapper}
                autoFocus={!amountValue}
                autoComplete="off"
                inputProps={{
                  decimalScale: 2,
                  allowNegative: false,
                  "aria-label": "Custom amount",
                  sx: { width: inputWidth, textOverflow: "ellipsis" },
                }}
                InputProps={{
                  inputRef,
                  value: amountValue,
                  onBlur,
                  onFocus,
                  disableUnderline: true,
                  classes: {
                    input: styles.input,
                  },
                  endAdornment: (
                    <>
                      <CustomBudgetRateStartAdornment />
                      <CustomBudgetRateEndAdornment
                        focus={focus}
                        disabled={disabled}
                      />
                    </>
                  ),
                }}
              />
            </Box>
          ) : (
            "Custom amount"
          ),
      }}
      shape="squared"
      onClick={handleButtonOnClick}
      isSelected={isSelected}
      handleChange={handleChange}
      variant={buttonVariant}
      tabIndex={isSelected ? -1 : 0}
      disableRipple={isSelected}
      className={cs(styles.button, {
        [styles.buttonSelected]: !isSelected || !focus,
      })}
    />
  );

  if (showTooltip) {
    return (
      <Tooltip
        title={`${amountValue || 0} BTRST`}
        disableHoverListener={!amountValue}
        disableFocusListener
        placement="top"
      >
        <span>{button}</span>
      </Tooltip>
    );
  }

  return <div>{button}</div>;
};

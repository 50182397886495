import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import type { WrappedFieldsProps } from "redux-form";
import { Fields } from "redux-form";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import {
  CalendarIcon,
  CircleCheckGreenIcon,
  CircleUncheckedIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { TalentAvailabilityForm } from "@js/apps/common/components/filters/config";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useTalentAvaiabilityFilterUtils } from "@js/apps/freelancer/hooks";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import { CheckboxField } from "@js/forms/fields";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import { PossibleFilters } from "@js/types/common";

import styles from "./styles.module.scss";

const formId = "talent-filters__availability";

export const AvailabilityFilter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [params, setSearchParams] = useSearchParams();
  const isNodeStaff = useIsNodeStaff();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.AVAILABILITY,
      filter_value: values,
    });
  };
  const appliedRecently = params.get("applied_recently");
  const lookingForWork = params.get("looking_for_work");
  const isActive = !!appliedRecently || !!lookingForWork;

  useEffect(() => {
    if (!isNodeStaff && appliedRecently) {
      setSearchParams((prevState) => {
        prevState.delete("applied_recently");
        return prevState;
      });
    }
  }, [isNodeStaff, appliedRecently, setSearchParams]);

  return (
    <JobPopoverFilterButton
      startIcon={<CalendarIcon />}
      isActive={isActive}
      label="Availability"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      popoverContent={
        <TalentAvailabilityForm
          form={formId}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Fields
                submit={submit}
                names={[
                  "applied_recently",
                  "looking_for_work",
                  "looking_for_work_options",
                ]}
                component={AvailabilityFilterContent}
              />
            );
          }}
        </TalentAvailabilityForm>
      }
    />
  );
};

type AvailabilityFilterContentProps = WrappedFieldsProps & {
  submit: () => void;
};

const AvailabilityFilterContent = ({
  submit,
  ...fields
}: AvailabilityFilterContentProps) => {
  const { looking_for_work, looking_for_work_options, applied_recently } =
    fields;

  const isNodeStaff = useIsNodeStaff();
  const { shouldDisplayOptions, handleLookingForWorkChange, handleReset } =
    useTalentAvaiabilityFilterUtils(fields);

  return (
    <JobPopoverFilterButton.Content
      maxWidth={640}
      onApply={submit}
      onReset={handleReset}
      showOverflow
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box className={styles.container}>
          <CheckboxField
            className={styles.checkbox}
            input={looking_for_work.input}
            meta={looking_for_work.meta}
            labelVariant={"transparent"}
            label={<LookingForWorkLabel />}
            icon={<CircleUncheckedIcon />}
            checkedIcon={<CircleCheckGreenIcon />}
            onChange={handleLookingForWorkChange}
          />
          {shouldDisplayOptions && (
            <ButtonSelectField
              options={lookingForWorkOptions}
              variant="white-grey"
              selectedVariant="medium-violet"
              input={looking_for_work_options.input}
              meta={looking_for_work_options.meta}
              size="x-small"
              typographySize="small"
            />
          )}
        </Box>
        {isNodeStaff && (
          <CheckboxField
            className={styles.appliedRecentlyField}
            input={applied_recently.input}
            meta={applied_recently.meta}
            label="Applied to a job in the last 30 days"
            disableRipple
          />
        )}
      </Box>
    </JobPopoverFilterButton.Content>
  );
};

const lookingForWorkOptions = [
  { value: ENUMS.FreelancerAvailabilityForWork.FULL_TIME, label: "Full time" },
  { value: ENUMS.FreelancerAvailabilityForWork.PART_TIME, label: "Part time" },
  {
    value: ENUMS.FreelancerAvailabilityForWork.DIRECT_HIRE,
    label: "Direct hire",
  },
];

const LookingForWorkLabel = () => {
  return (
    <>
      <Typography component="h3" variant="label" size="large" fontWeight={500}>
        Currently looking for work
      </Typography>
      <Typography
        variant="label"
        component="span"
        size="small"
        fontWeight={400}
      >
        Talent who turned on their “Looking for work” badge
      </Typography>
    </>
  );
};

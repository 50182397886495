import React from "react";

import { Box, Carousel } from "@braintrust/braintrust-ui-components";
import ProjectItem from "@js/apps/freelancer/components/projects/project-item";
import ProjectModalItem from "@js/apps/freelancer/components/projects/project-modal-item";
import { ModalInstance } from "@js/components/modal";
import ModalSlider from "@js/components/modal-slider";
import { setCurrentItemIndex } from "@js/components/modal-slider/actions";
import { useAppDispatch } from "@js/hooks";
import type { WorkSampleItem } from "@js/types/freelancer";

const SLIDER_MODAL_ID = "projects-sample-modal";

type ProjectsCarouselProps = {
  items: WorkSampleItem[];
  navigationNextRef: HTMLButtonElement | null;
  navigationPrevRef: HTMLButtonElement | null;
  itemWidth?: string;
};

export const ProjectsCarousel = ({
  navigationNextRef,
  navigationPrevRef,
  items,
  itemWidth,
}: ProjectsCarouselProps) => {
  const dispatch = useAppDispatch();

  const openModalWithWorkItem = React.useCallback(
    (index: number) => {
      dispatch(setCurrentItemIndex(SLIDER_MODAL_ID, index));
      ModalInstance.open({
        children: (
          <ModalSlider
            id={SLIDER_MODAL_ID}
            items={items.map((item, itemIndex) => (
              <ProjectModalItem
                key={item.id}
                item={item}
                index={itemIndex}
                lastIndex={items.length - 1}
                openDifferentProject={openModalWithWorkItem}
              />
            ))}
          />
        ),
        closeButton: true,
      });
    },
    [items, dispatch],
  );

  return (
    <>
      <Carousel
        slides={items.map((item, itemIndex) => {
          return (
            <Box mr={4} key={item.id}>
              <ProjectItem
                item={item}
                index={itemIndex}
                openProjectModal={openModalWithWorkItem}
              />
            </Box>
          );
        })}
        slideProps={{
          style: { width: itemWidth },
          onTouchMove: (e) => {
            e.stopPropagation();
          },
        }}
        navigation={{
          nextEl: navigationNextRef,
          prevEl: navigationPrevRef,
        }}
        keyboard={true}
      />
    </>
  );
};

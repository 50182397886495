import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";

import styles from "./style.module.scss";

export const DepositIsRequired = () => {
  return (
    <Box className={styles.rightPanelElement} bgcolor="var(--medium-blue)">
      <Typography component="p" variant="label" size="large">
        A one-time deposit is required
      </Typography>
      <div>
        <Typography
          component="p"
          variant="paragraph"
          size="medium"
          color="grey-1"
        >
          As a show of good faith and to protect the Talent, we ask clients to
          submit a $500 deposit (plus Stripe processing fees if using credit
          card) when creating an offer on their first job.
        </Typography>
        <br />
        <Typography
          component="p"
          variant="paragraph"
          size="medium"
          color="grey-1"
        >
          100% of this payment will be refunded (minus Stripe processing fees)
          once you pay your first invoice or if you close the job without hiring
          anyone.
        </Typography>
      </div>
    </Box>
  );
};

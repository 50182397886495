import React from "react";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import { Box, Typography } from "@braintrust/braintrust-ui-components";
import type { SIZE } from "@js/apps/employer/components/employer-logo";
import { BoxedEmployerLogo } from "@js/apps/employer/components/employer-logo";
import { RouterLink } from "@js/components/link";
import type { Employer } from "@js/types/employer";

export type EmployerLogoWithNameProps = {
  employer: Pick<
    Employer,
    "id" | "logo" | "name" | "logo_thumbnail" | "has_logo_set"
  >;
  gap: number;
  size: keyof typeof SIZE;
  nameVariant: TypographyProps["variant"];
  nameSize: TypographyProps["size"];
  nameComponent?: TypographyProps["component"];
  fontWeight?: TypographyProps["fontWeight"];
  noUrl?: boolean;
  color?: string;
  imageSource: string;
  jobId?: number;
  nameStyle?: React.CSSProperties | null;
  openInNewTab?: boolean;
};

export const EmployerLogoWithName = ({
  employer,
  gap,
  size,
  nameVariant,
  nameSize,
  nameComponent = "span",
  noUrl = false,
  fontWeight = 500,
  color = "var(--black)",
  imageSource,
  jobId,
  nameStyle,
  openInNewTab,
}: EmployerLogoWithNameProps) => {
  const nameColor = employer.has_logo_set ? color : "var(--info-2)";

  return (
    <Box display="flex" alignItems="center" columnGap={gap / 8}>
      <BoxedEmployerLogo
        size={size}
        source={imageSource}
        employer={employer}
        style={{
          border: "0.5px solid var(--soft-beige)",
        }}
      />
      <Typography
        component={nameComponent}
        variant={nameVariant}
        fontWeight={fontWeight}
        size={nameSize}
        style={{
          color: nameColor,
          wordBreak: "break-word",
          ...nameStyle,
        }}
        multilineEllipsis={2}
      >
        {noUrl ? (
          employer.name
        ) : (
          <RouterLink
            to={`/employers/${employer.id}/?job=${jobId}`}
            style={{ color: nameColor }}
            target={openInNewTab ? "_blank" : undefined}
          >
            {employer.name}
          </RouterLink>
        )}
      </Typography>
    </Box>
  );
};

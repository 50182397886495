import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ButtonSelectField } from "@js/forms/fields/button-select";

import type { PostCategoryFieldProps } from ".";

type MessageCategoryFieldProps = PostCategoryFieldProps & { label?: string };

export const MessageOfferCategory = ({
  input,
  meta,
  categories,
  label,
}: MessageCategoryFieldProps) => {
  return (
    <Box mt={3}>
      {label && (
        <Typography component="p" variant="paragraph" size="large" mb={1}>
          {label}
        </Typography>
      )}

      <ButtonSelectField
        input={input}
        meta={meta}
        multiple={false}
        options={categories}
        variant="white-violet"
        selectedVariant="violet-with-dark-border--violet-text"
        fontWeight={400}
        size="medium"
      />
    </Box>
  );
};

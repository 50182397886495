import { useMemo } from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import type { User } from "@js/types/auth";
import type { Reaction, StickerValue } from "@js/types/give-and-get-help";

import { StickerReaction } from "../reaction";
import { useUpdateDrawerState } from "../reactions-drawer/use-update-drawer-state";

type ReactionsProps = {
  reactions: Reaction[];
  onReactionClick: (sticker: StickerValue) => void;
  currentUser: User | null;
  entityId: number;
};

export const Reactions = ({
  reactions,
  onReactionClick,
  currentUser,
  entityId,
}: ReactionsProps) => {
  const reactionsToDisplay = useMemo(
    () =>
      reactions
        .filter((reaction) => reaction.count > 0)
        .sort((a, b) => b.count - a.count),
    [reactions],
  );
  useUpdateDrawerState({ entityId, reactions });

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      rowGap={1}
      sx={{
        columnGap: { xs: 1.5, sm: 2 },
      }}
    >
      {reactionsToDisplay.map((reaction) => {
        return (
          <StickerReaction
            key={reaction.sticker}
            entityId={entityId}
            entityReactions={reactions}
            onClick={onReactionClick}
            reaction={reaction}
            currentUser={currentUser}
          />
        );
      })}
    </Box>
  );
};

import React from "react";
import { Field } from "redux-form";

import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { ArrowLeftIcon } from "@braintrust/braintrust-ui-components/Icons";
import { createFormInstance } from "@js/forms/components";
import { TextField } from "@js/forms/fields/text";
import { required } from "@js/forms/validators";

import styles from "./styles.module.scss";

type FormData = {
  categoryName: string;
};

const SUGGEST_CATEGORY_FORM = "suggest-category-form";

export const SuggestCategoryForm = createFormInstance<FormData, unknown>(
  SUGGEST_CATEGORY_FORM,
);

export const SuggestCategoryModalFields = ({
  onCancel,
}: {
  onCancel: () => void;
}) => {
  return (
    <>
      <Box className={styles.suggestCategoryModalHeader}>
        <IconButton
          size="small"
          variant="transparent"
          aria-label="Close modal"
          onClick={onCancel}
          sx={{
            backgroundColor: "var(--grey-5) !important",
            p: "8px !important",
            mr: 2,
          }}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Typography component="p" variant="title" size="small" fontWeight={400}>
          Suggest a category
        </Typography>
      </Box>
      <Typography mb={3} component="p" variant="paragraph" size="large">
        Submit your suggestion for a new help category. If a lot of people want
        to offer this type of help, we’ll add it to the list.
      </Typography>
      <Field
        name="categoryName"
        id="category-name"
        maxLength={200}
        validate={[required]}
        variant="outlined"
        color="secondary"
        placeholder="Enter category name"
        component={TextField}
        label="Suggest a category"
      />
      <Typography component="p" size="small" mt={1} mb={3} color="grey-1">
        Add one category at a time.
      </Typography>
      <Divider color="beige" />
      <Box className={styles.suggestCategoryModalFooter}>
        <Button
          variant="secondary"
          shape="squared"
          size="medium"
          onClick={onCancel}
        >
          Discard suggestion
        </Button>
        <Button
          variant="positive-2"
          shape="squared"
          type="submit"
          size="medium"
        >
          Suggest category
        </Button>
      </Box>
    </>
  );
};

import type { ComponentPropsWithoutRef } from "react";

import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { RoundedBox } from "@braintrust/braintrust-ui-components";
import { AppLayout } from "@js/layouts/app";

import style from "./style.module.scss";

type JobManagementLayoutProps = ComponentPropsWithoutRef<typeof AppLayout>;

export const JobManagementLayout = (props: JobManagementLayoutProps) => {
  return (
    <AppLayout
      pageTitle="Job Applications"
      coreClassName={style.jobManagementPage}
      bgcolor="var(--soft-blue)"
      setBgOnBody
      {...props}
    />
  );
};

type JobManagementContentLayoutProps = {
  header: React.ReactNode;
  main: React.ReactNode;
};

export const JobManagementContentLayout = ({
  header,
  main,
}: JobManagementContentLayoutProps) => {
  return (
    <div className={style.jobManagementPageContent}>
      {header}
      {main}
    </div>
  );
};

export const JobManagementContentBox = ({ className, ...props }: BoxProps) => {
  return <RoundedBox component="main" className={className} {...props} />;
};

export const JobManagement = {
  Layout: JobManagementLayout,
  ContentLayout: JobManagementContentLayout,
  ContentBox: JobManagementContentBox,
};

import cs from "classnames";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { BoltIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { PostComment } from "@js/types/give-and-get-help";

import { CommentOfferBoxActions } from "./comment-offer-box-actions";
import { CommentOfferBoxLabel } from "./comment-offer-box-label";
import { useCommentOfferBox } from "./use-comment-offer-box";

import styles from "./style.module.scss";

type CommentOfferBoxProps = PostComment;

export const CommentOfferBox = (
  props: CommentOfferBoxProps,
): JSX.Element | null => {
  const {
    helpOffer,
    isCommentAuthor,
    hasCreatedOrPendingStatus,
    disablePostActions,
    labels,
    status,
    removeOffer,
    onClick,
  } = useCommentOfferBox(props);

  if (helpOffer === null) return null;

  return (
    <Box
      className={cs(styles.commentOffer, {
        [styles["comment-offer--declined"]]: status.offerDeclined,
        [styles["comment-offer__is-comment-author"]]: isCommentAuthor,
      })}
    >
      {status.offerAccepted && <OfferAcceptedBadge />}
      <CommentOfferBoxLabel budget={helpOffer?.budget} label={labels.label} />
      <CommentOfferBoxActions
        hasCreatedOrPendingStatus={hasCreatedOrPendingStatus}
        isCommentAuthor={isCommentAuthor}
        disabled={disablePostActions || status.offerDeclined}
        onRemoveOfferClick={removeOffer}
        onMessageButtonClick={onClick}
        label={labels.buttonLabel}
      />
    </Box>
  );
};

const OfferAcceptedBadge = () => {
  return (
    <div className={styles["comment-offer__accepted-badge"]}>
      <BoltIcon className={styles["comment-offer__accepted-badge__icon"]} />
      <Typography component="span" fontWeight={500} size="small">
        Offer accepted
      </Typography>
    </div>
  );
};

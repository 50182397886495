import { Box, Loader, Typography } from "@braintrust/braintrust-ui-components";
import { CircleCheckGreenIcon } from "@braintrust/braintrust-ui-components/Icons";

import type { SectionsWithChangesSavedType } from "./constant";
import {
  useHandleMarkerAutoHide,
  useShouldDisplayChangesMarker,
} from "./hooks";

type ChangesSavedMarkerProps = {
  variant: "full" | "minimal";
  sections: SectionsWithChangesSavedType;
};

export const ChangesSavedMarker = ({
  variant,
  sections,
}: ChangesSavedMarkerProps) => {
  useHandleMarkerAutoHide(variant === "minimal");
  const { shouldDisplayLoader, shouldDisplayMarker } =
    useShouldDisplayChangesMarker(sections);

  if (!shouldDisplayMarker) {
    return null;
  }

  if (shouldDisplayLoader) {
    return <Loader />;
  }

  if (variant === "full") {
    return (
      <Box height={24}>
        <Typography
          component="span"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center ",
          }}
          color="positive"
          variant="label"
          size="small"
        >
          Changes Saved
          <CircleCheckGreenIcon sx={{ ml: 1, fontSize: 12 }} />
        </Typography>
      </Box>
    );
  }

  return (
    <Box height={24}>
      <Typography
        component="span"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center ",
        }}
        color="positive"
        variant="label"
        size="small"
      >
        <CircleCheckGreenIcon sx={{ mr: 1, fontSize: 12 }} />
        Saved
      </Typography>
    </Box>
  );
};

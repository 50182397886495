import { type FC } from "react";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Box, Button } from "@braintrust/braintrust-ui-components";

import { useStripeCheckoutForm } from "./hooks/use-stripe-checkout-form";

const stripePromise = loadStripe(SETTINGS.STRIPE_INTERNAL_PUBLISHABLE_KEY);

export const StripeCheckoutForm: FC = () => {
  const { onPayViaBTRST, stripeOptions, upgradeCost } = useStripeCheckoutForm();

  return (
    <Box flex={1} overflow="auto" width="1020px">
      <Box>
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={stripeOptions}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </Box>
      <Box mt={2} px={{ xs: 2.5, md: 3.75 }} pb={{ xs: 2.5, md: 3.75 }}>
        <Button
          fullWidth
          variant="black-outlined"
          shape="squared"
          onClick={onPayViaBTRST}
        >
          Or pay{" "}
          <Box display="flex" alignItems="center" ml={0.75} mr={0.5} mt={-0.25}>
            <img
              width="auto"
              height="18px"
              src={`${SETTINGS.STATIC_URL}logo/logo-symbol.svg`}
              alt="logo"
            />
          </Box>{" "}
          {upgradeCost} BTRST
        </Button>
      </Box>
    </Box>
  );
};

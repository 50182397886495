import { Typography } from "@braintrust/braintrust-ui-components";
import { AppLayout } from "@js/layouts/app";

import styles from "./styles.module.scss";

const TooManyRequestsPage = () => {
  return (
    <AppLayout
      pageTitle="Too Many Requests"
      flexColumn
      className={styles.notFoundPage}
    >
      <>
        <Typography component="h1" variant="display">
          429
        </Typography>
        <Typography
          component="p"
          variant="title"
          fontWeight={400}
          className={styles.notFoundPageMessage}
        >
          Too Many Requests
        </Typography>
        <Typography
          component="p"
          variant="title"
          fontWeight={400}
          className={styles.notFoundPageInfo}
        >
          Please wait for a while or contact us about the problem.
        </Typography>
      </>
    </AppLayout>
  );
};

export default TooManyRequestsPage;

import type { MutableRefObject, ReactNode } from "react";
import { useImperativeHandle } from "react";
import type { DropzoneOptions, DropzoneRef } from "react-dropzone";
import { useDropzone } from "react-dropzone";

import {
  type BoxProps,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { Box } from "@braintrust/braintrust-ui-components";

import { FileDropzonePlaceholder } from "../file-dropzone-placeholder";
import { FileRejections } from "../file-rejections";

export type FileDropzoneContainerProps = Pick<
  BoxProps,
  "sx" | "className" | "tabIndex" | "id"
>;

export type RenderFileDropzonePlaceholderArg = {
  isDragActive: boolean;
  isFocused: boolean | undefined;
};

export type RenderFileDropzonePlaceholder =
  | ReactNode
  | ((arg: RenderFileDropzonePlaceholderArg) => ReactNode);

export type FileDropzoneProps = {
  label?: string;
  dropzoneOptions?: Omit<DropzoneOptions, "maxFiles">;
  error?: string;
  customRef?: MutableRefObject<DropzoneRef | undefined>;
  children?: RenderFileDropzonePlaceholder;
} & FileDropzoneContainerProps;

export const FileDropzone = ({
  label = "Upload file",
  children = (props) => <FileDropzonePlaceholder {...props} />,
  dropzoneOptions,
  error,
  customRef,
  ...boxProps
}: FileDropzoneProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    fileRejections,
    open,
  } = useDropzone({
    multiple: false,
    minSize: 1,
    maxSize: SETTINGS.DEFAULT_MAX_SIZE_FILE,
    ...dropzoneOptions,
  });

  const isFunctionAsChildren = typeof children === "function";
  const isReactNode = !!children && !isFunctionAsChildren;

  useImperativeHandle(customRef, () => {
    return { open };
  });

  return (
    <Box>
      <Box
        {...getRootProps()}
        role="button"
        aria-label={label}
        {...boxProps}
        sx={{
          ...boxProps?.sx,
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <input
          data-testid="file-dropzone-input"
          style={{ display: "none" }}
          {...getInputProps()}
        />
        <Box>
          {isFunctionAsChildren && children({ isDragActive, isFocused })}
          {isReactNode && <>{children}</>}
        </Box>
      </Box>
      {!!error && <DropzoneError>{error}</DropzoneError>}
      {!!fileRejections?.length && (
        <FileRejections fileRejections={fileRejections} />
      )}
    </Box>
  );
};

const DropzoneError = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      component="span"
      error
      size="small"
      className="dropzone-error"
      sx={{ mt: 0.5, display: "inline-block" }}
    >
      {children}
    </Typography>
  );
};

import { useCallback, useState } from "react";
import type { FileRejection } from "react-dropzone";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import {
  ArrowUpIcon,
  ErrorSVGIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { UploadedFile } from "@js/apps/file-upload";
import {
  FileDropzone,
  usePendingUploadSnackbar,
  useUploadSingleFile,
} from "@js/apps/file-upload";
import { getFileWithId } from "@js/apps/file-upload/helpers/file-upload";
import type { Attachment } from "@js/types/uploads";

import { UploadLoader } from "./upload-loader";
import { VideoCard } from "./video-card";

import styles from "./styles.module.scss";

type IntroVideoUploadProps = TypedWrappedFieldProps<{
  id: number;
  attachment: Attachment;
}>;

export const IntroVideoUpload = ({ input, meta }: IntroVideoUploadProps) => {
  const { onBeforeUpload, onAfterUpload } = usePendingUploadSnackbar({
    input,
    meta,
  });
  const onUpload = (arg: UploadedFile) => input.onChange(arg);
  const {
    uploadSingleFile,
    isUploading,
    error,
    resetFileState,
    cancelFileUpload,
    uploadingFileName,
  } = useUploadSingleFile({ onBeforeUpload, onUpload, onAfterUpload });
  const [fileUploadRejected, setfileUploadRejected] = useState<boolean>();

  const VIDEO_SIZE_LIMIT_MB =
    SETTINGS.JOB_INTRO_VIDEO_FILE_SIZE_LIMIT / 1024 / 1024;

  const onDrop = useCallback(
    (files: File[], rejectedFiles: FileRejection[]) => {
      setfileUploadRejected(!!rejectedFiles?.length);

      if (!!rejectedFiles?.length) {
        rejectedFiles.length = 0;
        return;
      }

      resetFileState();
      input.onChange(null);

      const [file] = files;
      if (!file) {
        return;
      }

      const fileWithId = getFileWithId(file);
      uploadSingleFile({
        file: fileWithId,
        uploadType: ENUMS.UploadType.JOB_INTRO_VIDEO_ATTACHMENT_UPLOAD_TYPE,
        maxSize: SETTINGS.JOB_INTRO_VIDEO_FILE_SIZE_LIMIT,
      });
    },
    [input, uploadSingleFile, resetFileState],
  );

  if (isUploading) {
    return (
      <UploadLoader
        uploadingFileName={uploadingFileName || ""}
        cancelFileUpload={() => {
          cancelFileUpload();
          resetFileState();
        }}
      />
    );
  }

  if (!!input?.value) {
    return (
      <>
        <Field
          name="intro_video"
          component={VideoCard}
          onReplaceVideo={onDrop}
          onDeleteVideo={() => {
            resetFileState();
            input.onChange(null);
          }}
        />
        {(error || fileUploadRejected) && <IntroVideoError />}
      </>
    );
  }

  return (
    <>
      <FileDropzone
        dropzoneOptions={{
          onDrop,
          multiple: false,
          accept: {
            "video/mp4": [],
            "video/quicktime": [],
          },
          maxSize: SETTINGS.JOB_INTRO_VIDEO_FILE_SIZE_LIMIT,
        }}
      >
        {({ isDragActive }) => (
          <Box
            className={`${styles.introVideoDropzone} ${isDragActive && styles.introVideoDropzoneActive}`}
          >
            <ArrowUpIcon className={styles.introVideoUploadIcon} />
            <Typography component="span" variant="label" size="small">
              Drag and drop video file or
            </Typography>
            <Button
              variant="white-grey"
              shape="squared"
              size="x-small"
              sx={{ borderColor: "var(--grey-4" }}
            >
              Select file
            </Button>
            <Typography
              component="span"
              sx={{ color: "var(--dark-beige)", fontSize: "12px !important" }}
            >
              Max size: {VIDEO_SIZE_LIMIT_MB}MB, Supported formats: MP4, MOV
            </Typography>
          </Box>
        )}
      </FileDropzone>

      {(error || fileUploadRejected) && <IntroVideoError />}
    </>
  );
};

const IntroVideoError = () => {
  const VIDEO_SIZE_LIMIT_MB =
    SETTINGS.JOB_INTRO_VIDEO_FILE_SIZE_LIMIT / 1024 / 1024;
  return (
    <Box
      sx={{
        gap: 1,
        background: "var(--soft-red)",
        padding: "12px 16px",
        borderRadius: "4px",
      }}
    >
      <Box display="flex" gap={1} alignItems="center">
        <ErrorSVGIcon
          style={{
            color: "var(--negative-2)",
            fontSize: 20,
          }}
        />
        <Typography
          component="span"
          size="small"
          fontWeight={400}
          color="negative"
        >
          Upload failed
        </Typography>
      </Box>
      <Typography component="p" size="small">
        Please ensure the video file is in MP4 or MOV format and does not exceed{" "}
        {VIDEO_SIZE_LIMIT_MB}MB in size.
      </Typography>
    </Box>
  );
};

import { useRef, useState } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { TextFieldProps } from "@braintrust/braintrust-ui-components";
import { Box } from "@braintrust/braintrust-ui-components";
import { EditPenIcon } from "@braintrust/braintrust-ui-components/Icons";
import { TextField } from "@js/forms/fields";

import styles from "./style.module.scss";

export const EditableTitleField = ({
  input,
  placeholder,
  meta,
  ...props
}: WrappedFieldProps & TextFieldProps) => {
  const [editMode, setEditMode] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const makeItEditable = () => setEditMode(true);
  const makeItUnEditable = () => setEditMode(false);

  return (
    <Box
      ref={wrapperRef}
      display="flex"
      alignItems="baseline"
      className={styles.titleContainer}
      onClick={makeItEditable}
      onFocus={makeItEditable}
      onBlur={makeItUnEditable}
    >
      <TextField
        size="large"
        inputTextSize="large"
        data-testid="job-title"
        placeholder={placeholder}
        input={input}
        inputProps={{
          readOnly: !editMode,
        }}
        hideBorder={!editMode}
        meta={meta}
        floatingLabel={false}
        variant="standard"
        {...props}
      />
      {!editMode && (
        <EditPenIcon
          className={styles.icon}
          style={{ color: "var(--grey-2)" }}
        />
      )}
    </Box>
  );
};

import { Field } from "redux-form";

import { Box } from "@braintrust/braintrust-ui-components";
import { CreatePoll } from "@js/apps/give-and-get-help/components/create-poll";
import { CreatePostLinkField } from "@js/apps/give-and-get-help/components/link-field";
import { useCreatePostOpenedFieldsContext } from "@js/apps/give-and-get-help/context";
import type { ReplyType } from "@js/apps/give-and-get-help/types";
import type { OptionWithPrimitiveValue } from "@js/types/common";

import { CommentsBudget } from "../comments-budget";
import { PostFilesUploadField } from "../post-files-upload-field";

export const CreatePostAttachmentFields = ({
  postBudget,
  disablePoll,
  type,
  isEditingPost,
}: {
  postBudget?: OptionWithPrimitiveValue[];
  disablePoll?: boolean;
  type?: ReplyType;
  isEditingPost?: boolean;
}) => {
  const { openedFields, toggleField } = useCreatePostOpenedFieldsContext();

  const shouldShowUploader =
    openedFields.includes("attachment_file") ||
    openedFields.includes("attachment_image");
  const onlyImages = openedFields.includes("attachment_image");

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Field
        name="attachments"
        component={PostFilesUploadField}
        shouldShowUploader={shouldShowUploader}
        onlyImages={onlyImages}
      />

      {openedFields.includes("link") && (
        <CreatePostLinkField
          toggleField={() => toggleField("link")}
          isEditing={isEditingPost}
          autoFocus
        />
      )}

      {openedFields.includes("poll") && (
        <CreatePoll disablePoll={disablePoll} isEditing={isEditingPost} />
      )}

      {openedFields.includes("offer") && (
        <Field
          name="budget"
          component={CommentsBudget}
          options={postBudget}
          type={type}
        />
      )}
    </Box>
  );
};

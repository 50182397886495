import { Field } from "redux-form";

import {
  Box,
  Button,
  Divider,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { TextareaField } from "@js/forms/fields";

import type { DeclineOfferData } from "../../types";

const DeclineOfferFormInstance = createFormInstance<DeclineOfferData, unknown>(
  "decline-offer-form",
);

type DeclineOfferFormProps = {
  onSubmit: ((values: DeclineOfferData) => Promise<unknown>) | (() => void);
  onSubmitSuccess: () => void;
};

export const DeclineOfferForm = ({
  onSubmit,
  onSubmitSuccess,
}: DeclineOfferFormProps) => {
  return (
    <DeclineOfferFormInstance
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
    >
      <Box>
        <Typography mb={3} component="h2" variant="label" size="medium">
          Decline offer
        </Typography>
        <Typography
          mb={1}
          component="h2"
          variant="title"
          size="medium"
          fontWeight={400}
        >
          Are you sure you want to decline this request?
        </Typography>
        <Typography component="p" mb={2}>
          You can give a reason for declining, but you don't have to.
        </Typography>
        <Box mb={2}>
          <Field
            id="decline-reason"
            name="decline_reason"
            variant="outlined"
            component={TextareaField}
          />
        </Box>
        <Box mb={2}>
          <Divider color="light-grey" />
        </Box>
        <Button
          variant="destructive"
          type="submit"
          shape="squared"
          size="medium"
          sx={{ float: "right" }}
        >
          Decline
        </Button>
      </Box>
    </DeclineOfferFormInstance>
  );
};

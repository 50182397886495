import { Button } from "@braintrust/braintrust-ui-components";

import { useCancelOfferButton } from "../../hooks/use-cancel-offer-button";

export const CancelOfferButton = ({
  offerId,
  jobId,
}: {
  offerId: number;
  jobId: number;
}) => {
  const { cancelOffer } = useCancelOfferButton({
    offerId,
    jobId,
  });

  return (
    <Button
      variant="destructive-outlined"
      shape="squared"
      size="medium"
      onClick={cancelOffer}
    >
      Cancel offer
    </Button>
  );
};

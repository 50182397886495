import { Box } from "@braintrust/braintrust-ui-components";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { JobBudgetWithPaymentTypeTooltip } from "@js/apps/jobs/components/job-budget-with-payment-type-tooltip";
import { JobItemShareAndBookmarkActionMenu } from "@js/apps/jobs/components/job-item/components/share-and-bookmark-action-menu";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemBudgetProps = {
  job: MainJobListingJob;
};

export const JobItemBudgetShareAndBookmarkSection = ({
  job,
}: JobItemBudgetProps) => {
  const isNodeStaff = useIsNodeStaff();
  return (
    <Box className={styles.wrapper} sx={{ mx: 1, mt: { xs: 1, sm: 0 }, mb: 1 }}>
      <JobBudgetWithPaymentTypeTooltip job={job} />
      {!isNodeStaff &&
        job.status_for_freelancer &&
        job.status_for_freelancer !==
          ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS && (
          <JobItemShareAndBookmarkActionMenu job={job} />
        )}
    </Box>
  );
};

import React from "react";
import cs from "classnames";

import { Box, Typography } from "@braintrust/braintrust-ui-components";

import style from "./style.module.scss";

const Colors = {
  green: "green",
  blue: "blue",
  red: "red",
  grey: "grey",
} as const;

type OfferStatusProps = {
  label: React.ReactNode;
  color: keyof typeof Colors;
  className?: string;
};

export const OfferStatusChip = ({
  label,
  color,
  className,
  ...props
}: OfferStatusProps) => {
  const chipColor = Colors[color];

  return (
    <Box
      className={cs(style.offerStatus, className, {
        [style[chipColor]]: !!color,
      })}
      {...props}
    >
      <span className={style.offerStatusDot} />
      <Typography component="span" variant="label" ellipsis>
        {label}
      </Typography>
    </Box>
  );
};

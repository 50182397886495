import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box, IconButton } from "@braintrust/braintrust-ui-components";
import { LocationWarningIcon } from "@braintrust/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

type LocationWarningProps = BoxProps & {
  onClick: () => void;
  children?: JSX.Element | string;
};

export const LocationWarning = ({
  onClick,
  children,
  ...props
}: LocationWarningProps) => {
  return (
    <Box className={styles.locationWarning} onClick={onClick} {...props}>
      <IconButton
        variant="soft-red"
        className={styles.locationWarningBtn}
        aria-label="Location warning"
      >
        <LocationWarningIcon viewBox="-3.5 0 20 20" />
      </IconButton>
      {children}
    </Box>
  );
};

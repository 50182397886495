import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { CalendarIcon } from "@braintrust/braintrust-ui-components/Icons";
import { Date } from "@js/components/date";
import type { IsoDateOrIsoDateTime } from "@js/utils/date/types";
import { DateFormats } from "@js/utils/date/types";

type JoinedInProps = { joinedIn: string };

export const JoinedIn = ({ joinedIn }: JoinedInProps) => {
  return (
    <Box sx={{ minWidth: "5rem" }}>
      <Typography
        component="span"
        variant="paragraph"
        size="small"
        color="grey-1"
      >
        <Box component="span" sx={{ lineHeight: 1, mr: 0.5 }}>
          <CalendarIcon
            sx={{ fontSize: "1rem", verticalAlign: "text-bottom" }}
          />
        </Box>
        Joined in{" "}
        <Date
          date={joinedIn as IsoDateOrIsoDateTime}
          format={DateFormats["January 1, 1970"]}
        />
      </Typography>
    </Box>
  );
};

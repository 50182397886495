import type { ReactNode } from "react";
import React from "react";

import { Stack } from "@braintrust/braintrust-ui-components";

type SearchViewHeaderContainerProps = {
  children: ReactNode;
};

export const SearchViewHeaderContainer = ({
  children,
}: SearchViewHeaderContainerProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: { xs: "wrap", lg: "nowrap" },
        columnGap: 2,
        rowGap: 1,
        mb: 1,
      }}
    >
      {children}
    </Stack>
  );
};

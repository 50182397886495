import React, { Component } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";

import {
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { RootState } from "@js/store";

import { setCurrentItemIndex as setCurrentItemIndexMethod } from "./actions";

import styles from "./style.module.scss";

/**
 * Usage:
 *
 * ModalSlider can be opened by dispatching `openModal` with its `id`.
 *
 * ```
 * <a onClick={() => dispatch(openModal('foo'))}>Open by dispatching `openModal`</a>
 *
 * <ModalSlider
 *     ref="modalSliderRef"
 *     id="foo"
 *     items={[
 *         <Item key="1" title="Foo" />,
 *         <Item key="2" title="Bar" />,
 *         <Item key="3" title="Baz" />,
 *     ]} />
 * ```
 */

type ModalSliderConnectedProps = ConnectedProps<typeof connector>;

type ModalSliderProps = {
  id: string;
  items: JSX.Element[];
};

class ModalSlider extends Component<
  ModalSliderProps & ModalSliderConnectedProps
> {
  static defaultProps = {
    id: undefined,
    items: [],
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
      this.onChangeSlide(e.key === "ArrowRight" ? 1 : -1);
    }
  };

  onChangeSlide(direction: 1 | -1) {
    const { currentIndex, setCurrentItemIndex } = this.props;
    const itemsLength = this.props.items.length;
    const newIndex = currentIndex + direction;

    if (newIndex >= 0 && newIndex < itemsLength) {
      setCurrentItemIndex(newIndex);
    }
  }

  get prevIcon() {
    if (this.props.items.length && this.props.currentIndex > 0) {
      return (
        <KeyboardArrowLeftIcon
          className={styles.modalSliderContentPrev}
          onClick={() => this.onChangeSlide(-1)}
        />
      );
    }

    return null;
  }

  get nextIcon() {
    if (
      this.props.items.length &&
      this.props.currentIndex < this.props.items.length - 1
    ) {
      return (
        <KeyboardArrowRightIcon
          className={styles.modalSliderContentNext}
          onClick={() => this.onChangeSlide(1)}
        />
      );
    }

    return null;
  }

  render() {
    const { items, currentIndex } = this.props;

    if (items) {
      return (
        <div className="text-content">
          {items[currentIndex]}
          {this.prevIcon}
          {this.nextIcon}
        </div>
      );
    }

    return null;
  }
}

const connector = connect(
  (state: RootState, ownProps: ModalSliderProps) => ({
    currentIndex: state.modalSlider.itemIndex[ownProps.id] || 0,
  }),
  (dispatch, ownProps) => ({
    setCurrentItemIndex: (index: number) =>
      dispatch(setCurrentItemIndexMethod(ownProps.id, index)),
  }),
  null,
  { forwardRef: true },
);

export default connector(ModalSlider);

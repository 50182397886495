import React from "react";

import { Tooltip } from "@braintrust/braintrust-ui-components";
import { InfoIcon } from "@braintrust/braintrust-ui-components/Icons";

export type InfoTooltipProps = {
  title: JSX.Element | string;
  size?: React.CSSProperties["fontSize"];
};

export const InfoTooltip = ({
  title,
  size = "clamp(18px, 130%, 22px)",
}: InfoTooltipProps) => {
  return (
    <Tooltip title={title} leaveTouchDelay={5000}>
      <InfoIcon
        style={{
          fontSize: size,
          verticalAlign: "-10%",
          marginLeft: 8,
          cursor: "pointer",
        }}
      />
    </Tooltip>
  );
};

import type { ReactNode } from "react";
import type { OptionsObject } from "notistack";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { TaskAltIcon } from "@braintrust/braintrust-ui-components/Icons";

import { Snackbar } from "../snackbar";

import styles from "./style.module.scss";

type DecoratedSuccessSnackbarContentProps = { children: ReactNode };

const DecoratedSuccessSnackbarContent = ({
  children,
}: DecoratedSuccessSnackbarContentProps) => {
  return (
    <Box className={styles.successSnackbar}>
      <TaskAltIcon />
      <Typography component="div" variant="paragraph" size="medium">
        {children}
      </Typography>
    </Box>
  );
};

const defaultOptions: OptionsObject = {
  anchorOrigin: { vertical: "bottom", horizontal: "right" },
  autoHideDuration: 3000,
};

export const openDecoratedSuccessSnackbar = (
  content: ReactNode,
  options: OptionsObject = defaultOptions,
) => {
  Snackbar.custom(
    <DecoratedSuccessSnackbarContent>
      {content}
    </DecoratedSuccessSnackbarContent>,
    options,
  );
};

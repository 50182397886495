import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { ArrowTopRightIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { BasicInfoProps } from "@js/apps/jobs/components";
import { BasicInfo } from "@js/apps/jobs/components/basic-info";
import { RouterLink } from "@js/components/link";
import { getPaymentAmountWithSuffix } from "@js/utils";

export const MyWorkCardBasicInfo = ({ job }: Pick<BasicInfoProps, "job">) => (
  <Box mt={1.25}>
    <BasicInfo job={job} />
  </Box>
);

type PaymentAmountProps = Partial<TypographyProps> & {
  paymentAmount: string;
  paymentType: EnumType<typeof ENUMS.JobPaymentType>;
};

export const PaymentAmount = ({
  paymentAmount,
  paymentType,
  ...props
}: PaymentAmountProps) => {
  return (
    <Typography component="p" variant="paragraph" size="large" {...props}>
      {getPaymentAmountWithSuffix(paymentAmount, paymentType, {
        amountOptions: { removeDecimal: true },
      })}
    </Typography>
  );
};

export const ViewJobButton = ({ jobId }: { jobId: number }) => (
  <Button
    variant="primary"
    to={`/jobs/${jobId}`}
    rel="noopener noreferrer"
    target="_blank"
    RouterLink={RouterLink}
    fullWidth={false}
    sx={{
      display: { xs: "none", sm: "flex" },
      flexShrink: 0,
      height: 40,
      width: "122px !important",
    }}
  >
    View job
    <ArrowTopRightIcon />
  </Button>
);

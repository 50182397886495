import React from "react";
import URI from "urijs";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ShareIcon } from "@braintrust/braintrust-ui-components/Icons";
import { CopyLinkBox } from "@js/apps/common/components";
import { Sanitize } from "@js/components/sanitize";
import { Snackbar } from "@js/components/snackbar";
import { useCopyToClipboard } from "@js/hooks";
import type { MainJobListingJob } from "@js/types/jobs";

import { ArcUnderline } from "../arc-underline";
import { ReferToJobButton } from "../refer-to-job";

type ShareThisJobCardProps = {
  job: MainJobListingJob;
  bgColor?: string;
  isEmbeddedView?: boolean;
};

const DEFAULT_BG_COLOR = "var(--soft-yellow)";
const BUTTON_BG_COLOR = "var(--medium-orange)";
const BUTTON_COLOR = "var(--dark-orange)";

export const ShareThisJobCard: React.FC<ShareThisJobCardProps> = ({
  job,
  bgColor = DEFAULT_BG_COLOR,
  isEmbeddedView,
}) => {
  const { handleCopy } = useCopyToClipboard(() =>
    Snackbar.success("URL successfully copied!"),
  );
  const referralLink = new URI(
    `${window.location.origin}/jobs/${job.id}`,
  ).toString();
  const isGrantType = job.job_type === ENUMS.JobType.GRANT;

  return (
    <Box
      sx={{
        borderRadius: "20px",
        backgroundColor: bgColor,
        padding: 3.5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          maxHeight: "40px",
        }}
      >
        <ShareIcon sx={{ fontSize: 26 }} />
        <Box component="h2" ml={2}>
          <Sanitize unescape input={SETTINGS.JOB_REFERRAL_HEADLINE_CARD} />
        </Box>
      </Box>
      <ArcUnderline />
      {isGrantType ? (
        <CopyLinkBox
          referralLink={referralLink}
          handleCopy={handleCopy}
          type="job"
          smallText
        />
      ) : (
        <Box lineHeight={3} margin={0}>
          <Sanitize unescape input={SETTINGS.JOB_REFERRAL_SUBTEXT} />
        </Box>
      )}
      {isGrantType ? (
        <Typography component="p" variant="paragraph" size="small">
          Grants are not eligible for BTRST referral bonuses at this time.
        </Typography>
      ) : (
        <ReferToJobButton
          job={job}
          style={{
            backgroundColor: BUTTON_BG_COLOR,
            color: BUTTON_COLOR,
          }}
          showDivider
          isEmbeddedView={isEmbeddedView}
        />
      )}
    </Box>
  );
};

import type { MouseEvent } from "react";

import {
  Box,
  Button,
  Tooltip,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  CheckIcon,
  PersonAddIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useInviteToBid } from "@js/apps/jobs/hooks";
import type { Talent } from "@js/types/freelancer";

import styles from "./style.module.scss";

type InviteToBidButtonProps = {
  jobId: number | undefined;
  freelancer: Talent;
  onClick: () => void;
};

export const InviteToBidButton = ({
  jobId,
  freelancer,
  onClick,
}: InviteToBidButtonProps) => {
  const {
    isInvited,
    loading,
    disabled,
    invite,
    canInviteAsStaff,
    canBeInvited,
    inviteAsStaff,
  } = useInviteToBid({ jobId, freelancer });

  if (!canInviteAsStaff && !canBeInvited && !isInvited) {
    return null;
  }

  const isInvitingToSpecificJob = !!jobId;

  const commonIsDisabled = disabled || loading;
  const isDisabled = isInvitingToSpecificJob
    ? commonIsDisabled || !!isInvited || !freelancer.approved
    : commonIsDisabled;

  const isInvitationForbidden = isInvitingToSpecificJob && !freelancer.approved;

  return (
    <Tooltip
      title="Unapproved talent can't be invited."
      disabled={!isInvitationForbidden}
    >
      <Box>
        <Typography component="div" size="small" fontWeight={500}>
          <Button
            variant={
              isInvitingToSpecificJob && isInvited ? "dark-violet" : "primary"
            }
            size="x-small"
            shape="squared"
            fullWidth
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
              onClick();

              if (!isInvitingToSpecificJob) {
                inviteAsStaff();
              } else {
                invite();
              }
            }}
            className={styles.inviteToBidButton}
            endIcon={
              isInvitingToSpecificJob && isInvited ? (
                <CheckIcon />
              ) : (
                <PersonAddIcon />
              )
            }
            disabled={isDisabled}
            disabledType="no-change"
          >
            {isInvitingToSpecificJob && isInvited ? (
              <>Invited!</>
            ) : loading ? (
              "Inviting..."
            ) : (
              "Invite"
            )}
          </Button>
        </Typography>
      </Box>
    </Tooltip>
  );
};

import { Chip } from "@braintrust/braintrust-ui-components";
import type { Skill } from "@js/types/admin";

type SelectableSkillItemProps = {
  skill: Pick<Skill, "id" | "name">;
  selected?: boolean;
  handleClick: (skillId: number) => void;
};

export const SelectableSkillItem = ({
  skill,
  handleClick,
  selected,
}: SelectableSkillItemProps) => {
  return (
    <Chip
      className="soft-violet-hover"
      role="checkbox"
      onClick={() => handleClick(skill.id)}
      color={selected ? "violet" : "grey-4"}
      label={skill.name}
      aria-checked={!!selected}
    />
  );
};

import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { FlowerIcon } from "@braintrust/braintrust-ui-components/Icons";

import style from "./style.module.scss";

type ActivityIconProps = {
  icon: JSX.Element;
  color: string;
};

export const ActivityIcon = ({ icon, color }: ActivityIconProps) => {
  return (
    <div className={style.activityIconWrapper}>
      <FlowerIcon
        style={{
          width: 48,
          height: 48,
          color: color,
        }}
      />

      <div className={style.activityIconWrapperIcon}>{icon}</div>
    </div>
  );
};

type ActivityItemProps = {
  icon: JSX.Element;
  color: string;
  activity: string;
  activitySubtext?: string;
};

export const ActivityItem = ({
  icon,
  color,
  activity,
  activitySubtext,
}: ActivityItemProps) => {
  return (
    <div className={style.activityItem}>
      <ActivityIcon icon={icon} color={color} />
      <div>
        <Typography component="p">{activity}</Typography>
        {activitySubtext && (
          <Typography component="p" size="small">
            {activitySubtext}
          </Typography>
        )}
      </div>
    </div>
  );
};

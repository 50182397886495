import React from "react";

import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { Button } from "@braintrust/braintrust-ui-components";
import { openProfileCompletionSequenceModal } from "@js/apps/freelancer/components/profile-completion-sequence/profile-completion-sequence";

type CompleteProfileButtonProps = Partial<ButtonProps>;

export const CompleteProfileButton = ({
  ...props
}: CompleteProfileButtonProps) => {
  return (
    <Button
      variant="primary"
      onClick={() => openProfileCompletionSequenceModal()}
      {...props}
    >
      Complete profile
    </Button>
  );
};

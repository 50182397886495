import { useCallback, useRef } from "react";
import { useSnackbar } from "notistack";

import { IconButton, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import {
  CircleCheckOutlineIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { setPostsToShowPulseAnimation } from "@js/apps/common/actions";
import { useUser } from "@js/apps/common/hooks";
import type { OptionalParams } from "@js/apps/common/services";
import { ShareLink } from "@js/apps/common/services";
import { actionTypes } from "@js/apps/give-and-get-help/action-types";
import { useTrackSharePostMutation } from "@js/apps/give-and-get-help/api";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector, useCopyToClipboard } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

import styles from "../style.module.scss";

type UseSharePostMenu = (args: { post: IPost; postUrl: string }) => {
  showPulseAnimation: boolean;
  isMobile: boolean;
  handleCopyPostLinkClick: () => void;
  handleSharePostButtonClick: () => void;
  getTitleForService: (service: string) => string;
  shareLinks: ReturnType<typeof ShareLink.createAllLinks>;
  handleTrackSharePost: (
    kind: EnumType<typeof ENUMS.SharePostEventKind>,
  ) => void;
};

export const useSharePostMenu: UseSharePostMenu = ({ post, postUrl }) => {
  const [trackSharePost] = useTrackSharePostMutation();
  const user = useUser();
  const showPulseAnimationForPosts = useAppSelector(
    (state) => state.common.showPulseAnimationForPosts,
  );
  const isMobile = useMediaQuery("sm");
  const { closeSnackbar } = useSnackbar();
  const onUrlCopied = useCallback(() => {
    const key = `${post.id}-${snackbarKey.current++}`;
    Snackbar.success("Link copied!", {
      content: (
        <div className={styles.snackbar}>
          <CircleCheckOutlineIcon />
          <Typography variant="paragraph" component="p">
            Link copied!
          </Typography>
          <IconButton
            aria-label="close snackbar"
            variant="transparent"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      key,
    });
  }, [closeSnackbar, post.id]);
  const { handleCopy: copyToClipboard } = useCopyToClipboard(onUrlCopied);
  const postLocation = usePostLocationContext();
  const dispatch = useAppDispatch();
  const snackbarKey = useRef(1);

  const shareLinks = ShareLink.createAllLinks(postUrl, {} as OptionalParams);
  const showPulseAnimation = showPulseAnimationForPosts.includes(post.id);

  const handleCopyPostLinkClick = () => {
    handleTrackSharePost(ENUMS.SharePostEventKind.COPY_LINK);
    dispatch({
      type: actionTypes["copy-clicked"],
      payload: {
        post,
        linkUrl: postUrl,
        entityType: "post",
        post_location: postLocation,
      },
    });
    copyToClipboard(postUrl);
  };

  const handleSharePostButtonClick = () =>
    dispatch(
      setPostsToShowPulseAnimation(
        showPulseAnimationForPosts.filter((postId) => postId !== post.id),
      ),
    );

  const getTitleForService = (service: string) => {
    return isMobile ? service : `Share on ${service}`;
  };

  const handleTrackSharePost = (
    kind: EnumType<typeof ENUMS.SharePostEventKind>,
  ) => {
    if (user) {
      trackSharePost({ id: post.id, kind });
    }
  };

  return {
    showPulseAnimation,
    isMobile,
    handleCopyPostLinkClick,
    shareLinks,
    handleSharePostButtonClick,
    getTitleForService,
    handleTrackSharePost,
  };
};

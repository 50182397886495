import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { insightsPurchaseModalClicked } from "@js/apps/jobs/actions";
import { INSIGHT_BTRST_COST } from "@js/apps/jobs/components/application-onramp-modal/constants";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

type PurchaseSummaryProps = {
  isPurchasing: boolean;
  onPurchase: () => void;
};

export const PurchaseSummary = ({
  isPurchasing,
  onPurchase,
}: PurchaseSummaryProps) => {
  const isTablet = useMediaQuery("lg");
  const dispatch = useAppDispatch();
  const handleButtonClick = () => {
    dispatch(insightsPurchaseModalClicked());
    onPurchase();
  };

  const breakDownItemProps: BoxProps = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  };

  return (
    <Box
      py={isTablet ? 0 : 4}
      flex={isTablet ? 0 : 1}
      display={isTablet ? "initial" : "flex"}
      justifyContent={isTablet ? "initial" : "center"}
      alignItems={isTablet ? "initial" : "center"}
    >
      <Box
        display="flex"
        gap={isTablet ? 2 : 3}
        maxWidth={isTablet ? "initial" : "361px"}
        flexDirection="column"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "var(--medium-green)",
            borderRadius: 2,
            height: "200px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "222px",
              height: "200px",
              position: "absolute",
              left: 0,
              background: `url(${`${SETTINGS.STATIC_URL}jobs/usd-to-btrst-bg.svg`})`,
            }}
          />
          <Box width="89%" maxWidth="309px" zIndex={1} display="flex">
            <img
              src={`${SETTINGS.STATIC_URL}jobs/insights-sample.png`}
              height="auto"
              width="100%"
              alt="insights-sample"
            />
          </Box>
        </Box>
        <Typography component="h3" size="large">
          Purchase Application Insights
        </Typography>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap={1}
          borderTop="1px solid var(--soft-beige)"
          borderBottom="1px solid var(--soft-beige)"
          paddingY={1}
        >
          <Box {...breakDownItemProps}>
            <Typography component="p" variant="paragraph" size="medium">
              30 days of Application Insights
            </Typography>
            <Typography component="p" variant="paragraph" size="medium">
              {INSIGHT_BTRST_COST} BTRST
            </Typography>
          </Box>
          <Box {...breakDownItemProps}>
            <Typography
              component="p"
              variant="label"
              size="medium"
              fontWeight={500}
            >
              Total
            </Typography>
            <Typography
              component="p"
              variant="label"
              size="medium"
              fontWeight={500}
            >
              {INSIGHT_BTRST_COST} BTRST
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            "& a": {
              color: "var(--link)",
              textDecoration: "none",
            },
          }}
        >
          <Typography component="p" variant="paragraph" size="small">
            By confirming payment you accept our{" "}
            <Typography
              component="a"
              size="small"
              href={SETTINGS.PRIVACY_DOC_URL}
              target="_blank"
              className="policy-field-link"
              rel="noreferrer"
              RouterLink={RouterLink}
            >
              Privacy Policy
            </Typography>
            , and{" "}
            <Typography
              component="a"
              size="small"
              href={SETTINGS.TERMS_DOC_URL}
              target="_blank"
              className="policy-field-link"
              rel="noreferrer"
              RouterLink={RouterLink}
            >
              Payments Terms & Conditions
            </Typography>
          </Typography>
        </Box>
        <Button
          disabled={isPurchasing}
          variant="positive-2"
          shape="squared"
          sx={{ padding: "15px 0 !important", fontWeight: "500 !important" }}
          onClick={handleButtonClick}
        >
          Purchase Application Insights
        </Button>
      </Box>
    </Box>
  );
};

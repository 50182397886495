import { Loader, Typography } from "@braintrust/braintrust-ui-components";

import { InvoicePageLayout } from "../../components/invoice-page-layout";
import { AddInvoiceForm } from "../../forms/add-invoice";
import { useEditInvoice } from "../../hooks/edit-invoice";

import { InvoiceEditTimer } from "./invoice-edit-timer";

export const EditInvoicePage = () => {
  const {
    enableReinitialize,
    onSubmit,
    onChange,
    onSubmitSuccess,
    initialValues,
    loading,
    canChangeJob,
    blockedUntil,
    existingAttachments,
  } = useEditInvoice();

  return (
    <>
      {blockedUntil && <InvoiceEditTimer timeToUnlock={blockedUntil} />}
      <InvoicePageLayout pageTitle="Edit Invoice">
        <Typography component="h1" variant="title" textTransform="uppercase">
          Edit Invoice
        </Typography>
        {loading || !initialValues ? (
          <Loader centered />
        ) : (
          <AddInvoiceForm
            enableReinitialize={enableReinitialize}
            isCopy={false}
            isEdit={true}
            canChangeJob={canChangeJob}
            onSubmitSuccess={onSubmitSuccess}
            onSubmit={onSubmit}
            onChange={onChange}
            existingAttachments={existingAttachments}
            initialValues={initialValues}
          />
        )}
      </InvoicePageLayout>
    </>
  );
};

import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useGetEmployerSearchItemQuery } from "@js/apps/employer/api";
import { EmployerLogo } from "@js/apps/employer/components/employer-logo";
import { JobItemCompanyLogo } from "@js/apps/jobs/components/job-item";
import { useIsImageBackgroundTransparent } from "@js/hooks";

import styles from "./style.module.scss";

export const PostJobAsBadge = ({
  input,
}: TypedWrappedFieldProps<number | null>) => {
  const selectedEmployerId = input.value;
  const { data: selectedEmployerData } = useGetEmployerSearchItemQuery(
    selectedEmployerId as number, // handled by skip
    {
      skip: !selectedEmployerId,
    },
  );

  const isTransparent = useIsImageBackgroundTransparent(
    selectedEmployerData ? selectedEmployerData?.logo_thumbnail : "",
  );

  return selectedEmployerData ? (
    <Box className={styles.postingJobAsBadge}>
      <Typography component="p" mb={1}>
        Posting job as:
      </Typography>
      {selectedEmployerData?.has_logo_set ? (
        <JobItemCompanyLogo
          logoThumbnail={selectedEmployerData?.logo}
          alt=""
          isTransparent={isTransparent}
        />
      ) : (
        <EmployerLogo
          employer={selectedEmployerData}
          size="small"
          variant="listing"
        />
      )}
    </Box>
  ) : null;
};

import React from "react";
import type { Dispatch } from "redux";
import { type DecoratedFormProps, Field } from "redux-form";

import { Typography } from "@braintrust/braintrust-ui-components";
import { recreateConfirmOTPAuth, recreateOTPAuth } from "@js/apps/auth/actions";
import { OTPAuthCodeField } from "@js/apps/common/forms/fields/otp/otp-auth-code";
import { EnableOTPContent } from "@js/apps/settings/components/otp-auth/enable-otp-modal/content";
import { Modal, ModalConfirm } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { createFormInstance } from "@js/forms/components";
import { CheckboxField } from "@js/forms/fields/checkbox";

import styles from "./styles.module.scss";

const ModalInstance = Modal("recreate-otp-auth-modal", {
  className: styles.modal,
  closeButton: false,
  keepOnBackdropClick: true,
});

type RecreateOTPAuthFormValue = {
  code: string;
  is_backup_code: boolean;
};

const FormInstance = createFormInstance<RecreateOTPAuthFormValue, unknown>(
  "recreate-otp-auth-form",
);

export const openRecreateOTPAuthModal = () => ModalInstance.open();

export const RecreateOTPAuthForm = () => {
  const [OTPAuthURI, setOTPAuthURI] = React.useState("");
  const [OTPSecret, setOTPSecret] = React.useState("");

  const clearState = () => {
    setOTPAuthURI("");
    setOTPSecret("");
  };

  const onCancel = () => {
    clearState();
    ModalInstance.close();
  };

  const onRecreateSubmit = (
    values: RecreateOTPAuthFormValue,
    _: Dispatch<any>,
    props: DecoratedFormProps<RecreateOTPAuthFormValue>,
  ) => {
    return recreateOTPAuth(values).then((data) => {
      if (!!props.reset) {
        props.reset();
      }
      setOTPAuthURI(data.otp_auth_uri);
      setOTPSecret(data.otp_secret);
    });
  };

  const handleOTPSecretError = () => {
    Snackbar.error("Failed, please try again");
    clearState();
    ModalInstance.close();
  };

  const onRecreateConfirmSubmit = async (values: RecreateOTPAuthFormValue) => {
    try {
      await recreateConfirmOTPAuth({
        code: values["code"],
        otp_secret: OTPSecret,
      });
      Snackbar.success("2-Step Authentication changed.");
      clearState();
      ModalInstance.close();
    } catch (_e) {
      handleOTPSecretError();
    }
  };

  return (
    <FormInstance
      onSubmit={OTPSecret ? onRecreateConfirmSubmit : onRecreateSubmit}
    >
      <Typography
        component="h3"
        variant="title"
        fontWeight={400}
        size="small"
        className="mt0 mb+"
      >
        Change 2-Step Authentication App
      </Typography>
      <ModalConfirm
        onCancel={onCancel}
        onConfirm="submit"
        confirmText="Change"
        buttonsOnEnds
        confirmButtonVariant="positive-2"
        buttonsSquareShape
      >
        {OTPSecret ? (
          <EnableOTPContent
            OTPAuthURI={OTPAuthURI}
            OTPAuthSecret={OTPSecret}
            renderPasswordField={false}
          />
        ) : (
          <>
            Enter the 6-digit code generated by your <strong>current</strong>{" "}
            Authenticator app.
            <OTPAuthCodeField />
            <Field
              name="is_backup_code"
              component={CheckboxField}
              label="Use backup code instead"
            />
          </>
        )}
      </ModalConfirm>
    </FormInstance>
  );
};

const RecreateOTPAuthModal = () => {
  return (
    <ModalInstance>
      <RecreateOTPAuthForm />
    </ModalInstance>
  );
};

export default RecreateOTPAuthModal;

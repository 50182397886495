import { Field } from "redux-form";

import { Box, Grid, Typography } from "@braintrust/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { SelectField, TextField } from "@js/forms/fields";
import { countrySelectOptions } from "@js/utils";

import type { AcceptJobOfferAddress } from "../../types";

type OwnProps = {
  disableAll: boolean;
  countryAsTextField?: boolean;
};

export type AcceptOfferFormData = {
  address: AcceptJobOfferAddress | null;
};

export const EquipmentShippingAddressFormInstance = createFormInstance<
  AcceptOfferFormData,
  unknown
>("equipment-shipping-address");

export const EquipmentShippingAddressFields = ({
  disableAll,
  countryAsTextField,
}: OwnProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} pb={2}>
          <Grid item xs={12} sm={6}>
            <Field
              disabled={disableAll}
              name="address.addressee_first_name"
              id="address.addressee_first_name"
              size="small"
              component={TextField}
              label="First name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              disabled={disableAll}
              name="address.addressee_last_name"
              id="address.addressee_last_name"
              size="small"
              component={TextField}
              label="Last name"
            />
          </Grid>
        </Grid>
        <Field
          disabled={disableAll}
          name="address.address1"
          id="address.address1"
          size="small"
          component={TextField}
          label="Address line 1"
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Field
            disabled={disableAll}
            name="address.address2"
            id="address.address2"
            size="small"
            component={TextField}
            label={<CustomLabel name="Apt / Suite / Other" label="Optional" />}
          />
        </Box>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          disabled={disableAll}
          name="address.city"
          id="address.city"
          size="small"
          component={TextField}
          label="City"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          disabled={disableAll}
          name="address.zipcode"
          id="address.zipcode"
          size="small"
          component={TextField}
          label="ZIP Code"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        {countryAsTextField ? (
          <Field
            disabled={disableAll}
            name="address.country_name"
            id="address.country_name"
            size="small"
            component={TextField}
            label="Country"
          />
        ) : (
          <Field
            disabled={disableAll}
            name="address.country"
            id="address.country"
            size="small"
            component={SelectField}
            options={countrySelectOptions}
            label="Country"
          />
        )}
      </Grid>
    </Grid>
  );
};

type CustomLabelProps = {
  name: string;
  label: string;
};

export const CustomLabel = ({ name, label }: CustomLabelProps) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      alignItems={{ xs: "flex-start", sm: "center" }}
    >
      <Typography
        mt={4}
        mb={{ xs: 0, sm: 3 }}
        component="p"
        variant="label"
        size="small"
      >
        {name}
      </Typography>
      <Typography
        component="p"
        size="small"
        color="grey"
        mb={{ xs: 2, sm: 0 }}
        ml={{ xs: 0, sm: 2 }}
        mt={{ xs: 0, sm: 1.4 }}
      >
        {label}
      </Typography>
    </Box>
  );
};

import { Button } from "@braintrust/braintrust-ui-components";
import {
  CopyIcon,
  LinkedinSVGIcon,
  MessageIcon,
  XIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { ReferType } from "@js/apps/dashboard/types";
import { RouterLink } from "@js/components/link";

import type { AllLinks } from "../../services";

import { useReferSocialButtons } from "./use-refer-social-buttons";

import styles from "../refer-modal-content/styles.module.scss";

type ReferSocialsButtonsProps = {
  shareLinks: AllLinks;
  hideShareLink?: boolean;
  handleCopy: (text: string) => void;
  referralLink: string;
  type: ReferType;
};

export const ReferSocialsButtons = ({
  shareLinks,
  hideShareLink = false,
  referralLink,
  type,
  handleCopy,
}: ReferSocialsButtonsProps) => {
  const {
    handleEmailClick,
    handleLinkedinClick,
    handleTwitterClick,
    handleCopyLinkClick,
  } = useReferSocialButtons({ type, referralLink, handleCopy });

  return (
    <div className={styles.referModalBtnSocials}>
      <Button
        variant="secondary"
        size="small"
        startIcon={<MessageIcon style={{ fontSize: "24px" }} />}
        href={shareLinks.email}
        onClick={handleEmailClick}
        target="_blank"
        rel="noopener noreferrer"
        RouterLink={RouterLink}
      >
        Email
      </Button>
      <Button
        variant="secondary"
        size="small"
        startIcon={<LinkedinSVGIcon style={{ fontSize: "24px" }} />}
        href={shareLinks.linkedIn}
        onClick={handleLinkedinClick}
        target="_blank"
        rel="noopener noreferrer"
        RouterLink={RouterLink}
      >
        LinkedIn
      </Button>
      <Button
        variant="secondary"
        size="small"
        startIcon={
          <XIcon style={{ fontSize: "24px" }} viewBox="-150 -150 1560 1595" />
        }
        href={shareLinks.twitter}
        onClick={handleTwitterClick}
        target="_blank"
        rel="noopener noreferrer"
        RouterLink={RouterLink}
      >
        X
      </Button>
      {hideShareLink && (
        <Button
          variant="secondary"
          size="small"
          startIcon={<CopyIcon />}
          onClick={handleCopyLinkClick}
          className={styles.referModalBtn}
        >
          Copy Link
        </Button>
      )}
    </div>
  );
};

import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export const W8FormGuidance = () => (
  <Typography component="span" mt={1}>
    All workers outside the U.S. must provide a W8 form. If you need help
    determining&nbsp; which form is right for you, please contact a local tax
    professional, and read&nbsp; more about forms&nbsp;
    <Typography
      component="a"
      href="https://www.irs.gov/forms-pubs/about-form-w-8-ben"
      target="_blank"
      rel="noopener noreferrer"
      RouterLink={RouterLink}
    >
      W8
    </Typography>
    &nbsp;and&nbsp;
    <Typography
      component="a"
      href="https://www.irs.gov/forms-pubs/about-form-w-8-ben-e"
      target="_blank"
      rel="noopener noreferrer"
      RouterLink={RouterLink}
    >
      W8-BEN-E
    </Typography>
    .&nbsp; In general, foreign individuals who receive payments from Braintrust
    should use&nbsp; form W8, and Form W-8BEN-E should be submitted by foreign
    companies that receive&nbsp; payments from Braintrust.
    <Typography
      component="a"
      display="block"
      href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf"
      target="_blank"
      rel="noopener noreferrer"
      RouterLink={RouterLink}
    >
      Download a W8 (Individuals)
    </Typography>
    <Typography
      component="a"
      display="block"
      href="https://www.irs.gov/pub/irs-pdf/fw8bene.pdf"
      target="_blank"
      rel="noopener noreferrer"
      RouterLink={RouterLink}
    >
      Download a W8-BEN-E (Entities)
    </Typography>
  </Typography>
);

import { Box } from "@braintrust/braintrust-ui-components";
import { HeaderPortal } from "@js/apps/jobs/components/header-portal";

import type { HeaderButtonProps } from "./button";
import { HeaderButton } from "./button";
import { VisibilitySettingsPopover } from "./popover";

export type CreateJobHeaderPortalProps = HeaderButtonProps & {
  displaySettings?: boolean;
  rightSocket?: JSX.Element;
  onClick?: () => void;
  leftSocket?: JSX.Element;
};

export const CreateJobHeaderPortal = ({
  backLink,
  isEdit,
  rightSocket,
  displaySettings,
  onClick,
  leftSocket,
}: CreateJobHeaderPortalProps) => {
  return (
    <HeaderPortal
      rightSocket={rightSocket}
      leftSocket={
        <Box display="inline-flex" gap="8px" sx={{ flexWrap: "wrap" }}>
          <HeaderButton isEdit={isEdit} backLink={backLink} onClick={onClick} />
          {displaySettings && <VisibilitySettingsPopover />}
          {leftSocket}
        </Box>
      }
    />
  );
};

import { IconButton, Menu } from "@braintrust/braintrust-ui-components";
import { MoreVertIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";

import type { ActionType } from "./use-get-actions";
import { useGetActions } from "./use-get-actions";

export const ActionMenu = ({ areNotificationsTurnOn, isAdmin }: ActionType) => {
  const user = useUser();
  const actions = useGetActions({
    areNotificationsTurnOn,
    isAdmin,
    userId: user?.id,
  });

  return (
    <Menu
      anchor={
        <IconButton
          aria-label="more actions"
          variant="transparent"
          shape="squared"
        >
          <MoreVertIcon />
        </IconButton>
      }
    >
      {actions.map((action) => {
        const { label, onClick } = action;
        return (
          <Menu.Item key={label} onClick={onClick}>
            {label}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

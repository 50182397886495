import { Box, Stack, Typography } from "@braintrust/braintrust-ui-components";

export const UploadCsvSuccess = () => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Box
        width={64}
        height={64}
        mb="12px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        bgcolor="var(--medium-violet)"
        borderRadius="50%"
      >
        <Typography component="p" variant="title" size="medium">
          🙌
        </Typography>
      </Box>
      <Typography component="p" variant="label" size="large" fontWeight={500}>
        Thanks for submitting!
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        size="medium"
        textAlign="center"
      >
        We'll email you a list of which connections will be the best referrals
        within 1 business day. As a reminder, we'll never reach out to your
        Connections without your permission!
      </Typography>
    </Stack>
  );
};

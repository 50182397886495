import React from "react";

import {
  Button,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";

type ReferralsStatsListResultsCountProps = {
  fetchMore: () => void;
  count: number;
  total: number;
  hasMore: boolean;
  loading: boolean;
};

export const ReferralsStatsListResultsCount = ({
  fetchMore,
  count,
  total,
  hasMore,
  loading,
}: ReferralsStatsListResultsCountProps) => {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography component="span" variant="label" size="medium">
        Showing {count} of {total}
      </Typography>

      {hasMore && (
        <Button
          style={{
            fontWeight: "400",
            fontSize: "16px",
            textDecoration: "underline",
          }}
          disabled={loading}
          onClick={fetchMore}
          variant="transparent"
        >
          Load more
        </Button>
      )}
    </Stack>
  );
};

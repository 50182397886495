import { z } from "zod";

import type { AccountInfoFormData } from "@js/apps/freelancer/forms/account-info";
import type { FreelancerExternalProfile } from "@js/types/common";

type PreparedExternalProfileData = {
  new_site: number;
  public_url: string;
};
export const prepareUpdateFreelancerCustomizationExternalProfilesValue = (
  values: AccountInfoFormData,
) => {
  const externalProfiles = values?.external_profiles;

  if (!externalProfiles) return;

  const mappedProfiles = externalProfiles.map((profile) => {
    if (!profile.public_url) {
      return null;
    }

    return {
      public_url: profile.public_url,
      new_site: profile.id,
    };
  });

  return mappedProfiles.filter(
    (externalProfile): externalProfile is PreparedExternalProfileData =>
      Boolean(externalProfile),
  );
};

export type FreelancerExternalProfileRequiredForError = Pick<
  FreelancerExternalProfile,
  "id"
>;

const errorSchema = z
  .object({ external_profiles: z.array(z.unknown()).optional() })
  .passthrough();

export const prepareExternalProfilesError = ({
  externalProfiles,
  preparedFormValue,
  errorData,
}: {
  externalProfiles: FreelancerExternalProfileRequiredForError[] | undefined;
  preparedFormValue: PreparedExternalProfileData[] | undefined;
  errorData: unknown;
}) => {
  if (!externalProfiles || !preparedFormValue) {
    return errorData;
  }

  const parseResult = errorSchema.safeParse(errorData);
  if (!parseResult.success) {
    return errorData;
  }

  const externalProfilesError = parseResult.data.external_profiles;
  if (!externalProfilesError) {
    return errorData;
  }

  const preparedError = externalProfiles.map((profileData) => {
    const submittedValueIndex = preparedFormValue.findIndex(
      (value) => value.new_site === profileData.id,
    );
    if (submittedValueIndex === -1) {
      return {};
    }
    const errorAtSubmittedValueIndex =
      externalProfilesError[submittedValueIndex];

    return errorAtSubmittedValueIndex || {};
  });

  return { ...parseResult.data, external_profiles: preparedError };
};

import React from "react";

import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import type { EmployerReferralType } from "@js/types/dashboard";
import { formatCurrency } from "@js/utils/currency";

import { ReferralTokenBalance } from "../referral-stats";

import styles from "./employer-card-list.module.scss";

const EmployerReferralCardBase = ({
  referral,
  isCompact,
}: {
  referral: EmployerReferralType;
  isCompact?: boolean;
}) => {
  const employerLink = `/employers/${referral.id}`;

  return (
    <Stack
      direction="row"
      gap={1.5}
      minWidth={0}
      sx={{ alignItems: { xs: "start", sm: "center" } }}
    >
      <RouterLink to={employerLink}>
        <Box
          height={50}
          width={50}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            borderRadius: "8px",
            backgroundColor: `var(${referral.logo_background_color || "--off-white"})`,
          }}
        >
          <Avatar
            data-testid={`client-avatar-${referral.id}`}
            src={{
              has_avatar_set: referral.has_logo_set,
              avatar_thumbnail: referral.logo_thumbnail,
              gravatar: "",
              first_name: referral.name,
              last_name: null,
            }}
            size="sm"
            color="medium-violet"
            textSize="small"
            className={styles.avatar}
          />
        </Box>
      </RouterLink>
      <Stack direction="column" className="display-ellipsis">
        <Stack direction="row" alignItems="center">
          <Typography
            component="link"
            variant="label"
            noWrap
            noTextDecoration
            RouterLink={RouterLink}
            href={employerLink}
            size={isCompact ? "medium" : "large"}
          >
            {referral.name}
          </Typography>
        </Stack>
        <Stack direction={isCompact ? "column" : "row"} gap={1} flexWrap="wrap">
          <Stack
            direction="row"
            divider={<Box px={0.5}>·</Box>}
            overflow="hidden"
            maxWidth="100%"
          >
            <Typography
              component="p"
              variant="paragraph"
              size="medium"
              color="grey-1"
              flexShrink={0}
            >
              Client since {referral.created}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const EmployerReferralCardDetails = ({
  referral,
  isCompact,
}: {
  referral: EmployerReferralType;
  isDesktop?: boolean;
  isCompact?: boolean;
}) => {
  const statVariant = isCompact ? "paragraph" : "title";
  const statSize = isCompact ? "large" : "small";
  const tokenSize = isCompact ? "medium" : "large";
  const showLabels = isCompact;

  const employerTotalBilled = formatCurrency(referral.total_billed ?? 0, {
    removeDecimal: true,
  });

  return (
    <Grid
      container
      spacing={showLabels ? 2.5 : 2}
      sx={{ alignItems: "center", justifyContent: "end" }}
    >
      <Grid item xs={2.5} lg sx={{ maxWidth: { lg: "130px" } }}>
        <Stack direction="column">
          {showLabels && (
            <Typography
              component="span"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              Jobs
            </Typography>
          )}
          <Typography
            component="span"
            variant={statVariant}
            size={statSize}
            fontWeight={400}
          >
            {referral.total_jobs}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2.5} lg sx={{ maxWidth: { lg: "130px" } }}>
        <Stack direction="column">
          {showLabels && (
            <Typography
              component="span"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              Hires
            </Typography>
          )}
          <Typography
            component="span"
            variant={statVariant}
            size={statSize}
            fontWeight={400}
          >
            {referral.total_talent_hired_count}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={7} lg="auto" sx={{ width: { lg: "300px" } }}>
        <Stack sx={{ pr: { lg: 1 } }}>
          {showLabels && (
            <Typography
              component="span"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              Billings
            </Typography>
          )}
          <Stack direction="row" gap={1} flexWrap="wrap" alignItems="center">
            <Typography
              component="span"
              variant={statVariant}
              size={statSize}
              fontWeight={400}
            >
              {employerTotalBilled}
            </Typography>
            <ReferralTokenBalance
              colorVariant="violet"
              tokenSize={tokenSize}
              tokenBalance={referral.total_earned_from_referral}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const EmployerCardList = ({
  referrals,
}: {
  referrals: EmployerReferralType[];
}) => {
  const isNotDesktop = useMediaQuery("lg");

  return (
    <List
      sx={{
        mb: "20px",
        gap: 2,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
    >
      {referrals.map((referral) => {
        return (
          <Box
            component="li"
            key={referral.id}
            sx={{
              bgcolor: "var(--white)",
              borderRadius: 2,
              py: 2,
              px: { xs: 2, lg: 0 },
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={12} lg={6} sx={{ pl: { lg: 2 } }}>
                <EmployerReferralCardBase
                  referral={referral}
                  isCompact={isNotDesktop}
                />
              </Grid>
              {isNotDesktop && (
                <Divider
                  color="beige"
                  sx={{
                    mt: 2,
                    mb: 1,
                  }}
                />
              )}
              <Grid item xs={12} lg={6}>
                <EmployerReferralCardDetails
                  referral={referral}
                  isCompact={isNotDesktop}
                />
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </List>
  );
};

import { useEffect, useMemo, useState } from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { ComboBoxProps } from "@braintrust/braintrust-ui-components";
import { TextField } from "@braintrust/braintrust-ui-components";
import { ComboBox } from "@braintrust/braintrust-ui-components";
import type { EmployerSearchMinimalResult } from "@js/apps/employer/api";
import {
  useGetEmployerSearchItemQuery,
  useGetEmployersSearchQuery,
} from "@js/apps/employer/api";
import { useSearchPhrase } from "@js/components/autocomplete-new/hooks/search-phrase";
import { useSynchronizeSingleValueInternalStateWithFormValue } from "@js/components/autocomplete-new/hooks/synchronize-with-form-value";

type RhfEmployerSearchComboboxFieldProps<T extends FieldValues> =
  UseControllerProps<T> &
    Partial<
      Omit<
        ComboBoxProps<
          EmployerSearchMinimalResult & { isInitialItem?: boolean },
          false
        >,
        "component"
      >
    > & {
      label: string | undefined;
      id: string;
      control: Control<T>;
      helperText?: string;
    };

export const RhfEmployerSearchComboboxField = <T extends FieldValues>({
  label,
  helperText,
  ...props
}: RhfEmployerSearchComboboxFieldProps<T>) => {
  const { field, fieldState, formState } = useController(props);
  const [value, setValue] = useState<EmployerSearchMinimalResult | null>(null);
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: users } = useGetEmployersSearchQuery({
    search: searchPhrase,
  });

  const defaultValue = formState.defaultValues
    ? formState.defaultValues[props.name]
    : undefined;

  const skip = fieldState.isDirty || !defaultValue || !!value; // "dirty" is cleared with every search so we need to rely on "value" too
  const { data: initialItem, isLoading: initialTaxonomyLoading } =
    useGetEmployerSearchItemQuery(Number(defaultValue), {
      skip,
    });

  const options = useMemo(() => {
    // include initialItem in options to avoid MUI warning related to isOptionEqualToValue
    if (initialItem) {
      return [...(users || []), { ...initialItem, isInitialItem: true }];
    }

    return users || [];
  }, [users, initialItem]);

  useSynchronizeSingleValueInternalStateWithFormValue({
    initialTaxonomyLoading,
    formValue: field.value,
    isValueEqualFormValue: field.value === value?.id,
    findOption: (option) => {
      return option.id === field.value;
    },
    setValue,
    options,
  });

  useEffect(() => {
    if (initialItem) {
      setValue(initialItem);
    }
  }, [initialItem]);

  return (
    <ComboBox<EmployerSearchMinimalResult & { isInitialItem?: boolean }, false>
      initialTaxonomiesLoading={initialTaxonomyLoading}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            error={fieldState.invalid}
            helperText={fieldState.error?.message || helperText}
          />
        );
      }}
      value={value || null}
      onInputChange={onInputChange}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        field.onChange(valueArg?.id || undefined);
      }}
      filterOptions={(optionsArg) => {
        return optionsArg.filter((opt) => !opt.isInitialItem);
      }}
      onBlur={field.onBlur}
      disabled={field.disabled}
      getOptionLabel={(option) => option.name}
      {...props}
    />
  );
};

import { useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Loader,
  ProgressBarStepper,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { AuthLayout } from "@js/apps/auth/views/layout/sign-in-layout";
import { InvitationDetails } from "@js/apps/byot/components/invitation-details";
import { useUser } from "@js/apps/common/hooks";
import { useGetFreelancerJobOffersQuery } from "@js/apps/freelancer/api";
import { useAcceptJobOfferMutation } from "@js/apps/jobs/apps/offers/api";
import { OfferDetailsForBYOTSignUp } from "@js/apps/jobs/apps/offers/components";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useNavigate } from "@js/hooks";
import type { FreelancerOffer, JobCreator } from "@js/types/jobs";

export const SignUpBYOTAcceptOffer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [acceptOffer] = useAcceptJobOfferMutation();
  const { data, isLoading } = useGetFreelancerJobOffersQuery({
    section: ENUMS.TalentOffersSectionFilter.CURRENT,
    order_by: "id",
  });

  const newestOffer = data ? data[data.length - 1] : null;
  const hiringManager = newestOffer ? newestOffer.job.creator : null;

  const onAcceptOfferClick = async () => {
    if (!newestOffer) {
      return;
    }

    try {
      setIsSubmitting(true);
      await acceptOffer({
        id: newestOffer?.id,
        checksum: newestOffer?.checksum,
        address: null,
      }).unwrap();
      await dispatch(fetchCurrentUser());
      navigate("/sign_up_byo_talent/congrats", {
        state: { allowAccessByotCongratsPage: true },
      });
    } catch (error: any) {
      Snackbar.error(error.data._error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout
      noLogoLink
      rightSocket={
        <Box
          p="64px"
          maxWidth="500px"
          bgcolor="var(--soft-blue)"
          whiteSpace="pre-wrap"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography component="h2" variant="label" size="large">
            Message from employer
          </Typography>
          <Typography component="p" size="small" mb={3} color="grey-1">
            You can always come back to this message later on.
          </Typography>
          <NoteToTalentAndHiringManagerTile newestOffer={newestOffer} />
        </Box>
      }
    >
      <Box maxWidth={672} width={1}>
        <Box mb={7} px={2}>
          {!!hiringManager && (
            <InvitationDetails invitationDetails={hiringManager} />
          )}
        </Box>
        <Box p={2}>
          <Box display="flex" flexDirection="column">
            <Content
              isLoading={isLoading}
              isSubmitting={isSubmitting}
              newestOffer={newestOffer}
              onAcceptOfferClick={onAcceptOfferClick}
            />
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

const NoteToTalentAndHiringManagerTile = ({
  newestOffer,
}: {
  newestOffer: FreelancerOffer | null;
}) => {
  if (!newestOffer) {
    return null;
  }

  const hiringManager = newestOffer.job.creator;

  return (
    <>
      {newestOffer.note_to_talent}
      <Box mt={3} display="flex">
        <Box mr={3}>
          <Avatar size="md" src={hiringManager} />
        </Box>
        <Box>
          <Typography component="p" variant="label">
            {hiringManager.first_name}, {newestOffer.job.employer.name}
          </Typography>
          <Typography component="p" size="small" mb={3} color="grey-1">
            Hiring manager
          </Typography>
        </Box>
      </Box>
    </>
  );
};

type ContentProps = {
  newestOffer: FreelancerOffer | null;
  isLoading: boolean;
  onAcceptOfferClick: () => void;
  isSubmitting: boolean;
};

const Content = ({
  newestOffer,
  isLoading,
  onAcceptOfferClick,
  isSubmitting,
}: ContentProps) => {
  if (!newestOffer || isLoading) {
    return <Loader />;
  }

  const hiringManager = newestOffer.job.creator;

  return (
    <>
      <Box mb="12px">
        <Typography component="p">Review offer</Typography>
        <Typography
          component="h1"
          variant="title"
          size="small"
          fontWeight={400}
        >
          Offer details for {newestOffer.job_title}
        </Typography>
      </Box>
      <OfferDetailsForBYOTSignUp offer={newestOffer} />
      <Box mt={8} />
      <Button
        variant="primary"
        type="submit"
        size="medium"
        onClick={onAcceptOfferClick}
        disabled={isSubmitting}
      >
        Accept offer
      </Button>
      <Box mt={2} display="flex" justifyContent="center">
        <MessageHiringManager hiringManager={hiringManager} />
      </Box>
      <Box mb={9} />
      <ProgressBarStepper activeStep={1} stepsLength={3} />
    </>
  );
};

const MessageHiringManager = ({
  hiringManager,
}: {
  hiringManager: JobCreator;
}) => {
  const user = useUser();
  const { openMessengerModal } = useOpenMessengerModal();

  return (
    <Typography component="p" size="small">
      Anything wrong with the offer?{" "}
      <Box
        component="span"
        sx={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => {
          if (user) {
            openMessengerModal({
              context: {
                participants: [user.id, hiringManager.id],
              },
            });
          }
        }}
      >
        Message {hiringManager.first_name}
      </Box>
    </Typography>
  );
};

import { Stack } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { CannotMakeAction } from "@js/apps/jobs/components/cannot-make-action";
import { InviteToJobButton } from "@js/apps/jobs/components/invite-to-job-button";
import { ViewProfileButton } from "@js/apps/jobs/components/view-profile-button";
import type { Job, TalentInviteSuggestion } from "@js/types/jobs";

import type { MatchSummaryUserWithBadgeProps } from "../match-summary";
import {
  MatchSummaryList,
  MatchSummaryListContainer,
  MatchSummaryUserWithBadge,
} from "../match-summary";

export type InviteTalentMatchSummaryProps = {
  freelancer: TalentInviteSuggestion;
  job: Job;
  closeSummary: () => void;
  notificationHandler: () => void;
  userWithBadgeContainerProps?: MatchSummaryUserWithBadgeProps["containerProps"];
};

export const InviteTalentMatchSummary = ({
  freelancer,
  job,
  notificationHandler,
  closeSummary,
  userWithBadgeContainerProps,
}: InviteTalentMatchSummaryProps) => {
  const isMobileOrTablet = useMediaQuery("md");
  const showInviteButton = !freelancer.invite_status;

  return (
    <Stack sx={{ height: "100%", minWidth: 0 }}>
      <MatchSummaryUserWithBadge
        matchLabel={freelancer.match_label}
        freelancerUser={freelancer.user}
        containerProps={userWithBadgeContainerProps}
      />
      <MatchSummaryListContainer>
        <MatchSummaryList matchSummary={freelancer.match_summary} />
      </MatchSummaryListContainer>
      <Stack
        direction="row"
        sx={{ gap: 1, justifyContent: { xs: "end", md: "start" } }}
      >
        <ViewProfileButton
          profileLink={freelancer.link}
          onClick={closeSummary}
          size={isMobileOrTablet ? "small" : "x-small"}
        />
        {showInviteButton && (
          <CannotMakeAction job={job}>
            <InviteToJobButton
              freelancer={freelancer}
              jobId={job.id}
              disabled={!job.is_open}
              notificationHandler={notificationHandler}
              onInvite={closeSummary}
              size={isMobileOrTablet ? "small" : "x-small"}
            />
          </CannotMakeAction>
        )}
      </Stack>
    </Stack>
  );
};

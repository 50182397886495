import { Box } from "@braintrust/braintrust-ui-components";

import { Avatar } from "../avatar";

import style from "./style.module.scss";

export const SpacesNavLabel = () => {
  return (
    <Box
      width={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      Spaces
    </Box>
  );
};

type SpacesSubNavLabelProps = {
  spaceName: string;
  notificationCounter: number;
  avatar?: string;
};

export const SpacesSubNavLabel = ({
  spaceName,
  notificationCounter,
  avatar,
}: SpacesSubNavLabelProps) => {
  return (
    <Box className={style.spacesSubNavLabel} title={spaceName}>
      <SpacesSubNavIcon
        notificationCounter={notificationCounter}
        avatar={avatar}
      />
      <span className={style.spacesSubNavLabelName}>{spaceName}</span>
    </Box>
  );
};

const SpacesSubNavIcon = ({
  notificationCounter,
  avatar,
}: Pick<SpacesSubNavLabelProps, "notificationCounter" | "avatar">) => {
  return (
    <div className={style.navAvatar}>
      <Avatar src={avatar} size="x-small" />
      {notificationCounter > 0 && (
        <div className={style.notificationMark}>{notificationCounter}</div>
      )}
    </div>
  );
};

import React, { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

import type { SliderProps } from "@braintrust/braintrust-ui-components";
import { Button, Slider } from "@braintrust/braintrust-ui-components";

import styles from "./style.module.scss";

export type CropperProps = {
  onCrop: (file: File) => void;
  file: File;
  onClose?: () => void;
  circularCrop?: boolean;
  height?: number;
  width?: number;
  shouldRespectTransparency?: boolean;
};

export const Cropper = ({
  onClose,
  onCrop,
  file,
  circularCrop = false,
  height = 350,
  width = 350,
  shouldRespectTransparency = true,
}: CropperProps) => {
  const editorRef = useRef<AvatarEditor>(null);
  const [scale, setScale] = useState<number>(1);
  const transparencyValue = shouldRespectTransparency ? 0 : 1;

  const handleClickSave = async () => {
    if (!editorRef.current) {
      return;
    }
    const canvas = editorRef.current.getImage();

    if (!canvas) {
      return;
    }

    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    context.globalCompositeOperation = "destination-over";
    context.imageSmoothingQuality = "high";
    context.save();
    context.fillStyle = `rgba(255, 255, 255, ${transparencyValue})`;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();

    const newFile: Blob | null = await new Promise((resolve) => {
      canvas.toBlob(resolve, file.type);
    });
    if (!newFile) {
      return;
    }

    onCrop(new File([newFile], file.name));
  };

  const handleScale = (_e: Event, newValue: number) => setScale(newValue);

  return (
    <div>
      <AvatarEditor
        ref={editorRef}
        image={file}
        width={width}
        height={height}
        style={{
          maxWidth: width + 150,
          width: "100%",
          height: "100%",
        }}
        border={10}
        scale={scale}
        borderRadius={circularCrop ? 1000 : 0}
      />
      <Slider
        className={styles.slider}
        color="secondary"
        min={0.3}
        max={3}
        step={0.001}
        value={scale}
        onChange={handleScale as SliderProps["onChange"]}
      />
      <div className="right mla">
        {onClose && (
          <Button variant="secondary" onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
        )}
        <Button variant="primary" onClick={handleClickSave}>
          Crop
        </Button>
      </div>
    </div>
  );
};

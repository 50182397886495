import { useEffect } from "react";
import type { PropsWithField } from "redux-form";
import { Fields } from "redux-form";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  ArrowRightIcon,
  ErrorIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useSendEmployerOnboardingDataMutation } from "@js/apps/auth/api";
import {
  clickClientInviteOrgDetails,
  viewClientInviteOrgPage,
} from "@js/apps/onboarding/actions";
import { OnboardingPage } from "@js/apps/onboarding/components/onboarding-page";
import { OnboardingOrganizationContent } from "@js/apps/onboarding/components/organization/onboarding-organization-content";
import { OnboardingOrganizationFormInstance } from "@js/apps/onboarding/components/organization/onboarding-organization-form";
import { ORGANIZATION_NAME_USED_ERROR_MESSAGE } from "@js/apps/onboarding/constants";
import {
  useAppDispatch,
  useNavigate,
  useUserRegistrationQueryParams,
} from "@js/hooks";

import { useOrganizationOnboardingForm } from "./use-organization-onboarding-form";

import styles from "./style.module.scss";

const NameErrorMessage = ({ name }: { name: PropsWithField }) => {
  const errors: string[] | undefined = name?.meta?.error;

  const isTryingToJoinExistingOrg = errors?.includes(
    ORGANIZATION_NAME_USED_ERROR_MESSAGE,
  );

  return isTryingToJoinExistingOrg ? (
    <Box className={styles.errorMessage}>
      <ErrorIcon sx={{ color: "var(--negative-1)" }} />
      <Typography component="p" variant="paragraph" error>
        Please contact your admin to join to this organization.
      </Typography>
    </Box>
  ) : null;
};

export const OnboardingOrganizationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sendEmployerOnboardingData] = useSendEmployerOnboardingDataMutation();

  const {
    onSubmit,
    initialValues,
    isLoadingEmployerData,
    isUpdatingEmployerData,
    saveChangesLocally,
    resetForm,
  } = useOrganizationOnboardingForm();

  const onSubmitSuccess = async () => {
    await sendEmployerOnboardingData();
    navigate("/onboarding/schedule-meeting");
    resetForm();
  };

  const { isClientInvite } = useUserRegistrationQueryParams();

  useEffect(() => {
    if (isClientInvite) {
      dispatch(viewClientInviteOrgPage());
    }
  }, [dispatch, isClientInvite]);

  return (
    <OnboardingPage>
      <Typography component="h2" variant="paragraph" marginBottom={1}>
        Your account is now active.
      </Typography>
      <Typography component="h1" variant="title" size="small" fontWeight={400}>
        Tell us about your organization
      </Typography>
      {isLoadingEmployerData ? (
        <Loader />
      ) : (
        <OnboardingOrganizationFormInstance
          onSubmit={onSubmit}
          initialValues={initialValues}
          onSubmitSuccess={onSubmitSuccess}
          onChange={saveChangesLocally}
        >
          {({ valid }) => {
            return (
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Fields names={["name"]} component={NameErrorMessage} />
                <OnboardingOrganizationContent />
                <Button
                  onClick={() => {
                    if (isClientInvite) {
                      dispatch(clickClientInviteOrgDetails());
                    }
                  }}
                  type="submit"
                  variant="positive"
                  shape="squared"
                  endIcon={<ArrowRightIcon />}
                  disabled={isUpdatingEmployerData || !valid}
                  size="medium"
                  className={styles.submitBtn}
                >
                  Next
                </Button>
              </Box>
            );
          }}
        </OnboardingOrganizationFormInstance>
      )}
    </OnboardingPage>
  );
};

import { Box } from "@braintrust/braintrust-ui-components";
import { useCompletionSequenceContext } from "@js/apps/freelancer/components/profile-completion-sequence/context/completion-sequence-context";
import { AddWorkHistoryModal } from "@js/apps/freelancer/components/work-history/modals";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const ProfileCompletionSequenceWorkExperienceStep = () => {
  const { handleStepCompleted } = useCompletionSequenceContext();

  return (
    <Box sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <AddWorkHistoryModal
        saveButtonText="Save and continue"
        persistModalInstance
        onFinished={handleStepCompleted}
      />
    </Box>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { ReviewReportedBadge } from "@js/apps/reviews/components/review-card/review-card-reported-badge";
import { Date } from "@js/components/date";
import type { EmployerPublicProfile, EmployerReview } from "@js/types/employer";
import type { FreelancerPublic } from "@js/types/freelancer";
import type { TalentReview } from "@js/types/reviews";
import { typeGuard } from "@js/utils";
import { DateFormats } from "@js/utils/date/types";

import { ReviewCardActionMenu } from "./review-card-action-menu";
import { ReviewerCardData } from "./review-card-data";
import { ReviewCardDescription } from "./review-card-description";
import { ReviewCardRating } from "./review-card-rating";
import { ReviewCardReviewType } from "./review-card-review-type";

import style from "./style.module.scss";

type FreelancerOrEmployerReview = TalentReview | EmployerReview;

type ReviewCardProps = {
  profile: FreelancerPublic | EmployerPublicProfile;
  review: FreelancerOrEmployerReview;
  isOwnProfile: boolean;
};
export const ReviewCard = ({
  profile,
  review,
  isOwnProfile,
}: ReviewCardProps) => {
  const {
    reviewer,
    average_review,
    additional_comments,
    show_contact_support,
  } = review;
  const reviewRating = Number(average_review);
  const isMobile = useMediaQuery("sm");
  const isTalentReview = checkForTalentReview(review);

  return (
    <Box className={style.reviewCard} id={`review${review.id}`}>
      <ReviewerCardData
        reviewer={reviewer}
        isMobile={isMobile}
        id={review.id}
        isOwnProfile={isOwnProfile}
        profileId={profile.id}
      />

      <div className={style.reviewCardReview}>
        <Box display="flex" columnGap={1} position="relative">
          <ReviewCardRating value={reviewRating} />
          <Typography component="span" color="grey-1" size="small">
            <Date date={review.created} format={DateFormats["12/31/1970"]} />
          </Typography>
          {!isMobile && (
            <Box position="absolute" right={0}>
              <ReviewCardActionMenu
                id={review.id}
                isOwnProfile={isOwnProfile}
                profileId={profile.id}
              />
            </Box>
          )}
        </Box>

        {isTalentReview && (
          <ReviewCardReviewType
            review={review as TalentReview}
            profile={profile as FreelancerPublic}
          />
        )}
        <ReviewCardDescription description={additional_comments} />
        {isOwnProfile && show_contact_support && (
          <Box mt={2}>
            <ReviewReportedBadge />
          </Box>
        )}
      </div>
    </Box>
  );
};

const checkForTalentReview = (review: TalentReview | EmployerReview) => {
  return !!typeGuard<FreelancerOrEmployerReview, TalentReview>(
    review,
    "review_type",
  );
};

import { Avatar, Box, Typography } from "@braintrust/braintrust-ui-components";
import type { GetByotInvitationDetailsResponse } from "@js/apps/byot/api";

type InvitationDetailsProps = {
  invitationDetails: GetByotInvitationDetailsResponse;
};

export const InvitationDetails = ({
  invitationDetails,
}: InvitationDetailsProps) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Avatar
        sx={{
          height: 28,
          width: 28,
          bgcolor: "none !important",
          marginRight: "-6px",
          zIndex: -1,
        }}
        alt={""}
        textSize="small"
        src={invitationDetails}
        shouldRenderFallback={false}
      />

      <Box
        bgcolor="var(--positive-3)"
        padding="2px 14px"
        border="2px solid white"
        borderRadius="35px"
      >
        <Typography
          component="span"
          variant="label"
          color="positive"
          size="small"
        >
          {invitationDetails.first_name} invited you to join Braintrust!
        </Typography>
      </Box>
    </Box>
  );
};

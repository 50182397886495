import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import { Typography } from "@braintrust/braintrust-ui-components";

type JobItemTextCaptionPropsTypes = {
  tagText: string;
  color?: TypographyProps["color"];
  style?: React.CSSProperties;
  className?: string;
};

export const JobItemTextCaption = ({
  tagText,
  color,
  ...props
}: JobItemTextCaptionPropsTypes) => {
  return (
    <Typography
      component="span"
      variant="label"
      color={color}
      size="small"
      {...props}
    >
      {tagText}
    </Typography>
  );
};

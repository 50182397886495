import type { FieldArrayFieldsProps, WrappedFieldMetaProps } from "redux-form";
import { Field, Fields } from "redux-form";

import {
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { DeleteTrashIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  DateField,
  DollarNumberField,
  NumberField,
  TextareaField,
} from "@js/forms/fields";
import { formatCurrency } from "@js/utils";

import { countItemTotal } from "../../logic";

import { AddInvoiceItemButton } from "./add-invoice-item-button";
import { PreviousPONumbersAutocompleteField } from "./previous-po-numbers";
import { useInvoiceSubmitErrors } from "./submit-errors";
import { TaxPopoverField } from "./tax-popover-field";

import styles from "./style.module.scss";

type InvoiceFormItemFieldData = {
  date_from?: string;
  date_to?: string;
  description?: string;
  po_number?: string;
  quantity: string;
  unit_price?: string;
  tax_name?: string;
  tax_rate?: string;
};
export const InvoiceItemField = ({
  fields,
  meta,
}: {
  fields: FieldArrayFieldsProps<InvoiceFormItemFieldData>;
  meta: WrappedFieldMetaProps;
}) => {
  const { getTaxNameError, getTaxRateError } = useInvoiceSubmitErrors(
    meta.form,
  );
  const isMobile = useMediaQuery("md");

  const handleAddItemClick = (initialUnitPrice: number | null) => {
    fields.push({
      unit_price: initialUnitPrice ? String(initialUnitPrice) : undefined,
      quantity: "1",
    });
  };

  return (
    <>
      {fields.map((item: string, index: number) => {
        const field = fields.get(index);
        const itemTotal = countItemTotal(
          field.unit_price ?? "0",
          field.quantity,
        );

        const taxNameErrors = getTaxNameError(index);
        const taxRateErrors = getTaxRateError(index);

        return (
          <TableRow
            key={item}
            className={styles.addInvoiceItem}
            data-testid="invoice-item"
          >
            <TableCell width={isMobile ? 180 : 210}>
              <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item minWidth={130}>
                  <Field
                    label="Date From"
                    id={`${item}.date_from`}
                    name={`${item}.date_from`}
                    component={DateField}
                  />
                </Grid>
                <Grid item minWidth={130}>
                  <Field
                    label="Date To"
                    id={`${item}.date_to`}
                    name={`${item}.date_to`}
                    component={DateField}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell sx={{ minWidth: "230px" }}>
              <Fields
                names={[`${item}.po_number`, "job"]}
                component={PreviousPONumbersAutocompleteField}
              />
            </TableCell>
            <TableCell sx={{ minWidth: "280px" }}>
              <Field
                variant="standard"
                placeholder="Describe the project."
                inputProps={{
                  "aria-label": `description ${index}`,
                }}
                InputProps={{
                  componentsProps: {
                    root: {
                      style: { padding: "0" },
                    },
                  },
                }}
                id={`${item}.description`}
                name={`${item}.description`}
                rows={6}
                component={TextareaField}
              />
            </TableCell>
            <TableCell
              align="right"
              className="bw"
              sx={{
                minWidth: "120px",
              }}
            >
              <Field
                className="text-right"
                variant="standard"
                placeholder="0.00"
                sx={{
                  marginTop: "23px",
                }}
                inputProps={{
                  "aria-label": `unit price ${index}`,
                }}
                id={`${item}.unit_price`}
                name={`${item}.unit_price`}
                component={DollarNumberField}
              />
              <TaxPopoverField
                buttonText={field.tax_name}
                taxRate={field.tax_rate}
                formName={meta.form}
                form={`tax-popover-${item}`}
                initialValues={{
                  tax_name: field.tax_name,
                  tax_rate: field.tax_rate,
                }}
                item={item}
              />
              {taxRateErrors &&
                taxRateErrors.map((error) => (
                  <Typography
                    component="p"
                    error
                    color="negative"
                    key={error}
                    textAlign="left"
                  >
                    {error}
                  </Typography>
                ))}
              {taxNameErrors &&
                taxNameErrors.map((error) => (
                  <Typography
                    component="p"
                    error
                    color="negative"
                    key={error}
                    textAlign="left"
                  >
                    {error}
                  </Typography>
                ))}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                minWidth: "130px",
              }}
            >
              <Field
                className="text-right"
                variant="standard"
                placeholder="0"
                inputProps={{
                  "aria-label": `quantity ${index}`,
                  decimalScale: 2,
                }}
                id={`${item}.quantity`}
                name={`${item}.quantity`}
                component={NumberField}
              />
            </TableCell>
            <TableCell
              align="right"
              className={styles.addInvoiceItemTotal}
              sx={{
                minWidth: "130px",
              }}
            >
              {formatCurrency(itemTotal)}
            </TableCell>
            <TableCell align="right">
              <IconButton
                aria-label="remove invoice item"
                className={styles.addInvoiceItemRemove}
                onClick={() => fields.remove(index)}
                variant="transparent"
              >
                <DeleteTrashIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      <TableRow>
        <TableCell colSpan={5} className={styles.addInvoiceItemLastRow}>
          <AddInvoiceItemButton onClick={handleAddItemClick} />
        </TableCell>
      </TableRow>
    </>
  );
};

import { Box } from "@braintrust/braintrust-ui-components";
import {
  LocationDetail,
  TimezoneDetail,
  WorkTimeDetail,
} from "@js/apps/jobs/components/job-basic-details";

import type { JobItemProps } from "../job-item";

export const JobForYouBasicInfo = ({ job }: Pick<JobItemProps, "job">) => {
  return (
    <Box display="flex" gap="8px 24px" flexWrap="wrap">
      <WorkTimeDetail
        expectedHoursPerWeek={job.expected_hours_per_week}
        variant="jobs-for-you-card"
        shortened
      />
      <LocationDetail locations={job.locations} variant="jobs-for-you-card" />
      <TimezoneDetail
        timezones={job.timezones}
        timezoneOverlap={job.timezone_overlap}
        variant="jobs-for-you-card"
        shortenedLabel
      />
    </Box>
  );
};

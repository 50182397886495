import { Box, Button } from "@braintrust/braintrust-ui-components";
import { assertUnreachable } from "@js/utils";

import type { RoleModuleFormProps } from "../../forms/role-form";
import { RoleModuleForm } from "../../forms/role-form";
import { useRoleModal } from "../../hooks";

import { RemoveRole } from "./remove-role";
import { RolesList } from "./roles-list";

type RoleModalProps = {
  persistModalInstance?: boolean;
  saveButtonText?: string;
  onFinished?: () => void;
  continueButton?: JSX.Element;
};

export const RoleModal = ({
  persistModalInstance = false,
  saveButtonText,
  onFinished,
  continueButton,
}: RoleModalProps) => {
  const {
    roles,
    modal,
    canSaveAndAddAnother,
    maxRoleCountReached,
    handleAdd,
    handleEdit,
    handleDelete,
    handleSaveRole,
    handleCancel,
  } = useRoleModal({ onFinished });

  const roleModuleFormCommonProps: RoleModuleFormProps = {
    canSaveAndAddAnother,
    onCancel: handleCancel,
    onSave: handleSaveRole,
    persistModalInstance,
    hasAnyRole: !!roles.length,
  };

  switch (modal.kind) {
    case "list":
      return (
        <div>
          <RolesList
            roles={roles}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          {(!maxRoleCountReached || continueButton) && (
            <Box display="flex" justifyContent="space-between" mt={4}>
              <Button variant="tertiary-dark" size="medium" onClick={handleAdd}>
                Add role
              </Button>
              {continueButton}
            </Box>
          )}
        </div>
      );

    case "add":
      return (
        <div>
          <RolesList
            roles={roles}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          <RoleModuleForm
            key={modal.initialValues ? "add" : "add-initial-values"}
            {...roleModuleFormCommonProps}
            initialValues={modal.initialValues}
            saveButtonText={saveButtonText}
          />
        </div>
      );

    case "edit":
      return (
        <RoleModuleForm
          {...roleModuleFormCommonProps}
          isEdit
          initialValues={modal.initialValues}
          roles={roles}
        />
      );

    case "remove":
      return (
        <RemoveRole
          role={modal.role}
          onCancel={handleCancel}
          onRemove={() => {
            handleSaveRole();
          }}
        />
      );

    default:
      assertUnreachable(modal);
      return null;
  }
};

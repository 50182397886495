import { type FC, useCallback } from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import { usePurchaseUpgradeMutation } from "@js/apps/auth/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

import { openSuccessPurchaseModal } from "../product-checkout-modal/btrst-checkout-form/success-purchase-modal";

import { Description } from "./components/description";
import { PurchaseSummary } from "./components/purchase-summary";

export const ApplicationInsightModal: FC = () => {
  const [purchaseUpgrade, { isLoading: isLoadingPurchaseUpgrade }] =
    usePurchaseUpgradeMutation();
  const dispatch = useAppDispatch();

  const onPurchase = useCallback(async () => {
    try {
      await purchaseUpgrade({
        upgrade_type: "insights",
      }).unwrap();
      ModalInstance.close();
      openSuccessPurchaseModal(dispatch);
    } catch {
      Snackbar.error("Something went wrong!");
    }
  }, [purchaseUpgrade, dispatch]);

  return (
    <Box
      minWidth={{
        lg: "1248px",
      }}
      width={{
        xs: "90vw",
        sm: "88vw",
        md: "86.5vw",
        lg: "auto",
      }}
      minHeight={{ xs: "100%", lg: "800px" }}
      padding={2}
      boxSizing="border-box"
      display="flex"
      gap={2}
      flexDirection={{ xs: "column", lg: "row" }}
    >
      <Description />
      <PurchaseSummary
        isPurchasing={isLoadingPurchaseUpgrade}
        onPurchase={onPurchase}
      />
    </Box>
  );
};

export const openInsightPurchaseModal = async () => {
  ModalInstance.open({
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
      },
    },
    children: <ApplicationInsightModal />,
  });
};

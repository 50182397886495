import React from "react";

import {
  AttachmentFileIcon,
  AttachmentImageIcon,
  AttachmentLinkIcon,
  PollIcon,
} from "@braintrust/braintrust-ui-components/Icons";

import type { FooterActionButtonProps } from "./footer-action-button";
import { FooterActionButton } from "./footer-action-button";

type SpecificFooterActionButtonProps = Pick<FooterActionButtonProps, "onClick">;

export type PostFooterButtonType = (
  props: SpecificFooterActionButtonProps,
) => JSX.Element;

export const AddFileButton: PostFooterButtonType = (props) => {
  return (
    <FooterActionButton
      aria-label="Attach file"
      title="Add a file"
      fieldName="attachment_file"
      {...props}
    >
      <AttachmentFileIcon />
    </FooterActionButton>
  );
};

export const AddImageButton: PostFooterButtonType = (props) => {
  return (
    <FooterActionButton
      aria-label="Attach image"
      fieldName="attachment_image"
      title="Add an image"
      {...props}
    >
      <AttachmentImageIcon />
    </FooterActionButton>
  );
};

export const AddLinkButton: PostFooterButtonType = (props) => {
  return (
    <FooterActionButton
      aria-label="Add link"
      fieldName="link"
      title="Add a link"
      {...props}
    >
      <AttachmentLinkIcon />
    </FooterActionButton>
  );
};

export const AddPollButton: PostFooterButtonType = (props) => {
  return (
    <FooterActionButton
      aria-label="Add poll"
      fieldName="poll"
      title="Add a poll"
      {...props}
    >
      <PollIcon />
    </FooterActionButton>
  );
};

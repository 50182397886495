import React, { type ReactNode, useLayoutEffect, useState } from "react";

import {
  Button,
  IconButton,
  Loader,
} from "@braintrust/braintrust-ui-components";
import { DownloadArrowIcon } from "@braintrust/braintrust-ui-components/Icons";
import { showAlert } from "@braintrust/braintrust-ui-components/utils";
import { W8FormGuidance } from "@js/apps/settings/components";
import {
  AddW8Modal,
  AddW9Modal,
  TaxDocumentsAlert,
} from "@js/apps/settings/components/tax-documents";
import { SectionSubtitle } from "@js/apps/settings/forms/helpers";
import { useTaxDocuments } from "@js/apps/settings/hooks/use-tax-documents";
import { Datetime } from "@js/components/date";
import type { IsoDateTime } from "@js/utils/date/types";
import { DateTimeFormats } from "@js/utils/date/types";

import { PaymentsPanelItem } from "../payments-panel-item";

export const TaxDocuments = React.memo(() => {
  // mountAlert flag is needed to show the alert correctly after going to the payments page back and forth
  const [mountAlert, setMountAlert] = useState(false);
  const taxDocuments = useTaxDocuments();
  const {
    uploadForm,
    formTypeName,
    returnForFormType,
    uploadedAt,
    deleteForm,
    uploadedFormUrl,
    country,
    isLoading,
  } = taxDocuments;

  useLayoutEffect(() => {
    setMountAlert(true);
  }, []);

  return (
    <>
      <SectionSubtitle
        title={formTypeName ?? ""}
        description={
          country
            ? returnForFormType({
                w8: W8FormGuidance() as ReactNode,
                w9: `U.S. residents must provide their W-9 tax information.`,
              })
            : "Before you can add tax documents, you must set your country location."
        }
        action={
          country && (
            <Button
              disabled={false}
              variant="secondary"
              onClick={() => uploadForm?.()}
            >
              Add {formTypeName}
            </Button>
          )
        }
      />
      {isLoading && <Loader />}
      {!isLoading && uploadedAt && uploadedFormUrl && (
        <PaymentsPanelItem
          header="Your Form"
          subheader={[
            getSubheader(uploadedFormUrl),
            <React.Fragment key="experience-panel-item-subheader">
              Uploaded at{" "}
              <Datetime
                date={uploadedAt as IsoDateTime}
                format={DateTimeFormats["January 1st 1970, 0:00:00 pm"]}
              />
            </React.Fragment>,
          ]}
          onDelete={() => deleteForm?.()}
          renderButtons={(buttons) => {
            return [
              <IconButton
                aria-label="download form"
                key="download_form"
                variant="tertiary"
                size="small"
                href={uploadedFormUrl}
                download
              >
                <DownloadArrowIcon />
              </IconButton>,
              ...buttons,
            ];
          }}
          borderBottom={true}
        />
      )}

      {mountAlert && showAlert(<TaxDocumentsAlert {...taxDocuments} />)}
      <AddW8Modal />
      <AddW9Modal />
    </>
  );
});

const getSubheader = (uploadedFormUrl: string) => {
  const matched = uploadedFormUrl.match(/\.([0-9a-z]+)(?:[?#]|$)/i)?.[1];
  if (matched) {
    return matched.toUpperCase();
  }

  return "";
};

import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import {
  Box,
  Button,
  Loader,
  ProgressBarStepper,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { AuthLayout } from "@js/apps/auth/views/layout/sign-in-layout";
import { useGetFreelancerJobOffersQuery } from "@js/apps/freelancer/api";
import { SignUpBYOTOfferSummary } from "@js/apps/freelancer/components/freelancer-dashboard-offers/offer-list/offer-item";
import { SignUpBYOTStaticChecklist } from "@js/apps/freelancer/components/onboarding-checklist";
import { useNavigate } from "@js/hooks";

export const SignUpBYOTCongratsAccessWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { state } = useLocation();

  if (!state?.allowAccessByotCongratsPage) {
    return <Navigate to="/" />;
  }

  return children;
};

export const SignUpBYOTCongrats = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetFreelancerJobOffersQuery({
    section: ENUMS.TalentOffersSectionFilter.CURRENT,
    order_by: "id",
  });
  const newestOffer = data ? data[data.length - 1] : null;

  return (
    <AuthLayout noLogoLink rightSocket={null}>
      <Box width={1} maxWidth={672} mt={6} p={2}>
        <Typography
          component="h1"
          variant="title"
          size="small"
          mb={1}
          fontWeight={400}
        >
          You just accepted the offer! Congrats 🎉 🙌
        </Typography>
        <Typography component="p" mb={3}>
          Nice job! Make sure you communicate with the Client about next steps,
          and{" "}
          <Typography component="span" fontWeight={500}>
            don’t forget to complete your checklist
          </Typography>{" "}
          to get set up for payments and taxes, review payment terms, and more.
        </Typography>
        {!newestOffer || isLoading ? (
          <Loader />
        ) : (
          <Box display="flex" flexDirection="column">
            <Box mb={3}>
              <SignUpBYOTOfferSummary offer={newestOffer} />
            </Box>
            <Box mb={8}>
              <SignUpBYOTStaticChecklist />
            </Box>
            <Button
              variant="primary"
              size="medium"
              onClick={() => {
                navigate("/");
              }}
            >
              Complete checklist
            </Button>
            <Box mt={6}>
              <ProgressBarStepper activeStep={2} stepsLength={3} />
            </Box>
          </Box>
        )}
      </Box>
    </AuthLayout>
  );
};

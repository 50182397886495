import React from "react";

import { Box, Stack } from "@braintrust/braintrust-ui-components";
import type { EmployerReferralsReturnType } from "@js/types/dashboard";

import {
  DEFAULT_EMPLOYER_REFERRALS_ORDER_BY,
  EMPLOYER_REFERRALS_ORDER_BY_OPTIONS,
} from "../../constants";
import { useReferralSearch } from "../../hooks/use-referral-search";
import type { GetEmployerReferralsParams } from "../../types";
import { DownloadReferralsCSVBtn } from "../download-referrals-csv";
import {
  ReferralsStatsListLoader,
  ReferralsStatsListResultsCount,
} from "../referral-stats";
import { ReferralsEmptyState } from "../referrals-empty-state";
import { ReferralsListTopContainer } from "../referrals-list-top-container";
import { ReferralsSearch } from "../referrals-search";
import { ReferralsSortButton } from "../referrals-sort-button";

import { EmployerCardList } from "./employer-card-list";
import { EmployerReferralListHeader } from "./employer-referrals-list-header";

export type EmployerReferralsListProps = {
  referrals: EmployerReferralsReturnType | undefined;
  fetchMore: () => void;
  loading: boolean;
  filters: GetEmployerReferralsParams;
};

export const EmployerReferralsList = ({
  referrals,
  fetchMore,
  loading,
  filters,
}: EmployerReferralsListProps) => {
  const { searchValue, handleUpdateSearch, handleSearchClear } =
    useReferralSearch();
  const referralItems = referrals?.results ?? [];
  const showReferralsItems = !!referralItems.length;
  const showLoader = !referralItems.length && loading;
  const showEmptyState = !referralItems.length && !loading;

  return (
    <Stack>
      <ReferralsListTopContainer>
        <Box display="flex" gap={1} width="100%" maxWidth={580} flexWrap="wrap">
          <ReferralsSearch
            value={searchValue}
            onChange={handleUpdateSearch}
            onClear={handleSearchClear}
            variant="violet"
          />
          <DownloadReferralsCSVBtn
            endpoint="/api/employer_referrals/"
            filters={filters}
            type="client"
          />
        </Box>
        <ReferralsSortButton
          colorVariant="violet"
          defaultOrderByParamValue={DEFAULT_EMPLOYER_REFERRALS_ORDER_BY}
          orderByOptions={EMPLOYER_REFERRALS_ORDER_BY_OPTIONS}
          type="client"
        />
      </ReferralsListTopContainer>
      <EmployerReferralListHeader />
      {showLoader && <ReferralsStatsListLoader />}
      {showEmptyState && <ReferralsEmptyState onClear={handleSearchClear} />}
      {showReferralsItems && referrals && (
        <>
          <EmployerCardList referrals={referralItems} />
          <ReferralsStatsListResultsCount
            count={referralItems.length}
            total={referrals.count}
            hasMore={!!referrals.next}
            fetchMore={fetchMore}
            loading={loading}
          />
        </>
      )}
    </Stack>
  );
};

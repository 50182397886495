import { change, Field, SubmissionError } from "redux-form";

import {
  Button,
  Divider,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks/use-user";
import { getFormId } from "@js/apps/messenger/utils/get-messenger-form-id";
import { updateAccountInfo } from "@js/apps/settings/actions";
import { Modal } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { TextField } from "@js/forms/fields";
import { required } from "@js/forms/validators";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { MessageRoom } from "@js/types/messenger";

const SchedulePromptModalInstance = Modal("schedule-prompt-modal", {
  closeButton: true,
  keepOnBackdropClick: false,
  closeButtonProps: {
    variant: "tertiary",
    size: "x-small",
    sx: {
      width: "42px",
      height: "42px",
    },
  },
});

export const openSchedulePromptModal = SchedulePromptModalInstance.open;
export const closeSchedulePromptModal = SchedulePromptModalInstance.close;

export type SchedulePromptFormData = {
  calendar_link: string;
};

const FormInstance = createFormInstance<SchedulePromptFormData, unknown>(
  "schedule-prompt-form",
);

type SchedulePromptModalProps = {
  activeRoom: MessageRoom;
};

export const SchedulePromptModal = ({
  activeRoom,
}: SchedulePromptModalProps) => {
  const isMobile = useMediaQuery("sm");
  const calendarLink = useUser()?.calendar_link;
  const formId = getFormId(activeRoom.id);
  const messageValue = useAppSelector((state) => state.form[formId]?.values);
  const dispatch = useAppDispatch();

  const prePopulateChatWithDefaultMessage = () => {
    const fieldName = "message";
    const payload = "Let's set up a time to speak live.";
    dispatch(change(formId, fieldName, payload));
  };

  const prePopulateChatWithCalendarLink = ({
    calendar_link,
  }: SchedulePromptFormData) => {
    const fieldName = "calendar_link";
    const payload = calendar_link;
    dispatch(change(formId, fieldName, payload));
  };

  const handleSubmit = async (data: SchedulePromptFormData) => {
    try {
      await updateAccountInfo(data);
    } catch (error) {
      throw new SubmissionError((error as any).errors);
    }

    prePopulateChatWithCalendarLink(data);
    if (!messageValue?.message) prePopulateChatWithDefaultMessage();
  };

  return (
    <SchedulePromptModalInstance onClose={closeSchedulePromptModal}>
      <FormInstance
        onSubmit={handleSubmit}
        onSubmitSuccess={closeSchedulePromptModal}
        initialValues={{ calendar_link: calendarLink }}
      >
        <Typography
          component="h2"
          variant="label"
          size="large"
          mb={isMobile ? 2.5 : 3.5}
        >
          Attach calendar link
        </Typography>
        <Typography
          component="h2"
          variant="title"
          size={isMobile ? "small" : "medium"}
          fontWeight={400}
          mb={isMobile ? 2 : 3}
        >
          What's your booking calendar URL?
        </Typography>
        <Field
          variant="outlined"
          id="calendar_link"
          name="calendar_link"
          placeholder="Enter calendar URL"
          InputProps={{
            style: { maxWidth: "440px" },
          }}
          component={TextField}
          validate={[required]}
        />
        <Divider
          sx={{
            borderColor: "var(--grey-4) !important",
            my: isMobile ? 2 : 3,
          }}
        />
        <Button
          variant="positive"
          size="medium"
          shape="squared"
          type="submit"
          sx={{ float: "right" }}
        >
          Continue
        </Button>
      </FormInstance>
    </SchedulePromptModalInstance>
  );
};

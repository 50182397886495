import type { MouseEvent } from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import type { MemberInviteData } from "../types";

type MemberOptionLabelProps = Pick<
  MemberInviteData,
  "freelancer_id" | "public_name"
> & {
  disableLeftClick?: boolean;
};

const commonLabelProps = {
  component: "h5",
  variant: "label",
  size: "medium",
  ellipsis: true,
  sx: { overflow: "hidden" },
} as const;

export const MemberOptionLabel = ({
  freelancer_id,
  public_name,
  disableLeftClick,
}: MemberOptionLabelProps) => {
  if (!freelancer_id) {
    return <Typography {...commonLabelProps}>{public_name}</Typography>;
  }

  const handleNameLinkClick = (ev: MouseEvent<HTMLAnchorElement>) => {
    if (!disableLeftClick) {
      return;
    }

    if (!ev.metaKey && !ev.ctrlKey) {
      return ev.preventDefault();
    }

    ev.stopPropagation();
  };

  return (
    <Typography
      {...commonLabelProps}
      RouterLink={RouterLink}
      to={`/talent/${freelancer_id}`}
      onClick={handleNameLinkClick}
      target="_blank"
      sx={{
        ...commonLabelProps.sx,
        "&:not(:hover)": { textDecoration: "none" },
      }}
    >
      {public_name}
    </Typography>
  );
};

import { Typography } from "@braintrust/braintrust-ui-components";
import { SaveAltIcon } from "@braintrust/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

type SavedFilterTitleProps = { title: string };

export const SavedFilterTitle = ({ title }: SavedFilterTitleProps) => {
  return (
    <div className={styles.title}>
      <span className={styles.separator} />
      <div className={styles.icon}>
        <SaveAltIcon sx={{ width: "24px", height: "24px" }} />
      </div>
      <Typography component="p" fontSize={13} fontWeight={500}>
        {title}
      </Typography>
    </div>
  );
};

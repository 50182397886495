import { useCallback } from "react";
import type { DropzoneOptions } from "react-dropzone";

import { Box } from "@braintrust/braintrust-ui-components";

import { getFileWithId } from "../../helpers/file-upload";
import { useUploadSingleFile } from "../../hooks/use-upload-single-file";
import type { ExistingFile, UploadedFile, UploadType } from "../../types";
import { AttachmentRenameInput } from "../attachment-rename-input";
import { FileDropzone } from "../file-dropzone";
import { FileDropzonePlaceholder } from "../file-dropzone-placeholder";

export type FileUploadProps = {
  uploadType: UploadType;
  existingFile?: ExistingFile;
  subtitle?: string;
  onBeforeUpload?: () => void;
  onAfterUpload?: () => void;
  onUpload?: (uploadedFile: UploadedFile) => void;
  onFileDelete?: () => void;
  id?: string;
  inputId?: string;
  className?: string;
  options?: Pick<DropzoneOptions, "maxSize" | "accept">;
};

export const FileUpload = ({
  uploadType,
  subtitle,
  id,
  inputId,
  className,
  options,
  existingFile,
  onBeforeUpload,
  onAfterUpload,
  onUpload,
  onFileDelete,
}: FileUploadProps) => {
  const { uploadedFile, uploadSingleFile, isUploading, error, resetFileState } =
    useUploadSingleFile({ onBeforeUpload, onUpload, onAfterUpload });

  const onDrop = useCallback(
    (files: File[]) => {
      resetFileState();

      const [file] = files;
      if (!file) {
        return;
      }

      const fileWithId = getFileWithId(file);
      uploadSingleFile({
        file: fileWithId,
        uploadType,
      });
    },
    [uploadSingleFile, resetFileState, uploadType],
  );

  const handleDeleteFile = () => {
    resetFileState(true);
    onFileDelete?.();
  };

  const previewedFileData = uploadedFile ?? existingFile;

  return (
    <Box>
      <FileDropzone
        id={id}
        dropzoneOptions={{
          onDrop,
          multiple: false,
          ...options,
        }}
        error={error}
      >
        {({ isDragActive, isFocused }) => (
          <FileDropzonePlaceholder
            className={className}
            isDragActive={isDragActive}
            isFocused={isFocused}
            isLoading={isUploading}
            subtitle={subtitle}
          />
        )}
      </FileDropzone>
      {(!!previewedFileData || isUploading) && (
        <Box sx={{ pt: 2 }}>
          <AttachmentRenameInput
            id={inputId}
            value={previewedFileData ? previewedFileData.attachment.name : ""}
            loading={isUploading}
            onDelete={() => handleDeleteFile()}
          />
        </Box>
      )}
    </Box>
  );
};

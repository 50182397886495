import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQueryState,
} from "@js/apps/common/frontend-storage";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { useAppDispatch, useNavigate } from "@js/hooks/";

import { useGetEmployerOfferQuery } from "../../api";

export const useEmployerOffer = () => {
  const dispatch = useAppDispatch();

  const { isLoading: isLoadingPaymentMethodFailedTopBar } =
    useGetStorageValueQueryState({
      key: FRONTEND_STORAGE_KEYS.PAYMENT_METHOD_FAILED_TOP_BAR,
    });
  const { id, offerId } = useParams();
  const { data: job, isLoading: isJobLoading } = useGetJobQuery({
    id: Number(id),
  });

  const navigate = useNavigate();
  const {
    data: offer,
    isFetching,
    error,
  } = useGetEmployerOfferQuery(Number(offerId), {
    skip: offerId === undefined,
    refetchOnMountOrArgChange: 5,
  });

  useEffect(() => {
    if (isFetching) return;
    if (offer?.bid.job.id !== Number(id) || !offerId || error) {
      navigate("/page-not-found/", { replace: true });
    }
  }, [offer, isFetching, error, id, offerId, dispatch, navigate]);

  const isLoading =
    isFetching || isJobLoading || isLoadingPaymentMethodFailedTopBar;

  return {
    loading: isLoading,
    offer,
    offerId: offerId ? Number(offerId) : undefined,
    job,
  };
};

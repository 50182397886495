import { useMessengerContext } from "@js/apps/messenger/context/messenger-context";
import { useActiveRoomData } from "@js/apps/messenger/hooks/use-active-room-data";

import { MessagesBox } from "../messages-box";
import type { MessengerProps } from "../messenger";
import { MessengerContentContainer } from "../messenger-content-container";
import { TextBox } from "../textbox";

type SingleRoomBoxProps = MessengerProps & { isModal?: boolean };

export const SingleRoomBox = ({
  textBoxProps,
  isModal,
}: SingleRoomBoxProps) => {
  const { activeRoom } = useMessengerContext();
  const { activeRoomData } = useActiveRoomData(activeRoom);

  return (
    <MessengerContentContainer sx={{ height: "100%" }}>
      <MessagesBox room={activeRoomData} isModal={isModal} />
      <TextBox room={activeRoomData} {...textBoxProps} />
    </MessengerContentContainer>
  );
};

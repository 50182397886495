import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

import { useCancelEmployerOfferMutation } from "../../api";

type UseCancelJobOfferArgs = {
  offerId: number;
  onSuccess: VoidFunction;
};

export const useCancelJobOffer = ({
  offerId,
  onSuccess,
}: UseCancelJobOfferArgs) => {
  const [cancelOffer] = useCancelEmployerOfferMutation();

  const onCancelJobOffer = () =>
    ModalInstance.open({
      children: (
        <ModalConfirm
          onCancel={ModalInstance.close}
          confirmText="Yes, cancel the offer"
          cancelText="No, go back"
          onConfirm={() => {
            cancelOffer(offerId)
              .unwrap()
              .then(() => {
                ModalInstance.close();
                onSuccess();
              })
              .catch((error) => {
                Snackbar.error(error || "Offer couldn't be cancelled.");
              });
          }}
        >
          <Box mt={4}>
            <Typography component="h2" variant="title" mb={1}>
              Are you sure you want to cancel this offer?
            </Typography>
            <Typography component="p" variant="paragraph">
              Canceling an offer cannot be undone. The Talent will be informed
              that this offer is no longer valid.
            </Typography>
          </Box>
        </ModalConfirm>
      ),
      className: "max-width-500",
      closeButton: true,
    });

  return onCancelJobOffer;
};

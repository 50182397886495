import { Avatar, Box, Typography } from "@braintrust/braintrust-ui-components";
import {
  ChatIcon,
  PointerIcon,
  VideoOutlineIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { Emoji } from "@js/components/emoji";
import type { User } from "@js/types/auth";
import type { Participant } from "@js/types/messenger";

import styles from "./styles.module.scss";

type AvatarGridGraphicProps = {
  leftName: string;
  leftAvatarProfile: User | Participant;
  rightName?: string;
  rightAvatarProfile?: User | Participant;
  backgroundColor?: string;
  categoryEmoji?: string;
  forceMobile?: boolean;
};

export const AvatarGridGraphic = ({
  leftName,
  leftAvatarProfile,
  rightName,
  rightAvatarProfile,
  backgroundColor,
  categoryEmoji,
  forceMobile,
}: AvatarGridGraphicProps) => {
  const bgColor = backgroundColor
    ? `var(${backgroundColor})`
    : "var(--medium-yellow)";

  return (
    <Box className={styles.avatarGridGraphicWrapper}>
      <Box
        display="flex"
        flexDirection="row-reverse"
        alignSelf="flex-end"
        sx={{
          transform: forceMobile
            ? "translate(26px, -26px)"
            : {
                xs: "translate(26px, -26px)",
                sm: "translate(-12px, -47px)",
              },
          zIndex: 2,
        }}
      >
        <PointerIcon
          sx={{
            fill: "var(--positive-2)",
            transform: "rotateY(180deg) translate(2px, -100%)",
          }}
        />
        <Box
          className={styles.avatarGridGraphicTalentName}
          bgcolor="var(--positive-2)"
        >
          <Typography
            display="block"
            color="white"
            component="span"
            textAlign="center"
            size="small"
            className={styles.avatarGridGraphicTalentNameText}
          >
            {leftName}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.avatarGridGraphicBrowserWindow}>
        <Box display="flex" gap="8px" mb={1}>
          <Box width="6px" height="6px" borderRadius="50%" bgcolor="#F05C5E" />
          <Box width="6px" height="6px" borderRadius="50%" bgcolor="#FDE245" />
          <Box width="6px" height="6px" borderRadius="50%" bgcolor="#46D43D" />
        </Box>
        <Box display="flex" gap="9px" justifyContent="center">
          <div className={styles.avatarGridGraphicBrowserColumn}>
            <Avatar
              src={leftAvatarProfile}
              alt="User avatar"
              className={styles.avatarGridGraphicAvatarMedium}
            />
            <div className={styles.avatarGridGraphicDecorationRectangle}>
              <ChatIcon style={{ fill: "var(--dark-green)", height: "24px" }} />
            </div>
          </div>
          <div className={styles.avatarGridGraphicBrowserColumn}>
            <Box
              className={styles.avatarGridGraphicDecorationSquare}
              bgcolor={bgColor}
            >
              {categoryEmoji ? (
                <Emoji
                  className={styles.avatarGridGraphicDecorationEmoji}
                  emoji={categoryEmoji}
                  size="26px"
                />
              ) : (
                <VideoOutlineIcon
                  className={styles.avatarGridGraphicVideoOutlineIcon}
                />
              )}
            </Box>
            {rightAvatarProfile ? (
              <Avatar
                src={rightAvatarProfile}
                alt="Recipient avatar"
                className={styles.avatarGridGraphicAvatarBig}
              />
            ) : (
              <div
                className={`${styles.avatarGridGraphicAvatarBig} ${styles.avatarGridGraphicAvatarEmoji}`}
              >
                <Emoji emoji="👋" size="48px" />
              </div>
            )}
          </div>
        </Box>
      </Box>
      {rightName && (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignSelf="flex-start"
          sx={{
            transform: forceMobile
              ? "translate(-30px, 78px)"
              : {
                  xs: "translate(-30px, 78px)",
                  sm: "translate(12px, 78px)",
                },

            zIndex: 2,
          }}
        >
          <PointerIcon
            sx={{
              fill: "var(--medium-violet)",
              transform: "translate(2px, -100%)",
            }}
          />
          <Box
            className={styles.avatarGridGraphicTalentName}
            bgcolor="var(--medium-violet)"
          >
            <Typography
              color="dark-violet"
              component="span"
              textAlign="center"
              display="block"
              size="small"
              className={styles.avatarGridGraphicTalentNameText}
            >
              {rightName}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

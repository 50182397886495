import { Switch } from "@braintrust/braintrust-ui-components";
import {
  CheckIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { NotificationSetting } from "@js/types/notifications";

import { useNotificationSettings } from "../allow-notifications-switch/use-unsubscribe-all-talent-notifications";

type NotificationSwitchProps = {
  notification: NotificationSetting;
  className?: string;
};

export const NotificationSwitch = ({
  notification,
  className,
}: NotificationSwitchProps) => {
  const { updateNotifications, areNotificationsAllowed } =
    useNotificationSettings();

  return (
    <Switch
      className={className}
      checked={
        areNotificationsAllowed ? (notification.value as boolean) : false
      }
      checkedIcon={<CheckIcon />}
      icon={<CloseIcon />}
      id={notification.notification}
      onChange={(data) => {
        updateNotifications({ ...notification, value: data.target.checked });
      }}
      inputProps={{ "aria-label": notification.label }}
    />
  );
};

import { List, ListItem } from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

const LOOKING_FOR_WORK_SPACE_URL = `/spaces/${SETTINGS.LOOKING_FOR_WORK_SPACE_ID}/`;

const SELF_PROMOTION_RULES = [
  { text: "Unsolicited self-promotion posts will be deleted.", id: 0 },
  { text: "Be kind and respectful.", id: 1 },
  {
    text: (
      <>
        Looking for a job? Check out{" "}
        <RouterLink
          style={{ textDecoration: "underline" }}
          to={LOOKING_FOR_WORK_SPACE_URL}
          target="_blank"
        >
          Looking for work
        </RouterLink>
        – a space for community members to share the types of professional
        opportunities they’re looking to move into.
      </>
    ),
    id: 2,
  },
  {
    text: "Before posting, ask yourself: Did you provide context with a clear ask or action?",
    id: 3,
  },
];

export const EtiquetteRules = () => {
  return (
    <List
      sx={{ padding: 0, pl: 2, mb: 3, listStyle: "decimal" }}
      component="ol"
    >
      {SELF_PROMOTION_RULES.map(({ text, id }) => {
        return (
          <ListItem
            key={id}
            disableGutters
            disablePadding
            sx={{ display: "list-item", fontSize: "13px" }}
          >
            {text}
          </ListItem>
        );
      })}
    </List>
  );
};

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";
import { normalizePath, pluralize } from "@js/utils";

import { useAIRInterview } from "../../hooks/use-air-interview";

type JobManagementNavProps = {
  job: Job;
  totalBidsCount: number;
  totalInterviewingBidsCount: number;
};

export const JobManagementNav = ({
  job,
  totalBidsCount,
  totalInterviewingBidsCount,
}: JobManagementNavProps) => {
  const { pathname } = useLocation();
  const { showAIRInterview } = useAIRInterview();
  const isMobile = useMediaQuery(800);
  const navLinks = useMemo(
    () =>
      getNavLinks({
        job,
        bidsCount: totalBidsCount,
        interviewingBidsCount: totalInterviewingBidsCount,
        airInterviewEnabled: showAIRInterview,
      }),
    [job, showAIRInterview, totalBidsCount, totalInterviewingBidsCount],
  );

  const tabsValue = useMemo(() => {
    const normalizedPathname = normalizePath(pathname);
    const selectedNavLink = navLinks.find(({ path }) => {
      const normalizedNavLinkPath = normalizePath(path);

      return normalizedPathname.startsWith(normalizedNavLinkPath);
    });

    return selectedNavLink?.path ?? navLinks[0]?.path;
  }, [navLinks, pathname]);

  return (
    <Tabs
      data-testid="job-management-nav"
      aria-label="job management navigation tabs"
      orientation={isMobile ? "vertical" : "horizontal"}
      value={tabsValue}
      TabIndicatorProps={{
        sx: {
          display: "none",
        },
      }}
      sx={{
        mb: 3,
        width: "100%",
        "& .MuiTabs-flexContainer": {
          gap: 3,
        },
      }}
    >
      {navLinks.map(({ path, search, label }) => {
        const isActive = tabsValue === path;
        const to = `${path}${search || ""}`;
        return (
          <Tab
            key={label}
            value={path}
            component={RouterLink}
            to={to}
            inactiveColorVariable="grey-3"
            sx={{
              borderBottom: isActive ? "2px solid" : "initial",
              maxWidth: isMobile ? "250px" : "auto",
            }}
            label={
              <Typography
                component="span"
                variant="title"
                size="small"
                fontWeight={400}
              >
                {label}
              </Typography>
            }
          />
        );
      })}
    </Tabs>
  );
};

type GetNavLinksProps = {
  job: Job;
  bidsCount: number;
  interviewingBidsCount: number;
  airInterviewEnabled: boolean;
};

type NavLink = {
  label: string;
  path: string;
  search?: string;
};
export const getNavLinks = ({
  job,
  bidsCount,
  interviewingBidsCount,
  airInterviewEnabled,
}: GetNavLinksProps) => {
  const navLinks: NavLink[] = [
    {
      label: "Invite Talent",
      path: `/jobs/${job.id}/invite_talent/`,
    },
    {
      label: `${bidsCount} Application${pluralize(bidsCount, {
        zeroPlural: true,
      })}`,
      path: `/jobs/${job.id}/proposals/`,
    },
  ];

  return !airInterviewEnabled
    ? navLinks
    : [
        ...navLinks,
        {
          label: `${interviewingBidsCount} Interviewing`,
          path: `/jobs/${job.id}/interviews/`,
          search: "?ordering=-ai_score",
        },
      ];
};

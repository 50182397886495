import React from "react";
import { Field } from "redux-form";

import { Typography } from "@braintrust/braintrust-ui-components";
import { disableOTPAuth, fetchCurrentUser } from "@js/apps/auth/actions";
import { OTPAuthCodeField } from "@js/apps/common/forms/fields/otp/otp-auth-code";
import { Modal, ModalConfirm } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { createFormInstance } from "@js/forms/components";
import { CheckboxField } from "@js/forms/fields/checkbox";
import { useAppDispatch } from "@js/hooks";

import styles from "./styles.module.scss";

const DisableOTPAuthModalInstance = Modal("disable-otp-auth-modal", {
  className: styles.modal,
  closeButton: false,
});

type DisableOTPAuthFormValue = { code: string; is_backup_code: boolean };

const FormInstance = createFormInstance<DisableOTPAuthFormValue, unknown>(
  "disable-otp-auth-form",
);
const initialValues: { is_backup_code: boolean } = { is_backup_code: false };

export const openDisableOTPAuthModal = () => DisableOTPAuthModalInstance.open();

const DisableOTPAuthModal = () => {
  const dispatch = useAppDispatch();

  const onSubmit = (values: DisableOTPAuthFormValue) =>
    disableOTPAuth(values)
      .then(() => {
        return dispatch(fetchCurrentUser());
      })
      .then(() => Snackbar.success("2-Step Authentication disabled."))
      .then(DisableOTPAuthModalInstance.close);

  return (
    <DisableOTPAuthModalInstance>
      <FormInstance onSubmit={onSubmit} initialValues={initialValues}>
        <Typography
          component="h3"
          variant="title"
          fontWeight={400}
          size="small"
          className="mt0 mb+"
        >
          Disable 2-Step Authentication
        </Typography>
        <Typography component="p" className="mb+">
          Enter the 6-digit code generated by your authenticator app.
        </Typography>
        <ModalConfirm
          onCancel={DisableOTPAuthModalInstance.close}
          onConfirm="submit"
          confirmText="Disable"
        >
          <OTPAuthCodeField />
          <Field
            name="is_backup_code"
            component={CheckboxField}
            label="Use backup code instead"
          />
        </ModalConfirm>
      </FormInstance>
    </DisableOTPAuthModalInstance>
  );
};

export default DisableOTPAuthModal;

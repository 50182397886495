import type { ReactNode } from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { ModalInstance } from "@js/components/modal";

import styles from "./match-summary-modal.module.scss";

const MatchSummaryContentWrapper = ({ children }: { children: ReactNode }) => {
  const isMobile = useMediaQuery("sm");
  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      {isMobile && (
        <Typography
          component="h2"
          variant="paragraph"
          size="large"
          fontWeight={400}
          sx={{ mb: 3, pt: 1 }}
        >
          Match Summary
        </Typography>
      )}
      {children}
    </Box>
  );
};

export const openMatchSummaryModal = ({
  children,
}: {
  children: ReactNode;
}) => {
  ModalInstance.close();
  return ModalInstance.open({
    className: styles.modal,
    children: (
      <MatchSummaryContentWrapper>{children}</MatchSummaryContentWrapper>
    ),
    padding: false,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
      className: styles.closeButton,
    },
  });
};

export const closeMatchSummaryModal = () => {
  ModalInstance.close();
};

import { Stack, type StackProps } from "@braintrust/braintrust-ui-components";

type CommonTeamMembersModalListItemContainerProps = StackProps;

export const CommonTeamMembersModalListItemContainer = ({
  children,
  sx,
  ...rest
}: CommonTeamMembersModalListItemContainerProps) => {
  return (
    <Stack
      component="li"
      direction="row"
      sx={{
        height: { xs: "32px", sm: "56px" },
        width: "100%",
        alignItems: "center",
        flexShrink: 0,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
};

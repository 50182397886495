import React from "react";

import { EmployerLogo, Typography } from "@braintrust/braintrust-ui-components";
import type { PublicInvitationToJob } from "@js/types/auth";
import { formatBudget } from "@js/utils";

import style from "./style.module.scss";

type InvitedByUserToJobProps = {
  publicInvitationDetails: PublicInvitationToJob;
};

export const InvitedByUserToJob: React.FC<InvitedByUserToJobProps> = ({
  publicInvitationDetails,
}) => {
  const { job } = publicInvitationDetails;

  return (
    <div className={style.wrapper}>
      <div className={style.logoWrapper}>
        <EmployerLogo src={job.employer} />
      </div>
      <div className={style.jobDetails}>
        <div style={{ minWidth: 0 }}>
          <Typography
            component="p"
            variant="label"
            size="medium"
            ellipsis
            title={job.employer.name}
          >
            {job.employer.name}
          </Typography>
          <Typography
            component="p"
            variant="paragraph"
            size="medium"
            ellipsis
            title={job.title}
          >
            {job.title}
          </Typography>
        </div>
        <Typography component="p" variant="paragraph" size="small">
          {formatBudget(job.budget_minimum_usd, job.budget_maximum_usd, {
            removeDecimal: true,
            paymentType: job.payment_type,
            hourlyRateSuffix: "/hr",
          })}
        </Typography>
      </div>
    </div>
  );
};

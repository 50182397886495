import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import type { SpaceRule } from "@js/types/spaces";

import { useDeleteSpaceRuleMutation } from "../../api";

import style from "./style.module.scss";

type DeleteRuleProps = {
  ruleId: SpaceRule["id"];
  ruleNumber: number;
  spaceId: number;
};

const DeleteRule = ({ ruleId, ruleNumber, spaceId }: DeleteRuleProps) => {
  const [deleteSpaceRuleMutation] = useDeleteSpaceRuleMutation();
  const handleDeleteRule = async () => {
    await deleteSpaceRuleMutation({ ruleId, spaceId });
    ModalInstance.close();
  };

  return (
    <Box className={style.container}>
      <Typography variant="label" size="large" component="p" mb={3}>
        Delete rule
      </Typography>

      <Box className={style.deleteRuleWrapper}>
        <Typography
          variant="title"
          component="p"
          fontWeight={400}
          size="medium"
        >
          Are you sure you want to remove Rule {ruleNumber} from this space?
        </Typography>

        <Typography variant="paragraph" component="p">
          You can’t undo this action.
        </Typography>

        <Button
          onClick={handleDeleteRule}
          variant="destructive"
          shape="squared"
          className={style.deleteRuleWrapperDeleteButton}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};

export type OpenDeleteRuleModalArg = {
  onClose: () => void;
} & DeleteRuleProps;

const openDeleteRuleModal = ({
  onClose,
  ...deleteRuleProps
}: OpenDeleteRuleModalArg) => {
  // close the sortable rule list modal
  ModalInstance.close();

  ModalInstance.open({
    className: style.ruleListModal,
    padding: false,
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    children: <DeleteRule {...deleteRuleProps} />,
    onClose: onClose,
  });
};

export { openDeleteRuleModal };

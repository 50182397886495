import { useMemo } from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import type { PurchaseUpgradeParams } from "@js/apps/auth/api";

type UseDescriptionSectionParams = {
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const useDescriptionSection = ({
  upgradeType,
}: UseDescriptionSectionParams) => {
  const productDescriptionTitle = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return "What are Application Boosts?";

      case "insights":
        return "What is Application Insights?";

      default:
        return "";
    }
  }, [upgradeType]);

  const productDescriptionContent = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return (
          <Typography component="p" variant="paragraph" size="small">
            Application Boost lets you select 3 job applications to boost.
            Boosting your job application places it above similar match
            applicants with a “boost” badge to the employer, increasing your
            visibility and likelihood of securing the job.
          </Typography>
        );

      case "insights":
        return (
          <Box>
            <Typography component="p" variant="paragraph" size="small">
              Application Insights provides personalized feedback for each job,
              using Braintrust AI.
            </Typography>
            <Typography
              component="div"
              variant="paragraph"
              size="small"
              sx={{ "& ul": { margin: 0, paddingInlineStart: "20px" } }}
            >
              <ul>
                <li>Identify jobs where you are a great match</li>
                <li>Compare your standing among others</li>
                <li>Discover strengths and areas to improve</li>
              </ul>
            </Typography>
          </Box>
        );

      default:
        return null;
    }
  }, [upgradeType]);

  const howMuchIsItContent = useMemo(() => {
    switch (upgradeType) {
      case "boost":
        return "For 39 BTRST or $39.00, you get 3 credits to boost your applications whenever you need.";

      case "insights":
        return "For 20 BTRST tokens or $20.00 USD, unlock 30 days of Application Insights. No recurring charges. Renew at your convenience.";

      default:
        return "";
    }
  }, [upgradeType]);

  const extraContent = useMemo(() => {
    switch (upgradeType) {
      case "insights":
        return (
          <Box>
            <Typography component="p" variant="label" fontWeight={500} mb={1.5}>
              What type of Insights can I expect?
            </Typography>
            <Typography component="p" variant="paragraph" size="small">
              For every job, you can uncover critical insights that influence
              your chances of being selected, including:
            </Typography>
            <Typography
              component="div"
              variant="paragraph"
              size="small"
              sx={{ "& ul": { margin: 0, paddingInlineStart: "10px" } }}
            >
              <ul>
                <li>
                  <b>Skill alignment:</b>{" "}
                  <span>
                    How well do your skills match the job's key requirements?
                  </span>
                </li>
                <li>
                  <b>Competition overview:</b>{" "}
                  <span>
                    The number of applicants for this position and your ranking
                    among them if you've applied.
                  </span>
                </li>
                <li>
                  <b>Strengths:</b>{" "}
                  <span>Positive traits that distinguish you from others.</span>
                </li>
                <li>
                  <b>Improvement areas:</b>{" "}
                  <span>
                    Aspects that could lower your likelihood of securing the
                    job.
                  </span>
                </li>
              </ul>
            </Typography>
            <br />
            <Typography component="div" variant="paragraph" size="small">
              Remember that each job is unique, so we present only the most
              relevant insights for you.
            </Typography>
          </Box>
        );

      case "boost":
      default:
        return null;
    }
  }, [upgradeType]);

  return {
    extraContent,
    howMuchIsItContent,
    productDescriptionTitle,
    productDescriptionContent,
  };
};

import { useCallback, useMemo } from "react";
import cs from "classnames";

import {
  Box,
  Divider,
  Pagination,
  PaginationWrapper,
} from "@braintrust/braintrust-ui-components";
import type {
  GridSlotProps,
  GridSortModel,
} from "@braintrust/braintrust-ui-components/components/DataGrid";
import { DataGrid } from "@braintrust/braintrust-ui-components/components/DataGrid";
import {
  CONTRACT_HISTORY_TABLE_QUERY_PARAM_PREFIX,
  useContractHistoryTableFilters,
} from "@js/apps/jobs/apps/offers/components/contracts-history-table/hooks/use-contract-history-table-filters";
import type { OfferHistoryItem } from "@js/apps/jobs/apps/offers/types";
import { RouterLink } from "@js/components/link";
import {
  ColumnHeaderSortIcon,
  EmptyColumnResizeIcon,
} from "@js/components/table-column-header-icons";
import type { PaginatedResult } from "@js/types/generic";

import { CONTRACT_HISTORY_TABLE_COLUMNS } from "./columns";
import type { ContractHistoryTableParamsOrderBy } from "./types";

import styles from "./contracts-history-table.module.scss";

type ContractHistoryTableProps = {
  data: PaginatedResult<OfferHistoryItem>;
  loading: boolean;
  className?: string;
  getTableContentHeight?: (state: {
    rowsMeta: { currentPageTotalHeight: number };
  }) => void;
};

export const ContractHistoryTable = ({
  data,
  loading,
  className,
  getTableContentHeight,
}: ContractHistoryTableProps) => {
  const pageQueryParamName = `${CONTRACT_HISTORY_TABLE_QUERY_PARAM_PREFIX}page`;
  const { filters, handleSortChange } = useContractHistoryTableFilters();
  const sortBy = filters?.order_by;
  const sortDir = filters?.order_dir;

  const sortModel = useMemo<
    [{ field: string; sort: "asc" | "desc" }] | []
  >(() => {
    if (!sortBy || !sortDir) {
      return [];
    }

    return [{ field: sortBy, sort: sortDir }];
  }, [sortBy, sortDir]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const [sortObject] = newSortModel || [];

    handleSortChange({
      sortBy: sortObject?.field as
        | ContractHistoryTableParamsOrderBy
        | undefined,
      sortDir: sortObject?.sort ?? undefined,
    });
  };

  const renderColumnHeaderSortIcon = useCallback(
    (props: GridSlotProps["columnHeaderSortIcon"]) => (
      <ColumnHeaderSortIcon activeSortedColor={null} {...props} />
    ),
    [],
  );

  const showPagination = data.count > filters.page_size;

  return (
    <>
      <Box flexGrow={1}>
        <DataGrid
          autoHeight
          onStateChange={getTableContentHeight}
          className={cs(styles.contractsHistoryTable, className)}
          columns={CONTRACT_HISTORY_TABLE_COLUMNS}
          rows={data?.results || []}
          disableVirtualization
          disableColumnMenu
          disableColumnResize
          disableColumnSelector
          disableMultipleRowSelection
          hideFooter
          rowSelection={false}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          rowHeight={64}
          columnHeaderHeight={36}
          loading={loading}
          slots={{
            columnResizeIcon: EmptyColumnResizeIcon,
            columnHeaderSortIcon: renderColumnHeaderSortIcon,
          }}
        />
        <Divider color="beige" />
      </Box>
      {showPagination && (
        <PaginationWrapper>
          <Pagination
            page={filters.page}
            count={data?.count || 0}
            pageQueryParamName={pageQueryParamName}
            perPage={filters.page_size}
            RouterLink={RouterLink}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

import type { FC } from "react";

import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import type { PurchaseUpgradeParams } from "@js/apps/auth/api";
import type { ModalInstance as ModalInstanceComponent } from "@js/components/modal";

import { useProductDescriptionModal } from "./hooks/use-action-section";

type ActionSectionProps = {
  ModalInstance: typeof ModalInstanceComponent;
  onCancel?: () => void;
  onCloseSuccessPurchaseModal?: () => void;
  openProductDescriptionModal: (param: {
    onCancel?: () => void;
    onCloseSuccessPurchaseModal?: () => void;
    upgradeType: PurchaseUpgradeParams["upgrade_type"];
  }) => void;
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const ActionSection: FC<ActionSectionProps> = ({
  ModalInstance,
  onCancel,
  onCloseSuccessPurchaseModal,
  openProductDescriptionModal,
  upgradeType,
}) => {
  const {
    buttonProceedLabel,
    onClickCheckoutProduct,
    productCost,
    productPackage,
  } = useProductDescriptionModal(
    {
      onCancel,
      openProductDescriptionModal,
      upgradeType,
      onCloseSuccessPurchaseModal,
    },
    ModalInstance,
  );

  return (
    <Box
      position="sticky"
      bottom={0}
      bgcolor="var(--white)"
      py={3}
      px={3.5}
      boxShadow="0px -2px 32px 0px rgba(0, 0, 0, 0.15)"
      display="flex"
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      flexDirection={{ xs: "column", md: "row" }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          sx={{ wordBreak: "keep-all" }}
        >
          <img
            width="14px"
            height="auto"
            src={`${SETTINGS.STATIC_URL}logo/logo-symbol.svg`}
            alt="btrst-logo-symbol"
          />
          <Typography
            component="p"
            size="small"
            variant="label"
            fontWeight={500}
          >
            {productCost} BTRST
          </Typography>
        </Box>
        <Typography component="p" size="small" variant="label" fontWeight={400}>
          or
        </Typography>
        <Typography component="p" size="small" variant="label" fontWeight={500}>
          ${productCost.toFixed(2)}
        </Typography>
        <Typography component="p" size="small" variant="label" fontWeight={400}>
          for {productPackage}
        </Typography>
      </Box>
      <Button
        shape="squared"
        variant="positive-2"
        sx={{
          width: { xs: "100%", md: "unset" },
          textTransform: "capitalize",
          "&.typography": {
            fontWeight: "500 !important",
          },
        }}
        onClick={onClickCheckoutProduct}
      >
        {buttonProceedLabel}
      </Button>
    </Box>
  );
};

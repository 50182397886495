import type { ComponentProps } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import {
  Box,
  GraterThanZeroIntegerNumberFormat,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useEmployerMaxOpeningsNumber } from "@js/apps/jobs/hooks";
import { NumberWithButtonsField } from "@js/forms/fields";

type OpeningsNumberFieldProps = TypedWrappedFieldProps<number> & {
  labelProps?: Partial<TypographyProps>;
  containerProps?: ComponentProps<typeof Box>;
  titleLabel?: string;
  isEditForm: boolean | undefined;
};

export const OpeningsNumberField = ({
  labelProps,
  containerProps,
  titleLabel,
  input,
  meta,
  isEditForm,
}: OpeningsNumberFieldProps) => {
  const maxOpeningsNumber = useEmployerMaxOpeningsNumber();
  const label = titleLabel || "How many open roles?";
  const { jobToEdit } = useJobFormContext();
  const hiredTalentCount = jobToEdit?.hired_bids_count;

  const minOpeningsNumber =
    isEditForm && hiredTalentCount ? hiredTalentCount : 1;

  return (
    <Box mb={6} id="openings-number">
      <Box {...containerProps}>
        <Typography
          {...labelProps}
          component="label"
          display="block"
          variant="label"
          htmlFor="openings_number-id"
          id="openings-number-label"
        >
          {label}
        </Typography>
        <NumberWithButtonsField
          input={input}
          meta={meta}
          id="openings_number-id"
          center
          radius
          width={80}
          min={minOpeningsNumber}
          max={maxOpeningsNumber}
          NumberFormat={GraterThanZeroIntegerNumberFormat}
          hideError
          displayDecrementTooltip={
            minOpeningsNumber > 1 && Number(input.value) <= minOpeningsNumber
          }
          tooltipTitle={`You already hired ${minOpeningsNumber} Talent, openings number can't be lower then that.`}
        />
      </Box>
      {!!meta.error && (
        <Typography mt={2} component="p" variant="paragraph" color="negative">
          {meta.error}
        </Typography>
      )}
    </Box>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { DownloadArrowIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { Attachment } from "@js/types/uploads";

import { EmbeddedResumeViewer } from "./embedded-resume-viewer";

import styles from "./styles.module.scss";

export const ResumeViewer = ({
  resumeFile,
  rightButtonSlot,
  resumeFileDownloadUrl,
}: {
  resumeFile: Attachment["file"];
  resumeFileDownloadUrl: Attachment["file_download_url"];
  rightButtonSlot?: JSX.Element;
}) => {
  if (!resumeFile || !resumeFileDownloadUrl) return null;

  return (
    <div>
      <Box mb={2.5} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography component="label" variant="label">
            <a
              download
              href={resumeFileDownloadUrl}
              className={styles.resumeViewerDownload}
            >
              <DownloadArrowIcon /> Download
            </a>
          </Typography>
        </Box>
        {rightButtonSlot || null}
      </Box>
      <EmbeddedResumeViewer sourceFile={resumeFile} />
    </div>
  );
};

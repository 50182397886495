export const CHECKLIST_ITEMS = {
  TWO_FACTOR: "TWO_FACTOR",
  BANKING: "BANKING",
  TAX_DOCS: "TAX_DOCS",
  PAYMENT_TERMS: "PAYMENT_TERMS",
  INVOICING: "INVOICING",
} as const;

export type ChecklistItemType = ObjectValues<typeof CHECKLIST_ITEMS>;

export const reviewInvoicingAction = {
  type: CHECKLIST_ITEMS.INVOICING,
  header: "Review how invoicing works",
  subheader:
    "Make sure you have all the information you need to submit your first invoice",
  description: (
    <>
      <p>
        You will submit invoices on the Braintrust platform through{" "}
        <a
          href="https://app.usebraintrust.com/talent/invoices"
          rel="noopener noreferrer"
          target="_blank"
        >
          this
        </a>{" "}
        portal.
      </p>
      <p>
        Our{" "}
        <a
          href={SETTINGS.SUPPORT_URLS.TALENT_FAQ_ARTICLE_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          FAQ
        </a>{" "}
        is the best place to start with questions on how invoicing works on
        Braintrust.
      </p>
      <p>
        If you have any questions about payment terms or invoices - please reach
        out to us first!
      </p>
    </>
  ),
};

export const reviewPaymentTermsAction = {
  type: CHECKLIST_ITEMS.PAYMENT_TERMS,
  header: "Review payment terms",
  subheader:
    "Review terms and conditions, payment terms and frequency for this job",
  description: (
    <>
      <p>
        You are entering into a contract with Braintrust and Braintrust pays
        your invoices as soon as the client pays.
      </p>
      <p>
        When your invoice is paid, it has to go through our partner, Wise in
        order to get into your account. Wise takes 7-10 days, but sometimes this
        happens sooner.
      </p>
      <p>
        When you joined Braintrust, you signed up for our Terms and Conditions,
        which are applicable to this contract. Please review{" "}
        <a
          href={SETTINGS.TERMS_DOC_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </>
  ),
};

import React from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import type { Job } from "@js/types/jobs";

import { MatchItem } from "./matcher-item";

export const MatchersListing = ({ job }: { job: Job }) => {
  return (
    <Box maxWidth={1}>
      {job.creator && (
        <Box mb={1}>
          <MatchItem
            user={job.creator}
            name={job.creator.public_name}
            title="Employer"
            job={job}
            actionIconType="view"
          />
        </Box>
      )}

      {job.op_owners?.map((op_owner) => (
        <Box mb={1} key={op_owner.id}>
          <MatchItem
            user={op_owner}
            name={op_owner.public_name}
            actionIconType="view"
            job={job}
            title="Braintrust Matcher"
          />
        </Box>
      ))}

      {job.sale_owners?.map((sale_owner) => (
        <Box mb={1} key={sale_owner.id}>
          <MatchItem
            user={sale_owner}
            name={sale_owner.public_name}
            actionIconType="view"
            job={job}
            title="Hiring Manager"
          />
        </Box>
      ))}
    </Box>
  );
};

import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { useMessagesContext } from "@js/apps/messenger/context";
import { useMessengerContext } from "@js/apps/messenger/context/messenger-context";
import { useActiveRoomData } from "@js/apps/messenger/hooks/use-active-room-data";

import type { TextBoxProps } from "..";
import { MessagesBox } from "../messages-box";
import { MessengerContentContainer } from "../messenger-content-container";
import { RoomList } from "../room-list";
import { TextBox } from "../textbox";

import styles from "./styles.module.scss";

export type MessengerProps = {
  textBoxProps?: Omit<TextBoxProps, "room">;
};

export const Messenger = ({ textBoxProps }: MessengerProps) => {
  const { activeRoom, setActiveRoom } = useMessengerContext();
  const isMobile = useMediaQuery("md");
  const { activeRoomData, isLoading } = useActiveRoomData(activeRoom);
  const { textboxPlaceholder } = useMessagesContext();

  return (
    <div className={styles.messenger}>
      <RoomList
        activeRoom={activeRoom}
        setActiveRoom={setActiveRoom}
        isActiveRoomLoading={isLoading}
      />
      {!isMobile && (
        <MessengerContentContainer>
          <MessagesBox room={activeRoomData} />
          <TextBox
            room={activeRoomData}
            placeholder={textboxPlaceholder}
            {...textBoxProps}
          />
        </MessengerContentContainer>
      )}
    </div>
  );
};

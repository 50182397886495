import { Box, Typography } from "@braintrust/braintrust-ui-components";

import style from "./style.module.scss";

export const SectionBanner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "8px",
        padding: "8px",
        borderRadius: "16px",
        background:
          "linear-gradient(90deg, #D4E4F9 0.14%, #E5D7FB 48.5%, #FFF5E3 76.06%, #FFFDBD 99.97%)",
      }}
    >
      <img
        src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-star.svg`}
        alt="ai job icon star"
        width={27}
        height={27}
      />
      <Typography
        component="span"
        fontWeight={500}
        size="small"
        color="dark-violet"
        sx={{
          border: "2px solid var(--medium-violet)",
          borderRadius: "35px",
          padding: "4px 12px",
          backgroundColor: "var(--soft-violet)",
          whiteSpace: "nowrap",
        }}
      >
        AI generated questions
      </Typography>
      <Typography
        component="span"
        fontWeight={400}
        size="small"
        className={style.interviewBanner}
        textAlign="center"
      >
        Please review the recommended questions based on your position.
      </Typography>
    </Box>
  );
};

import {
  Box,
  Button,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { openInterviewModal } from "@js/apps/interview";
import { useNavigate } from "@js/hooks";

import { BidCreateSuccessModalCloseButton } from "../bid-create-success-modal-close-button";

type StartScreeningModalContentProps = {
  closeModal: () => void;
};

export const StartScreeningModalContent = ({
  closeModal,
}: StartScreeningModalContentProps) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("sm");

  const returnToJobsList = () => {
    navigate("/jobs/");
    closeModal();
  };

  const onStartScreeningClick = () => {
    closeModal();
    openInterviewModal({
      onClose: () => {
        navigate("/jobs/");
      },
    });
  };

  return (
    <Stack sx={{ maxWidth: "800px" }}>
      <Stack
        sx={{
          background: "var(--medium-green)",
          p: { xs: 3, sm: 4 },
        }}
      >
        <BidCreateSuccessModalCloseButton
          variant="gray-green"
          onClick={returnToJobsList}
          sx={{
            position: "absolute",
            top: { xs: "14px", sm: "19px" },
            right: { xs: "14px", sm: "19px" },
          }}
        />

        <Typography
          component="h3"
          variant="label"
          size="medium"
          fontWeight={500}
          sx={{ mb: 1 }}
        >
          Application sent 🥳
        </Typography>
        <Typography
          component="h2"
          variant="title"
          size="small"
          fontWeight={400}
          sx={{ mb: 2 }}
        >
          Start your video screening!
        </Typography>
        <Typography component="p" variant="paragraph" mb={3}>
          We require all our job-seeking talent to complete a 10-minute video
          screening, where you answer four questions.
          <br />
          This isn't a technical test but a chance for us to learn more about
          you and your past experiences to match you with suitable jobs on our
          platform. If you pass the screening process, you'll earn an Approved
          Talent badge, and your application will be sent to the client.
        </Typography>
        <Box sx={{ alignSelf: "center" }}>
          <Button
            variant="primary"
            shape="squared"
            onClick={onStartScreeningClick}
            size={isMobile ? "small" : "medium"}
          >
            Start screening
          </Button>
        </Box>
      </Stack>
      <Box
        sx={{
          backgroundColor: "var(--off-white)",
          p: { xs: 3, sm: "36px 32px" },
        }}
      >
        <Typography component="p" variant="paragraph">
          Not ready to start your screening just yet? We've sent you an email
          with instructions so when you're ready, simply follow the steps
          outlined in the email.
        </Typography>
      </Box>
    </Stack>
  );
};

import { Box, Switch } from "@braintrust/braintrust-ui-components";
import {
  CheckIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useUpdateNotificationsSettingsMutation } from "@js/apps/settings/api";
import type { NotificationSetting } from "@js/types/notifications";

import styles from "./styles.module.scss";

type UnsubscribeAllFieldProps = {
  notification: NotificationSetting;
};
export const AllowNotificationsSwitch = ({
  notification,
}: UnsubscribeAllFieldProps) => {
  const [updateNotifications] = useUpdateNotificationsSettingsMutation();

  const handleChange = (value: boolean) => {
    updateNotifications([{ ...notification, value: value }]);
  };

  return (
    <Box className={styles.unsubscribeAllNotifications}>
      <Switch
        checked={notification.value as boolean}
        onChange={(ev) => {
          handleChange(ev.target.checked);
        }}
        checkedIcon={<CheckIcon />}
        icon={<CloseIcon />}
        id={notification.notification}
        name={notification.notification}
        color="primary"
        label={notification.label}
        LabelProps={{
          labelPlacement: "start",
          style: { width: "fit-content" },
        }}
        inputProps={{ "aria-label": notification.label }}
      />
    </Box>
  );
};

import React, { useMemo } from "react";
import cs from "classnames";

import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box } from "@braintrust/braintrust-ui-components";

import styles from "./styles.module.scss";

type ProgressBarNudgeProps = BoxProps & {
  value: number;
  maxValue?: number;
  darkProgressBar?: boolean;
};

const COLORS = [
  {
    range: { min: 0, max: 20 },
    color: "var(--orange)",
  },
  {
    range: { min: 21, max: 79 },
    color: "var(--peach)",
  },
  {
    range: { min: 80, max: 100 },
    color: "var(--positive-2)",
  },
];

export const ProgressBarNudge: React.FC<
  React.PropsWithChildren<ProgressBarNudgeProps>
> = ({ value, maxValue = 100, darkProgressBar, children, ...rest }) => {
  const color = useMemo(() => {
    const selectedColor = COLORS.find((el) => {
      return el.range.min <= value && el.range.max >= value;
    });
    return selectedColor?.color as string;
  }, [value]);

  return (
    <Box
      className={cs(styles.nudgeProgressBar, {
        [styles.nudgeProgressBarDark]: darkProgressBar,
      })}
      display="flex"
      overflow="hidden"
      height={8}
      flexDirection="row"
      borderRadius={8}
      width="100%"
      {...rest}
    >
      <Box
        display="inline-block"
        bgcolor={color}
        width={`${(value / maxValue) * 100}%`}
        height={100}
      >
        {children}
      </Box>
    </Box>
  );
};

import { useMemo } from "react";

import {
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import {
  BraintrustIcon,
  CloseIcon,
  InfoIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

const PendingTransactionModal = (): JSX.Element => {
  const isMobile = useMediaQuery("sm");

  const mb = useMemo(() => {
    return isMobile ? "16px" : "24px";
  }, [isMobile]);

  return (
    <Grid container height="100%">
      <Grid
        item
        container
        mb={mb}
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          component="p"
          variant="label"
          size="medium"
          mb={0}
          className={styles["onramp-modal__title"]}
        >
          Purchase
          <BraintrustIcon
            style={{
              fontSize: "inherit",
              verticalAlign: "text-bottom",
              marginLeft: "6px",
              marginRight: "6px",
            }}
          />
          BTRST
        </Typography>
        <IconButton
          variant="tertiary"
          size="small"
          aria-label="Close pending BTRST purchase modal"
          onClick={() => ModalInstance.close()}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        mb={mb}
        xs={12}
        className={styles["onramp-pending-modal__main-section"]}
      >
        <div className={styles["onramp-pending-modal__main-content"]}>
          <Typography
            component="p"
            variant="title"
            size={isMobile ? "small" : "medium"}
            mb={mb}
            fontWeight={400}
          >
            Your BTRST will be available soon
          </Typography>
          <Typography
            component="p"
            variant="paragraph"
            size={isMobile ? "medium" : "large"}
            mb={mb}
          >
            Once your purchase on Coinbase is completed, your tokens will be
            transferred to your Braintrust wallet. This usually takes a few
            minutes but can take up to 3 hours.
          </Typography>
          <Typography
            component="p"
            variant="paragraph"
            size={isMobile ? "medium" : "large"}
          >
            We’ll let you know when it’s available.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} mb={mb}>
        <div className={styles["onramp-pending-modal__info"]}>
          <InfoIcon />
          <Typography component="p" variant="paragraph" size="small">
            If you’ve already made a purchase on Coinbase, please close this
            modal.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} mb={mb} display="flex" justifyContent="flex-end">
        <Button
          onClick={() => ModalInstance.close()}
          variant="primary"
          shape="squared"
          size="medium"
        >
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export const openPendingTransactionModal = () => {
  ModalInstance.open({
    closeButton: false,
    padding: false,
    className: styles["onramp-pending-modal"],
    children: <PendingTransactionModal />,
  });
};

import { useEffect, useRef, useState } from "react";
import { Fields } from "redux-form";
import { isString } from "underscore";
import uuid4 from "uuid4/browser";

import { FormHelperText } from "@braintrust/braintrust-ui-components";
import { useLazyGetLinkMetadataQuery } from "@js/apps/common/api";
import type { TypedWrappedFieldProps } from "@js/forms/utils";
import type { LinkMetadata } from "@js/types/common";
import { isNotNullable } from "@js/utils";

import type { Link } from "./helpers";
import {
  deduplicateLinks,
  getExcludedLinks,
  getLinksFromText,
} from "./helpers";

export const LinksFromTextAdapterWrapper = () => {
  return (
    <Fields
      names={[
        "text",
        "links_from_text",
        "excluded_links_from_text",
        "links_metadata_from_text",
        "links",
      ]}
      component={LinksFromTextAdapter}
    />
  );
};

export type LinksFromTextAdapterProps = {
  text: TypedWrappedFieldProps<string>;
  excluded_links_from_text: TypedWrappedFieldProps<string[] | "">;
  links_from_text: TypedWrappedFieldProps<string[]>;
  links_metadata_from_text: TypedWrappedFieldProps<LinkMetadata[]>;
  links: TypedWrappedFieldProps<Link[] | "">;
};

export const LinksFromTextAdapter = ({
  text,
  links_from_text,
  excluded_links_from_text,
  links_metadata_from_text,
  links,
}: LinksFromTextAdapterProps) => {
  const textValue = text.input.value;
  const totalLinksLimit = SETTINGS.POST_LINK_ATTACHMENTS_LIMIT ?? Infinity;

  const isInitializedRef = useRef(false);

  const [getLinkMetadata] = useLazyGetLinkMetadataQuery();
  const [linksLimitReached, setLinksLimitReached] = useState(false);

  useEffect(() => {
    if (!isInitializedRef.current) {
      isInitializedRef.current = true;

      return;
    }

    let isCancelled = false;

    const timeout = setTimeout(async function updateLinksMetadata() {
      const excludedLinksFromTextValue =
        excluded_links_from_text.input.value || [];
      const foundLinks = getLinksFromText(textValue);
      const filteredLinks = deduplicateLinks(
        links,
        foundLinks,
        excludedLinksFromTextValue,
      );

      const manuallyAddedLinksCount = links.input.value?.length || 0;
      const totalLinkCount = filteredLinks.length + manuallyAddedLinksCount;
      setLinksLimitReached(totalLinkCount > totalLinksLimit);

      excluded_links_from_text.input.onChange(
        getExcludedLinks({
          foundLinks,
          currentExcludedLinks: excludedLinksFromTextValue,
        }),
      );

      const foundLinksToPreviewCount =
        totalLinksLimit - manuallyAddedLinksCount;

      if (foundLinksToPreviewCount <= 0) {
        links_metadata_from_text.input.onChange([]);
        links_from_text.input.onChange([]);

        return;
      }

      const foundLinksToPreview = filteredLinks.slice(
        0,
        foundLinksToPreviewCount,
      );

      const foundLinksMetaDataPromiseArray = foundLinksToPreview.map((url) =>
        getLinkMetadata({ url }, true),
      );

      const foundLinksMetadata = await Promise.all(
        foundLinksMetaDataPromiseArray,
      );

      if (isCancelled) {
        return;
      }

      const validFoundLinksMetaData = foundLinksMetadata
        .map(({ data }) => data)
        .filter(isNotNullable);

      links_metadata_from_text.input.onChange(
        validFoundLinksMetaData.map((data) => ({
          ...data,
          id: uuid4(),
          isFromText: true,
        })),
      );

      links_from_text.input.onChange(
        validFoundLinksMetaData.map((data) => data?.url).filter(isString),
      );
    }, 550);

    return () => {
      clearInterval(timeout);
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLinkMetadata, textValue]);

  if (linksLimitReached) {
    return (
      <FormHelperText sx={{ color: "var(--dark-orange)" }}>
        Limit of {SETTINGS.POST_LINK_ATTACHMENTS_LIMIT} link previews has been
        exceeded.
        <br />
        You can add as many links as you want, but only 3 of them will show up
        as previews.
      </FormHelperText>
    );
  }

  return null;
};

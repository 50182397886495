import { IconButton, Typography } from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";

import styles from "../../styles.module.scss";

type TitleProps = {
  text: string;
};

export const Title = ({ text }: TitleProps) => {
  return (
    <Typography
      component="h3"
      variant="paragraph"
      size="large"
      fontWeight={500}
    >
      {text}
    </Typography>
  );
};

type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = ({ onClose }: CloseButtonProps) => {
  return (
    <IconButton
      className={styles.headerCloseBtn}
      variant="tertiary"
      size="small"
      aria-label="Close location mismatch modal"
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
};

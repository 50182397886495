import React from "react";

import {
  Divider,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import type { MatchSummary } from "@js/types/jobs";

import { MatchSummaryList } from "../match-summary";

type BidApplicationMatchSummaryProps = {
  freelancerFirstName: string;
  matchSummary: MatchSummary[];
  matchLabel: keyof typeof ENUMS.SmartMatchingLabels;
};

export const BidApplicationMatchSummary = ({
  freelancerFirstName,
  matchLabel,
  matchSummary,
}: BidApplicationMatchSummaryProps) => {
  return (
    <Stack sx={{ mb: 2 }}>
      <Typography
        component="h3"
        variant="paragraph"
        size="large"
        fontWeight={400}
        sx={{ mb: 2 }}
      >
        {freelancerFirstName} is {formatMatchLabel(matchLabel)} because:
      </Typography>
      <MatchSummaryList
        matchSummary={matchSummary}
        sentenceProps={{ size: "medium" }}
      />
      <Divider color="beige" sx={{ mt: 3 }} />
    </Stack>
  );
};

const formatMatchLabel = (
  matchLabel: keyof typeof ENUMS.SmartMatchingLabels,
) => {
  const label = ENUMS.SmartMatchingLabels[matchLabel];
  switch (label) {
    case ENUMS.SmartMatchingLabels.recommended_by_matcher:
      return `${label}`;
    case ENUMS.SmartMatchingLabels.ok:
      return `an ${label}`;
    default:
      return `a ${label.toLowerCase()}`;
  }
};

import { Box } from "@braintrust/braintrust-ui-components";
import { SubheaderPortal } from "@js/layouts/app/components/subheader-portal";

export type HeaderPortalProps = {
  rightSocket?: JSX.Element;
  leftSocket?: JSX.Element;
};

export const HeaderPortal = ({
  rightSocket,
  leftSocket,
}: HeaderPortalProps) => {
  return (
    <SubheaderPortal>
      <Box
        py={2.5}
        px={{ xs: 2, md: 7.5 }}
        borderTop="1px solid var(--grey-4)"
        borderBottom="1px solid var(--grey-4)"
      >
        <Box
          flexWrap={{ xs: "wrap", md: "nowrap" }}
          gap={1}
          display="flex"
          justifyContent="space-between"
          maxWidth="1320px"
          margin="auto"
        >
          {leftSocket}
          {rightSocket}
        </Box>
      </Box>
    </SubheaderPortal>
  );
};

import type { CSSProperties, ReactNode } from "react";
import { forwardRef, useCallback, useRef } from "react";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";

import { Stack } from "@braintrust/braintrust-ui-components";
import { BidListEmptyState } from "@js/apps/jobs/components";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useSelectBid } from "../../hooks";
import { useJobBidListScroll } from "../../hooks/use-job-bid-list-scroll";

import { bidItemContent } from "./virtualized-job-bid-list-item";

import styles from "./style.module.scss";

export type VirtualizedJobBidListProps = {
  bidList: EmployerBidListBid[];
  job: Job;
  areFiltersDefault: boolean;
};

export const VirtualizedJobBidList = ({
  job,
  areFiltersDefault,
  bidList = [],
}: VirtualizedJobBidListProps) => {
  const ref = useRef<VirtuosoHandle>(null);
  const { isSelected, toggleSelect } = useSelectBid();
  useJobBidListScroll({ ref, count: bidList.length });

  const handleOnCheckboxChange = useCallback(
    (bidId: number) => {
      toggleSelect(bidId);
    },
    [toggleSelect],
  );

  if (!bidList.length && areFiltersDefault) {
    return (
      <div className={styles.wrapper} data-testid="bid-list-empty-state">
        <BidListEmptyState job={job} />
      </div>
    );
  }

  return (
    <Virtuoso
      ref={ref}
      components={Components}
      useWindowScroll
      // The height of the item is 175px, but we need to add 1px to make sure the scroll is triggered via the Virtuoso ref.
      // Without this additional 1px, the scroll is not triggered.
      defaultItemHeight={176}
      increaseViewportBy={500}
      totalCount={bidList.length}
      itemContent={(index) => {
        const bidItem = bidList[index];
        return bidItemContent({
          index,
          bid: bidItem,
          job,
          selected: isSelected(bidItem.id),
          onCheckboxChange: handleOnCheckboxChange,
        });
      }}
    />
  );
};

const Components = {
  List: forwardRef<
    HTMLDivElement,
    { style?: CSSProperties; children?: ReactNode }
  >(({ style, children }, listRef) => {
    return (
      <Stack
        style={style}
        className={styles.wrapper}
        ref={listRef}
        data-testid="job-bid-list"
      >
        {children}
      </Stack>
    );
  }),
};

import { useMemo } from "react";
import type { FormErrors } from "redux-form";
import { Fields } from "redux-form";
import cs from "classnames";

import { Box, Stack } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { ResourcesContextProvider } from "@js/apps/give-and-get-help/context";
import type { ReplyType } from "@js/apps/give-and-get-help/types";
import { UserAvatar } from "@js/components/user-avatar";
import { createFormInstance } from "@js/forms/components";
import { maxLength } from "@js/forms/validators";
import type { PostComment } from "@js/types/give-and-get-help";
import { validateAttachments } from "@js/utils/redux-form";

import { PostAttachmentsWrapper } from "../../components/create-or-edit-post-modal/attachments-wrapper";
import { DESCRIPTION_MAX_LENGTH } from "../../constants";
import { usePostsContext } from "../../context/posts";
import type { CreateOrEditPostFormData } from "../../form";
import { REPLY_FORM_ID } from "../../form/constants";
import { useCreateOrEditReply } from "../../hooks";
import { useDisableSpecificPostActions } from "../../hooks/use-disable-specific-post-actions";
import { POST_FIELD_TEXT } from "../fields/post-description-field/constants";
import { ReplyTextField } from "../fields/reply-text-field";

import { useReply } from "./hooks/use-reply";
import { ReplyAttachmentFields } from "./reply-attachment-fields";
import { EditReplyFormActions, ReplyFormActions } from "./reply-form-actions";

import styles from "./style.module.scss";

export type ReplyOpenedField = "attachment_file" | "link" | "offer";

export type ReplyFormData = CreateOrEditPostFormData & {
  openedFields?: ReplyOpenedField[];
};

const validate = (values: CreateOrEditPostFormData) => {
  const errors: FormErrors<CreateOrEditPostFormData, string> = {};
  const charLimit = maxLength(DESCRIPTION_MAX_LENGTH)(values[POST_FIELD_TEXT]);
  if (!values[POST_FIELD_TEXT]) {
    errors[POST_FIELD_TEXT] = "This field is required.";
  }
  if (charLimit) {
    errors[POST_FIELD_TEXT] = charLimit;
  }

  const attachmentsError = validateAttachments(values);
  if (attachmentsError) {
    errors["attachments"] = attachmentsError;
  }

  return errors;
};

const ReplyFormInstance = createFormInstance<ReplyFormData, unknown>(
  REPLY_FORM_ID,
  {
    validate,
    enableReinitialize: false,
    touchOnChange: true,
    destroyOnUnmount: false,
  },
);

export type ReplyFormProps = {
  id: number;
  formId: string;
  type: ReplyType;
  expanded?: boolean;
  onCloseReply?: (postId: number) => void;
  onReplyClick?: () => void;
  onAddReply?: (commentId: number) => void;
  commentToReply?: PostComment | null;
  commentToEdit?: PostComment | null;
  isCelebratoryPost?: boolean;
  "data-testid"?: string;
};

export const ReplyForm = ({
  id,
  formId,
  type,
  expanded,
  onCloseReply,
  onAddReply,
  onReplyClick,
  commentToReply,
  commentToEdit,
  isCelebratoryPost = false,
  "data-testid": dataTestId,
}: ReplyFormProps) => {
  const disablePostActions = useDisableSpecificPostActions();
  const { onReplyFormInit, onReplyFormExpand, expandedReplyForms } =
    usePostsContext();

  const isReplyExpanded = useMemo(() => {
    if (expanded) {
      return true;
    }

    return expandedReplyForms.includes(formId);
  }, [expanded, expandedReplyForms, formId]);

  const { onSubmit, onSubmitSuccess, isLoading, initialValues } =
    useCreateOrEditReply({
      id,
      onCloseReply,
      onAddReply,
      commentToEdit,
      commentToReply,
    });

  const { budget, canGiveOffer } = useReply(isCelebratoryPost);
  const user = useUser();

  const linksEditOnClickActive =
    (!!commentToEdit && !!commentToEdit?.links_metadata.length) || undefined;

  const isMobile = useMediaQuery("sm");

  const handleInputMouseDown = () => {
    onReplyClick?.();
    onReplyFormExpand(formId);
  };

  const handleInputFocus = () => {
    onReplyFormExpand(formId);
  };

  useEffectOnce(() => {
    onReplyFormInit(formId);
  });

  const handleInputChange = () => {
    if (isReplyExpanded) {
      return;
    }
    onReplyClick?.();
    onReplyFormExpand(formId);
  };

  return (
    <ResourcesContextProvider>
      <ReplyFormInstance
        form={formId}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      >
        <Box display="flex" alignItems="flex-start" gap={1} mt={2}>
          {user && (
            <UserAvatar
              className={styles.avatar}
              user={commentToEdit?.freelancer.user || user}
              hideBorder
              hideBadge
              disableShadow
              size={isMobile ? "sm" : "md"}
            />
          )}
          <Box
            data-testid={`reply-form-${id}`}
            className={cs(styles.replyForm, {
              [styles.replyFormExpanded]: isReplyExpanded,
            })}
            onMouseDown={handleInputMouseDown}
            onFocus={handleInputFocus}
          >
            <ReplyTextField
              user={user}
              type={type}
              data-testid={dataTestId}
              onChange={handleInputChange}
            />

            <Stack sx={{ gap: 1 }}>
              {commentToEdit ? (
                <EditReplyFormActions
                  replyType={type}
                  isLoading={isLoading}
                  disabled={disablePostActions}
                  canGiveOffer={canGiveOffer}
                  onCancel={() => onCloseReply && onCloseReply(id)}
                />
              ) : (
                <ReplyFormActions
                  replyType={type}
                  isExpanded={isReplyExpanded}
                  isLoading={isLoading}
                  disabled={disablePostActions}
                  canGiveOffer={canGiveOffer}
                />
              )}
              <ReplyAttachmentFields postBudget={budget} type={type} />
              <Fields
                names={[
                  "attachments",
                  "links",
                  "links_metadata",
                  "links_metadata_from_text",
                  "links_from_text",
                  "excluded_links_from_text",
                  "openedFields",
                ]}
                component={PostAttachmentsWrapper}
                linksEditOnClickActive={linksEditOnClickActive}
              />
            </Stack>
          </Box>
        </Box>
      </ReplyFormInstance>
    </ResourcesContextProvider>
  );
};

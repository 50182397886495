import { forwardRef } from "react";

import { Button } from "@braintrust/braintrust-ui-components";
import { AddReactionIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { AnchorProps } from "@js/apps/give-and-get-help/components/stickers-module/stickers-popover";

export const ReactSticker = forwardRef<HTMLButtonElement, AnchorProps>(
  ({ isTouchDevice, isOpen, disabled, onClick, ...rest }, ref) => {
    return (
      <Button
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        sx={{
          touchAction: isTouchDevice ? "none" : undefined,
          "&:hover": {
            backgroundColor: "var(--grey-4)",
          },
        }}
        disabledType="opacity"
        variant={isOpen ? "violet-with-dark-border" : "white-border-beige"}
        size="x-small"
        shape="squared"
        aria-label="open sticker menu"
        {...rest}
      >
        <AddReactionIcon style={{ width: "20px", height: "20px" }} />
      </Button>
    );
  },
);

import { useCallback, useState } from "react";

import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { useAccountType } from "@js/apps/common/hooks";
import { navItemClicked } from "@js/apps/freelancer/actions";
import type {
  UniversalSearchJobsResponse,
  UniversalSearchPopularPostsResponse,
  UniversalSearchTalentResponse,
} from "@js/apps/universal-search/api";
import { useUniversalSearchPopularSearches } from "@js/apps/universal-search/hooks/use-universal-search-popular-searches";
import { NAV_ITEM_IDS } from "@js/constants";
import { useAppDispatch } from "@js/hooks";
import { useCheckIsEmailVerified } from "@js/hooks/check-is-email-verified";

import type { UniversalTabValue } from "../constants";
import { useDebouncedUniversalSearchPhrase } from "../universal-search-slice";

import { useUniversalSearchModalTab } from "./use-universal-search-modal-tab";
import { useUniversalSearchSearches } from "./use-universal-search-searches";

export type UniversalSearchCurrentData = {
  jobResults: UniversalSearchJobsResponse | undefined;
  postResults: UniversalSearchPopularPostsResponse | undefined;
  talentResults: UniversalSearchTalentResponse | undefined;
};

type UniversalSearchFormHookReturn = {
  isUniversalSearchOpen: boolean;
  currentTab: UniversalTabValue;
  isMobile: boolean;
  onFieldFocus: () => void;
  onTabChange: (tab: UniversalTabValue) => void;
  handleSetOpen: (value?: boolean) => void;
  isLoading: boolean;
  isFetching: boolean;
  currentData?: UniversalSearchCurrentData;
  canAccessGiveAndGetHelp: boolean;
  searchQuery?: string;
};

export const useUniversalSearchForm = (): UniversalSearchFormHookReturn => {
  const dispatch = useAppDispatch();
  const search = useDebouncedUniversalSearchPhrase();

  const [open, setOpen] = useState(false);
  const { changeCurrentTab, currentTab } = useUniversalSearchModalTab();
  const { redirectIfUnverified } = useCheckIsEmailVerified();

  const { isEmployer, isFreelancer } = useAccountType();
  const canAccessGiveAndGetHelp = !isEmployer;
  const isMobile = useMediaQuery(615);

  const { popularData, isLoadingPopularData, isFetchingPopularData } =
    useUniversalSearchPopularSearches({
      currentTab,
      open,
    });

  const { searchData, isLoadingSearchData, isFetchingSearchData } =
    useUniversalSearchSearches({
      currentTab,
      open,
    });

  const shouldUseSearchData = search.length > 0;

  const handleSetOpen = useCallback((value?: boolean) => {
    setOpen((prev) => {
      return typeof value !== "undefined" ? value : !prev;
    });
  }, []);

  const handleOnFieldFocus = () => {
    if (isFreelancer) {
      redirectIfUnverified();
    }

    setOpen(true);
    dispatch(
      navItemClicked({
        nav_item_id: NAV_ITEM_IDS.HEADER_SEARCH,
        context_url: window.location.pathname,
      }),
    );
  };

  const onTabChange = (tab: UniversalTabValue) => {
    changeCurrentTab(tab);
  };

  return {
    isUniversalSearchOpen: open,
    canAccessGiveAndGetHelp,
    currentTab,
    isMobile,
    onFieldFocus: handleOnFieldFocus,
    onTabChange,
    handleSetOpen,
    searchQuery: search,
    currentData: shouldUseSearchData ? searchData : popularData,
    isLoading: shouldUseSearchData ? isLoadingSearchData : isLoadingPopularData,
    isFetching: shouldUseSearchData
      ? isFetchingSearchData
      : isFetchingPopularData,
  };
};

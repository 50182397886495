import React from "react";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import { Box, Tooltip, Typography } from "@braintrust/braintrust-ui-components";
import { BraintrustIcon } from "@braintrust/braintrust-ui-components/Icons";
import { formatTokenAmount, roundBalance } from "@js/utils";

export type MyWalletBalanceProps = {
  balance: string | number;
  textStyle?: React.CSSProperties;
  variant?: TypographyProps["variant"];
  size?: TypographyProps["size"];
  fontWeight?: TypographyProps["fontWeight"];
  iconStyle?: React.CSSProperties;
  "data-testid"?: string;
};

export const MyWalletBalance = ({
  balance,
  textStyle = {},
  variant = "title",
  size,
  fontWeight = 500,
  iconStyle = {},
  "data-testid": testId,
}: MyWalletBalanceProps) => {
  const balanceToDisplay = balance ? roundBalance(balance) : "0";

  return (
    <Box display="flex" alignItems="baseline">
      <Typography
        component="span"
        variant={variant}
        fontWeight={fontWeight}
        size={size}
        style={textStyle}
        className="available-balance-amount"
        noWrap
      >
        <BraintrustIcon
          style={{
            marginRight: 12,
            verticalAlign: "middle",
            ...iconStyle,
          }}
        />
        <Tooltip
          style={{ cursor: "pointer", verticalAlign: "middle" }}
          title={formatTokenAmount(balance)}
        >
          <span data-testid={testId}>{balanceToDisplay}</span>
        </Tooltip>
      </Typography>
    </Box>
  );
};

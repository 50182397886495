import React from "react";

import { Button } from "@braintrust/braintrust-ui-components";

export const SaveSearchButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="white-grey"
      shape="squared"
      size="x-small"
      fontWeight={400}
      onClick={onClick}
    >
      Save search
    </Button>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useIsNodeStaff, useUser } from "@js/apps/common/hooks";
import {
  useGetOrCreateMessageRoomMutation,
  useManualSendMessageInBulkMutation,
  useManualSendMessageMutation,
} from "@js/apps/messenger/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { EmployerBidListBid } from "@js/types/jobs";

import {
  useUpdateEmployerBidInBulkMutation,
  useUpdateEmployerBidSectionMutation,
} from "../../api";
import type { SendMessageFormValues } from "../../forms";
import { BidSendMessage } from "../../forms";
import {
  getReceiversForBulkMessage,
  getRoomContext,
  handleFailureForRestrictedApplications,
} from "../../helpers";
import { useSelectedInterviewableIds } from "../../hooks/use-selected-interviewable-ids";
import type { InterviewRequestMessageProps } from "../../models";

export const InterviewRequestMessage = ({
  bid,
  job,
  refetchBidList,
  interviewableBids,
}: InterviewRequestMessageProps) => {
  const [getOrCreateMessageRoom] = useGetOrCreateMessageRoomMutation();
  const user = useUser();
  const [manualSendMessage] = useManualSendMessageMutation();
  const [manualSendMessageInBulk] = useManualSendMessageInBulkMutation();
  const name = bid?.freelancer.user.first_name || "there";
  const isNodeStaff = useIsNodeStaff();
  const { selectedInterviewableIds } = useSelectedInterviewableIds();
  const [updateEmployerBidSection] = useUpdateEmployerBidSectionMutation();
  const [updateEmployerBidInBulk] = useUpdateEmployerBidInBulkMutation();

  if (!refetchBidList || !user) return null;

  const authorId = isNodeStaff ? job.creator?.id : user?.id;

  const initialMessage =
    `Hi ${name} 👋,\n\n` +
    `Thanks for applying to this role.\n\n` +
    `I'd love to schedule a call with you. Please let me know a few times that work for you.\n\n` +
    `Thanks!`;

  const selectedInterviewableBids = interviewableBids || [];
  const isBulk = selectedInterviewableBids.length > 1;
  const headlineName = isBulk
    ? `${selectedInterviewableBids.length} applicants`
    : name;

  const submitButtonLabel = isBulk
    ? `Send ${selectedInterviewableBids.length} interview requests`
    : "Send interview request";

  const handleSendInterviewError = () => {
    Snackbar.error(
      "Updating status failed. Please refresh the page and try again",
    );

    ModalInstance.close();
  };

  const handleSendMessageError = () => {
    Snackbar.error(
      "Sending message failed. Please refresh the page and try again",
    );

    ModalInstance.close();
  };

  const sendInterviewOnSingleBid = async (
    bidToInterview: EmployerBidListBid,
    message: string,
  ) => {
    const sendInterviewResponse = await updateEmployerBidSection({
      bidId: bidToInterview.id,
      status: ENUMS.BidStatus.INTERVIEWING,
    });

    refetchBidList();

    if (!!sendInterviewResponse && "error" in sendInterviewResponse) {
      return handleSendInterviewError();
    }

    if (typeof authorId === "undefined") {
      return;
    }

    const roomResponse = await getOrCreateMessageRoom({
      context: { participants: [authorId, bidToInterview.freelancer.user.id] },
    });

    if ("error" in roomResponse) {
      return handleSendMessageError();
    }

    const { data: room } = roomResponse;

    return manualSendMessage({
      payload: { message_content: message, author: authorId },
      room_context: {
        content_type: ENUMS.RoomTypeContentType.P2P_ROOM_TYPE,
        context: getRoomContext(user, isNodeStaff, room, authorId),
      },
    })
      .unwrap()
      .then(() => ModalInstance.close())
      .catch(() => handleSendMessageError());
  };

  const sendInterviewOnSelectedBids = async (message: string) => {
    const sendInterviewResponse = await updateEmployerBidInBulk({
      status: ENUMS.BidStatus.INTERVIEWING,
      pks: selectedInterviewableIds,
    });

    if (!!sendInterviewResponse && "error" in sendInterviewResponse) {
      return handleSendInterviewError();
    }

    if (typeof authorId === "undefined") {
      return;
    }

    const receivers = getReceiversForBulkMessage(
      selectedInterviewableBids,
      sendInterviewResponse.data,
    );

    return manualSendMessageInBulk({
      payload: {
        message_content: message,
        author: authorId,
      },
      room_context: {
        content_type: ENUMS.RoomTypeContentType.P2P_ROOM_TYPE,
        sender: authorId,
        receivers,
      },
    })
      .unwrap()
      .then(() => ModalInstance.close())
      .catch(() => handleSendMessageError());
  };

  const handleSubmit = async (values: SendMessageFormValues) => {
    const { message } = values;

    if (selectedInterviewableIds.length) {
      return sendInterviewOnSelectedBids(message);
    }

    if (!bid) {
      return handleFailureForRestrictedApplications();
    }

    sendInterviewOnSingleBid(bid, message);
  };

  return (
    <InterviewRequestModalContent
      headlineName={headlineName}
      initialMessage={initialMessage}
      handleSubmit={handleSubmit}
      submitButtonLabel={submitButtonLabel}
    >
      {isBulk ? (
        <>
          Each one will receive the message below and be able to respond. You
          can edit it however you like.
          <b> The same message will be sent to all applicants.</b>
        </>
      ) : (
        "This marks Talent as “Interviewing”."
      )}
    </InterviewRequestModalContent>
  );
};

type InterviewRequestModalContentProps = {
  headlineName: string;
  initialMessage: string;
  handleSubmit: ({ message }: SendMessageFormValues) => Promise<void>;
  submitButtonLabel: string;
  children?: JSX.Element | string;
};

export const InterviewRequestModalContent = ({
  headlineName,
  initialMessage,
  handleSubmit,
  submitButtonLabel,
  children,
}: InterviewRequestModalContentProps) => {
  return (
    <Box p={3}>
      <Typography component="h2" variant="label">
        Request an interview with {headlineName}
      </Typography>
      <Typography component="p" size="small" mb={2}>
        {children}
      </Typography>

      <BidSendMessage
        form="send-interview-request"
        onSubmit={handleSubmit}
        initialValues={{
          message: initialMessage,
        }}
        ButtonProps={{
          children: submitButtonLabel,
          variant: "medium-violet",
          type: "submit",
        }}
      />
    </Box>
  );
};

import type { FormEvent, MouseEvent, ReactNode } from "react";

import { Box } from "@braintrust/braintrust-ui-components";

export type BlockInteractionWrapperProps = {
  children: ReactNode;
  onBlockedInteraction: () => void;
};

export const BlockInteractionWrapper = ({
  onBlockedInteraction,
  children,
}: BlockInteractionWrapperProps) => {
  const handleInteraction = (
    ev: { stopPropagation: () => void },
    blockedInteractionCallback?: () => void,
  ) => {
    ev.stopPropagation();
    blockedInteractionCallback?.();
  };

  const handleClickCapture = (ev: MouseEvent<HTMLElement>) => {
    handleInteraction(ev, onBlockedInteraction);
  };

  const handleSubmitCapture = (ev: FormEvent<HTMLElement>) => {
    handleInteraction(ev, () => ev.preventDefault());
  };

  return (
    <Box
      onClickCapture={handleClickCapture}
      onMouseDownCapture={handleInteraction}
      onMouseUpCapture={handleInteraction}
      onKeyDownCapture={handleInteraction}
      onKeyUpCapture={handleInteraction}
      onChangeCapture={handleInteraction}
      onSubmitCapture={handleSubmitCapture}
      sx={{ cursor: "pointer" }}
      data-testid="block-interaction-wrapper"
    >
      <Box sx={{ pointerEvents: "none" }}>{children}</Box>
    </Box>
  );
};

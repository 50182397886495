import { Fragment } from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { PollResultsIndicator } from "@js/apps/give-and-get-help/components/poll/poll-results-indicator";
import { usePollResults } from "@js/apps/give-and-get-help/components/poll/utils";
import { PollResultsBar } from "@js/apps/give-and-get-help/components/poll-results-bar";
import type { PollOption } from "@js/types/give-and-get-help";

import styles from "./poll-result.module.scss";

export const PollResults = ({
  options,
  votes_count,
}: {
  options: PollOption[];
  votes_count: number | null;
}) => {
  const isSmall = useMediaQuery("sm");

  const { results, isAny100 } = usePollResults({
    options,
    votes_count,
  });

  return (
    <Box className={styles.container}>
      {results.map((option) => {
        return (
          <Fragment key={option.id}>
            <Box>
              <Typography
                component="p"
                variant="paragraph"
                size={isSmall ? "medium" : "large"}
              >
                {option.text}
              </Typography>
              <PollResultsBar option={option} />
            </Box>
            <Box sx={{ minWidth: "140px" }}>
              <PollResultsIndicator option={option} isAny100={isAny100} />
            </Box>
          </Fragment>
        );
      })}
    </Box>
  );
};

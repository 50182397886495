import { useMemo } from "react";

import type { IconButtonProps as DSIconButtonProps } from "@braintrust/braintrust-ui-components";
import { SocialProfiles } from "@js/components/social-profiles";
import type { FreelancerPublicExternalProfile } from "@js/types/common";

const PROFILES_TO_DISPLAY = [
  SETTINGS.QUESTIONNAIRE_PERSONAL_SITE_ID,
  SETTINGS.QUESTIONNAIRE_LINKEDIN_SITE_ID,
];

type LimitedFreelancerLinksProps = {
  externalProfiles: Array<FreelancerPublicExternalProfile>;
  IconButtonProps?: Partial<DSIconButtonProps>;
};

export const LimitedFreelancerLinks = ({
  externalProfiles,
  IconButtonProps,
}: LimitedFreelancerLinksProps) => {
  const profilesToDisplay = useMemo<FreelancerPublicExternalProfile[]>(() => {
    const sites: FreelancerPublicExternalProfile[] = [];
    const otherSites: FreelancerPublicExternalProfile[] = [];

    externalProfiles.forEach((profile) => {
      if (PROFILES_TO_DISPLAY.includes(profile.site.id)) {
        sites.push(profile);
      } else {
        otherSites.push(profile);
      }
    });

    return sites.concat(otherSites).slice(0, 2);
  }, [externalProfiles]);

  return (
    <SocialProfiles
      IconButtonProps={{
        size: "x-small",
        variant: "white-border-beige",
        ...IconButtonProps,
      }}
      IconStyle={{
        width: "18",
        height: "18",
      }}
      profiles={profilesToDisplay}
    />
  );
};

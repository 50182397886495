import classNames from "classnames";

import type { StackProps } from "@braintrust/braintrust-ui-components";
import { Stack } from "@braintrust/braintrust-ui-components";

import styles from "./jobs-container.module.scss";

type JobsContainerProps = StackProps;

export const JobsContainer = ({
  className,
  children,
  ...rest
}: JobsContainerProps) => {
  return (
    <Stack {...rest} className={classNames(styles.container, className)}>
      {children}
    </Stack>
  );
};

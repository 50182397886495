import React, { useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import {
  Box,
  FormHelperText,
  Popover,
  PopoverAnchorButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { DollarNumberField } from "@js/forms/fields/number/number";
import { getError, isError, isTouched } from "@js/forms/utils";
import { shortenValue } from "@js/utils";

type RatePopoverProps = {
  min_rate: TypedWrappedFieldProps<string>;
  max_rate: TypedWrappedFieldProps<string>;
  isAiGenerated: boolean;
  isRangeSelected: boolean;
  onClick: () => void;
};

export const RatePopover = ({
  isAiGenerated,
  isRangeSelected,
  min_rate,
  max_rate,
  onClick,
}: RatePopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number;
    left: number;
  }>();

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const customLabel = getCustomOptionLabel({
    min: min_rate,
    max: max_rate,
    isRangeSelected,
    isAiGenerated,
  });

  return (
    <Popover
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onOpenChange={(open) => {
        if (!buttonRef.current) return;

        const { bottom, left } = buttonRef.current.getBoundingClientRect();
        setAnchorPosition({ top: bottom, left });
        setIsOpen(open);
      }}
      anchor={
        <PopoverAnchorButton
          isOpen={isOpen}
          onClick={onClick}
          ref={buttonRef}
          isSelected={isRangeSelected}
        >
          {customLabel}
        </PopoverAnchorButton>
      }
    >
      <Box maxWidth={320} p={2}>
        <Typography
          mb={2}
          fontWeight={400}
          size="small"
          component="h4"
          variant="label"
        >
          You can enter a custom rate or a range.
        </Typography>
        <MinMaxRateField min_rate={min_rate} max_rate={max_rate} />
      </Box>
    </Popover>
  );
};

type MinMaxRateFieldProps = {
  min_rate: TypedWrappedFieldProps<string>;
  max_rate: TypedWrappedFieldProps<string>;
};

const MinMaxRateField = ({ min_rate, max_rate }: MinMaxRateFieldProps) => {
  const fields = [min_rate, max_rate];

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width={109}>
          <DollarNumberField
            id="min-rate"
            placeholder="Min rate"
            inputTextSize="small"
            meta={min_rate.meta}
            input={min_rate.input}
            hideError
          />
        </Box>
        <Typography mx={1} my={0} component="span" size="small">
          to
        </Typography>
        <Box width={109}>
          {!!max_rate && (
            <DollarNumberField
              id="max_rate"
              placeholder="Max rate"
              inputTextSize="small"
              meta={max_rate.meta}
              input={max_rate.input}
              hideError
            />
          )}
        </Box>
      </Box>
      {isError(fields) && isTouched(fields) && (
        <FormHelperText error>{getError(fields)}</FormHelperText>
      )}
    </>
  );
};

type GetCustomOptionLabelProps = {
  min: TypedWrappedFieldProps<string>;
  max: TypedWrappedFieldProps<string>;
  isRangeSelected: boolean;
  isAiGenerated: boolean;
};

const getCustomOptionLabel = ({
  min,
  max,
  isRangeSelected,
  isAiGenerated,
}: GetCustomOptionLabelProps) => {
  const minRate = min.input.value;
  const maxRate = max.input.value;

  if (!isRangeSelected || (!minRate && !maxRate)) {
    return isAiGenerated ? "Custom" : "Range";
  }

  if ((minRate && !maxRate) || (!minRate && maxRate)) {
    return `$${minRate || maxRate}`;
  }

  const isRateTheSame = !!(minRate === maxRate);
  if (isRateTheSame) {
    return `$${shortenValue(minRate, 6)}`;
  }

  return `$${shortenValue(minRate, 6)}-${shortenValue(maxRate, 6)}`;
};

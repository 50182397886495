import { useUser } from "@js/apps/common/hooks";
import type { FreelancerBid, Job } from "@js/types/jobs";

import { JobDetailsActionBar } from "../job-details-action-bar";

import { JobDetailsHeader } from "./job-details-header";

type JobHeaderProps = {
  job: Job;
  bid?: FreelancerBid;
  className?: string;
  withDetailsHeader?: boolean;
};

export const JobHeader = ({
  job,
  bid,
  className,
  withDetailsHeader,
}: JobHeaderProps) => {
  const user = useUser();
  if (!job) {
    return <></>;
  }

  return (
    <div className={className}>
      {user && <JobDetailsActionBar job={job} />}
      {withDetailsHeader && <JobDetailsHeader job={job} bid={bid} />}
    </div>
  );
};

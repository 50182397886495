import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { flushSync } from "react-dom";
import type { WrappedFieldProps } from "redux-form";
import { submit } from "redux-form";
import cs from "classnames";

import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import type { AvatarWithBadgeProps } from "@js/components/avatar-with-badge";
import { UserAvatar } from "@js/components/user-avatar";
import type { TextFieldProps } from "@js/forms/fields";
import { TextareaField } from "@js/forms/fields";
import { useAppDispatch } from "@js/hooks";
import type { User } from "@js/types/auth";

import styles from "./style.module.scss";

type CommentTextFieldProps = WrappedFieldProps &
  TextFieldProps & {
    submitButton?: JSX.Element;
    hideAvatar?: boolean;
    avatarProps?: Pick<AvatarWithBadgeProps, "className">;
    avatarSize?: BoxProps["width"];
    expanding?: boolean;
    showSubmitButton?: "always" | "default";
  };

export const CommentTextField = ({
  InputProps,
  className,
  meta,
  input,
  submitButton,
  hideAvatar = false,
  showSubmitButton = "default",
  avatarProps,
  avatarSize = 40,
  placeholder,
  expanding = false,
  ...props
}: CommentTextFieldProps) => {
  const dispatch = useAppDispatch();
  const user = useUser() as User;
  const spanRef = useRef<HTMLSpanElement>(null);

  const [textWidth, setTextWidth] = useState(0);
  const [spanText, setSpanText] = useState(input.value);

  const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;

    flushSync(() => {
      setSpanText(value);
    });

    flushSync(() => {
      if (spanRef.current) {
        setTextWidth(spanRef.current.getBoundingClientRect().width);
      }
    });
    flushSync(() => {
      input.onChange(value);
    });
  };

  const handleEnter = useCallback(
    () => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        dispatch(submit(meta.form));
      }
    },
    [dispatch, meta.form],
  );

  useEffect(() => {
    if (spanRef.current) {
      setTextWidth(spanRef.current.getBoundingClientRect().width);
    }
  }, []);

  const fieldProps = useMemo(
    () => ({
      placeholder,
      input,
      meta,
      onKeyDown: handleEnter(),
      minRowsNumber: true,
      InputProps: {
        endAdornment:
          submitButton &&
          (() => {
            return input.value || showSubmitButton === "always" ? (
              <Box marginTop="auto">{submitButton}</Box>
            ) : null;
          })(),
        ...InputProps,
        classes: {
          root: styles.expandingInputRoot,
        },
      },
    }),
    [
      input,
      meta,
      placeholder,
      handleEnter,
      InputProps,
      submitButton,
      showSubmitButton,
    ],
  );

  return (
    <div className={styles.commentTextFieldWrapper}>
      {hideAvatar || !user ? null : (
        <Box
          width={avatarSize}
          height={avatarSize}
          flexShrink={0}
          sx={{
            "& > div": {
              height: "100%",
            },
            ".MuiBadge-root > div": {
              width: "100%",
            },
          }}
        >
          <UserAvatar
            user={user}
            hideBorder
            hideBadge
            size={"full"}
            {...avatarProps}
          />
        </Box>
      )}

      {expanding ? (
        <span className={styles.expandingInput}>
          <span
            aria-hidden="true"
            className={styles.expandingInputContent}
            ref={spanRef}
          >
            {spanText || placeholder}
          </span>
          <TextareaField
            style={{ width: textWidth }}
            className={cs(styles.expandingInputInput, className)}
            onChange={_onChange}
            {...fieldProps}
            {...props}
          />
        </span>
      ) : (
        <TextareaField className={cs(className)} {...fieldProps} {...props} />
      )}
    </div>
  );
};

import React from "react";

import {
  Box,
  Typography,
  VerticalSeparator,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { BookmarkJobButton } from "@js/apps/bookmarks/components";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { EmployerLogoWithNameAndColor } from "@js/apps/employer/components/profile/employer-logo-with-name-and-color";
import { CompleteProfileOrSubmitButton } from "@js/apps/jobs/components/complete-profile-or-submit-button";
import { NotLoggedInApplyButton } from "@js/apps/jobs/components/not-logged-in-apply-button";
import { getApplyButtonLabelByStatus } from "@js/apps/jobs/views/public-job-details/helpers";
import type { FreelancerBid, Job } from "@js/types/jobs";
import { dateFromNow } from "@js/utils";

import { isJobCompleted, mapScreenSizeToJobTitleSize } from "../../utils";
import { ShareJobButton } from "../share-job-button";

import style from "./style.module.scss";

type JobDetailsHeaderProps = {
  job: Job;
  bid?: FreelancerBid;
};

export const JobDetailsHeader = ({ job, bid }: JobDetailsHeaderProps) => {
  const md = useMediaQuery("md");
  const sm = useMediaQuery("sm");
  const user = useUser();
  const titleSize = mapScreenSizeToJobTitleSize(sm, md);

  return (
    <>
      <Box display="flex" mb={1} columnGap={2} flexWrap="wrap">
        <EmployerLogoWithNameAndColor employer={job.employer} jobId={job.id} />
        <VerticalSeparator
          className={style.verticalSeparator}
          height=""
          m={0}
        />
        <Typography
          className={style.postDate}
          component="span"
          variant="label"
          color="tertiary"
          fontWeight={500}
          size="medium"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Posted {dateFromNow(job.created)}
        </Typography>
      </Box>

      <Box className={style.title}>
        <Box sx={{ flex: 1 }}>
          <Typography
            component="h2"
            variant="title"
            className="capitalize-first-letter"
            fontWeight={400}
            style={{ overflowWrap: "anywhere" }}
            size={titleSize}
          >
            {job.title}
          </Typography>
        </Box>
        {user ? (
          <JobDetailsHeaderActions job={job} bid={bid} />
        ) : (
          <NotLoggedInApplyButton
            jobId={job.id}
            shape="squared"
            variant="positive-2"
            fullWidth={sm}
            label={getApplyButtonLabelByStatus(job.job_status)}
          />
        )}
      </Box>
    </>
  );
};

type JobDetailsHeaderActionsProps = {
  job: Job;
  bid?: FreelancerBid;
};

const JobDetailsHeaderActions = ({
  job,
  bid,
}: JobDetailsHeaderActionsProps) => {
  const { isFreelancer } = useAccountType();
  const isCompleted = isJobCompleted(job);

  return (
    <Box
      display={{ xs: "flex" }}
      alignItems="center"
      minWidth="fit-content"
      ml={2}
    >
      {!isCompleted && <ShareJobButton sx={{ mr: 1.5 }} job={job} />}
      {isFreelancer && (
        <BookmarkJobButton className={style.saveButton} job={job} />
      )}
      <Box display={{ xs: "none", sm: "flex" }}>
        {isFreelancer && (
          <CompleteProfileOrSubmitButton job={job} bid={bid} hideBoostButton />
        )}
      </Box>
    </Box>
  );
};

import type { MouseEvent } from "react";
import { useEffect, useMemo, useState } from "react";
import linkifyStr from "linkify-string";

import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import {
  calculateContentLengthWithNewlineWeight,
  truncateContentWithNewlineWeight,
} from "@js/utils";

import { READ_MORE_LIMIT, READ_MORE_MOBILE_LIMIT } from "../../constants";
import { usePostsContext } from "../../context/posts";

type UseHandleReadMoreProps = {
  id: number;
  content: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  onExpand?: () => void;
  onCollapse?: () => void;
};

export const useHandleReadMore = ({
  content,
  parentRef,
  id,
  onExpand,
  onCollapse,
}: UseHandleReadMoreProps) => {
  const { postOrCommentContentExpandedIds, togglePostOrCommentContent } =
    usePostsContext();
  const showFullText = useMemo(
    () => postOrCommentContentExpandedIds.includes(id),
    [id, postOrCommentContentExpandedIds],
  );
  const [canBeScrolled, setCanBeScrolled] = useState(false);
  const isMobile = useMediaQuery("sm");
  const newLineCharacterWeight = 50;

  const textContent = useMemo(() => {
    const readMoreLimit = isMobile ? READ_MORE_MOBILE_LIMIT : READ_MORE_LIMIT;
    const processedContent = showFullText
      ? content
      : truncateContentWithNewlineWeight(
          content,
          readMoreLimit,
          newLineCharacterWeight,
        );

    const linkifiedContent = linkifyStr(processedContent);

    return linkifiedContent;
  }, [showFullText, content, isMobile]);

  useEffect(() => {
    if (parentRef?.current && !showFullText && canBeScrolled) {
      parentRef.current.scrollIntoView({
        block: "center",
        inline: "start",
        behavior: "smooth",
      });
    }

    // Switching to false to prevent scrolling on states updates not triggered by user (showMore)
    setCanBeScrolled(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textContent]);

  const toggleReadMore = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    togglePostOrCommentContent(id);
    setCanBeScrolled(true);

    if (showFullText) {
      onCollapse?.();
    } else {
      onExpand?.();
    }
  };

  const showButton = useMemo(() => {
    const contentLength = calculateContentLengthWithNewlineWeight(
      content,
      newLineCharacterWeight,
    );

    return isMobile
      ? contentLength > READ_MORE_MOBILE_LIMIT
      : contentLength > READ_MORE_LIMIT;
  }, [content, isMobile]);

  return {
    textContent,
    showButton,
    showFullText,
    toggleReadMore,
  };
};

import { Box, Loader, Typography } from "@braintrust/braintrust-ui-components";
import {
  CommentSectionContext,
  usePostContext,
} from "@js/apps/give-and-get-help/context";
import type { IPost } from "@js/types/give-and-get-help";

import { PostSignupPrompt } from "../../post-signup-prompt";
import { CommentsList, PublicCommentsList } from "../comments-list";

import { useCommentsSection } from "./use-comments-section";

import styles from "../styles.module.scss";

type CommentsSectionProps = {
  post: IPost;
  isPublic: boolean | undefined;
  isSinglePostView: boolean | undefined;
};

export const CommentsSection = ({
  post,
  isSinglePostView,
  isPublic,
}: CommentsSectionProps) => {
  const {
    comments,
    totalCommentsCount,
    contextValue,
    areAllCommentsVisible,
    preloadedCommentsCount,
    isFetching,
    postCommentsExpanded,
    toggleReplies,
  } = useCommentsSection({
    post,
    isSinglePostView,
    isPublic,
  });
  const { onViewCommentClick } = usePostContext();

  const ListComponent = isPublic ? PublicCommentsList : CommentsList;

  const shouldDisplayViewAllRepliesButton =
    !isPublic && !postCommentsExpanded && !areAllCommentsVisible;

  if (!totalCommentsCount) {
    return <></>;
  }

  return (
    <CommentSectionContext.Provider value={contextValue}>
      <Box component="section" className={styles.feedCommentsSection}>
        {!!comments.length && (
          <ListComponent postId={post.id} comments={comments} isFirstLevel />
        )}
        {shouldDisplayViewAllRepliesButton && (
          <Typography
            role="button"
            variant="label"
            size="small"
            component="p"
            color="blue"
            sx={{ cursor: "pointer", width: "max-content", mt: 2 }}
            onClick={() => {
              if (!!onViewCommentClick) {
                return onViewCommentClick(post);
              }
              toggleReplies();
            }}
          >
            View all ({totalCommentsCount}) replies
          </Typography>
        )}
        {isFetching && (
          <Box sx={{ position: "relative", minHeight: "4rem" }}>
            <Loader centered />
          </Box>
        )}
        {isPublic && (
          <PostSignupPrompt visibleCommentsCount={preloadedCommentsCount} />
        )}
      </Box>
    </CommentSectionContext.Provider>
  );
};

import type { ReactNode } from "react";

import {
  Box,
  Loader,
  Typography,
  type TypographyProps,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import { formatCurrency } from "@js/utils/currency";

type EmployerInvoicesStatisticValueProps = {
  isLoading: boolean;
  value: string | undefined;
  label: string;
  helperText?: ReactNode;
} & Partial<TypographyProps>;

export const EmployerInvoicesStatisticValue = ({
  isLoading,
  value,
  label,
  helperText,
  ...rest
}: EmployerInvoicesStatisticValueProps) => {
  const isMobile = useMediaQuery("sm");
  const formattedAmount = formatCurrency(value ?? 0);
  return (
    <Box>
      <Typography
        component="label"
        variant="label"
        size="medium"
        sx={{ mb: { xs: "2px", md: 1 }, display: "block" }}
      >
        {label}
      </Typography>
      <Box sx={{ mb: { xs: "12px", md: 2 } }}>
        {isLoading && (
          <Box sx={{ pt: 1 }}>
            <Loader />
          </Box>
        )}
        {!isLoading && (
          <Typography
            component="p"
            variant="title"
            size={isMobile ? "small" : "medium"}
            fontWeight={400}
            {...rest}
          >
            {formattedAmount}
          </Typography>
        )}
      </Box>
      {!isLoading && !!helperText && helperText}
    </Box>
  );
};

import { useParams } from "react-router-dom";
import type { WrappedFieldsProps } from "redux-form";
import { touch } from "redux-form";

import { Button } from "@braintrust/braintrust-ui-components";
import { useAppDispatch, useNavigate } from "@js/hooks";

export const EditJobNextStepButton = ({ role }: WrappedFieldsProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Button
      onClick={() =>
        role.input.value < 0
          ? dispatch(touch("edit-job-form", "role"))
          : navigate(`/jobs/${id}/edit/`)
      }
      variant="positive"
      shape="squared"
    >
      Next step
    </Button>
  );
};

import type { KeyboardEvent, MouseEvent } from "react";

import { Button, Typography } from "@braintrust/braintrust-ui-components";
import { PersonAddIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  CommonTeamMembersModalListItemContainer,
  CommonTeamMembersModalListMemberItemDetails,
} from "@js/apps/employer/components/common-team-members-modal";
import type { EmployerTeamMemberType } from "@js/types/employer";
import type { JobSubscriberData } from "@js/types/jobs";

export type JobSubscribersListItemProps = {
  teamMember: EmployerTeamMemberType;
  jobOwnerId?: number;
  currentUserId: number;
  jobSubscribers: JobSubscriberData[];
  editedJobSubscribers: JobSubscriberData[];
  toggleJobSubscriber: () => void;
};

export const JobSubscribersListItem = ({
  teamMember,
  jobOwnerId,
  jobSubscribers,
  editedJobSubscribers,
  currentUserId,
  toggleJobSubscriber,
}: JobSubscribersListItemProps) => {
  const isSubscribed = jobSubscribers.some(
    (jobSub) => jobSub.team_member_id === teamMember.id,
  );
  const isInEditedSubscribers = editedJobSubscribers.some(
    (jobSub) => jobSub.team_member_id === teamMember.id,
  );

  const isTeamMemberJobOwner = jobOwnerId === teamMember.id;
  const isTeamMemberCurrentUser = currentUserId === teamMember.id;
  const isCurrentUserJobOwner = jobOwnerId === currentUserId;
  const canToggleSubscriber =
    isTeamMemberCurrentUser || isCurrentUserJobOwner || !isSubscribed;

  return (
    <CommonTeamMembersModalListItemContainer
      sx={{ gap: 1.5, justifyContent: "space-between" }}
      data-testid={`team-member-${teamMember.id}`}
    >
      <CommonTeamMembersModalListMemberItemDetails
        teamMember={teamMember}
        isJobOwner={isTeamMemberJobOwner}
      />

      {canToggleSubscriber && (
        <ToggleJobSubscriberButtons
          isSubscribed={isSubscribed}
          isInEditedSubscribers={isInEditedSubscribers}
          toggleSubscriber={toggleJobSubscriber}
        />
      )}
    </CommonTeamMembersModalListItemContainer>
  );
};

type ToggleJobSubscriberButtonsProps = {
  isSubscribed: boolean;
  isInEditedSubscribers: boolean;
  toggleSubscriber: () => void;
};

const ToggleJobSubscriberButtons = ({
  isSubscribed,
  isInEditedSubscribers,
  toggleSubscriber,
}: ToggleJobSubscriberButtonsProps) => {
  if (!isInEditedSubscribers) {
    return (
      <Button
        variant="transparent-border-grey-4"
        shape="squared"
        startIcon={<PersonAddIcon />}
        onClick={toggleSubscriber}
        size="x-small"
      >
        Add
      </Button>
    );
  }

  const handleUnsubscribeClick = (ev: MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    toggleSubscriber();
  };

  const handleUnsubscribeKeyDown = (ev: KeyboardEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    if (ev.key !== "Enter") {
      return;
    }
    toggleSubscriber();
  };

  return (
    <Typography
      component="a"
      role="button"
      href="#"
      variant="link"
      size="small"
      color="grey-1"
      tabIndex={0}
      sx={{ cursor: "pointer" }}
      onKeyDown={handleUnsubscribeKeyDown}
      onClick={handleUnsubscribeClick}
    >
      {isSubscribed ? "Unsubscribe" : "Remove"}
    </Typography>
  );
};

import { Box } from "@braintrust/braintrust-ui-components";
import { JobTags } from "@js/apps/jobs/components/job-type-and-role-tags/job-tags";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemTagsProps = {
  job: MainJobListingJob;
  jobType: EnumType<typeof ENUMS.JobType>;
};

export const JobItemTags = ({ job, jobType }: JobItemTagsProps) => {
  return (
    <Box className={styles.wrapper}>
      <JobTags
        createdTime={job.created}
        jobType={jobType}
        role={job.role}
        mt={0}
      />
    </Box>
  );
};

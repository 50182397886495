import React from "react";

import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";

type NoteProps = {
  avatar: JSX.Element;
  content: string;
  onDelete: () => void;
  name: string;
  date: JSX.Element;
  highlighted: boolean;
  style?: React.CSSProperties;
};

export const Note = React.forwardRef<HTMLElement, NoteProps>(
  ({ avatar, content, onDelete, name, date, highlighted, style }, ref) => {
    return (
      <Card
        ref={ref}
        component="article"
        style={
          highlighted
            ? {
                outlineColor: "var(--black)",
                outlineStyle: "solid",
                outlineWidth: 1,
                ...style,
              }
            : style
        }
      >
        <CardHeader
          component="header"
          avatar={avatar}
          action={
            <IconButton
              onClick={onDelete}
              aria-label="delete note"
              variant="transparent"
            >
              <CloseIcon />
            </IconButton>
          }
          title={name}
          subheader={date}
        />
        <CardContent
          component="p"
          className="text-content mt0 mb0"
          style={{ paddingTop: 0 }}
        >
          {content}
        </CardContent>
      </Card>
    );
  },
);

import type { KeyboardEventHandler, MouseEventHandler } from "react";

import { Box, IconButton, Loader } from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";

import { FILE_UPLOAD_STATE_STATUS } from "../../constants";
import type { FilePreviewData } from "../../types";

import styles from "./image-preview-panel-item.module.scss";

export type ImagePreviewPanelItemProps = {
  filePreviewData: FilePreviewData;
  onFileDelete: () => void;
};

export const ImagePreviewPanelItem = ({
  filePreviewData,
  onFileDelete,
}: ImagePreviewPanelItemProps) => {
  const isUploading =
    filePreviewData.status === FILE_UPLOAD_STATE_STATUS.UPLOADING;

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    onFileDelete();
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    onFileDelete();
  };
  return (
    <Box className={styles.itemWrap}>
      <img
        src={filePreviewData.file?.attachment.file || transparentPixel}
        alt={`Image preview`}
        draggable={false}
        title={"Drag item to change order."}
        width="auto"
        height="auto"
      />
      {isUploading && <Loader className={styles.loader} />}
      <IconButton
        variant="white-violet"
        aria-label="Delete image"
        title="Delete"
        size="small"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        className={styles.deleteBtn}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

const transparentPixel =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42m" +
  "NkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

import type { Location } from "react-router-dom";
import _ from "underscore";

import { PROF_NET_ONBOARDING_PATHS } from "@js/apps/onboarding/constants";
import type { User } from "@js/types/auth";

import { NOTIFICATION_UNSUBSCRIBE_TYPE } from "../hooks";
import {
  deleteQueryStringParameters,
  getCurrentPathEncoded,
  getQuery,
  hasGroupPermission,
  hasPermission,
} from "../utils";

import { ACCESS_RULE } from "./constants";

export const canEnterProtectedRoute = ({
  rules,
  location,
  user,
}: {
  rules: EnumType<typeof ACCESS_RULE>[];
  location: Location;
  user: User | null;
}) => {
  const result = { can: false, to: "/" };

  const query = Object.fromEntries(new URLSearchParams(location.search));
  const nextQueryParam =
    typeof query.next === "string" ? query.next : undefined;

  let requireAuth = false;

  const containsRule = (rule: EnumType<typeof ACCESS_RULE>) =>
    _.contains(rules, rule);

  const authenticationRequired = containsRule(ACCESS_RULE.AUTHENTICATED);

  const requestedURL = getRequestedUrl();

  if (authenticationRequired) {
    requireAuth = true;
  }

  if (authenticationRequired && !user) {
    result.can = false;
    result.to = `/auth/login/?next=${requestedURL}`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.ACCOUNT_TYPE_SELECTED) &&
    (!user || (user && !user.account_type))
  ) {
    result.can = false;
    result.to = PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.path;
    return result;
  } else if (
    containsRule(ACCESS_RULE.EMAIL_NOT_VERIFIED) &&
    user &&
    user.is_verified
  ) {
    result.can = false;
    result.to = "/";
    return result;
  } else if (
    containsRule(ACCESS_RULE.ACCOUNT_TYPE_NOT_SELECTED) &&
    user?.account_type
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.NOT_AUTHENTICATED) &&
    user?.account_type
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.NOT_AUTHENTICATED_OR_IN_IFRAME) &&
    user?.account_type &&
    !("iframe" in query)
  ) {
    result.can = false;
    result.to = nextQueryParam || `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.NEW_USER_HAS_PENDING_BYOT_OFFER) &&
    !user?.has_pending_initial_byot_offer
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_MANAGE_JOBS) &&
    !user?.has_node_staff_permissions &&
    !user?.employer
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (containsRule(ACCESS_RULE.HAS_EMPLOYER) && !user?.employer) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (containsRule(ACCESS_RULE.HAS_FREELANCER) && !user?.freelancer) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.FREELANCER_APPROVED) &&
    !user?.freelancer_can_use_job_related_features
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_CUSTOMER_INVOICE_REPORT) &&
    !hasPermission(
      user,
      ENUMS.CustomPermission.CAN_VIEW_CUSTOMER_INVOICE_REPORT,
    )
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_GRANT_TOKEN_REWARD) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_GRANT_TOKENS)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (containsRule(ACCESS_RULE.IS_SUPER_USER) && !user?.is_superuser) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.IS_IMPERSONATED_SESSION) &&
    !user?.is_impersonated_session
  ) {
    result.can = false;
    result.to = "/";
    return result;
  } else if (
    containsRule(ACCESS_RULE.EMPLOYER_HAS_ADDRESS_SET) &&
    user?.employer &&
    !user?.has_address_set
  ) {
    result.can = false;
    result.to = "/onboarding/organization/";
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_TOKEN_WITHDRAWALS) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_TOKEN_WITHDRAWALS)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_REWARDS_CENTER) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_REWARDS_CENTER)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_MONEY_FLOW_DASHBOARD) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_MONEY_FLOW_DASHBOARD)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_REFERRALS_REPORT) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_REFERRALS_REPORT)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_DEPOSIT_REPORT) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_DEPOSIT_REPORT)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_TALENTS_WITH_JOBS_REPORT) &&
    !hasPermission(
      user,
      ENUMS.CustomPermission.CAN_VIEW_TALENTS_WITH_JOBS_REPORT,
    )
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_ALL_JOBS_REPORT) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_ALL_JOBS_REPORT)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_TOKEN_BALANCE_REPORT) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_TOKEN_BALANCE_REPORT)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_OFFER_REPORT) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_OFFER_REPORT) &&
    !hasGroupPermission(user, SETTINGS.GROUP.JOB_OPS) &&
    !hasGroupPermission(user, SETTINGS.GROUP.JOB_SALES)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_EMPLOYER_INVOICES) &&
    !user?.can_view_employer_invoices
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.IS_OPS_OR_SALES) &&
    !hasGroupPermission(user, SETTINGS.GROUP.JOB_OPS) &&
    !hasGroupPermission(user, SETTINGS.GROUP.JOB_SALES)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_MANAGE_TOKEN_TAXES_REPORT) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_MANAGE_TOKEN_TAXES_REPORT)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_MANAGE_TOKEN_GRANT_PLANS) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_MANAGE_TOKEN_GRANT_PLANS)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_TOKEN_GRANT_PLANS) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_MANAGE_TOKEN_GRANT_PLANS) &&
    !hasPermission(user, ENUMS.CustomPermission.CAN_VIEW_TOKEN_GRANT_PLANS)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_SUGGESTED_CATEGORIES_REPORT) &&
    !hasGroupPermission(user, SETTINGS.GROUP.NODE_STAFF_PERMISSIONS)
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else if (
    containsRule(ACCESS_RULE.CAN_VIEW_HELP_OFFER_PAYMENTS_REPORT) &&
    !hasPermission(
      user,
      ENUMS.CustomPermission.CAN_VIEW_HELP_OFFER_PAYMENTS_REPORT,
    )
  ) {
    result.can = false;
    result.to = `/`;
    return result;
  } else {
    result.can = true;
    result.to = requestedURL;
  }

  if (requireAuth) {
    // @ts-ignore: Fix types window
    window.currentRouteRequireAuth = true;
  }

  // @ts-ignore: Fix type window
  if (window.dataLayer && window.dataLayer.push) {
    // @ts-ignore: Fix type
    dataLayer.push({ event: "optimize.activate" });
  }

  return result;
};

const getRequestedUrl = () => {
  const queryParam = getQuery(NOTIFICATION_UNSUBSCRIBE_TYPE);

  if (!queryParam) return getCurrentPathEncoded();

  const urlWithoutParam = deleteQueryStringParameters(
    NOTIFICATION_UNSUBSCRIBE_TYPE,
    window.location.pathname,
  );

  return (
    encodeURIComponent(urlWithoutParam) +
    `&${NOTIFICATION_UNSUBSCRIBE_TYPE}=${queryParam}`
  );
};

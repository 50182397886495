import cs from "classnames";

import { Box, Typography } from "@braintrust/braintrust-ui-components";

import styles from "./styles.module.scss";

type ReferralsEmptyStateProps = {
  variant?: "violet" | "green";
  onClear: () => void;
};

export const ReferralsEmptyState = ({
  variant = "violet",
  onClear,
}: ReferralsEmptyStateProps) => {
  return (
    <Box className={styles.wrapper}>
      <Box
        className={styles.emojiWrapper}
        sx={{
          backgroundColor: `var(--medium-${variant})`,
        }}
      >
        <span className={cs(styles.emoji, styles.emojiNode)}>☹️</span>
      </Box>
      <Box>
        No results found.{" "}
        <Typography
          onClick={onClear}
          component="button"
          variant="link"
          className={styles.clearButton}
        >
          Clear search
        </Typography>
        .
      </Box>
    </Box>
  );
};

import { Tooltip, Typography } from "@braintrust/braintrust-ui-components";

type ActivityHistoryButtonProps = {
  download: () => void;
};

export const ActivityHistoryButton = ({
  download,
}: ActivityHistoryButtonProps) => {
  return (
    <Tooltip
      data-testid="transaction-history-button-tooltip"
      title="Download a file of all the transactions that occurred in your Braintrust wallet."
    >
      <span>
        <Typography
          onClick={download}
          component="button"
          variant="paragraph"
          className="btn-reset"
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          Download activity
        </Typography>
      </span>
    </Tooltip>
  );
};

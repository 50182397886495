import { useEffect } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";
import { resetSection, submit } from "redux-form";

import type { NumberInputProps } from "@braintrust/braintrust-ui-components";
import { PatternFormat } from "@braintrust/braintrust-ui-components";
import { NumberField } from "@js/forms/fields";
import { required } from "@js/forms/validators";
import { useAppDispatch } from "@js/hooks";

type OtpAuthCodeFieldProps = {
  simpleFormField?: boolean;
  name?: string;
} & {
  inputProps?: OTPAuthCodeFormComponentProps["inputProps"];
};

export const OTPAuthCodeField = ({
  inputProps,
  name = "code",
  simpleFormField,
  ...props
}: OtpAuthCodeFieldProps) => {
  return (
    <Field
      name={name}
      validate={[required]}
      component={
        simpleFormField ? OTPAuthCodeFormComponent : OTPAuthCodeComponent
      }
      {...props}
      normalize={(value) => value}
      className="text-field number-enhanced"
      inputProps={{
        "aria-label": "authentication code",
        valueIsNumericString: true,
        format: "######",
        mask: "_",
        ...(inputProps || {}),
      }}
    />
  );
};

type OTPAuthCodeFormComponentProps = Omit<NumberInputProps, "variant"> &
  TypedWrappedFieldProps<string>;

const OTPAuthCodeComponent = ({
  input,
  meta,
  ...props
}: OTPAuthCodeFormComponentProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (String(input.value).length === 6) {
      dispatch(submit(meta.form));
    }
  }, [dispatch, input.value, meta.form]);

  useEffect(() => {
    // OTPAuthCodeComponent is currently included in few other forms (not as a standalone form) in authentication flow. Closing the field (unmounting) does not clear values kept in redux-form, so we dispatch resetSection on unmount
    return () => {
      dispatch(resetSection(meta.form, input.name));
    };
  }, [dispatch, meta.form, input.name]);

  return <OTPAuthCodeFormComponent input={input} meta={meta} {...props} />;
};

const OTPAuthCodeFormComponent = ({
  input,
  meta,
  ...props
}: OTPAuthCodeFormComponentProps) => {
  return (
    <>
      <NumberField
        NumberFormat={PatternFormat}
        className=""
        autoComplete="off"
        id="auth-code-field"
        placeholder="000 000"
        variant="standard"
        input={input}
        meta={meta}
        {...props}
      />
      <input type="submit" className="invisible" />
    </>
  );
};

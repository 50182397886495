import { useEffect } from "react";
import type { WrappedFieldProps } from "redux-form";

import { Box, IconButton } from "@braintrust/braintrust-ui-components";
import { CloseRoundedIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useGetLinkMetadataQuery } from "@js/apps/common/api";
import { LinkPreview } from "@js/apps/give-and-get-help/components/attachment-preview";
import type { MessageRoom } from "@js/types/messenger";

import {
  openSchedulePromptModal,
  SchedulePromptModal,
} from "../../components/schedule-prompt-modal";

const HIDE_CALENDAR_PROMPT = true;

type TextboxLinkPreviewFieldProps = WrappedFieldProps & {
  activeRoom: MessageRoom;
};

type TextboxLinkPreviewProps = {
  clearCalendarLink?: () => void;
  link: string;
};

export const TextboxLinkPreviewField = ({
  input,
  activeRoom,
}: TextboxLinkPreviewFieldProps) => {
  const { value, onChange } = input;

  useEffect(() => {
    if (!HIDE_CALENDAR_PROMPT && activeRoom?.should_show_calendar_link_prompt) {
      openSchedulePromptModal();
    }
  }, [activeRoom]);

  return (
    <>
      {!!value && (
        <TextboxLinkPreview
          link={value}
          clearCalendarLink={() => onChange(undefined)}
        />
      )}
      {!HIDE_CALENDAR_PROMPT && <SchedulePromptModal activeRoom={activeRoom} />}
    </>
  );
};

const TextboxLinkPreview = ({
  link,
  clearCalendarLink,
}: TextboxLinkPreviewProps) => {
  const { data: metadata, isLoading } = useGetLinkMetadataQuery({
    url: link,
  });

  if (isLoading || !metadata) return null;

  return (
    <Box display="flex">
      <LinkPreview {...metadata} />
      <Box position="relative" right={20} bottom={5}>
        <IconButton
          size="x-small"
          variant="primary"
          onClick={clearCalendarLink}
          sx={{ padding: "2px !important" }}
          aria-label="Close"
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

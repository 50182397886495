import type { TypedWrappedFieldProps } from "redux-form";

import { Box } from "@braintrust/braintrust-ui-components";
import type { Role } from "@js/types/roles";

import { SkillsAutocomplete } from "../../components/skills-autocomplete";
import { MAX_TOP_SKILLS_COUNT } from "../const";
import { useEditFreelancerProfileSkillsLogic } from "../hooks/use-skills-logic";
import { PopularSkillsField } from "../popular-skills-field";
import { SelectedSkillsList } from "../selected-skills-list";

type TalentProfileSkillsFieldProps = {
  role: TypedWrappedFieldProps<Role>;
  new_skills: TypedWrappedFieldProps<number[]>;
  superpower_skills: TypedWrappedFieldProps<number[]>;
};

export const TalentProfileSkillsField = ({
  role,
  new_skills,
  superpower_skills,
}: TalentProfileSkillsFieldProps) => {
  const { displayError, newMeta, error, popularSkillsOptions } =
    useEditFreelancerProfileSkillsLogic({
      newSkillsFormField: new_skills,
      roleId: role.input.value.id,
    });

  return (
    <>
      <Box mb={2}>
        <SkillsAutocomplete
          value={new_skills.input.value}
          onChange={new_skills.input.onChange}
        />
      </Box>
      {Boolean(popularSkillsOptions.length) && (
        <PopularSkillsField
          input={new_skills.input}
          meta={newMeta}
          options={popularSkillsOptions}
          selectedRole={role.input.value}
        />
      )}
      <SelectedSkillsList
        error={displayError ? error : undefined}
        skillsInput={new_skills.input}
        favoriteSkillsInput={superpower_skills.input}
        maxFavoriteSkillsCount={MAX_TOP_SKILLS_COUNT.talent_profile}
      />
    </>
  );
};

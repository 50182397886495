import { Box, Loader } from "@braintrust/braintrust-ui-components";

type LoadingOverlayProps = {
  fullScreen?: boolean;
  loaderProps?: React.ComponentProps<typeof Loader>;
  show: boolean;
  backgroundColor?: string;
  opacity?: number;
};

export const LoadingOverlay = ({
  fullScreen,
  loaderProps,
  show,
  backgroundColor,
  opacity,
}: LoadingOverlayProps) => {
  return show ? (
    <Box
      sx={{
        position: fullScreen ? "fixed" : "absolute",
        width: fullScreen ? "100vw" : "100%",
        height: fullScreen ? "100vh" : "100%",
        zIndex: 1000,
        left: 0,
        top: 0,
        opacity: opacity || 0.8,
        backgroundColor: backgroundColor || "var(--white)",
      }}
    >
      <Loader centered {...loaderProps} />
    </Box>
  ) : null;
};

import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { Button } from "@braintrust/braintrust-ui-components";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { openRejectOfferMessageModal } from "../bid-messages-modals";

type RejectButtonProps = {
  bid: EmployerBidListBid;
  job: Job;
  onOptionClick?: () => void;
  closeDrawer?: () => void;
} & Partial<ButtonProps>;

export const RejectButton = ({
  job,
  bid,
  onOptionClick,
  closeDrawer,
  ...buttonProps
}: RejectButtonProps) => {
  const { refetchBidList } = useBidsContext();

  return (
    <Button
      variant="primary"
      shape="squared"
      disabled={!!job.completed_at || job.openings_left === 0}
      sx={{ flexShrink: 0, height: "42px" }}
      {...buttonProps}
      onClick={() => {
        onOptionClick?.();
        if (!!closeDrawer) closeDrawer();
        openRejectOfferMessageModal({
          bid,
          nextUrl: `/jobs/${job.id}/proposals/${bid.id}/rejection_feedback`,
          refetchBidList,
          job,
        });
      }}
    >
      Reject
    </Button>
  );
};

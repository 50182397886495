import {
  BoltIcon,
  BraintrustIcon,
  PersonAddIcon,
  SuitcaseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { enumToOptions } from "@js/utils";

const ICONS: Record<keyof typeof ENUMS.JoiningReasonLabels, JSX.Element> = {
  get_a_job: <SuitcaseIcon />,
  career_help: <BoltIcon />,
  hire_talent: <PersonAddIcon />,
  refer_and_earn_btrst: <BraintrustIcon />,
};

export const JOINING_REASON_OPTIONS = enumToOptions(
  ENUMS.JoiningReasonLabels,
).map(({ value, label }, index) => ({
  value,
  label,
  icon: ICONS[value],
  "data-testid": `joining-reason-${index}`,
}));

import { RoundedBox } from "@braintrust/braintrust-ui-components";
import { useAccountType } from "@js/apps/common/hooks";
import { ApplicationInsightFeatureFlagWrapper } from "@js/apps/jobs/components/application-insight-feature-flag-wrapper";
import type { Job } from "@js/types/jobs";

import ApplicationInsights from "./application-insights";
import { Description } from "./section-description";
import { JobDescriptionSkillSection } from "./skills";

type JobDescriptionProps = {
  job: Job;
};

export const JobDescription = ({ job }: JobDescriptionProps) => {
  const { isFreelancer } = useAccountType();

  return (
    <>
      {job.id && isFreelancer && (
        <ApplicationInsightFeatureFlagWrapper>
          <ApplicationInsights job={job} />
        </ApplicationInsightFeatureFlagWrapper>
      )}
      <JobDescriptionSkillSection job={job} />
      {job.description && (
        <RoundedBox mb={3}>
          <Description description={job.description} title="Job description" />
        </RoundedBox>
      )}
      {!!job.requirements && (
        <RoundedBox mb={3}>
          <Description description={job.requirements} title="Requirements" />
        </RoundedBox>
      )}
      {job.introduction && (
        <RoundedBox>
          <Description
            description={job.introduction}
            title="What you’ll be working on"
          />
        </RoundedBox>
      )}
    </>
  );
};

import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";

export const CheckMailStatus = () => (
  <Box textAlign="center">
    <img
      src={`${SETTINGS.STATIC_URL}auth/check-email.svg`}
      alt="check-email"
      height="200"
      width="200"
    />
    <Typography component="p">
      If this email address exists in our system, then we will send a password
      reset link.
    </Typography>
  </Box>
);

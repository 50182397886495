import { Avatar, EmployerLogo } from "@braintrust/braintrust-ui-components";
import type { useUser } from "@js/apps/common/hooks";
import type { Employer } from "@js/types/employer";

import type { AvatarType } from "..";

type OrganizationLogoOrAvatarProps = {
  employerProfile?: Employer;
  user: ReturnType<typeof useUser>;
  src?: string;
  className?: string;
  title?: string;
  type: AvatarType;
};

const defaultOrganizationAvatar = `${SETTINGS.STATIC_URL}onboarding/default-organization-avatar.png`;

export const OrganizationLogoOrAvatar = ({
  employerProfile,
  user,
  src,
  className,
  title,
  type,
}: OrganizationLogoOrAvatarProps) => {
  if (type === "employer" && !src) {
    // only DownloadCloudIcon visible in this case
    return null;
  }

  if (employerProfile && type === "organization") {
    const logoSrc =
      src || employerProfile?.logo_thumbnail || defaultOrganizationAvatar;

    return (
      <EmployerLogo
        src={{
          name: employerProfile.name,
          logo_thumbnail: logoSrc,
        }}
        className={className}
        title={title}
      />
    );
  }

  const avatarSrc = src || user?.avatar_thumbnail || "";

  return (
    user && (
      <div className={className}>
        <Avatar
          src={{
            has_avatar_set: !!avatarSrc,
            avatar_thumbnail: avatarSrc,
            gravatar: user.gravatar,
            first_name: user.first_name,
            last_name: user.last_name,
          }}
          size="full"
          title={title}
        />
      </div>
    )
  );
};

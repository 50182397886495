import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { HelpOfferButtonsRefundRequested } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/help-offer-buttons-refund-requested";
import type { ActionBarOfferButtonsProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";
import { assertUnreachable } from "@js/utils";

import { HelpOfferButtonsAccepted } from "./help-offer-buttons-accepted";
import { HelpOfferButtonsApprovalRequested } from "./help-offer-buttons-approval-requested";
import { HelpOfferButtonsPending } from "./help-offer-buttons-pending";

export const ActionBarOfferButtons = ({
  offer,
  isOfferAuthor,
  type,
  openEditMode,
  CompletedStatusComponent,
  onMessageHelper,
}: ActionBarOfferButtonsProps) => {
  const isMedium = useMediaQuery("md");
  const isLarge = useMediaQuery("lg");

  const isMobile = useMediaQuery("sm");
  const isMobileView = isMobile || (!isMedium && isLarge);

  const props = {
    offer,
    isOfferAuthor,
    type,
    openEditMode,
    isMobile: isMobileView,
    onMessageHelper,
  };
  const offerStatus = offer.status;

  switch (offerStatus) {
    case ENUMS.HelpOfferStatus.PENDING: {
      if (offer.reported) return null;

      return <HelpOfferButtonsPending {...props} />;
    }

    case ENUMS.HelpOfferStatus.ACCEPTED:
      if (offer.refund_requested) {
        return <HelpOfferButtonsRefundRequested {...props} />;
      }

      if (offer.marked_complete)
        return <HelpOfferButtonsApprovalRequested {...props} />;

      return <HelpOfferButtonsAccepted {...props} />;

    case ENUMS.HelpOfferStatus.COMPLETED:
      return <CompletedStatusComponent {...props} />;

    case ENUMS.HelpOfferStatus.PENDING_APPROVAL:
    case ENUMS.HelpOfferStatus.REFUND_REQUESTED:
    case ENUMS.HelpOfferStatus.CREATED:
    case ENUMS.HelpOfferStatus.DECLINED:
    case ENUMS.HelpOfferStatus.DELETED:
    case ENUMS.HelpOfferStatus.EXPIRED:
      return null;
    default:
      assertUnreachable(offerStatus);
      return null;
  }
};

import type { FormValue } from "@braintrust/braintrust-ui-components";
import { Box, useButtonSelect } from "@braintrust/braintrust-ui-components";
import { CustomOptionTileButton } from "@js/components/option-tile-buttons";

const OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

type BooleanTileButtonProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  options?: Array<{ label: string; value: boolean }>;
  revertBooleans?: boolean;
};

export const BooleanTileButton = ({
  value,
  onChange,
  options = OPTIONS,
  revertBooleans = false,
}: BooleanTileButtonProps) => {
  const { isSelected, handleChange, itemA11yProps, containerA11yProps } =
    useButtonSelect({
      value,
      onChange: onChange as (value: FormValue) => void,
    });

  const _options = getOptions(revertBooleans, options);

  return (
    <Box display="flex" gap={1} {...containerA11yProps}>
      {_options.map((option, index) => {
        const isOptionSelected = isSelected(option);
        return (
          <CustomOptionTileButton
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...itemA11yProps(isOptionSelected)}
            sx={{
              minWidth: "72px !important",
              width: "fit-content !important",
              height: "72px !important",
            }}
            handleChange={handleChange}
            variant="white-violet"
            className="button-shadow"
            option={option}
            isSelected={isOptionSelected}
          />
        );
      })}
    </Box>
  );
};

const getOptions = (
  revertBooleans: boolean,
  options: Array<{ label: string; value: boolean }>,
) => {
  if (!revertBooleans) return options;

  return options.map((option) => ({
    label: option.label,
    value: !option.value,
  }));
};

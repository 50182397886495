import type { FC, ReactNode } from "react";
import { useEffect } from "react";

import { RoundedBox } from "@braintrust/braintrust-ui-components";
import { useAppDispatch } from "@js/hooks";
import type { EmployerBidListBid } from "@js/types/jobs";

import { initiateBidSelectionData } from "../bulk-select-actions/bid-bulk-selection-slice";

import styles from "./style.module.scss";

export const BidListActionBar: FC<{
  children: ReactNode;
  bids: EmployerBidListBid[];
}> = ({ children, bids }) => {
  useInitiateBidsDataForBulkSelection(bids);

  return (
    <RoundedBox className={styles.bidListActionBar}>{children}</RoundedBox>
  );
};

const useInitiateBidsDataForBulkSelection = (bids: EmployerBidListBid[]) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initiateBidSelectionData(bids));
  }, [bids, dispatch]);
};

import { type ReactNode, useRef } from "react";
import cs from "classnames";

import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box, Stack } from "@braintrust/braintrust-ui-components";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useScrollTop } from "@js/hooks";
import { useForceRender } from "@js/hooks/use-force-render";

import styles from "./styles.module.scss";

export type ScrollableContainerProps = {
  children: ReactNode;
} & Pick<BoxProps, "sx" | "className">;

const fadeThresholdPx = 20;

export const ScrollableContainer = ({
  children,
  className,
  ...rest
}: ScrollableContainerProps) => {
  const forceRender = useForceRender();
  const { scrollTop, onScroll } = useScrollTop();
  const ref = useRef<HTMLDivElement>(null);

  const containerHeight = ref.current?.scrollHeight ?? 0;
  const visibleHeight = ref.current?.clientHeight ?? 0;
  const offsetBottom = scrollTop + visibleHeight;

  const isScrollable = !!ref.current && containerHeight > visibleHeight;

  const showTopFade = isScrollable && scrollTop > fadeThresholdPx;
  const showBottomFade =
    isScrollable && containerHeight - offsetBottom > fadeThresholdPx;

  useEffectOnce(() => {
    if (containerHeight !== 0) {
      return;
    }
    // force rerender on mount to recalculate container sizes ...
    // ... and show appropriate fades
    forceRender();
  });

  return (
    <Stack sx={{ position: "relative", overflow: "hidden" }}>
      <Box
        ref={ref}
        {...rest}
        onScroll={onScroll}
        className={cs(styles.container, className)}
      >
        {children}
      </Box>

      <Box
        className={cs(styles.anchor, {
          [styles.anchorTopFade]: showTopFade,
          [styles.anchorBottomFade]: showBottomFade,
        })}
      ></Box>
    </Stack>
  );
};

import { useState } from "react";

import { clickClientInviteAgreeTerms } from "@js/apps/onboarding/actions";
import { useNewClientSignUpInvitation } from "@js/apps/onboarding/hooks/new-client-signup-invitation";
import { useAppDispatch } from "@js/hooks";

/**
 * Used to track if a new client agreed to terms and conditions.
 *
 * Ensures the event is logged only once, even if the user toggles the TOS checkbox multiple times.
 *
 * Handles the following tracking events:
 * - CLICK_CLIENT_SIGNUP_AGREE_TERMS
 * - CLICK_CLIENT_INVITE_SIGNUP_AGREE_TERMS
 */

export const useTrackClientSignUpTermsClicked = () => {
  const [termsClicked, setTermsClicked] = useState(false);
  const dispatch = useAppDispatch();

  const { isClientInvitation } = useNewClientSignUpInvitation();

  const handleTermsClick = () => {
    if (termsClicked) return;

    if (isClientInvitation) {
      dispatch(clickClientInviteAgreeTerms());
      setTermsClicked(true);
    }
  };

  return { handleTermsClick };
};

import { Suspense } from "react";

import { Box, Loader } from "@braintrust/braintrust-ui-components";

import { FreelancerDashboardInvitesLazy } from "../lazy";

import { FreelancerDashboardLayout } from "./layout";

import styles from "./listings-content-wrapper.module.scss";

export const DashboardMyInvitesPage = () => {
  return (
    <FreelancerDashboardLayout pageTitle="Invites">
      <Box className={styles.wrapper}>
        <Suspense fallback={<Loader centered />}>
          <FreelancerDashboardInvitesLazy />
        </Suspense>
      </Box>
    </FreelancerDashboardLayout>
  );
};

import React from "react";
import { Field } from "redux-form";

import { capitalize } from "@braintrust/braintrust-ui-components";
import { TextField } from "@js/forms/fields/text";

export const FirstOrLastNameField = ({
  variant,
}: {
  variant: "first_name" | "last_name";
}) => {
  const variantName = variant.replace("_", " ");
  const label = capitalize(variantName);
  const placeholder = `Enter your ${variantName}`;
  return (
    <Field
      name={variant}
      placeholder={placeholder}
      maxLength={50}
      variant="outlined"
      color="secondary"
      id={variant.replace("_", "-")}
      component={TextField}
      label={`${label}*`}
    />
  );
};

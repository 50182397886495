import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import { ScheduleDelighter } from "@js/apps/messenger/components/schedule-delighter";
import { openSchedulePromptModal } from "@js/apps/messenger/components/schedule-prompt-modal";
import type { Participant } from "@js/types/messenger";

import styles from "./styles.module.scss";

type SchedulePromptPopoverContentProps = {
  sender: Participant;
  recipient: Participant;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SchedulePromptPopoverContent = ({
  sender,
  recipient,
  setIsOpen,
}: SchedulePromptPopoverContentProps) => {
  return (
    <Box maxWidth="373px">
      <Typography component="h2" variant="label" size="large" mb={2.5}>
        Scheduling a 1:1 call
      </Typography>
      <IconButton
        size="small"
        variant="tertiary"
        className={styles.closeButton}
        aria-label="Close coach"
        onClick={() => setIsOpen(false)}
      >
        <CloseIcon sx={{ m: 1 }} />
      </IconButton>
      <ScheduleDelighter
        userName={sender.first_name}
        userProfile={sender}
        recipientName={recipient.first_name}
        recipientProfile={recipient}
      />
      <Typography
        component="h2"
        variant="title"
        size="small"
        fontWeight={400}
        maxWidth={300}
        mt={3}
      >
        Share your calendar link for easy scheduling
      </Typography>
      <Typography component="p" variant="paragraph" mt={1} mb={3}>
        Attach a link to your booking calendar so {recipient.first_name} can
        easily schedule a call with you.
      </Typography>
      <Button
        variant="positive"
        size="medium"
        shape="squared"
        onClick={() => {
          setIsOpen(false);
          openSchedulePromptModal();
        }}
        sx={{ float: "right" }}
      >
        Set up 1:1
      </Button>
    </Box>
  );
};

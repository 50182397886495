import { Typography } from "@braintrust/braintrust-ui-components";
import { SliderField, type SliderFieldProps } from "@js/forms/fields";

export const HoursPerWeekSliderField = ({
  showErrors,
  ...props
}: SliderFieldProps & { showErrors?: boolean }) => {
  return (
    <>
      <SliderField
        size="small"
        color="white"
        showMinMaxLabel
        marks
        step={5}
        min={5}
        max={40}
        {...props}
      />
      {showErrors && props.meta.error && props.meta.touched && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {props.meta.error}
        </Typography>
      )}
    </>
  );
};

import React, { useRef } from "react";

import { useVisiblePostSubscription } from "@js/apps/give-and-get-help/hooks/websocket-subscribe/visible-post-subscription";

import type { BasePostItemProps } from "../base-post-item";
import { BasePostItem } from "../base-post-item";
import { BaseSocialProofPostItem } from "../base-social-proof-post-item";

export type PostItemProps = Omit<BasePostItemProps, "isPublic">;

export const PostItem = React.memo((props: PostItemProps) => {
  const { disablePostSubscription, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  useVisiblePostSubscription({
    disablePostSubscription,
    postElementRef: ref,
    postData: rest.postData,
  });

  if (rest.postData.is_celebratory_post) {
    return <BaseSocialProofPostItem {...rest} ref={ref} />;
  }
  return <BasePostItem {...rest} ref={ref} />;
});

export const PublicPostItem = (props: PostItemProps) => {
  if (props.postData.is_celebratory_post) {
    return <BaseSocialProofPostItem {...props} isPublic />;
  }
  return <BasePostItem {...props} isPublic />;
};

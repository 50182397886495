import { forwardRef } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { touch as touchAction } from "redux-form";

import type {
  NumberInputProps,
  NumberInputWithButtonsProps,
} from "@braintrust/braintrust-ui-components";
import {
  DollarInput,
  NumberInput,
  NumberInputWithButtons,
} from "@braintrust/braintrust-ui-components";

import { ReduxFormTextFieldFactory } from "../text";

type NumberFieldProps = TypedWrappedFieldProps<string> & NumberInputProps;

export const NumberField =
  ReduxFormTextFieldFactory<NumberFieldProps>(NumberInput);

type NumberWithButtonsFieldBaseProps = NumberInputWithButtonsProps &
  TypedWrappedFieldProps<number> &
  NumberInputProps;

const NumberWithButtonsFieldBase =
  ReduxFormTextFieldFactory<NumberWithButtonsFieldBaseProps>(
    NumberInputWithButtons,
  );

export const NumberWithButtonsField = forwardRef<
  HTMLDivElement,
  NumberWithButtonsFieldBaseProps
>(({ input, meta, ...props }, ref) => {
  const touch = () => meta.dispatch(touchAction(meta.form, input.name));

  return (
    <NumberWithButtonsFieldBase
      {...props}
      onChange={(value) => {
        input.onChange(value as number);
        touch();
      }}
      ref={ref}
      input={input}
      meta={meta}
    />
  );
});

export type DollarNumberFieldProps = NumberInputProps &
  TypedWrappedFieldProps<string>;

export const DollarNumberField =
  ReduxFormTextFieldFactory<DollarNumberFieldProps>(DollarInput);

import React from "react";
import { Field, reduxForm } from "redux-form";

import { Button } from "@braintrust/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import { TextField } from "@js/forms/fields/text";

import styles from "./style.module.scss";

export type PromptFormProps = {
  onCancel?: VoidFunction;
  label: string;
  placeholder: string;
  fieldName: string;
  normalize?: <T>(value: T) => T;
  required?: boolean;
};

export const PromptForm = reduxForm<
  Record<string, string>,
  React.PropsWithChildren<PromptFormProps>
>({
  form: "prompt",
  enableReinitialize: true,
  validate: (values, promptForm) => {
    const errors: Record<string, string> = {};

    if (promptForm.required && !values[promptForm.fieldName]) {
      errors[promptForm.fieldName] = "This field is required.";
    }

    return errors;
  },
})((props) => {
  const getCancelButton = () => {
    if (props.onCancel) {
      return (
        <Button variant="secondary" onClick={props.onCancel}>
          Cancel
        </Button>
      );
    }

    return null;
  };

  return (
    <Form onSubmit={props.submitting ? null : props.submit} error={null}>
      {props.children}
      <Field
        normalize={props.normalize}
        id={props.fieldName}
        name={props.fieldName}
        component={TextField}
        variant="standard"
        className={styles.prompt}
        label={props.label || ""}
        size="small"
        placeholder={props.placeholder || ""}
      />
      <div className={`${styles.modalButtons} right buttons`}>
        {getCancelButton()}
        <Button variant="primary" type="submit" disabled={!props.valid}>
          Okay
        </Button>
      </div>
    </Form>
  );
});

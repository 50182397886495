import { Box } from "@braintrust/braintrust-ui-components";
import {
  LocationDetail,
  TimezoneDetail,
  WorkTimeDetail,
} from "@js/apps/jobs/components/job-basic-details";
import type { Job } from "@js/types/jobs";

export type BasicInfoProps = {
  job: Pick<
    Job,
    "locations" | "timezones" | "timezone_overlap" | "expected_hours_per_week"
  >;
  flexDirection?: React.CSSProperties["flexDirection"];
  additionalInfo?: React.ReactNode;
};

export const BasicInfo = ({
  job,
  flexDirection,
  additionalInfo,
}: BasicInfoProps) => {
  const { locations, timezones, timezone_overlap, expected_hours_per_week } =
    job;
  return (
    <Box
      display="flex"
      columnGap={2.5}
      rowGap={1}
      flexDirection={flexDirection ?? { xs: "column", sm: "row" }}
      flexWrap="wrap"
    >
      <LocationDetail locations={locations} variant="listing" />
      <TimezoneDetail
        timezones={timezones}
        timezoneOverlap={timezone_overlap}
        variant="listing"
      />
      <WorkTimeDetail
        expectedHoursPerWeek={expected_hours_per_week}
        variant="listing"
        shortened
      />
      {additionalInfo && additionalInfo}
    </Box>
  );
};

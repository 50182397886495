import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from "react-hook-form";

import { TextField } from "@braintrust/braintrust-ui-components";
import { CompaniesCreatableCombobox } from "@js/components/autocomplete-new/creatable/companies";

export type RhfCompaniesFieldProps<T extends FieldValues> =
  UseControllerProps<T> & {
    id: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    ["aria-label"]?: string;
  };

export const RhfCompaniesField = <T extends FieldValues>({
  placeholder,
  label,
  required,
  ["aria-label"]: ariaLabel,
  ...props
}: RhfCompaniesFieldProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <CompaniesCreatableCombobox
      {...props}
      {...field}
      ref={undefined}
      value={field.value || ""}
      onChange={(_ev, data) => {
        if (!data) {
          return;
        }
        field.onChange(data.value);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            label={label}
            required={required}
            error={!!error}
            helperText={error?.message}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

import type { TextFieldUnionProps } from "@braintrust/braintrust-ui-components";
import { TextField } from "@braintrust/braintrust-ui-components";

import { useJobLocationExpandableField } from "./hook";

import style from "./style.module.scss";

type JobLocationExpandableTextFieldProps = TextFieldUnionProps;

export const JobLocationExpandableTextField = ({
  className,
  ["aria-labelledby"]: ariaLabelledBy,
  ...props
}: JobLocationExpandableTextFieldProps) => {
  const { spanRef, spanText, textWidth } = useJobLocationExpandableField(props);

  return (
    <span className={style.textField}>
      <span aria-hidden="true" className={style.textFieldContent} ref={spanRef}>
        {spanText}
      </span>
      <TextField
        {...props}
        inputProps={{
          ...props.inputProps,
          "aria-labelledby": ariaLabelledBy,
          style: { width: textWidth },
        }}
      />
    </span>
  );
};

import type { ReactNode } from "react";
import React from "react";

import { Stack } from "@braintrust/braintrust-ui-components";

type FiltersBottomContainerProps = {
  children: ReactNode;
};

export const FiltersBottomContainer = ({
  children,
}: FiltersBottomContainerProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "32px",
      }}
    >
      {children}
    </Stack>
  );
};

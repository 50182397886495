import React from "react";

import {
  Pagination,
  PaginationWrapper,
} from "@braintrust/braintrust-ui-components";
import { PublicJobItem } from "@js/apps/jobs/components";
import { JobsContainer } from "@js/apps/jobs/components/listing/jobs-container";
import { RouterLink } from "@js/components/link";
import type { JobListingJob } from "@js/types/jobs";

export const PublicJobsList = React.memo(
  ({ jobs, total }: { jobs: JobListingJob[]; total: number }) => {
    return (
      <>
        <JobsContainer sx={{ gap: 2 }}>
          {jobs.map((job) => (
            <PublicJobItem key={job.id} job={job} />
          ))}
        </JobsContainer>
        <PaginationWrapper>
          <Pagination
            RouterLink={RouterLink}
            count={total}
            perPage={SETTINGS.JOBS_LISTING_JOBS_PER_PAGE}
          />
        </PaginationWrapper>
      </>
    );
  },
);

import { useState } from "react";
import { Field } from "redux-form";

import {
  Box,
  ListItem,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";

import { IntroVideoUpload } from "./intro-video-upload";

export const JobInterviewIntroVideo = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Stack
      sx={{
        gap: 2,
        padding: "32px",
        borderRadius: "16px",
        backgroundColor: "var(--white)",
        border: "1px solid var(--grey-4)",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="h1" size="large" fontWeight={400}>
          Intro video
        </Typography>
        <Typography
          component="span"
          size="small"
          fontWeight={400}
          color="grey-2"
        >
          Optional
        </Typography>
      </Box>
      <Typography component="p" size="small" fontWeight={400}>
        Make a strong first impression with a personalized video introduction.
        Here’s how to make the most of a custom video
        {!isExpanded ? (
          <>
            {"... "}
            <Typography
              component="span"
              size="small"
              fontWeight={400}
              color="blue"
              className="pointer"
              onClick={() => setIsExpanded(true)}
            >
              Read more
            </Typography>
          </>
        ) : (
          <>
            {":"}
            <DescriptionListItem
              label="Content"
              subtext="Highlight the role, company culture, and the purpose of the interview."
            />
            <DescriptionListItem
              label="Setting"
              subtext="Choose a well-lit, quiet space with minimal background noise."
            />
            <DescriptionListItem
              label="Tone"
              subtext="Use a friendly, reassuring tone and warm smile to help candidates feel at ease."
            />
            <Typography component="span" size="small" fontWeight={400}>
              🔥 Pro tip: Your video intro is a chance to connect personally.
              Keep it welcoming and informative!
            </Typography>
          </>
        )}
      </Typography>
      <Field name="intro_video" component={IntroVideoUpload} />
    </Stack>
  );
};

const DescriptionListItem = ({
  label,
  subtext,
}: {
  label: string;
  subtext: string;
}) => {
  return (
    <ListItem sx={{ padding: "0 8px" }}>
      <Typography
        component="span"
        size="small"
        marginRight={1}
        alignSelf="start"
      >
        •
      </Typography>
      <Typography component="span" size="small">
        <Typography
          component="span"
          size="small"
          sx={{ marginRight: 1 }}
          fontWeight={500}
        >
          {label}:
        </Typography>
        {subtext}
      </Typography>
    </ListItem>
  );
};

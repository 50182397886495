import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { ToggleDrawer } from "@braintrust/braintrust-ui-components";
import { TimezoneIcon } from "@braintrust/braintrust-ui-components/Icons";

import { RequirementCard } from "./card";

type Timezone = { id: string; name: string; value: string };

type RequirementTimezoneCardProps = TypedWrappedFieldProps<Timezone[]> & {
  toggleDrawer: ToggleDrawer;
};

export const RequirementTimezoneCardField = ({
  toggleDrawer,
  input,
}: RequirementTimezoneCardProps) => {
  const timezoneList = input.value;

  const detailContent =
    timezoneList.length === 0
      ? "Work anytime"
      : timezoneList.map((timezone) => timezone.name).join(", ");

  return (
    <RequirementCard
      type="timezones"
      label="Working hours + Time zone"
      detail={detailContent}
      icon={<TimezoneIcon />}
      toggleDrawer={toggleDrawer}
    />
  );
};

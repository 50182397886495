import React, { type MouseEvent } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import uuid4 from "uuid4/browser";

import type { MenuItemProps } from "@braintrust/braintrust-ui-components";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import { LogOutIcon } from "@braintrust/braintrust-ui-components/Icons";
import { AvailableForWorkStatus } from "@js/apps/available-for-work/components";
import { CoachMarkTooltip } from "@js/apps/available-for-work/components/coach-mark";
import { useCombinedCoachMarks } from "@js/apps/available-for-work/hooks/use-combined-coach-marks";
import { useWorkAvailability } from "@js/apps/available-for-work/hooks/use-work-availability";
import { BookmarkNavLink } from "@js/apps/bookmarks/components/bookmark-nav-link";
import { FRONTEND_ADMIN_URL } from "@js/apps/common/constants";
import { useIsNodeStaff, useUser } from "@js/apps/common/hooks";
import { useCompleteProfileTopBar } from "@js/apps/dashboard/components/complete-profile-bar/hooks/use-complete-profile-top-bar";
import { openCreateNewEmployerModal } from "@js/apps/employer/components/create-new-employer-modal";
import {
  clickUpdateAvailabilityMenu,
  navItemClicked,
} from "@js/apps/freelancer/actions";
import { AvailabilityEntryPointButton } from "@js/apps/freelancer/components/availability-entry-point-button";
import { MessengerNotificationsIconButton } from "@js/apps/notifications/components/message-notifications-icon";
import { SiteNotificationsIcon } from "@js/apps/notifications/components/site-notifications-icon";
import { RouterLink } from "@js/components/link";
import { UserAvatar } from "@js/components/user-avatar";
import { NAV_ITEM_IDS } from "@js/constants";
import { useAppDispatch } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import type { User } from "@js/types/auth";
import {
  getCurrentPathEncoded,
  getURL,
  hasGroupPermission,
  hasPermission,
} from "@js/utils";

import { isTalentPathname } from "../../helpers";
import { ViewEmployerProfileLabel } from "../view-employer-profile-label";

import styles from "./styles.module.scss";

type NavbarProps = {
  showMenuOnly?: boolean;
};

export const Navbar = ({ showMenuOnly }: NavbarProps) => {
  const user = useUser();

  return (
    <Box className={styles.actions}>
      {user ? (
        <LoggedInRight user={user} showMenuOnly={showMenuOnly} />
      ) : (
        <GuestRight />
      )}
    </Box>
  );
};

const LoggedInRight = ({
  user,
  showMenuOnly,
}: {
  user: User;
  showMenuOnly?: boolean;
}) => {
  const menuLinks = useMenuLinks();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useIdParam();
  const {
    showAvailableForWorkCoachMark,
    showWorkingHoursCoachMark,
    handleAvailableForWorkCoachMarkClose,
    handleWorkingHoursCoachMarkClose,
  } = useCombinedCoachMarks();
  const { showTopBar } = useCompleteProfileTopBar();

  const { onClick, isAvailableForWork } = useWorkAvailability({
    profileId: user.id,
  });

  const logBookmarksOpen = () => {
    dispatch(
      navItemClicked({
        nav_item_id: NAV_ITEM_IDS.BOOKMARKS,
        nav_item_href: "/bookmarks/career_help/",
        context_url: window.location.pathname,
      }),
    );
  };

  const logMenuItemSelect = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    dispatch(
      navItemClicked({
        nav_item_id: target.innerText as NAV_ITEM_IDS,
        nav_item_href: getURL(target.baseURI),
        context_url: location.pathname,
      }),
    );
  };

  const isCurrentTalentPage =
    isTalentPathname(location.pathname) && user.freelancer === idParam;
  return (
    <>
      {!showMenuOnly && (
        <>
          <MessengerNotificationsIconButton style={{ padding: "10px" }} />
          {user.account_type === ENUMS.AccountType.FREELANCER && (
            <BookmarkNavLink
              onClick={logBookmarksOpen}
              className={styles.actionsBookmarks}
            />
          )}
          <SiteNotificationsIcon style={{ padding: "10px" }} />
        </>
      )}
      <Menu
        anchor={
          <Box sx={{ ml: 1 }}>
            <CoachMarkTooltip
              disablePortal
              showAvailableForWorkCoachMark={
                !isCurrentTalentPage && showAvailableForWorkCoachMark
              }
              showWorkingHoursCoachMark={
                !isCurrentTalentPage && showWorkingHoursCoachMark
              }
              handleWorkingHoursCoachMarkClose={
                handleWorkingHoursCoachMarkClose
              }
              handleAvailableForWorkCoachMarkClose={
                handleAvailableForWorkCoachMarkClose
              }
              placement="bottom-end"
            >
              <IconButton
                variant="primary"
                style={{
                  padding: 0,
                  backgroundColor: "var(--white)",
                }}
                aria-label="user menu"
              >
                <UserAvatar user={user} size="md" disableShadow hideBadge />
                {isAvailableForWork ? (
                  <AvailableForWorkStatus onClick={onClick} short />
                ) : null}
              </IconButton>
            </CoachMarkTooltip>
          </Box>
        }
        style={{ maxWidth: "300px", paddingTop: showTopBar ? "34px" : "0" }}
        onClick={logMenuItemSelect}
        minMenuWidth="220px"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuLinks.map((link) => {
          if (link.divider) {
            return <Divider key={link.id} color="secondary" component="li" />;
          }
          const { label, onClick: onLinkClick, to, href, icon } = link;
          const routerLinkProps = {
            component: to || href ? RouterLink : undefined,
            to,
            href,
          };

          return (
            <Menu.Item
              key={link.id}
              {...routerLinkProps}
              onClick={onLinkClick}
              className={icon ? styles.menuWithIcon : ""}
            >
              {label}
              {icon || undefined}
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );
};

const GuestRight = () => {
  const [params] = useSearchParams();
  const isMobile = useMediaQuery("sm");

  return (
    <Box display="flex">
      <Button
        variant="tertiary"
        size={isMobile ? "x-small" : "small"}
        className={styles.btnGuest}
        style={{ marginRight: 8 }}
        href={`/auth/login/?next=${getCurrentPathEncoded()}&${params.toString()}`}
        RouterLink={RouterLink}
      >
        Log In
      </Button>
      <Button
        variant="secondary"
        size={isMobile ? "x-small" : "small"}
        className={styles.btnGuest}
        href={`/auth/sign_up/?next=${getCurrentPathEncoded()}&${params.toString()}`}
        RouterLink={RouterLink}
      >
        Sign Up
      </Button>
    </Box>
  );
};

type MenuLink = MenuItemProps & {
  id?: string;
  label?: string | JSX.Element;
  to?: string;
  href?: string;
  icon?: React.ReactNode;
};

const useMenuLinks = () => {
  const user = useUser();
  const isStaff = useIsNodeStaff();
  const dispatch = useAppDispatch();
  const { onClick: handleSetAvailabilityStatus, isAvailableForWork } =
    useWorkAvailability({
      hideCoachMarkOnClick: true,
    });

  if (!user) {
    return [];
  }

  const _hasPermission = (permission: User["permissions"][number]) => {
    return hasPermission(user, permission);
  };

  const _hasGroupPermission = (group: User["groups"][number]) => {
    return hasGroupPermission(user, group);
  };

  const handleAvailableForWorkStatusClick = (
    event: MouseEvent<HTMLElement>,
  ) => {
    handleSetAvailabilityStatus(event);
    dispatch(clickUpdateAvailabilityMenu());
  };

  const menuLinks: MenuLink[] = [];

  if (!!user.freelancer) {
    menuLinks.push({
      label: (
        <AvailabilityEntryPointButton
          isAvailabilitySet={!!isAvailableForWork}
          user={user}
        />
      ),
      onClick: handleAvailableForWorkStatusClick,
    });
    menuLinks.push({
      to: `/talent/${user.freelancer}/`,
      label: "My profile",
    });

    if (user.freelancer) {
      menuLinks.push({
        to: "/talent/invoices/",
        label: "My invoices",
      });
    }
  }
  if (isStaff) {
    menuLinks.push({
      label: "Create new client",
      onClick: openCreateNewEmployerModal,
    });
  }
  if (!!user.employer) {
    menuLinks.push({
      to: `/employers/${user.employer}/`,
      label: <ViewEmployerProfileLabel />,
    });
    menuLinks.push({ divider: true });
    menuLinks.push({
      to: `/employer/edit/organization_info/`,
      label: "Edit company details",
    });
    menuLinks.push({
      to: `/settings/payments/`,
      label: "Payment methods",
    });
    if (user.can_view_employer_invoices) {
      menuLinks.push({
        to: `/employer/invoices/`,
        label: "Invoices",
      });
    }
  }

  if (isStaff) {
    menuLinks.push({
      label: "Deposit report",
      to: `/${FRONTEND_ADMIN_URL}/deposit_report`,
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_GRANT_TOKENS)) {
    menuLinks.push({
      to: `/grant_token_reward`,
      label: "Grant BTRST Reward",
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_VIEW_CUSTOMER_INVOICE_REPORT)) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/customer_invoice_report`,
      label: "Customer Invoice Report",
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_VIEW_MONEY_FLOW_DASHBOARD)) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/money_flow`,
      label: "Money Flow Dashboard",
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_VIEW_TOKEN_WITHDRAWALS)) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/withdrawals`,
      label: "Withdrawals",
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_VIEW_REWARDS_CENTER)) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/rewards/rewards`,
      label: "Rewards Center",
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_VIEW_REFERRALS_REPORT)) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/referrals_report`,
      label: "Referrals Report",
    });
  }

  if (
    _hasPermission(ENUMS.CustomPermission.CAN_VIEW_TALENTS_WITH_JOBS_REPORT)
  ) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/talents_with_jobs_report`,
      label: "Talent With Jobs Report",
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_VIEW_ALL_JOBS_REPORT)) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/all_jobs_report`,
      label: "All Jobs Report",
    });
  }

  if (
    _hasPermission(ENUMS.CustomPermission.CAN_VIEW_OFFER_REPORT) ||
    _hasGroupPermission(SETTINGS.GROUP.JOB_OPS) ||
    _hasGroupPermission(SETTINGS.GROUP.JOB_SALES)
  ) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/offers_report`,
      label: "Offers Report",
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_VIEW_TOKEN_BALANCE_REPORT)) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/token_balance_report`,
      label: "Token Balance Report",
    });
  }

  if (_hasPermission(ENUMS.CustomPermission.CAN_MANAGE_TOKEN_GRANT_PLANS)) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/token_vesting_tool/create_token_grant_plan`,
      label: "Token Grants",
    });
  } else if (
    _hasPermission(ENUMS.CustomPermission.CAN_VIEW_TOKEN_GRANT_PLANS)
  ) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/token_vesting_tool/list_of_token_grant_plans`,
      label: "Token Grants",
    });
  }

  if (
    _hasPermission(ENUMS.CustomPermission.CAN_VIEW_HELP_OFFER_PAYMENTS_REPORT)
  ) {
    menuLinks.push({
      to: `/${FRONTEND_ADMIN_URL}/help_offer_payments_report`,
      label: "Help Offer Payments Report",
    });
  }

  menuLinks.push({ divider: true });
  menuLinks.push({ to: `/settings/account_info/`, label: "Settings" });
  menuLinks.push({
    href: SETTINGS.SUPPORT_URLS.HELP_CENTER_URL,
    label: "Help Center",
  });
  menuLinks.push({ divider: true });
  menuLinks.push({
    to: `/auth/logout/`,
    label: "Log out",
    icon: <LogOutIcon style={{ fontSize: 18 }} />,
  });

  menuLinks.forEach((link) => (link.id = uuid4()));
  return menuLinks;
};

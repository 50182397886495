import type { ReactNode } from "react";
import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields/field-section-panel";

type RateFieldPanelProps = {
  title: string;
  description: string;
  displayError: boolean;
  error: string;
  children: ReactNode;
};

export const RateFieldPanel = ({
  title,
  description,
  displayError,
  error,
  children,
}: RateFieldPanelProps) => {
  return (
    <FieldSectionPanelWithImportance
      title={title}
      description={description}
      fieldImportance="required"
      isError={displayError}
    >
      {children}
      {displayError && (
        <Typography mt={2} error component="p" variant="paragraph">
          {error}
        </Typography>
      )}
    </FieldSectionPanelWithImportance>
  );
};

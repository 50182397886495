import React, { useEffect, useState } from "react";
import type { Parser, TypedWrappedFieldProps } from "redux-form";
import { Field, reduxForm } from "redux-form";
import classNames from "classnames";

import type { TextFieldUnionProps } from "@braintrust/braintrust-ui-components";
import {
  Button,
  Checkbox,
  Grid,
  InputAdornment,
} from "@braintrust/braintrust-ui-components";
import { Form } from "@js/forms/components";
import { CheckboxField, TextField } from "@js/forms/fields";
import type { EmployerBid } from "@js/types/jobs";

import styles from "./style.module.scss";

const OPTIONAL_FEES_FORM_ID = "optional-fees-form";

type OptionalFeesFormValues = Pick<
  EmployerBid,
  | "compliance_fee_percent"
  | "background_fee_amount"
  | "w2_markup_percent"
  | "braintrust_pays_talent_off_platform_enabled"
>;

type OptionalFeesFormProps = {
  onCancel: () => void;
  canChangeBackgroundFee: boolean;
};

export const OptionalFeesForm = reduxForm<
  OptionalFeesFormValues,
  OptionalFeesFormProps
>({
  form: OPTIONAL_FEES_FORM_ID,
  enableReinitialize: true,
})(({ submit, error, submitting, onCancel, canChangeBackgroundFee }) => {
  return (
    <Form onSubmit={submitting ? undefined : submit} error={error}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            id="compliance_fee_percent"
            name="compliance_fee_percent"
            label={
              SETTINGS.OPTIONAL_FEE_NAMES
                .COMPLIANCE_FEE_RATE_INVOICE_ITEM_DESCRIPTION
            }
            initialValue="5"
            parse={parseNull}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            component={OptionalFeeField}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            id="w2_markup_percent"
            name="w2_markup_percent"
            label={
              SETTINGS.OPTIONAL_FEE_NAMES.W2_FEE_RATE_INVOICE_ITEM_DESCRIPTION
            }
            parse={parseNull}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            component={OptionalFeeField}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            id="background_fee_amount"
            name="background_fee_amount"
            label={
              SETTINGS.OPTIONAL_FEE_NAMES
                .BACKGROUND_FEE_AMOUNT_INVOICE_ITEM_DESCRIPTION
            }
            initialValue="85.00"
            parse={parseNull}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            disabled={!canChangeBackgroundFee}
            component={OptionalFeeField}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            id="braintrust_pays_talent_off_platform_enabled"
            name="braintrust_pays_talent_off_platform_enabled"
            label="Braintrust pays talent off platform enabled"
            component={CheckboxField}
          />
        </Grid>

        <Grid item xs={12} className="buttons right">
          <Button variant="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" disabled={submitting} onClick={submit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
});

const parseNull: Parser = (value) => (value === "" ? null : value);

type OptionalFeeFieldProps = TypedWrappedFieldProps<string> &
  TextFieldUnionProps & {
    initialValue?: string | null;
  };

const OptionalFeeField = ({
  disabled = false,
  input,
  label,
  id,
  meta,
  initialValue = null,
  InputProps,
}: OptionalFeeFieldProps) => {
  const [checked, setChecked] = useState(disabled || !!input.value);

  useEffect(() => {
    input.onChange(checked ? input.value || initialValue : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, initialValue]);

  return (
    <>
      <Checkbox
        id={`${id}-checkbox`}
        className={styles.feeFieldCheckbox}
        checked={checked}
        onChange={() => setChecked(!checked)}
        label={label}
        disabled={disabled}
      />
      <TextField
        id={`${id}-amount`}
        className={styles.feeFieldValue}
        input={input}
        meta={meta}
        disabled={disabled || !checked}
        variant="standard"
        placeholder="00.00"
        size="small"
        InputProps={{
          ...InputProps,
          className: classNames({ disabled: disabled || !checked }),
        }}
        floatingLabel={false}
      />
    </>
  );
};

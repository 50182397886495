import * as React from "react";
import cs from "classnames";

import type { AvatarGroupProps } from "@braintrust/braintrust-ui-components";
import {
  Avatar,
  AvatarGroup,
  Typography,
  VerticalSeparator,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { VerifiedIcon } from "@braintrust/braintrust-ui-components/Icons";
import { Logo } from "@js/components/logo";
import type { User } from "@js/types/auth";

import styles from "./styles.module.scss";

type ExampleUser = Pick<
  User,
  | "first_name"
  | "id"
  | "avatar"
  | "has_avatar_set"
  | "avatar_thumbnail"
  | "gravatar"
>;

type UsersThumbnailAvatarsProps = AvatarGroupProps & {
  users: User[] | ExampleUser[];
  usersAmount?: string;
  variantType?: "withLogo" | "verified";
  maxGroupImages?: number;
  avatarsSpacing?: number;
};

export const UsersThumbnailAvatars: React.FC<UsersThumbnailAvatarsProps> = ({
  variantType = "withLogo",
  users,
  usersAmount,
  ...props
}) => {
  const isVariantWithLogo = variantType === "withLogo";
  const isMobile = useMediaQuery("sm");

  const _users = isMobile ? users.slice(0, 3) : users;

  return (
    <div className={styles.avatars}>
      {isVariantWithLogo && <Logo className={styles.avatarsLogo} />}
      {isMobile && <VerticalSeparator />}
      <div className={styles.wrapper}>
        <AvatarGroup
          className={cs({
            [styles.groupVerified]: !isVariantWithLogo,
          })}
          {...props}
        >
          {!isVariantWithLogo && (
            <VerifiedIcon sx={{ position: "relative", zIndex: 10 }} />
          )}
          {_users.map((user, idx) => {
            return (
              <Avatar
                key={user.id}
                className={styles.groupAvatar}
                sx={
                  isVariantWithLogo
                    ? { zIndex: idx }
                    : { marginLeft: "-8px !important" }
                }
                alt={user.first_name}
                src={user}
              />
            );
          })}
        </AvatarGroup>
        {isVariantWithLogo && !!usersAmount && (
          <UsersThumbnailCountBadge
            amount={usersAmount}
            zIndex={users.length}
          />
        )}
      </div>
    </div>
  );
};

type UsersThumbnailCountBadgeProps = {
  amount: string;
  zIndex: number;
};

const UsersThumbnailCountBadge: React.FC<UsersThumbnailCountBadgeProps> = ({
  amount,
  zIndex,
}) => {
  return (
    <Typography
      component="span"
      variant="label"
      className={styles.badge}
      sx={{ zIndex }}
    >
      {amount} members
    </Typography>
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

const SendScheduleLinkConfirmationModalContent = () => (
  <>
    <Typography component="h1" variant="title" fontWeight={400} size="small">
      Scheduling link sent!
    </Typography>
    <Box mt={1}>
      The link you provided has been sent, and they will receive a notification
      and email indicating your interest in conducting an interview.
    </Box>
  </>
);

export const openSendScheduleLinkConfirmationModal = () => {
  ModalInstance.open({
    className: "max-width-500 text-content-mw",
    children: <SendScheduleLinkConfirmationModalContent />,
    keepOnBackdropClick: true,
    closeButton: true,
    closeButtonProps: {
      sx: {
        backgroundColor: "var(--grey-5) !important",
        height: "40px",
        width: "40px",
      },
      size: "x-small",
    },
  });
};

import type { TypedWrappedFieldProps } from "redux-form";

import {
  Box,
  ComboBoxMultiple,
  StyleAutocompleteDelimiterSeparatedOptions,
  StyleAutocompleteDelimiterSeparatedOptionsPaper,
} from "@braintrust/braintrust-ui-components";
import { LabelWithLimit } from "@js/apps/jobs/apps/create-job/components/label-with-limit";
import type { TimezoneOption } from "@js/types/jobs";

import { JobLocationExpandableTextField } from "../../job-location-expandable-text-field";

import { timezoneFilters, timezoneOptions } from "./helpers";
import { useJobTimezoneField } from "./hook";

export const JobTimezoneField = ({
  input,
  meta,
}: TypedWrappedFieldProps<TimezoneOption[]>) => {
  const { handleOnChange, renderOptionsHandler, renderTagsHandler } =
    useJobTimezoneField(input);

  return (
    <Box display="flex" flexDirection="column" maxWidth={400}>
      <LabelWithLimit
        id="select-timezone-label-id"
        label="Select a timezone"
        limit={SETTINGS.JOB_TIMEZONES_LIMIT}
        currentLength={input.value.length}
      />
      <ComboBoxMultiple<TimezoneOption, false>
        initialTaxonomiesLoading={false}
        value={input.value || []}
        limitTags={1}
        component={StyleAutocompleteDelimiterSeparatedOptions}
        PaperComponent={StyleAutocompleteDelimiterSeparatedOptionsPaper}
        fullWidth
        openOnFocus
        getLimitTagsText={() => "..."}
        filterOptions={timezoneFilters}
        options={timezoneOptions}
        renderOption={renderOptionsHandler}
        renderTags={renderTagsHandler}
        onChange={(_event, value) => {
          handleOnChange(value);
        }}
        renderInput={(params) => {
          return (
            <JobLocationExpandableTextField
              aria-labelledby="select-timezone-label-id"
              placeholder={input.value.length === 0 ? "Select a timezone" : ""}
              error={!!meta.error}
              helperText={meta.error}
              {...params}
            />
          );
        }}
      />
    </Box>
  );
};

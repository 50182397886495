import type { ReactNode } from "react";

import { Stack } from "@braintrust/braintrust-ui-components";

type FiltersBottomActionsContainerProps = {
  children: ReactNode;
};

export const FiltersBottomActionsContainer = ({
  children,
}: FiltersBottomActionsContainerProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        gap: 1,
        flexWrap: "wrap",
      }}
    >
      {children}
    </Stack>
  );
};

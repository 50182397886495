import { z } from "zod";

import { API } from "@js/api";
import { UPDATE_USER } from "@js/apps/auth/action-types";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { WebSocketManagerContainer } from "@js/components/websocket";
import { useAppDispatch } from "@js/hooks";
import { clearLocalStorageDataRelatedToJobAndTalentFilters } from "@js/services/local-storage";
import type { WebsocketEventData } from "@js/types/websocket";
import { isInIframe } from "@js/utils/iframe";
import { getCurrentPathEncoded } from "@js/utils/url";

import { useEffectOnce } from "../use-effect-once";

const COMMON_WEBSOCKET_EVENT_LISTENER_ID = "COMMON_WEBSOCKET_EVENT_LISTENER_ID";

export const useCommonWebsocketEventListener = () => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    const commonListener = (event: MessageEvent) => {
      const eventData: unknown = event.data;
      const parsedEventData = getParsedEventData(eventData);
      if (!parsedEventData) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      switch (parsedEventData.broadcast_type) {
        case ENUMS.BroadcastType.FREELANCER_APPROVAL: {
          dispatch(fetchCurrentUser()).catch(() => null);
          dispatch(API.util.invalidateTags(["WalletBalance"]));
          return;
        }
        case ENUMS.BroadcastType.USER_VERIFY_ACCOUNT: {
          dispatch({
            type: UPDATE_USER,
            payload: {
              is_verified: true,
            },
          });
          return;
        }
        case ENUMS.BroadcastType.EMPLOYER_APPROVAL_CHANGED: {
          dispatch(API.util.invalidateTags(["ManagedEmployers"]));
          return;
        }
        case ENUMS.BroadcastType.USER_POST_BAN: {
          dispatch({
            type: UPDATE_USER,
            payload: {
              is_banned_from_posting: parsedEventData.ban,
            },
          });

          return;
        }
        case ENUMS.BroadcastType.USER_LOGGED_OUT: {
          clearLocalStorageDataRelatedToJobAndTalentFilters();
          if (isInIframe()) {
            window.location.reload();
            break;
          }

          const isLoggingOutInCurrentSession =
            location.pathname.startsWith("/auth/logout");
          if (!isLoggingOutInCurrentSession) {
            window.location.href = `/auth/login/?message=user_logged_out&next=${getCurrentPathEncoded()}`;
          }

          break;
        }
        default:
          return;
      }
    };

    WebSocketManagerContainer.attachListener(
      commonListener,
      COMMON_WEBSOCKET_EVENT_LISTENER_ID,
    );

    return () => {
      WebSocketManagerContainer.removeListener(
        COMMON_WEBSOCKET_EVENT_LISTENER_ID,
      );
    };
  });
  return {};
};

const commonEventDataSchema = z
  .object({
    broadcast_type: z.nativeEnum(ENUMS.BroadcastType),
  })
  .passthrough();

const getParsedEventData = (
  eventData: unknown,
): WebsocketEventData | undefined => {
  const parseEventDataResult = commonEventDataSchema.safeParse(eventData);
  if (!parseEventDataResult.success) {
    return;
  }

  const parsedEventData = parseEventDataResult.data;

  return parsedEventData as WebsocketEventData;
};

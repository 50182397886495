import { useLayoutEffect, useRef, useState } from "react";

import type { TextFieldUnionProps } from "@braintrust/braintrust-ui-components";

type UseJobLocationExpandableTextFieldProps = TextFieldUnionProps;

export const useJobLocationExpandableField = (
  props: UseJobLocationExpandableTextFieldProps,
) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [textWidth, setTextWidth] = useState(0);

  const setTextFieldWidth = (spaces: number) => {
    if (spanRef.current) {
      setTextWidth(
        spanRef.current.getBoundingClientRect().width + spaces * 4.22,
      );
    }
  };

  useLayoutEffect(() => {
    const numberOfSpaces = (props.inputProps?.value.match(/ /g) || []).length;

    setTextFieldWidth(numberOfSpaces);
  }, [props.InputProps?.startAdornment, props.inputProps?.value]);

  const getSpanText = () => {
    if (!props.inputProps?.value.length && !props.InputProps?.startAdornment) {
      return props.placeholder;
    }

    return props.inputProps?.value;
  };

  return {
    spanRef,
    textWidth: textWidth,
    spanText: getSpanText(),
  };
};

import type { ReactNode } from "react";

import {
  Box,
  ListItem,
  ListItemButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import { useCloseSearchContext } from "../../context/close-search-context";

import styles from "./search-list-item.module.scss";

export type SearchListItemProps = {
  title: string;
  badgeComponent: ReactNode;
  detailsContent: ReactNode;
  to: string;
  onClick?: () => void;
};

export const SearchListItem = ({
  badgeComponent,
  title,
  detailsContent,
  to,
  onClick,
}: SearchListItemProps) => {
  const handleCloseSearch = useCloseSearchContext();

  return (
    <ListItem sx={{ padding: 0 }} onClick={onClick}>
      <ListItemButton
        className={styles.item}
        component={RouterLink}
        to={to}
        onClick={handleCloseSearch}
      >
        {badgeComponent}
        <Box>
          <Typography
            component="h5"
            variant="label"
            size="medium"
            ellipsis
            sx={{ overflow: "hidden" }}
          >
            {title}
          </Typography>
          {detailsContent}
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

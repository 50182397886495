import { Button } from "@braintrust/braintrust-ui-components";
import { openProfileCompletionSequenceModal } from "@js/apps/freelancer/components/profile-completion-sequence/profile-completion-sequence";
import { pluralize as pluralizeUtil } from "@js/utils";

export type OpenProfileCompletionSequenceModalButtonProps = {
  name?: string;
  pluralize?: boolean;
  number: number | null;
};

export const OpenProfileCompletionSequenceModalButton = ({
  name,
  pluralize,
  number,
}: OpenProfileCompletionSequenceModalButtonProps) => {
  return (
    <Button
      onClick={openProfileCompletionSequenceModal}
      variant="transparent"
      disableRipple
      style={{
        fontFamily: "inherit",
        color: "inherit",
        fontWeight: "inherit",
        textDecoration: "underline",
        height: "100%",
        verticalAlign: "baseline",
        padding: 0,
        minWidth: 0,
      }}
    >
      <>
        {name}
        {pluralize && number && pluralizeUtil(number)}
      </>
    </Button>
  );
};

import React, { useState } from "react";

import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box } from "@braintrust/braintrust-ui-components";

import {
  type ModalConfirmCommonProps,
  ModalConfirmContent,
} from "./modal-confirm-content";

/**
 * Example usage:
 *
 * ```
 * <TestModal>
 *     <ModalConfirm
 *         onConfirm={() => {
 *             alert('Confirmed!');
 *             TestModal.close();
 *         }}
 *         onCancel={() => {
 *             alert('Declined!');
 *             TestModal.close();
 *         }}>
 *         Are you sure?
 *     </ModalConfirm>
 * </TestModal>
 */

export type ModalConfirmProps = {
  onConfirm?: (() => void) | "submit";
  onConfirm2?: () => void;
} & Omit<ModalConfirmCommonProps, "onConfirm" | "onConfirm2">;

export const ModalConfirm: React.FC<ModalConfirmProps> = ({
  onConfirm,
  confirmDisabled = false,
  onConfirm2,
  confirmDisabled2,
  ...props
}) => {
  const onConfirmClickHandler = () => {
    if (onConfirm === "submit") {
      return;
    }
    onConfirm?.();
  };

  const onConfirm2ClickHandler = () => {
    onConfirm2?.();
  };

  return (
    <ModalConfirmContent
      onConfirm={onConfirm ? onConfirmClickHandler : undefined}
      onConfirm2={onConfirm2 ? onConfirm2ClickHandler : undefined}
      confirmButtonType={onConfirm === "submit" ? "submit" : undefined}
      confirmDisabled={confirmDisabled}
      confirmDisabled2={confirmDisabled2}
      {...props}
    />
  );
};

export type ModalConfirmAsyncProps = {
  onConfirm?: (() => Promise<void>) | "submit";
  onConfirm2?: () => Promise<void>;
} & Omit<ModalConfirmCommonProps, "onConfirm" | "onConfirm2">;

export const ModalConfirmAsync: React.FC<ModalConfirmAsyncProps> = ({
  onConfirm,
  confirmDisabled = false,
  onConfirm2,
  confirmDisabled2,
  ...props
}) => {
  const [isConfirmingInProgress, setConfirmingInProgress] = useState(false);
  const [isConfirming2InProgress, setConfirming2InProgress] = useState(false);

  const onConfirmClickHandler = async () => {
    if (onConfirm === "submit") {
      return;
    }
    setConfirmingInProgress(true);
    await onConfirm?.();
    setTimeout(() => setConfirmingInProgress(false), 500);
  };

  const onConfirm2ClickHandler = async () => {
    setConfirming2InProgress(true);
    await onConfirm2?.();
    setTimeout(() => setConfirming2InProgress(false), 500);
  };

  return (
    <ModalConfirmContent
      onConfirm={onConfirm ? onConfirmClickHandler : undefined}
      onConfirm2={onConfirm2 ? onConfirm2ClickHandler : undefined}
      confirmButtonType={onConfirm === "submit" ? "submit" : undefined}
      confirmDisabled={confirmDisabled || isConfirmingInProgress}
      confirmDisabled2={confirmDisabled2 || isConfirming2InProgress}
      {...props}
    />
  );
};

/*
  Alternative to ModalConfirm - it's easier to add Buttons than configure by props in specific way
*/
export const ConfirmButtonsWrapper = (props: BoxProps) => {
  return (
    <Box mt={2} display="flex" justifyContent="flex-end" gap={1} {...props} />
  );
};

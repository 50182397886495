import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { useDeleteSpaceUserMutation } from "@js/apps/spaces/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { SpaceUser } from "@js/types/spaces";

import { UserRemovedSnackbarContent } from "./remove-member-snackbar";

import styles from "./remove-member-modal.module.scss";

type RemoveMemberModalConentProps = {
  member: {
    user: SpaceUser["user"];
  };
  spaceId: number;
};
const RemoveMemberModalContent = ({
  member,
  spaceId,
}: RemoveMemberModalConentProps) => {
  const isMobile = useMediaQuery("sm");
  const [deleteSpaceMember] = useDeleteSpaceUserMutation();
  const deleteMember = async () => {
    await deleteSpaceMember({ spaceId, memberId: member.user.id });
    ModalInstance.close();
    Snackbar.custom(<UserRemovedSnackbarContent />, {
      autoHideDuration: 5000,
    });
  };

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography
          component="h4"
          variant="label"
          size="large"
          sx={{ mb: 3.5 }}
        >
          Remove member
        </Typography>
        <Typography
          component="p"
          variant="title"
          size={isMobile ? "small" : "medium"}
          fontWeight={400}
          sx={{ mb: 1 }}
        >
          Are you sure you want to remove {member.user.public_name} from this
          space?
        </Typography>
        <Typography component="p" variant="paragraph" size="medium">
          You can’t undo this action.
        </Typography>
      </Box>
      <Box sx={{ textAlign: "end" }}>
        <Button
          variant="destructive"
          shape="squared"
          size="medium"
          onClick={() => deleteMember()}
        >
          Remove member
        </Button>
      </Box>
    </>
  );
};

export const openRemoveMemberModal = (arg: RemoveMemberModalConentProps) => {
  ModalInstance.close();
  ModalInstance.open({
    children: <RemoveMemberModalContent {...arg} />,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    className: styles.modal,
  });
};

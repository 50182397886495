import type { TypedWrappedFieldProps } from "redux-form";

import { Typography } from "@braintrust/braintrust-ui-components";
import { GraterOrEqualZeroIntegerNumberFormat } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import {
  MAX_YEARS_OF_EXPERIENCE,
  MIN_YEARS_OF_EXPERIENCE,
} from "@js/apps/freelancer/constants";
import { NumberWithButtonsField } from "@js/forms/fields";

type YearsOfExperienceProps = TypedWrappedFieldProps<number>;

export const YearsOfExperience = ({ input, meta }: YearsOfExperienceProps) => {
  const isMobile = useMediaQuery("sm");
  const labelFontSize = isMobile ? "small" : "medium";

  return (
    <>
      <Typography
        component="label"
        htmlFor="role-years-of-experience"
        variant="label"
        size={labelFontSize}
        display={"block"}
        mt={3}
        mb={2}
      >
        Years of experience in this role*
      </Typography>
      <NumberWithButtonsField
        id="role-years-of-experience"
        placeholder="0"
        min={MIN_YEARS_OF_EXPERIENCE}
        max={MAX_YEARS_OF_EXPERIENCE}
        NumberFormat={GraterOrEqualZeroIntegerNumberFormat}
        showErrorWhen="dirty"
        center
        meta={meta}
        input={input}
        sx={{ mb: 3 }}
      />
    </>
  );
};

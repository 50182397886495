import { useMemo } from "react";
import type { ControllerRenderProps, FieldError } from "react-hook-form";

import {
  Box,
  ComboBox,
  TextField,
  Typography,
} from "@braintrust/braintrust-ui-components";
import type { AvailabilityFormValues } from "@js/apps/available-for-work/hooks/use-availability-form";

import type { HourOption } from "./constants";
import { HOUR_OPTIONS } from "./constants";

import styles from "./styles.module.scss";

type HoursRangeSelectorProps = {
  startField: ControllerRenderProps<
    AvailabilityFormValues,
    "working_hours_start"
  >;
  endField: ControllerRenderProps<AvailabilityFormValues, "working_hours_end">;
  startError?: FieldError;
  endError?: FieldError;
  disabled: boolean;
};

export const HoursRangeSelector = ({
  startField,
  endField,
  startError,
  endError,
  disabled,
}: HoursRangeSelectorProps) => {
  const { selectedStartHour, selectedEndHour, hoursOptions, error } =
    useSelectRangeHoursField({
      fromHourValue: startField.value,
      toHourValue: endField.value,
      startError,
      endError,
    });

  return (
    <Box mb={2}>
      <Typography
        component="label"
        size="medium"
        fontWeight={500}
        color={disabled ? "grey-3" : undefined}
      >
        Working hours
      </Typography>
      <Box mt={1} display="flex" alignItems="center">
        <ComboBox
          className={styles.hourSelector}
          initialTaxonomiesLoading={false}
          disabled={disabled}
          disableClearable={selectedStartHour !== null}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                ["aria-label"]: "Start working hour",
              }}
            />
          )}
          {...startField}
          ref={undefined}
          options={hoursOptions}
          getOptionLabel={(option) => option.label}
          value={selectedStartHour}
          onChange={(_, selectedHour: HourOption | null) => {
            startField.onChange(selectedHour?.value ?? null);
          }}
        />
        <Typography component="span" size="medium" mx={{ xs: 1, sm: 2 }}>
          to
        </Typography>
        <ComboBox
          className={styles.hourSelector}
          initialTaxonomiesLoading={false}
          disabled={disabled}
          disableClearable={selectedEndHour !== null}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  ["aria-label"]: "End working hour",
                }}
              />
            );
          }}
          {...endField}
          ref={undefined}
          options={hoursOptions}
          getOptionLabel={(option) => option.label}
          value={selectedEndHour}
          onChange={(_, selectedHour: HourOption | null) => {
            endField.onChange(selectedHour?.value ?? null);
          }}
        />
      </Box>
      {!!error && (
        <Typography component="p" size="small" error>
          {error}
        </Typography>
      )}
    </Box>
  );
};

type UseSelectRangeHoursFieldArgs = {
  fromHourValue?: number | null;
  toHourValue?: number | null;
  startError?: FieldError;
  endError?: FieldError;
};

const useSelectRangeHoursField = ({
  fromHourValue,
  toHourValue,
  startError,
  endError,
}: UseSelectRangeHoursFieldArgs) => {
  const selectedStartHour = useMemo(
    () => HOUR_OPTIONS.find(({ value }) => value === fromHourValue) || null,
    [fromHourValue],
  );

  const selectedEndHour = useMemo(
    () => HOUR_OPTIONS.find(({ value }) => value === toHourValue) || null,
    [toHourValue],
  );

  const error = startError?.message || endError?.message;

  return {
    selectedStartHour,
    selectedEndHour,
    hoursOptions: HOUR_OPTIONS,
    error,
  };
};

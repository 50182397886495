import type { ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { DEFAULT_MESSENGER_TEXTBOX_PLACEHOLDER } from "@js/apps/messenger/components/textbox/constants";

function useMessagesStore(): {
  get: (roomId: number) => string | undefined | null;
  set: (roomId: number | undefined, message: string | undefined) => void;
} {
  const messagesRef = useRef<Record<number, string | undefined | null>>({});

  const get = useCallback((roomId: number) => {
    return messagesRef.current[roomId];
  }, []);

  const set = useCallback(
    (roomId: number | undefined, message: string | undefined) => {
      if (!roomId) return;
      messagesRef.current = { ...messagesRef.current, [roomId]: message };
    },
    [],
  );

  return {
    get,
    set,
  };
}

type AttachmentTypeTypes = {
  isImageAttachment?: boolean;
  setIsImageAttachment: React.Dispatch<React.SetStateAction<boolean>>;
};

type MessengerTextboxPlaceHolderTypes = {
  textboxPlaceholder?: string;
  setTextboxPlaceholder: React.Dispatch<React.SetStateAction<string>>;
  resetTextBoxPlaceholder: () => void;
};

type UseMessagesStoreReturnType = ReturnType<typeof useMessagesStore>;

type MessagesContextModel = UseMessagesStoreReturnType &
  MessengerTextboxPlaceHolderTypes &
  AttachmentTypeTypes;

export const MessagesContext = createContext<MessagesContextModel | null>(null);

export const MessagesProvider = ({ children }: { children: ReactNode }) => {
  const messagesStore = useMessagesStore();
  const [textboxPlaceholder, setTextboxPlaceholder] = useState(
    DEFAULT_MESSENGER_TEXTBOX_PLACEHOLDER,
  );
  const [isImageAttachment, setIsImageAttachment] = useState(false);

  const resetTextBoxPlaceholder = useCallback(() => {
    setTextboxPlaceholder(DEFAULT_MESSENGER_TEXTBOX_PLACEHOLDER);
  }, []);

  const providerValue = useMemo(
    () => ({
      ...messagesStore,
      textboxPlaceholder,
      isImageAttachment,
      setTextboxPlaceholder,
      resetTextBoxPlaceholder,
      setIsImageAttachment,
    }),
    [
      messagesStore,
      textboxPlaceholder,
      setTextboxPlaceholder,
      resetTextBoxPlaceholder,
      isImageAttachment,
      setIsImageAttachment,
    ],
  );

  return (
    <MessagesContext.Provider value={providerValue}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = () => {
  const ctx = useContext(MessagesContext);

  if (!ctx) {
    throw new Error("Component beyond MessagesContext");
  }

  return ctx;
};

import type { Dispatch } from "react";
import { useEffect, useState } from "react";

import {
  BasicInput,
  Box,
  Button,
  ButtonCore,
  FormHelperText,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  AddCircleOutlineIcon,
  CloseIcon,
  TaskAltIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useIsTouchDevice } from "@js/apps/common/hooks";
import type { CustomTextFieldProps } from "@js/forms/fields";
import { TextFieldArray } from "@js/forms/fields";

export const MAX_QUESTIONS_LIMIT = SETTINGS.JOB_APPLICATION_QUESTIONS_LIMIT;

export const TextFieldProposalArray = ({ fields }: any) => {
  return (
    <TextFieldArray
      fields={fields}
      CustomTextField={TextFieldProposal}
      CustomPlaceholder={FieldPlaceholder}
      maxQuestionsNumber={MAX_QUESTIONS_LIMIT}
      maxQuestionsLabel={`Max ${MAX_QUESTIONS_LIMIT} questions.`}
    />
  );
};

const TextFieldProposal = (props: CustomTextFieldProps) => {
  const [customFocus, setCustomFocus] = useState(false);
  const { value, onChange } = props.input;
  const { error } = props.meta;
  const { isFirstRender } = props;

  useEffect(() => {
    if (!isFirstRender) {
      setCustomFocus(true);
    }
    return () => setCustomFocus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (customFocus)
    return (
      <>
        <FieldFocused
          value={value}
          onChange={onChange}
          onBlur={() => setCustomFocus(false)}
          {...props}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </>
    );

  if (value)
    return (
      <>
        <FieldFilled
          value={value}
          setFocus={(val) => setCustomFocus(val)}
          {...props}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </>
    );

  return (
    <>
      <FieldPlaceholder onClick={() => setCustomFocus(true)} />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

type FieldFocusedProps = {
  value: string;
  onChange: CustomTextFieldProps["input"]["onChange"];
  onBlur: VoidFunction;
} & CustomTextFieldProps;

const FieldFocused = ({
  value,
  onChange,
  onBlur,
  ...props
}: FieldFocusedProps) => {
  const isTouchDevice = useIsTouchDevice();

  const executeOnBlurAndRemoveIfEmpty = () => {
    onBlur();

    if (!value.trim()) {
      props.fields.remove(props.index);
    }
  };

  return (
    <BasicInput
      autoFocus
      multiline
      onChange={(event) => {
        onChange(event.target.value);
      }}
      value={value}
      inputProps={{
        "aria-label": `Application question number ${props.index + 1}`,
        sx: {
          padding: "8px 18px",
          height: "27px",
        },
      }}
      onBlur={(ev) => {
        // prevent blur when user selects different browser tab
        // https://stackoverflow.com/a/61758013/5908844
        if (ev.target !== document.activeElement) {
          executeOnBlurAndRemoveIfEmpty();
        }
      }}
      onKeyPress={(e) => {
        if (e.code === "Enter") {
          executeOnBlurAndRemoveIfEmpty();
        }
      }}
      startAdornment={<TaskAltIcon sx={{ fontSize: "20px", ml: "14.5px" }} />}
      endAdornment={
        !isTouchDevice && (
          <Typography
            sx={{
              minWidth: "fit-content",
              pr: "18px",
              color: "var(--grey-2)",
            }}
            component="p"
            variant="paragraph"
            size="small"
          >
            Press enter to add
          </Typography>
        )
      }
      sx={{
        borderRadius: "8px",
        backgroundColor: "var(--off-white)",
        width: "100%",
      }}
    />
  );
};

type FieldFilledProps = {
  value: string;
  setFocus: Dispatch<React.SetStateAction<boolean>>;
} & CustomTextFieldProps;

const FieldFilled = ({ value, setFocus, ...props }: FieldFilledProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="18px"
      padding="7.5px 18px 7.5px 14.5px"
      position="relative"
      component="div"
      width="inherit"
      border="none"
      sx={{
        borderRadius: "8px",
        backgroundColor: "var(--medium-green)",
        color: "var(--dark-green)",
      }}
    >
      <TaskAltIcon sx={{ fontSize: "20px", color: "inherit" }} />
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          component="p"
          variant="label"
          size="medium"
          sx={{ wordBreak: "break-word" }}
        >
          {value}
        </Typography>
        <Box display="flex" flexWrap="nowrap">
          <Button
            variant="transparent"
            size="small"
            onClick={() => setFocus(true)}
            onKeyPress={() => setFocus(true)}
            style={{ fontSize: "13px" }}
          >
            Edit{" "}
            <span className="sr-only">
              application question number {props.index + 1}
            </span>
          </Button>
          <IconButton
            variant="transparent"
            onClick={() => props.fields.remove(props.index)}
            onKeyPress={() => props.fields.remove(props.index)}
            aria-label={`Delete application question number ${props.index + 1}`}
          >
            <CloseIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const FieldPlaceholder = ({ onClick }: PlaceholderProps) => {
  return (
    <Box
      component={ButtonCore}
      onClick={onClick}
      onKeyPress={onClick}
      display="flex"
      alignItems="center"
      justifyContent="start"
      gap="18px"
      padding="10px 18px 10px 14.5px"
      position="relative"
      sx={{
        borderStyle: "dotted",
        borderWidth: "1.5px",
        borderRadius: "8px",
        borderColor: "var(--grey-3)",
        color: "var(--grey-3)",
        width: "100%",
      }}
    >
      <AddCircleOutlineIcon style={{ fontSize: "20px", color: "inherit" }} />
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography component="p" variant="label" size="medium">
          Add a question
        </Typography>
      </Box>
    </Box>
  );
};

type PlaceholderProps = {
  onClick: () => void;
};

import classNames from "classnames";

import { Box } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";
import { NotLoggedInApplyButton } from "@js/apps/jobs/components//not-logged-in-apply-button";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";
import { BoostJobButton } from "@js/apps/jobs/components/boost-job-button";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemButtonsProps = {
  isTablet: boolean;
  isMobile: boolean;
  isInvitesTab?: boolean;
  isPublic?: boolean;
  job: MainJobListingJob;
  children?: JSX.Element;
};

export const JobItemButtons = ({
  isTablet,
  isMobile,
  isInvitesTab = false,
  job,
  isPublic = false,
  children,
}: JobItemButtonsProps) => {
  const user = useUser();
  const alreadyBoosted = job?.is_boosted ? job?.is_boosted : false;

  return (
    <Box
      className={classNames(styles.wrapper, {
        [styles.invitesTab]: isTablet && isInvitesTab,
      })}
    >
      {isPublic && (
        <NotLoggedInApplyButton
          jobId={job.id}
          variant="secondary"
          fullWidth={isMobile}
          sx={{ height: isMobile ? "auto" : 40 }}
          size={isMobile ? "x-small" : "medium"}
        />
      )}
      <ApplicationBoostFeatureFlagWrapper>
        {job.has_freelancer_bid &&
          user?.freelancer_approved &&
          !alreadyBoosted && (
            <BoostJobButton
              variant="secondary"
              color="secondary"
              statusForFreelancer={job.status_for_freelancer}
              boostCredit={user?.boost_credit}
              bidId={job?.bid_id}
              fullWidth={isMobile}
              size={isMobile ? "x-small" : "medium"}
              sx={{
                display: { xs: "none", sm: "flex" },
                flexShrink: 0,
                height: isMobile ? "auto" : 40,
              }}
              isOpen={true}
            />
          )}
      </ApplicationBoostFeatureFlagWrapper>

      {children}
    </Box>
  );
};

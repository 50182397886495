import type { TypedWrappedFieldProps } from "redux-form";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  type LocationValue,
  LocationValueType,
} from "@js/components/autocomplete-new/google-places/types";

import { usePopularLocationsButtons } from "./hook";

type PopularLocationsButtonsProps = {
  input: TypedWrappedFieldProps<LocationValue[] | undefined>["input"];
};

export const PopularLocationsButtons = ({
  input,
}: PopularLocationsButtonsProps) => {
  const {
    handleOnClick,
    isBtnSelected,
    isBtnDisabled,
    popularLocationOptions,
  } = usePopularLocationsButtons({ input });

  if (!popularLocationOptions.length) return <Loader />;

  const generatedPopularLocationButtons = popularLocationOptions.map(
    (option) => {
      const opt = getPopularLocationData(option);

      if (!opt) {
        return null;
      }

      const { id: locationId, location } = opt;
      const isDisabled = isBtnDisabled(option);
      const isSelected = isBtnSelected(option);

      return (
        <div
          key={locationId}
          style={{
            cursor: isDisabled ? "not-allowed" : "initial",
          }}
        >
          <Button
            aria-pressed={isSelected ? true : false}
            variant={isSelected ? "primary" : "secondary"}
            disabled={isDisabled}
            shape="squared"
            className="create-job__popular-locations-buttons"
            onClick={() => handleOnClick(option)}
          >
            {location}
          </Button>
        </div>
      );
    },
  );

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <Typography
        component="label"
        mb={1}
        variant="label"
        size="medium"
        fontWeight={400}
      >
        Popular Talent locations
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {generatedPopularLocationButtons}
      </Box>
    </Box>
  );
};

const getPopularLocationData = (location: LocationValue) => {
  if (location?.location_type === LocationValueType.custom) {
    return {
      id: location.custom_location,
      location: location.location,
    };
  }

  if (location?.location_type === LocationValueType.google) {
    return {
      id: location.place_id,
      location: location.location,
    };
  }

  return null;
};

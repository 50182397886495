import { useMemo } from "react";
import classNames from "classnames";
import { omit } from "underscore";

import type {
  AvatarColors,
  AvatarProps,
  AvatarSrc,
} from "@braintrust/braintrust-ui-components";
import { Avatar, Badge } from "@braintrust/braintrust-ui-components";
import { ApprovedBadgeIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { AccountType } from "@js/types/auth";

import { getColor } from "./utils";

import styles from "./styles.module.scss";

export type AvatarWithBadgeProps = {
  approved?: boolean;
  className?: string;
  disableShadow?: boolean;
  hideBorder?: boolean;
  color?: AvatarColors;
  alt?: string;
  user: AvatarSrc & { account_type?: AccountType | null };
  avatarClassName?: string;
  size?: AvatarProps["size"];
  onClick?: AvatarProps["onClick"];
};

export const AvatarWithBadge = ({
  approved = false,
  className = "",
  disableShadow = false,
  size,
  user,
  hideBorder,
  color,
  alt,
  avatarClassName,
  onClick,
}: AvatarWithBadgeProps) => {
  const avatarSrc = useMemo(() => omit(user, "account_type"), [user]);
  const avatarColor = getColor(user.account_type, color, user.avatar_thumbnail);

  return (
    <div className={className}>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ root: styles.badgeRoot, badge: styles.badge }}
        badgeContent={
          approved ? (
            <ApprovedBadgeIcon
              className={classNames(
                styles.badgeIcon,
                size ? styles[size] : undefined,
              )}
            />
          ) : null
        }
      >
        <div className={avatarClassName}>
          <Avatar
            src={avatarSrc}
            alt={alt || ""}
            color={avatarColor}
            size={size}
            className={classNames(
              styles.avatar,
              size ? styles[size] : undefined,
              {
                [styles.noShadow]: disableShadow,
                [styles.noBorder]: hideBorder,
              },
            )}
            onClick={onClick}
          />
        </div>
      </Badge>
    </div>
  );
};

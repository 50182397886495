import React from "react";

import type {
  RatingProps,
  TypographyProps,
} from "@braintrust/braintrust-ui-components";
import {
  Box,
  Divider,
  Rating,
  Typography,
  VerticalSeparator,
} from "@braintrust/braintrust-ui-components";
import { BriefcaseIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  EmployerWebsiteUrl,
  OverviewContainer,
} from "@js/apps/employer/components/profile/";
import type { EmployerLogoWithNameAndColorProps } from "@js/apps/employer/components/profile/employer-logo-with-name-and-color";
import { EmployerLogoWithNameAndColor } from "@js/apps/employer/components/profile/employer-logo-with-name-and-color";
import { RouterLink } from "@js/components/link";
import type { EmployerPublicProfile } from "@js/types/employer";

export type JobEmployerDetailsProps = {
  employer: EmployerPublicProfile;
  jobId: number;
  hideOpenJobs?: boolean;
};

export const JobEmployerDetails = ({
  employer,
  jobId,
  hideOpenJobs,
}: JobEmployerDetailsProps): JSX.Element => {
  const employerProfileUrl = `/employers/${employer.id}/?job=${jobId}`;

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Typography
        variant="label"
        component="h2"
        style={{ overflowWrap: "break-word" }}
        size="medium"
      >
        More About {employer.name}
      </Typography>
      <EmployerLogoNameAndRating employer={employer} jobId={jobId} />
      <Box display="flex" flexDirection="column" gap="16px">
        {employer.website && <EmployerWebsiteUrl url={employer.website} />}
        <Box display="flex" flexWrap="wrap" gap="16px">
          {employer.industry?.name && (
            <Typography variant="label" component="h3" size="medium">
              {employer.industry?.name}
            </Typography>
          )}
          {employer.location && (
            <Typography variant="label" component="h3" size="medium">
              {employer.location}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <OverviewContainer
          employerProfileUrl={employerProfileUrl}
          overview={employer.overview}
          jobId={jobId}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="16px">
        {!hideOpenJobs && employer.open_jobs_count > 1 && (
          <>
            <Divider color="secondary" />
            <Typography
              color="blue"
              component="link"
              to={employerProfileUrl}
              size="medium"
              noTextDecoration
              RouterLink={RouterLink}
            >
              <LabelWithIcon
                icon={<BriefcaseIcon style={{ fontSize: "16px" }} />}
              >
                {employer.open_jobs_count} open jobs
              </LabelWithIcon>
            </Typography>
          </>
        )}
        <Divider color="secondary" />
      </Box>
      <Box display="flex" gap="105px">
        <Box>
          <Typography component="h3" variant="label" size="small">
            Completed Jobs
          </Typography>
          <Typography component="p" variant="paragraph" size="medium">
            {employer.completed_jobs_number}
          </Typography>
        </Box>
        {!!employer.talent_hired_count && (
          <Box>
            <Typography component="h3" variant="label" size="small">
              Talent Hired
            </Typography>
            <Typography component="p" variant="paragraph" size="medium">
              {employer.talent_hired_count}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

type LabelWithIconProps = Omit<TypographyProps, "component" | "to" | "href"> & {
  icon?: JSX.Element;
};

const LabelWithIcon: React.FC<React.PropsWithChildren<LabelWithIconProps>> = ({
  children,
  icon,
  ...props
}) => {
  return (
    <Box display="flex" alignItems="center">
      {icon && (
        <Box mr={1.5} display="flex" justifyContent="center">
          {icon}
        </Box>
      )}
      <Typography
        component="p"
        variant="paragraph"
        whiteSpace="normal"
        size="small"
        {...props}
      >
        {children}
      </Typography>
    </Box>
  );
};

type EmployerLogoNameAndRatingProps = {
  employer: EmployerPublicProfile;
  logoSize?: EmployerLogoWithNameAndColorProps["size"];
  ratingColor?: RatingProps["color"];
  ratingIconSize?: "medium" | "small";
} & Omit<Partial<EmployerLogoWithNameAndColorProps>, "employer">;

export const EmployerLogoNameAndRating = ({
  employer,
  jobId,
  logoSize,
  ratingColor,
  ratingIconSize = "medium",
  ...props
}: EmployerLogoNameAndRatingProps) => {
  const avgRating =
    (Number(employer.average_work_quality) +
      Number(employer.average_responsiveness)) /
    2;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      gap="16px"
    >
      <EmployerLogoWithNameAndColor
        employer={employer}
        jobId={jobId}
        size={logoSize}
        {...props}
      />
      {!!avgRating && (
        <>
          <VerticalSeparator height={16} mr={0} ml={0} />
          <Rating
            color={ratingColor}
            value={avgRating}
            iconStyle={
              ratingIconSize === "medium"
                ? { width: "24px", height: "24px" }
                : { width: "16px", height: "16px" }
            }
            readOnly
            sx={{ display: "flex" }}
          />
        </>
      )}
    </Box>
  );
};

import { Navigate, Outlet } from "react-router-dom";

import {
  Box,
  Button,
  Loader,
  Pagination,
  PaginationWrapper,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  FiltersClearAllButton,
  ResultsCount,
} from "@js/apps/common/components/filters/components";
import { SEARCH_FILTER_DATA_TYPES } from "@js/apps/common/components/filters/constants";
import { SearchFilter } from "@js/apps/common/components/filters/fields";
import { SearchFilterLocationContext } from "@js/apps/common/context/search-filter-location-context";
import {
  CantManageMessage,
  FilteringActions,
  JobManagementHeader,
  JobManagementNav,
} from "@js/apps/jobs/components";
import { JobManagement } from "@js/apps/jobs/layout";
import { RouterLink } from "@js/components/link";
import { useIdParam } from "@js/hooks/use-id-param";

import {
  BidListActionBar,
  BulkSelectActions,
  useJobBids,
} from "../../apps/bids";
import { AirAiDissmissableBar } from "../../apps/bids/components/air-ai-dissmissable-message";
import { VirtualizedJobBidList } from "../../apps/bids/components/virtualized-job-bid-list";
import { BidsLoadingStateTalentsWithSmartMatching } from "../../components/loading-state-talents-with-smart-matching";
import { RefetchBidContextProvider } from "../../context/refetch-bids-context";
import { useCanManageJobsOnBehalfOfClient } from "../../hooks";
import { useAIRInterview } from "../../hooks/use-air-interview";

import style from "./style.module.scss";

export const InterviewPage = () => {
  const jobId = useIdParam();
  const { showAIRInterview } = useAIRInterview();

  if (!jobId || !showAIRInterview) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  return (
    <JobManagement.Layout pageTitle="Job - Invite Talent">
      <InterviewPageContent jobId={jobId} />
    </JobManagement.Layout>
  );
};

const AiInterviewEnabledNoSchedule = ({ jobId }: { jobId: number }) => {
  return (
    <Box my={6} className={style.aiInterviewSection}>
      <Typography
        component="span"
        className={style.aiInterviewSectionIcon}
        sx={{
          backgroundColor: "#E8F2FC",
          marginBottom: "15px",
        }}
      >
        💭
      </Typography>
      <Typography component="h1" fontWeight={500} size="large">
        No interviews scheduled yet
      </Typography>
      <Typography
        component="h1"
        fontWeight={400}
        size="medium"
        maxWidth="648px"
        textAlign="center"
      >
        Once you start scheduling interviews, this is where you’ll find all your
        candidates and track their progress through the interview stages.
      </Typography>
      <Button
        variant="primary"
        type="button"
        sx={{ marginTop: "15px" }}
        to={`/jobs/${jobId}/interview-questions`}
        RouterLink={RouterLink}
      >
        Edit AI Interview
      </Button>
    </Box>
  );
};

const AiInterviewDisabled = ({ jobId }: { jobId: number }) => {
  return (
    <Box my={6} className={style.aiInterviewSection}>
      <Typography
        component="span"
        className={style.aiInterviewSectionIcon}
        sx={{
          background:
            "linear-gradient(119.92deg, #D4E4F9 1.7%, #E5D7FB 44.69%, #FFF5E3 80.85%, #FFFDBD 102.16%)",
          marginBottom: "15px",
        }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/ai-icon.svg`}
          alt="ai job"
          width={45}
          height={48}
          style={{ marginLeft: "-3px" }}
        />
      </Typography>
      <Typography component="h1" fontWeight={500} size="large">
        Automate your interview process
      </Typography>
      <Typography
        component="h1"
        fontWeight={400}
        size="medium"
        maxWidth="648px"
        textAlign="center"
      >
        Utilize our AI interview feature to screen candidates efficiently. Our
        AI conducts initial interviews based on the criteria you set, saving you
        time and ensuring a consistent interview experience for all candidates.
      </Typography>
      <Button
        variant="primary"
        type="button"
        sx={{ marginTop: "15px" }}
        to={`/jobs/${jobId}/interview-questions`}
        RouterLink={RouterLink}
      >
        Create AI interview
      </Button>
    </Box>
  );
};

const InterviewPageContent = ({ jobId }: { jobId: number }) => {
  const {
    loading,
    jobBidsLoading,
    jobBidsFetching,
    job,
    refetchBidList,
    areFiltersDefault,
    isShowingHiddenBids,
    resetFilters,
    totalBidsCount,
    bidList,
  } = useJobBids(jobId);
  const canManageJobsOnBehalfOfClient = useCanManageJobsOnBehalfOfClient();

  if (loading) return <Loader />;

  if (!job) return <></>;

  if (!job.user_can_manage) {
    return (
      <CantManageMessage
        title="You can't manage talent interviews in this job."
        to={`/jobs/${job?.id}`}
      />
    );
  }

  if (totalBidsCount === 0 && areFiltersDefault) {
    return (
      <JobManagement.ContentLayout
        header={<JobManagementHeader job={job} />}
        main={
          <JobManagement.ContentBox>
            <JobManagementNav
              job={job}
              totalBidsCount={job.bids_count?.total_bids_count}
              totalInterviewingBidsCount={totalBidsCount}
            />
            {job.is_ai_interview_enabled ? (
              <AiInterviewEnabledNoSchedule jobId={jobId} />
            ) : (
              <AiInterviewDisabled jobId={jobId} />
            )}
          </JobManagement.ContentBox>
        }
      />
    );
  }
  return (
    <JobManagement.ContentLayout
      header={<JobManagementHeader job={job} />}
      main={
        <SearchFilterLocationContext.Provider value="bid_search_box">
          <RefetchBidContextProvider
            loadingBids={jobBidsLoading}
            fetchingBids={jobBidsFetching}
            refetchBidList={refetchBidList}
          >
            <JobManagement.ContentBox className={style.jobBidsPageMain}>
              <Box className={style.jobBidsListSectionHeader}>
                <JobManagementNav
                  job={job}
                  totalBidsCount={job.bids_count?.total_bids_count}
                  totalInterviewingBidsCount={totalBidsCount}
                />
                <Box sx={{ maxWidth: "200px", flexShrink: 0 }}>
                  <SearchFilter
                    className={style.jobBidsSearchField}
                    dataType="BID"
                    placeholder="Search by name"
                  />
                </Box>
              </Box>
              <BidListActionBar bids={bidList}>
                <FilteringActions
                  isMatcher={canManageJobsOnBehalfOfClient}
                  isAiInterviewEnabled={job.is_ai_interview_enabled}
                  jobType={job.job_type}
                  jobId={job.id}
                />
                <BulkSelectActions
                  job={job}
                  bidList={bidList}
                  isShowingHiddenBids={isShowingHiddenBids}
                />
              </BidListActionBar>
              {!areFiltersDefault && (
                <Box display="flex" alignItems="center">
                  <ResultsCount
                    dataType={SEARCH_FILTER_DATA_TYPES.talent}
                    count={totalBidsCount}
                    wholeTextBold
                  />
                  <FiltersClearAllButton onClick={resetFilters} />
                </Box>
              )}
              {job.is_ai_interview_enabled && totalBidsCount !== 0 && (
                <AirAiDissmissableBar jobId={job.id} />
              )}

              {jobBidsLoading ? (
                <BidsLoadingStateTalentsWithSmartMatching />
              ) : (
                <>
                  <VirtualizedJobBidList
                    job={job}
                    bidList={bidList}
                    areFiltersDefault={areFiltersDefault}
                  />
                  <PaginationWrapper mt={0}>
                    <Pagination
                      RouterLink={RouterLink}
                      count={totalBidsCount}
                      perPage={SETTINGS.EMPLOYER_BIDS_JOBS_PER_PAGE}
                    />
                  </PaginationWrapper>
                  <Outlet />
                </>
              )}
            </JobManagement.ContentBox>
          </RefetchBidContextProvider>
        </SearchFilterLocationContext.Provider>
      }
    />
  );
};

import { useCallback } from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

import { deleteSavedFilters } from "../../actions";
import type { SavedFiltersEndpoint } from "../../const";

export const useDeleteSavedFilter = (endpoint: SavedFiltersEndpoint) => {
  const dispatch = useAppDispatch();

  const deleteSavedFilter = useCallback(
    (filterId: number) => {
      ModalInstance.open({
        children: (
          <ModalConfirm
            onCancel={ModalInstance.close}
            onConfirm={() => {
              dispatch(deleteSavedFilters(endpoint, filterId)).then(() => {
                Snackbar.success("Deleted saved filter.");
                ModalInstance.close();
              });
            }}
          >
            <Box p="32px 16px 16px 0">Are you sure you want to delete it?</Box>
          </ModalConfirm>
        ),
      });
    },
    [dispatch, endpoint],
  );

  return { deleteSavedFilter };
};

import { Stack, type StackProps } from "@braintrust/braintrust-ui-components";
import {
  CalendarIcon,
  ChatIcon,
  EarthIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { Space } from "@js/types/spaces";
import { pluralize } from "@js/utils";

import { ActivityItem } from "./activity-item";

export type SpaceActivitiesProps = {
  spaceType: Space["visibility"];
  created: Space["created"];
  lastWeekPosts: Space["number_of_posts_for_last_7_days"];
  lastMonthPosts?: Space["number_of_posts_for_last_30_days"];
} & StackProps;

export const SpaceActivities = ({
  spaceType,
  lastWeekPosts,
  lastMonthPosts,
  created,
  ...stackProps
}: SpaceActivitiesProps) => {
  return (
    <Stack {...stackProps} sx={{ gap: 2, ...stackProps.sx }}>
      {spaceType && (
        <ActivityItem
          icon={<EarthIcon style={{ color: "var(--dark-green)" }} />}
          color="var(--medium-green)"
          activity={`${spaceType === "public" ? "Public" : ""} space`}
          activitySubtext={
            spaceType === "public"
              ? "Everyone can see posts in this space"
              : undefined
          }
        />
      )}
      <ActivityItem
        icon={<ChatIcon style={{ color: "var(--dark-violet)" }} />}
        color="var(--medium-violet)"
        activity={`${lastWeekPosts ?? 0} new post${pluralize(
          lastWeekPosts ?? 0,
          { zeroPlural: true },
        )} last week`}
        activitySubtext={`${lastMonthPosts ?? 0} post${pluralize(
          lastMonthPosts ?? 0,
          { zeroPlural: true },
        )} last month`}
      />
      {created && (
        <ActivityItem
          icon={<CalendarIcon style={{ color: "var(--dark-yellow)" }} />}
          color="var(--medium-yellow)"
          activity={`Created ${created}`}
        />
      )}
    </Stack>
  );
};

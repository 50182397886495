import {
  Avatar,
  Box,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { SearchListItem } from "@js/apps/universal-search/components/search-results/search-list-item";
import { Emoji } from "@js/components/emoji";
import type { User } from "@js/types/auth";
import type { IPost } from "@js/types/give-and-get-help";
import { relativeDateDifference } from "@js/utils";

import { DetailsContentContainer } from "./details-content-container";

import styles from "./career-search-list-item.module.scss";

export type CareerSearchListItemProps = {
  post: Pick<
    IPost,
    "id" | "created" | "category" | "title" | "is_celebratory_post"
  > & {
    freelancer: {
      user: Pick<
        User,
        | "has_avatar_set"
        | "avatar"
        | "avatar_thumbnail"
        | "gravatar"
        | "introduction_headline"
        | "public_name"
        | "first_name"
      >;
    };
  };
  onClick?: () => void;
};

export const CareerSearchListItem = ({
  post,
  onClick,
}: CareerSearchListItemProps) => {
  const isMobile = useMediaQuery("sm");
  const createdLabel = relativeDateDifference(post.created);

  const postCategory = post.category;
  const emoji = getEmoji(post);
  const badgeComponent = (
    <Box className={styles.categoryContainter}>
      <Emoji emoji={emoji} size="24px" />
    </Box>
  );

  const detailsContent = (
    <DetailsContentContainer>
      <Stack direction="row" gap={1}>
        <Avatar src={post.freelancer.user} size={"xs"} textSize="small" />
        {!isMobile && (
          <Typography component="span" variant="paragraph" size="small">
            {post.freelancer.user.public_name}
          </Typography>
        )}
      </Stack>

      {!!postCategory && (
        <Typography
          component="span"
          variant="paragraph"
          size="small"
          ellipsis
          sx={{ overflow: "hidden" }}
          color="tertiary"
        >
          {postCategory.name}
        </Typography>
      )}

      {!isMobile && createdLabel && (
        <Typography
          component="span"
          variant="paragraph"
          size="small"
          color="tertiary"
        >
          {createdLabel}
        </Typography>
      )}
    </DetailsContentContainer>
  );

  return (
    <SearchListItem
      to={`/feed/${post.id}/`}
      title={post.title}
      onClick={onClick}
      badgeComponent={badgeComponent}
      detailsContent={detailsContent}
    />
  );
};

const getEmoji = (post: CareerSearchListItemProps["post"]) => {
  const postCategory = post.category;

  if (postCategory?.emoji) {
    return postCategory.emoji;
  }

  if (post.is_celebratory_post) {
    return "🎉";
  }

  return "braintrust";
};

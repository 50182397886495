import type { FC } from "react";
import React, { useEffect } from "react";

import type { SvgIcon } from "@braintrust/braintrust-ui-components";
import { Button } from "@braintrust/braintrust-ui-components";

import WelcomeLayout from "../../layouts/welcome";

import type { CrumbProps } from "./breadcrumbs";
import { Breadcrumbs, Crumb } from "./breadcrumbs";

/**
 * Example usage:
 *
 *  ```
 *  const FreelancerWizard = wizard(crumbs);
 *
 *  <FreelancerWizard
 *      step={1}
 *      onContinue={() => console.log('Continue...')}>
 *      Your Content
 *      </FreelancerWizard>
 *  ```
 */

let maxVisitedStep = 0;

const setMaxVisitedStep = (step: number): void => {
  if (maxVisitedStep < step) {
    maxVisitedStep = step;
  }
};

type WizardProps = {
  children?: React.ReactNode;
  step?: number;
  onContinue?: () => void;
  continueButtonText?: string;
  continueButtonColor?: string;
  crumbs?: CrumbProps[];
  customBackURL?: string;
  customBackIcon?: typeof SvgIcon;
  hideSeparator?: boolean;
  oneActive?: boolean;
  slot?: JSX.Element;
  buttonsSlot?: JSX.Element;
  pageTitle?: string;
  disableSubmit?: boolean;
};

export const Wizard: FC<React.PropsWithChildren<WizardProps>> = (
  props,
): JSX.Element => {
  const {
    children,
    step = 0,
    onContinue,
    continueButtonText,
    continueButtonColor,
    crumbs = [],
    customBackIcon,
    customBackURL,
    hideSeparator,
    oneActive,
    slot,
    buttonsSlot,
    disableSubmit,
    ...rest
  } = props;

  const backURL =
    customBackURL || (step && (crumbs[step - 2] ? crumbs[step - 2].path : ""));

  useEffect(() => {
    setMaxVisitedStep(step);
  }, [step]);

  const headerSlot = (
    <>
      <Breadcrumbs
        step={step}
        maxVisitedStep={maxVisitedStep}
        hideSeparator={hideSeparator}
        oneActive={oneActive}
      >
        {crumbs.map((crumb) => (
          <Crumb key={crumb.title} {...crumb} />
        ))}
      </Breadcrumbs>

      {slot}

      <div className="buttons">
        {buttonsSlot}

        {!!onContinue && (
          <Button
            onClick={onContinue}
            variant="primary"
            inverse
            color={continueButtonColor || "primary"}
            disabled={disableSubmit}
          >
            {continueButtonText || "Continue"}
          </Button>
        )}
      </div>
    </>
  );

  return (
    <WelcomeLayout
      {...rest}
      showLogo={false}
      backURL={backURL}
      backIcon={customBackIcon}
      headerSlot={headerSlot}
      fixedHeader
      justify="flex-start"
    >
      {children}
    </WelcomeLayout>
  );
};

import { Typography } from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import styles from "./freelancer-not-found.module.scss";

const FreelancerNotFound = () => {
  return (
    <>
      <Typography component="h1" variant="display">
        404
      </Typography>
      <Typography
        component="p"
        variant="title"
        fontWeight={400}
        className={styles.message}
      >
        Sorry - the requested freelancer profile can’t be found/is no longer
        active.
      </Typography>
      <Typography
        component="p"
        variant="title"
        size="medium"
        fontWeight={400}
        className={styles.info}
      >
        Return to the <RouterLink to="/">homepage</RouterLink> or contact us
        about the problem
      </Typography>
    </>
  );
};

export default FreelancerNotFound;

import type { ChipProps } from "@braintrust/braintrust-ui-components";
import { Box, Chip } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { StarIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { FreelancerSkill } from "@js/types/freelancer";

import styles from "./styles.module.scss";

type SkillListProps = {
  prominent: Array<FreelancerSkill>;
  prominentChipColor?: ChipProps["color"];
  chipColor?: ChipProps["color"];
  skills?: Array<FreelancerSkill>;
  chipSize?: ChipProps["size"];
};

export const SkillList = ({
  skills,
  prominent,
  chipColor = "tertiary",
  prominentChipColor = "primary",
}: SkillListProps): JSX.Element => {
  const isSmall = useMediaQuery("sm");

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={1}
        flexDirection={isSmall ? "column" : "row"}
        alignItems="stretch"
      >
        {prominent.map((el) => {
          return (
            <Chip
              key={`prominent-${el.id}`}
              className={styles.priminentSkill}
              variant="rounded"
              label={el.skill.name}
              LabelProps={{
                component: "span",
                variant: "label",
              }}
              icon={<StarIcon />}
              color={prominentChipColor}
              size="default"
              typographySize="medium"
            />
          );
        })}
      </Box>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {skills &&
          skills.map((skill) => {
            return (
              <Chip
                key={`skill-${skill.id}`}
                label={skill.skill.name}
                LabelProps={{
                  component: "span",
                  variant: "label",
                }}
                color={chipColor}
                variant="rounded"
                size="small"
                typographySize="small"
              />
            );
          })}
      </Box>
    </Box>
  );
};

import type { ReactNode } from "react";

import { Box } from "@braintrust/braintrust-ui-components";

type FiltersCarouselContainerProps = {
  children: ReactNode;
};

export const FiltersCarouselContainer = ({
  children,
}: FiltersCarouselContainerProps) => {
  return <Box sx={{ mb: 2 }}>{children}</Box>;
};

import type { WrappedFieldsProps } from "redux-form";
import { Fields } from "redux-form";
import cs from "classnames";

import {
  Box,
  Button,
  Divider,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { ServicesBudgetField } from "@js/apps/common/forms/fields/budget-field";
import { useClearAddServicesForm } from "@js/apps/freelancer/forms/add-ggh-services-form/service-category-form";
import { useGetPostCategoriesQuery } from "@js/apps/give-and-get-help/api";
import {
  getBudgetOptions,
  getPostCategoriesOptions,
} from "@js/apps/give-and-get-help/components/category-and-budget-utils/utils";

import { DescriptionField } from "./fields/description-field";

import styles from "./styles.module.scss";

type ServiceDetailsFormContentProps = {
  onBack: () => void;
  onDiscardChanges: () => void;
};

export const ServiceDetailsFormContent = ({
  onBack,
  onDiscardChanges,
}: ServiceDetailsFormContentProps) => {
  return (
    <Fields
      names={["category", "id"]}
      onBack={onBack}
      onDiscardChanges={onDiscardChanges}
      component={ServiceDetailsFormContentComponent}
    />
  );
};

const ServiceDetailsFormContentComponent = ({
  onBack,
  category,
  id,
  onDiscardChanges,
}: ServiceDetailsFormContentProps & WrappedFieldsProps) => {
  const { destroyForm } = useClearAddServicesForm();
  const { data, isLoading } = useGetPostCategoriesQuery();
  const isSmall = useMediaQuery("sm");

  const selectedCategory = category.input.value;
  const isEditMode = id.input.value;

  if (isLoading || !data?.length) return null;

  const categories = getPostCategoriesOptions(data, false);

  const displayCategory = categories.find(
    (el) => el.value === selectedCategory,
  );

  const budgetOptions = getBudgetOptions({
    budget_higher: displayCategory?.budget_higher,
    budget_lower: displayCategory?.budget_lower,
  });

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography
          component="h3"
          variant={isSmall ? "paragraph" : "title"}
          size={isSmall ? "large" : "small"}
          fontWeight={400}
        >
          Services
        </Typography>
      </Box>
      <Typography component="p" my={3}>
        Help the community with <b>{displayCategory?.name}</b>
      </Typography>

      <DescriptionField label="Describe this service" />

      <ServicesBudgetField options={budgetOptions} />

      <Divider color="light-grey" />

      <Box
        className={cs(styles.footer, {
          [styles.footerEdit]: isEditMode,
        })}
      >
        {isEditMode ? (
          <Button
            shape="squared"
            variant="secondary"
            size="medium"
            onClick={() => {
              onDiscardChanges();
              destroyForm();
            }}
          >
            Discard changes
          </Button>
        ) : (
          <Button
            shape="squared"
            variant="secondary"
            size="medium"
            onClick={onBack}
          >
            Back
          </Button>
        )}
        <Button shape="squared" variant="positive" type="submit" size="medium">
          {isEditMode ? "Save changes" : "Save"}
        </Button>
      </Box>
    </Box>
  );
};

import React from "react";

import { Box, Stack } from "@braintrust/braintrust-ui-components";
import type { GetTalentReferralsParams } from "@js/apps/dashboard/types";
import type { FreelancerReferralsReturnType } from "@js/types/dashboard";

import {
  DEFAULT_TALENT_REFERRALS_ORDER_BY,
  TALENT_REFERRALS_ORDER_BY_OPTIONS,
} from "../../constants";
import { useReferralSearch } from "../../hooks/use-referral-search";
import { DownloadReferralsCSVBtn } from "../download-referrals-csv";
import {
  ReferralsStatsListLoader,
  ReferralsStatsListResultsCount,
} from "../referral-stats";
import { ReferralsEmptyState } from "../referrals-empty-state";
import { ReferralsListTopContainer } from "../referrals-list-top-container";
import { ReferralsSearch } from "../referrals-search";
import { ReferralsSortButton } from "../referrals-sort-button";

import { TalentCardList } from "./talent-card-list";
import { TalentReferralListHeader } from "./talent-referrals-list-header";

export type TalentReferralsListProps = {
  referrals: FreelancerReferralsReturnType | undefined;
  fetchMore: () => void;
  loading: boolean;
  filters: GetTalentReferralsParams;
};

export const TalentReferralsList = ({
  referrals,
  fetchMore,
  loading,
  filters,
}: TalentReferralsListProps) => {
  const { searchValue, handleUpdateSearch, handleSearchClear } =
    useReferralSearch();
  const referralItems = referrals?.results ?? [];
  const showReferralsItems = !!referralItems.length;
  const showLoader = !referralItems.length && loading;
  const showEmptyState = !referralItems.length && !loading;

  return (
    <Stack>
      <ReferralsListTopContainer>
        <Box display="flex" gap={1} width="100%" maxWidth={580} flexWrap="wrap">
          <ReferralsSearch
            value={searchValue}
            onChange={handleUpdateSearch}
            onClear={handleSearchClear}
            variant="green"
          />
          <DownloadReferralsCSVBtn
            endpoint="/api/freelancer_referrals/"
            filters={filters}
            type="talent"
          />
        </Box>
        <ReferralsSortButton
          colorVariant="green"
          orderByOptions={TALENT_REFERRALS_ORDER_BY_OPTIONS}
          defaultOrderByParamValue={DEFAULT_TALENT_REFERRALS_ORDER_BY}
          type="talent"
        />
      </ReferralsListTopContainer>
      <TalentReferralListHeader />
      {showLoader && <ReferralsStatsListLoader />}
      {showEmptyState && (
        <ReferralsEmptyState onClear={handleSearchClear} variant="green" />
      )}
      {showReferralsItems && referrals && (
        <>
          <TalentCardList referrals={referralItems} />
          <ReferralsStatsListResultsCount
            count={referralItems.length}
            total={referrals.count}
            hasMore={!!referrals.next}
            fetchMore={fetchMore}
            loading={loading}
          />
        </>
      )}
    </Stack>
  );
};

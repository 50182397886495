import { Box, Typography } from "@braintrust/braintrust-ui-components";

import styles from "./style.module.scss";

export const SubmitLinkedinUrlAlreadyOptedState = () => {
  return (
    <Box className={styles.optedState}>
      <Box className={styles.icon}>
        <Typography component="p" variant="title" size="medium">
          🙌
        </Typography>
      </Box>

      <Typography component="p" variant="paragraph" width="fit-content">
        Thanks for submitting your LinkedIn URL. The Braintrust team will review
        your network and if you are a good match we’ll reach out.
      </Typography>
    </Box>
  );
};

import { Typography } from "@braintrust/braintrust-ui-components";
import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";
import { RouterLink } from "@js/components/link";

import { HelpOfferButtonPrimary } from "./help-offer-button-primary";

export const HelpOfferButtonsLeaveReview = ({
  offer,
  isOfferAuthor,
  isMobile,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const isOfferAuthorRemoved = offer.author.user.is_removed;
  if (isOfferAuthor || isOfferAuthorRemoved) return null;

  return (
    <HelpOfferButtonPrimary
      to={`/talent/${offer.author.id}/?write_review=true&offer=${offer.id}&content_type=${ENUMS.ReviewType.HELP_OFFER_REVIEW}`}
      RouterLink={RouterLink}
    >
      Leave{" "}
      {!isMobile && (
        <Typography
          component="span"
          ellipsis
          sx={{ "&&": { font: "inherit" }, mx: "5px" }}
        >
          {offer.author.user.first_name}
        </Typography>
      )}
      a review
    </HelpOfferButtonPrimary>
  );
};

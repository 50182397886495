import { Avatar, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { SignupBanner } from "@js/components/signup-banner";
import type { User } from "@js/types/auth";

import styles from "./styles.module.scss";

type PublicSpacePageSignupBannerProps = {
  inviter: Pick<
    User,
    "avatar" | "avatar_thumbnail" | "gravatar" | "has_avatar_set" | "first_name"
  >;
};
export const PublicSpacePageSignupBanner = ({
  inviter,
}: PublicSpacePageSignupBannerProps) => {
  const isMobile = useMediaQuery("sm");
  const copy = isMobile
    ? `${inviter?.first_name} invited you.`
    : `${inviter?.first_name} invited you to this space`;

  return (
    <SignupBanner>
      <>
        <div className={styles.publicSpacePageSignupBannerAvatar}>
          <Avatar src={inviter} size="xs" role="avatar" />
        </div>
        <div className={styles.publicSpacePageSignupBannerBadge}>
          <Typography component="span" variant="label" size="small">
            {copy}
          </Typography>
        </div>
      </>
      {!isMobile && (
        <Typography
          className={styles.publicSpacePageSignupBannerCopy}
          component="span"
          variant="paragraph"
          size="small"
          ml={1}
        >
          View their conversations and join by creating an account.
        </Typography>
      )}
    </SignupBanner>
  );
};

import { useMemo } from "react";

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { PersonAddFilledIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useGetEmployerTeamMembersQuery } from "@js/apps/employer/api";
import { useAppDispatch } from "@js/hooks";
import type { EmployerTeamMemberType } from "@js/types/employer";
import type { JobSubscriberData } from "@js/types/jobs";

import { addTeamMembersClicked } from "../../actions";

import styles from "./style.module.scss";

type AddTeamMembersButtonProps = {
  onClick: () => void;
} & PrependedContentProps;

export const AddTeamMembersButton = ({
  onClick,
  jobSubscribers,
}: AddTeamMembersButtonProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(addTeamMembersClicked());
    onClick();
  };

  return (
    <Button
      shape="squared"
      variant="white-violet"
      inverse
      startIcon={<PrependedContent jobSubscribers={jobSubscribers} />}
      onClick={handleClick}
    >
      Add team members
    </Button>
  );
};

type PrependedContentProps = { jobSubscribers: JobSubscriberData[] };

const PrependedContent = ({ jobSubscribers }: PrependedContentProps) => {
  const { data: teamMembers } = useGetEmployerTeamMembersQuery();

  const subscribedTeamMembers = useMemo(() => {
    if (!teamMembers || !jobSubscribers.length) {
      return [];
    }
    return teamMembers.filter((member) =>
      jobSubscribers.some(
        (jobSubscriber) => jobSubscriber.team_member_id === member.id,
      ),
    );
  }, [jobSubscribers, teamMembers]);

  return (
    <Box display="flex" alignItems="center" gap="12px">
      {!!subscribedTeamMembers.length && (
        <TeamMembersAvatars users={subscribedTeamMembers} />
      )}
      <PersonAddFilledIcon className={styles.icon} />
    </Box>
  );
};

type TeamMembersAvatarsProps = {
  users: EmployerTeamMemberType[];
};

const TeamMembersAvatars = ({ users }: TeamMembersAvatarsProps) => {
  const displayedUsers = users.slice(0, 2);

  return (
    <AvatarGroup
      spacing={8}
      total={users.length}
      renderSurplus={(surplus) => (
        <Typography
          component="p"
          size="small"
          fontWeight={500}
          color="grey-2"
          data-testid="avatar-surplus"
        >
          {surplus}
        </Typography>
      )}
      classes={{
        avatar: styles.avatar,
      }}
    >
      {displayedUsers.map((user) => {
        return (
          <Avatar
            key={user.id}
            src={{ ...user, last_name: null }}
            color="medium-violet"
            data-testid={`team-member-avatar-${user.id}`}
          />
        );
      })}
    </AvatarGroup>
  );
};

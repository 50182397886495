import React from "react";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import { Tooltip, Typography } from "@braintrust/braintrust-ui-components";
import { JobBudget } from "@js/apps/jobs/components/job-budget";
import { jobBudgetTooltipTitle } from "@js/apps/jobs/components/job-item/utils";
import type { MainJobListingJob } from "@js/types/jobs";

type JobBudgetWithPaymentTypeTooltipProps = {
  job: Pick<
    MainJobListingJob,
    "payment_type" | "budget_minimum_usd" | "budget_maximum_usd"
  >;
  size?: TypographyProps["size"];
  variant?: TypographyProps["variant"];
  fontWeight?: TypographyProps["fontWeight"];
};
export const JobBudgetWithPaymentTypeTooltip = ({
  job,
  size = "large",
  variant = "paragraph",
  fontWeight,
}: JobBudgetWithPaymentTypeTooltipProps) => {
  return (
    <Typography
      component="p"
      variant={variant}
      size={size}
      fontWeight={fontWeight}
    >
      <Tooltip
        title={jobBudgetTooltipTitle(job.payment_type)}
        arrow
        placement="top"
        enterDelay={500}
        enterNextDelay={300}
      >
        <span>
          <JobBudget job={job} formatWithPrefix />
        </span>
      </Tooltip>
    </Typography>
  );
};

import type { RefObject } from "react";
import { useEffect, useState } from "react";

import { CSSVariable } from "@braintrust/braintrust-ui-components/utils";

export const useHandleLeftColumnWidth = (elRef: RefObject<HTMLElement>) => {
  const [leftColumnWidth, setLeftColumnWidth] = useState(0);

  useEffect(() => {
    const el = elRef.current;
    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setLeftColumnWidth(width);
    });

    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.disconnect();
      }
    };
  }, [elRef]);

  useEffect(() => {
    CSSVariable.setValue("--left-column-width", `${leftColumnWidth}px`);
  }, [leftColumnWidth]);

  useEffect(() => {
    return () => {
      CSSVariable.setValue("--left-column-width", "0px");
    };
  }, []);

  return leftColumnWidth;
};

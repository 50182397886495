import type { FormEvent, ReactNode } from "react";

import type {
  BoxProps,
  StackProps,
} from "@braintrust/braintrust-ui-components";
import { Box, Stack } from "@braintrust/braintrust-ui-components";

import styles from "./common-team-members-modal-content-containers.module.scss";

type CommonTeamMembersModalContentContainerProps = {
  children: ReactNode;
  onSubmit: () => void;
} & Pick<StackProps, "sx">;

export const CommonTeamMembersModalContentContainer = ({
  children,
  sx,
  onSubmit,
}: CommonTeamMembersModalContentContainerProps) => {
  const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    onSubmit();
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit}
      sx={{ gap: 3, ...sx }}
      className={styles.modalContent}
    >
      {children}
    </Stack>
  );
};

type CommonTeamMembersModalHeaderContaineProps = { children: ReactNode } & Pick<
  BoxProps,
  "sx"
>;

export const CommonTeamMembersModalHeaderContainer = ({
  children,
  sx,
}: CommonTeamMembersModalHeaderContaineProps) => {
  return (
    <Box className={styles.modalHeader} sx={sx}>
      {children}
    </Box>
  );
};

type CommonTeamMembersModalCTAContaineProps = { children: ReactNode };

export const CommonTeamMembersModalCTAContainer = ({
  children,
}: CommonTeamMembersModalCTAContaineProps) => {
  return <Box className={styles.modalCtaContainer}>{children}</Box>;
};

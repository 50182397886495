import { Box, Loader } from "@braintrust/braintrust-ui-components";

export const BookmarksLoader = () => {
  return (
    <Box
      display="grid"
      justifyContent="center"
      alignContent="center"
      minHeight={200}
    >
      <Loader />
    </Box>
  );
};

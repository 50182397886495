import React from "react";

import { Chip, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import {
  CircleCheckOutlineIcon,
  InfoIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useIsCategorySuggestedContext } from "@js/apps/give-and-get-help/context";
import { openSuggestCategoryModal } from "@js/apps/give-and-get-help/hooks/suggest-category";
import { Emoji } from "@js/components/emoji";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

type CreateNewCategoryPillsProps = {
  onSuggestCategoryCancel: () => void;
  onSuggestCategorySuccess: () => void;
  onSuggestCategoryFail: () => void;
};

export const CreateNewCategoryPills = ({
  onSuggestCategoryCancel,
  onSuggestCategorySuccess,
  onSuggestCategoryFail,
}: CreateNewCategoryPillsProps) => {
  const { isCategorySuggested } = useIsCategorySuggestedContext();
  const isSmall = useMediaQuery("sm");

  if (isCategorySuggested) {
    return <CategorySuccessfullySuggested />;
  }

  const handleClick = () => {
    ModalInstance.close();
    openSuggestCategoryModal({
      onCancel: onSuggestCategoryCancel,
      onSubmitSuccess: onSuggestCategorySuccess,
      onSubmitFail: onSuggestCategoryFail,
    });
  };

  return (
    <Chip
      fullWidth={isSmall}
      color="soft-grey"
      LabelProps={{
        ellipsis: false,
      }}
      icon={<InfoIcon />}
      label={
        <Typography size={isSmall ? "small" : "medium"} component="span">
          Need a different category?{" "}
          <Typography
            component="button"
            sx={{
              textDecoration: "underline",
              border: "none",
              backgroundColor: "transparent",
              color: "currentColor",
              padding: 0,
              cursor: "pointer",
            }}
            size={isSmall ? "small" : "medium"}
            onClick={handleClick}
          >
            Let us know
          </Typography>
        </Typography>
      }
    />
  );
};

const CategorySuccessfullySuggested = () => {
  const isSmall = useMediaQuery("sm");

  return (
    <Chip
      fullWidth={isSmall}
      color="green"
      style={{
        whiteSpace: "normal",
      }}
      className={styles.suggestCategoryModalChip}
      icon={
        <CircleCheckOutlineIcon
          sx={{
            fontSize: "16px",
            color: "var(--positive-2)",
          }}
        />
      }
      LabelProps={{
        ellipsis: false,
        size: isSmall ? "small" : "medium",
        fontWeight: 400,
      }}
      label={
        <>
          Your category suggestion was sent to the team{" "}
          <Emoji emoji="🎉" className={styles.suggestCategoryModalChipEmoji} />
        </>
      }
    />
  );
};

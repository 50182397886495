import { useCallback, useRef } from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import { CreatePostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { openCreatePostModal } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/open-modal";
import { CreatePostFAB } from "@js/apps/give-and-get-help/components/create-post";
import {
  PostBtn,
  PostTextField,
} from "@js/apps/give-and-get-help/components/create-post/components";
import { useOpenCreatePostModalFromParams } from "@js/apps/give-and-get-help/components/create-post/hooks";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { useShouldShowCreatePostFAB } from "@js/apps/give-and-get-help/hooks";
import { UserAvatar } from "@js/components/user-avatar";
import { useAppDispatch } from "@js/hooks";
import type { Space } from "@js/types/spaces";

import { useIsSpaceMember } from "../../hooks/use-is-space-member";
import { openJoinThisSpaceModal } from "../join-the-space-modal/join-this-space-modal";

import styles from "./styles.module.scss";

export const CreateSpacePost = ({ spaceData }: { spaceData: Space }) => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();
  const { isSpaceMember } = useIsSpaceMember({ spaceId: spaceData.id });
  const isMobile = useMediaQuery(450);
  const isTablet = useMediaQuery(1350);
  const showShortText = useMediaQuery(650);
  const createPostSectionRef = useRef<HTMLDivElement | null>(null);
  const shouldShowCreatePostFAB = useShouldShowCreatePostFAB(
    true,
    createPostSectionRef,
  );

  const handleOpenCreatePostModal = useCallback(() => {
    if (isSpaceMember) {
      dispatch(
        openCreatePostModal({
          composer_location: postLocation,
          spaceId: Number(spaceData.id),
        }),
      );
    } else {
      openJoinThisSpaceModal(spaceData);
    }
  }, [isSpaceMember, dispatch, postLocation, spaceData]);

  useOpenCreatePostModalFromParams(handleOpenCreatePostModal);

  if (!user) return <></>;

  return (
    <>
      <Box className={styles.createPost} ref={createPostSectionRef}>
        <UserAvatar user={user} hideBadge disableShadow hideBorder />

        <PostTextField
          createPostPrompt={
            showShortText
              ? "Ask a question..."
              : "Ask the members a question..."
          }
          onClick={handleOpenCreatePostModal}
          isMobile={isMobile}
          isTablet={isTablet}
          hideActionButtons={false}
        />

        <PostBtn onClick={handleOpenCreatePostModal} isMobile={isMobile} />
      </Box>

      <CreatePostModalInstance />
      {shouldShowCreatePostFAB && isSpaceMember && (
        <CreatePostFAB containerClassName={styles.fabContainer} />
      )}
    </>
  );
};

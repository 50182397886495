import { Box, Button } from "@braintrust/braintrust-ui-components";
import { useIsEmployerImpersonated } from "@js/apps/jobs/hooks";
import { SubscribeSyncErrors } from "@js/forms/components";
import { useGoBackHistory } from "@js/hooks";

import { getRequiredFieldsWhenPostingForAClient } from "../helpers";
import { PostJobForClientModalInstance } from "../modal";

export const SubmitAndCancelButtons = () => {
  const goBack = useGoBackHistory();
  const isEmployerImpersonated = useIsEmployerImpersonated();

  return (
    <Box display="flex" gap={2}>
      <SubscribeSyncErrors
        fields={getRequiredFieldsWhenPostingForAClient(isEmployerImpersonated)}
      >
        {({ isError }) => {
          return (
            <Button
              size="medium"
              variant="positive"
              shape="squared"
              disabled={isError}
              onClick={PostJobForClientModalInstance.close}
            >
              Create new job
            </Button>
          );
        }}
      </SubscribeSyncErrors>
      <Button
        onClick={() => {
          goBack("/jobs");
        }}
        variant="transparent"
        shape="squared"
        size="medium"
      >
        Cancel
      </Button>
    </Box>
  );
};

import { useCallback } from "react";
import cs from "classnames";

import { Button, Tooltip } from "@braintrust/braintrust-ui-components";
import { BookmarkIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks/use-user";
import {
  useBookmarkPostMutation,
  useUnbookmarkPostMutation,
} from "@js/apps/give-and-get-help/api";
import { usePostContext } from "@js/apps/give-and-get-help/context";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

import { addBookmark, savePostButtonClicked } from "../../actions";

import styles from "./style.module.scss";

type BookmarkButtonProps = {
  savedPostsCount: number;
  savedPostId?: number | null;
  postId: number;
  postData: Optional<Pick<IPost, "id" | "slot_position">, "slot_position">;
};

export const BookmarkButton = ({
  savedPostsCount,
  savedPostId,
  postId,
  postData,
}: BookmarkButtonProps) => {
  const { isBookmarked, isDisabled, toggleBookmark } = useBookmarkButton({
    postData,
    savedPostId,
    postId,
  });

  const variant = !isBookmarked ? "white-border-beige" : "green";

  const className = cs(styles.bookmark, {
    [styles.bookmarkWithTooltip]: !isBookmarked,
  });

  const startIcon = (
    <BookmarkIcon
      style={{ width: "20px", height: "20px" }}
      className={cs(styles.bookmarkIconOutline, {
        [styles.bookmarkIconActive]: isBookmarked,
      })}
    />
  );

  return (
    <Tooltip title="Save for later" disabled={isBookmarked || isDisabled}>
      <Button
        className={className}
        disabled={isDisabled}
        disabledType="opacity"
        aria-label="bookmark-post"
        startIcon={startIcon}
        variant={variant}
        size="x-small"
        shape="squared"
        onClick={toggleBookmark}
      >
        {savedPostsCount}
      </Button>
    </Tooltip>
  );
};

type UseBookmarkButton = (
  config: Pick<BookmarkButtonProps, "postData" | "postId" | "savedPostId">,
) => {
  isBookmarked: boolean;
  isDisabled: boolean;
  toggleBookmark: () => void;
};

const useBookmarkButton: UseBookmarkButton = ({
  postData,
  savedPostId,
  postId,
}) => {
  const isBookmarked = !!savedPostId;
  const user = useUser();
  const postLocation = usePostLocationContext();
  const [bookmarkMutationTrigger, { isLoading: bookmarking }] =
    useBookmarkPostMutation();
  const [unbookmarkMutationTrigger, { isLoading: unbookmarking }] =
    useUnbookmarkPostMutation();
  const dispatch = useAppDispatch();
  const { isPublic } = usePostContext();

  const isDisabled = bookmarking || unbookmarking || !user?.is_verified;

  const bookmarkPost = useCallback(
    (id: number) => {
      bookmarkMutationTrigger(id)
        .unwrap()
        .then(() => {
          dispatch(
            savePostButtonClicked({ ...postData, post_location: postLocation }),
          );
        })
        .catch(() => {
          Snackbar.error("Something went wrong, please try again.");
        });
    },
    [bookmarkMutationTrigger, dispatch, postData, postLocation],
  );

  const unbookmarkPost = useCallback(
    (savedId: number | null) => {
      unbookmarkMutationTrigger({ savedPostId: savedId, postId })
        .unwrap()
        .catch(() => {
          Snackbar.error("Something went wrong, please try again.");
        });
    },
    [unbookmarkMutationTrigger, postId],
  );

  const toggleBookmark = useCallback(() => {
    if (isBookmarked) {
      unbookmarkPost(savedPostId);
    } else {
      bookmarkPost(postId);
      dispatch(addBookmark());
    }
  }, [
    bookmarkPost,
    unbookmarkPost,
    postId,
    savedPostId,
    isBookmarked,
    dispatch,
  ]);

  const publicOnToggleBookmark = useCallback(() => {
    openSignUpModal();
  }, []);

  return {
    isBookmarked,
    isDisabled,
    toggleBookmark: isPublic ? publicOnToggleBookmark : toggleBookmark,
  };
};

import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

import style from "./style.module.scss";

export type CreateClientSuccessModalData = {
  first_name: string;
  last_name: string;
  email: string;
};

export const openConfirmationClientInviteModal = (
  data: CreateClientSuccessModalData,
) => {
  ModalInstance.open({
    children: <ConfirmationClientInviteModalContent {...data} />,
    closeButton: true,
    closeButtonProps: {
      size: "medium",
    },
    className: style.confirmationClientInviteModal,
    disableEscapeKeyDown: false,
    keepOnBackdropClick: true,
    padding: false,
  });
};

const ConfirmationClientInviteModalContent = ({
  first_name,
  last_name,
  email,
}: CreateClientSuccessModalData) => {
  const full_name = `${first_name} ${last_name}`;

  return (
    <div className={style.confirmationClientInviteModalTopSection}>
      <Typography component="p" variant="title" size="large">
        🎉
      </Typography>
      <Typography
        component="p"
        variant="title"
        size="small"
        fontWeight={400}
        mb={2}
        mt={0.5}
      >
        Client invite sent!
      </Typography>
      <Box maxWidth={600} mb={5}>
        An email was sent to{" "}
        <Typography component="span" fontWeight={500}>
          {full_name}
        </Typography>{" "}
        at{" "}
        <Typography component="span" fontWeight={500}>
          {email}
        </Typography>{" "}
        that contains a link for them to activate their account.
      </Box>
    </div>
  );
};

import { useMemo } from "react";

import { Box, FormHelperText } from "@braintrust/braintrust-ui-components";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";

import type { WorkingTimezoneTalents } from "../../config";

import type { HoursOverlapProps } from "./hours-overlap-field";
import { HoursOverlapField } from "./hours-overlap-field";
import type { TimezoneSelectProps } from "./timezone-select-field";
import { TimezoneSelectField } from "./timezone-select-field";

export const fieldNames: Array<keyof WorkingTimezoneTalents> = [
  "timezone",
  "hours_overlap",
];

type WorkingTimezoneTalentsFieldsProps = {
  submit: () => void;
  formError?: string;
} & HoursOverlapProps &
  TimezoneSelectProps;

export const WorkingTimezoneTalentsFields = ({
  timezone,
  hours_overlap,
  submit,
}: WorkingTimezoneTalentsFieldsProps) => {
  const handleReset = () => {
    timezone.input.onChange(null);
    hours_overlap.input.onChange(null);
  };

  const handleApply = () => {
    submit();
  };

  const formError = useMemo(
    () => getFormError([timezone, hours_overlap]),
    [timezone, hours_overlap],
  );

  return (
    <JobPopoverFilterButton.Content
      onApply={handleApply}
      onReset={handleReset}
      sx={{
        "& > .MuiBox-root": {
          overflow: "visible",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <TimezoneSelectField timezone={timezone} />
        <HoursOverlapField hours_overlap={hours_overlap} />
        {formError && <FormHelperText error>{formError}</FormHelperText>}
      </Box>
    </JobPopoverFilterButton.Content>
  );
};

const getFormError = (
  fields: Array<{ meta: { touched: boolean; error?: string } }>,
) => {
  return fields.find((field) => field.meta.touched && field.meta.error)?.meta
    .error;
};

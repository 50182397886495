import { useEffect, useMemo } from "react";

import { useGetHashtagByIdQuery } from "@js/apps/give-and-get-help/api";
import { CreateOrEditPostModalContent } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal";
import { CreatePostForm } from "@js/apps/give-and-get-help/form";
import {
  useCreateOrEditPost,
  useNewPostModalVisibleURL,
} from "@js/apps/give-and-get-help/hooks/create-or-edit-post";
import { useSelectedHashtag } from "@js/apps/give-and-get-help/hooks/use-selected-hashtag";

type CreatePostModalContentProps = { spaceId?: number };

export const CreatePostModalContent = ({
  spaceId,
}: CreatePostModalContentProps) => {
  const { selectedHashtagId } = useSelectedHashtag();
  const { handleSubmit, onSubmitSuccess } = useCreateOrEditPost({
    space: spaceId,
  });
  const { addModalVisibleUrlParam, removeModalVisibleUrlParam } =
    useNewPostModalVisibleURL();
  const {
    data: queryParamHashtagData,
    isFetching: isFetchingHashtagFromQueryParam,
    isSuccess,
  } = useGetHashtagByIdQuery(selectedHashtagId as number, {
    skip: !selectedHashtagId,
  });

  const initialValues = useMemo(
    () => ({
      links_metadata_from_text: [],
      links_from_text: [],
      text: isSuccess ? `#${queryParamHashtagData.name}` : undefined,
      post_hashtags: isSuccess ? [queryParamHashtagData] : undefined,
    }),
    [isSuccess, queryParamHashtagData],
  );

  useEffect(() => {
    addModalVisibleUrlParam();
  }, [addModalVisibleUrlParam]);

  useEffect(() => {
    return () => {
      removeModalVisibleUrlParam();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingHashtagFromQueryParam) {
    return <></>;
  }

  return (
    <CreatePostForm
      onSubmit={handleSubmit}
      onSubmitSuccess={onSubmitSuccess}
      initialValues={initialValues}
    >
      <CreateOrEditPostModalContent />
    </CreatePostForm>
  );
};

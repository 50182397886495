import type { BoxProps } from "@braintrust/braintrust-ui-components";
import { Box } from "@braintrust/braintrust-ui-components";

export const MakeOfferMessageBox = (props: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        maxWidth: "576px",
        width: "90vw",
        borderRadius: "20px",
        p: 3,
        ...props.sx,
      }}
    />
  );
};

import React from "react";

import { Box, Loader } from "@braintrust/braintrust-ui-components";

export const ReferralsStatsListLoader = () => {
  return (
    <Box sx={{ position: "relative", minHeight: "6.75rem", mt: "12px" }}>
      <Loader centered />
    </Box>
  );
};

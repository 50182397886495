import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";

type BadgeProps = {
  backgroundColor?: string;
  color?: string;
};

export const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({
  backgroundColor,
  color,
  children,
}) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        borderRadius: "20px",
        padding: "0 8px",
        backgroundColor,
      }}
    >
      <Typography
        variant="label"
        component="span"
        sx={{ color }}
        style={{ fontSize: 12, lineHeight: "24px" }}
      >
        {children}
      </Typography>
    </Box>
  );
};

import { Field, type InjectedFormProps, reduxForm } from "redux-form";

import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { TwoFactorAuthForPhoneVerification } from "@js/apps/common/components/two-factor-auth";
import { useUser } from "@js/apps/common/hooks";
import { Form } from "@js/forms/components/form";
import { TextField } from "@js/forms/fields";
import { maxLength } from "@js/forms/utils";
import { required } from "@js/forms/validators";

import styles from "../styles.module.scss";

type UpdatePhoneNumberVerificationFormValues = {
  password: string;
  verification_code: string;
};

type PromptFormProps = {
  onSubmitVerification: (
    values: UpdatePhoneNumberVerificationFormValues,
  ) => Promise<void> | undefined;
  onCancel: () => void;
  phoneNumber: string;
  is_otp_enabled?: boolean;
};

type InjectedProps = InjectedFormProps<
  UpdatePhoneNumberVerificationFormValues,
  PromptFormProps
>;

const UpdatePhoneNumberVerification = (
  props: InjectedProps & PromptFormProps,
) => {
  const {
    valid,
    submitting,
    handleSubmit,
    onSubmitVerification,
    onCancel,
    phoneNumber,
    is_otp_enabled,
  } = props;

  const user = useUser();

  return (
    <Form
      className={styles.phoneVerificationForm}
      onSubmit={submitting ? null : handleSubmit(onSubmitVerification)}
      error={null}
    >
      {user?.phone_number && (
        <TwoFactorAuthForPhoneVerification isOtpEnabled={is_otp_enabled} />
      )}

      <Typography component="h4" size="large" fontWeight={400} mt={2}>
        Mobile Number Verification
      </Typography>
      <Typography component="p" mt={1}>
        A code has been sent to {phoneNumber}
      </Typography>
      <Field
        normalize={(value) => maxLength(value, 6)}
        validate={[required]}
        id="verification_code"
        name="verification_code"
        component={TextField}
        variant="standard"
        className="text-field number-enhanced"
        placeholder="000000"
        floatingLabel={false}
      />

      <Box className="buttons right" sx={{ mt: 3 }}>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting || !valid}>
          Okay
        </Button>
      </Box>
    </Form>
  );
};

export const UpdatePhoneNumberVerificationForm = reduxForm<
  UpdatePhoneNumberVerificationFormValues,
  PromptFormProps
>({
  form: "update-phone-number",
  enableReinitialize: true,
})(UpdatePhoneNumberVerification);

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { ModalConfirm } from "@js/components/modal";

type ConfirmRevokeOfferModalContentProps = {
  handleRemoveOffer: () => void;
};

export const ConfirmRevokeOfferModalContent = ({
  handleRemoveOffer,
}: ConfirmRevokeOfferModalContentProps) => {
  return (
    <ModalConfirm
      confirmButtonVariant="destructive"
      confirmText="Revoke offer"
      buttonsSquareShape
      onConfirm={handleRemoveOffer}
    >
      <Typography component="p" size="large" fontWeight={500} mb={3}>
        Revoke your offer
      </Typography>
      <Box>
        <Typography component="p" variant="title" fontWeight={400} mb={1}>
          Are you sure you want to revoke your offer?
        </Typography>
        <Typography component="p" mb={3}>
          Revoking your offer will remove your offer for 1:1 help. It will not
          delete your original reply or any reactions to the post. You can’t
          undo this.
        </Typography>
      </Box>
    </ModalConfirm>
  );
};

import { IconButton } from "@braintrust/braintrust-ui-components";
import type { GridColumnHeaderSortIconProps } from "@braintrust/braintrust-ui-components/components/DataGrid";
import { KeyboardArrowUpIcon } from "@braintrust/braintrust-ui-components/Icons";

export const EmptyColumnResizeIcon = () => {
  return <></>;
};

type ColumnHeaderSortIconProps = GridColumnHeaderSortIconProps & {
  activeSortedColor?: string | null;
};

export const ColumnHeaderSortIcon = ({
  activeSortedColor = "var(--medium-violet)",
  direction,
}: ColumnHeaderSortIconProps) => {
  const isSorted = !!direction;

  return (
    <IconButton
      variant="transparent"
      aria-label="Sort button"
      sx={{ p: "0!important", ml: "3px" }}
      size="medium"
      tabIndex={-1}
      data-sorting={String(isSorted)}
    >
      <KeyboardArrowUpIcon
        sx={{
          color: isSorted ? "var(--black)" : "var(--grey-2)",
          fontSize: "24px",
          borderRadius: "50%",
          backgroundColor: isSorted ? activeSortedColor : undefined,
          transform: direction === "desc" ? "rotate(180deg)" : undefined,
        }}
      />
    </IconButton>
  );
};

import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { Modal } from "@js/components/modal";
import type { JobInterviewTranscript } from "@js/types/jobs";

import styles from "./styles.module.scss";

const BID_INTERVIEW_VIDEO = "bid-interview-video";

export const BidInterviewVideoModal = Modal(BID_INTERVIEW_VIDEO, {
  closeButton: true,
  keepOnBackdropClick: true,
  padding: false,
  className: styles.bidInterviewModal,
});

export const BidInterviewModalContent = ({
  transcript,
  title,
  subtitle,
  videoUrl,
}: {
  transcript: JobInterviewTranscript[];
  title: string;
  subtitle: string;
  videoUrl: string | null;
}) => {
  const [playerRef, setPlayerRef] = useState<ReactPlayer | null>(null);

  useEffect(() => {
    if (playerRef) {
      playerRef.seekTo(0);
    }
  }, []);

  return (
    <BidInterviewVideoModal>
      <Box className={styles.modalContent}>
        <Box className={styles.modalContentLeft}>
          <Box mb={2}>
            <Typography component="p">{subtitle}</Typography>
            <Typography component="h2" variant="title" size="small">
              {title}
            </Typography>
          </Box>
          {videoUrl ? (
            <Box className={styles.video} sx={{ position: "relative" }}>
              <ReactPlayer
                ref={setPlayerRef}
                url={videoUrl}
                controls
                width="100%"
                height="100%"
                style={{ position: "absolute", top: 0, left: 0 }}
              />
            </Box>
          ) : (
            <Typography
              component="h3"
              color="negative"
              size="small"
              marginTop={10}
              textAlign={"center"}
            >
              No video found
            </Typography>
          )}
        </Box>
        <Box className={styles.modalContentRight}>
          <Box
            sx={{
              marginTop: "-10px",
            }}
          >
            <Typography
              sx={{
                color: "var(--black)",
              }}
              component="h3"
              variant="label"
              size="medium"
            >
              Transcript
            </Typography>
          </Box>
          <Box mt={3}>
            <Box className={styles.scrollableContent}>
              {transcript.map((t, index) => {
                const sameAsPrev = transcript[index - 1]?.name === t.name;
                const sameAsNext = transcript[index + 1]?.name === t.name;

                const shouldDisplayName = index === 0 || !sameAsPrev;

                return (
                  <Box key={t.text} mb={sameAsNext ? 0 : 3}>
                    {shouldDisplayName && (
                      <Typography component="p" variant="label" size="small">
                        {t.name}
                      </Typography>
                    )}
                    <Typography component="p" variant="paragraph" size="small">
                      {t.text}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </BidInterviewVideoModal>
  );
};

import {
  Loader,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { Modal } from "@js/components/modal";

import styles from "./styles.module.scss";

const CreatingWalletModalContent = () => (
  <Stack
    direction="column"
    gap={3}
    alignItems="center"
    p={2}
    textAlign="center"
  >
    <div className={styles.creatingWalletModalLoaderWrapper}>
      <Loader size={78} color="inherit" />
    </div>
    <Typography component="h2" variant="label" size="large">
      Creating wallet
    </Typography>
    <Typography component="h3" variant="label" size="medium">
      This could take several minutes. We will notify you once your wallet has
      been created.
    </Typography>
  </Stack>
);

const CREATING_WALLET_MODAL_ID = "creating-wallet-modal";

const CreatingWalletModalInstance = Modal(CREATING_WALLET_MODAL_ID, {
  keepOnBackdropClick: false,
  className: styles.creatingWalletModal,
  padding: false,
  children: <CreatingWalletModalContent />,
});

type OpenCreatingWalletModalArgs = {
  onOpen: VoidFunction;
  onClose: VoidFunction;
};

const openCreatingWalletModal = ({
  onOpen,
  onClose,
}: OpenCreatingWalletModalArgs) => {
  CreatingWalletModalInstance.open({ onOpen, onClose });
};

const closeCreatingWalletModal = () => {
  CreatingWalletModalInstance.close();
};

export {
  closeCreatingWalletModal,
  CreatingWalletModalInstance,
  openCreatingWalletModal,
};

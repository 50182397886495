import type { ReactNode } from "react";
import React from "react";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import {
  Box,
  List,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  CheckIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { EmployerBidListBid, MatchSummary } from "@js/types/jobs";
import { MATCH_SUMMARY_TYPE } from "@js/types/jobs";

export const MatchSummaryList = ({
  matchSummary,
  sentenceProps,
}: {
  matchSummary: EmployerBidListBid["match_summary"];
  sentenceProps?: SentenceProps;
}) => {
  if (!matchSummary.length) {
    return <SummaryNoResults />;
  }

  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: 0,
        overflowWrap: "anywhere",
      }}
    >
      {matchSummary.map((summary) => {
        return (
          <Summary
            key={summary.sentence}
            summary={summary}
            sentenceProps={sentenceProps}
          />
        );
      })}
    </List>
  );
};

export const MatchSummaryListContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box sx={{ mb: 4, flex: 1, overflow: "auto", maxHeight: "70vh" }}>
      {children}
    </Box>
  );
};

const commonIconStyles = { marginTop: "2px", fontSize: "20px" };

type SentenceProps = Partial<Pick<TypographyProps, "size" | "variant">>;
type SummaryProps = { summary: MatchSummary; sentenceProps?: SentenceProps };

const Summary = ({ summary, sentenceProps }: SummaryProps) => {
  return (
    <Stack component="li" direction="row" sx={{ gap: 1 }}>
      {summary.summary_type === MATCH_SUMMARY_TYPE.POSITIVE ? (
        <CheckIcon sx={{ color: "var(--positive-2)", ...commonIconStyles }} />
      ) : (
        <CloseIcon sx={{ color: "var(--negative-1)", ...commonIconStyles }} />
      )}
      <Typography
        component="p"
        variant="paragraph"
        size="small"
        {...sentenceProps}
      >
        {summary.sentence}
      </Typography>
    </Stack>
  );
};

const SummaryNoResults = () => {
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: "100%",
        minHeight: "5rem",
      }}
    >
      <Typography
        component="span"
        variant="paragraph"
        size="medium"
        color="grey-3"
      >
        No results
      </Typography>
    </Box>
  );
};

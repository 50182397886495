import type { TypedWrappedFieldProps } from "redux-form";

import { Divider, Typography } from "@braintrust/braintrust-ui-components";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields";
import { PaymentMethodsActionBar } from "@js/apps/payments/components/payment-methods-action-bar";
import { PaymentMethodsContainer } from "@js/apps/payments/containers/payment-methods";
import { shouldDisplayError } from "@js/forms/utils";
import type { PaymentMethod } from "@js/types/payments";

import { PaymentMethodsListing } from "../../components/payment-method-listing";

type BillingDetailsSectionProps = TypedWrappedFieldProps<PaymentMethod>;

export const BillingDetailsSection = ({
  input,
  meta,
}: BillingDetailsSectionProps) => {
  const displayError = shouldDisplayError({ input, meta });
  const errorMessage = meta.error as string;

  return (
    <FieldSectionPanelWithImportance
      title="🏦 Billing details"
      fieldImportance="required"
      isError={displayError}
    >
      <Divider color="beige" sx={{ mt: "16px", mb: "24px" }} />

      <PaymentMethodsContainer
        inOfferFlow={true}
        render={(props) => {
          const {
            creatingPaymentMethod,
            addPaymentMethod,
            fetchingPaymentMethods,
            paymentMethods,
            canAddPaymentMethod,
            cannotAddPaymentMethodReason,
            deletePaymentMethod,
            onPaymentMethodDelete,
          } = props;

          const title =
            paymentMethods.length > 0
              ? "Please choose a payment method"
              : "Add a bank account or credit card to pay invoices on Braintrust.";

          return (
            <>
              <PaymentMethodsActionBar
                canAddPaymentMethod={canAddPaymentMethod}
                cannotAddPaymentMethodReason={cannotAddPaymentMethodReason}
                creatingPaymentMethod={creatingPaymentMethod}
                title={title}
                buttonLabel="Add payment method"
                buttonProps={{
                  variant: "white-violet",
                  shape: "squared",
                  onClick: addPaymentMethod,
                  size: "x-small",
                  sx: { marginLeft: "auto" },
                }}
                titleProps={{
                  component: "p",
                  variant: "paragraph",
                  size: "medium",
                }}
                containerProps={{ alignItems: "center", gap: "16px" }}
              />

              {displayError && (
                <Typography mb={2} component="p" variant="paragraph" error>
                  {errorMessage}
                </Typography>
              )}

              <PaymentMethodsListing
                onChange={input.onChange}
                meta={meta}
                paymentMethods={paymentMethods}
                fetchingPaymentMethods={fetchingPaymentMethods}
                onDelete={(paymentMethod) =>
                  onPaymentMethodDelete(deletePaymentMethod, paymentMethod)
                }
              />
            </>
          );
        }}
      />
    </FieldSectionPanelWithImportance>
  );
};

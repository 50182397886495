import React from "react";
import { useSearchParams } from "react-router-dom";
import type { WrappedFieldsProps } from "redux-form";
import { Fields } from "redux-form";
import cs from "classnames";

import { Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { ExperienceIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { YearsOfExperienceRange } from "@js/apps/common/components/filters/config";
import { YearsOfExperienceForm } from "@js/apps/common/components/filters/forms/years-of-experience-form";
import type { FilterLocation } from "@js/apps/filters/types";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components/job-popover-filter-button";
import { getFiltersStorageKey } from "@js/apps/jobs/apps/listing/utils";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import { LocalStorage } from "@js/services";
import { PossibleFilters } from "@js/types/common";

import styles from "./style.module.scss";

type YearsOfExperienceOption = {
  label: string;
  value: YearsOfExperienceRange;
};

const yearsOfExperienceOptions = [
  {
    label: "1-3 yrs",
    value: "1-3",
  },
  {
    label: "3-5 yrs",
    value: "3-5",
  },
  {
    label: "5-10 yrs",
    value: "5-10",
  },
  {
    label: "10+ yrs",
    value: "10+",
  },
] as YearsOfExperienceOption[];

type YearsOfExperienceFilterProps = {
  location: FilterLocation;
};

export const YearsOfExperienceFilter = ({
  location,
}: YearsOfExperienceFilterProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [params] = useSearchParams();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.EXPERIENCE,
      filter_value: values,
    });
  };

  const experience = params.get("experience");
  const appliedExperienceRanges = experience?.split(
    ",",
  ) as YearsOfExperienceRange[];

  const label = getYearsOfExperienceLabel(appliedExperienceRanges);

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      label={label}
      startIcon={<ExperienceIcon />}
      isActive={!!appliedExperienceRanges?.length}
      popoverContent={
        <YearsOfExperiencePopoverForm
          location={location}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
          onSubmitSideAction={handleSubmitSideAction}
        />
      }
    />
  );
};

type YearsOfExperiencePopoverFormProps = {
  onSubmitSuccess: () => void;
  location: FilterLocation;
  onSubmitSideAction: (values: Record<string, unknown>) => void;
};

const YearsOfExperiencePopoverForm = ({
  onSubmitSuccess,
  location,
  onSubmitSideAction,
}: YearsOfExperiencePopoverFormProps) => {
  return (
    <YearsOfExperienceForm
      onSubmitSuccess={onSubmitSuccess}
      form="listing-filters__experience"
      onSubmitSideAction={onSubmitSideAction}
    >
      {({ submit }) => {
        return (
          <Fields
            submit={submit}
            component={YearsOfExperienceField}
            names={["experience"]}
            location={location}
          />
        );
      }}
    </YearsOfExperienceForm>
  );
};

type YearsOfExperienceFieldProps = WrappedFieldsProps & {
  submit: () => void;
  location: FilterLocation;
};

const YearsOfExperienceField = ({
  submit,
  experience,
  location,
}: YearsOfExperienceFieldProps) => {
  const isMobile = useMediaQuery("sm");

  const handleReset = () => {
    experience.input.onChange(null);
  };

  const handleApply = () => {
    submit();

    LocalStorage.removeItem(getFiltersStorageKey(location));
  };

  return (
    <div>
      <JobPopoverFilterButton.Content
        onApply={handleApply}
        onReset={handleReset}
        showOverflow
      >
        <Typography component="p" variant="paragraph" size="medium" my={2}>
          Select the years of experience
        </Typography>
        <ButtonSelectField
          spacing={isMobile ? 1 : 2}
          className={cs(styles.button, "button-shadow")}
          labelledBy="category-label"
          fontWeight={500}
          options={yearsOfExperienceOptions}
          multiple
          canSingleValueBeEmpty
          variant="white-violet"
          shape="squared"
          input={experience.input}
          meta={experience.meta}
        />
      </JobPopoverFilterButton.Content>
    </div>
  );
};

export const DEFAULT_LABEL = "Years of experience";

type RangeType = {
  value: YearsOfExperienceRange;
  min: number;
  max: number;
  isPlusValue: boolean;
};

export const getYearsOfExperienceLabel = (
  appliedExperienceRanges: YearsOfExperienceRange[],
) => {
  if (!appliedExperienceRanges?.length) {
    return DEFAULT_LABEL;
  }

  const rangesWithMinMax = appliedExperienceRanges.map(
    (value: YearsOfExperienceRange): RangeType => {
      const [_min, _max] = value.split(/-|\+/g);
      const min = Number(_min);
      const max = Number(_max);

      const isPlusValue = value.includes("+");

      return {
        value,
        min,
        max: isPlusValue ? min : max,
        isPlusValue,
      };
    },
  );

  rangesWithMinMax.sort((a, b) => {
    const aMin = a.min;
    const bMin = b.min;

    if (aMin < bMin) return -1;
    if (aMin > bMin) return 1;
    return 0;
  });

  const accumulatedRanges = rangesWithMinMax.reduce(
    (accumulated: RangeType[], currentValue, index, arr) => {
      const previousValue = arr[index - 1];

      if (!previousValue) {
        return [...accumulated, currentValue];
      }

      const lastAccumulated = accumulated[accumulated.length - 1];

      if (currentValue.min === lastAccumulated.max) {
        lastAccumulated.max = currentValue.max;
        lastAccumulated.isPlusValue =
          currentValue.isPlusValue || lastAccumulated.isPlusValue;
      } else {
        return [...accumulated, currentValue];
      }

      return accumulated;
    },
    [],
  );

  const accumulatedRangesStrings = accumulatedRanges.map(
    ({ min, max, isPlusValue }) => {
      const value = min === max ? min : `${min}-${max}`;
      const plus = isPlusValue ? "+" : "";

      return `${value}${plus}`;
    },
  );

  return accumulatedRangesStrings.join(", ") + " yrs";
};

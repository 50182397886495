import type { ChangeEvent, ReactNode } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@braintrust/braintrust-ui-components";

/**
 * Example usage:
 *
 * ```
 * <Field name="sex" component={RadioField} options={[{ value: "male", label: "Male" }, { value: "female", label: "Female" }]}>
 *     <Radio value="male" label="male" />
 *     <Radio value="female" label="female" />
 *  </Field>
 * ```
 */

type RadioFieldProps = TypedWrappedFieldProps<string> & {
  label?: ReactNode;
  options: Array<{ value: string | number | boolean; label: string }>;
  color?: "primary" | "secondary";
  row?: boolean;
  id?: string;
};

export const RadioField = ({
  input,
  meta: { error, touched },
  color,
  label,
  options,
  ...rest
}: RadioFieldProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    input.onChange(event.target.value);
  };
  return (
    <FormControl>
      {!!label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup {...input} onChange={onChange} {...rest}>
        {options.map(({ value, label: displayLabel }) => {
          return (
            <FormControlLabel
              key={String(value)}
              value={value}
              label={displayLabel || ""}
              control={<Radio color={color || "secondary"} />}
            />
          );
        })}
      </RadioGroup>
      {error && touched && (
        <FormHelperText error variant="filled">
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

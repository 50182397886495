import React from "react";

import type { BoxProps } from "@braintrust/braintrust-ui-components";
import {
  Box,
  IconButton,
  RoundedBox,
  Tooltip,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { InfoIcon } from "@braintrust/braintrust-ui-components/Icons";
import { BookmarkJobButton } from "@js/apps/bookmarks/components";
import { useAccountType } from "@js/apps/common/hooks";
import { JobBudget, ShareJobButton } from "@js/apps/jobs/components";
import { jobBudgetTooltipTitle } from "@js/apps/jobs/components/job-item/utils";
import { JobSectionTitle } from "@js/apps/jobs/components/job-section-title";
import { JobTokenReward } from "@js/apps/jobs/components/job-token-reward";
import type { FreelancerBid, Job } from "@js/types/jobs";

type RateProps = BoxProps & {
  job: Job;
  bid: FreelancerBid | undefined;
  isCompleted?: boolean;
};

export const Rate = ({ job, bid, isCompleted, ...props }: RateProps) => {
  const displayReferralSection =
    !isCompleted && job.token_reward && job.job_type !== ENUMS.JobType.GRANT;

  return (
    <RoundedBox height="100%" {...props}>
      <Box
        display={{ xs: "flex", md: "block" }}
        alignItems={"start"}
        justifyContent={{ xs: "space-between" }}
        flexWrap="wrap"
        gap={2}
        mb={3}
      >
        <RateSectionModule job={job} />
        <JobDetailsRateActions job={job} isCompleted={isCompleted} />
      </Box>
      {displayReferralSection && (
        <Box sx={{ mt: "10px" }}>
          <JobSectionTitle>BTRST Referral</JobSectionTitle>
          <JobTokenReward tokenReward={job?.token_reward} />
        </Box>
      )}
    </RoundedBox>
  );
};
export const RateSectionModule = ({ job }: { job: Job }) => {
  return (
    <div>
      <Box>
        <Box>
          <JobSectionTitle>Rate</JobSectionTitle>
        </Box>
        <Box>
          {job && (
            <Typography
              component="div"
              fontWeight={400}
              variant="title"
              size="small"
            >
              <Tooltip
                title={jobBudgetTooltipTitle(job.payment_type)}
                arrow
                placement="top"
                enterDelay={500}
                enterNextDelay={300}
              >
                <span>
                  <JobBudget removeDecimal job={job} />
                </span>
              </Tooltip>
            </Typography>
          )}
        </Box>
      </Box>
      {job?.estimated_value && (
        <Typography
          component="p"
          variant="paragraph"
          color="secondary"
          size="small"
          fontWeight={500}
        >
          Est. Value {job?.estimated_value}
          <Tooltip title="Estimated total value of project based on rate, commitment and duration.">
            <span>
              <IconButton
                variant="transparent"
                style={{
                  pointerEvents: "none",
                  padding: "5px",
                  color: "var(--black-80)",
                }}
                aria-label="Info"
              >
                <InfoIcon sx={{ fontSize: "14px", marginBottom: "2px" }} />
              </IconButton>
            </span>
          </Tooltip>
        </Typography>
      )}
    </div>
  );
};

type JobDetailsRateActionsProps = {
  job: Job;
  isCompleted?: boolean;
};

const JobDetailsRateActions = ({
  job,
  isCompleted,
}: JobDetailsRateActionsProps) => {
  const { isFreelancer } = useAccountType();

  if (isCompleted) return null;

  return (
    <Box display={{ xs: "flex", sm: "none" }} flexDirection="column" gap={2}>
      <Box display="flex">
        <ShareJobButton sx={{ mr: 1.5 }} job={job} />
        {isFreelancer && <BookmarkJobButton job={job} />}
      </Box>
    </Box>
  );
};

import { SwipeableDrawer } from "@braintrust/braintrust-ui-components";
import type { AddTeamMemberDrawerContentProps } from "@js/apps/jobs/apps/create-job/components/add-team-member-drawer/add-team-member-drawer-content";
import { AddTeamMemberDrawerContent } from "@js/apps/jobs/apps/create-job/components/add-team-member-drawer/add-team-member-drawer-content";
import { AddTeamMembersButton } from "@js/apps/jobs/components";
import type { TypedWrappedFieldProps } from "@js/forms/utils";
import type {
  JobSubscriberData,
  JobSubscriberInvitationData,
} from "@js/types/jobs";

import { useAddTeamMemberDrawer } from "./use-add-team-member-drawer";

export type AddTeamMemberDrawerProps = {
  isOpen: boolean;
  onOpen: () => void;
} & Pick<
  AddTeamMemberDrawerContentProps,
  | "changeSubscribers"
  | "jobOwnerId"
  | "jobSubscriberInvitationsValue"
  | "jobSubscribersValue"
  | "jobSubscribers"
  | "handleClose"
  | "errorMessage"
>;

const AddTeamMemberDrawer = ({
  isOpen,
  handleClose,
  onOpen,
  ...addTeamMemberDrawerContentProps
}: AddTeamMemberDrawerProps) => {
  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={handleClose}
      onOpen={onOpen}
      anchor="right"
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        sx: {
          width: { xs: "min(100%, 600px)", md: "90vw", lg: "72vw" },
          maxWidth: "750px",
        },
      }}
    >
      <AddTeamMemberDrawerContent
        handleClose={handleClose}
        {...addTeamMemberDrawerContentProps}
      />
    </SwipeableDrawer>
  );
};

type AddTeamMemberDrawerTriggerProps = {
  jobOwnerId: TypedWrappedFieldProps<number>;
  job_subscribers: TypedWrappedFieldProps<JobSubscriberData[] | undefined>;
  job_subscriber_invitations: TypedWrappedFieldProps<
    JobSubscriberInvitationData[] | undefined
  >;
};

export const AddTeamMemberDrawerTrigger = ({
  job_subscribers,
  job_subscriber_invitations,
  jobOwnerId,
}: AddTeamMemberDrawerTriggerProps) => {
  const {
    isAddingTeamMembersEnabled,
    isOpen,
    handleOpen,
    handleClose,
    openDrawer,
  } = useAddTeamMemberDrawer();

  if (!isAddingTeamMembersEnabled) {
    return <></>;
  }

  const handleChangeSubscribers = ({
    jobSubscribersData,
    jobSubscriberInvitationsData,
  }: {
    jobSubscribersData: JobSubscriberData[];
    jobSubscriberInvitationsData: JobSubscriberInvitationData[];
  }) => {
    job_subscribers.input.onChange(jobSubscribersData);
    job_subscriber_invitations.input.onChange(jobSubscriberInvitationsData);
  };

  return (
    <>
      <AddTeamMembersButton
        onClick={openDrawer}
        jobSubscribers={job_subscribers.input.value ?? []}
      />
      <AddTeamMemberDrawer
        isOpen={isOpen}
        handleClose={handleClose}
        onOpen={handleOpen}
        jobOwnerId={jobOwnerId.input.value}
        jobSubscribersValue={job_subscribers.input.value || []}
        jobSubscribers={job_subscribers.meta.initial || []}
        jobSubscriberInvitationsValue={
          job_subscriber_invitations.input.value || []
        }
        changeSubscribers={handleChangeSubscribers}
        errorMessage={
          typeof job_subscriber_invitations.meta.error === "string"
            ? job_subscriber_invitations.meta.error
            : undefined
        }
      />
    </>
  );
};

import { useMemo, useRef } from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import { prepareDOMInput } from "@js/services/dom-sanitize";
import type { IPost } from "@js/types/give-and-get-help";

import { useHandleReadMore } from "../../hooks/use-handle-read-more";
import { ReadMoreButton } from "../read-more-button";

import { PostTextWithHashtagLinks } from "./post-text-with-hashtag-links";

import styles from "./styles.module.scss";

export type PostTextProps = {
  postData: IPost;
  onExpand: () => void;
  onCollapse: () => void;
};

export const PostText = ({ postData, onExpand, onCollapse }: PostTextProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { textContent, showButton, showFullText, toggleReadMore } =
    useHandleReadMore({
      id: postData.id,
      content: postData.text,
      parentRef: contentRef,
      onExpand,
      onCollapse,
    });

  const sanitizedText = useMemo(
    () => prepareDOMInput(textContent, { markdown: false }),
    [textContent],
  );

  return (
    <Box ref={contentRef} className={styles.postText}>
      <PostTextWithHashtagLinks text={sanitizedText} postData={postData} />
      {showButton && (
        <ReadMoreButton showFullText={showFullText} onClick={toggleReadMore} />
      )}
    </Box>
  );
};

import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from "react-hook-form";
import classNames from "classnames";
import _ from "underscore";

import {
  TextField,
  type TextFieldProps,
  Typography,
} from "@braintrust/braintrust-ui-components";

import styles from "./styles.module.scss";

export type RhfTextareaFieldProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    minLength?: number;
    maxLength?: number;
    maxLabel?: boolean;
    markdown?: boolean;
    minRowsNumber?: boolean;
    maxLengthInHelpText?: boolean;
    maxLengthCounterThreshold?: number;
  };

export const RhfTextareaField = <T extends FieldValues>(
  props: RhfTextareaFieldProps<T>,
) => {
  const { field, fieldState } = useController(props);
  const {
    minLength,
    maxLabel,
    maxLength,
    minRowsNumber,
    fullWidth,
    className,
    markdown,
    helperText,
    maxLengthInHelpText,
    maxLengthCounterThreshold,
  } = props;

  const textLength = field.value ? field.value?.length : 0;

  const showMaxLengthCounter = maxLengthCounterThreshold
    ? textLength >= maxLengthCounterThreshold
    : maxLength;

  return (
    <div
      className={classNames("textarea", styles.textarea, className)}
      style={fullWidth ? { width: "100%" } : undefined}
    >
      {!maxLengthInHelpText && maxLength && showMaxLengthCounter && (
        <Typography
          component="p"
          className={classNames(styles.textareaMaxLength, {
            [styles.textareaMaxLengthError]: textLength > maxLength,
          })}
        >
          {textLength} / {maxLength}
        </Typography>
      )}
      <TextField
        {...field}
        {..._.omit(
          props,
          "rows",
          "minLength",
          "maxLength",
          "maxLabel",
          "className",
          "markdown",
          "minRowsNumber",
          "helperText",
          "maxLengthInHelpText",
          "maxLengthCounterThreshold",
          "control",
        )}
        value={field.value || ""}
        ref={undefined}
        error={fieldState.invalid}
        multiline
        rows={!minRowsNumber ? props.rows || 4 : undefined}
        helperText={
          maxLengthInHelpText ? (
            <div className="textarea__helper-text-wrapper">
              {helperText || fieldState.error?.message}

              {maxLength && (
                <Typography
                  component="p"
                  className={classNames(
                    styles.textareaMaxLength,
                    styles.textareaMaxLengthStatic,
                    {
                      [styles.textareaMaxLengthError]: textLength > maxLength,
                    },
                  )}
                >
                  {textLength} / {maxLength}
                </Typography>
              )}
            </div>
          ) : (
            helperText || fieldState.error?.message
          )
        }
      />
      <Typography component="p" size="small" pt={1}>
        {minLength && <span>Min {minLength} characters. </span>}
        {maxLabel && <span>Max {maxLength} characters. </span>}
        {markdown && (
          <span>
            You can use
            <Typography
              component="a"
              fontWeight={500}
              className={styles.textareaLink}
              href="https://help.github.com/en/github/writing-on-github/basic-writing-and-formatting-syntax"
              target="_blank"
              rel="noopener noreferrer"
            >
              basic markdown syntax
            </Typography>
            .
          </span>
        )}
      </Typography>
    </div>
  );
};

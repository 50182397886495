import { Field } from "redux-form";

import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { TextareaField } from "@js/forms/fields";
import { maxLength } from "@js/forms/validators";

import { Label } from "../../label";

const MAX_LENGTH = 1000;

type DescriptionProps = {
  label: string;
};

export const DescriptionField = ({ label }: DescriptionProps) => {
  const isSmall = useMediaQuery("sm");

  return (
    <Field
      label={<Label isMobile={isSmall}>{label}</Label>}
      autoComplete="off"
      id="description"
      name="description"
      variant="outlined"
      multiline
      rows={4}
      placeholder="Description"
      component={TextareaField}
      maxLength={MAX_LENGTH}
      validate={[maxLength(MAX_LENGTH)]}
    />
  );
};

import type { ToggleDrawer } from "@braintrust/braintrust-ui-components";
import {
  Box,
  Button,
  IconButton,
  Loader,
  Rating,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { ReviewsProps } from "@js/apps/common/api";
import { useUser } from "@js/apps/common/hooks";
import { Date } from "@js/components/date";
import { UserAvatar } from "@js/components/user-avatar";
import type { User } from "@js/types/auth";
import type { InternalTalentReview } from "@js/types/common";
import { DateFormats } from "@js/utils/date/types";

import { InternalRatingForm } from "../../forms/internal-rating";
import { useInternalTalentReviews } from "../../hooks/use-internal-talent-reviews";
import type { InternalRatingProfile } from "../rating-card";

import style from "./style.module.scss";

type InternalTalentReviewDrawerProps = {
  onClose: ToggleDrawer;
  talent: InternalRatingProfile;
};

export const InternalTalentReviewDrawer = ({
  onClose,
  talent,
}: InternalTalentReviewDrawerProps) => {
  const { reviews, loadingReviews, onSubmit, onSubmitSuccess, onDelete } =
    useInternalTalentReviews({ talent });

  return (
    <Box className={style.internalTalentReviews}>
      <header className={style.internalTalentReviewsHeader}>
        <Typography component="h2" variant="label" multipleEllipsis>
          Braintrust rating for {talent.user.first_name} {talent.user.last_name}
        </Typography>

        <IconButton
          variant="transparent-border-beige"
          aria-label="close rating drawer"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </header>

      <div className={style.internalTalentReviewsComments}>
        {reviews?.internal_reviews.length ? (
          loadingReviews ? (
            <Loader centered />
          ) : (
            reviews?.internal_reviews.map((comment) => (
              <ReviewComment
                key={comment.id}
                comment={comment}
                onDelete={onDelete}
              />
            ))
          )
        ) : (
          <EmptyState talent={talent} />
        )}
      </div>

      <div>
        <InternalRatingForm
          talent={talent}
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
        />
      </div>
    </Box>
  );
};

type ReviewCommentProps = {
  comment: InternalTalentReview;
  onDelete: (
    id: number,
  ) => Promise<{ data: ReviewsProps } | { error: unknown }>;
};

const ReviewComment = ({ comment, onDelete }: ReviewCommentProps) => {
  const user = useUser();
  return (
    <Box className={style.internalTalentReviewsItem}>
      <UserAvatar
        size="sm"
        user={
          { ...comment.author, first_name: comment.author.public_name } as User
        }
        hideBorder
        hideBadge
      />

      <Box minWidth={0} overflow="hidden">
        <div className={style.internalTalentReviewsItemHeader}>
          <Typography
            component="p"
            variant="label"
            multipleEllipsis
            size="small"
          >
            {comment.author.public_name}
          </Typography>

          {comment.rating && (
            <Rating
              containerProps={{
                className: style.internalTalentReviewsItemRatingContainer,
              }}
              readOnly
              variant="stars"
              iconStyle={{ width: "16px", height: "16px" }}
              value={comment.rating}
            />
          )}
        </div>

        <Typography
          component="p"
          size="small"
          sx={{ wordBreak: "break-all" }}
          whiteSpace="pre-line"
        >
          {comment.text}
        </Typography>

        <div className={style.internalTalentReviewsItemFooter}>
          <Typography component="p" color="grey-3" size="small">
            <Date date={comment.created} format={DateFormats["Jan 1, 1970"]} />
          </Typography>

          {user && user.id === comment.author.id && (
            <Button
              variant="transparent-grey"
              size="x-small"
              fontWeight={400}
              onClick={() => onDelete(comment.id)}
            >
              <Typography
                component="span"
                size="small"
                sx={{ textDecoration: "underline" }}
              >
                Delete comment
              </Typography>
            </Button>
          )}
        </div>
      </Box>
    </Box>
  );
};

const EmptyState = ({
  talent,
}: Pick<InternalTalentReviewDrawerProps, "talent">) => {
  return (
    <div className={style.internalTalentReviewsEmptyState}>
      <img
        src={`${SETTINGS.STATIC_URL}common/internal-rating-empty-bg.png`}
        className={style.internalTalentReviewsEmptyStateIcon}
        alt=""
        width="192"
        height="101"
      />

      <Typography component="p" variant="label">
        Leave the first rate for {talent.user.first_name}
      </Typography>

      <Typography component="p" size="small">
        Share how high quality this talent appears based on their profile, and
        why. This is a rating of the talent which will persist across
        applications, not just related to this job application.
      </Typography>

      <Typography
        component="p"
        variant="label"
        size="small"
        className={style.internalTalentReviewsEmptyStateCore}
      >
        Only the core team can view.
      </Typography>
    </div>
  );
};

import React from "react";
import type { WrappedFieldProps } from "redux-form";

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { PollResultsIndicator } from "@js/apps/give-and-get-help/components/poll/poll-results-indicator";
import { usePollResults } from "@js/apps/give-and-get-help/components/poll/utils";
import { PollResultsBar } from "@js/apps/give-and-get-help/components/poll-results-bar";
import type { PollOption } from "@js/types/give-and-get-help";

type PollFieldProps = WrappedFieldProps & {
  options: PollOption[];
  votes_count: number | null;
  disablePoll?: boolean;
  resultsShown?: boolean;
};

export const PollField: React.FC<PollFieldProps> = ({
  input,
  meta,
  options,
  votes_count,
  disablePoll,
  resultsShown,
}) => {
  const isSmall = useMediaQuery("sm");
  const { error, touched } = meta;
  const { name } = input;

  const { isAny100, results } = usePollResults({
    options,
    votes_count,
  });

  return (
    <FormControl component="fieldset">
      {error && touched && (
        <div className="general-form-error general-form-error__align-left">
          {error}
        </div>
      )}
      <RadioGroup
        aria-label="Vote in a poll"
        name={name}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "var(--grey-4)",
          },
        }}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          {results.map((option) => {
            return (
              <Box key={option.id} height={45} display="flex">
                <Box flexGrow={1}>
                  <FormControlLabel
                    checked={input.value === option.id}
                    onChange={() => !touched && input.onChange(option.id)}
                    // disable native features of radio group by using type checkbox ...
                    // ... as we don't want to trigger selection with key(up|down)
                    control={<Radio inputProps={{ type: "checkbox" }} />}
                    disabled={disablePoll}
                    label={
                      <Typography
                        component="span"
                        variant="paragraph"
                        size={isSmall ? "medium" : "large"}
                      >
                        {option.text}
                      </Typography>
                    }
                  />
                  {resultsShown && (
                    <Box ml="33px">
                      <PollResultsBar option={option} />
                    </Box>
                  )}
                </Box>
                {resultsShown && (
                  <PollResultsIndicator option={option} isAny100={isAny100} />
                )}
              </Box>
            );
          })}
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

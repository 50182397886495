import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, TextField } from "@braintrust/braintrust-ui-components";
import { useLazyGetEmployerDefaultJobOwnersQuery } from "@js/apps/employer/api";
import { EmployerSearchComboboxField } from "@js/components/autocomplete-new/combobox/employer";
import { UserSearchComboboxField } from "@js/components/autocomplete-new/combobox/user";
import { required } from "@js/forms/validators";
import type { JobFormValues } from "@js/types/jobs";

import { POST_JOB_FOR_CLIENT_FIELDS } from "../constants";

type EmployerAndHiringManagerFieldProps = {
  employer: TypedWrappedFieldProps<number | undefined>;
  hiring_manager: TypedWrappedFieldProps<number | undefined>;
  op_owners: TypedWrappedFieldProps<JobFormValues["op_owners"]>;
  sale_owners: TypedWrappedFieldProps<JobFormValues["sale_owners"]>;
};

export const EmployerAndHiringManagerField = ({
  employer,
  hiring_manager,
  op_owners,
  sale_owners,
}: EmployerAndHiringManagerFieldProps) => {
  const [getEmployerDefaultOwners] = useLazyGetEmployerDefaultJobOwnersQuery();
  const handleEmployerChange = (employerId: number | null) => {
    hiring_manager.input.onChange(null);
    if (!employerId) {
      return;
    }

    getEmployerDefaultOwners(employerId, true)
      .unwrap()
      .then((data) => {
        const ops = data?.default_op_owners.map((owner) => owner.id);
        const sales = data?.default_sale_owners.map((owner) => owner.id);

        op_owners.input.onChange(ops);
        sale_owners.input.onChange(sales);
      })
      .catch(() => null);
  };

  return (
    <>
      <Box mb={2}>
        <Field
          validate={[required]}
          name={POST_JOB_FOR_CLIENT_FIELDS.employer}
          component={EmployerField}
          format={null}
          onEmployerChange={handleEmployerChange}
        />
      </Box>
      <Box mb={2}>
        <Field
          validate={[required]}
          name={POST_JOB_FOR_CLIENT_FIELDS.hiring_manager}
          component={HiringManagerField}
          selectedEmployer={employer.input.value}
        />
      </Box>
    </>
  );
};

type EmployerFieldProps = TypedWrappedFieldProps<number | null> & {
  onEmployerChange: (id: number | null) => void;
};
const EmployerField = ({
  onEmployerChange,
  input,
  meta,
}: EmployerFieldProps) => {
  const onChange = (employerId: number | null) => {
    input.onChange(employerId);
    onEmployerChange(employerId);
  };
  const inputWithEnchancedOnChange = {
    ...input,
    onChange,
  };

  return (
    <EmployerSearchComboboxField
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Select client"
            size="medium"
            placeholder="Select client"
          />
        );
      }}
      meta={meta}
      input={inputWithEnchancedOnChange}
    />
  );
};

type HiringManagerFieldProps = TypedWrappedFieldProps & {
  selectedEmployer: number | undefined;
};
const HiringManagerField = ({
  selectedEmployer,
  input,
  meta,
}: HiringManagerFieldProps) => {
  const endpointParams = useMemo(() => {
    return {
      hiring_manager: true,
      page_size: 100,
      for_employer: selectedEmployer,
    };
  }, [selectedEmployer]);

  return (
    <UserSearchComboboxField
      key={selectedEmployer} // to make sure every employer has unique options
      input={input}
      meta={meta}
      endpointParams={endpointParams}
      disabled={!selectedEmployer}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="medium"
            label="Add hiring manager"
            placeholder="Select Hiring Manager"
            error={(meta.touched || meta.submitFailed) && meta.error}
          />
        );
      }}
    />
  );
};

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { Date } from "@js/components/date";
import type { EmployerOffer, FreelancerOffer } from "@js/types/jobs";
import { DateFormats } from "@js/utils/date/types";

type TalentOrEmployerType = EmployerOffer | FreelancerOffer;

type OfferDetailsHeaderProps = {
  offer: TalentOrEmployerType;
  smallerTypography?: boolean;
};

export const OfferDetailsHeader = ({
  offer,
  smallerTypography = false,
}: OfferDetailsHeaderProps) => {
  let date = offer.created;
  let suffix = "Sent";

  if (offer.updated_at) {
    date = offer.updated_at;
    suffix = "Updated";
  }

  return (
    <Box>
      <Box
        component="header"
        display="flex"
        justifyContent="space-between"
        gap={1}
        flexWrap="wrap"
      >
        <Typography
          component="h2"
          size={smallerTypography ? "medium" : "large"}
          variant="label"
        >
          🗓 Offer details
        </Typography>
        <Typography
          component="span"
          size={smallerTypography ? "small" : "medium"}
          variant="label"
        >
          {suffix} <Date date={date} format={DateFormats["January 1, 1970"]} />
        </Typography>
      </Box>
    </Box>
  );
};

import { MessageIcon } from "@braintrust/braintrust-ui-components/Icons";

import type { TeamMemberInvitationsAutocompleteProps } from "../team-member-invitations-autocomplete";
import {
  TeamMemberInvitationsActionButton,
  TeamMemberInvitationsAutocomplete,
} from "../team-member-invitations-autocomplete";

type ShareWithTeamMemberInvitationsAutocompleteProps = Pick<
  TeamMemberInvitationsAutocompleteProps,
  "selectedInvitations" | "toggleInvitation" | "errorMessage"
>;

export const ShareWithTeamMemberInvitationsAutocomplete = ({
  ...rest
}: ShareWithTeamMemberInvitationsAutocompleteProps) => {
  return (
    <TeamMemberInvitationsAutocomplete
      {...rest}
      invitationActionButton={<ShareWithTeamMemberInvitationsInviteButton />}
    />
  );
};

const ShareWithTeamMemberInvitationsInviteButton = () => {
  return <TeamMemberInvitationsActionButton label="Share" Icon={MessageIcon} />;
};

import type { ReactNode } from "react";
import React from "react";

import { Divider, Stack } from "@braintrust/braintrust-ui-components";

type ReferralsStatsListContainerProps = {
  children: ReactNode;
  dividerColor: string;
};

export const ReferralsStatsListContainer = ({
  children,
  dividerColor,
}: ReferralsStatsListContainerProps) => {
  return (
    <Stack sx={{ pt: { xs: "40px", md: "52px" } }}>
      <Divider sx={{ borderColor: dividerColor, mb: 3 }} flexItem />
      {children}
    </Stack>
  );
};

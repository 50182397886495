import cs from "classnames";

import { Tooltip } from "@braintrust/braintrust-ui-components";
import type { Space } from "@js/types/spaces";

import { Avatar } from "../avatar";

import { FOR_YOU_BADGE, SpaceCardBadge } from "./space-card-badge";
import { SpaceCardDescription } from "./space-card-description";
import { SpaceCardDetails } from "./space-card-details";
import { SpaceCardJoinButton } from "./space-card-join-button";
import { SpaceCardName } from "./space-card-name";
import { useSpaceCard } from "./use-space-card";

import styles from "./style.module.scss";

export type SpaceCardProps = Omit<Space, "created" | "notifications_count"> & {
  showMobileVariant?: boolean;
  inviter?: string;
};

export const SpaceCard = (props: SpaceCardProps) => {
  const { mobileVariant, handleJoinSpaceClick, isJoining } = useSpaceCard(
    props.id,
    props.showMobileVariant,
  );

  return (
    <SpaceCardComponent
      {...props}
      showMobileVariant={mobileVariant}
      onJoinSpaceClick={handleJoinSpaceClick}
      isJoining={isJoining}
    />
  );
};

type SpaceCardComponentProps = SpaceCardProps & {
  onJoinSpaceClick: () => void;
  isJoining?: boolean;
};

const SpaceCardComponent = ({
  id,
  name,
  badge,
  description,
  total_members,
  number_of_posts_for_last_7_days,
  visibility,
  avatar_thumbnail = "",
  showMobileVariant,
  inviter,
  onJoinSpaceClick,
  isJoining,
}: SpaceCardComponentProps) => {
  return (
    <div className={styles.cardContainer}>
      <div
        className={cs(styles.card, {
          [styles.cardNoBadge]: !badge,
        })}
      >
        <Avatar
          src={avatar_thumbnail}
          size={showMobileVariant ? "medium" : "large"}
          className={styles.cardImage}
        />
        <SpaceCardName
          id={id}
          name={name}
          showMobileVariant={showMobileVariant}
        />
        <SpaceCardDetails
          visibility={visibility}
          memberCount={total_members}
          postsLastWeek={number_of_posts_for_last_7_days}
          showMobileVariant={showMobileVariant}
        />
        {badge && (
          <Tooltip
            title="Spaces tagged 'For you' are recommended based on your interactions with their members."
            fontWeight={400}
            disabled={badge !== FOR_YOU_BADGE}
          >
            <div className={styles.badgeContainer}>
              <SpaceCardBadge badge={badge} inviter={inviter} />
            </div>
          </Tooltip>
        )}
        <SpaceCardDescription
          description={description}
          showMobileVariant={showMobileVariant}
        />
        <SpaceCardJoinButton
          onJoinSpaceClick={onJoinSpaceClick}
          disabled={isJoining}
        />
      </div>
    </div>
  );
};

import React, { useMemo } from "react";

import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { MessengerContext } from "@js/apps/messenger/context/messenger-context";
import { useActiveRoom } from "@js/apps/messenger/hooks/use-active-room";
import { AppLayout } from "@js/layouts/app";

import type { MessengerProps } from "../../components";
import { Messenger } from "../../components";
import { SingleRoomBox } from "../../components/single-room";

export const MessagesPage = () => {
  const { activeRoom, setActiveRoom } = useActiveRoom();

  const providerValue = useMemo(
    () => ({
      activeRoom,
      setActiveRoom,
    }),
    [activeRoom, setActiveRoom],
  );

  return (
    <MessengerContext.Provider value={providerValue}>
      <MessengerContent activeRoom={activeRoom} />
    </MessengerContext.Provider>
  );
};

type MessengerContentProps = {
  activeRoom?: number | undefined;
};

const MessengerContent = ({ activeRoom }: MessengerContentProps) => {
  const isMobile = useMediaQuery("md");

  if (isMobile && !!activeRoom) {
    return <SingleRoomBox />;
  }

  return <MessengerWrapper />;
};

const MessengerWrapper = (props: MessengerProps) => {
  return (
    <AppLayout bgcolor="var(--soft-orange)" className="messages-page">
      <Messenger {...props} />
    </AppLayout>
  );
};

import { useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";
import _ from "underscore";

import { Box } from "@braintrust/braintrust-ui-components";
import { ErrorSection } from "@js/components/error-section";
import { SortableList } from "@js/components/sortable-list/motion";
import { useFormErrors } from "@js/forms/hooks";
import { isError, isTouched } from "@js/forms/utils";
import type { InterviewQuestion } from "@js/types/jobs";
import { getErrorsForRequiredFields } from "@js/utils";

import { SubmitButton } from "../job-interview-questions-header";

import { AddInterviewQuestionButton } from "./add-interview-question-button";
import { InterviewQuestionCard } from "./interview-question-card";
import { SectionBanner } from "./section-banner";

import style from "./style.module.scss";

export const JobInterviewQuestions = (
  interview_questions: TypedWrappedFieldProps<InterviewQuestion[]>,
) => {
  const [questionItems, setQuestionItems] = useState<InterviewQuestion[]>(
    interview_questions.input.value || [],
  );
  const isErrorInSection = isError([interview_questions]);
  const isAnyTouched = isTouched([interview_questions]);
  const displayError = isErrorInSection && isAnyTouched;
  const { errors } = useFormErrors(interview_questions.meta);

  const requiredErrors = getErrorsForRequiredFields(errors, [
    "interview_questions",
  ]);

  const cardError = interview_questions.meta.error?.split(";");

  const updateItems = (items: InterviewQuestion[]) => {
    const interviewQuestions = items.map((item, index) => ({
      ...item,
      order: index,
    }));
    setQuestionItems(interviewQuestions);
    interview_questions.input.onChange(interviewQuestions);
  };

  const debounceReorder = _.debounce(updateItems, 100);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        gap: "16px",
      }}
    >
      <SectionBanner />
      <SortableList<InterviewQuestion>
        className={style.sortableList}
        items={questionItems}
        onReorder={async (items) => {
          debounceReorder(items);
        }}
        style={{
          display: "grid",
          gap: "16px",
        }}
      >
        {(questionItem, index) => {
          return (
            <Box
              sx={{
                backgroundColor: "var(--white)",
                borderRadius: "16px",
                border: "1px solid #E9E7E4",
                padding: "24px",
              }}
            >
              <InterviewQuestionCard
                label={`Question ${index + 1}`}
                question={questionItem.question}
                displayError={displayError}
                error={cardError && cardError[index + 1]?.replace(/\|/g, "")}
                onChange={(question) => {
                  updateItems(
                    questionItems.map((item) =>
                      item.id === questionItem.id
                        ? { ...item, question }
                        : item,
                    ),
                  );
                }}
                onDelete={() => {
                  updateItems(
                    questionItems.filter((item) => item.id !== questionItem.id),
                  );
                }}
              />
            </Box>
          );
        }}
      </SortableList>
      {questionItems.length < 5 && (
        <Field
          name=""
          onAddQuestion={() => {
            updateItems([
              ...questionItems,
              {
                id: !!questionItems.length
                  ? Math.max(...(questionItems || []).map((item) => item.id)) +
                    1
                  : 0,
                question: "",
                order: 0,
              },
            ]);
          }}
          component={AddInterviewQuestionButton}
        />
      )}

      {displayError && cardError.length < 2 && (
        <ErrorSection
          meta={interview_questions.meta}
          requiredErrors={requiredErrors}
          errorMessage={interview_questions.meta.error}
        />
      )}
      <Box display="flex" justifyContent="end">
        <SubmitButton />
      </Box>
    </Box>
  );
};

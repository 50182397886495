import { Box, Typography } from "@braintrust/braintrust-ui-components";
import type { ProfileCompletionAreasMap } from "@js/types/freelancer";

import type { OpenProfileCompletionSequenceModalButtonProps } from "./open-profile-completion-sequence-modal-button";
import { OpenProfileCompletionSequenceModalButton } from "./open-profile-completion-sequence-modal-button";

type ProfileCompletionFeedbackLabelProps =
  OpenProfileCompletionSequenceModalButtonProps & {
    customNamePrefix?: string;
    progress: number;
  };

export const ProfileCompletionFeedbackLabel = ({
  number,
  name,
  customNamePrefix,
  pluralize,
  progress,
}: ProfileCompletionFeedbackLabelProps) => {
  return (
    <Box display="flex" width={1}>
      <Typography
        component="p"
        variant="paragraph"
        color="inherit"
        flexGrow={1}
        pr={1}
      >
        {customNamePrefix ?? "Add"} {number ? `${number}` : ""}{" "}
        <OpenProfileCompletionSequenceModalButton
          name={name}
          pluralize={pluralize}
          number={number}
        />
      </Typography>

      <Typography component="p">{progress}%</Typography>
    </Box>
  );
};

export const profileCompletionFeedbackLabelPropMap: ProfileCompletionAreasMap<
  Partial<ProfileCompletionFeedbackLabelProps>
> = {
  [ENUMS.ProfileCompletionArea.FreelancerFullNameCompletionDetail]: {
    customNamePrefix: "Add a",
    name: "Full Name",
  },
  [ENUMS.ProfileCompletionArea.FreelancerLocationCompletionDetail]: {
    customNamePrefix: "Add a",
    name: "Location",
  },
  [ENUMS.ProfileCompletionArea.FreelancerRoleCompletionDetail]: {
    customNamePrefix: "Add your",
    name: "Role",
  },
  [ENUMS.ProfileCompletionArea.FreelancerSkillCompletionDetail]: {
    name: "Skill",
    customNamePrefix: "Add your",
    pluralize: true,
  },
  [ENUMS.ProfileCompletionArea.FreelancerSuperPowersCompletionDetail]: {
    name: "Top skill",
    pluralize: true,
  },
  [ENUMS.ProfileCompletionArea.FreelancerWorkExperienceCompletionDetail]: {
    name: "Work Experience",
  },
};

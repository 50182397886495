import type { ReactNode } from "react";
import classnames from "classnames";

import {
  Box,
  Loader,
  Stack,
  type StackProps,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { DownloadCloudIcon } from "@braintrust/braintrust-ui-components/Icons";

import styles from "./file-dropzone-placeholder.module.scss";

export type FileDropzonePlaceholderContainerProps = Pick<
  StackProps,
  "sx" | "className" | "children"
>;

export type FileDropzonePlaceholderProps = {
  isDragActive: boolean;
  isFocused?: boolean;
  title?: ReactNode;
  subtitle?: string;
  imagePreview?: string;
  isLoading?: boolean;
} & FileDropzonePlaceholderContainerProps;

export const FileDropzonePlaceholder = ({
  title,
  subtitle,
  isDragActive,
  isFocused,
  className,
  imagePreview,
  isLoading,
  children,
  ...rest
}: FileDropzonePlaceholderProps) => {
  return (
    <>
      <Stack
        {...rest}
        className={classnames(
          styles.container,
          className,
          "dropzone-placeholder-container",
          {
            [styles.active]: isDragActive,
            [styles.hasPreview]: !!imagePreview,
            [styles.loading]: !!isLoading,
            [styles.focus]: !!isFocused,
          },
        )}
        data-testid="dropzone-placeholder-container"
      >
        {!!isLoading && <Loader centered className="placeholder-loader" />}
        <Stack
          className={classnames(styles.placeholder, "dropzone-placeholder")}
          data-testid="dropzone-placeholder"
        >
          <DownloadCloudIcon
            sx={{
              fontSize: "36px",
              mb: 1,
            }}
            className="dropzone-icon"
          />
          {!!title ? title : <DropzoneTitle isDragActive={isDragActive} />}
          {!!subtitle && (
            <Typography
              component="p"
              size="small"
              className="dropzone-subtitle"
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
        {!!imagePreview && (
          <Box
            className={classnames(
              styles.imageContainer,
              "dropzone-image-container",
            )}
          >
            <img
              src={imagePreview}
              alt={"Image preview"}
              className={classnames(styles.image, "dropzone-image-preview")}
              width="auto"
              height="auto"
            />
          </Box>
        )}
        {!!children && <Box>{children}</Box>}
      </Stack>
    </>
  );
};

const DropzoneTitle = ({ isDragActive }: { isDragActive: boolean }) => {
  return (
    <Typography
      component="label"
      size="medium"
      sx={{ cursor: "pointer" }}
      className="dropzone-title"
    >
      {isDragActive ? (
        "Drop here..."
      ) : (
        <>
          Drag image here or <u>browse</u> your files.
        </>
      )}
    </Typography>
  );
};

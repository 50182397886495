import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";

import { Loader, Typography } from "@braintrust/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import type { InjectedFormProps } from "@js/forms/fields";
import { RadioListField } from "@js/forms/fields";
import type { PaymentMethod } from "@js/types/payments";

import { PaymentMethodInline } from "../../components/payment-method-inline";

import styles from "./styles.module.scss";

export const PAY_FORM_ID = "pay";

type PayFormComponentProps = {
  paymentMethods: PaymentMethod[];
  fetchingPaymentMethods: boolean;
};

export type PayFormValues = {
  paymentMethod: PaymentMethod;
} & Record<string, unknown>;

type PayFormContentProps = PayFormComponentProps &
  InjectedFormProps<PayFormValues, PayFormComponentProps, string>;

const PayFormContent = (props: PayFormContentProps) => {
  const { paymentMethods, submit, error, submitting, fetchingPaymentMethods } =
    props;

  const filteredPaymentMethods = useMemo(() => {
    return paymentMethods.filter(
      ({ can_be_charged: canBeCharged }) => canBeCharged,
    );
  }, [paymentMethods]);

  const paymentMethodOptions = useMemo(() => {
    return filteredPaymentMethods.map((paymentMethod) => ({
      value: paymentMethod,
      label: <PaymentMethodInline paymentMethod={paymentMethod} />,
    }));
  }, [filteredPaymentMethods]);

  const noPaymentMethodsText =
    !fetchingPaymentMethods && !filteredPaymentMethods.length
      ? "No payment methods defined. Please add one first."
      : undefined;

  const choosePaymentMethodsText =
    !fetchingPaymentMethods && !!filteredPaymentMethods.length
      ? "Please choose payment method from the list below:"
      : undefined;

  const paymentMethodsText = noPaymentMethodsText || choosePaymentMethodsText;

  return (
    <Form onSubmit={submitting ? null : submit}>
      {fetchingPaymentMethods && <Loader />}
      {!!paymentMethodsText && (
        <Typography
          component="p"
          variant="paragraph"
          size="small"
          sx={{ mb: 2 }}
        >
          {paymentMethodsText}
        </Typography>
      )}
      <Field
        name="paymentMethod"
        label="Payment method"
        component={RadioListField}
        radioOptionClassName={styles.paymentMethodOption}
        options={paymentMethodOptions}
        errorStyle={{
          margin: "0 0 8px",
          textAlign: "center",
          fontSize: "13px",
        }}
      />
      {!!error && (
        <Typography
          component="p"
          variant="paragraph"
          size="small"
          error
          sx={{ textAlign: "center", mb: 2 }}
        >
          {error}
        </Typography>
      )}
    </Form>
  );
};

export const PayFormComponent = reduxForm<PayFormValues, PayFormComponentProps>(
  {
    form: PAY_FORM_ID,
    enableReinitialize: true,
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.paymentMethod) {
        errors.paymentMethod = "Please choose payment method.";
      }

      return errors;
    },
  },
)(PayFormContent);

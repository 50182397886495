import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import type { Reaction } from "@js/types/give-and-get-help";

import {
  StickerReactionButtonDesktop,
  type StickerReactionButtonDesktopProps,
} from "./sticker-reaction-button-desktop";
import {
  StickerReactionButtonMobile,
  type StickerReactionButtonMobileProps,
} from "./sticker-reaction-button-mobile";

type ChildrenPropsToOmit = "children" | "reactionValue";

export type StickerReactionButtonProps = Omit<
  StickerReactionButtonDesktopProps,
  ChildrenPropsToOmit
> &
  Omit<StickerReactionButtonMobileProps, ChildrenPropsToOmit> & {
    reaction: Reaction;
  };

export const StickerReactionButton = ({
  reaction,
  ...rest
}: StickerReactionButtonProps) => {
  const { sticker: reactionValue } = reaction;
  const isMobile = useMediaQuery("sm");

  const StickerReactionButtonComponent = isMobile
    ? StickerReactionButtonMobile
    : StickerReactionButtonDesktop;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: {
          xs: "4px",
          sm: "6px",
        },
      }}
    >
      <StickerReactionButtonComponent {...rest} reactionValue={reactionValue} />
      <Typography component="span">
        {reaction.count > 0 && reaction.count}
      </Typography>
    </Box>
  );
};

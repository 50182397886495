import { Box } from "@braintrust/braintrust-ui-components";
import { ViewContractButton } from "@js/apps/jobs/components/job-action-buttons/view-contract-button";
import type { Message } from "@js/types/messenger";

type ButtonForTextMessageProps = {
  message: Message;
  isOwner: boolean;
};

export const ButtonForTextMessage = ({
  message,
  isOwner,
}: ButtonForTextMessageProps) => {
  if (message.message_type === ENUMS.MessageType.OFFER_EXTENDED) {
    const { job_id, offer_id } = message.message_context;
    return (
      <Box mt={1}>
        <ViewContractButton
          variant={isOwner ? "medium-green" : "primary"}
          sx={isOwner ? { color: "var(--black)" } : {}}
          jobId={job_id}
          offerId={offer_id}
        />
      </Box>
    );
  }

  return null;
};

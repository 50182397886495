import { Box } from "@braintrust/braintrust-ui-components";
import { BookmarkButton } from "@js/apps/bookmarks/components/bookmark-post-button";
import { SharePostMenu } from "@js/apps/give-and-get-help/components/share-post-menu";
import { Reactions } from "@js/apps/give-and-get-help/components/stickers-module";
import { StickersPopover } from "@js/apps/give-and-get-help/components/stickers-module/stickers-popover";
import { useInteractsSection } from "@js/apps/give-and-get-help/hooks";
import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import { createPostOrCommentURL } from "@js/apps/give-and-get-help/utils/common";
import type { IPost } from "@js/types/give-and-get-help";

import { ReactSticker } from "./react-sticker";

import styles from "./style.module.scss";

type PostInteractsSectionProps = {
  postData: IPost;
  restrictTogglingReplies?: boolean;
};

export const PostInteractsSection = ({
  postData,
  restrictTogglingReplies = false,
}: PostInteractsSectionProps) => {
  const { handleReactionSelect, user } = useInteractsSection({
    entityData: postData,
    restrictTogglingReplies,
  });
  const disablePostActions = useDisableSpecificPostActions();

  const postUrl = createPostOrCommentURL({ post: postData });

  const showShareButton = !user?.is_banned_from_posting && !!user?.is_verified;

  return (
    <Box className={styles.interactsSection}>
      <Box>
        {!!postData.reactions.length && (
          <Reactions
            reactions={postData.reactions}
            entityId={postData.id}
            onReactionClick={handleReactionSelect}
            currentUser={user}
          />
        )}
      </Box>

      <Box className={styles.interactsSectionActionButtons}>
        <BookmarkButton
          savedPostsCount={postData.saved_posts_count}
          savedPostId={postData.saved_post_id}
          postId={postData.id}
          postData={postData}
        />

        {showShareButton && <SharePostMenu post={postData} postUrl={postUrl} />}

        <StickersPopover
          disabled={Boolean(disablePostActions || !user?.is_verified)}
          onStickerSelect={handleReactionSelect}
          AnchorComponent={ReactSticker}
        />
      </Box>
    </Box>
  );
};

import { Typography } from "@braintrust/braintrust-ui-components";
import { useIssueHelpOfferRefundMutation } from "@js/apps/give-and-get-help/api";
import {
  IssueOfferRefundForm,
  IssueOfferRefundModalFields,
} from "@js/apps/give-and-get-help/components/issue-refund-modal/issue-refund-modal";
import styles from "@js/apps/give-and-get-help/components/issue-refund-modal/styles.module.scss";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { HelpOffer } from "@js/types/give-and-get-help";

export const useIssueHelpOfferRefund = (offer: HelpOffer) => {
  const [issueRefund] = useIssueHelpOfferRefundMutation();

  const openIssueHelpOfferRefundModal = () => {
    ModalInstance.open({
      closeButton: true,
      className: styles.issueRefundModal,
      children: (
        <IssueOfferRefundForm
          onSubmit={() => {
            issueRefund({
              id: offer.id,
              data: {
                receiver: offer.receiver.user,
                author: offer.author.user,
                //   TODO Partial return postponed. Uncomment to enable the feature
                // budget: values.budget,
                budget: offer.budget.toString(),
              },
            })
              .unwrap()
              .then(() => {
                Snackbar.toast({
                  header: `A full refund was sent to ${offer.receiver.user.first_name}.`,
                  buttonText: "Okay",
                  content: (
                    <>
                      {offer.budget} BTRST was transferred from your{" "}
                      <Typography
                        component="link"
                        RouterLink={RouterLink}
                        to="/talent/dashboard/my_wallet/"
                      >
                        wallet{" "}
                      </Typography>
                      back to {offer.receiver.user.first_name}.
                    </>
                  ),
                });
              })
              .catch(() => Snackbar.error("Failed to issue refund."));
          }}
          onSubmitSuccess={() => {
            ModalInstance.close();
          }}
        >
          <IssueOfferRefundModalFields
            offerCategory={offer.category.name}
            askerFirstName={offer.receiver.user.first_name}
            budget={offer.budget}
          />
        </IssueOfferRefundForm>
      ),
    });
  };

  return { openIssueHelpOfferRefundModal };
};

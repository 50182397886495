import React from "react";
import { reduxForm } from "redux-form";

import { Alert, Typography } from "@braintrust/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import type { StripeWithdrawalMethod } from "@js/types/withdrawals";

export const ADD_STRIPE_WITHDRAWAL_METHOD_FORM_ID =
  "create-stripe-withdrawal-method";

export const AddStripWithdrawalMethodForm = reduxForm<StripeWithdrawalMethod>({
  form: ADD_STRIPE_WITHDRAWAL_METHOD_FORM_ID,
  enableReinitialize: true,
})(({ submit, submitting, error }) => {
  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <Alert type="info" className="mt+">
        <Typography component="p" size="small" color="blue" fontWeight={500}>
          Stripe charges a {SETTINGS.STRIPE_WITHDRAWAL_FEE}% fee for payments.
        </Typography>
      </Alert>
    </Form>
  );
});

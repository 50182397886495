import {
  Button,
  RoundedBox,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";

import { getRecommendedTalentListURL } from "../../utils";
import { CannotMakeAction } from "../cannot-make-action";

type JobManagementRequirementsCTAProps = {
  job: Job;
  title: string;
  subtitle: string;
  action: {
    path: string;
    name: string;
  };
};

export const JobManagementRequirementsCTA = ({
  job,
  title,
  subtitle,
  action,
}: JobManagementRequirementsCTAProps) => {
  return (
    <RoundedBox
      sx={{
        backgroundColor: "var(--soft-blue)",
        textAlign: "center",
      }}
    >
      <Typography component="h4" variant="label" mb={1}>
        {title}
      </Typography>
      <Typography component="p" mb={4}>
        {subtitle}
      </Typography>
      <CannotMakeAction job={job}>
        <Button
          variant="secondary"
          to={action.path}
          disabled={!!job.completed_at}
          RouterLink={RouterLink}
        >
          {action.name}
        </Button>
      </CannotMakeAction>
    </RoundedBox>
  );
};

export const EditJobRequirementsCTA = ({ job }: { job: Job }) => {
  return (
    <JobManagementRequirementsCTA
      job={job}
      title="You've reached the end of the applications."
      subtitle={`Haven't found the right fit yet?`}
      action={{ name: "Edit job requirements", path: `/jobs/${job.id}/edit/` }}
    />
  );
};

export const FindMoreTalentToInviteCTA = ({ job }: { job: Job }) => {
  return (
    <JobManagementRequirementsCTA
      job={job}
      title="Want to find more Talent to invite?"
      subtitle="Browse all Talent on Braintrust"
      action={{
        name: "Browse all Talent",
        path: getRecommendedTalentListURL(job),
      }}
    />
  );
};

import { Box } from "@braintrust/braintrust-ui-components";
import { Typography } from "@braintrust/braintrust-ui-components";

import { SkillsForm } from "./form";

type SkillsEditModuleProps = {
  onSubmitSuccess: () => void;
  saveButtonText?: string;
};

export const SkillsEditModule = ({
  onSubmitSuccess,
  saveButtonText,
}: SkillsEditModuleProps) => {
  return (
    <Box maxWidth="740px" minWidth={{ sm: "543px" }}>
      <Typography
        fontWeight={400}
        variant="title"
        size="small"
        mb={4}
        component="h1"
      >
        🔧 Skills
      </Typography>
      <SkillsForm
        saveButtonText={saveButtonText}
        onSubmitSuccess={onSubmitSuccess}
      />
    </Box>
  );
};

import React from "react";
import type { InjectedFormProps } from "redux-form";
import { Field, reduxForm } from "redux-form";

import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { LinkedInIcon } from "@braintrust/braintrust-ui-components/Icons";
import { createOTPModalInstance } from "@js/apps/common/forms/fields/otp/otp-auth-modal";
import { PROF_NET_ONBOARDING_PATHS } from "@js/apps/onboarding/constants";
import { RouterLink } from "@js/components/link";
import { Form } from "@js/forms/components/form";
import { PasswordField, TextField } from "@js/forms/fields";
import { required } from "@js/forms/validators";
export const LOGIN_FORM_ID = "logIn";

export const { openLoginOTPModal, closeLoginOTPModal, LoginOTPModalInstance } =
  createOTPModalInstance("LoginOTPModal");

import { signInWithLinkedIn } from "./sign-in-with-linkedin";

type OwnProps = {
  isExtraAuthRequired: boolean;
};

export type LoginFormData = {
  password: string;
  code?: string;
  email?: string;
  connect?: boolean;
  is_backup_code?: boolean;
};

type Props = InjectedFormProps<LoginFormData, OwnProps> & OwnProps;

const EmailLoginFormComponent = ({
  submit,
  error,
  submitting,
  isExtraAuthRequired,
}: Props) => {
  return (
    <Form onSubmit={submitting ? null : submit}>
      <Grid container spacing={3} justifyContent="center">
        <LoginOTPModalInstance />
        {error && (
          <Grid item xs={12} className="center general-form-error">
            {error}
          </Grid>
        )}
        <Grid item xs={12}>
          {isExtraAuthRequired ? (
            <Field
              id="connect"
              name="connect"
              type="hidden"
              component="input"
            />
          ) : (
            <Field
              data-lpignore="true"
              name="email"
              id="email-address"
              validate={[required]}
              variant="outlined"
              color="secondary"
              component={TextField}
              label="Email address"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column-reverse">
            <Field
              //special data atribute to prevent LastPass from filling this field
              data-lpignore="true"
              id="password"
              name="password"
              validate={[required]}
              color="secondary"
              variant="outlined"
              component={PasswordField}
              label="Password"
            />
            <Box
              display="flex"
              justifyContent="flex-end"
              position="relative"
              zIndex="2"
              marginBottom="-26px"
            >
              <Typography
                component="link"
                to="/auth/forgot_password/"
                size="small"
                RouterLink={RouterLink}
              >
                Forgot password?
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ borderColor: "var(--grey-4)" }} />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="primary"
            size="medium"
            fullWidth
            style={{ marginBottom: 24 }}
            disabled={submitting}
            type="submit"
          >
            Sign in
          </Button>
          {!isExtraAuthRequired && (
            <>
              <Button
                variant="secondary"
                disabled={submitting}
                size="medium"
                fullWidth
                endIcon={<LinkedInIcon />}
                onClick={signInWithLinkedIn}
              >
                Sign in with LinkedIn
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="p"
            color="secondary"
            mt={4}
            textAlign="center"
            size="small"
          >
            New to Braintrust?{" "}
            <Typography
              component="link"
              to={PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.path}
              size="small"
              RouterLink={RouterLink}
            >
              Create an account.
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Form>
  );
};

export const EmailLoginForm = reduxForm<LoginFormData, OwnProps>({
  form: LOGIN_FORM_ID,
})(EmailLoginFormComponent);

import { Suspense } from "react";

import { Box, Loader } from "@braintrust/braintrust-ui-components";
import { lazyWithRetry } from "@js/utils";

import type { PhoneFieldProps } from "./phone-field";

const PhoneFieldLazy = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "formatted-phone-number" */ "./phone-field"
  );

  return { default: module.PhoneFieldComponent };
});

export const PhoneField = (props: PhoneFieldProps) => {
  return (
    <Suspense fallback={<PhoneFieldLoader />}>
      <PhoneFieldLazy {...props} />
    </Suspense>
  );
};

const PhoneFieldLoader = () => {
  return (
    <Box sx={{ minHeight: "82px", position: "relative" }}>
      <Loader />
    </Box>
  );
};

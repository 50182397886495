import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components";

export const Item = ({ children }: { children: JSX.Element[] }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3.25}
      maxWidth={337}
      minWidth={285}
    >
      {children}
    </Box>
  );
};

const GreenBox = ({ children }: { children: JSX.Element[] }) => {
  return (
    <Box
      bgcolor="var(--medium-green)"
      borderRadius="18px"
      height="349px"
      position="relative"
      overflow="hidden"
    >
      {children}
    </Box>
  );
};

const Title = ({ text }: { text: string }) => {
  return (
    <Typography
      component="p"
      variant="label"
      size="medium"
      color="white"
      bgcolor="var(--green)"
      p="5px"
      borderRadius="14px 14px 0 0"
      textAlign="center"
    >
      {text}
    </Typography>
  );
};

type DescriptionProps = {
  header: string;
  children: string;
};

const Description = ({ header, children }: DescriptionProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Box>
      <Typography
        component="p"
        variant="label"
        size={isMobile ? "medium" : "large"}
      >
        {header}
      </Typography>
      <Typography component="p" variant="paragraph" size="small" mt={1}>
        {children}
      </Typography>
    </Box>
  );
};

Item.GreenBox = GreenBox;
Item.Title = Title;
Item.Description = Description;

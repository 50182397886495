import type { FunctionComponent } from "react";

import {
  Box,
  Button,
  Loader,
  Pagination,
  PaginationWrapper,
} from "@braintrust/braintrust-ui-components";
import { KeyboardArrowRightIcon } from "@braintrust/braintrust-ui-components/Icons";
import { SearchListingHeader } from "@js/apps/common/components";
import { SavedTalentsFiltersMenu } from "@js/apps/common/components/save-filters/saved-talents-filters-menu";
import { SearchViewHeaderContainer } from "@js/apps/common/components/search-view-header-container";
import { TalentListing } from "@js/apps/freelancer/components/talent-list";
import { TALENT_LOCATION } from "@js/apps/freelancer/constants";
import { useTalentListing } from "@js/apps/freelancer/hooks/listing";
import { useClearFilters } from "@js/apps/jobs/apps/listing/hooks/use-clear-filters";
import { UNIVERSAL_SEARCH_TABS } from "@js/apps/universal-search/constants";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { clearFiltersTalentsAndJobsUniversalSearch } from "@js/apps/universal-search/helpers";
import { RouterLink } from "@js/components/link";
import { useNavigate } from "@js/hooks";
import { AppLayout } from "@js/layouts/app";
import type { SavedJobFilter } from "@js/types/jobs";

import { SearchTalentsListFilters } from "../../components/search-talents-list-filters";

import styles from "./style.module.scss";

export const SearchTalentPage = () => {
  const {
    filters,
    isAnyFilterApplied,
    count,
    total_count,
    loading,
    talents,
    loadNewFilters,
  } = useTalentListing(TALENT_LOCATION.talent_search);
  const searchTerm = filters.search;

  return (
    <UniversalSearchContextProvider initialTab="PEOPLE">
      <AppLayout
        pageTitle="Talent Search"
        className="wider"
        bgcolor="var(--white)"
      >
        <Box className={styles.listingPage}>
          <Header searchTerm={searchTerm} loadNewFilters={loadNewFilters} />
          <Filters
            isAnyFilterApplied={isAnyFilterApplied}
            filters={filters}
            count={count}
            total_count={total_count}
          />
          <TalentList loading={loading} talents={talents} count={count} />
        </Box>
      </AppLayout>
    </UniversalSearchContextProvider>
  );
};

const Header = ({
  searchTerm,
  loadNewFilters,
}: { searchTerm?: string } & CtaButtonsProps) => {
  const headerText = searchTerm
    ? `People results for "${searchTerm}"`
    : "People results";

  return (
    <SearchViewHeaderContainer>
      <SearchListingHeader size="medium" hideUnderline>
        {headerText}
      </SearchListingHeader>
      <CtaButtons loadNewFilters={loadNewFilters} />
    </SearchViewHeaderContainer>
  );
};

const useCtaButton = () => {
  const navigate = useNavigate();

  const handleCtaClick = () => {
    clearFiltersTalentsAndJobsUniversalSearch(UNIVERSAL_SEARCH_TABS.PEOPLE);
    navigate("/talent");
  };

  return handleCtaClick;
};

type CtaButtonsProps = {
  loadNewFilters: (filters: SavedJobFilter) => void;
};
const CtaButtons = ({ loadNewFilters }: CtaButtonsProps) => {
  const handleCtaClick = useCtaButton();

  return (
    <Box display="flex" gap={1}>
      <Button
        variant="secondary"
        endIcon={<KeyboardArrowRightIcon />}
        onClick={handleCtaClick}
        className="text-nowrap"
      >
        Go to People
      </Button>
      <SavedTalentsFiltersMenu onChange={loadNewFilters} />
    </Box>
  );
};

type FiltersProps = Pick<
  ReturnType<typeof useTalentListing>,
  "isAnyFilterApplied" | "filters" | "count" | "total_count"
>;

const Filters: FunctionComponent<FiltersProps> = ({
  isAnyFilterApplied,
  filters,
  count,
  total_count,
}) => {
  return (
    <Box maxWidth={1200} mb={2}>
      <SearchTalentsListFilters
        isAnyFilterApplied={isAnyFilterApplied}
        filters={filters}
        count={count}
        total_count={total_count}
      />
    </Box>
  );
};

type TalentListProps = Pick<
  ReturnType<typeof useTalentListing>,
  "loading" | "talents" | "count"
>;
const TalentList: FunctionComponent<TalentListProps> = ({
  loading,
  talents,
  count,
}) => {
  const { clearFilters } = useClearFilters();
  return (
    <Box className={styles.listingPageContent}>
      {loading ? (
        <Loader centered />
      ) : (
        <>
          <TalentListing
            talentList={talents}
            onClear={clearFilters}
            location={TALENT_LOCATION.talent_search}
            searchResults
          />
          <PaginationWrapper>
            <Pagination
              RouterLink={RouterLink}
              count={count}
              perPage={SETTINGS.TALENT_LISTING_TALENTS_PER_PAGE}
            />
          </PaginationWrapper>
        </>
      )}
    </Box>
  );
};

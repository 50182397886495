import React, { useMemo } from "react";

import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { SimplyUsersThumbnailAvatars } from "@js/components/simply-user-thumbnail-avatars";
import type { User } from "@js/types/auth";
import type { Space } from "@js/types/spaces";

import { useIsSpaceMember } from "../../hooks/use-is-space-member";
import { openJoinThisSpaceModal } from "../join-the-space-modal/join-this-space-modal";

export type SpaceMemberAvatarData = {
  user: Pick<User, "id" | "has_avatar_set" | "avatar_thumbnail" | "gravatar">;
};

type SpaceMembersProps = {
  space: Space;
  members: SpaceMemberAvatarData[];
  membersCount: number;
  showCta?: boolean;
  ctaAction?: () => void;
  className?: string;
  membersSrc?: string;
};

export const SpaceMembers = ({
  space,
  members,
  membersCount,
  membersSrc,
  showCta,
  ctaAction,
  className,
}: SpaceMembersProps) => {
  const { isSpaceMember } = useIsSpaceMember({ spaceId: space.id });
  const users = useMemo(() => {
    return members.map((member) => member.user) ?? [];
  }, [members]);

  if (users.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <Typography component="h2" fontWeight={500} mb={1.5}>
        Members
      </Typography>
      <Box mb={3}>
        <SimplyUsersThumbnailAvatars
          users={users}
          usersAmount={membersCount}
          membersSrc={membersSrc}
          maxGroupImages={4}
          onClick={(e) => {
            if (!isSpaceMember) {
              e.preventDefault();
              const handleAction = () =>
                !membersSrc ? ctaAction?.() : openJoinThisSpaceModal(space);
              handleAction();
            }
          }}
        />
      </Box>
      {showCta && ctaAction && (
        <Button variant="primary" fullWidth onClick={ctaAction}>
          Join this space
        </Button>
      )}
    </div>
  );
};

import React from "react";

import { Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import type { StripeCreditCardPayment } from "@js/types/payments";

type PaymentMethodMaskedCreditCardNumberProps = {
  paymentMethod: StripeCreditCardPayment;
};

export const PaymentMethodMaskedCreditCardNumber = ({
  paymentMethod,
}: PaymentMethodMaskedCreditCardNumberProps) => {
  const isSmall = useMediaQuery("sm");

  return (
    <Typography component="span" size={isSmall ? "small" : "medium"}>
      {isSmall ? (
        <>&bull; &bull; &bull; &bull;&nbsp;&nbsp;</>
      ) : (
        <>
          &bull; &bull; &bull; &bull;&nbsp;&nbsp; &bull; &bull; &bull;
          &bull;&nbsp;&nbsp; &bull; &bull; &bull; &bull;&nbsp;&nbsp;
        </>
      )}
      {paymentMethod.method.last4}
    </Typography>
  );
};

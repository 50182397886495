import React, { type MouseEvent } from "react";

import {
  Button,
  IconButton,
  Menu,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  ClearOutlinedIcon,
  SaveOutlinedIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { SAVED_FILTERS_ENDPOINT } from "@js/apps/common/components/save-filters/const";
import { useDeleteSavedFilter } from "@js/apps/common/components/save-filters/hooks/use-delete-saved-filter";
import { useSavedTalentsFilters } from "@js/apps/common/components/save-filters/hooks/use-saved-talents-filters";
import type { SavedJobFilter } from "@js/types/jobs";

type NewSavedFiltersProps = {
  onChange: (filter: SavedJobFilter) => void;
};

export const SavedTalentsFiltersMenu = ({ onChange }: NewSavedFiltersProps) => {
  const { savedTalentFilters } = useSavedTalentsFilters();
  const { deleteSavedFilter } = useDeleteSavedFilter(
    SAVED_FILTERS_ENDPOINT.TALENTS,
  );
  const showList = savedTalentFilters.length > 0;

  const emptylist = (
    <Typography component="p" size="small" textAlign="center">
      No saved filters
    </Typography>
  );
  const listOfFilters = savedTalentFilters.map((savedFilter) => (
    <div
      className="flex space-between"
      key={savedFilter.id}
      role="menuitem"
      tabIndex={0}
      onKeyDown={(ev) => {
        if (ev.key === "Enter") {
          onChange(savedFilter);
        }
      }}
    >
      <Menu.Item
        onClick={() => onChange(savedFilter)}
        onKeyDown={(ev) => {
          if (ev.key === "Tab") {
            ev.stopPropagation(); // to prevent closing menu on Tab press
          }
        }}
        className="width-100"
      >
        {savedFilter.name}
      </Menu.Item>
      <IconButton
        variant="transparent"
        onClick={(ev: MouseEvent<HTMLElement>) => {
          ev.stopPropagation(); // to prevent closing popover of saved filter
          deleteSavedFilter(savedFilter.id);
        }}
        aria-label="Delete"
        size="medium"
      >
        <ClearOutlinedIcon className="saved-filters-delete-icon" />
      </IconButton>
    </div>
  ));

  return (
    <Menu
      anchor={
        <Button
          variant="primary"
          startIcon={
            <SaveOutlinedIcon sx={{ width: "16px", height: "16px" }} />
          }
        >
          Saved searches
        </Button>
      }
      id="saved-filters-menu"
      minMenuWidth="170px"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {showList && listOfFilters}
      {!showList && emptylist}
    </Menu>
  );
};

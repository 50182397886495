import React from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import type { JobTypeAndRoleTagProps } from "@js/apps/jobs/components/job-type-and-role-tags";
import { RolesTag } from "@js/apps/jobs/components/job-type-and-role-tags";
import { JobTypeTagWithTooltip } from "@js/apps/jobs/components/job-type-tag-with-tooltip";

import { JobItemSecondaryCaption } from "../job-item/components/secondary-caption";

export const JobTags = ({
  jobType,
  role,
  mt = 2,
  createdTime,
}: JobTypeAndRoleTagProps) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap="8px" mt={mt}>
      <JobTypeTagWithTooltip jobType={jobType} />
      <RolesTag role={role} />
      <JobItemSecondaryCaption createdTime={createdTime} />
    </Box>
  );
};

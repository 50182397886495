import React from "react";
import cs from "classnames";

import {
  IconButton,
  type IconButtonProps,
} from "@braintrust/braintrust-ui-components";
import { STICKERS_MAP } from "@js/apps/give-and-get-help/constants";
import type { StickerValue } from "@js/types/give-and-get-help";

import { isStickerValue } from "../helper";
import { StickerIcon } from "../sticker-icon";

import styles from "./sticker-icon-button.module.scss";

export type StickerIconButtonProps = {
  selected?: boolean;
  reactionValue: StickerValue;
} & Partial<IconButtonProps>;

export const StickerIconButton = ({
  selected,
  reactionValue,
  ...rest
}: StickerIconButtonProps) => {
  if (!isStickerValue(reactionValue)) {
    return <></>;
  }

  const { title, colorVariant, backgroundColor } = STICKERS_MAP[reactionValue];

  return (
    <IconButton
      data-testid="reaction-icon-button"
      aria-label={`React with ${title}`}
      {...rest}
      size="x-small"
      variant="white-border-beige"
      style={{
        "--backgroundColor": backgroundColor,
        "--colorVariant": `var(--medium-${colorVariant})`,
        "--darkColorVariant": `var(--dark-${colorVariant})`,
      }}
      className={cs(styles.button, rest.className, {
        selected: selected,
      })}
    >
      <StickerIcon stickerValue={reactionValue} sx={{ fontSize: "inherit" }} />
    </IconButton>
  );
};

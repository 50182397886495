import type { FC } from "react";

import { Box, IconButton } from "@braintrust/braintrust-ui-components";
import { CloseRoundedIcon } from "@braintrust/braintrust-ui-components/Icons";
import type { PurchaseUpgradeParams } from "@js/apps/auth/api";
import type { ModalInstance as ModalInstanceComponent } from "@js/components/modal";

import { ActionSection } from "./action-section";
import { DescriptionSection } from "./description-section";
import { HeaderSection } from "./header-section";

type ProductDescriptionModalProps = {
  onCancel?: () => void;
  onCloseSuccessPurchaseModal?: () => void;
  openProductDescriptionModal: (param: {
    onCancel?: () => void;
    onCloseSuccessPurchaseModal?: () => void;
    upgradeType: PurchaseUpgradeParams["upgrade_type"];
  }) => void;
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
  ModalInstance: typeof ModalInstanceComponent;
};

export const ProductDescriptionModal: FC<ProductDescriptionModalProps> = ({
  onCancel,
  onCloseSuccessPurchaseModal,
  openProductDescriptionModal,
  upgradeType,
  ModalInstance,
}) => {
  return (
    <Box
      sx={{
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
      }}
    >
      <IconButton
        size="x-small"
        onClick={() => {
          ModalInstance.close();
          onCancel?.();
        }}
        variant="primary"
        aria-label="close-button"
        sx={{
          padding: "12px !important",
          position: "absolute",
          top: { xs: 21, md: 23 },
          right: { xs: 21, md: 14 },
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      <HeaderSection upgradeType={upgradeType} />
      <DescriptionSection upgradeType={upgradeType} />
      <ActionSection
        onCancel={onCancel}
        onCloseSuccessPurchaseModal={onCloseSuccessPurchaseModal}
        openProductDescriptionModal={openProductDescriptionModal}
        upgradeType={upgradeType}
        ModalInstance={ModalInstance}
      />
    </Box>
  );
};

import React, { useEffect, useState } from "react";

import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import { MenuArrowDownIcon } from "@braintrust/braintrust-ui-components/Icons";
import { insightsTooltipViewed } from "@js/apps/jobs/actions";
import { useShowInsightFeatureFlag } from "@js/apps/jobs/components/application-insight-feature-flag-wrapper/hooks";
import { useAppDispatch } from "@js/hooks";

import { InsightTooltip } from "./insight-tooltip";
import {
  formatMatchLabel,
  getMatchLabelColors,
  useScoreMatchBadge,
} from "./utils";

type ScoreMatchBadgeProps = {
  isApplied: boolean;
  jobId: number;
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
};

export const ScoreMatchBadge = ({
  isApplied,
  jobId,
  matchLabel,
}: ScoreMatchBadgeProps) => {
  const [showInsightTooltip, setShowInsightTooltip] = useState<boolean>(false);
  const { showBadge } = useScoreMatchBadge(matchLabel);
  const matchLabelStyleProps = getMatchLabelColors(matchLabel, true);

  const isMobile = useMediaQuery("sm");
  const isEnabledInsightFeatureFlag = useShowInsightFeatureFlag();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showInsightTooltip) {
      dispatch(insightsTooltipViewed({ jobId }));
    }
  }, [showInsightTooltip, dispatch, jobId]);

  if (!showBadge) {
    return null;
  }

  return (
    <Tooltip
      slotProps={{
        tooltip: {
          sx: {
            width: { xs: "90vw", sm: "unset" },
            minWidth: { xs: "unset", sm: "311px" },
            maxWidth: { xs: "90vw", sm: "400px" },
            borderRadius: "24px",
            backgroundColor: "var(--white)",
            border: "1px solid var(--soft-beige)",
            padding: "20px",
          },
        },
        arrow: {
          sx: {
            fontSize: "1.5em",
            "&::before": {
              border: "1px solid var(--soft-beige)",
              backgroundColor: "var(--white)",
            },
          },
        },
      }}
      variant="white"
      placement={isMobile ? "bottom" : "right"}
      onOpen={() => setShowInsightTooltip(true)}
      onClose={() => setShowInsightTooltip(false)}
      open={isEnabledInsightFeatureFlag && showInsightTooltip}
      title={
        <InsightTooltip
          isApplied={isApplied}
          jobId={jobId}
          matchLabel={matchLabel}
        />
      }
    >
      <Box
        role="button"
        sx={{
          backgroundColor: matchLabelStyleProps.backgroundColor,
          "&:hover": {
            cursor: isEnabledInsightFeatureFlag ? "pointer" : "default",
          },
        }}
        borderRadius={5}
        alignItems="center"
        display="flex"
        marginTop={1}
        paddingY={0.75}
        paddingLeft={1.75}
        paddingRight={isEnabledInsightFeatureFlag ? 1 : 1.75}
      >
        <Box>
          <Typography
            fontWeight={500}
            component="span"
            variant="paragraph"
            size="small"
            sx={{
              userSelect: "none",
              color: matchLabelStyleProps.textColor,
            }}
          >
            {formatMatchLabel(matchLabel)}
          </Typography>
          <Box
            marginTop={-0.5}
            marginBottom={0.5}
            borderTop={`1px dashed ${matchLabelStyleProps.textColor}`}
          />
        </Box>
        {isEnabledInsightFeatureFlag && (
          <MenuArrowDownIcon
            sx={{
              flex: 1,
              fontSize: "16px",
              color: matchLabelStyleProps.textColor,
              marginRight: "2px",
              strokeWidth: "2.5 !important",
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
};

import { useEffect, useState } from "react";
import type {
  TypedWrappedFieldProps,
  WrappedFieldArrayProps,
} from "redux-form";

import {
  Box,
  ButtonCore,
  IconButton,
  InputAdornment,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import { userStartTypingPollOption } from "@js/apps/give-and-get-help/actions";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { useOnUserStartedTyping } from "@js/apps/give-and-get-help/hooks";
import type { CustomTextFieldProps, TextFieldProps } from "@js/forms/fields";
import { TextField } from "@js/forms/fields";
import { TextFieldArray } from "@js/forms/fields";

import styles from "./styles.module.scss";

const MAX_QUESTIONS_LIMIT = 3;

export type PollTextFieldArrayProps = {
  disabled: boolean | undefined;
  shouldValidate: boolean;
  singleFieldValidator: Array<(value: string | number) => string | undefined>;
} & WrappedFieldArrayProps;

export const PollTextFieldArray = ({
  fields,
  disabled,
  shouldValidate,
  singleFieldValidator,
  ...props
}: PollTextFieldArrayProps) => {
  return (
    <>
      <Typography component="p" variant="label" mb={2}>
        {shouldValidate ? "Poll response options" : "Enter options below"}
      </Typography>
      <TextFieldArray
        optionItemContentKey="text"
        fields={fields}
        CustomTextField={PollTextField}
        CustomPlaceholder={PollFieldPlaceholder}
        maxQuestionsNumber={MAX_QUESTIONS_LIMIT}
        optionsGap={2}
        disabled={disabled}
        singleFieldValidator={singleFieldValidator}
        {...props}
      />
    </>
  );
};

type PollTextFieldProps = CustomTextFieldProps;

const PollTextField = ({ isFirstRender, ...props }: PollTextFieldProps) => {
  const { value } = props.input;
  const [customFocus, setCustomFocus] = useState(false);
  const location = usePostLocationContext();

  useOnUserStartedTyping({
    input: props.input,
    meta: props.meta,
    action: userStartTypingPollOption({
      composer_location: location,
    }),
  });

  useEffect(() => {
    if (!isFirstRender) {
      setCustomFocus(true);
    }
    return () => setCustomFocus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (customFocus || !!value) {
    return (
      <FieldFocused customOnBlur={() => setCustomFocus(false)} {...props} />
    );
  }

  return <PollFieldPlaceholder onClick={() => setCustomFocus(true)} />;
};

type FieldFocusedProps = TextFieldProps &
  TypedWrappedFieldProps<string> & {
    customOnBlur?: () => void;
    fields: WrappedFieldArrayProps["fields"];
    index: number;
  };

const FieldFocused = ({
  customOnBlur,
  fields,
  index,
  ...props
}: FieldFocusedProps) => {
  const executeOnBlurAndRemoveIfEmpty = () => {
    if (!props.meta.visited) {
      return;
    }

    if (customOnBlur) customOnBlur();

    if (!props.input.value.trim()) {
      fields.remove(index);
    }
  };

  return (
    <TextField
      autoFocus
      placeholder="Add an option..."
      InputProps={{
        "aria-label": `Pool option number ${index + 1}`,
        onBlur: (ev) => {
          // prevent blur when user selects different browser tab
          // https://stackoverflow.com/a/61758013/5908844
          if (ev.target !== document.activeElement) {
            executeOnBlurAndRemoveIfEmpty();
          }
        },
        onKeyPress: (e) => {
          if (e.code === "Enter") {
            executeOnBlurAndRemoveIfEmpty();
          }
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={() => fields.remove(index)}
              variant="transparent"
              disabled={props.disabled}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

const PollFieldPlaceholder = ({ onClick }: PlaceholderProps) => {
  return (
    <Box
      component={ButtonCore}
      onClick={onClick}
      onKeyDown={onClick}
      className={styles.pollAnswerPlaceholder}
    >
      <Typography component="p" variant="paragraph">
        Add an option...
      </Typography>
    </Box>
  );
};

type PlaceholderProps = {
  onClick: () => void;
};

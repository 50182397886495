import type { ReactNode } from "react";
import React from "react";

import {
  Box,
  Chip,
  Tooltip,
  Typography,
} from "@braintrust/braintrust-ui-components";
import type { FreelancerReferralType } from "@js/types/dashboard";

type TalentCardApprovalBadgeProps = {
  referral: FreelancerReferralType;
};

export const TalentCardApprovalBadge = ({
  referral,
}: TalentCardApprovalBadgeProps) => {
  const firsName = referral.user.first_name;

  switch (referral.approval_status) {
    case ENUMS.FreelancerApprovalStatus.APPROVED:
      return <></>;
    case ENUMS.FreelancerApprovalStatus.PENDING:
      return <TalentCardPendingScreeningBadge firstName={firsName} />;
    case ENUMS.FreelancerApprovalStatus.NOT_APPROVED:
      return <TalentCardNotApprovedBadge firstName={firsName} />;
    default:
      referral.approval_status satisfies never;
      return <></>;
  }
};

const commonChipProps = {
  variant: "squared",
  sx: {
    borderRadius: "8px !important",
  },
  size: "x-small",
  typographySize: "small",
} as const;

const TalentCardPendingScreeningBadge = ({
  firstName,
}: {
  firstName: string;
}) => {
  return (
    <TalentCardBadgeTooltip
      title={`${firstName} has signed up and is working towards being eligible for screening.`}
    >
      <Chip color="yellow" label="Pending screening" {...commonChipProps} />
    </TalentCardBadgeTooltip>
  );
};

const TalentCardNotApprovedBadge = ({ firstName }: { firstName: string }) => {
  return (
    <TalentCardBadgeTooltip
      title={`${firstName} has gone through screening but cannot be an approved talent at this time.`}
    >
      <Chip color="beige" label="Talent not approved" {...commonChipProps} />
    </TalentCardBadgeTooltip>
  );
};

type TalentCardBadgeTooltipProps = {
  children: ReactNode;
  title: string;
};
const TalentCardBadgeTooltip = ({
  children,
  title,
}: TalentCardBadgeTooltipProps) => {
  return (
    <Tooltip
      title={
        <Typography component="p" variant="paragraph" size="medium">
          {title}
        </Typography>
      }
      placement="top"
    >
      <Box component="span" sx={{ lineHeight: 1, cursor: "pointer" }}>
        {children}
      </Box>
    </Tooltip>
  );
};

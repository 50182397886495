import { RoundedBox } from "@braintrust/braintrust-ui-components";
import type { Job } from "@js/types/jobs";

import { SkillSectionContent } from "./skill-section-content";
import { SkillSectionTitle } from "./skill-section-title";

type JobDescriptionSkillSectionProps = {
  job: Job;
};

export const JobDescriptionSkillSection = ({
  job,
}: JobDescriptionSkillSectionProps) => {
  return (
    <RoundedBox mb={3}>
      <SkillSectionTitle />
      <SkillSectionContent job={job} />
    </RoundedBox>
  );
};

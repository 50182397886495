import { Navigate } from "react-router-dom";

import { Box, Paper } from "@braintrust/braintrust-ui-components";
import { useGetSpaceMembersQuery } from "@js/apps/spaces/api";
import { useIsSpaceMember } from "@js/apps/spaces/hooks/use-is-space-member";
import { useIdParam } from "@js/hooks/use-id-param";

import {
  PeopleInviteButton,
  PeopleList,
  PeopleLoader,
  PeopleNoData,
  PeopleSearch,
  PeopleTitle,
  PeopleTopContainer,
  usePeopleFilters,
} from "../../people";
import { PeoplePagination } from "../../people/people-pagination";

export const SpacesPeople = () => {
  const { filters } = usePeopleFilters();
  const { page, search } = filters ?? {};
  const spaceId = useIdParam("spaceId");
  const { isSpaceMember, isLoading: isLoadingIsSpaceMember } = useIsSpaceMember(
    { spaceId },
  );
  const { data, isLoading } = useGetSpaceMembersQuery(
    { id: spaceId as number, page: page === 1 ? undefined : page, search },
    { skip: !spaceId },
  );
  const members = data?.results;
  const noData = !isLoading && !data?.count;
  const showMembers = !isLoading && !!members?.length;

  if (!spaceId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  if (isLoadingIsSpaceMember) {
    return <></>;
  }

  if (!isSpaceMember) {
    return <Navigate to={`/spaces/${spaceId}`} replace />;
  }

  return (
    <Paper
      sx={{ py: { xs: 3, md: 4 }, borderRadius: 4, minHeight: "500px" }}
      variant="outlined"
    >
      <PeopleTopContainer>
        <PeopleTitle />
        <Box
          display="flex"
          sx={{
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
            },
            gap: {
              xs: 2,
              sm: 1,
            },
          }}
        >
          <PeopleInviteButton spaceId={spaceId} />
          <PeopleSearch />
        </Box>
      </PeopleTopContainer>
      {noData && <PeopleNoData spaceId={spaceId} />}
      {isLoading && <PeopleLoader />}
      {showMembers && <PeopleList members={members} spaceId={spaceId} />}
      <PeoplePagination total={data?.count ?? 0} page={filters.page} />
    </Paper>
  );
};

import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import { MessageIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import {
  MyWorkCardBasicInfo,
  PaymentAmount,
} from "@js/apps/freelancer/components/my-work-base-component/components";
import {
  JobItemEmployerData,
  JobsOfferActionButton,
  RolesTag,
  WorkTimeDetail,
} from "@js/apps/jobs/components";
import {
  ApplicationStatusWithTooltip,
  ApplictionStatus,
} from "@js/apps/jobs/components/application-status";
import { BoostedJobBadge } from "@js/apps/jobs/components/boosted-job-badge";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import type { FreelancerOffer } from "@js/types/jobs";

import { MyWorkBaseCard } from "../../my-work-base-component";

export const OfferItem = ({ offer }: { offer: FreelancerOffer }) => {
  const user = useUser();
  const isMobile = useMediaQuery("sm");
  const { openMessengerModal } = useOpenMessengerModal();

  return (
    <MyWorkBaseCard
      job={offer.job}
      infoSlot={<MyWorkCardBasicInfo job={offer.job} />}
      roleSlot={<RolesTag role={offer.role} />}
      statusSlot={
        <Box display="flex" gap={1}>
          {offer.is_boosted && (
            <BoostedJobBadge
              jobId={offer.job.id}
              statusForFreelancer={offer.status_for_freelancer}
            />
          )}
          <ApplicationStatusWithTooltip
            status={offer.status_for_freelancer}
            offerAcceptedDate={offer.accepted_at}
            offerCreatedDate={offer.created}
            appliedDate={null}
            offerUpdatedDate={offer.updated_at}
          />
        </Box>
      }
      rateSlot={
        <PaymentAmount
          paymentAmount={offer.payment_amount}
          paymentType={offer.payment_type}
        />
      }
      ctaSlot={
        <>
          <JobsOfferActionButton
            jobId={offer.job.id}
            offerId={offer.id}
            statusForFreelancer={offer.status_for_freelancer}
            size={isMobile ? "x-small" : "medium"}
          />

          <IconButton
            variant="transparent-border-beige"
            shape="squared"
            size={isMobile ? "x-small" : "medium"}
            aria-label="Application reactions"
            onClick={() =>
              user &&
              openMessengerModal({
                context: {
                  participants: [user?.id, offer.job.creator.id],
                },
              })
            }
          >
            <MessageIcon style={{ fontSize: "16px" }} />
          </IconButton>
        </>
      }
    />
  );
};

export const SignUpBYOTOfferSummary = ({
  offer,
}: {
  offer: FreelancerOffer;
}) => {
  return (
    <Box
      display="flex"
      p={2}
      border="1px solid var(--soft-grey)"
      borderRadius="20px"
      gap={3}
    >
      <Box minWidth={115}>
        <JobItemEmployerData
          employer={offer.job.employer}
          isSmallJobItemCard
          noLink
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
      >
        <Typography component="h2" variant="label">
          {offer.job.title}
        </Typography>
        <WorkTimeDetail
          variant="listing"
          expectedHoursPerWeek={offer.job.expected_hours_per_week}
          shortened
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexShrink={1}
        gap={1}
      >
        <ApplictionStatus status={offer.status_for_freelancer} />
        <PaymentAmount
          paymentAmount={offer.payment_amount}
          paymentType={offer.payment_type}
        />
      </Box>
    </Box>
  );
};

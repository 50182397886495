import type { WrappedFieldProps } from "redux-form";
import { Fields } from "redux-form";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { TalentProfileSkillsField } from "@js/apps/skills/fields";
import { createFormInstance } from "@js/forms/components";

import type { SkillsFormData } from "./hook";
import { useSkillsForm } from "./hook";

import styles from "./styles.module.scss";

const SkillsFormInstance = createFormInstance<SkillsFormData, unknown>(
  "skills-module-form",
);

type SkillsFormProps = {
  onSubmitSuccess: () => void;
  saveButtonText?: string;
};

export const SkillsForm = ({
  onSubmitSuccess,
  saveButtonText,
}: SkillsFormProps) => {
  const { onSubmit, initialValues, isLoading } = useSkillsForm();

  if (isLoading) {
    return <SkillsLoader />;
  }

  return (
    <SkillsFormInstance
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ pristine }) => (
        <div className={styles.wrapper}>
          <Fields names={["new_skills"]} component={SkillsErrors} />
          <Typography component="p" size="large" mb={2}>
            Add at least 5 skills, then star 3-4 of them you consider your top
            skill
          </Typography>
          <Fields
            names={["role", "new_skills", "superpower_skills"]}
            component={TalentProfileSkillsField}
          />
          {!pristine && (
            <div className={styles.actions}>
              <Button variant="positive" type="submit" shape="squared">
                {saveButtonText || "Save"}
              </Button>
            </div>
          )}
        </div>
      )}
    </SkillsFormInstance>
  );
};

const SkillsErrors = ({ new_skills }: { new_skills: WrappedFieldProps }) => {
  return new_skills.meta.error?.map((error: string) => (
    <Typography key={error} mb={2} component="p" variant="paragraph" error>
      {error}
    </Typography>
  ));
};

const SkillsLoader = () => {
  return (
    <Box sx={{ position: "relative" }} className={styles.wrapper}>
      <Loader centered />
    </Box>
  );
};

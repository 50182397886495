import { useMemo } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
} from "@js/apps/common/frontend-storage";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { isDaysFromTodayEqualOrHigher } from "@js/utils";

const DAYS_THRESHOLD = 2;

export const useShowCompleteProfileTopBar = () => {
  const freelancerProfile = useFreelancerProfile();
  const shouldDisplayTopBar =
    !!freelancerProfile && !freelancerProfile?.can_bid;

  const {
    data: storedDate,
    isLoading,
    isError,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.complete_profile_top_bar_dismissed_at },
    { skip: !shouldDisplayTopBar },
  );

  const showCompleteProfileTopBar = useMemo(() => {
    if (!shouldDisplayTopBar || isLoading || isError) {
      return false;
    }

    if (!storedDate || typeof storedDate !== "string") {
      return true;
    }
    const shouldDisplayTopBarAgain = isDaysFromTodayEqualOrHigher(
      storedDate,
      DAYS_THRESHOLD,
    );

    return shouldDisplayTopBarAgain;
  }, [isLoading, shouldDisplayTopBar, storedDate, isError]);

  return { showCompleteProfileTopBar };
};

import React from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import type { DecoratedFormProps } from "redux-form";
import {
  clearFields,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
  stopSubmit,
} from "redux-form";
import type { CancelTokenSource } from "axios";
import _ from "underscore";

import { Box, Grid, Loader } from "@braintrust/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import { SelectField } from "@js/forms/fields";
import { TextField, ToggleButtonGroupField } from "@js/forms/fields";
import type { AppDispatch, RootState } from "@js/store";
import type {
  WithdrawalFieldRequirement,
  WithdrawalFormRequirements,
} from "@js/types/withdrawals";
import { mapTypedDispatchToProps } from "@js/utils/store";

import {
  fetchCurrencyRequirements,
  fetchOtherRequirements,
  fetchTransferWiseCurrencies,
} from "../actions";
import { AddWithdrawalMethodOTPModalInstance } from "../components/add-withdrawal-method/withdrawal-method-otp-modal-instance";

import style from "./style.module.scss";

export const ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID = "addTransferWiseWithdrawal";
const selector = formValueSelector(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID);

type AddTransferWiseWithdrawalFormClassType = ConnectedProps<
  typeof connector
> & {
  formRequirements: WithdrawalFormRequirements;
};

class AddTransferWiseWithdrawalFormComponent extends React.Component<
  AddTransferWiseWithdrawalFormClassType & DecoratedFormProps
> {
  static defaultProps = {
    legalType: null,
  };

  componentDidMount() {
    this.props.dispatch(fetchTransferWiseCurrencies());
  }

  requiredValidator = (value?: string) => (value ? undefined : "Required");

  shouldComponentUpdate(
    nextProps: AddTransferWiseWithdrawalFormClassType,
    nextState: any,
  ) {
    const {
      initialValues: { recipient_type: existingRecipientType },
      change,
    } = this.props;
    const {
      initialValues: { recipient_type: recipientType },
    } = nextProps;

    if (recipientType !== existingRecipientType && change) {
      change("recipient_type", recipientType);
    }

    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  getAddressPrefix = () => {
    const { legalType } = this.props;
    let addressPrefix = "Address";

    if (legalType) {
      addressPrefix =
        legalType === "PRIVATE" ? "Personal Address" : "Business Address";
    }

    return addressPrefix;
  };

  renderElement = (
    formRequirement: WithdrawalFormRequirements[keyof WithdrawalFormRequirements]["fields"][number],
  ) => {
    const { type, key, name, valuesAllowed } = formRequirement;
    const label = formRequirement.is_address_field ? (
      <>
        <span className={style.addressPrefix}>{this.getAddressPrefix()}</span>
        &nbsp;- {name}
      </>
    ) : (
      name
    );

    switch (type) {
      case "text":
        return (
          <Field
            key={key}
            className="form-line"
            id={key}
            name={key}
            component={TextField}
            variant="standard"
            label={label}
            // autoComplete - please see: https://stackoverflow.com/a/30976223
            autoComplete={`new-${key}`}
          />
        );
      case "radio":
      case "select":
        return (
          <Field
            key={key}
            className="form-line"
            id={key}
            name={key}
            // autoComplete - please see: https://stackoverflow.com/a/30976223
            autoComplete={`new-${key}`}
            variant="standard"
            component={SelectField}
            options={(valuesAllowed || []).map((el) => ({
              label: el.name,
              value: el.key,
            }))}
            label={label}
          />
        );
      default:
        return null;
    }
  };

  renderMembers = () => {
    const { formRequirements, recipientType } = this.props;

    if (recipientType && recipientType in formRequirements) {
      return formRequirements[recipientType].fields.map((formRequirement) =>
        this.renderElement(formRequirement),
      );
    }

    return null;
  };

  render() {
    const {
      submit,
      submitting,
      error,
      currency,
      fetchingTransferWiseCurrencies,
      transferWiseCurrencies,
      formRequirements,
    } = this.props;

    const showLoader =
      fetchingTransferWiseCurrencies || !transferWiseCurrencies.length;

    return (
      <Form
        onSubmit={submitting ? null : submit}
        error={error}
        autoComplete="off"
      >
        <AddWithdrawalMethodOTPModalInstance />
        {showLoader && (
          <Box sx={{ minHeight: "6rem", position: "relative" }}>
            <Loader centered />
          </Box>
        )}
        {!showLoader && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                className="form-line"
                id="currency"
                name="currency"
                // autoComplete - please see: https://stackoverflow.com/a/30976223
                autoComplete="new-currency"
                variant="standard"
                component={SelectField}
                validate={[this.requiredValidator]}
                options={transferWiseCurrencies.map((key) => ({
                  label: key,
                  value: key,
                }))}
                label="Receiving Currency"
              />
              <Field
                key="account_name"
                variant="standard"
                className="form-line"
                id="account_name"
                name="account_name"
                // autoComplete - please see: https://stackoverflow.com/a/30976223
                autoComplete="new-account_name"
                component={TextField}
                label="Full Name of the Account Holder"
              />
              {currency && (
                <>
                  <div className="center mb+">
                    <Field
                      key="recipient_type"
                      className="form-line"
                      id="recipient_type"
                      name="recipient_type"
                      disableUnselecting
                      component={ToggleButtonGroupField}
                      options={Object.keys(formRequirements).map((key) => ({
                        label: formRequirements[key].title,
                        value: key,
                      }))}
                    />
                  </div>
                  <div className={style.dynamicFiledContainer}>
                    <FieldArray name="dynamic" component={this.renderMembers} />
                  </div>
                </>
              )}
              <input type="submit" className="invisible" />
            </Grid>
          </Grid>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  {
    formRequirements,
  }: {
    formRequirements: WithdrawalFormRequirements;
  },
) => ({
  fetchingTransferWiseCurrencies:
    state.withdrawal.fetchingTransferWiseCurrencies,
  transferWiseCurrencies: state.withdrawal.transferWiseCurrencies,
  creatingWithdrawalMethod: state.withdrawal.creatingWithdrawalMethod,
  legalType: selector(state, "legal_type") || null,
  recipientType: selector(state, "recipient_type") || null,
  currency: selector(state, "currency") || null,
  initialValues: {
    currency: selector(state, "currency") || SETTINGS.DEFAULT_CURRENCY,
    account_name:
      selector(state, "account_name") ||
      (state.auth.user && state.auth.user.public_name),
    recipient_type: _.isEmpty(formRequirements)
      ? null
      : Object.keys(formRequirements)[0],
  },
});

let lastRequestPromise:
  | null
  | {
      source: CancelTokenSource;
    }
  | Promise<void> = null;
let lastRequestTimeout: null | NodeJS.Timeout = null;

const AddTransferWiseWithdrawalForm = reduxForm<any, any>({
  form: ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID,
  enableReinitialize: false,
  onChange: (
    values,
    dispatch: AppDispatch,
    { formRequirements },
    previousValues,
  ) => {
    const { currency, recipient_type: recipientType } = values;
    const { currency: previousCurrency } = previousValues;

    const clearRedundantFormValues = (
      requirements: WithdrawalFormRequirements,
    ) => {
      if (recipientType in requirements) {
        dispatch(
          clearFields(
            ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID,
            false,
            false,
            ..._.without(
              Object.keys(previousValues),
              ...requirements[recipientType].fields.map((fr) => fr.key),
              "currency",
              "account_name",
              "recipient_type",
              "code",
              "is_backup_code",
            ),
          ),
        );
      }
    };

    if (currency !== previousCurrency) {
      return dispatch(fetchCurrencyRequirements(currency))
        .then((newRequirements) => clearRedundantFormValues(newRequirements))
        .catch((response) => {
          dispatch(
            stopSubmit(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID, response.errors),
          );
        });
    }

    if (recipientType && recipientType in formRequirements) {
      clearRedundantFormValues(formRequirements);

      const refreshRequirementsOnChangeFieldsChanged = Object.keys(values)
        .filter(
          (name) =>
            JSON.stringify(values[name]) !==
            JSON.stringify(previousValues[name]),
        )
        .map((name) =>
          formRequirements[recipientType].fields.find(
            (formRequirement: WithdrawalFieldRequirement) =>
              formRequirement.key === name,
          ),
        )
        .filter((value) => !!value)
        .filter((value) => value.refreshRequirementsOnChange);

      if (refreshRequirementsOnChangeFieldsChanged.length) {
        if (lastRequestPromise && "source" in lastRequestPromise) {
          lastRequestPromise.source.cancel("New request issued");
          lastRequestPromise = null;
        }

        if (lastRequestTimeout) {
          clearTimeout(lastRequestTimeout);
          lastRequestTimeout = null;
        }

        lastRequestTimeout = setTimeout(() => {
          lastRequestPromise = dispatch(fetchOtherRequirements(values))
            .then((newRequirements) => {
              clearRedundantFormValues(newRequirements);

              lastRequestPromise = null;
              lastRequestTimeout = null;
            })
            .catch((error) => {
              dispatch(stopSubmit(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID, error));
            });
        }, 200);
      }
    }
  },
})(AddTransferWiseWithdrawalFormComponent);

const connector = connect(mapStateToProps, mapTypedDispatchToProps);

export default connector(AddTransferWiseWithdrawalForm);

import {
  IconButton,
  InputAdornment,
  RegularTextField,
} from "@braintrust/braintrust-ui-components";
import {
  CloseIcon,
  SearchSVGIcon,
} from "@braintrust/braintrust-ui-components/Icons";

type ReferralsSearchProps = {
  variant?: "violet" | "green";
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
};

export const ReferralsSearch = ({
  variant = "violet",
  value,
  onChange,
  onClear,
}: ReferralsSearchProps) => {
  return (
    <RegularTextField
      variant="outlined"
      size="medium"
      value={value}
      onChange={(ev) => onChange(ev.target.value)}
      placeholder="Search for a referral"
      inputProps={{
        "aria-label": "Search for a referral",
      }}
      sx={{
        maxWidth: { xs: "initial", sm: "25rem" },
        ".MuiInputBase-root": {
          borderRadius: "5rem",
          bgcolor: "var(--white)",
          px: 2,
          "&:hover, &.Mui-focused": {
            "& fieldset": {
              border: `1px solid var(${variant === "violet" ? "--medium-violet" : "--medium-green"})`,
              boxShadow: "var(--elevation-3)",
            },
          },
        },
        ".MuiInputBase-input": {
          py: "8px",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchSVGIcon />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={onClear}
              variant="transparent"
              sx={{
                p: "0px !important",
              }}
              size="medium"
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};

import type {
  FormErrors,
  TypedWrappedFieldProps,
  WrappedFieldsProps,
} from "redux-form";
import { Field, Fields, getFormSyncErrors, touch } from "redux-form";

import type { ToggleDrawer } from "@braintrust/braintrust-ui-components";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Tooltip,
  Typography,
  useDrawer,
} from "@braintrust/braintrust-ui-components";
import { CloseRoundedIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  maxOpeningsNumberValidator,
  minOpeningsNumberValidator,
  openingsValidator,
} from "@js/apps/jobs/apps/create-job/validators";
import {
  JobTypeTag,
  OpeningsNumberButtonTag,
  RolesButtonTag,
} from "@js/apps/jobs/components";
import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useEmployerMaxOpeningsNumber } from "@js/apps/jobs/hooks";
import { useGetRoleData } from "@js/apps/roles/hooks";
import { getError, isError } from "@js/forms/utils";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { JobFormValues } from "@js/types/jobs";
import { pluralize } from "@js/utils";

import { JobRoleField } from "../../fields";
import { OpeningsNumberField } from "../../fields/openings-number-field";

type RoleSectionProps = {
  isEditForm: boolean | undefined;
} & TypedWrappedFieldProps<number>;

export const RoleSection = ({ input, meta, isEditForm }: RoleSectionProps) => {
  const maxOpeningsNumber = useEmployerMaxOpeningsNumber();
  const formName = meta.form;
  const syncErrors: FormErrors<JobFormValues> = useAppSelector((state) =>
    getFormSyncErrors(formName)(state),
  );

  const { isOpen, toggleDrawer } = useDrawer();
  const dispatch = useAppDispatch();
  const isOtherRole = input.value === SETTINGS.OTHER_ROLE_ID;
  const fields = isOtherRole
    ? (["role", "openings_number", "other_role_name"] as const)
    : (["role", "openings_number"] as const);
  const displayError = fields.some((field) => !!syncErrors[field]);

  const shouldStayOpen = displayError;
  const { jobToEdit } = useJobFormContext();
  const talentHiredCount = jobToEdit?.hired_bids_count;

  const handleClose = shouldStayOpen
    ? () => {
        dispatch(touch(formName, "role"));

        if (isOtherRole) {
          dispatch(touch(formName, "other_role_name"));
        }
      }
    : toggleDrawer;

  const minOpeningsNumber =
    isEditForm && talentHiredCount ? talentHiredCount : 1;

  return (
    <>
      <Fields
        id="role_section"
        names={["job_type", "role", "openings_number"]}
        toggleDrawer={toggleDrawer}
        component={JobTypeAndRolesTags}
        isEditForm={isEditForm}
      />
      <SwipeableDrawer
        open={isOpen}
        onClose={handleClose}
        onOpen={toggleDrawer}
        anchor="right"
      >
        <Box width={{ xs: "calc(100vw)", sm: 480, md: 800 }} p={5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4.5}
          >
            <Typography
              component="h2"
              variant="title"
              fontWeight={400}
              size="small"
            >
              Job Category & open roles
            </Typography>
            <Button
              startIcon={<CloseRoundedIcon />}
              onClick={handleClose}
              variant="white-violet"
              shape="squared"
            >
              Close
            </Button>
          </Box>
          <Divider color="secondary" sx={{ mb: 4 }} />
          <Field
            name="openings_number"
            titleLabel="Number of open roles"
            component={OpeningsNumberField}
            validate={[
              openingsValidator,
              maxOpeningsNumberValidator(maxOpeningsNumber),
              minOpeningsNumberValidator(minOpeningsNumber),
            ]}
            containerProps={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              columnGap: 6,
              rowGap: 2,
            }}
            isEditForm={isEditForm}
          />
          {isEditForm && !!talentHiredCount ? (
            <Tooltip
              title="You can't change job's categories after hiring a Talent."
              placement="top"
              enterNextDelay={500}
            >
              <span>
                <Box
                  sx={{
                    pointerEvents: "none",
                    opacity: "50%",
                  }}
                >
                  <JobRoleField hideError />
                </Box>
              </span>
            </Tooltip>
          ) : (
            <JobRoleField hideError />
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

type JobTypeAndRolesTagsProps = WrappedFieldsProps & {
  toggleDrawer: ToggleDrawer;
  isEditForm: boolean | undefined;
};

const JobTypeAndRolesTags = ({
  job_type,
  role,
  openings_number,
  toggleDrawer,
  isEditForm,
}: JobTypeAndRolesTagsProps) => {
  const maxOpeningsNumber = useEmployerMaxOpeningsNumber();
  const { data: selectedRoleData } = useGetRoleData(
    typeof role.input.value === "number" ? role.input.value : undefined,
  );
  const fields = [role, openings_number];
  const openingsNumber = openings_number.input.value;
  const { jobToEdit } = useJobFormContext();
  const hiredTalentCount = jobToEdit?.hired_bids_count;

  const displayError = isError(fields);
  const disableCategoryButtons =
    !!isEditForm && !!hiredTalentCount && !displayError;
  const disableOpeningsButton =
    !!isEditForm &&
    !!hiredTalentCount &&
    hiredTalentCount >= maxOpeningsNumber &&
    !displayError;

  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      <JobTypeTag type={job_type.input.value} />
      {selectedRoleData && (
        <RolesButtonTag
          onClick={toggleDrawer}
          role={selectedRoleData}
          disabled={disableCategoryButtons}
        />
      )}

      <OpeningsNumberButtonTag
        onClick={toggleDrawer}
        disabled={disableOpeningsButton}
        label={`${openingsNumber} open role${pluralize(openingsNumber)}`}
      />

      <Box>
        {displayError && (
          <Typography mt={2} component="p" variant="paragraph" color="negative">
            {getError(fields)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

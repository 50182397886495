import type { ReactNode } from "react";
import classnames from "classnames";

import {
  Box,
  Loader,
  Stack,
  type StackProps,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { AddFileIcon } from "@braintrust/braintrust-ui-components/Icons";

import styles from "./file-dropzone-placeholder-dashed.module.scss";

export type FileDropzonePlaceholderDashedContainerProps = Pick<
  StackProps,
  "sx" | "className" | "id"
>;

export type FileDropzonePlaceholderDashedProps = {
  isDragActive: boolean;
  isFocused?: boolean;
  title?: ReactNode;
  subtitle?: string;
  isLoading?: boolean;
} & FileDropzonePlaceholderDashedContainerProps;

export const FileDropzonePlaceholderDashed = ({
  title,
  subtitle,
  isDragActive,
  isFocused,
  className,
  isLoading,
  ...rest
}: FileDropzonePlaceholderDashedProps) => {
  return (
    <>
      <Stack
        {...rest}
        className={classnames(
          styles.container,
          className,
          "dropzone-placeholder-container",
          {
            [styles.focus]: !!isFocused,
            [styles.active]: isDragActive,
            [styles.loading]: isLoading,
          },
        )}
      >
        <Stack
          className={classnames(styles.placeholder, "dropzone-placeholder")}
        >
          <Box className={styles.iconContainer}>
            {!!isLoading ? (
              <Loader color="inherit" centered />
            ) : (
              <AddFileIcon
                sx={{ fontSize: "inherit" }}
                className={classnames("dropzone-icon", styles.icon)}
              />
            )}
          </Box>
          {!!title ? title : <DropzoneTitle isDragActive={isDragActive} />}
          {!!subtitle && (
            <Typography
              component="p"
              size="small"
              className="dropzone-subtitle"
              color="grey-2"
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
};

const DropzoneTitle = ({ isDragActive }: { isDragActive: boolean }) => {
  return (
    <Typography
      component="label"
      size="large"
      sx={{ cursor: "pointer" }}
      className={"dropzone-title"}
      fontWeight={500}
    >
      {isDragActive ? (
        "Drop file here..."
      ) : (
        <>
          Drag & drop file or{" "}
          <Typography
            component="span"
            size="large"
            color="dark-violet"
            fontWeight={500}
          >
            Browse
          </Typography>
        </>
      )}
    </Typography>
  );
};

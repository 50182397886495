import { useCallback } from "react";
import classNames from "classnames";

import { Box, Paper, Typography } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { ModalInstance } from "@js/components/modal";

import {
  openSubmitLinkedInUrlModal,
  SubmitLinkedinUrlAlreadyOptedState,
} from "../../components";
import { UploadLinkedinConnections } from "../../components/upload-linkedin-connections";

import styles from "./style.module.scss";

const ReferClientsProgramPage = () => {
  const user = useUser();
  const hasOptedIn = !!user?.refer_client_program_opted_in_at;

  return (
    <Paper className={styles.referClientProgram}>
      <Box>
        <Typography
          component="h2"
          variant="title"
          size="small"
          fontWeight={400}
          sx={{ mb: "8px" }}
        >
          Work directly with the Braintrust network to maximize your referral
          potential.
        </Typography>
        <Box
          className={classNames(styles.textContent, {
            [styles.textContentOptedIn]: hasOptedIn,
          })}
        >
          <Typography component="p" variant="paragraph" size="medium">
            Upload your LinkedIn Connections and we'll email you a list of which
            connections will be the best referrals within 1 business day. We'll
            never reach out to your Connections without your permission.
          </Typography>

          {hasOptedIn ? (
            <SubmitLinkedinUrlAlreadyOptedState />
          ) : (
            <Typography component="p" variant="paragraph" size="medium">
              Want more? Meet directly with the Braintrust team to maximize your
              referral potential by <SubmitLinkedInURLButton /> so we can review
              your network.
            </Typography>
          )}
        </Box>
      </Box>
      <UploadLinkedinConnections />
    </Paper>
  );
};

const SubmitLinkedInURLButton = () => {
  const onSubmitSuccess = useCallback(() => {
    ModalInstance.close();
  }, []);

  return (
    <button
      className="btn-reset pointer text-underline"
      onClick={() => openSubmitLinkedInUrlModal({ onSubmitSuccess })}
    >
      submitting your LinkedIn URL
    </button>
  );
};

export default ReferClientsProgramPage;

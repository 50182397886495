import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import type { FreelancerBid, MainJobListingJob } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import {
  BrowseOpenJobs,
  RespondToOfferButton,
  ReviewContractButton,
  ScheduleInterviewButton,
  StartAIInterviewButton,
  StartScreeningButton,
  ViewApplicationButton,
} from "./components";

type BidActionButtonProps = {
  job: MainJobListingJob;
  bid: FreelancerBid;
  statusForFreelancer: EnumType<typeof ENUMS.JobFreelancerStatus>;
  size?: ButtonProps["size"];
};

export const BidActionButton = ({
  job,
  bid,
  statusForFreelancer,
  size,
}: BidActionButtonProps) => {
  const talentId = useUser()?.freelancer;

  switch (statusForFreelancer) {
    case ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS:
    case ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT:
    case ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER:
      return null;

    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
      return (
        <>
          <ViewApplicationButton
            jobId={job.id}
            bidId={bid.id}
            variant="secondary"
            size={size}
          />
          <StartScreeningButton variant="primary" size={size} withIcon />
        </>
      );
    case ENUMS.JobFreelancerStatus.OFFER_EXTENDED:
    case ENUMS.JobFreelancerStatus.OFFER_ACCEPTED:
      if (!bid.current_offer?.id) return null;
      return (
        <ReviewContractButton
          jobId={job.id}
          offerId={bid.current_offer.id}
          size={size}
          variant="primary"
        />
      );
    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
    case ENUMS.JobFreelancerStatus.IN_REVIEW_AFTER_AI_INTERVIEW:
      return (
        <ViewApplicationButton
          jobId={job.id}
          bidId={bid.id}
          variant="primary"
          withIcon
          size={size}
        />
      );
    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
      if (!bid.current_offer?.id) {
        return null;
      }
      return (
        <RespondToOfferButton
          jobId={job.id}
          offerId={bid.current_offer.id}
          variant="primary"
          size={size}
        />
      );
    case ENUMS.JobFreelancerStatus.LIVE_INTERVIEWING:
      return (
        <ScheduleInterviewButton
          employerUserId={job?.creator_user_id}
          variant="primary"
          size={size}
        />
      );
    case ENUMS.JobFreelancerStatus.AI_INTERVIEWING:
      if (!talentId) return null;

      return (
        <StartAIInterviewButton
          variant="primary"
          jobId={job.id}
          talentId={talentId}
          size={size}
        />
      );

    case ENUMS.JobFreelancerStatus.APPLICATION_REJECTED:
      return (
        <ViewApplicationButton
          jobId={job.id}
          bidId={bid.id}
          variant="secondary"
          size={size}
        />
      );

    case ENUMS.JobFreelancerStatus.JOB_FILLED:
    case ENUMS.JobFreelancerStatus.JOB_CLOSED:
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
      return (
        <>
          <ViewApplicationButton
            jobId={job.id}
            bidId={bid.id}
            variant="secondary"
            size={size}
          />
          <BrowseOpenJobs size={size} />
        </>
      );

    default: {
      assertUnreachable(statusForFreelancer);
      return null;
    }
  }
};

import React from "react";

import type { IconButtonProps as DSIconButtonProps } from "@braintrust/braintrust-ui-components";
import { IconButton } from "@braintrust/braintrust-ui-components";
import { LinkIcon } from "@braintrust/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import type { FreelancerPublicExternalProfile } from "@js/types/common";

import styles from "./style.module.scss";

type SocialProfilesProps = {
  profiles: FreelancerPublicExternalProfile[];
  IconStyle: { width: string | number; height: string | number };
  IconButtonProps?: Omit<DSIconButtonProps, "to" | "href" | "aria-label">;
};

export const SocialProfiles = ({
  profiles,
  IconStyle,
  IconButtonProps,
}: SocialProfilesProps): JSX.Element | null => {
  if (!profiles?.length) {
    return null;
  }

  return (
    <ul className={`${styles.socialProfilesList} list-reset`}>
      {profiles.map(({ id, public_url: url, site }) => {
        return (
          <li key={id} title={site.name} className="social-profiles-list-item">
            <IconButton
              variant="secondary"
              size="medium"
              aria-label={`Open user ${site.name} profile`}
              {...IconButtonProps}
              to={url}
              target="_blank"
              rel="noopener noreferrer"
              RouterLink={RouterLink}
            >
              {site.logo ? (
                <img
                  src={site.logo.thumbnail}
                  alt={site.name}
                  width={IconStyle.width}
                  height={IconStyle.height}
                />
              ) : (
                <LinkIcon titleAccess="Custom link" style={{ fontSize: 23 }} />
              )}
            </IconButton>
          </li>
        );
      })}
    </ul>
  );
};

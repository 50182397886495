import type { ReactNode } from "react";

import {
  Box,
  ClickAwayListener,
  Tooltip,
} from "@braintrust/braintrust-ui-components";
import type { EmployerBidListBid } from "@js/types/jobs";

import { MatchSummaryChipInteractive } from "./match-summary-chip";

export const MatchSummaryTooltip = ({
  children,
  matchLabel,
  open,
  onClose,
  onOpen,
}: {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  children: ReactNode;
  matchLabel: EmployerBidListBid["match_label"];
}) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box>
        <Tooltip
          open={open}
          variant="white"
          placement="right"
          arrow
          onClose={onClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            disablePortal: true,
            sx: {
              zIndex: 1500,
              ".MuiTooltip-tooltip": {
                maxWidth: "unset",
                borderRadius: "24px",
                p: 0,
              },
            },
          }}
          title={
            <Box
              sx={{
                display: "grid",
                p: { xs: 2, sm: 3 },
                width: "23.75rem",
              }}
            >
              {children}
            </Box>
          }
        >
          <span>
            <MatchSummaryChipInteractive
              matchLabel={matchLabel}
              onClick={onOpen}
            />
          </span>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

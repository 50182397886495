import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { ArrowLeftIcon } from "@braintrust/braintrust-ui-components/Icons";
import { CommonConfirmationModal } from "@js/components/modal";
import type { HelpService } from "@js/types/give-and-get-help";

type DeleteServiceModalProps = {
  service: HelpService;
  onDelete: (id: number) => void;
};

export const openDeleteServiceModal = (
  service: HelpService,
  onDelete: (id: number) => void,
) =>
  CommonConfirmationModal.open({
    children: (
      <DeleteServiceModalContent service={service} onDelete={onDelete} />
    ),
  });

const DeleteServiceModalContent = ({
  service,
  onDelete,
}: DeleteServiceModalProps) => {
  const isSmall = useMediaQuery("sm");

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} mb={isSmall ? 3 : 4}>
        <span>
          <IconButton
            variant="tertiary"
            onClick={CommonConfirmationModal.close}
            aria-label="go back"
          >
            <ArrowLeftIcon />
          </IconButton>
        </span>
        <Typography
          variant={isSmall ? "paragraph" : "title"}
          size={isSmall ? "large" : "small"}
          component="h3"
          fontWeight={400}
        >
          Remove service
        </Typography>
      </Box>
      <Typography variant="paragraph" component="p" mb={isSmall ? 3 : 4}>
        Removing your <strong>{service.category.name}</strong> service from your
        profile can’t be undone. Are you sure you want to remove this service?
      </Typography>
      <Box
        display="flex"
        gap={2}
        justifyContent="flex-end"
        flexDirection={isSmall ? "column" : "row"}
      >
        <Button
          variant="black-outlined"
          shape="squared"
          onClick={CommonConfirmationModal.close}
        >
          Keep service
        </Button>
        <Button
          variant="destructive"
          shape="squared"
          onClick={() => {
            onDelete(service.id);
            CommonConfirmationModal.close();
          }}
        >
          Remove service
        </Button>
      </Box>
    </Box>
  );
};

import { Stack } from "@braintrust/braintrust-ui-components";
import { SignUpConfirmationContent } from "@js/apps/onboarding/components/sign-up-confirmation-content";
import { CoreLayout } from "@js/layouts/core";

import styles from "./styles.module.scss";

export const SignUpEmailConfirmPage = () => {
  return (
    <CoreLayout
      pageTitle="Check your email to verify your account."
      className={styles.wrapper}
    >
      <Stack sx={{ maxWidth: "930px", alignItems: "center", px: "20px" }}>
        <SignUpConfirmationContent />
      </Stack>
    </CoreLayout>
  );
};

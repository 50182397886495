import React from "react";

import type {
  BoxProps,
  TypographyProps,
} from "@braintrust/braintrust-ui-components";
import {
  Box,
  IconButton,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

export type CarouselHeaderProps = BoxProps & {
  title: string;
  url?: string;
  urlLabel?: string;
  hideNavigationButtons?: boolean;
  nextRef?: any;
  prevRef?: any;
  titleProps?: Omit<TypographyProps, "component" | "to" | "href">;
};

export const CarouselHeader = ({
  title,
  url,
  urlLabel,
  prevRef,
  nextRef,
  hideNavigationButtons = false,
  titleProps,
  ...rest
}: CarouselHeaderProps) => {
  return (
    <Box
      display="flex"
      alignItems={{ xs: "flex-start", md: "center" }}
      justifyContent="space-between"
      flexDirection={{ xs: "column", md: "row" }}
      {...rest}
    >
      <Box component="span">
        <Typography
          component="h3"
          variant="title"
          size="small"
          fontWeight={400}
          {...titleProps}
        >
          {title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {url && (
          <Typography
            component="link"
            variant="link"
            to={url}
            size="large"
            RouterLink={RouterLink}
          >
            {urlLabel}
          </Typography>
        )}
        <Box>
          <IconButton
            ref={prevRef}
            className="button-prev-slide"
            variant="secondary"
            aria-label="previous"
            hidden={hideNavigationButtons}
            style={{ marginRight: 8 }}
            size="small"
          >
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            ref={nextRef}
            className="button-next-slide"
            variant="secondary"
            aria-label="next"
            hidden={hideNavigationButtons}
            size="small"
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

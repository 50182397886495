import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { useAppDispatch } from "@js/hooks";
import type { StickerValue } from "@js/types/give-and-get-help";

import { selectReaction } from "../store/stickers-module-slice";

import { ReactionsDrawerList } from "./reactions-drawer-list";
import { ReactionsDrawerTabs } from "./reactions-drawer-tabs";
import { useReactionsData } from "./use-reactions-data";

export const ReactionsDrawerContent = () => {
  const { reactions, selectedReaction } = useReactionsData();
  const dispatch = useAppDispatch();

  const handleSelectedReactionChange = (reactionValue: StickerValue) => {
    dispatch(selectReaction(reactionValue));
  };

  const shouldDisplayReactions = !!selectedReaction && !!reactions.length;

  return (
    <Box
      data-testid="reactions-drawer-content"
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {shouldDisplayReactions && (
        <>
          <ReactionsDrawerTabs
            selectedReactionValue={selectedReaction.sticker}
            entityReactions={reactions}
            onReactionChange={handleSelectedReactionChange}
          />
          <ReactionsDrawerList users={selectedReaction.users} />
        </>
      )}
      {!shouldDisplayReactions && <NoReactionsMessage />}
    </Box>
  );
};

const NoReactionsMessage = () => {
  return (
    <Typography
      component="p"
      variant="paragraph"
      size="small"
      sx={{ color: "var(--grey-3)", px: 2.5 }}
    >
      There are no reactions
    </Typography>
  );
};

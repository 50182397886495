import { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import {
  ArrowForwardIcon,
  CloseIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { useGetFreelancerBidsPaginatedQuery } from "@js/apps/freelancer/api";
import { boostBannerClicked, boostBannerViewed } from "@js/apps/jobs/actions";
import { useAppDispatch } from "@js/hooks";
import { usePurchaseUpgrade } from "@js/hooks/purchase-upgrade";
import { LocalStorage } from "@js/services";

export const JobListBoostBanner = () => {
  const user = useUser();

  const { data, isLoading: isLoadingJobsApplied } =
    useGetFreelancerBidsPaginatedQuery({
      ordering: "-created",
      page_size: 1,
      current: true,
    });
  const [showJobListBoostBanner, setShowJobListBoostBanner] = useState(false);
  const { isFreelancer } = useAccountType();
  const { onClickPurchaseUpgrade } = usePurchaseUpgrade("boost");
  const dispatch = useAppDispatch();
  const jobListBoostBannerWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const determineBoostBannerVisibility = async () => {
      if (user?.boost_credit || !isFreelancer || !user?.freelancer_approved) {
        return;
      }

      const dismissTime = LocalStorage.getItem(
        LocalStorage.keys.JOB_LIST_BOOST_BANNER_DISMISS_TIME,
      );
      const noJobsApplied = data && data?.count === 0;

      if (noJobsApplied) {
        setShowJobListBoostBanner(false);
        return;
      }

      if (!dismissTime) {
        setShowJobListBoostBanner(true);
        return;
      }

      const twoHoursLater = new Date(
        parseInt(dismissTime) + 2 * 60 * 60 * 1000,
      );
      setShowJobListBoostBanner(new Date() > twoHoursLater);
    };

    if (!isLoadingJobsApplied) {
      determineBoostBannerVisibility();
    }
  }, [user, isFreelancer, data, isLoadingJobsApplied]);

  useEffect(() => {
    const wrapperRefCurrent = jobListBoostBannerWrapperRef.current;
    if (!wrapperRefCurrent) return;
    if (!showJobListBoostBanner) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            dispatch(boostBannerViewed());
            observer.disconnect();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      },
    );

    observer.observe(wrapperRefCurrent);

    return () => {
      if (wrapperRefCurrent) {
        observer.unobserve(wrapperRefCurrent);
      }
    };
  }, [jobListBoostBannerWrapperRef, showJobListBoostBanner, dispatch]);

  const handleDismiss = () => {
    LocalStorage.setItem(
      LocalStorage.keys.JOB_LIST_BOOST_BANNER_DISMISS_TIME,
      String(new Date().getTime()),
    );
    setShowJobListBoostBanner(false);
  };
  const openModal = async () => {
    dispatch(boostBannerClicked());
    onClickPurchaseUpgrade();
  };

  const isMobile = useMediaQuery("sm");

  if (!showJobListBoostBanner) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      borderRadius={4}
      position="relative"
      sx={{
        border: "1px solid var(--soft-grey)",
      }}
      overflow="auto"
      ref={jobListBoostBannerWrapperRef}
    >
      <IconButton
        size={isMobile ? "medium" : "x-small"}
        variant="transparent"
        aria-label="Dismiss boost banner"
        sx={{
          padding: isMobile ? "7px 6px !important" : "12px",
          position: "absolute",
          right: isMobile ? 0 : 1,
          top: isMobile ? 0 : 2,
        }}
        onClick={handleDismiss}
      >
        <CloseIcon />
      </IconButton>

      <Box display="flex" flex="3">
        <Box
          minHeight={isMobile ? "110px" : "148px"}
          width="100%"
          sx={{
            backgroundColor: "var(--blue)",
            backgroundImage: `url(${SETTINGS.STATIC_URL}jobs/job-list-banner.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: isMobile ? "bottom" : "center",
            backgroundSize: "contain",
          }}
        />
      </Box>

      <Box
        bgcolor="white"
        display="flex"
        flexWrap={isMobile ? "wrap" : "nowrap"}
        alignItems="center"
        justifyContent="space-between"
        gap={1.5}
        p={3}
        flex="5"
      >
        <Box display="flex" flexDirection="column">
          <Typography component="p" size="large" mb={1}>
            Stand out with Application Boost
          </Typography>

          <Typography component="p" size="small">
            Elevate your applications to the top and get noticed by employers.
          </Typography>
        </Box>

        <Box>
          <Button
            onClick={openModal}
            variant="positive"
            sx={{
              height: "40px",
              borderRadius: "10px !important",
              textWrap: "nowrap",
            }}
            endIcon={<ArrowForwardIcon />}
          >
            Get Application Boosts
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

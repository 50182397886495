import type { MouseEventHandler, ReactElement, ReactNode } from "react";

import type { StackProps } from "@braintrust/braintrust-ui-components";
import { Chip, Stack } from "@braintrust/braintrust-ui-components";
import {
  BraintrustIcon,
  KeyboardArrowDownIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import type { EmployerBidListBid } from "@js/types/jobs";

export const MatchSummaryChipInteractive = ({
  matchLabel,
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLElement>;
  matchLabel: EmployerBidListBid["match_label"];
}) => {
  const label = ENUMS.SmartMatchingLabels[matchLabel];
  const { backgroundColor, color, borderColor, icon } =
    getMatchBadgeChipStyles(label);

  return (
    <Chip
      sx={{
        backgroundColor: `${backgroundColor} !important`,
        color: `${color} !important`,
        cursor: "pointer",
        "&:hover, &:focus": {
          boxShadow: `0 0 0 4px ${borderColor}`,
        },
      }}
      onClick={onClick}
      label={
        <BidScoreMatchChipLabel
          icon={icon}
          label={label}
          sx={{
            textDecoration: "underline dashed",
            textUnderlineOffset: "4px",
          }}
        />
      }
      endIcon={
        <KeyboardArrowDownIcon
          sx={{
            height: "16px",
            width: "16px",
            color: `${color} !important`,
          }}
        />
      }
      size="x-small"
    />
  );
};

export const MatchSummaryChip = ({
  matchLabel,
}: {
  matchLabel: EmployerBidListBid["match_label"];
}) => {
  const label = ENUMS.SmartMatchingLabels[matchLabel];
  const { backgroundColor, color, icon } = getMatchBadgeChipStyles(label);

  return (
    <Chip
      sx={{
        backgroundColor: `${backgroundColor} !important`,
        color: `${color} !important`,
      }}
      label={<BidScoreMatchChipLabel icon={icon} label={label} />}
      size="x-small"
    />
  );
};

const BidScoreMatchChipLabel = ({
  icon,
  label,
  sx,
}: {
  icon: ReactNode;
  label: ReactNode;
  sx?: StackProps["sx"];
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        gap: "5px",
        alignItems: "center",
        fontSize: "12px",
        lineHeight: "24px",
        ...sx,
      }}
    >
      {icon}
      {label}
    </Stack>
  );
};

const MATCH_SCORE_CHIP_STYLES: Record<
  EnumType<typeof ENUMS.SmartMatchingLabels>,
  {
    color: string;
    backgroundColor: string;
    borderColor: string;
    icon?: ReactElement;
  }
> = {
  [ENUMS.SmartMatchingLabels.recommended_by_matcher]: {
    color: "var(--white)",
    backgroundColor: "var(--black)",
    borderColor: "var(--grey-4)",
    icon: (
      <BraintrustIcon
        style={{
          height: "16px",
          width: "16px",
        }}
      />
    ),
  },
  [ENUMS.SmartMatchingLabels.great]: {
    color: "var(--white)",
    backgroundColor: "var(--positive-2)",
    borderColor: "var(--positive-3)",
  },
  [ENUMS.SmartMatchingLabels.good]: {
    color: "var(--info-1)",
    backgroundColor: "var(--blue)",
    borderColor: "var(--soft-blue)",
  },
  [ENUMS.SmartMatchingLabels.ok]: {
    color: "var(--dark-yellow)",
    backgroundColor: "var(--warning-2)",
    borderColor: "var(--soft-orange)",
  },
  [ENUMS.SmartMatchingLabels.bad]: {
    color: "var(--negative-1)",
    backgroundColor: "var(--medium-red)",
    borderColor: "var(--soft-red)",
  },
  [ENUMS.SmartMatchingLabels.unavailable]: {
    color: "transparent",
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
};

const getMatchBadgeChipStyles = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabels>,
) => {
  return MATCH_SCORE_CHIP_STYLES[matchLabel];
};

import React from "react";
import cx from "classnames";

import {
  Box,
  ButtonCore,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { CheckIcon } from "@braintrust/braintrust-ui-components/Icons";

import style from "./style.module.scss";

export type VisibilityOption = {
  icon: JSX.Element;
  label: string;
  description: string;
  value: boolean;
  iconColor: { selected: string; notSelected: string };
};

type VisibilitySettingProps = Omit<VisibilityOption, "value"> & {
  selected: boolean;
  disabled: boolean;
  onClick?: () => void;
};

export const VisibilitySetting = ({
  icon,
  label,
  description,
  selected,
  disabled,
  iconColor,
  onClick,
}: VisibilitySettingProps) => {
  return (
    <Box
      component={ButtonCore}
      onClick={onClick}
      className={cx(style.jobVisibilitySetting, {
        "button-shape--squared": true,
        "selected-as-hover": selected,
      })}
      disabled={disabled}
    >
      <Box display="flex" alignItems="center">
        <Box mr={1} color={selected ? iconColor.selected : "inherit"}>
          {icon}
        </Box>
        <Typography component="p" variant="label" size="small">
          {label}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography component="p" variant="paragraph" size="small">
          {description}
        </Typography>
        {selected && <CheckIcon sx={{ ml: 1, fontSize: 21 }} />}
      </Box>
    </Box>
  );
};

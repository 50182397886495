import {
  type ChangeEvent,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  Suspense,
} from "react";

import { Box, Checkbox, Loader } from "@braintrust/braintrust-ui-components";

import { FreelancerDashboardLayout } from "./layout";

import styles from "./listings-content-wrapper.module.scss";

type CheckboxProps = {
  checkboxText: string;
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
};

type MyWorkListingPageProps = {
  pageTitle: string;
  checkboxProps: CheckboxProps;
  children: ReactNode;
};

export const MyWorkListingPage = ({
  pageTitle,
  checkboxProps,
  children,
}: MyWorkListingPageProps) => {
  const { checkboxText, isChecked, setIsChecked } = checkboxProps;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <FreelancerDashboardLayout
        pageTitle={pageTitle}
        contentClassName="dashboard-layout-content"
      >
        <Box gap={3} display="flex" flexDirection="column">
          <Box>
            <Checkbox
              checked={isChecked}
              label={checkboxText}
              onChange={handleCheckboxChange}
              checkedColor="yellow"
            />
          </Box>
          <Box className={styles.wrapper}>
            <Suspense fallback={<Loader centered />}>{children}</Suspense>
          </Box>
        </Box>
      </FreelancerDashboardLayout>
    </>
  );
};

import { useCallback, useMemo } from "react";
import { Field, SubmissionError } from "redux-form";

import {
  Box,
  Button,
  Loader,
  Stack,
  useMediaQuery,
} from "@braintrust/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import {
  useOptInIntoReferClientProgramMutation,
  useSendConnectorSignupDataMutation,
} from "@js/apps/auth/api";
import { useUser } from "@js/apps/common/hooks";
import { useGetFreelancerExternalProfilesQuery } from "@js/apps/freelancer/api";
import { openDecoratedSuccessSnackbar } from "@js/components/decorated-success-snackbar";
import { createFormInstance } from "@js/forms/components";
import { TextField } from "@js/forms/fields/text";
import { required } from "@js/forms/validators";
import { useAppDispatch, useAppSelector } from "@js/hooks";

type JoinClientsReferralProgramFormProps = {
  onSubmitSuccess: () => void;
};

export const JoinClientsReferralProgramForm = ({
  onSubmitSuccess,
}: JoinClientsReferralProgramFormProps) => {
  const isMobile = useMediaQuery("sm");
  const user = useUser();
  const dispatch = useAppDispatch();
  const { data: externalProfiles, isLoading: isLoadingExternalProfiles } =
    useGetFreelancerExternalProfilesQuery();

  const linkedInProfile = useMemo(
    () =>
      externalProfiles?.find(
        (externalProfile) =>
          externalProfile.id === SETTINGS.QUESTIONNAIRE_LINKEDIN_SITE_ID,
      ),
    [externalProfiles],
  );
  const isFetchingCurrentUser = useAppSelector(
    (state) => state.auth.fetchingCurrentUser,
  );

  const [sendConnectorSignupData] = useSendConnectorSignupDataMutation();
  const [
    optInIntoReferClientProgram,
    { isLoading: loadingOptInIntoReferProgram },
  ] = useOptInIntoReferClientProgramMutation();

  const handleOnSubmitSuccess = useCallback(async () => {
    sendConnectorSignupData({ page_name: "Earn" });

    openDecoratedSuccessSnackbar(
      "Your LinkedIn URL has been successfully shared.",
    );

    onSubmitSuccess();
  }, [onSubmitSuccess, sendConnectorSignupData]);

  const isSubmitting = useMemo(() => {
    return loadingOptInIntoReferProgram || isFetchingCurrentUser || !user;
  }, [loadingOptInIntoReferProgram, isFetchingCurrentUser, user]);

  const onSubmit = async (values: ClientReferralProgramFormValues) => {
    return optInIntoReferClientProgram(values)
      .unwrap()
      .then(() => {
        return dispatch(fetchCurrentUser());
      })
      .catch((error) => {
        throw new SubmissionError(error?.data);
      });
  };

  const isLoading = isLoadingExternalProfiles || !externalProfiles;

  return (
    <JoinClientReferralProgramForm
      onSubmit={onSubmit}
      onSubmitSuccess={handleOnSubmitSuccess}
      initialValues={{
        refer_client_program_linkedin_url:
          linkedInProfile?.public_url ?? undefined,
      }}
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <Stack
          direction={isMobile ? "column" : "row"}
          sx={{ alignItems: "flex-start", flexWrap: "nowrap", gap: 1.5 }}
        >
          <Field
            label="Your LinkedIn URL"
            validate={[required]}
            id="refer_client_program_linkedin_url"
            name="refer_client_program_linkedin_url"
            component={TextField}
            sx={{ maxWidth: "23rem" }}
          />
          <Box pt={isMobile ? "0" : "36px"}>
            <Button
              variant="primary"
              type="submit"
              sx={{ textWrap: "nowrap" }}
              disabled={isSubmitting}
            >
              Share
            </Button>
          </Box>
        </Stack>
      )}
    </JoinClientReferralProgramForm>
  );
};

export type ClientReferralProgramFormValues = {
  refer_client_program_linkedin_url: string;
};

const JoinClientReferralProgramForm = createFormInstance<
  ClientReferralProgramFormValues,
  unknown
>("joinClientReferralProgramForm", {
  touchOnBlur: false,
  enableReinitialize: true,
});

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import type { WrappedFieldsProps } from "redux-form";
import { Field, Fields } from "redux-form";

import type { FormValue } from "@braintrust/braintrust-ui-components";
import {
  Box,
  Typography,
  useButtonSelect,
} from "@braintrust/braintrust-ui-components";
import { Date } from "@js/components/date";
import { CustomOptionTileButton } from "@js/components/option-tile-buttons";
import { DatePopoverField } from "@js/forms/fields";
import { shouldDisplayError } from "@js/forms/utils";
import { required } from "@js/forms/validators";
import { deepClone } from "@js/utils";
import { DateFormats } from "@js/utils/date/types";

import { FieldSectionPanelWithImportance } from "../field-section-panel";

type AvailabilityValue = EnumType<typeof ENUMS.FreelancerAvailability>;

type AvailabilityOptionType = {
  label: string | JSX.Element;
  value: AvailabilityValue;
};

const AVAILABILITY_OPTIONS: AvailabilityOptionType[] = [
  { label: "⚡️ Right away", value: ENUMS.FreelancerAvailability.IMMEDIATELY },
  {
    label: "⏱ Two weeks after offer",
    value: ENUMS.FreelancerAvailability.TWO_WEEKS_FROM_OFFER,
  },
  {
    label: "🗓 After a specific date",
    value: ENUMS.FreelancerAvailability.SPECIFIC_DATE,
  },
];

export const AvailabilityFieldContainer = () => {
  return (
    <Fields
      component={AvailabilityTileField}
      names={[
        "freelancer_availability",
        "freelancer_availability_specific_date",
      ]}
      validate={{ freelancer_availability: required }}
    />
  );
};

export const AvailabilityTileField = ({
  freelancer_availability,
  freelancer_availability_specific_date,
}: WrappedFieldsProps) => {
  const availability = freelancer_availability;
  const date = freelancer_availability_specific_date;
  const location = useLocation();
  const isNewPage = location.pathname.includes("new");

  const error = availability.meta.error || date.meta.error;

  const handleClick = (value: AvailabilityValue): void => {
    availability.input.onChange(value);
  };

  const isCustomOptionSelected =
    availability.input.value === ENUMS.FreelancerAvailability.SPECIFIC_DATE &&
    !!date.input.value;

  useEffect(() => {
    if (
      availability.input.value !== ENUMS.FreelancerAvailability.SPECIFIC_DATE
    ) {
      date.input.onChange(isNewPage ? undefined : null);
    }
  }, [availability.input.value, date.input, isNewPage]);

  const clearSpecificDate = () => {
    if (
      availability.input.value === ENUMS.FreelancerAvailability.SPECIFIC_DATE &&
      !date.input.value
    ) {
      availability.input.onChange(undefined);
    }
  };

  const displayError = shouldDisplayError([availability, date]);

  return (
    <FieldSectionPanelWithImportance
      title="When are you available to start?"
      isError={displayError}
      fieldImportance="required"
    >
      <AvailabilityTileButtons
        value={availability.input.value}
        clearSpecificDate={clearSpecificDate}
        isCustomOptionSelected={isCustomOptionSelected}
        specificDate={date.input.value}
        onChange={(availabilityValue) =>
          handleClick(availabilityValue as AvailabilityValue)
        }
        options={AVAILABILITY_OPTIONS}
      />

      {displayError && error && (
        <Typography
          component="p"
          error
          color="negative"
          key={error}
          textAlign="left"
          sx={{ mt: 2 }}
        >
          {error}
        </Typography>
      )}
    </FieldSectionPanelWithImportance>
  );
};

type AvailabilityTileButtonsProps = {
  value: AvailabilityValue;
  onChange: (value: FormValue) => void;
  options: AvailabilityOptionType[];
  isCustomOptionSelected: boolean;
  specificDate: string;
  clearSpecificDate: () => void;
};

export const AvailabilityTileButtons = ({
  value,
  onChange,
  options,
  isCustomOptionSelected,
  specificDate,
  clearSpecificDate,
}: AvailabilityTileButtonsProps) => {
  const { isSelected, handleChange, itemA11yProps, containerA11yProps } =
    useButtonSelect({
      value,
      onChange,
    });

  return (
    <Box display="flex" gap={1} flexWrap="wrap" {...containerA11yProps}>
      {options.map((option, index) => {
        const _isSelected = isSelected(option);

        const key = typeof option.value !== "boolean" ? option.value : index;
        const commonProps = {
          ...itemA11yProps(_isSelected),
          sx: { width: "fit-content !important" },
          handleChange,
        };

        if (option.value === ENUMS.FreelancerAvailability.SPECIFIC_DATE) {
          const _option = deepClone(option);

          if (_isSelected && !!specificDate) {
            _option.label = (
              <>
                🗓 After{" "}
                <Date
                  date={specificDate}
                  format={DateFormats["January 1, 1970"]}
                />
              </>
            );
          }

          return (
            <Field
              key={option.value}
              name="freelancer_availability_specific_date"
              Anchor={CustomOptionTileButton}
              onClose={clearSpecificDate}
              anchorProps={{
                ...commonProps,
                onClick: () => {
                  handleChange(option);
                },
                option: _option,
                isSelected: isCustomOptionSelected,
              }}
              component={DatePopoverField}
              disablePast
              showDaysOutsideCurrentMonth
            />
          );
        }

        return (
          <CustomOptionTileButton
            variant="white-violet"
            key={key}
            {...commonProps}
            option={option}
            isSelected={_isSelected}
          />
        );
      })}
    </Box>
  );
};

import type { FC } from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import type { PurchaseUpgradeParams } from "@js/apps/auth/api";
import { Emoji } from "@js/components/emoji";

import { useHeaderSection } from "./hooks/use-header-section";

type HeaderSectionProps = {
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const HeaderSection: FC<HeaderSectionProps> = ({ upgradeType }) => {
  const {
    backgroundColor,
    productBackgroundImage,
    productImage,
    productImageSx,
    showBoostBanner,
    subText,
    title,
  } = useHeaderSection({ upgradeType });

  return (
    <Box
      paddingTop={{ xs: 5, md: 6 }}
      position="relative"
      bgcolor={backgroundColor}
    >
      <Box
        position="absolute"
        top={0}
        left={{ xs: 0, md: -37 }}
        sx={{ "& img": { width: { xs: "390px", md: "372px" } } }}
      >
        <img
          width="auto"
          height="auto"
          src={productBackgroundImage}
          alt="product-bg"
        />
      </Box>
      <Box
        zIndex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 0.5, md: 3 }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <img
          width="auto"
          height="29px"
          src={`${SETTINGS.STATIC_URL}logo/logo.svg`}
          alt="logo"
        />
        <Box
          display={{ xs: "none", md: "block" }}
          height="32.5px"
          border="0.5px solid var(--black)"
        />
        <Typography
          component="h1"
          variant="title"
          size="small"
          fontWeight={400}
          textTransform="capitalize"
        >
          {title}
        </Typography>
      </Box>
      <Box
        zIndex={1}
        position="relative"
        mt={2}
        mx="auto"
        px={{ xs: 1, md: 0 }}
        maxWidth="579px"
        gap={{ xs: 1, md: 0 }}
      >
        <Typography
          component="p"
          variant="label"
          fontWeight={500}
          textAlign="center"
        >
          {subText}
        </Typography>
      </Box>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          ...productImageSx,
        }}
      >
        <img
          width="100%"
          height="auto"
          src={productImage}
          alt="product-preview"
        />
      </Box>
      {showBoostBanner && (
        <Box
          py={2.2}
          px={{ xs: 1, md: 0 }}
          display="flex"
          gap="14px"
          justifyContent="center"
          alignItems="center"
          flexDirection={{ xs: "column", md: "row" }}
          bgcolor="var(--medium-blue)"
          sx={{
            borderTopLeftRadius: 18,
            borderTopRightRadius: 18,
          }}
        >
          <Box
            bgcolor="var(--blue)"
            display="flex"
            py="4.5px"
            px="10px"
            borderRadius="22px"
          >
            <Emoji size="21px" emoji="👀" />
          </Box>
          <Typography
            component="p"
            textAlign="center"
            fontWeight={500}
            sx={{ color: "var(--dark-blue)" }}
          >
            Boosted talent is 3x more likely to get interviewed or hired.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

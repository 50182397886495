import React from "react";
import cs from "classnames";

import type { TypographyProps } from "@braintrust/braintrust-ui-components";
import { Typography } from "@braintrust/braintrust-ui-components";
import { FlowerIcon } from "@braintrust/braintrust-ui-components/Icons";
import { Emoji } from "@js/components/emoji";

import {
  mapErrorCodeToUserError,
  mapMessageCodeToUserMessage,
} from "../../utils";

import styles from "./style.module.scss";

type OwnProps = {
  title: string;
  subTitle?: string;
  titleEmoji?: string;
  messageCode?: string;
  errorCode?: string;
  className?: string;
  invitationError?: boolean;
  isInIframe?: boolean;
  titleSize?: TypographyProps["size"];
};

export const AuthComponent: React.FC<React.PropsWithChildren<OwnProps>> = ({
  children,
  className,
  errorCode,
  invitationError,
  isInIframe,
  messageCode,
  title,
  subTitle,
  titleEmoji,
  titleSize = "small",
}) => {
  return (
    <div className={cs(styles.authForm, className)}>
      {!isInIframe && (
        <>
          {titleEmoji && <AuthEmojiTitle emoji={titleEmoji} />}
          <Typography
            component="h1"
            variant="title"
            size={titleSize}
            fontWeight={400}
            mb={subTitle ? 2 : 5}
          >
            {title}
          </Typography>

          {subTitle && (
            <Typography component="p" size="large" fontWeight={400} mb={4}>
              {subTitle}
            </Typography>
          )}
        </>
      )}
      <div>
        {(errorCode || messageCode) && (
          <div className={cs("mt0 mb+")}>
            {mapErrorCodeToUserError(errorCode) ||
              mapMessageCodeToUserMessage(messageCode)}
          </div>
        )}
        {!!invitationError && (
          <div className="mt0 mb+ color-warning">{invitationError}</div>
        )}
        {children}
      </div>
    </div>
  );
};

const AuthEmojiTitle = ({ emoji }: { emoji: string }) => {
  return (
    <div className={styles.authTitleEmojiWrapper}>
      <FlowerIcon />
      <Emoji emoji={emoji} size="26px" />
    </div>
  );
};

import { Button } from "@braintrust/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export const EditOfferButton = ({
  jobId,
  offerId,
}: {
  jobId: number;
  offerId: number;
}) => (
  <Button
    variant="primary"
    sx={{ padding: "12px 24px !important" }}
    shape="squared"
    size="medium"
    to={`/jobs/${jobId}/offers/${offerId}/edit`}
    RouterLink={RouterLink}
  >
    Edit offer
  </Button>
);

import type { WrappedFieldsProps } from "redux-form";
import { Field, Fields } from "redux-form";

import {
  Box,
  Divider,
  SwipeableDrawer,
  Typography,
  useDrawer,
} from "@braintrust/braintrust-ui-components";
import { getError, isError, isTouched } from "@js/forms/utils";

import { InfoTag } from "../../info-tag";

import { RequirementLocationCardField } from "./requirement-cards/location-card-field";
import { RequirementTimezoneCardField } from "./requirement-cards/timezone-card-field";
import { LocationAndTimezoneDrawerContent } from "./drawer-content";

export const LocationAndTimezoneSection = ({
  locations,
  timezones,
  timezone_overlap,
}: WrappedFieldsProps) => {
  const { isOpen, toggleDrawer } = useDrawer();

  const fields = [locations, timezones, timezone_overlap];
  const isAnyTouched = isTouched(fields);
  const isErrorInSection = isError(fields);

  return (
    <Box>
      <Divider color="beige" sx={{ mt: 5 }} />

      <Box
        display="flex"
        my={2}
        rowGap={2}
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Typography id="location-label" component="label" sx={{ my: "auto" }}>
          Any location or time zone requirements?
        </Typography>
      </Box>

      {isErrorInSection && isAnyTouched && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {getError(fields)}
        </Typography>
      )}

      <Fields
        names={["is_ai_generated"]}
        component={InfoTag}
        message="Please make any selections here if you have specific location or timezone requirements."
      />

      <Box>
        <Field
          name="locations"
          component={RequirementLocationCardField}
          toggleDrawer={toggleDrawer}
        />
      </Box>

      <Box mt={3}>
        <Field
          name="timezones"
          component={RequirementTimezoneCardField}
          toggleDrawer={toggleDrawer}
        />
      </Box>

      <SwipeableDrawer
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        anchor="right"
        ModalProps={{
          keepMounted: false,
        }}
      >
        <LocationAndTimezoneDrawerContent toggleDrawer={toggleDrawer} />
      </SwipeableDrawer>
    </Box>
  );
};

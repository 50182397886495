import { Typography } from "@braintrust/braintrust-ui-components";

export const TeamMemberInvitationsInviteTag = () => {
  return (
    <Typography
      component="span"
      variant="paragraph"
      size="small"
      color="secondary"
      sx={{
        display: "inline-block",
        backgroundColor: "var(--medium-grey)",
        padding: 0.5,
        borderRadius: 1,
        fontSize: "10px !important",
        lineHeight: "normal !important",
      }}
    >
      Invited
    </Typography>
  );
};

import JobSvg from "@static/employer/job.svg";

import { Box } from "@braintrust/braintrust-ui-components";
import { FlowerIcon } from "@braintrust/braintrust-ui-components/Icons";

import { Item } from "./item";

import styles from "./styles.module.scss";

export const Job = () => {
  return (
    <Item>
      <Item.GreenBox>
        <Item.Title text="1. Post a job" />
        <JobIllustration />
      </Item.GreenBox>
      <Item.Description header="AI Job Description Generator">
        Automatically generate compelling job postings with AI and attract top
        talent effortlessly.
      </Item.Description>
    </Item>
  );
};

const JobIllustration = () => {
  return (
    <>
      <JobSvg className={styles.job} />
      <Box position="absolute" top={60} right={42} zIndex={1}>
        <Box position="relative">
          <FlowerIcon className={styles.jobIcon} />
          <img
            src={`${SETTINGS.STATIC_URL}employer/hand.png`}
            className={styles.handIcon}
            width="40px"
            height="40px"
            alt=""
          />
        </Box>
      </Box>
    </>
  );
};

import { Box } from "@braintrust/braintrust-ui-components";
import { PublicTopNavigation } from "@js/components/public-top-navigation";
import { AppLayout } from "@js/layouts/app";

import {
  PublicJobs,
  PublicJobsListingClientsCarousel,
  PublicJobsListingHeader,
} from "../components";

import styles from "./public-listing.module.scss";

export const PublicJobsListingPage = () => {
  return (
    <AppLayout
      pageTitle="Jobs"
      bgcolor={`var(--soft-blue)`}
      renderOverwriteNavigationComponent={() => <PublicTopNavigation />}
      className={styles.publicJobsPage}
    >
      <Box sx={{ width: "100%" }}>
        <PublicJobsListingHeader />
        <PublicJobsListingClientsCarousel />
        <PublicJobs />
      </Box>
    </AppLayout>
  );
};

import { useMemo } from "react";

import type { CarouselProps } from "@braintrust/braintrust-ui-components";
import { Carousel } from "@braintrust/braintrust-ui-components";
import {
  CLIENT_TESTIMONIALS,
  USER_TESTIMONIALS,
} from "@js/apps/onboarding/constants";
import { useUserCommentsCarousel } from "@js/apps/onboarding/hooks/";
import { useNewClientSignUpInvitation } from "@js/apps/onboarding/hooks/new-client-signup-invitation";

import { ClientTestimonialSlide } from "./client-testimonial-slide";
import { UserTestimonialSlide } from "./user-testimonial-slide";

import style from "./style.module.scss";

export const SignUpTestimonialsCarousel = () => {
  const { isClientInvitation } = useNewClientSignUpInvitation();

  const isClientSignUpFlow = isClientInvitation;

  if (isClientSignUpFlow) {
    return <ClientTestimonialsCarousel />;
  }

  return <UserTestimonialsCarousel />;
};

export const UserTestimonialsCarousel = () => {
  const [initSlide, handleSlideChange] = useUserCommentsCarousel();

  const slides = useMemo(() => {
    return USER_TESTIMONIALS.map(({ id, ...slide }) => (
      <UserTestimonialSlide key={id} {...slide} />
    ));
  }, []);

  return (
    <CarouselWithDefaults
      initialSlide={initSlide}
      onSlideChange={handleSlideChange}
      slides={slides}
    />
  );
};

export const ClientTestimonialsCarousel = () => {
  const [initSlide, handleSlideChange] = useUserCommentsCarousel();

  const slides = useMemo(() => {
    return CLIENT_TESTIMONIALS.map(({ id, ...slide }) => (
      <ClientTestimonialSlide key={id} {...slide} />
    ));
  }, []);

  return (
    <CarouselWithDefaults
      initialSlide={initSlide}
      onSlideChange={handleSlideChange}
      slides={slides}
    />
  );
};

type CarouselWithDefaultsProps = Pick<
  CarouselProps,
  "initialSlide" | "onSlideChange" | "slides"
>;

const CarouselWithDefaults = ({
  initialSlide,
  onSlideChange,
  slides,
}: CarouselWithDefaultsProps) => {
  return (
    <Carousel
      className={style.testimonialsCarousel}
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      loop
      grabCursor
      initialSlide={initialSlide}
      onSlideChange={onSlideChange}
      pagination={{ clickable: true }}
      slides={slides}
      spaceBetween={36}
    />
  );
};

import type { ReactNode } from "react";

import { Stack } from "@braintrust/braintrust-ui-components";

type ReferralsStatsContainerProps = {
  children: ReactNode;
  color: "green" | "violet";
};

export const ReferralsStatsContainer = ({
  children,
  color,
}: ReferralsStatsContainerProps) => {
  return (
    <Stack
      sx={{
        borderRadius: 2,
        mx: { xs: -2, sm: 0 },
        p: { xs: 2, md: "43px" },
        bgcolor: `var(--soft-${color})`,
      }}
    >
      {children}
    </Stack>
  );
};

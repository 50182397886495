import { Typography } from "@braintrust/braintrust-ui-components";

export const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      component="h2"
      color="primary"
      variant="label"
      mb={2}
      size="medium"
    >
      {title}
    </Typography>
  );
};

import type { MouseEvent } from "react";
import { useState } from "react";
import type { DecoratedFormProps } from "redux-form";
import { change, Field, reduxForm } from "redux-form";
import classNames from "classnames";

import {
  Button,
  Grid,
  Popover,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { NumberField, TextField } from "@js/forms/fields";
import { maxLength, minValue } from "@js/forms/validators";
import type { AppDispatch } from "@js/store";

import styles from "./style.module.scss";

const minValueValidator = minValue(0);
const maxTaxNameLength = maxLength(64);
const maxTaxRateLength = maxLength(10);

type TaxPopoverComponentOwnProps = {
  buttonText: string | undefined;
  taxRate: string | undefined;
};

type TaxPopoverProps = TaxPopoverComponentOwnProps &
  Pick<DecoratedFormProps, "submit" | "submitting" | "reset" | "valid">;

const TaxPopover = ({
  buttonText,
  reset,
  submit,
  submitting,
  taxRate,
  valid,
}: TaxPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleApplyClick = () => {
    if (valid && !submitting) {
      submit?.();
      handleClose();
    }
  };

  const getButtonLabel = () => {
    return buttonText || taxRate
      ? `+${buttonText} ${taxRate && `${taxRate}%`}`
      : "Add Tax";
  };

  return (
    <>
      <Popover
        anchor={
          <button
            type="button"
            aria-label={taxRate ? `tax rate: ${getButtonLabel()}` : "Add tax"}
            className={classNames("link btn-reset", styles.taxPopoverButton, {
              [styles.taxPopoverHasTax]: taxRate,
            })}
            onClick={handleClick}
          >
            {getButtonLabel()}
          </button>
        }
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionProps={{
          onEnter: reset,
        }}
      >
        <div className={styles.taxPopoverContent}>
          <Typography
            component="h1"
            variant="title"
            fontWeight={400}
            size="small"
            mb={1}
          >
            Add Tax
          </Typography>
          <Grid container spacing={3} alignItems="end">
            <Grid item xs={4}>
              <Field
                variant="standard"
                label="Rate"
                validate={[minValueValidator, maxTaxRateLength]}
                id="tax_rate"
                name="tax_rate"
                component={NumberField}
                size="small"
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                variant="standard"
                label="Tax Name"
                size="small"
                id="tax_name"
                name="tax_name"
                validate={[maxTaxNameLength]}
                component={TextField}
              />
            </Grid>
          </Grid>
          <div className={styles.taxPopoverActions}>
            <Button variant="primary" onClick={handleApplyClick}>
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

type TaxPopoverFormValues = {
  tax_rate: string | undefined;
  tax_name: string | undefined;
};

type TaxPopoverOwnProps = TaxPopoverComponentOwnProps & {
  item: string;
  formName: string;
};

export const TaxPopoverField = reduxForm<
  TaxPopoverFormValues,
  TaxPopoverOwnProps
>({
  // unique form id is provided directly by props
  enableReinitialize: true,
  onSubmit: (
    values: TaxPopoverFormValues,
    dispatch: AppDispatch,
    props: DecoratedFormProps<TaxPopoverFormValues, TaxPopoverOwnProps, string>,
  ) => {
    // we need to refer to `props.formName`; props.change seems to refer to different form
    dispatch(change(props.formName, `${props.item}.tax_name`, values.tax_name));
    dispatch(change(props.formName, `${props.item}.tax_rate`, values.tax_rate));
  },
})(TaxPopover);

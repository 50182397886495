import { Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components";

type TeamMemberInvitationsOptionLabelProps = {
  publicName: string;
  isInvited?: boolean;
};

export const TeamMemberInvitationsOptionLabel = ({
  publicName,
  isInvited,
}: TeamMemberInvitationsOptionLabelProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Typography
      component="h5"
      variant="label"
      size={isMobile ? "small" : "medium"}
      ellipsis={true}
      sx={{ overflow: "hidden", color: isInvited ? "var(--grey-2)" : null }}
    >
      {publicName}
    </Typography>
  );
};

import React, { Component } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import type { WrappedFieldProps } from "redux-form";
import _ from "underscore";

import type { SelectProps } from "@braintrust/braintrust-ui-components";
import { fetchIndustries } from "@js/apps/common/actions";
import { SelectField } from "@js/forms/fields";
import type { AppDispatch, RootState } from "@js/store";

type IndustrySelectFieldReduxProps = ConnectedProps<typeof connector> &
  WrappedFieldProps &
  Omit<SelectProps, "input" | "options" | "ref">;

class IndustrySelectField extends Component<IndustrySelectFieldReduxProps> {
  componentDidMount() {
    this.props.fetchIndustries();
  }

  render() {
    const { industries, ...props } = this.props;

    return (
      <SelectField
        {..._.omit(props, "fetchIndustries")}
        options={industries.map(({ id, name }) => ({ label: name, value: id }))}
      />
    );
  }
}

const connector = connect(
  (state: RootState) => ({
    industries: state.common.industries,
  }),
  (dispatch: AppDispatch) => ({
    fetchIndustries: () => dispatch(fetchIndustries()),
  }),
);

export default connector(IndustrySelectField);

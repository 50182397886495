import { Box } from "@braintrust/braintrust-ui-components";
import { useGetSkillById } from "@js/apps/skills/hooks";

import { SelectableSkillItem } from "../selectable-skill-item";

import styles from "./selected-skills.module.scss";

export type SelectedSkillsProps = {
  skillsValue: number[];
  toggleSkill: (skillId: number) => void;
};

export const SelectedSkills = ({
  skillsValue,
  toggleSkill,
}: SelectedSkillsProps) => {
  if (!skillsValue.length) {
    return <></>;
  }

  return (
    <Box display="flex" gap={1} className={styles.container}>
      {skillsValue.map((skillId) => {
        return (
          <SelectedSkillItem
            key={skillId}
            skillId={skillId}
            toggleSkill={toggleSkill}
          />
        );
      })}
    </Box>
  );
};

type SelectedSkillItemProps = {
  skillId: number;
  toggleSkill: (skillId: number) => void;
};
const SelectedSkillItem = ({
  skillId,
  toggleSkill,
}: SelectedSkillItemProps) => {
  const { data: skill } = useGetSkillById({ id: skillId });
  if (!skill) {
    return <></>;
  }

  return (
    <SelectableSkillItem
      key={skill.id}
      skill={skill}
      handleClick={toggleSkill}
      selected
    />
  );
};

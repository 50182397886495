import React, { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Field,
  getFormSyncErrors,
  hasSubmitFailed,
  isSubmitting,
  setSubmitFailed,
  setSubmitSucceeded,
} from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";

import {
  Box,
  Button,
  FormHelperText,
  Loader,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useFreelancerId } from "@js/apps/common/hooks";
import { useGetFreelancerPublicProfileQuery } from "@js/apps/freelancer/api";
import { nextStepClicked } from "@js/apps/give-and-get-help/actions";
import type { PostFooterButtonType } from "@js/apps/give-and-get-help/components/footer-action-buttons-section/single-buttons";
import {
  AddFileButton,
  AddImageButton,
  AddLinkButton,
  AddPollButton,
} from "@js/apps/give-and-get-help/components/footer-action-buttons-section/single-buttons";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import type { CreateOrEditPostMode } from "@js/apps/give-and-get-help/types";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useResourcesContext } from "../../context";
import type {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "../../form/constants";
import { useCheckForSelfPromotion } from "../../hooks/use-check-for-self-promotion";
import { CreateOrEditPostErrorSection } from "../create-or-edit-post-error-section";
import { CreateOrEditPostWarningSection } from "../create-or-edit-post-warning-section";
import type { PostFooterAction } from "../footer-action-buttons-section";
import { CreateOrEditPostFooterActionsButtonsSection } from "../footer-action-buttons-section";

import styles from "./styles.module.scss";

const FooterWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Box className={styles.footerWrapper}>{children}</Box>;
};

export type CreateOrEditPostStepOneFooterProps = {
  mode: CreateOrEditPostMode;
  onNextClick: () => void;
  formId: typeof CREATE_POST_FORM_ID | typeof EDIT_POST_FORM_ID;
  onFirstStepFail: (errors: string[]) => void;
  isLinkFieldOpen: boolean;
  disablePoll: boolean | undefined;
};

export const CreateOrEditPostStepOneFooter = ({
  onNextClick,
  formId,
  onFirstStepFail,
  mode,
  isLinkFieldOpen,
  disablePoll,
}: CreateOrEditPostStepOneFooterProps) => {
  const [showErrorSection, setShowErrorSection] = useState(false);
  const [hideWarning, setHideWarning] = useState(false);
  const syncErrorsObj = useAppSelector((state) =>
    getFormSyncErrors(formId)(state),
  );
  const dispatch = useAppDispatch();
  const location = usePostLocationContext();
  const {
    checkPostForSelfPromotion,
    isCheckInProgress,
    clearWarning,
    ignoreWarning,
    isWarningIgnored,
  } = useCheckForSelfPromotion(formId);

  const handleOnNextClick = async () => {
    dispatch(
      nextStepClicked({
        mode,
        composer_location: location,
      }),
    );

    const fieldsWithError = Object.keys(syncErrorsObj);

    if (fieldsWithError.length !== 0) {
      dispatch(setSubmitFailed(formId)); // we want to show result of the validation
      onFirstStepFail(fieldsWithError);
      setShowErrorSection(true);
      return;
    }

    const selfPromotion = !isWarningIgnored
      ? await checkPostForSelfPromotion()
      : false;

    if (selfPromotion) {
      dispatch(setSubmitFailed(formId)); // we want to show result of the validation
      onFirstStepFail(fieldsWithError);
      setShowErrorSection(true);
      ignoreWarning();
      return;
    }

    dispatch(setSubmitSucceeded(formId)); // reset to previous state
    return onNextClick();
  };

  const hasMissingTitleOrText =
    "text" in syncErrorsObj || "title" in syncErrorsObj;
  const hasPendingUploads = "attachments" in syncErrorsObj;
  const errorMessage = useMemo(() => {
    if (hasMissingTitleOrText) {
      return "Please complete your post before proceeding";
    }

    if (hasPendingUploads) {
      return "Please wait, still uploading...";
    }

    return;
  }, [hasPendingUploads, hasMissingTitleOrText]);

  const buttonsToRender = useMemo(() => {
    return [
      POST_FOOTER_ACTIONS_TO_BUTTON_NAME_AND_COMPONENT_MAP.POST_FILE,
      POST_FOOTER_ACTIONS_TO_BUTTON_NAME_AND_COMPONENT_MAP.POST_IMAGE,
      POST_FOOTER_ACTIONS_TO_BUTTON_NAME_AND_COMPONENT_MAP.POST_LINK,
      !disablePoll
        ? POST_FOOTER_ACTIONS_TO_BUTTON_NAME_AND_COMPONENT_MAP.POST_POLL
        : null,
    ].filter(
      (buttonKey): buttonKey is PostFooterButtonNameAndComponentMapItem =>
        Boolean(buttonKey),
    );
  }, [disablePoll]);

  const isEditingPost = mode === "edit-post";
  const isEditingALink = isEditingPost && isLinkFieldOpen;
  const { isLinkMetaLoading } = useResourcesContext();

  const shouldNextButtonBeDisabled =
    isLinkMetaLoading || isEditingALink || isCheckInProgress;

  const freelancerId = useFreelancerId();
  const { data: userPublicProfileData } = useGetFreelancerPublicProfileQuery(
    freelancerId ?? skipToken,
  );

  const { id } = useParams();

  const locationPage = useLocation();

  return (
    <>
      {showErrorSection && (
        <Field name="_error_section" component={CreateOrEditPostErrorSection} />
      )}
      <Field
        name="_warning_section"
        component={CreateOrEditPostWarningSection}
        onCloseWarning={clearWarning}
      />
      {locationPage.pathname === `/talent/${id}/` &&
      (!userPublicProfileData?.external_profiles[1]?.public_url ||
        !userPublicProfileData?.resume?.attachment?.file) ? (
        <>
          {!hideWarning && (
            <Box
              bgcolor="var(--soft-blue)"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              padding={2}
              mb={4}
              borderRadius={2}
              boxSizing={"border-box"}
              gap={3}
            >
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <img
                  src={`${SETTINGS.STATIC_URL}get-career-help/info.svg`}
                  alt="info"
                  width="24"
                  height="25"
                />
                <Typography component="p" fontWeight={400}>
                  Remember to add your Linkedin and your resume to your
                  Braintrust profile.
                </Typography>
              </Box>

              <Box
                onClick={() => setHideWarning(true)}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={"#ECEFF6"}
                borderRadius={"500px"}
                padding="11px"
                width={"20px"}
                height={"20px"}
                sx={{
                  cursor: "pointer",
                  fontSize: "25px !important",
                }}
              >
                <CloseIcon fontSize="small" />
              </Box>
            </Box>
          )}
        </>
      ) : null}
      <FooterWrapper>
        <Box display="flex" alignItems="center">
          <CreateOrEditPostFooterActionsButtonsSection
            mode={mode}
            buttons={buttonsToRender}
          />
        </Box>
        {showErrorSection && errorMessage && (
          <Typography
            error
            component="p"
            variant="paragraph"
            size="small"
            className={styles.footerWrapperErrorMessage}
          >
            {errorMessage}
          </Typography>
        )}
        <Button
          variant="positive-2"
          shape="squared"
          size="medium"
          onClick={handleOnNextClick}
          disabled={shouldNextButtonBeDisabled}
        >
          Next
          {isCheckInProgress && (
            <Loader
              className={styles.footerWrapperLoader}
              size={20}
              color="inherit"
            />
          )}
        </Button>
      </FooterWrapper>
    </>
  );
};

export type PostFooterButtonNameAndComponentMapItem = {
  Component: PostFooterButtonType;
  name: PostFooterAction;
};

const POST_FOOTER_ACTIONS_TO_BUTTON_NAME_AND_COMPONENT_MAP: Record<
  string,
  PostFooterButtonNameAndComponentMapItem
> = {
  POST_FILE: { name: "post-file", Component: AddFileButton },
  POST_IMAGE: { name: "post-image", Component: AddImageButton },
  POST_LINK: { name: "post-link", Component: AddLinkButton },
  POST_POLL: { name: "post-poll", Component: AddPollButton },
};

type CreateOrEditPostStepTwoFooterProps = {
  onPreviousClick: () => void;
  isEditMode?: boolean;
  formId: typeof CREATE_POST_FORM_ID | typeof EDIT_POST_FORM_ID;
};

export const CreateOrEditPostStepTwoFooter = ({
  onPreviousClick,
  isEditMode,
  formId,
}: CreateOrEditPostStepTwoFooterProps) => {
  const { clearWarning } = useCheckForSelfPromotion(formId);

  const isSubmitFailed = useAppSelector((state) =>
    hasSubmitFailed(formId)(state),
  );

  const isSubmittingPost = useAppSelector((state) =>
    isSubmitting(formId)(state),
  );

  const syncErrorsObj = useAppSelector((state) =>
    getFormSyncErrors(formId)(state),
  );

  const hasMissingCategory = "category" in syncErrorsObj;

  return (
    <>
      {isSubmitFailed && (
        <Field name="" component={CreateOrEditPostErrorSection} isSecondStep />
      )}
      <Field
        name="_warning_section"
        component={CreateOrEditPostWarningSection}
        onCloseWarning={clearWarning}
      />
      <FooterWrapper>
        <Button
          variant="secondary"
          shape="squared"
          size="medium"
          onClick={onPreviousClick}
        >
          Back
        </Button>

        {isSubmitFailed && hasMissingCategory && (
          <FormHelperText
            className={`${styles.footerWrapperErrorMessage} ${styles.footerWrapperErrorMessageStepTwo}`}
            error
          >
            <Typography component="p" variant="paragraph" size="small">
              Please complete your post before proceeding
            </Typography>
          </FormHelperText>
        )}

        <Button
          type="submit"
          variant="positive-2"
          shape="squared"
          size="medium"
        >
          {isEditMode ? "Update post" : "Create post"}
          {isSubmittingPost && (
            <Loader
              className={styles.footerWrapperLoader}
              size={20}
              color="inherit"
            />
          )}
        </Button>
      </FooterWrapper>
    </>
  );
};

import * as React from "react";
import classNames from "classnames";

import { Grid, IconButton } from "@braintrust/braintrust-ui-components";
import { ArrowLeftIcon } from "@braintrust/braintrust-ui-components/Icons";
import { Logo } from "@js/components/logo";

import { withRouter } from "../../hooks";
import { getQuery } from "../../utils";
import { CoreLayout } from "../core";

import styles from "./styles.module.scss";

class WelcomeLayout extends React.Component<any> {
  goBack() {
    const { navigate, backURL } = this.props;
    navigate(backURL || getQuery("back") || "/auth/");
  }

  get back() {
    if ((this.props.back && getQuery("back")) || this.props.backURL) {
      const BackIcon = this.props.backIcon || ArrowLeftIcon;
      return (
        <IconButton
          aria-label="go back"
          onClick={() => this.goBack()}
          variant="tertiary"
          sx={{ color: "var(--grey-4)", alignSelf: "center" }}
        >
          <BackIcon />
        </IconButton>
      );
    }

    return null;
  }

  get header() {
    const back = this.back;
    const { headerSlot } = this.props;

    if (back || headerSlot) {
      return (
        <div className={styles.welcomeLayoutHeader}>
          <div className={styles.welcomeLayoutHeaderContent}>
            {back}
            {headerSlot}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { className, showLogo, children, justify, fixedHeader, pageTitle } =
      this.props;

    return (
      <CoreLayout
        pageTitle={pageTitle}
        className={classNames(styles.welcomeLayout, className, {
          [styles.withFixedHeader]: fixedHeader,
        })}
      >
        {this.header}
        <div className={styles.welcomeLayoutContainer}>
          <div className={styles.welcomeLayoutContent}>
            {showLogo && <Logo variant="symbol" />}
            <Grid container item xs={12} justifyContent={justify}>
              {children}
            </Grid>
          </div>
        </div>
      </CoreLayout>
    );
  }
}

export default withRouter(WelcomeLayout);

import type { ReactNode } from "react";
import React from "react";

import { Tooltip, Typography } from "@braintrust/braintrust-ui-components";
import { JobTypeTag } from "@js/apps/jobs/components/job-type-and-role-tags";

const jobTypeTagTooltipTitle = (
  type: EnumType<typeof ENUMS.JobType>,
): ReactNode => {
  switch (type) {
    case ENUMS.JobType.FREELANCE:
      return (
        <>
          <Typography component="span" fontWeight={500}>
            Freelance job type:{" "}
          </Typography>
          <Typography component="span">
            You will remain self-employed, hired as an independent contractor.
            You invoice the client through Braintrust and pay your own taxes.
          </Typography>
        </>
      );
    case ENUMS.JobType.GRANT:
      return (
        <>
          <Typography component="span" fontWeight={500}>
            Grant job type:{" "}
          </Typography>
          <Typography component="span">
            A specially-funded, specific contribution to a Web3 project. You
            will be hired as a freelancer, with the output of your work shared
            publicly.
          </Typography>
        </>
      );
    case ENUMS.JobType.DIRECT_HIRE:
      return (
        <>
          <Typography component="span" fontWeight={500}>
            Employee job type:{" "}
          </Typography>
          <Typography component="span">
            You will be an employee of the company hiring you. The client pays
            you directly and taxes are usually withheld from your pay.
          </Typography>
        </>
      );
    default:
      return "";
  }
};

type JobTypeTagWithTooltipProps = {
  jobType: EnumType<typeof ENUMS.JobType>;
};

export const JobTypeTagWithTooltip = ({
  jobType,
}: JobTypeTagWithTooltipProps) => {
  return (
    <Tooltip
      title={jobTypeTagTooltipTitle(jobType)}
      arrow
      placement="top"
      enterDelay={500}
      enterNextDelay={300}
    >
      <span>
        <JobTypeTag type={jobType} />
      </span>
    </Tooltip>
  );
};

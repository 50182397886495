import type { ComponentProps } from "react";
import { useMemo } from "react";
import type { FormErrors, TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import type { TypographyColors } from "@braintrust/braintrust-ui-components";
import {
  Box,
  Button,
  Divider,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { TextareaField, TextField } from "@js/forms/fields";
import { maxLength, requiredWithSpecificMessage } from "@js/forms/validators";
import type { SpaceRule } from "@js/types/spaces";

import { useRuleForm } from "../hooks";

import style from "./style.module.scss";

const RULE_FORM_ID = "rule-form";
const MAX_RULE_DESCRIPTION_LENGTH = 1_000;
const MAX_RULE_TITLE_LENGTH = 55;

const validateFormFields = (
  formValues: Partial<RuleFormData>,
): FormErrors<RuleFormData, string> => {
  const errors: FormErrors<RuleFormData> = {
    title: "",
    description: "",
  };

  const missingTitleError = requiredWithSpecificMessage(
    "Enter a title for this rule",
  );

  const maxTitleLengthError = maxLength(MAX_RULE_TITLE_LENGTH);
  const maxDescriptionLengthError = maxLength(MAX_RULE_DESCRIPTION_LENGTH);

  const { title, description } = formValues;

  errors.title = missingTitleError(title);

  if (title) {
    errors.title = maxTitleLengthError(title);
  }

  if (description) {
    errors.description = maxDescriptionLengthError(description);
  }

  return errors;
};

type RuleFormData = Pick<SpaceRule, "title" | "description">;

const RuleFormInstance = createFormInstance<RuleFormData, unknown>(
  RULE_FORM_ID,
  {
    validate: validateFormFields,
  },
);

type RuleFormProps = { ruleNumber: number; spaceId: number } & (
  | {
      mode: "edit";
      rule: SpaceRule;
    }
  | {
      mode: "create";
    }
);

const RuleForm = (props: RuleFormProps) => {
  const { mode, spaceId } = props;

  const { onSubmit, onSubmitSuccess, onBackButtonClick } = useRuleForm({
    mode,
    spaceId,
    ruleId: mode === "edit" ? props.rule.id : undefined,
  });

  const initialValues = useMemo(
    () => (props.mode === "create" ? undefined : { ...props.rule }),
    [props],
  );

  return (
    <RuleFormInstance
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      initialValues={initialValues}
    >
      <Field
        ruleNumber={props.ruleNumber}
        name="title"
        id="title"
        placeholder="Give this rule a title..."
        maxTitleLength={MAX_RULE_TITLE_LENGTH}
        className={style.ruleTitleField}
        component={TextFieldWithMaxLengthCounter}
      />

      <Field
        name="description"
        id="description"
        label={
          <Typography component="label" fontWeight={500}>
            Additional details{" "}
            <Typography component="label">(optional)</Typography>
          </Typography>
        }
        placeholder="Further explain the rule, provide guidelines and expectations..."
        inputProps={{ style: { padding: 0 } }}
        maxLength={MAX_RULE_DESCRIPTION_LENGTH}
        component={TextareaField}
      />

      <Divider className={style.divider} color="light-grey" />

      <Box className={style.ruleButtonsWrapper}>
        <Button variant="secondary" shape="squared" onClick={onBackButtonClick}>
          Back
        </Button>

        <Button type="submit" variant="positive-2" shape="squared">
          Save
        </Button>
      </Box>
    </RuleFormInstance>
  );
};

type TextFieldWithMaxLengthCounterProps = ComponentProps<typeof TextField> &
  TypedWrappedFieldProps<string> & {
    ruleNumber: number;
    maxTitleLength: number;
  };

const TextFieldWithMaxLengthCounter = ({
  ruleNumber,
  maxTitleLength,
  input,
  meta,
  ...props
}: TextFieldWithMaxLengthCounterProps) => {
  const titleLength = input.value.length;

  const hasExceededCharacterLimit = titleLength > maxTitleLength;

  const charactersCountColor: TypographyColors = hasExceededCharacterLimit
    ? "negative"
    : "grey-2";

  return (
    <Box>
      <Box className={style.ruleTitleLabelWrapper}>
        <label htmlFor={props.id}>
          <Typography variant="label" component="p">
            Rule {ruleNumber}
          </Typography>
        </label>

        <Typography
          variant="paragraph"
          component="p"
          color={charactersCountColor}
        >
          {titleLength} / {maxTitleLength}
        </Typography>
      </Box>

      <TextField input={input} meta={meta} {...props} />
    </Box>
  );
};

export {
  MAX_RULE_DESCRIPTION_LENGTH,
  MAX_RULE_TITLE_LENGTH,
  RULE_FORM_ID,
  RuleForm,
};
export type { RuleFormData };

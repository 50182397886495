import React from "react";

import { Box, Typography } from "@braintrust/braintrust-ui-components";
import { approveTalent } from "@js/apps/admin/actions";
import type { RefetchBidList } from "@js/apps/jobs/context/refetch-bids-context";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

export const handleApproveTalentClick = (
  freelancerId: number,
  refetchBidList: RefetchBidList,
) => {
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    children: (
      <ModalConfirm
        onCancel={ModalInstance.close}
        confirmText="Yes, approve Talent"
        onConfirm={() => {
          approveTalent(freelancerId)
            .then(() => {
              Snackbar.success(
                "Talent is approved and application is visible to the client",
              );
              ModalInstance.close();
              refetchBidList();
            })
            .catch(() => Snackbar.error("Failed to approve talent."));
        }}
      >
        <Box maxWidth={404}>
          <Typography variant="title" component="h4" mt={4.75} mb={1}>
            Approve this Talent
          </Typography>
          You’re about to change this Talent’s status to “Approved” on the
          Braintrust platform.
        </Box>
      </ModalConfirm>
    ),
  });
};

import {
  Box,
  Button,
  Divider,
  Loader,
} from "@braintrust/braintrust-ui-components";
import type { EmployerTeamMemberType } from "@js/types/employer";

import { useShareWithTeamMembers } from "../../hooks/use-share-with-team-members";
import {
  CommonTeamMembersModalContentContainer,
  CommonTeamMembersModalCTAContainer,
  CommonTeamMembersModalHeaderContainer,
  CommonTeamMembersModalHeaderText,
  CommonTeamMembersModalListContainer,
  CommonTeamMembersModalMessage,
  CommonTeamMembersModalText,
} from "../common-team-members-modal";
import { ShareWithTeamMemberInvitationsAutocomplete } from "../share-with-team-member-invitations-autocomplete";
import { ShareWithTeamMemberInvitationsList } from "../share-with-team-member-invitations-list";

type OnSaveArg = {
  emailInvitations: string[];
  teamMemberIds: number[];
  message?: string;
};

export type ShareBidsWithTeamMembersModalContentProps = {
  jobOwnerId: number | undefined;
  bidsToShareCount: number;
  teamMembers: EmployerTeamMemberType[] | undefined;
  isSaving: boolean;
  isLoading: boolean;
  onSave: (arg: OnSaveArg) => void;
  errorMessage: string | undefined;
  clearErrorMessage: () => void;
};

export const ShareBidsWithTeamMembersModalContent = ({
  jobOwnerId,
  isSaving,
  isLoading,
  teamMembers,
  bidsToShareCount,
  onSave,
  errorMessage,
  clearErrorMessage,
}: ShareBidsWithTeamMembersModalContentProps) => {
  const {
    message,
    shareWithTeamMemberEmailInvitations,
    shareWithTeamMemberIds,
    setMessage,
    toggleShareWithTeamMember,
    toggleShareWithTeamMemberInvitation,
  } = useShareWithTeamMembers({ clearErrorMessage });

  const hasSelectedTeamMembersOrInvitations =
    !!shareWithTeamMemberEmailInvitations.length ||
    !!shareWithTeamMemberIds.length;

  const disableSubmit = isSaving || !hasSelectedTeamMembersOrInvitations;

  const handleSubmit = () => {
    if (disableSubmit) {
      return;
    }

    onSave({
      emailInvitations: shareWithTeamMemberEmailInvitations,
      teamMemberIds: shareWithTeamMemberIds,
      message: !!message ? message : undefined,
    });
  };

  return (
    <CommonTeamMembersModalContentContainer onSubmit={handleSubmit}>
      <CommonTeamMembersModalHeaderContainer
        sx={{ maxWidth: "calc(100% - 60px)" }}
      >
        <CommonTeamMembersModalHeaderText>
          {getShareBidsModalTitle(bidsToShareCount)}
        </CommonTeamMembersModalHeaderText>
      </CommonTeamMembersModalHeaderContainer>

      <CommonTeamMembersModalText>
        Invite team members to review applicants. If they're not on Braintrust
        yet, you can invite them to join your organization by entering their
        email addresses
      </CommonTeamMembersModalText>

      <ShareWithTeamMemberInvitationsAutocomplete
        selectedInvitations={shareWithTeamMemberEmailInvitations}
        toggleInvitation={toggleShareWithTeamMemberInvitation}
        errorMessage={errorMessage}
      />

      <CommonTeamMembersModalListContainer
        isLoading={isLoading || !teamMembers}
        sx={{ maxHeight: { xs: "272px", sm: "345px" } }}
      >
        {!!teamMembers && (
          <ShareWithTeamMemberInvitationsList
            jobOwnerId={jobOwnerId}
            invitationsToShare={shareWithTeamMemberEmailInvitations}
            teamMemberIdsToShare={shareWithTeamMemberIds}
            toggleShareWithTeamMember={toggleShareWithTeamMember}
            toggleShareWithTeamMemberInvitation={
              toggleShareWithTeamMemberInvitation
            }
            teamMembers={teamMembers}
          />
        )}
      </CommonTeamMembersModalListContainer>

      <Box>
        <CommonTeamMembersModalMessage
          message={message}
          onChange={setMessage}
        />
      </Box>

      <Box>
        <Divider sx={{ borderColor: "var(--grey-4)" }} />
      </Box>

      <CommonTeamMembersModalCTAContainer>
        <Button
          variant="positive"
          shape="squared"
          type="submit"
          disabled={disableSubmit}
          endIcon={isSaving ? <Loader size={20} color="inherit" /> : undefined}
        >
          {getShareBidsCTAText(bidsToShareCount)}
        </Button>
      </CommonTeamMembersModalCTAContainer>
    </CommonTeamMembersModalContentContainer>
  );
};

const getShareBidsModalTitle = (bidsToShareCount: number) => {
  if (bidsToShareCount === 1) {
    return "Share this applicant with your team";
  }

  return `Share ${bidsToShareCount} applicants with your team`;
};

const getShareBidsCTAText = (bidsToShareCount: number) => {
  if (bidsToShareCount === 1) {
    return "Share applicant";
  }

  return `Share applicants`;
};

import { Typography } from "@braintrust/braintrust-ui-components";
import { assertUnreachable } from "@js/utils";

type EmployerTopStripeContentProps = {
  offerStatus: EnumType<typeof ENUMS.OfferStatus> | null;
};

export const EmployerTopStripeContent = ({
  offerStatus,
}: EmployerTopStripeContentProps) => {
  switch (offerStatus) {
    case null: {
      return (
        <Typography
          component="p"
          variant="paragraph"
          p={0.5}
          textAlign="center"
        >
          Make sure the terms of this offer are correct before sending. Editing
          these details later is possible but not recommended.
        </Typography>
      );
    }
    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.UPDATED:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_IN_PROGRESS:
      return (
        <Typography
          component="p"
          variant="paragraph"
          p={0.5}
          textAlign="center"
        >
          Make sure you and the Talent agree to any changes before you send an
          updated offer.
        </Typography>
      );

    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.CANCELLED:
    case ENUMS.OfferStatus.REJECTED:
    case ENUMS.OfferStatus.EXPIRED:
    case ENUMS.OfferStatus.EXTENDED:
      return null;

    default:
      assertUnreachable(offerStatus);
      return null;
  }
};

import type { FormValue } from "@braintrust/braintrust-ui-components";
import { getError, shouldDisplayError } from "@js/forms/utils";
import { assertUnreachable } from "@js/utils";

import {
  getLocationRequirementDescription,
  getTimezoneRequirementDescription,
} from "./helpers";
import type { UseRequirementField } from "./types";

export const useRequirementField: UseRequirementField = ({
  field,
  job,
  type,
}) => {
  const handleChange = (value: FormValue) => field.input.onChange(value);
  const displayError = shouldDisplayError(field);
  const error = getError(field);

  const commonProperties = {
    handleChange,
    displayError,
    error,
  };

  switch (type) {
    case "location": {
      return {
        ...commonProperties,
        description: getLocationRequirementDescription(job.locations),
        title: "Do you meet the location requirement for this job?",
      };
    }
    case "timezone": {
      return {
        ...commonProperties,
        description: getTimezoneRequirementDescription(
          job.timezones,
          job.timezone_overlap,
        ),
        title: "Do you meet the timezone requirement for this job?",
      };
    }
    default: {
      return assertUnreachable(type) as never;
    }
  }
};

import React from "react";
import { Field } from "redux-form";

import { Typography } from "@braintrust/braintrust-ui-components";
import { ModalConfirm } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { SelectField } from "@js/forms/fields";
import type { EmployerOwnJob } from "@js/types/jobs";

import { useChangeJobOwnersForm } from "../../hooks/change-job-owners";

const CHANGE_JOB_OWNERS_FORM_ID = "change-job-owner-form";

export type ChangeJobOwnerFormData = {
  new_owner: number;
};

type ChangeJobOwnerFormProps = {
  jobId: number;
  jobStatus: EmployerOwnJob["status"];
  onCancel: () => void;
  onSuccess: () => void;
};

const ChangeJobOwnerFormInstance = createFormInstance<
  ChangeJobOwnerFormData,
  unknown
>(CHANGE_JOB_OWNERS_FORM_ID, {
  enableReinitialize: true,
});

const ChangeJobOwnerForm = ({
  jobId,
  jobStatus,
  onCancel,
  onSuccess,
}: ChangeJobOwnerFormProps) => {
  const { jobOwnersToSelect, onSubmitSuccess, ...formProps } =
    useChangeJobOwnersForm(jobId, jobStatus);
  return (
    <ChangeJobOwnerFormInstance
      onSubmitSuccess={() => onSubmitSuccess({ onSuccess, onCancel })}
      {...formProps}
    >
      <ModalConfirm
        onConfirm="submit"
        confirmText="Change Owner"
        onCancel={onCancel}
      >
        <Typography component="h1" mb={1}>
          Select new owner for this job.
        </Typography>
        <Field
          variant="standard"
          name="new_owner"
          id="new_owner"
          component={SelectField}
          label="New Owner"
          options={jobOwnersToSelect.map((user) => ({
            value: user.id,
            label: user.public_name,
          }))}
        />
      </ModalConfirm>
    </ChangeJobOwnerFormInstance>
  );
};

export default ChangeJobOwnerForm;

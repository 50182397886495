import { Box, Stack } from "@braintrust/braintrust-ui-components";

import type {
  UniversalSearchJobsResponse,
  UniversalSearchPostsResponse,
  UniversalSearchTalentResponse,
} from "../../api";
import { CareerSearchListItem } from "../search-results/career-search-list-item";
import { JobSearchListItem } from "../search-results/job-search-list-item";
import { PeopleSearchListItem } from "../search-results/people-search-list-item";
import { SearchList } from "../search-results/search-list";
import { UniversalSearchResultsHeader } from "../universal-search-results-header";

import type { UniversalSearchEmptyCommunicationProps } from "./universal-search-empty-communication";
import { UniversalSearchEmptyCommunication } from "./universal-search-empty-communication";

type UniversalSearchEmptyStateOtherTabsProps =
  UniversalSearchEmptyCommunicationProps & {
    currentData: {
      talentResults: UniversalSearchTalentResponse | undefined;
      jobResults: UniversalSearchJobsResponse | undefined;
      postResults: UniversalSearchPostsResponse | undefined;
    };
  };

export const UniversalSearchEmptyStateOtherTabs = ({
  currentTabName,
  tabsWithData,
  currentData,
}: UniversalSearchEmptyStateOtherTabsProps) => {
  const careerResults = (currentData.postResults?.results ?? []).slice(0, 3);
  const peopleResults = (currentData.talentResults?.results ?? []).slice(0, 3);
  const jobsResults = (currentData.jobResults?.results ?? []).slice(0, 3);
  return (
    <Stack sx={{ overflow: "auto", px: 1, mt: { xs: 2, mt: 3 }, pb: 1 }}>
      <Box sx={{ px: 1 }}>
        <UniversalSearchEmptyCommunication
          tabsWithData={tabsWithData}
          currentTabName={currentTabName}
        />
      </Box>
      <Stack sx={{ mt: 3, gap: 2 }}>
        {tabsWithData.map((tabWithData) => {
          const currentTab = tabWithData.value;
          return (
            <Box key={currentTab}>
              <Box sx={{ px: 1, mb: 1 }}>
                <UniversalSearchResultsHeader
                  currentTab={currentTab}
                  results={currentData}
                />
              </Box>
              <SearchList>
                {currentTab === "CAREER_HELP" &&
                  careerResults.map((post) => (
                    <CareerSearchListItem key={post.id} post={post} />
                  ))}
                {currentTab === "PEOPLE" &&
                  peopleResults.map((talent) => (
                    <PeopleSearchListItem key={talent.id} freelancer={talent} />
                  ))}
                {currentTab === "JOBS" &&
                  jobsResults.map((job) => (
                    <JobSearchListItem key={job.id} job={job} />
                  ))}
              </SearchList>
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};

import cs from "classnames";

import { Typography } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { pluralize } from "@js/utils";

import styles from "./styles.module.scss";

type HelpOfferStatusBadgeProps = {
  count?: number | null;
  status: "pending" | "active";
  icon: JSX.Element;
};

export const HelpOfferStatusBadge = ({
  count = 0,
  status,
  icon,
}: HelpOfferStatusBadgeProps): JSX.Element | null => {
  const isMobile = useMediaQuery("sm");
  if (!count) {
    return null;
  }
  return (
    <div
      className={cs(styles.offerBadge, {
        [styles.offerBadgeActive]: status === "active",
        [styles.offerBadgePending]: status === "pending",
      })}
    >
      {!isMobile && icon}
      <Typography
        size="small"
        variant="paragraph"
        component="p"
        fontWeight={500}
      >
        {count} {status} {!isMobile && "offer" + pluralize(count)}
      </Typography>
    </div>
  );
};

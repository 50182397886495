import React, { Fragment, useEffect } from "react";
import type { WrappedFieldArrayProps } from "redux-form";
import { Field } from "redux-form";

import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
} from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import {
  transformLanguageListToCommonFormat,
  useGetLanguageListQuery,
} from "@js/apps/common/api";
import { Snackbar } from "@js/components/snackbar";
import { SelectField } from "@js/forms/fields";
import { enumToOptions } from "@js/utils";

import { SectionSubtitle } from "../helpers";

type SkillLevelFieldValue = {
  skill_level?: string;
};

type LanguageIdFieldValue = {
  language_id?: number;
};

type LanguageFieldValue = SkillLevelFieldValue & LanguageIdFieldValue;

export const LanguagesField = ({
  fields,
  meta: { error, submitFailed },
}: WrappedFieldArrayProps<LanguageFieldValue>) => {
  const {
    isLoading,
    isError,
    data: languages = [],
  } = useGetLanguageListQuery();

  const commonFormatLanguages = transformLanguageListToCommonFormat(languages);

  useEffect(() => {
    if (!isError) return;

    Snackbar.error("Failed to fetch languages.");
  }, [isError]);

  return (
    <>
      <Grid item xs={12}>
        <SectionSubtitle
          containerProps={{ mt: 8 }}
          action={
            <Button variant="secondary" onClick={() => fields.push({})}>
              Add Language
            </Button>
          }
        >
          Language
        </SectionSubtitle>
      </Grid>
      {!isLoading &&
        fields.map((fieldName, index) => {
          //fieldName would be user_languages[0] for example

          return (
            <Fragment key={fieldName}>
              <Grid item sm={6} xs={12}>
                <Field
                  id={`${fieldName}.language_id`}
                  name={`${fieldName}.language_id`}
                  component={SelectField}
                  options={commonFormatLanguages}
                  label={`${index === 0 ? "Primary " : ""}Language`}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Field
                  id={`${fieldName}.skill_level`}
                  name={`${fieldName}.skill_level`}
                  component={SelectField}
                  options={enumToOptions(ENUMS.LanguageSkillLevelLabels)}
                  label="Proficiency level"
                />
                <IconButton
                  variant="secondary"
                  size="small"
                  onClick={() => fields.remove(index)}
                  sx={{ m: "36px 0px 0 16px" }}
                  aria-label="Delete language"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Fragment>
          );
        })}
      {submitFailed && error && (
        <Grid item xs={12}>
          <FormHelperText error>{error}</FormHelperText>
        </Grid>
      )}
    </>
  );
};
